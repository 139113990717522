import React, { useEffect, useState, useContext } from 'react';
import { components, IndicatorProps, SingleValueProps } from 'react-select';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { DownOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
// models
import { SuccessErrorCallback } from '@models/callback';
import { SelectOption } from '@models/Option';
import { CompanyProfile } from '@models/Company';
// constants
import { GET_ET_COMPANIES_ENDPOINT } from '@constants/asyncEndpoints';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// components
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
// styled components
import Styles from '@features/company/add-to-equity-touch/components/EquityTouchAddon.style';
import { Context } from '@optx/screens/App/EquityTouchBulk/components/common/Context';

/**
 * customDropdownIndicator - style the arrow of the dropdown.
 */
const DropdownIndicator: React.FC<IndicatorProps<any>> = props => (
  <components.DropdownIndicator {...props}>
    <DownOutlined />
  </components.DropdownIndicator>
);

const SingleValue: React.FC<SingleValueProps<any>> = ({ children }) => (
  <div className="multiselect-select__single-value">
    <TruncateTooltip title={children} placement="topLeft">
      {children}
    </TruncateTooltip>
  </div>
);

interface BulkEquityTouchAddonProps {
  name: string;
  readOnly?: boolean;
}

const BulkEquityTouchAddon: React.FC<BulkEquityTouchAddonProps> = ({ name, readOnly }) => {
  const companyData = useSelector(bulkActionsSelectors.equityTouch.getCompanyData);
  const companiesById = useSelector(bulkActionsSelectors.equityTouch.getCompanies);
  const bulkValues = useSelector(bulkActionsSelectors.equityTouch.getBulkValues);
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);

  const [fetchedOptions, setFetchedOptions] = useState<SelectOption[]>([]);
  const [values, setValues] = useState<SelectOption[]>();

  const [, , helpers] = useField(name);
  const { step, addon: addonContext } = useContext(Context);
  const { loadOptions } = useAsyncSearch({ endpoint: GET_ET_COMPANIES_ENDPOINT });

  useEffect(() => {
    if (Object.keys(companyData).length) {
      const addons = companiesById?.[(companyData as CompanyProfile)?.company_id]?.[
        'Add-On For'
      ] as SelectOption[];

      setValues(addons);
      helpers.setValue(addons);

      if (temporaryData?.step2) {
        const tempDataAddonFor = (
          temporaryData.step2[(companyData as CompanyProfile)?.company_id] as any
        )?.['Add-On For'] as SelectOption[];

        if (tempDataAddonFor !== values) {
          setValues(addons);
          helpers.setValue(addons);
        }
      }
    }

    if (step === 0) {
      const bulkAddon =
        bulkValues['Add-On For'] || addonContext || temporaryData.step1?.['Add-On For'];
      setValues(bulkAddon as SelectOption[]);

      helpers.setValue(bulkAddon);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesById, companyData]);

  const handleChange = (values: any) => {
    setValues(values);
    helpers.setValue(values);
  };

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  return (
    <Styles.AsyncWrapper>
      <AsyncMultiSelect
        className="addon--multiselect-async"
        cacheOptions
        components={{ DropdownIndicator, SingleValue }}
        loadOptions={getLoadOptionsDelay}
        defaultOptions={fetchedOptions}
        defaultValue={values}
        getOptionValue={(option: SelectOption) => option.id?.toString() || ''}
        onChange={handleChange}
        maxLength={500}
        loadingMessage={({ inputValue }) => (inputValue.length > 2 ? 'Loading...' : 'Begin typing')}
        onSelectResetsInput={false}
        blurInputOnSelect={false}
        closeOnSelect
        isDisabled={readOnly}
        autoFocus={false}
      />
    </Styles.AsyncWrapper>
  );
};

export default BulkEquityTouchAddon;
