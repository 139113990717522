import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
// constants
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// models
import { CompanyKeys } from '@optx/models/table/Columns';
import Company, { CompanyProfile } from '@optx/models/Company';
// services
import { CompanyService, UserService } from '@optx/services/api';
// utils
import { getIsPsgCompany } from '@optx/utils/company';
// components
import { EditFieldSingleSelect } from '@optx/features/company/edit-fields/components';
import {
  TableCellActions,
  TableCellRadioAdmin,
} from '@optx/components/common/table/Companies/cells';
import {
  TableCellName,
  TableCellCompanyURL,
} from '@optx/components/common/table/Companies/styled-cells';
import TableCellScore from './cells/TableCellScore';
import TableCellDefaultRender from '@optx/components/common/table/AntTable/cells/TableCellDefault';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

export interface CompanyColumnType extends ColumnType<CompanyProfile> {
  dataIndex: CompanyKeys;
}

export const columns: Array<CompanyColumnType> = [
  {
    dataIndex: 'company_url',
    title: '',
    className: 'company_url',
    render: (value: string | null, record: Company, index: number) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    className: 'company_name',
    sorter: true,
    render: (value: string, record: CompanyProfile, index: number) => (
      <TableCellName value={value} record={record} />
    ),
    width: 170,
    ellipsis: true,
  },
  {
    dataIndex: 'checklist_reviewed_by',
    title: 'Reviewer',
    className: 'checklist_reviewed_by',
    sorter: true,
    render: TableCellDefaultRender,
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'stage',
    title: 'Stage',
    className: 'stage',
    sorter: true,
    render: (value: string, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          service={CompanyService.updateCompanyData}
          fieldName="Stage"
          value={value}
          successMessage="Company stage updated successfully!"
          errorMessage="Company stage update failed, Server error!"
          record={record}
          fieldKey="stage"
        />
      ) : (
        value
      ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'pipeline_rank',
    title: 'Pipeline Rank',
    className: 'pipeline_rank',
    sorter: true,
    render: (value: string, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          service={CompanyService.updateCompanyData}
          fieldName="Pipeline Rank"
          value={value}
          successMessage="Company pipeline rank updated successfully!"
          errorMessage="Company pipeline rank update failed, Server error!"
          record={record}
          fieldKey="rank"
        />
      ) : (
        value
      ),
    width: 85,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'checklist_reviewed_date',
    title: 'Date Reviewed',
    className: 'checklist_reviewed_date',
    sorter: true,
    render: (value: string) => (
      <TruncateTooltip
        title={moment(value, ISO_DATE_FORMAT).format(globalConfig.grid_date.DATE_FORMAT)}
      >
        {moment(value, ISO_DATE_FORMAT).format(globalConfig.grid_date.DATE_FORMAT)}
      </TruncateTooltip>
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'score',
    title: 'OPTX Score',
    className: 'score',
    sorter: true,
    width: 150,
    render: (value: number | string | null, record: CompanyProfile) => (
      <TableCellScore
        value={value}
        record={record}
        verifiedKey="optx_score_verified"
        isPSGCompany={getIsPsgCompany(record)}
      />
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'il_optx_score',
    title: 'IL OPTX Score',
    className: 'il_optx_score',
    sorter: true,
    width: 150,
    render: (value: number | string | null, record: CompanyProfile) => (
      <TableCellScore
        value={value}
        record={record}
        verifiedKey="il_optx_score_verified"
        isPSGCompany={getIsPsgCompany(record)}
      />
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'is_interesting',
    title: 'PSG Fit',
    className: 'is_interesting',
    sorter: true,
    render: (value: boolean | null, record: Company, index: number) => (
      <TableCellRadioAdmin
        value={value}
        record={record}
        fieldName="PSG Fit"
        service={UserService.updateFitEvaluation}
        successMessage="Company PSG fit updated successfully!"
        errorMessage="Failed to update value!"
        destroyOnHide
        isFromGrid
      />
    ),
    width: 110,
    align: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'is_software',
    title: 'Software',
    className: 'is_software',
    sorter: true,
    width: 85,
    render: (value: boolean | null, record: Company, index: number) => (
      <TableCellRadioAdmin
        value={value}
        record={record}
        fieldName="Software Company"
        service={UserService.addCompanySoftwareEvaluation}
        successMessage="Company software company updated successfully!"
        errorMessage="Failed to update value!"
        destroyOnHide
        isFromGrid
      />
    ),
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    className: 'row_actions',
    render: (value: unknown, record: CompanyProfile, index: number) => (
      <TableCellActions value={value} record={record} index={index} />
    ),
    width: 65,
    fixed: 'right',
  },
];

export default columns;
