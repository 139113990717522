import React, { useEffect } from 'react';
import { FormikProps, useField } from 'formik';
import { Row, Col, Typography } from 'antd';
import { FormItem, Input, Select } from 'formik-antd';
import { OptionTypeBase } from 'react-select';
// models
import { FormValues } from '../models';
import { SelectOption } from '@models/Option';
// hooks
import useGetFilterData from '../hooks/useGetFilterData';
// components
import InputNumberField from './fields/InputNumberField';
import StageWithRationaleField from './fields/StageWithRationaleField';
// styled
import { Styled } from './AddCompanyModal.styled';
import AddonForField from './fields/AddonForField';

const { Option } = Select;
const { TextArea } = Input;

interface Step4Props {
  formikProps: FormikProps<FormValues>;
  handleDisableNextButton: (disabled: boolean) => void;
}

/**
 * PSG Context.
 *
 * In Step 4 we handle the rest of the fields.
 */
const AddCompanyStep4: React.FC<Step4Props> = ({ formikProps, handleDisableNextButton }) => {
  const [companyOwnerField, , companyOwnerHelpers] = useField<SelectOption | undefined>(
    'companyOwner'
  );
  const [rankField, , rankHelpers] = useField<SelectOption | undefined>('rank');
  const [sourceField, , sourceHelpers] = useField<SelectOption | undefined>('source');
  const [opportunityTypeField, , opportunityHelpers] = useField<SelectOption | undefined>(
    'opportunityType'
  );
  const [fundField, , fundHelpers] = useField<SelectOption | undefined>('fund');
  const [isAddonField, , isAddonHelpers] = useField<SelectOption<number> | null>('isAddon');
  const [addonForField, , addonForHelpers] = useField<SelectOption[] | undefined>('addonFor');

  const { analysts, funds, ranks, sources, opportunityTypes } = useGetFilterData(4);

  useEffect(() => {
    // since we're on the last step, just check if there's no error
    if (Object.keys(formikProps.errors).length) handleDisableNextButton(true);
    else handleDisableNextButton(false);
  }, [formikProps, handleDisableNextButton]);

  return (
    <Styled.Step4>
      <Row className="spacer-bottom">
        <Typography.Text>Now, let's add the PSG context.</Typography.Text>
      </Row>

      <Row gutter={16} id="step-4">
        <Col span={8}>
          <FormItem name={companyOwnerField.name} label="Company Owner" required>
            <Select
              name={companyOwnerField.name}
              value={companyOwnerField.value?.value}
              onChange={(val: string) => {
                const analyst = analysts.find(analyst => analyst.value === val);

                if (analyst) {
                  companyOwnerHelpers.setValue({ label: analyst.label, value: analyst.value });
                }
              }}
              filterOption={(input: string, option: OptionTypeBase | undefined) => {
                const fullName = `${option?.children.props.children}`;

                return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              showSearch
            >
              {analysts.map((analyst, index) => (
                <Option key={index} value={analyst.value}>
                  <Typography.Text>{analyst.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name="stage" label="Stage" required>
            <StageWithRationaleField />
          </FormItem>

          <FormItem name={rankField.name} label="Pipeline Rank (optional)">
            <Select
              name={rankField.name}
              value={rankField.value?.value}
              onChange={(val: string) => {
                const rank = ranks.find(rank => rank.value === val);

                if (rank) {
                  rankHelpers.setValue({ label: rank.label, value: rank.value });
                }
              }}
              filterOption={(input: string, option: OptionTypeBase | undefined) => {
                const fullName = `${option?.children.props.children}`;

                return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              showSearch
            >
              {ranks.map((rank, index) => (
                <Option key={index} value={rank.value}>
                  <Typography.Text>{rank.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name={sourceField.name} label="Lead Source (optional)">
            <Select
              name={sourceField.name}
              value={sourceField.value?.value}
              onChange={(val: string) => {
                const source = sources.find(source => source.value === val);

                if (source) {
                  sourceHelpers.setValue({ label: source.label, value: source.value });
                }
              }}
              filterOption={(input: string, option: OptionTypeBase | undefined) => {
                const fullName = `${option?.children.props.children}`;

                return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              showSearch
            >
              {sources.map((source, index) => (
                <Option key={index} value={source.value}>
                  <Typography.Text>{source.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name="rationale" label="Rationale (optional)">
            <Input name="rationale" />
          </FormItem>

          <FormItem name="opportunityType" label="Opportunity Type" required>
            <Select
              name={opportunityTypeField.name}
              value={opportunityTypeField.value?.value}
              onChange={(val: string) => opportunityHelpers.setValue({ label: val, value: val })}
              showSearch
            >
              {opportunityTypes.map((opportunityType, index) => (
                <Option key={index} value={opportunityType.value}>
                  <Typography.Text>{opportunityType.label}</Typography.Text>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>

        <Col span={16}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem name="etCheck" label="Equity Check (in millions)" required>
                <InputNumberField id="etCheck" value="" />
              </FormItem>

              <FormItem name={fundField.name} label="Fund" required>
                <Select
                  name={fundField.name}
                  value={fundField.value?.value}
                  onChange={(val: string) => {
                    const fund = funds.find(fund => fund.value === val);

                    if (fund) {
                      fundHelpers.setValue({ label: fund.label, value: fund.value });
                    }
                  }}
                  filterOption={(input: string, option: OptionTypeBase | undefined) => {
                    const fullName = `${option?.children.props.children}`;

                    return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                >
                  {funds.map((fund, index) => (
                    <Option key={index} value={fund.value}>
                      <Typography.Text>{fund.label}</Typography.Text>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col span={12}>
              <FormItem name={isAddonField.name} label="Add-on (optional)">
                <Select
                  name={isAddonField.name}
                  value={isAddonField.value?.value}
                  onChange={(val: number) => {
                    if (val === 0) {
                      addonForHelpers.setValue([]);
                    }

                    isAddonHelpers.setValue({ label: val === 1 ? 'Yes' : 'No', value: val });
                  }}
                >
                  <Option value={1}>
                    <Typography.Text>Yes</Typography.Text>
                  </Option>

                  <Option value={0}>
                    <Typography.Text>No</Typography.Text>
                  </Option>
                </Select>
              </FormItem>

              <FormItem
                name={addonForField.name}
                label="Add-on for"
                required={isAddonField.value?.value === 1}
              >
                <AddonForField />
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FormItem name="description" label="Description" required>
                <TextArea name="description" rows={8} placeholder="Add a description" />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </Styled.Step4>
  );
};

export default React.memo(AddCompanyStep4);
