import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { PageInfo } from '@optx/models/table/Pagination';
import { SortByRule, SortRule } from '@models/table/sorting';
// utils
import { getErrorMessage } from '@utils/api/errors';
import { mapSortQuery } from '@optx/utils/search';
// redux
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
import { actions as listsAndSearchesActions } from '@features/lists-and-searches/pagination';
import { selectors as userSelectors, actions as userActions } from '@redux/user/information';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import * as models from './interfaces';
import * as selectors from './selectors';
import { ListsService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';
import { searchSourceScrubLists } from './sagasReusable';

export function* searchSourceScrubListsFail() {
  const errorMessage = 'Error fetching source scrub lists!';
  yield put(actions.searchSourceScrubListsFail(errorMessage));
  NotificationService.error(errorMessage);
}

export function* searchSourceScrubsListsSaga(
  action: PayloadAction<models.SearchPayload | undefined>
) {
  const initialQuery: string = yield select(selectors.getQuery);
  let query = '';

  if (action.payload && action.payload.query) {
    query = action.payload.query;
  }

  const pageNumber: number = yield select(selectors.getPageNumber);
  const userPageNumber: number = yield select(userSelectors.getSourceScrubListResultsPageNumber);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);

  const sortBy: SortByRule<any>[] = yield select(selectors.getSort);
  const searchData: models.SearchPayload = {
    query,
    pagination: {
      pageSize,
      pageNumber: query !== initialQuery ? 1 : pageNumber,
    },
    ...(query ? { sortBy: [] } : { sortBy }),
  };

  try {
    yield call(searchSourceScrubLists, searchData);

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettingsForSSList({
          source_scrub_list_page_number: searchData.pagination.pageNumber,
          source_scrub_list_filters: query,
        })
      );
    } else {
      yield put(userActions.updateUserSettingsForSSList({ source_scrub_list_filters: query }));
    }
  } catch (error: any) {
    yield call(searchSourceScrubListsFail);
  }
}

export function* changePaginationSaga(action: PayloadAction<PageInfo>) {
  const pagination = action.payload;

  const query: string = yield select(selectors.getQuery);
  const sortBy: SortByRule<any>[] = yield select(selectors.getSort);
  const userPageNumber: number = yield select(userSelectors.getSourceScrubListResultsPageNumber);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);

  const searchData: models.SearchPayload = {
    query,
    pagination,
    sortBy,
  };

  try {
    yield call(searchSourceScrubLists, searchData);

    if (pagination.pageSize !== pageSize && searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettingsForSSList({
          lists_and_searches_results_per_page: pagination.pageSize,
          source_scrub_list_page_number: searchData.pagination.pageNumber,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['watchlists', 'companySearches', 'contactSearches'],
          pagination,
        })
      );
    } else if (pagination.pageSize !== pageSize) {
      yield put(
        userActions.updateUserSettingsForSSList({
          lists_and_searches_results_per_page: pagination.pageSize,
        })
      );
      yield put(
        listsAndSearchesActions.updateMyWatchlistTabsPagination({
          actions: ['watchlists', 'companySearches', 'contactSearches'],
          pagination,
        })
      );
    } else if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettingsForSSList({
          source_scrub_list_page_number: searchData.pagination.pageNumber,
        })
      );
    }
  } catch (error: any) {
    yield call(searchSourceScrubListsFail);
  }
}

export function* sortSourceScrubListsSaga(action: PayloadAction<SortRule<any>>) {
  const currentSortBy: SortByRule<any>[] = yield select(selectors.getSort);
  const query: string = yield select(selectors.getQuery);
  const pageSize: number = yield select(listsAndSearchesSelectors.pageSize);
  const userPageNumber: number = yield select(userSelectors.getSourceScrubListResultsPageNumber);

  // if we need to sort by relevance we need to set sortBy as an empty array.
  // for this, we check if we are sorting by the same column,
  // if current sorting is done in descending order and if we have a query term set
  const sortBy =
    currentSortBy?.length &&
    currentSortBy[0].id === action.payload.sortBy[0].id &&
    !action.payload.sortBy[0].desc &&
    query
      ? []
      : action.payload.sortBy;

  const searchData: models.SearchPayload = {
    query,
    pagination: {
      pageNumber: 1,
      pageSize,
    },
    sortBy,
  };

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  try {
    yield call(searchSourceScrubLists, searchData);

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield put(
        userActions.updateUserSettingsForSSList({
          source_scrub_list_sorting: sortQuery,
          source_scrub_list_page_number: searchData.pagination.pageNumber,
        })
      );
    } else {
      yield put(userActions.updateUserSettingsForSSList({ source_scrub_list_sorting: sortQuery }));
    }
  } catch (error: any) {
    yield call(searchSourceScrubListsFail);
  }
}

export function* fetchSourceScrubListsTotalSaga() {
  try {
    const res: AxiosResponse<number> = yield call(ListsService.getSourceScrubListsTotal);
    yield put(actions.fetchSourceScrubListsTotalSuccess(res.data));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetching source scrub lists total!');
    yield put(actions.searchSourceScrubListsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* sourceScrubsListsSaga() {
  yield takeLatest(actions.searchSourceScrubLists, searchSourceScrubsListsSaga);
  yield takeLatest(actions.changeSourceScrubPagination, changePaginationSaga);
  yield takeLatest(actions.sortSourceScrub, sortSourceScrubListsSaga);
  yield takeLatest(actions.fetchSourceScrubListsTotal, fetchSourceScrubListsTotalSaga);
}
