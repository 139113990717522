import React, { Context, useContext, useState } from 'react';
import { Typography } from 'antd';
import { OptionsType } from 'react-select';
import { debounce } from 'lodash';
// models
import { SuccessErrorCallback } from '@models/callback';
import { SelectOption } from '@optx/models/Option';
// constants
import { GET_ET_COMPANIES_ENDPOINT } from '@constants/asyncEndpoints';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// components
import { BulkEditFieldContext } from '@optx/models/bulkActions';
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';
import Styled from './EditAddon.style';

interface EditAddonProps {
  context: Context<BulkEditFieldContext>;
}

export const EditAddon: React.FC<EditAddonProps> = ({ context }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectOption>>([]);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([
    { label: 'Clear add-ons', value: 'None' },
  ]);

  const { setSelectedData } = useContext(context);

  const { loadOptions } = useAsyncSearch({ endpoint: GET_ET_COMPANIES_ENDPOINT });

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  return (
    <>
      <Typography.Text className="ant-modal-title">Select New Add-on</Typography.Text>
      <Styled.AsyncWrapper>
        <AsyncMultiSelect
          className="addon--multiselect-async bulk-action-multiselect"
          onChange={options => {
            let mappedOptions = (options as SelectOption[]).map(option => ({
              ...option,
              label: option.value,
            }));

            const blankOption = (mappedOptions as SelectOption[]).find(
              option => option.value === 'None'
            );

            if (blankOption) {
              mappedOptions = [blankOption];
            }

            setIsDisabled(!!blankOption);
            setSelectedData(mappedOptions);
            setSelectedOptions(mappedOptions);
          }}
          loadOptions={getLoadOptionsDelay}
          defaultOptions={fetchedOptions}
          defaultValue={selectedOptions}
          loadingMessage={({ inputValue }) =>
            inputValue.length > 2 ? 'Loading...' : 'Begin typing'
          }
          isDisabled={isDisabled}
          isOptionDisabled={() => false}
          // addon values can repeat causing multiselect issues
          getOptionValue={(option: SelectOption) => option.id?.toString() || ''}
          menuIsOpen
          maxMenuHeight={200}
          onBlur={() => setFetchedOptions([{ label: 'Clear add-ons', value: 'None' }])}
        />
      </Styled.AsyncWrapper>
    </>
  );
};
