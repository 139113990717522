import {
  useInjectCompanySavedSearches,
  useInjectEquityTouch,
  useInjectUserList,
  useInjectSourceScrubLists,
  useInjectContactsSearch,
  useInjectCompanyFundingRounds,
  useInjectCompanyInBusinessEvaluation,
  useInjectCompanyActive,
  useInjectScore,
  useInjectOpportunityPresentation,
} from '@optx/common/hooks/inject';
import { useInject as useInjectCompanyIndividualEdit } from '@components/feature/company-individual-edit';
import { useInject as useInjectScroll } from '@features/scroll-history';
import { useInject as useInjectTouches } from '@features/company-touches';
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
import { useInject as useInjectCompanyGraphs } from '@features/company/graphs';

export const useInjectPage = () => {
  // initialize states and sagas
  useInjectScroll();
  useInjectCompanySavedSearches();
  useInjectEquityTouch();
  useInjectUserList();
  useInjectSourceScrubLists();
  useInjectContactsSearch();
  useInjectOpportunityPresentation();
  useInjectCompanyIndividualEdit();
  useInjectCompanyCard();
  useInjectCompanyGraphs();
  useInjectCompanyFundingRounds();
  useInjectTouches();
  useInjectCompanyInBusinessEvaluation();
  useInjectCompanyActive();
  useInjectScore();
  useInjectOpportunityPresentation();
};
