import { createSelector } from 'reselect';
// constants
import { DEFAULT_SORTING_USER_SETTINGS } from '@optx/constants/filters';
// redux
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { selectors as myCompaniesSortSelectors } from '@redux/my-companies/sort';
import { selectors as filterSelectors } from '@features/grid/filter';
import { selectors as userSelectors } from '@redux/user/information';

export const isLoading = createSelector(
  companyFiltersSelectors.isLoading,
  searchSelectors.isLoading('myCompanies'),
  myCompaniesSortSelectors.isLoading,
  (loadingFilters, loadingMyCompaniesSearch, loadingMyCompaniesSort) =>
    loadingFilters || loadingMyCompaniesSearch || loadingMyCompaniesSort
);

export const shouldFetchMyCompanies = createSelector(
  userInformationSelectors.getSessionSettings,
  companyFiltersSelectors.filtersLoaded,
  userSelectors.loaded,
  searchSelectors.fetched('myCompanies'),
  filterSelectors.getFiltersLoaded('myCompanies'),
  userSelectors.getDefaultViewId,
  (
    persistFilters,
    loadedFilters,
    loadedUser,
    loadedMyCompanies,
    loadedMyCompaniesFilters,
    defaultViewId
  ) => {
    if (
      !defaultViewId &&
      ((!persistFilters.my_companies_filters && !persistFilters.my_companies_sorting) ||
        (persistFilters.my_companies_filters === '' &&
          persistFilters.my_companies_sorting === DEFAULT_SORTING_USER_SETTINGS))
    ) {
      return loadedUser && !loadedMyCompanies;
    }

    return loadedUser && loadedFilters && loadedMyCompaniesFilters && !loadedMyCompanies;
  }
);
