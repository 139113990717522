import React, { useMemo, useCallback } from 'react';
import { Result, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
//utils
import { exportDataToCSV } from '@optx/utils/csvHandler';
// redux
import {
  selectors as importSelectors,
  actions as importActions,
} from '@optx/features/list-import/state';

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

const Completed: React.FC = () => {
  const successfulCount = useSelector(importSelectors.successfulCount);
  const failedImportedCompanies = useSelector(importSelectors.getFailedImports);
  const totalCount = useSelector(importSelectors.getImportCompaniesCount);
  const watchlistTitle = useSelector(importSelectors.getWatchlistTitle);
  const canceledImportsNumber = useSelector(importSelectors.getCanceledImportsNumber);
  const failedImportsOnRequestNumber = useSelector(importSelectors.getFailedImportsOnRequestNumber);
  const listId = useSelector(importSelectors.getListId);
  const dispatch = useDispatch();

  const handleRetryImport = useCallback(() => {
    dispatch(importActions.updateProgress(true));
    dispatch(importActions.updateCompleted(false));
    dispatch(importActions.updatePercentage({ result: 0 }));
    dispatch(importActions.retryImport());
  }, [dispatch]);

  const handleExportErrorsToCSV = useCallback(() => {
    const listOfFailedCompanies = failedImportedCompanies.map(company => {
      return {
        Company: company.Company,
        URL: company.URL,
        'Error Reason': company.Reason,
      };
    });
    exportDataToCSV(listOfFailedCompanies, watchlistTitle);
  }, [failedImportedCompanies, watchlistTitle]);

  const subTitle = useMemo(() => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span>
          {`${
            totalCount - successfulCount - canceledImportsNumber
          } Errors discovered. Use the table below to fix any errors`}
        </span>
        {canceledImportsNumber > 100 && <span>{`${canceledImportsNumber} Canceled by user`}</span>}
        {failedImportsOnRequestNumber > 0 && (
          <div className="d-flex align-items-center">
            <span>{`${failedImportsOnRequestNumber} cancelled due to API issue.`}</span>
            <Button onClick={handleRetryImport} className="ml-2">
              Try Again
            </Button>
          </div>
        )}
        <div>
          {successfulCount > 0 && (
            <Button className="mt-3" type="primary" href={`/user-lists/${listId}`} target="_blank">
              View List
            </Button>
          )}
          {failedImportedCompanies.length > 0 && (
            <Button
              style={{ marginLeft: successfulCount > 0 ? '8px' : '0' }}
              className="mt-3"
              onClick={handleExportErrorsToCSV}
            >
              Export Errors to New CSV
            </Button>
          )}
        </div>
      </div>
    );
  }, [
    totalCount,
    successfulCount,
    canceledImportsNumber,
    failedImportsOnRequestNumber,
    handleRetryImport,
    listId,
    failedImportedCompanies.length,
    handleExportErrorsToCSV,
  ]);

  return (
    <ResultWrapper
      key={'list-import-completed'}
      status={successfulCount > 0 ? 'success' : 'error'}
      title={`${successfulCount || 0}/${totalCount} ${
        totalCount === 1 ? 'Company' : 'Companies'
      } Successfully Added to "${watchlistTitle}"`}
      subTitle={subTitle}
    />
  );
};

export default Completed;
