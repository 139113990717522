import moment from 'moment';
import { Tooltip } from 'antd';
// hooks
import useMyWatchlists from '@hooks/my-watchlists/useMyWatchlists';
// components
import Styled from './RecentlyAccessedGroupCard.styled';
import CommonStyled from '@features/analyst-home/components/AnalystSearchCard/AnalystSearchCard.styled';
import RecentlyAccessedGroupCardSkeleton from './RecentlyAccessedGroupCardSkeleton';

interface RecentlyAccessedGroupCardProps {
  id: number;
  title: string;
  notificationsCount?: number;
  companiesCount: string;
  date: string;
  isLoading: boolean;
}

const RecentlyAccessedGroupCard = ({
  id,
  title,
  notificationsCount,
  companiesCount,
  date,
  isLoading,
}: RecentlyAccessedGroupCardProps) => {
  const { handleMyWatchlistsResults } = useMyWatchlists();

  const normalizedCompaniesCount = Number(companiesCount);
  const isTitleTooLong = title.length > 28;
  const areNotifications = notificationsCount !== undefined;

  const handleClick = () => {
    handleMyWatchlistsResults(id.toString());
  };

  if (isLoading) {
    return <RecentlyAccessedGroupCardSkeleton />;
  }

  return (
    <CommonStyled.StyledCard
      onClick={handleClick}
      hoverable
      $isSmallCard
      $areNotifications={areNotifications}
    >
      {isTitleTooLong ? (
        <Tooltip title={title} overlayStyle={{ maxWidth: '220px' }}>
          <Styled.CardTitle>
            <p>{title.slice(0, 28).concat('...')}</p>
          </Styled.CardTitle>
        </Tooltip>
      ) : (
        <Styled.CardTitle>
          <p>{title}</p>
        </Styled.CardTitle>
      )}
      <div>
        {areNotifications && (
          <Styled.TextBlock>
            <Styled.StyledTag color="success">
              {notificationsCount}
              {` Notification${notificationsCount === 1 ? '' : 's'}`}
            </Styled.StyledTag>
          </Styled.TextBlock>
        )}
        <Styled.HighlightedBlock>
          {companiesCount}{' '}
          {!isNaN(normalizedCompaniesCount) && normalizedCompaniesCount === 1
            ? 'Company'
            : 'Companies'}
        </Styled.HighlightedBlock>
        {date && <Styled.DateBlock>{moment(date).format('MM/DD/YY')} last viewed</Styled.DateBlock>}
      </div>
    </CommonStyled.StyledCard>
  );
};

export default RecentlyAccessedGroupCard;
