import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch, useSelector } from 'react-redux';
// models
import { CompanyProfile } from '@optx/models/Company';
// redux
import { actions as inBusinessActions } from '@redux/company/in-business';
import { getIsInBusiness } from '@redux/company/profile/selectors';

interface ProfileInBusinessCheckboxProps {
  company: CompanyProfile;
  actionPendo?: string;
}

const ProfileInBusinessCheckbox: React.FC<ProfileInBusinessCheckboxProps> = ({
  company,
  actionPendo,
}) => {
  const dispatch = useDispatch();
  const isInBusiness = useSelector(getIsInBusiness);
  const [isChecked, setIsChecked] = useState<boolean>(isInBusiness);

  const updateCompanyInBusinessCheckbox = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    setIsChecked(checked);

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    dispatch(inBusinessActions.addCompanyInBusinessEvaluation(company.company_id, checked));
  };

  useEffect(() => {
    setIsChecked(isInBusiness);
  }, [isInBusiness]);

  return <Checkbox onChange={updateCompanyInBusinessCheckbox} checked={isChecked} />;
};

export default React.memo(ProfileInBusinessCheckbox);
