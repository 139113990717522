import { Modal } from 'antd';
import styled from 'styled-components';

const TouchContainer = styled.div`
  h1 {
    font-size: 16px;
  }
  .ant-typography {
    display: block;
  }
  .ant-select,
  .ant-picker {
    width: 100%;
    background: #ffffff;
  }
  .ant-picker.ant-picker-disabled {
    background: #f5f5f5;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .change-contact-cell {
    background: #fff;
    padding: 4px 10px;
    border: 1px solid #d9d9d9;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 12px;
  }
  .ant-select-selection-overflow {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-select-selector .ant-select-selection-placeholder {
    z-index: 1;
    font-size: 14px;
  }
  .ant-select-customize-input .ant-select-arrow {
    top: 50%;
  }
  .ant-select-selection-overflow-item {
    display: inline-block;
    margin-right: 3px;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  }
  .ant-select-open
    .ant-select-selector
    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
    display: none;
  }
`;

const SelectContactCell = styled.div`
  .change-contact-cell {
    width: 100%;
    color: #1890ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .anticon svg {
      width: 16px;
      height: 16px;
    }
  }
  button:disabled,
  button[disabled] {
    color: gray;
    opacity: 0.5;
  }
  .ant-select-disabled.ant-select-multiple {
    .ant-select-selector {
      background: initial;
      opacity: 0.5;
    }
    .ant-select-arrow .anticon svg {
      color: gray;
      opacity: 0.5;
    }
  }
`;

const DocumentUploadModal = styled(Modal)<{ $isChromeExtension: boolean }>`
  width: ${({ $isChromeExtension }) => ($isChromeExtension ? '590px' : '724px')} !important;
  transform: ${({ $isChromeExtension }) =>
    $isChromeExtension ? 'translateX(-250px)' : 'inherit'} !important;
`;

export const Styled = {
  TouchContainer,
  SelectContactCell,
  DocumentUploadModal,
};
