import styled from 'styled-components';
import AddonMultiSelectFilter from './AddonMultiSelect';

import { styledColors } from '@optx/utils/theme/colors';

export const StyledAddonMultiSelect = styled(AddonMultiSelectFilter)`
  min-width: 210px;
  max-width: 350px;

  .multiselect__control {
    min-height: 32px;
    background-color: rgba(194, 207, 224, 0.25);
    border: 1px solid rgba(194, 207, 224, 0.25);

    &:hover {
      border: 1px solid ${styledColors.blue6};
      background: #fff;
    }
  }

  .multiselect__control.multiselect__control--is-focused {
    border: 1px solid ${styledColors.blue6};
    box-shadow: none;
  }

  .multiselect__value-container {
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    cursor: pointer;

    span.ant-typography.ant-typography-ellipsis.ant-typography-single-line {
      max-width: 280px !important;
    }
  }

  .multiselect__menu {
    z-index: 4;
  }

  .multiselect__option {
    .open-in-new-wrapper {
      visibility: hidden;
      height: 28px;
      width: 27px;
      margin-left: 16px;

      .open-in-new {
        display: inline-block;
        color: #000;

        svg {
          height: auto;
          width: auto;
        }
      }
    }
  }

  .multiselect__option:hover {
    .open-in-new-wrapper {
      visibility: visible;
    }

    .open-in-new:hover {
      color: ${styledColors.blue6};
    }
  }

  .anticon-search {
    display: none;
  }
`;

export const StyledMultiSelectWrapper = styled.div`
  display: inline-block;
`;
