import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'antd';
import { useLocation } from 'react-router-dom';
// constants
import { CompanyPageAddOnMGMT } from '@optx/constants/pendoActions';
// hooks
import { useReduxFullScreen } from '@hooks/fullscreen';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import {
  actions as fullScreenActions,
  selectors as fullscreenSelectors,
} from '@redux/ui/settings/fullscreen';
// components
import { GridContext } from '@components/pages/CompanyProfile/ProfileTabs/Addon/AddonManagementContext';
import { BulkActionsButton } from '@features/bulk-actions/actions-menu';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import SearchCount from '@features/grid/search-count/components/SearchCount';
// styles
import Styled from './AddonGridControls.styles';

const HTML = document.children[0] as HTMLElement;

const AddonGridControls: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('addonManagement')
  );

  // fullscreen functionality
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);

  const { isFullscreen } = useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  useEffect(() => {
    const profileInfo = document.querySelector('.profile-hero');

    if (isFullscreen && profileInfo) {
      profileInfo.classList.add('is-fullscreen');
    }

    if (!isFullscreen && profileInfo && profileInfo.classList.contains('is-fullscreen')) {
      profileInfo.classList.remove('is-fullscreen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);

  return (
    <Styled.AddonGridWrapper justify="space-between" align="middle">
      <Col flex="auto" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Styled.PageTitle ellipsis>Add-on MGMT</Styled.PageTitle>
        <SearchCount gridName="addons" />
        <Styled.ControlsWrapper>
          {!!selectedCompanies.length && (
            <BulkActionsButton
              context={GridContext}
              selectedCompanies={selectedCompanies}
              referrer={encodeURIComponent(location.pathname)}
            />
          )}
        </Styled.ControlsWrapper>
        {!fullscreen && <GridFullScreen pendoAction={CompanyPageAddOnMGMT.fullScreen} />}
      </Col>
    </Styled.AddonGridWrapper>
  );
};

export default React.memo(AddonGridControls);
