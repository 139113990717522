import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { SelectOption } from '@models/Option';
// utils
import { getMultiSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';
// redux
import { selectors, actions } from '@features/pipeline-report/state';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';

interface MultiSelectFilterProps extends MultiSelectProps {
  filterKey: 'fund';
}

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
  filterKey,
  ...restProps
}) => {
  const [options, setOptions] = useState<Array<SelectOption>>();
  const dispatch = useDispatch();
  const { normalized, values } = useSelector(selectors.getFilters);
  const normalizedData = normalized[filterKey];

  const valuesData = values[filterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getMultiSelectOptions(normalizedData as any) as SelectOption[];
      setOptions(sortedOptions);
    }
  }, [normalizedData]);

  const updateValue = useCallback(
    (newValue: Array<SelectOption>) => {
      if (newValue.length === 0) {
        const sortedOptions = getMultiSelectOptions(normalizedData as any) as SelectOption[];
        setOptions(sortedOptions);
        dispatch(actions.updateValue({ filter: { value: sortedOptions, key: filterKey } }));
      } else {
        dispatch(actions.updateValue({ filter: { value: newValue, key: filterKey } }));
      }
    },
    [dispatch, filterKey, normalizedData]
  );

  const { localValue, onBlur, onChange } = useLocalMultiSelect(valuesData as any, updateValue);

  return (
    <MultiSelectInline
      options={options}
      onBlur={onBlur}
      onChange={onChange}
      value={localValue}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      allPrefix={normalizedData ? normalizedData.placeholders : undefined}
      {...restProps}
    />
  );
};

export default React.memo(MultiSelectFilter);
