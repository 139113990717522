import React, { useState } from 'react';
import { Menu, Tooltip, Popconfirm } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { CompanyService } from '@optx/services/api';
import { columns } from '@optx/components/common/table/Companies/columns/virtual-column-config';
// redux
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@features/grid/filter';
import { getViewId } from '@optx/features/grid/view/state/selectors';
// utils
import { mapSortFieldQuery } from '@utils/mapSortFieldQuery';
import { mapSortOrderQuery } from '@utils/mapSortOrderQuery';
import mapFilterToParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandler';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';

const FILE_NAME = 'saved search';

interface RedirectSavedSearchesMenuItemProps {
  hideDropdown?: () => void;
}

const RedirectSavedSearchesMenuItem = ({
  hideDropdown,
  onClick,
  ...restProps
}: MenuItemProps & RedirectSavedSearchesMenuItemProps) => {
  const searchCompanyCount = useSelector(searchCountSelectors.getSearchCount('advancedSearch'));
  const searchHasValues = useSelector(companySearchSelectors.searchHasValues);
  const searchKey = useSelector(searchSelectors.getSearchKey('advancedSearch'));
  const filter = useSelector(filterSelectors.getFilter('advancedSearch'));
  const sortBy = useSelector(companySearchSelectors.getSorting);
  const filtersMap = useSelector(filterSourcesSelectors.getFiltersMap);
  const listType = useSelector(searchSelectors.getListType('advancedSearch'));
  const id = useSelector(getViewId('advancedSearch'));
  const [isLoading, setIsLoading] = useState(false);

  if (!searchHasValues) {
    return null;
  }

  const handleExportCSV = (includeDescription: boolean) => {
    const params = mapFilterToParams(filtersMap, filter);

    const paramsQuery = queryString.stringify(
      {
        ...params,
        ...(searchKey ? { query: searchKey } : {}),
        ...(listType === 'combined' ? { combined_search_id: id } : {}),
      },
      { arrayFormat: 'comma' }
    );

    const parameterFilter = {
      filters: paramsQuery,
      sortFields: queryString.stringify(
        { sort_fields: sortBy.map(mapSortFieldQuery) },
        { arrayFormat: 'comma' }
      ),
      sortOrders: queryString.stringify(
        {
          sort_orders: sortBy.map(mapSortOrderQuery),
        },
        { arrayFormat: 'comma' }
      ),
    };

    setIsLoading(true);

    exportFileCSV(
      FILE_NAME,
      parameterFilter,
      columns,
      CompanyService.exportToCSV,
      includeDescription
    ).then(() => {
      setIsLoading(false);
    });
  };

  const disabled = !searchCompanyCount || searchCompanyCount > COMPANY_CSV_SIZE_LIMIT || isLoading;

  const handleClick = (includeDescription = false) => {
    if (!disabled) {
      handleExportCSV(includeDescription);
      setTimeout(() => {
        hideDropdown && hideDropdown();
      }, 1000);
    }
  };

  //   wrap Menu.Item in span to show tooltip on hovering while Menu.Item is disabled.
  return (
    <Tooltip title="Up to 100K rows" placement="left">
      <span>
        <Popconfirm
          title="Include the description?"
          onConfirm={() => handleClick(true)}
          onCancel={() => handleClick()}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item disabled={disabled} key="export-to-csv" {...restProps}>
            {isLoading ? 'Exporting' : 'Export to .csv'}
          </Menu.Item>
        </Popconfirm>
      </span>
    </Tooltip>
  );
};

export default React.memo(RedirectSavedSearchesMenuItem);
