import React, { useCallback, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// models
import { Gutter } from 'antd/lib/grid/row';
import { CompanyProfile, ScoreGrowth } from '@optx/models/Company';
// redux
import { selectors as fundingSelectors } from '@redux/company/funding-rounds';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
// components
import ProfileInformation from './CompanyInfo/ProfileInformation';
import ProfileGraphs from './CompanyInfo/ProfileGraphs';
import FundingRounds from './FundingRounds';
import Competitors from './Competitors';
import Styles from './ProfileOverview.styles';
import RelationshipMGMTSummary from './RelationshipMGMTSummary/RelationshipMGMTSummary';

interface ProfileOverviewProps {
  company: CompanyProfile;
}

const totalRaisedFormat = (value: string | number | null) => {
  if (value === null) {
    return DEFAULT_CELL_VALUE;
  }

  if (value.toString().indexOf(' (')) {
    return value.toString().split(' (')[0].toString();
  }

  return value.toString();
};

const rowGutter: Gutter | [Gutter, Gutter] | undefined = [
  { xs: 20, sm: 20, lg: 20, xl: 42 },
  { xs: 20, sm: 20, lg: 20, xl: 42 },
];

const ProfileOverview: React.FC<ProfileOverviewProps> = ({ company }) => {
  const dispatch = useDispatch();

  const investmentHistory = useSelector(fundingSelectors.getInvestmentHistory);
  const fundingGraphData = useSelector(fundingSelectors.getFundingRoundsGraph);
  const isFundingGraphLoading = useSelector(fundingSelectors.isLoading);
  const fundingGraphMessage = useSelector(fundingSelectors.getFundingGraphMessage);
  const roundsTotal = useSelector(fundingSelectors.getNumberOfRounds);
  const totalRaised = useSelector(fundingSelectors.getTotalRaised);
  const totalInvestors = useSelector(fundingSelectors.getTotalInvestors);

  const extraFunding = [
    {
      label: 'Total # of rounds',
      value: roundsTotal?.toString(),
    },
    {
      label: 'Total # of investors',
      value: totalInvestors ? totalInvestors.toString() : '0',
    },
    {
      label: 'Total raised',
      value: totalRaisedFormat(totalRaised),
      format: true,
    },
  ];

  const getScoreGrowth = useCallback(
    (payload: ScoreGrowth) => dispatch(scoreGrowthActions.fetchScoreGrowth(payload)),
    [dispatch]
  );

  useEffect(() => {
    getScoreGrowth({
      company_id: company.company_id,
      company_url: company.company_url,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.score, company.score_v3, company.il_optx_score]);

  return (
    <Styles.ProfileOverviewContainer className="profile-overview">
      <div className="profile-info">
        <Row className="profile-overview__row" gutter={rowGutter}>
          <Col span={24} className="profile-information">
            <ProfileInformation company={company} />
          </Col>
          <Col span={24}>
            <RelationshipMGMTSummary company={company} />
            <ProfileGraphs
              company={company}
              fundingGraphData={fundingGraphData}
              isFundingGraphLoading={isFundingGraphLoading}
              fundingGraphMessage={fundingGraphMessage}
              extraFunding={extraFunding}
            />
          </Col>
        </Row>
        <Row gutter={rowGutter} style={{ paddingTop: '42px', paddingBottom: '42px' }}>
          <Col span={24} xl={8}>
            <Competitors competitors={company.competitors} />
          </Col>
          <Col span={24} xl={16}>
            <FundingRounds
              investmentHistory={investmentHistory}
              fundingGraphMessage={fundingGraphMessage}
            />
          </Col>
        </Row>
      </div>
    </Styles.ProfileOverviewContainer>
  );
};

export default ProfileOverview;
