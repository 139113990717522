import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Dictionary, isEqual } from 'lodash';
// models
import Company from '@optx/models/Company';
import {
  TouchesCreateForm,
  AddTouchToCompanyPayload,
  TouchesFinancialPayload,
} from '@models/api/touches';
import {
  CompanyTouchResponse,
  CompanyTouchSuccessResponse,
  ErrorCompanyTouchResponse,
} from '@models/company/CompanyTouch';
import { BaseFilter, FilterGroup } from '@optx/models/filters';
import { PageInfo } from '@optx/models/table/Pagination';
import { CompanyUserContact } from '@models/api/contacts';
import { EditCompanyTouches, FiltersResponse, validationErrorsTypes } from './interfaces';
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { PsgParticipant } from '@models/api/user';
import { FinancialYearsChangeLog } from '@optx/features/edit-all-info/state/interfaces';
//constants
import { TOUCHES_PER_PAGE } from '@features/long-card/company-card';
// services
import { FilterService, CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
import { formatFinalValues } from '@utils/touches';
// redux
import { actions as relationshipManagementActions } from '@redux/company/relationship-management';
import {
  actions as touchSearchActions,
  selectors as touchSearchSelectors,
} from '@redux/company/touches/search';
import { selectors as cardSelectors } from '@features/long-card/company-card';
import { actions as touchesActions } from '@features/company-touches';
import {
  actions as touchesFiltersActions,
  selectors as touchesFiltersSelectors,
} from '@redux/company/touches/filters';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { selectors as editAllInfoSelectors } from '@optx/features/edit-all-info/state';
import * as actions from './actions';
import { selectors as touchSelectors } from '../company-touches';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';

function* initCompanyTouchesDialogSaga(action: PayloadAction<number | undefined>) {
  const psgParticipants: string[] = yield select(touchSelectors.getPsgParticipants);
  const contacts: Partial<CompanyUserContact>[] = yield select(
    touchSelectors.getTouchCompanyContacts
  );

  if (!psgParticipants.length) {
    yield put(actions.getPsgParticipants());
  }

  if (!contacts.length && action.payload) {
    yield put(actions.getTouchCompanyContacts(action.payload));
  }

  yield put(actions.getTouchesFilters());
}

function* toggleCompanyTouchesSaga(action: PayloadAction<EditCompanyTouches>) {
  const isOpen: boolean = yield select(touchSelectors.isOpen);
  const psgParticipants: string[] = yield select(touchSelectors.getPsgParticipants);
  const touchFilters: Dictionary<BaseFilter> | null = yield select(touchSelectors.getTouchFilters);

  if (action.payload.companyId && isOpen) {
    if (!psgParticipants.length) {
      yield put(actions.getPsgParticipants());
    }

    if (!touchFilters) {
      yield put(actions.getTouchesFilters());
    }

    yield put(actions.getTouchFinancialFilters(action.payload.companyId));

    if (!action.payload.company?.contacts?.length) {
      yield put(actions.getTouchCompanyContacts(action.payload.companyId));
    }
  }
}

function* addTouchToCompanySaga(action: PayloadAction<AddTouchToCompanyPayload>) {
  const { touchInfo: touchForm, additionalCompanyInfo, location } = action.payload;

  yield put(customGlobalLoaderActions.toggle({ loading: true }));

  let validAction = true;

  try {
    let errorMessage = touchForm.touchId
      ? 'Failed to edit Touch!'
      : 'Failed to create a new Touch!';

    // throw an error if initiatorId is 0, meaning that
    // the selected initiator should not be able to interact with touches
    if (touchForm.initiatorId === 0 || touchForm.initiatorId === null) {
      yield put(actions.addTouchToCompanyFail(errorMessage));
      NotificationService.error(errorMessage);

      return;
    }

    const res: AxiosResponse<CompanyTouchResponse> = yield call(
      CompanyService.addTouches,
      touchForm as TouchesCreateForm
    );
    let resSchedule: AxiosResponse<CompanyTouchResponse>;

    if (res.data && res.data.success) {
      if (additionalCompanyInfo) {
        yield put(editFieldActions.updateFields(additionalCompanyInfo));
      } else {
        yield put(actions.addTouchToCompanyErrorValidation('noEditFieldErrors'));
      }

      yield put(actions.addTouchToCompanySuccess(res.data.payload as CompanyTouchSuccessResponse));

      const successMessage = touchForm.touchId
        ? 'Touch edited successfully!'
        : 'New Touch added successfully!';

      if (touchForm.scheduleFollow) {
        const touchSchedule = {
          ...touchForm,
          touchDate: touchForm.scheduleTouchDate,
          touchId: null,
          touchType: 'schedule',
          type: touchForm.scheduleType,
          typeId: touchForm.scheduleTypeId,
          subject: touchForm.scheduleSubject,
          textarea: '',
          scheduleFollow: false,
        };

        resSchedule = yield call(CompanyService.addTouches, touchSchedule as TouchesCreateForm);

        if (resSchedule.data && resSchedule.data.success) {
          yield put(
            actions.addTouchToCompanySuccess(
              resSchedule.data.payload as CompanyTouchSuccessResponse
            )
          );
          const successScheduleMessage = 'New Touch added successfully!';
          NotificationService.success(successScheduleMessage);
        } else {
          let errorMessageSchedule = 'Failed to create a new schedule Touch!';
          yield put(actions.addTouchToCompanyFail(errorMessageSchedule));
          NotificationService.error(errorMessageSchedule);
          validAction = false;
        }
      } else {
        NotificationService.success(successMessage);
      }

      const getCompanyId: number | null = yield select(touchSearchSelectors.getCompanyId);
      const pagination: PageInfo = {
        pageNumber: 1,
        pageSize: 25,
      };
      const initiators: string | undefined = yield select(
        touchesFiltersSelectors.getCurrentInitiator
      );
      const currentTouchType: string | undefined = yield select(
        touchesFiltersSelectors.getCurrentTouchType
      );

      yield put(actions.addTouchToCompanyErrorValidation('noTouchErrors'));

      if (getCompanyId && getCompanyId.toString() === touchForm.companyId) {
        const filtersPayload = {
          companyId: getCompanyId,
          currentInitiator: initiators,
          currentType: currentTouchType,
        };

        yield put(touchesFiltersActions.fetchTouchesFilters(filtersPayload));
        yield put(
          touchSearchActions.fetchTouches({
            companyId: getCompanyId,
            pagination: pagination,
            initiatorId: initiators,
            touchType: currentTouchType,
          })
        );

        if (location?.includes('profile')) {
          yield put(relationshipManagementActions.fetchRelationshipManagement(getCompanyId));
        }
      }

      const hasCompanyCard: boolean = yield select(touchSelectors.hasCompanyCard);

      if (hasCompanyCard) {
        const selectedCompanyCard: Company = yield select(cardSelectors.getProfile);

        if (selectedCompanyCard?.company_id) {
          yield put(
            touchesActions.search.fetchTouches({
              companyId: selectedCompanyCard.company_id,
              pagination: { pageSize: TOUCHES_PER_PAGE, pageNumber: 1 },
            })
          );
        }
      }
    } else {
      if ((res.data?.payload as ErrorCompanyTouchResponse).errorMsg) {
        errorMessage = (res.data?.payload as ErrorCompanyTouchResponse).errorMsg;
      }

      yield put(actions.addTouchToCompanyFail(errorMessage));
      NotificationService.error(errorMessage);
      validAction = false;
    }
  } catch (e: any) {
    validAction = false;

    const errorMessage = touchForm.touchId
      ? getErrorMessage(e, 'Failed to edit Touch, Server error!')
      : getErrorMessage(e, 'Create new touch failed, Server error!');

    NotificationService.error(errorMessage);
    yield put(actions.addTouchToCompanyFail(errorMessage));
  }

  const initialFinancialState: Partial<TouchesCreateForm> | null = yield select(
    touchSelectors.getInitialFinancialState
  );
  const financialTouch: Array<CompanyFieldsGroup> = yield select(touchSelectors.getTouchFinancials);
  const financialYearsChangeLog: FinancialYearsChangeLog = yield select(
    editAllInfoSelectors.getFinancialYearsChangeLog
  );

  const initialFinancialData = formatFinalValues(
    { companyId: touchForm.companyId },
    initialFinancialState,
    financialTouch
  );

  const financialData = formatFinalValues(
    touchForm,
    initialFinancialState,
    financialTouch,
    financialYearsChangeLog
  );

  if (validAction) {
    try {
      if (financialData && !isEqual(initialFinancialData, financialData)) {
        const normalizedFinancialData = {
          ...financialData,
          funding: {
            ...financialData.funding,
            capital_raised:
              initialFinancialData?.funding?.capital_raised !== null &&
              financialData?.funding?.capital_raised === null
                ? 0
                : financialData?.funding?.capital_raised,
          },
        };
        const resFinancialInfo: AxiosResponse<TouchesFinancialPayload> = yield call(
          CompanyService.updateCompanyInfo,
          normalizedFinancialData as Partial<TouchesFinancialPayload>
        );

        if (resFinancialInfo.data) {
          const financialSuccessMessage = 'Company Info updated successfully!';
          NotificationService.success(financialSuccessMessage);

          const finacialResponse = {
            ...financialData,
            financials: resFinancialInfo.data.financials,
            funding: resFinancialInfo.data.funding,
          };

          yield put(actions.updateTouchCompanyInfoSuccess(finacialResponse));

          yield put(actions.addTouchToCompanyErrorValidation('noFinancialErrors'));
          yield put(fundingRoundsActions.getCompanyFundingRounds(Number(touchForm.companyId ?? 0)));
        } else {
          const financialErrorMessage = 'Failed to update Company Info!';
          NotificationService.error(financialErrorMessage);
          yield put(actions.addTouchToCompanyFail(financialErrorMessage));
        }
      } else {
        yield put(actions.addTouchToCompanyErrorValidation('noFinancialErrors'));
      }
    } catch (e: any) {
      const errorMessageCatch = getErrorMessage(e, 'Failed to update Company Info!');
      NotificationService.error(errorMessageCatch);
      yield put(actions.addTouchToCompanyFail(errorMessageCatch));
    }
  } else {
    yield put(actions.addTouchToCompanyErrorValidation('noFinancialErrors'));
  }
}

function* getPsgParticipantsSaga() {
  try {
    const res: AxiosResponse<PsgParticipant[]> = yield call(CompanyService.getPsgContacts);

    if (res.data) {
      yield put(actions.getPsgParticipantsSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch PSG user list!';
      yield put(actions.getPsgParticipantsFail(errorMessage));

      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to fetch PSG user list!');
    yield put(actions.getPsgParticipantsFail(errorMessage));

    NotificationService.error(errorMessage);
  }
}

function* getTouchesFiltersSaga() {
  try {
    const res: AxiosResponse<FiltersResponse> = yield call(
      FilterService.getOptions,
      FilterGroup.Touches
    );

    if (res.data) {
      yield put(actions.getTouchesFiltersSuccess(res.data.filters));
    } else {
      let errorMessage = 'Failed to load filters!';

      yield put(actions.getTouchesFiltersFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to load filters!');
    NotificationService.error(errorMessage);
    yield put(actions.getTouchesFiltersFail(errorMessage));
  }
}

function* getTouchCompanyContactsSaga(action: PayloadAction<number>) {
  try {
    const res: AxiosResponse<Array<CompanyUserContact>> = yield call(
      CompanyService.getPrimaryContacts,
      action.payload
    );

    if (res.data) {
      yield put(actions.getTouchCompanyContactsSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch contacts!';
      yield put(actions.getTouchCompanyContactsFail(errorMessage));
      NotificationService.error(errorMessage);
      yield put(customGlobalLoaderActions.toggle({ loading: false }));
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch contacts!');
    yield put(actions.getTouchCompanyContactsFail(errorMessage));
    NotificationService.error(errorMessage);
    yield put(customGlobalLoaderActions.toggle({ loading: false }));
  }
}

function* editTouchSaga(action: PayloadAction<AddTouchToCompanyPayload>) {
  const { touchInfo: touchForm, additionalCompanyInfo } = action.payload;

  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  try {
    const res: AxiosResponse<CompanyTouchResponse> = yield call(
      CompanyService.addTouches,
      touchForm as TouchesCreateForm
    );

    if (res.data && res.data.success) {
      if (additionalCompanyInfo) {
        yield put(editFieldActions.updateFields(additionalCompanyInfo));
      }

      yield put(actions.addTouchToCompanySuccess(res.data.payload as CompanyTouchSuccessResponse));

      const successMessage = 'Touch was edited successfully!';

      NotificationService.success(successMessage);

      const getCompanyId: number | null = yield select(touchSearchSelectors.getCompanyId);
      const pagination: PageInfo = {
        pageNumber: 1,
        pageSize: 25,
      };
      const currentInitiator: string | undefined = yield select(
        touchesFiltersSelectors.getCurrentInitiator
      );
      const currentType: string | undefined = yield select(
        touchesFiltersSelectors.getCurrentTouchType
      );
      yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));

      if (getCompanyId) {
        const filtersPayload = {
          companyId: getCompanyId,
          currentInitiator: currentInitiator,
          currentType: currentType,
        };
        yield put(touchesFiltersActions.fetchTouchesFilters(filtersPayload));

        yield put(
          touchSearchActions.fetchTouches({
            companyId: getCompanyId,
            pagination: pagination,
            initiatorId: currentInitiator,
            touchType: currentType,
          })
        );
      }

      const hasCompanyCard: boolean = yield select(touchSelectors.hasCompanyCard);

      if (hasCompanyCard) {
        const selectedCompanyCard: Company = yield select(cardSelectors.getProfile);

        if (selectedCompanyCard?.company_id) {
          yield put(
            touchesActions.search.fetchTouches({
              companyId: selectedCompanyCard.company_id,
              pagination: { pageSize: TOUCHES_PER_PAGE, pageNumber: 1 },
            })
          );
        }
      }
    } else {
      let errorMessage = 'Failed to edit Touch!';

      if ((res.data?.payload as ErrorCompanyTouchResponse).errorMsg) {
        errorMessage = (res.data?.payload as ErrorCompanyTouchResponse).errorMsg;
      }

      yield put(actions.editTouchFail(errorMessage));
      NotificationService.error(errorMessage);
      yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Edit touch failed, Server error!');
    NotificationService.error(errorMessage);
    yield put(actions.editTouchFail(errorMessage));
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

function* getTouchFinancialFiltersSaga(action: PayloadAction<string>) {
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  try {
    const res: AxiosResponse<CompanyFieldsGroup[]> = yield call(
      CompanyService.getCompanyInfo,
      action.payload
    );

    if (res.data) {
      yield put(actions.getTouchFinancialFiltersSuccess(res.data));
    } else {
      const errorMessage = 'Failed to load financials!';
      yield put(actions.getTouchFinancialFiltersFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to load financials!');
    NotificationService.error(errorMessage);
    yield put(actions.getTouchFinancialFiltersFail(errorMessage));
  }

  yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
}

function* addTouchToCompanySuccessSaga(action: PayloadAction<validationErrorsTypes>) {
  const noErrorsOnSubmitState: boolean = yield select(touchSelectors.getNoErrorsOnSubmitState);

  if (noErrorsOnSubmitState) {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  }
}

function* addTouchToCompanyFailSaga(action: PayloadAction<string>) {
  yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
}

export default function* touchSaga() {
  yield takeLatest(actions.initCompanyTouchesDialog, initCompanyTouchesDialogSaga);
  yield takeLatest(actions.toggleCompanyTouchesDialog, toggleCompanyTouchesSaga);
  yield takeLatest(actions.getTouchCompanyContacts, getTouchCompanyContactsSaga);
  yield takeLatest(actions.getPsgParticipants, getPsgParticipantsSaga);
  yield takeLatest(actions.getTouchesFilters, getTouchesFiltersSaga);
  yield takeLatest(actions.getTouchFinancialFilters, getTouchFinancialFiltersSaga);
  yield takeLatest(actions.addTouchToCompany, addTouchToCompanySaga);
  yield takeLatest(actions.addTouchToCompanyErrorValidation, addTouchToCompanySuccessSaga);
  yield takeLatest(actions.addTouchToCompanyFail, addTouchToCompanyFailSaga);
  yield takeLatest(actions.editTouch, editTouchSaga);
}
