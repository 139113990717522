import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import './style.scss';

interface DropdownDotRemoveProps {
  id: number;
  label?: string;
  handleDeleteItem: (noteId: number) => void;
  handleEditItem?: () => void;
}

const DropdownDotRemove: React.FC<DropdownDotRemoveProps> = ({
  id,
  label,
  handleDeleteItem,
  handleEditItem,
}) => {
  const dropddownMenu = (
    <Menu>
      <Menu.Item key="delete-menu">
        <Button type="text" onClick={() => handleDeleteItem(id)}>
          {label || 'Delete'}
        </Button>
      </Menu.Item>
      {handleEditItem && (
        <Menu.Item key="edit-menu">
          <Button type="text" onClick={() => handleEditItem()}>
            {label || 'Edit'}
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="dropdown-dot-remove">
      <Dropdown trigger={['click']} overlay={dropddownMenu}>
        <Button icon={<MoreOutlined />} />
      </Dropdown>
    </div>
  );
};

export default DropdownDotRemove;
