import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, Dictionary } from 'lodash';
// models
import { UserInformation } from '@models/user';
import { FilterSource } from '@optx/models/filters';
import { CompanyFiltersMeta, SearchSaveResponse } from '@optx/models/search';
import { ColumnDisplay } from '@optx/models/table/Columns';
// constants
import {
  columnDisplayInitialState,
  initialColumnOrder,
  customUIViews,
} from '@optx/constants/table/columnDisplay/company-search';
import appRoutes from '@optx/constants/routes';
// redux
import { gridResetColumnWidthsReducer, gridUpdateColumnWidthsReducer } from '@optx/redux/grid';
import { reducers as columnReducers } from '@optx/features/columns/update-columns';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as companyFiltersActions } from '@redux/company/filters';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import * as actions from './actions';

const initialState: ColumnDisplay = {
  columnDisplay: cloneDeep(columnDisplayInitialState),
  columnOrder: initialColumnOrder,
};

const saveColumnsReducer: CaseReducer<ColumnDisplay, PayloadAction<ColumnDisplay>> = (
  draftState,
  action
) => {
  const { columnDisplay, columnOrder } = action.payload;
  draftState.columnDisplay = columnDisplay;
  draftState.columnOrder = columnOrder;
};

const updateColumnsReducer: CaseReducer<
  ColumnDisplay,
  PayloadAction<{
    columns: string | null;
    columnOrder: string | null;
    pinnedColumns: string | null;
  }>
> = (draftState, action) => {
  const { columns, columnOrder, pinnedColumns } = action.payload;

  columnReducers.updateColumnReducer(
    draftState,
    columns,
    columnOrder,
    pinnedColumns,
    columns !== null,
    pinnedColumns !== null
  );
};

const fetchUserInformationSuccessReducer: CaseReducer<
  ColumnDisplay,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const columns = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.company_display_columns
    : undefined;

  const columnOrder = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.company_column_order
    : undefined;

  const pinnedColumns = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.company_pinned_columns
    : undefined;

  columnReducers.updateColumnReducer(
    draftState,
    columns,
    columnOrder,
    pinnedColumns,
    typeof columns === 'string',
    typeof pinnedColumns === 'string'
  );

  gridUpdateColumnWidthsReducer(draftState, action.payload.settings.column_widths_advanced_search);
};

const fetchCompanyFiltersSuccessReducer: CaseReducer<
  ColumnDisplay,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  const settings = action.meta.settings;
  const customUIViewId = action.meta.settings?.default_view ?? '';

  let columns = null;
  let columnOrder = null;
  let pinnedColumns = null;

  if (customUIViewId) {
    columns = customUIViews[customUIViewId].columns;
    columnOrder = customUIViews[customUIViewId].column_order;
    pinnedColumns = customUIViews[customUIViewId].pinned_columns;
  } else if (action.meta.defaultViews.length) {
    columns = action.meta.defaultViews[0].columns;
    columnOrder = action.meta.defaultViews[0].column_order;
    pinnedColumns = action.meta.defaultViews[0].pinned_columns;
  }

  if (columns !== null || columnOrder !== null || pinnedColumns !== null) {
    columnReducers.updateColumnReducer(
      draftState,
      columns,
      columnOrder,
      pinnedColumns,
      columns !== null && typeof settings?.session_settings.company_display_columns !== 'string',
      pinnedColumns !== null &&
        typeof settings?.session_settings.company_pinned_columns !== 'string'
    );
  }
};

const initializeSavedSearchesAndViewsSuccessReducer: CaseReducer<
  ColumnDisplay,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    { isAnalyst?: boolean }
  >
> = (draftState, action) => {
  const allSavedSearches = action.payload as Array<SearchSaveResponse>;
  const { isAnalyst } = action.meta;

  const query = window.location.search ? window.location.search.replace('?', '') : undefined;
  const listId = query?.split('=')[1];
  const list = query?.split('=')[0] === 'saved_search_id';
  const searchPathname = isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

  if (list && window.location.pathname === searchPathname) {
    const savedSearch = allSavedSearches.find(search => search.unique_id.toString() === listId);

    if (savedSearch) {
      const columns = savedSearch.columns;
      const columnOrder = savedSearch.column_order;
      const pinnedColumns = savedSearch.pinned_columns;

      columnReducers.updateColumnReducer(
        draftState,
        columns,
        columnOrder,
        pinnedColumns,
        typeof columns === 'string',
        typeof pinnedColumns === 'string'
      );
    }
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.saveColumns, saveColumnsReducer)
    .addCase(actions.updateColumns, updateColumnsReducer)
    .addCase(actions.saveColumnWidth, columnReducers.saveColumnWidthReducer)
    .addCase(actions.resetColumnWidths, gridResetColumnWidthsReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(companyFiltersActions.fetchCompanyFiltersSuccess, fetchCompanyFiltersSuccessReducer)
    .addCase(
      companyFiltersActions.fetchCompanyFiltersForNotificationsSuccess,
      fetchCompanyFiltersSuccessReducer
    )
    .addCase(
      savedSearchesActions.initializeSavedSearchesAndViewsSuccess,
      initializeSavedSearchesAndViewsSuccessReducer
    )
);

export default reducer;
