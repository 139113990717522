import { createSelector } from 'reselect';
import { featureName } from '../featureName';
import { AddCompanyState } from './interfaces';

const selectFeatureState = (state: any) => state[featureName] as AddCompanyState;

export const getSimilarCompanies = createSelector(
  selectFeatureState,
  state => state.similarCompanies
);

export const getAddedCompany = createSelector(selectFeatureState, state => state.addedCompany);

export const isLoading = createSelector(selectFeatureState, state => state.loading);

export const getUrlData = createSelector(selectFeatureState, state => state.urlData);
