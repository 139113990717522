import React from 'react';
import { useDispatch } from 'react-redux';
// redux
import { actions as userInformationActions } from '@redux/user/information';
// components
import ToggleButton from '@components/common/molecules/buttons/ToggleButton';

interface QuickFiltersToggleProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const QuickFiltersToggle: React.FC<QuickFiltersToggleProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      userInformationActions.updateUserSettingsForUserList({
        user_list_show_quick_filters: !open,
      })
    );

    setOpen(!open);
  };

  const title = `${open ? 'Hide' : 'Show'} Quick Filters`;

  return <ToggleButton onClick={handleClick} hide={open} title={title} />;
};

export default React.memo(QuickFiltersToggle);
