import { createReducer, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { Dictionary, isEqual } from 'lodash';
// models
import { SortByRule } from '@models/table/sorting';
import { SearchPayload } from '@models/api/contacts';
import Company from '@models/Company';
import { FilterSource } from '@models/filters';
import { CompanyFiltersMeta, SearchSaveResponse } from '@models/search';
import { GridPayload, GridPayloadMain } from '@models/grid';
import { UserInformation } from '@models/user';
import { SortState } from './interfaces';
// constants
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
import appRoutes from '@constants/routes';
// utils
import { parseSorting } from '@utils/filters/parseSorting';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { actions as companyFiltersActions } from '@redux/company/filters';
import { actions as listsActions } from '@redux/lists/details';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import * as actions from './actions';
import { actions as searchActions } from '@features/grid/search';
import { ResetFilter } from '@optx/features/grid/search/state/interfaces';

const initialState: SortState = {
  multiSort: false,
  sortBy: COMPANY_DEFAULT_SORT,
  loading: false,
};

export const changeSortReducer: CaseReducer<SortState> = draftState => {
  draftState.loading = true;

  if (draftState.isSimilarCompaniesSorting) {
    draftState.isSimilarCompaniesSorting = false;
  }
};

export const resetSortSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<Array<SortByRule<any>> | undefined>
> = (draftState, action) => {
  draftState.multiSort = false;
  draftState.sortBy = action.payload ? action.payload : COMPANY_DEFAULT_SORT;
  draftState.loading = false;
};

const similarCompaniesSortingReducer: CaseReducer<SortState, PayloadAction<boolean>> = (
  draftState,
  action
) => {
  draftState.isSimilarCompaniesSorting = action.payload;

  if (action.payload === false) {
    draftState.sortBy = COMPANY_DEFAULT_SORT;
  }
};

export const createMultiSortReducer =
  (
    initState = initialState
  ): CaseReducer<SortState, PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>> =>
  (draftState, action) => {
    const multiSort = action.payload;

    draftState.multiSort = multiSort;

    if (!multiSort) {
      draftState.sortBy = initState.sortBy;
    }

    if (draftState.isSimilarCompaniesSorting) {
      draftState.isSimilarCompaniesSorting = false;
    }
  };

export const multiSortReducer = createMultiSortReducer(initialState);

export const searchCompaniesSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<Company[], any, GridPayload<Partial<SearchPayload>>>
> = (draftState, action) => {
  const { data, gridKey } = action.meta;

  if (gridKey === 'advancedSearch') {
    if (!isEqual(draftState.sortBy, data.sortBy)) {
      draftState.sortBy = data.sortBy || [];
    }

    if (draftState.isSimilarCompaniesSorting) {
      draftState.sortBy = [];
    }
  }
};

// clear search
export const clearSearchSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<GridPayload<boolean>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  if (draftState.isSimilarCompaniesSorting) {
    draftState.isSimilarCompaniesSorting = false;
    draftState.sortBy = COMPANY_DEFAULT_SORT;
  }

  if (gridKey === 'advancedSearch') {
    draftState.multiSort = data;
    draftState.loading = false;
  }
};

export const resetFilterReducer: CaseReducer<
  SortState,
  PayloadAction<GridPayloadMain<ResetFilter>>
> = (draftState, action) => {
  const hasSimilarCompanies = action.payload.data.selectedTag.label === 'Similar Companies';

  if (hasSimilarCompanies) {
    draftState.sortBy = COMPANY_DEFAULT_SORT;
  }
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.company_sorting;

  if (sorting) {
    const { sortBy, isMultiSort } = parseSorting(sorting);
    draftState.sortBy = sortBy;
    draftState.multiSort = isMultiSort;
    draftState.loading = false;
  } else if (sorting === '') {
    draftState.sortBy = [];
    draftState.multiSort = false;
  }
};

export const updateUserSettingsSuccessReducer: CaseReducer<SortState> = draftState => {
  draftState.loading = false;
};

const fetchCompanyFiltersSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  if (action.meta.defaultViews.length && !action.meta.settings?.session_settings?.company_sorting) {
    const { sortBy } = parseSorting(action.meta.defaultViews[0].search_criteria);

    if (sortBy.length) {
      draftState.sortBy = sortBy;
    }

    if (sortBy.length > 1) {
      draftState.multiSort = true;
    }
  }
};

export const loadCompanyListSuccessReducer: CaseReducer<SortState> = draftState => {
  draftState.multiSort = false;
  draftState.sortBy = COMPANY_DEFAULT_SORT;
};

const initializeSavedSearchesAndViewsSuccessReducer: CaseReducer<
  SortState,
  PayloadAction<
    Array<SearchSaveResponse> | Dictionary<SearchSaveResponse>,
    any,
    { isAnalyst?: boolean }
  >
> = (draftState, action) => {
  const allSavedSearches = action.payload as Array<SearchSaveResponse>;
  const { isAnalyst } = action.meta;

  const query = window.location.search ? window.location.search.replace('?', '') : undefined;
  const listId = query?.split('=')[1];
  const list = query?.split('=')[0] === 'saved_search_id';
  const searchPathname = isAnalyst ? appRoutes.advancedSearch : appRoutes.home;

  if (list && window.location.pathname === searchPathname) {
    const savedSearch = allSavedSearches.find(search => search.unique_id.toString() === listId);

    if (savedSearch) {
      const { sortBy } = parseSorting(savedSearch.search_criteria);

      if (sortBy.length) {
        draftState.sortBy = sortBy;
      }

      if (sortBy.length > 1) {
        draftState.multiSort = true;
      }
    }
  }

  draftState.loading = false;
};

const reducer = createReducer<SortState>(initialState, builder =>
  builder
    .addCase(actions.changeSortAction, changeSortReducer)
    .addCase(actions.multiSortAction, multiSortReducer)
    .addCase(actions.resetSortSuccessAction, resetSortSuccessReducer)
    .addCase(actions.similarCompaniesSortingAction, similarCompaniesSortingReducer)
    .addCase(searchActions.searchCompaniesSuccess, searchCompaniesSuccessReducer)
    .addCase(searchActions.clearSearchSuccess, clearSearchSuccessReducer)
    .addCase(searchActions.resetSearchSuccess, clearSearchSuccessReducer)
    .addCase(searchActions.resetFilter, resetFilterReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(userInformationActions.updateUserSettingsSuccess, updateUserSettingsSuccessReducer)
    .addCase(
      userInformationActions.updateUserSettingsFromAutoSaveSuccess,
      updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForSSListSuccess,
      updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForFavoritesListSuccess,
      updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForUserListSuccess,
      updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForSavedContactSearchesSuccess,
      updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForSuggestedSearchesSuccess,
      updateUserSettingsSuccessReducer
    )
    .addCase(companyFiltersActions.fetchCompanyFiltersSuccess, fetchCompanyFiltersSuccessReducer)
    .addCase(
      companyFiltersActions.fetchCompanyFiltersForNotificationsSuccess,
      fetchCompanyFiltersSuccessReducer
    )
    // Load company list success.
    .addCase(listsActions.loadCompanyListSuccess, loadCompanyListSuccessReducer)
    .addCase(
      savedSearchesActions.initializeSavedSearchesAndViewsSuccess,
      initializeSavedSearchesAndViewsSuccessReducer
    )
);

export default reducer;
