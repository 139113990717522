import { SelectedCompaniesKeys } from '@models/bulkActions';
import {
  EquityTouchKeys,
  EquityTouchContactKeys,
  EquityTouchContactForm,
} from '@models/equityTouch';

/* list of fields to be displayed within a container
   this model is used for positioning the fields on the page and determine their width
   each main array item represents the fields to be displayed on a single row
   and the items inside the secondary array are the fields to be displayed on that row
   empty string is used when there is only one field on a row that should take half the width
*/
export const companyInfo = [
  ['Company Name', 'Company Type'],
  ['addressLine1', 'Website'],
  ['City', 'State', 'Country'],
  ['Sector', 'Sub-Sector'],
  ['Product Category'],
  ['Source', ''],
  ['Description Source'],
  ['Description'],
] as Array<Array<EquityTouchKeys>>;

// same as above
export const psgContext = [
  ['Deal Team Lead', 'Fund'],
  ['Groups', ''],
  ['Equity Check', 'Stage'],
  ['Rank', 'Website Global Rank'],
  ['Website US Rank', 'FTEs'],
  ['Funding', 'Investors'],
  ['Add-On For', 'Add-On'],
  ['Rationale'],
] as Array<Array<EquityTouchKeys>>;

export const sector = [['Sector', '']] as Array<Array<EquityTouchKeys>>;

export const BULK_ACTIONS_FIELDS: SelectedCompaniesKeys[] = [
  'company_id',
  'company_url',
  'raw_url',
  'company_name',
  'stage',
  'pipeline_rank',
  'company_owner',
  'company_owner_id',
  'addon',
  'addon_bool',
  'is_software',
  'is_interesting',
  'score',
  'score_v3',
  'is_in_et',
  'cs_tags',
  'company_type',
  'num_employees',
  'street',
  'city',
  'state',
  'country',
  'sector',
  'sub_sector',
  'product_category',
  'source',
  'company_description',
  'addon_to',
  'sw_website_rank',
  'sw_website_rank_per_country',
  'contacts',
  'year_founded',
  'location',
  'url_status',
  'lead_source',
  'senior_advisor_thesis',
  'asking_amount',
];

export const BULK_ACTIONS_CONTACT_FIELDS: EquityTouchContactKeys[] = [
  'first_name',
  'last_name',
  'full_name',
  'title',
  'additional_phones',
  'phones',
  'phone',
  'phone_type',
  'email',
  'linkedin',
  'primary_contact',
  'tenure_start_date',
  'tenure_end_date',
  'person_id',
  'company_id',
  'psg_function',
  'slack_channel',
  'portfolio_executive',
];

export const EQT_CONTACT_FORM_INITIAL_STATE: EquityTouchContactForm = {
  first_name: '',
  last_name: '',
  title: '',
  additional_phones: [{ phone_number: '', phone_type_id: undefined }],
  tenure_start_date: '',
  tenure_end_date: '',
  linkedin: '',
  primary_contact: false,
  emails: [{ email: '' }],
  email: null,
  portfolio_executive: false,
};

export const AUTOSAVE_TIMER_DURATION = 1500;
