import React, { useState, useContext, Context } from 'react';
import { Typography } from 'antd';
import { OptionsType } from 'react-select';
import { debounce } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
import { BulkEditFieldContext } from '@optx/models/bulkActions';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { COMPANY_PRODUCT_CATEGORY_ENDPOINT } from '@constants/asyncEndpoints';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';

interface EditProductCategoryProps {
  context: Context<BulkEditFieldContext>;
}

export const EditProductCategory: React.FC<EditProductCategoryProps> = ({ context }) => {
  const { setSelectedData } = useContext(context);
  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_PRODUCT_CATEGORY_ENDPOINT });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length > 2) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  return (
    <>
      <Typography.Text className="ant-modal-title">Select Product Category</Typography.Text>

      <AsyncMultiSelect
        className="addon--multiselect-async bulk-action-multiselect"
        onChange={options => {
          setSelectedData(options as SelectOption[]);
        }}
        loadOptions={getLoadOptionsDelay}
        defaultOptions={fetchedOptions}
        loadingMessage={({ inputValue }) => (inputValue.length > 2 ? 'Loading...' : 'Begin typing')}
        menuIsOpen
        maxMenuHeight={200}
      />
    </>
  );
};
