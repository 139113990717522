import { each, cloneDeep } from 'lodash';
import { ColumnType } from 'antd/lib/table';
// models
import { ColumnKeys, ColumnDisplay, CompanyColumnDisplay } from '@optx/models/table/Columns';
import { SortByRule } from '@optx/models/table/sorting';
import Company from '@optx/models/Company';
import { CompanyColumnType } from '@components/common/table/Companies/columns/virtual-column-config';
import { YEARS_LABELS } from '@optx/constants/search';

/**
 * return a string list of visible columns
 * @param columns columns object
 */
export const getVisibleColumns = (payload: ColumnDisplay) => {
  const { columnDisplay, columnOrder } = payload;

  return columnOrder.filter(columnId => columnDisplay[columnId].visible).join(',');
};

/**
 * get pinned columns, based on column data object and return them as string
 * @param {CompanyColumnDisplay} columns object with column data
 */
export const getPinnedColumns = (columns: CompanyColumnDisplay) =>
  Object.keys(columns)
    .filter(columnId => columns[columnId as ColumnKeys].pinned)
    .join(',');

/**
 * return the unpinned columns as an array of column ids
 * @param {CompanyColumnDisplay} columnDisplay object with column data
 * @param {ColumnKeys[]} columnOrder array with the order of the columns
 */
export const mapSortableColumns = (
  columnDisplay: CompanyColumnDisplay<any>,
  columnOrder: string[],
  defaultOptxScore: string
) =>
  columnOrder
    .filter(columnId => {
      if (columnId === 'score' || columnId === 'score_v3') {
        if (defaultOptxScore === 'il') {
          return null;
        }

        return !columnDisplay[columnId].pinned;
      }

      if (columnId === 'il_optx_score') {
        if (defaultOptxScore === 'il') {
          return !columnDisplay[columnId].pinned;
        }

        return null;
      }

      return !columnDisplay[columnId].pinned;
    })
    .map(columnId => ({ ...columnDisplay[columnId] }));

/**
 * return the pinned columns as an array of column ids
 * @param {CompanyColumnDisplay} columnDisplay object with column data
 * @param {ColumnKeys[]} columnOrder array with the order of the columns
 */
export const mapPinnedColumns = (
  columnDisplay: CompanyColumnDisplay,
  columnOrder: ColumnKeys[],
  defaultOptxScore: string
) =>
  columnOrder
    .filter(columnId => {
      if (columnId === 'score' || columnId === 'score_v3') {
        if (defaultOptxScore === 'il') {
          return null;
        }

        return columnDisplay[columnId].pinned;
      }

      if (columnId === 'il_optx_score') {
        if (defaultOptxScore === 'il') {
          return columnDisplay[columnId].pinned;
        }

        return null;
      }

      return columnDisplay[columnId].pinned;
    })
    .map(columnId => ({ ...columnDisplay[columnId] }));

/**
 * create new column data object based on a string with the new visible columns
 * @param {CompanyColumnDisplay} columnDisplay initial column data
 * @param {string} columnVisibility string with visible columns
 * @param {string} pinnedColumns optional string with pinned columns
 */
export const getNewColumnDisplay = (
  columnDisplay: CompanyColumnDisplay,
  columnVisibility: string,
  pinnedColumns?: string
) => {
  const newColumnDisplay: CompanyColumnDisplay | {} = {};
  Object.keys(columnDisplay).forEach(columnId => {
    // @ts-ignore
    newColumnDisplay[columnId as ColumnKeys] = {
      ...columnDisplay[columnId as ColumnKeys],
      // set property visible to true if column is saved as visible or
      // if pinned columns weren't saved, but the column is currently pinned
      visible:
        columnVisibility?.split(',').includes(columnId) ||
        (pinnedColumns === undefined && columnDisplay[columnId as ColumnKeys].pinned) ||
        false,
      ...(typeof pinnedColumns === 'string' && {
        pinned: pinnedColumns.includes(columnId) || false,
      }),
    };
  });

  return newColumnDisplay as CompanyColumnDisplay;
};

/**
 * Generates a dynamic column key based on the specified column type and year type.
 *
 * This function constructs a string key that combines the column type with a year value
 * derived from the current date. The year value can represent the current year, the next year,
 * or one of the past two years.
 *
 * @param {('growth' | 'arr' | 'ebitda')} columnType - The type of column for which the key is being generated.
 * @param {('current' | 'future' | 'past 1 year' | 'past 2 years')} yearType - The year type indicating the time frame
 *        relative to the current year.
 * @returns {string} A string representing the dynamic column key in the format `${columnType}_${year}_value`.
 *
 * @example
 * // Returns "growth_2023_value" if the current year is 2023
 * generateDynamicColumnKeys('growth', 'current');
 *
 * @example
 * // Returns "arr_2024_value" if the current year is 2023
 * generateDynamicColumnKeys('arr', 'future');
 *
 * @example
 * // Returns "ebitda_2022_value" if the current year is 2023
 * generateDynamicColumnKeys('ebitda', 'past 1 year');
 */
export const generateDynamicColumnKeys = (
  columnType: 'growth' | 'arr' | 'ebitda',
  yearType: 'current' | 'future' | 'past 1 year' | 'past 2 years'
) => {
  const year = YEARS_LABELS[yearType];

  return `${columnType}_${year}_value`;
};

/**
 * Generates a dynamic visibility configuration for columns based on the provided initial state.
 *
 * This function takes an initial state of column display settings and returns a new configuration
 * where specific columns ('arr', 'ebitda', 'growth') are set to be visible. The keys for these
 * columns are dynamically generated using the `generateDynamicColumnKeys` function.
 *
 * @param {CompanyColumnDisplay} clonedColumnDisplayInitialState - The initial state of the column display settings.
 * @returns {object} A new column display configuration with updated visibility settings.
 */
export const generateDynamicVisiblityColumns = (
  clonedColumnDisplayInitialState: CompanyColumnDisplay
) => {
  return {
    [`${generateDynamicColumnKeys('arr', 'current')}`]: {
      ...clonedColumnDisplayInitialState[
        `${generateDynamicColumnKeys('arr', 'current')}` as keyof CompanyColumnDisplay
      ],
      visible: true,
    },
    [`${generateDynamicColumnKeys('ebitda', 'current')}`]: {
      ...clonedColumnDisplayInitialState[
        `${generateDynamicColumnKeys('ebitda', 'current')}` as keyof CompanyColumnDisplay
      ],
      visible: true,
    },
    [`${generateDynamicColumnKeys('growth', 'current')}`]: {
      ...clonedColumnDisplayInitialState[
        `${generateDynamicColumnKeys('growth', 'current')}` as keyof CompanyColumnDisplay
      ],
      visible: true,
    },
  };
};

/**
 * if a column's width has been changed return it's id
 * @param {ColumnType<any>[]} columns current column array data
 * @param {ColumnType<any>[]} defaultColumns initial column array data
 */
export const getResizedColumn = (columns: ColumnType<any>[], defaultColumns: ColumnType<any>[]) => {
  let resizedColumnId: undefined | ColumnKeys;

  each(columns, column => {
    const defaultColumn = defaultColumns.find(
      defaultColumn => defaultColumn.dataIndex === column.dataIndex
    );

    if (defaultColumn && column.width !== defaultColumn.width) {
      resizedColumnId = column.dataIndex as ColumnKeys;

      return false;
    }

    return true;
  });

  return resizedColumnId;
};

export const getNewSorting = (
  sorting: SortByRule<Company>[],
  columnData: ColumnDisplay,
  columns: CompanyColumnType[]
) => {
  const { columnDisplay, columnOrder } = columnData;

  let newSort: SortByRule<Company> | undefined;

  if (sorting.length === 1 && !columnDisplay[sorting[0].id as ColumnKeys].visible) {
    if (sorting[0].id !== 'company_name' && columnDisplay.company_name.visible) {
      newSort = {
        id: 'company_name',
        desc: false,
      };
    } else {
      const sortableColumns = columns
        .filter(column => column.sorter)
        .map(column => column.dataIndex);
      const firstOrderableColumnId = columnOrder.find(
        columnId => columnDisplay[columnId].visible && sortableColumns.includes(columnId)
      );

      if (firstOrderableColumnId) {
        newSort = {
          id: firstOrderableColumnId,
          desc: false,
        };
      }
    }
  }

  return newSort;
};

/**
 * restore columns to initial state, but keep modified column widths
 * @param columns current column state
 * @param initialState initial column state
 */
export const restoreColumns = (
  columns: CompanyColumnDisplay<any>,
  initialState: CompanyColumnDisplay<any>
) => {
  const newColumns = cloneDeep(initialState);

  Object.entries(newColumns)
    .filter(([columnId, columnData]) => columnData.width !== columns[columnId as ColumnKeys].width)
    .forEach(([columnId]) => {
      newColumns[columnId as ColumnKeys].width = columns[columnId as ColumnKeys].width;
    });

  return newColumns;
};
