import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Card, Col, Row } from 'antd';
import numeral from 'numeral';
// models
import { CompanyProfile, ProductCategory } from '@optx/models/Company';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import globalConfig from '@optx/constants/config';
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
import { CompanyPageOverviewPendo } from '@optx/constants/pendoActions';
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
// services
import { CompanyService, UserService } from '@optx/services/api';
// utils
import { getMostRecentYear } from '@optx/utils/option';
import { formatAddon, formatValueToMillions } from '@optx/utils/format';
import { validateField } from '@optx/utils/editFields';
import { getDateColor } from '@utils/text';
import { formatNumber } from '@utils/helpers';

// redux
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
// components
import {
  EditFieldCheckBoxSelectAsync,
  EditFieldRadio,
  EditFieldSingleSelect,
  EditFieldSingleSelectText,
  EditFieldFinancial,
  EditFieldRadioRationale,
  EditFieldMultiSelect,
  EditFieldMultiSelectAsyncProductCategory,
  EditFieldMultiSelectAsyncCreatable,
  EditFieldSingleSelectOther,
} from '@optx/features/company/edit-fields/components';
import EditFieldDate from '@optx/features/company/edit-fields/components/EditFieldDate';
import { MultipleDate } from '@components/common/MultipleDate';
import { KeyInformationSectionRow } from './KeyInformationSectionRow';
import ProfileInBusinessCheckbox from './CompanyContext/ProfileInBusinessCheckbox';
import { ProfileActiveSwitch } from './CompanyContext';
// styles
import './styles.scss';

interface ProfileInformationProps {
  company: CompanyProfile;
}

const ProfileInformation: React.FC<ProfileInformationProps> = ({ company }) => {
  const revenueList = company.revenue_list;
  const ebitdaList = company.ebitda_list;
  const revenueLabel = `Revenue ${getMostRecentYear(revenueList) || ''}`;
  const ebitdaLabel = `EBITDA ${getMostRecentYear(ebitdaList) || ''}`;

  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);
  const ebitdaOptions = useSelector(getFinancialFilterOptions('ebitda_numeric'));
  const revenueOptions = useSelector(getFinancialFilterOptions('last_rev_update_amount'));

  const keyInformationTitle = (
    <div className="d-flex">
      <span className="flex-grow-1">Key Information</span>
      <ProfileActiveSwitch company={company} />
    </div>
  );

  const getPopupContainer = (trigger: HTMLElement): HTMLElement => {
    return trigger.parentNode?.parentNode as HTMLElement;
  };

  return (
    <Card title={keyInformationTitle} className="profile-information__card profile-box">
      <Row className="profile-information__section-title">PSG CONTEXT</Row>

      <Row>
        <Col className="profile-information__label">Stage</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              fieldName="Stage"
              fieldKey="stage"
              value={company.stage === 'None' ? DEFAULT_EMPTY_VALUE : company.stage}
              record={company}
              service={CompanyService.updateCompanyData}
              successMessage="Company stage updated successfully!"
              errorMessage="Company stage update failed, Server error!"
              isFromGrid={false}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.stage}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">{company.stage || DEFAULT_EMPTY_VALUE}</Col>
        )}
      </Row>
      <Row className="profile-information__pipeline-rank">
        <Col className="profile-information__label">Pipeline Rank</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              value={company.pipeline_rank ? company.pipeline_rank : DEFAULT_EMPTY_VALUE}
              record={company}
              isFromGrid={false}
              service={CompanyService.updateCompanyData}
              successMessage="Pipeline rank updated successfully!"
              errorMessage="Pipeline rank update failed, Server error!"
              fieldName="Pipeline Rank"
              fieldKey="rank"
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.rank}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">
            {company.pipeline_rank || DEFAULT_EMPTY_VALUE}
          </Col>
        )}
      </Row>
      <Row className="profile-information__owner">
        <Col className="profile-information__label">Company Owner</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              value={company.company_owner}
              record={company}
              isFromGrid={false}
              service={CompanyService.updateCompanyData}
              successMessage="Company owner updated successfully!"
              errorMessage="Company owner update failed, Server error!"
              fieldName="Company Owner"
              fieldKey="deal_team_leader"
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.owner}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">{company.company_owner || 'Unowned'}</Col>
        )}
      </Row>
      <Row>
        <Col className="profile-information__label">PSG Fit</Col>
        <Col className="profile-information__edit">
          {isAdminOrAnalyst ? (
            <EditFieldRadioRationale
              record={company}
              value={company.is_interesting}
              isFromGrid={false}
              service={UserService.updateFitEvaluation}
              successMessage="Company PSG fit updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="PSG Fit"
              destroyOnHide
              actionPendo={CompanyPageOverviewPendo.fit}
            />
          ) : (
            <EditFieldRadio
              record={company}
              value={company.is_interesting}
              isFromGrid={false}
              service={UserService.updateFitEvaluation}
              successMessage="Company PSG fit updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="PSG Fit"
              actionPendo={CompanyPageOverviewPendo.fit}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Date Presented</Col>
        <Col className="profile-information__edit">
          <EditFieldDate
            record={company}
            value={company.date_presented}
            fieldKey="date_presented"
            isFromGrid={false}
            service={CompanyService.updateDatePresented}
            successMessage="Success updating company presentation!"
            fieldName="Date Presented"
            destroyOnHide
            format={globalConfig.short_date.DATE_FORMAT}
            actionPendo={CompanyPageOverviewPendo.date}
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">PSG Platform Company</Col>
        <Col className="profile-information__edit">
          <EditFieldRadio
            value={company.is_portfolio}
            record={company}
            fieldName="PSG Platform"
            service={CompanyService.updatePortfolio}
            successMessage="PSG platform company successfully updated!"
            errorMessage="Error updating company portfolio!"
            isFromGrid={false}
            actionPendo={CompanyPageOverviewPendo.psgPlatformCompany}
          />
        </Col>
      </Row>
      <Row className="profile-information__addon">
        <Col className="profile-information__label">Add-on</Col>
        <Col className="profile-information__value">
          {company.source_tag.includes('et') ? (
            <EditFieldCheckBoxSelectAsync
              fieldName="Add-on"
              record={company}
              value={company.addon}
              service={CompanyService.updateAddon}
              successMessage="Add-on updated successfully!"
              errorMessage=""
              isFromGrid={false}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.addOn}
            />
          ) : (
            formatAddon(company.addon)
          )}
        </Col>
      </Row>
      <Row className="profile-information__addon">
        <Col className="profile-information__label">Add-on for</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldCheckBoxSelectAsync
              fieldName="Add-on"
              record={company}
              value={company.addon}
              type="company"
              service={CompanyService.updateAddon}
              successMessage="Add-on updated successfully!"
              errorMessage=""
              isFromGrid={false}
              hasAddonLink={true}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.addOnFor}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">{formatAddon(company.addon)}</Col>
        )}
      </Row>
      <Row className="profile-information__lead-source">
        <Col className="profile-information__label">Lead Source</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              fieldName="Lead Source"
              fieldKey="lead_source"
              value={company.lead_source === 'None' ? DEFAULT_EMPTY_VALUE : company.lead_source}
              record={company}
              service={CompanyService.updateCompanyData}
              successMessage="Lead Source updated successfully!"
              errorMessage="Lead Source update failed, Server error!"
              isFromGrid={false}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.leadSource}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">
            {company.lead_source || DEFAULT_EMPTY_VALUE}
          </Col>
        )}
      </Row>
      <Row className="profile-information__senior-advisor">
        <Col className="profile-information__label">Senior Advisor Thesis</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              fieldName="Senior Advisor Thesis"
              fieldKey="senior_advisor_thesis"
              record={company}
              isFromGrid={false}
              service={CompanyService.updateCompanyData}
              successMessage="Senior Advisor Thesis updated successfully!"
              errorMessage="Senior Advisor Thesis update failed, Server error!"
              value={
                company.senior_advisor_thesis === 'None'
                  ? DEFAULT_EMPTY_VALUE
                  : company.senior_advisor_thesis
              }
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.seniorAdvisorThesis}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">
            {company.senior_advisor_thesis || DEFAULT_EMPTY_VALUE}
          </Col>
        )}
      </Row>
      <Row className="profile-information__senior-deal-team">
        <Col className="profile-information__label">Senior Deal Team Lead</Col>
        <Col className="profile-information__edit senior-deal-team">
          <EditFieldMultiSelect
            fieldName="Senior Deal Team Lead"
            record={company}
            isFromGrid={false}
            service={UserService.updateSeniorDealTeamLead}
            successMessage="Senior Deal Team Lead updated successfully!"
            errorMessage="Senior Deal Team Lead update failed, Server error!"
            value={company.senior_deal_team_lead}
            destroyOnHide={true}
            getPopupContainer={getPopupContainer}
            actionPendo={CompanyPageOverviewPendo.seniorDealTeamLead}
          />
        </Col>
      </Row>
      <Row className="profile-information__in-business">
        <Col className="profile-information__label">In Business</Col>
        <Col className="profile-information__value">
          <ProfileInBusinessCheckbox
            company={company}
            actionPendo={CompanyPageOverviewPendo.inBusiness}
          />
        </Col>
      </Row>

      <Row className="profile-information__ops">
        <Col className="profile-information__label">OPS Team </Col>
        <Col className="profile-information__edit ops-team">
          <EditFieldMultiSelect
            record={company}
            fieldName="OPS Team"
            isFromGrid={false}
            service={UserService.updateOpsTeam}
            value={company.ops_team}
            destroyOnHide={true}
            getPopupContainer={getPopupContainer}
            actionPendo={CompanyPageOverviewPendo.opsTeam}
          />
        </Col>
      </Row>
      <Row className="profile-information__ops">
        <Col className="profile-information__label">IC Status Date</Col>
        <Col className="profile-information__edit ops-team">
          <EditFieldDate
            value={company.ic_status_date!}
            fieldName="IC Status Date"
            fieldKey="ic_status_date"
            service={UserService.updateCompanyInfo}
            successMessage="IC Status Date updated successfully!"
            errorMessage="IC Status Date update failed, Server error!"
            isFromGrid={false}
            record={company}
            destroyOnHide
            getPopupContainer={getPopupContainer}
            format={globalConfig.short_date.DATE_FORMAT}
            actionPendo={CompanyPageOverviewPendo.icStatusDate}
          />
        </Col>
      </Row>
      <Row className="profile-information__ops">
        <Col className="profile-information__label">Expected Close Date</Col>
        <Col className="profile-information__edit ops-team">
          <EditFieldDate
            value={company.expected_close_date!}
            fieldName="Expected Close Date"
            fieldKey="expected_close_date"
            service={UserService.updateCompanyInfo}
            isFromGrid={false}
            successMessage="Expected Close Date updated successfully!"
            errorMessage="Expected Close Date update failed, Server error!"
            record={company}
            getPopupContainer={getPopupContainer}
            destroyOnHide
            format={globalConfig.short_date.DATE_FORMAT}
            actionPendo={CompanyPageOverviewPendo.expectedCloseDate}
          />
        </Col>
      </Row>

      <Row className="profile-information__ops">
        <Col className="profile-information__label">Deal Team </Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit deal-team">
            <EditFieldMultiSelect
              record={company}
              fieldName="Deal Team"
              isFromGrid={false}
              successMessage="Deal team added successfully!"
              errorMessage="Deal team update failed, Server error!"
              service={UserService.updateDealTeam}
              value={company.deal_team}
              destroyOnHide={true}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.dealTeam}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">
            {company.deal_team || DEFAULT_EMPTY_VALUE}
          </Col>
        )}
      </Row>

      <Row className="profile-information__fund">
        <Col className="profile-information__label">Fund</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              fieldName="Fund"
              fieldKey="fund"
              record={company}
              isFromGrid={false}
              service={CompanyService.updateCompanyData}
              successMessage="Company fund updated successfully!"
              errorMessage="Company fund update failed, Server error!"
              value={company.fund || DEFAULT_EMPTY_VALUE}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.fund}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">{company.fund || DEFAULT_EMPTY_VALUE}</Col>
        )}
      </Row>

      <Row className="profile-information__next-steps">
        <Col className="profile-information__label">Next Steps</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelectOther
              isFromGrid={false}
              service={UserService.updateNextSteps}
              fieldName="Next Steps"
              value={company.next_steps}
              fieldOtherKey={'next_steps_opinion'}
              fieldOtherValue={company.next_steps_opinion}
              successMessage="Company next steps updated successfully!"
              errorMessage="Company next steps update failed, Server error!"
              record={company}
              otherEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
              destroyOnHide
              autoFocus
              defaultMenuIsOpen
              actionPendo={CompanyPageOverviewPendo.nextSteps}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">
            {company.next_steps || DEFAULT_EMPTY_VALUE}
          </Col>
        )}
      </Row>

      <Row className="profile-information__section-title">Relationship mgmt</Row>
      <Row>
        <Col className="profile-information__label">Last Touch</Col>
        <Col
          className="profile-information__value"
          style={{ color: `${getDateColor(company.last_touch_date)}` }}
        >
          {company.last_touch_date
            ? moment(company.last_touch_date).format(SHORT_MONTH_YEAR_DATE_FORMAT)
            : DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Scheduled Touch</Col>
        <Col className="profile-information__value">
          {company.next_touch_date ? (
            <MultipleDate
              dates={company.next_touch_date as string[]}
              format={SHORT_MONTH_YEAR_DATE_FORMAT}
            />
          ) : (
            DEFAULT_EMPTY_VALUE
          )}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Total # of touches</Col>
        <Col className="profile-information__value">
          {company.total_touches || DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">First Touch Date</Col>
        <Col className="profile-information__value">
          {company.first_touch_date
            ? moment(company.first_touch_date).format(SHORT_MONTH_YEAR_DATE_FORMAT)
            : DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>

      <Row className="profile-information__section-title">Financial</Row>
      <Row>
        <Col className="profile-information__label">Last Round</Col>
        <Col className="profile-information__edit proprietary-icon">
          <EditFieldSingleSelectText
            fieldName="Last Round"
            formatValue={formatValueToMillions}
            isProfileInformation
            validation={value => validateField(value, 'Last Round', undefined)}
            service={UserService.updateCompanyUserValues}
            company={company}
            actionPendo={CompanyPageOverviewPendo.lastRound}
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">{revenueLabel}</Col>
        <Col className="profile-information__edit proprietary-icon">
          <EditFieldFinancial
            companyId={company.company_id}
            companyUrl={company.company_url}
            fieldKey="revenue_list"
            extraKeys={['last_rev_update_amount', 'last_rev_update_year']}
            fieldListValues={company.revenue_list}
            fieldName="Revenue"
            dbSource={company.last_rev_source_db}
            service={UserService.updateCompanyUserValues}
            options={revenueOptions}
            actionPendo={CompanyPageOverviewPendo.revenue}
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Valuation</Col>
        <Col className="profile-information__value">{company.last_valuation || 'Unknown'}</Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Last Funding Date</Col>
        <Col className="profile-information__edit">
          <EditFieldDate
            record={company}
            fieldKey="raise_date"
            value={company.raise_date}
            isFromGrid={false}
            service={UserService.updateCompanyUserValues}
            fieldName="Last Funding Date"
            format={globalConfig.short_date.DATE_FORMAT}
            destroyOnHide
            actionPendo={CompanyPageOverviewPendo.lastFundingDate}
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">{ebitdaLabel}</Col>
        <Col className="profile-information__edit proprietary-icon">
          <EditFieldFinancial
            companyId={company.company_id}
            companyUrl={company.company_url}
            fieldKey="ebitda_list"
            extraKeys={['ebitda_amount', 'ebitda_year']}
            fieldListValues={company.ebitda_list}
            service={UserService.updateCompanyUserValues}
            fieldName="EBITDA"
            dbSource={company.ebitda_source_db}
            options={ebitdaOptions}
            actionPendo={CompanyPageOverviewPendo.ebitda}
          />
        </Col>
      </Row>

      <Row className="profile-information__section-title">Taxonomy</Row>
      <Row>
        <Col className="profile-information__label">Software Company</Col>
        <Col className="profile-information__edit">
          {isAdminOrAnalyst ? (
            <EditFieldRadioRationale
              value={company.is_software}
              record={company}
              isFromGrid={false}
              service={UserService.addCompanySoftwareEvaluation}
              successMessage="Company software company updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="Software Company"
              destroyOnHide
              actionPendo={CompanyPageOverviewPendo.softwareCompany}
            />
          ) : (
            <EditFieldRadio
              value={company.is_software}
              record={company}
              isFromGrid={false}
              service={UserService.addCompanySoftwareEvaluation}
              successMessage="Company software company updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="Software Company"
              actionPendo={CompanyPageOverviewPendo.softwareCompany}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">AI / ML in Products</Col>
        <Col className="profile-information__edit">
          <EditFieldRadio
            record={company}
            value={company.is_ai_ml}
            isFromGrid={false}
            service={UserService.updateAiMl}
            successMessage="Company AI / ML updated successfully!"
            errorMessage="Failed to update value!"
            fieldName="AI / ML"
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Company Type</Col>
        <Col className="profile-information__edit">
          <EditFieldSingleSelect
            isFromGrid={false}
            service={CompanyService.updateCompanyData}
            fieldName="Company Type"
            fieldKey="company_type"
            value={company.company_type || DEFAULT_EMPTY_VALUE}
            successMessage="Company Type updated successfully!"
            errorMessage="Company Type update failed, Server error!"
            record={company}
            getPopupContainer={getPopupContainer}
            actionPendo={CompanyPageOverviewPendo.companyType}
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Opportunity Type</Col>
        {company.source_tag.includes('et') ? (
          <Col className="profile-information__edit">
            <EditFieldSingleSelect
              isFromGrid={false}
              service={CompanyService.updateCompanyData}
              fieldName="Opportunity Type"
              fieldKey="opportunity_type"
              value={company.opportunity_type || DEFAULT_EMPTY_VALUE}
              successMessage="Opportunity Type updated successfully!"
              errorMessage="Opportunity Type update failed, Server error!"
              record={company}
              getPopupContainer={getPopupContainer}
              actionPendo={CompanyPageOverviewPendo.opportunityType}
            />
          </Col>
        ) : (
          <Col className="profile-information__value">{company.opportunity_type || '-'}</Col>
        )}
      </Row>
      <Row>
        <Col className="profile-information__label">Sector</Col>
        <Col className="profile-information__edit">
          <EditFieldSingleSelect
            fieldName="Sector"
            fieldKey="sector"
            isFromGrid={false}
            service={CompanyService.updateCompanyData}
            record={company}
            successMessage="Company sector updated successfully"
            errorMessage="Company sector update failed, Server error!"
            value={
              company.sector && company.sector !== 'None' ? company.sector : DEFAULT_EMPTY_VALUE
            }
            hidePenIcon={false}
            getPopupContainer={getPopupContainer}
            actionPendo={CompanyPageOverviewPendo.sector}
          />
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Sub-Sector</Col>
        <Col className="profile-information__edit">
          <EditFieldSingleSelect
            value={
              company.sub_sector && company.sub_sector !== 'None'
                ? company.sub_sector
                : DEFAULT_EMPTY_VALUE
            }
            record={company}
            isFromGrid={false}
            service={CompanyService.updateCompanyData}
            successMessage="Company sub-sector updated successfully!"
            errorMessage="Company sub-sector update failed, Server error!"
            fieldName="Sub-Sector"
            fieldKey="sub_sector"
            getPopupContainer={getPopupContainer}
            actionPendo={CompanyPageOverviewPendo.subsector}
          />
        </Col>
      </Row>
      <KeyInformationSectionRow overflowHidden={false} label="Product Category">
        <Col className="profile-information__edit product-category">
          <EditFieldMultiSelectAsyncProductCategory
            value={
              company.product_category === null
                ? []
                : (company.product_category as ProductCategory[])
            }
            record={company}
            hidePenIcon={false}
            successMessage="Product category updated successfully!"
            asyncSearchEndpoint="/search/company_product_category"
            service={CompanyService.updateCompanyData}
            isFromGrid={false}
            getPopupContainer={getPopupContainer}
            fieldName="Product Category"
            fieldKey="product_category"
            actionPendo={CompanyPageOverviewPendo.productCategory}
            destroyOnHide
          />
        </Col>
      </KeyInformationSectionRow>
      <KeyInformationSectionRow overflowHidden={false} label="Sub Vertical">
        <Col className="profile-information__edit sub-vertical">
          <EditFieldMultiSelectAsyncCreatable
            value={company.sub_vertical === null ? [] : (company.sub_vertical as string[])}
            record={company}
            hidePenIcon={false}
            successMessage="Sub Vertical updated successfully!"
            errorMessage="Sub Vertical update failed, Server error!"
            asyncSearchEndpoint="/search/sub_vertical"
            service={UserService.subVertical}
            fieldName="Sub Vertical"
            getPopupContainer={getPopupContainer}
            destroyOnHide
          />
        </Col>
      </KeyInformationSectionRow>

      <Row className="profile-information__section-title">Social Health</Row>
      <Row>
        <Col className="profile-information__label">WW Website Rank</Col>
        <Col className="profile-information__value">
          {company.sw_website_rank || DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">G2Crowd Rating</Col>
        <Col className="profile-information__value">{company.g2_rating ?? DEFAULT_EMPTY_VALUE}</Col>
      </Row>
      <Row>
        <Col className="profile-information__label">G2Crowd Review Count</Col>
        <Col className="profile-information__value">
          {company.g2_review_count ?? DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Capterra Rating</Col>
        <Col className="profile-information__value">{company.ct_rating || DEFAULT_EMPTY_VALUE}</Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Capterra Review Count</Col>
        <Col className="profile-information__value">
          {company.ct_review_count || DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Crunchbase Rank</Col>
        <Col className="profile-information__value">
          {company.cb_rank ? numeral(company.cb_rank).format('0,0') : DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
      <Row>
        <Col className="profile-information__label">Size Multiple</Col>
        <Col className="profile-information__value">
          {company.size_multiple ? formatNumber(company.size_multiple) : DEFAULT_EMPTY_VALUE}
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileInformation;
