/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect';
import { Dictionary, pick, each } from 'lodash';
// models
import { EquityTouchContact } from '@models/equityTouch';
import { CompanyProfile } from '@models/Company';
import { SelectOption } from '@models/Option';
// constants
import { BULK_ACTIONS_CONTACT_FIELDS } from '@constants/equitytouch';
// redux
import { selectors as localSelectors } from '../../equity-touch';
import { selectFeatureState } from './selectFeature';

const equityTouchState = createSelector(selectFeatureState, state => {
  return state.equityTouch;
});

// slice selectors
const shouldFetch = createSelector(equityTouchState, localSelectors.shouldFetch);
const loading = createSelector(equityTouchState, localSelectors.loading);
const getFormData = createSelector(equityTouchState, localSelectors.formData);
const getCompanies = createSelector(equityTouchState, localSelectors.companies);
const getAllIds = createSelector(equityTouchState, localSelectors.allIds);
const getQueue = createSelector(equityTouchState, localSelectors.getQueue);
const getCompanyData = createSelector(equityTouchState, localSelectors.companyData);
const getBulkValues = createSelector(equityTouchState, localSelectors.bulkValues);
const getTemporaryData = createSelector(equityTouchState, localSelectors.getTemporaryData);
const getListId = createSelector(equityTouchState, localSelectors.getListId);
const getListType = createSelector(equityTouchState, localSelectors.getListType);

const getCompanyEqtData = (companyId: number) => {
  return createSelector(equityTouchState, localSelectors.companyEqtData(companyId));
};

const getCompanyEqtTemporaryData = (companyId: number) => {
  return createSelector(equityTouchState, localSelectors.getCompanyTemporaryData(companyId));
};

const getSelectedContactIds = createSelector(
  getCompanies,
  getCompanyData,
  (companiesById, companyData) => {
    let contactIds: number[] = [];

    if (Object.keys(companyData).length) {
      contactIds =
        (companiesById[(companyData as CompanyProfile).company_id]
          ?.selectedContactIds as number[]) || [];
    }

    return contactIds;
  }
);

const getContacts = createSelector(
  getCompanies,
  getCompanyData,
  getSelectedContactIds,
  (companiesById, companyData, contactIds) => {
    let contacts: EquityTouchContact[] = [];

    if (Object.keys(companyData).length) {
      if (contactIds.length) {
        contacts = companiesById[(companyData as CompanyProfile).company_id]
          .contacts as EquityTouchContact[];
      }

      (companyData as CompanyProfile).contacts.forEach(contact => {
        if (!contactIds.includes(contact.person_id as number)) {
          contacts.push(pick(contact, BULK_ACTIONS_CONTACT_FIELDS));
        }
      });
    }

    return contacts;
  }
);

const getInitialFormValues = createSelector(
  getFormData,
  getBulkValues,
  getTemporaryData,
  (formData, bulkValues, temporaryData) => {
    if (temporaryData.step1) return temporaryData.step1;

    if (Object.keys(bulkValues).length) {
      return bulkValues as Dictionary<string | number | boolean | number[] | SelectOption>;
    }

    let lead = '';
    let fund = '';
    let rank = '';
    let stage = '';
    let sector = '';
    let source = '';
    let companyType = '';

    if (Object.keys(formData).length) {
      if (formData['Company Type'].database_matched) {
        companyType = formData['Company Type'].database_matched.itemId;
      }

      if (formData['Deal Team Lead'].database_matched) {
        lead = formData['Deal Team Lead'].database_matched.itemId;
      }

      if (formData.Fund.database_matched) {
        fund = formData.Fund.database_matched.itemId;
      }

      if (formData.Rank.database_matched) {
        rank = formData.Rank.database_matched.itemId;
      }

      if (formData.Stage.database_matched) {
        stage = formData.Stage.database_matched.itemId;
      }

      if (formData.Sector.database_matched) {
        sector = formData.Sector.database_matched.itemId;
      }

      if (formData.Source.database_matched) {
        source = formData.Source.database_matched.itemId;
      }
    }

    const initialData: Dictionary<string | number | boolean | number[] | string[] | SelectOption> =
      {
        'Add-On': false,
        'Add-On For': [],
        'Company Type': companyType,
        'Deal Team Lead': lead,
        'Equity Check': '',
        Fund: fund,
        Funding: '',
        Groups: '',
        Investors: '',
        Rationale: '',
        Rank: rank,
        Sector: sector,
        Source: source,
        Stage: stage,
        FTEs: '',
      };

    return initialData;
  }
);

const getEditCompanyPosition = createSelector(getCompanyData, getAllIds, (company, allIds) => {
  let position;

  if (company) {
    each(allIds, (id, index) => {
      if (id === (company as CompanyProfile).company_id) {
        position = index + 1;

        return false;
      }

      return true;
    });
  }

  return position;
});

const results = createSelector(equityTouchState, localSelectors.getResults);

const isInProgress = createSelector(equityTouchState, localSelectors.isInProgress);
const isCompleted = createSelector(equityTouchState, localSelectors.isCompleted);

const percentageCompleted = createSelector(equityTouchState, localSelectors.getPercentageCompleted);

const selectedCompaniesCount = createSelector(
  equityTouchState,
  localSelectors.getSelectedCompaniesCount
);

const successfulCount = createSelector(results, results => {
  const successCompanies = results.reduce((acc: number, item) => {
    if (item.et_sync_status) {
      acc += 1;
    }

    return acc;
  }, 0);

  return successCompanies;
});

const cancel = createSelector(equityTouchState, localSelectors.cancel);

const getCadence = createSelector(getBulkValues, bulkValues => bulkValues.cadence);

export default {
  shouldFetch,
  loading,
  getFormData,
  getInitialFormValues,
  getCompanies,
  getAllIds,
  getQueue,
  getCompanyData,
  getCadence,
  getBulkValues,
  getCompanyEqtData,
  getCompanyEqtTemporaryData,
  getEditCompanyPosition,
  getContacts,
  results,
  isInProgress,
  isCompleted,
  percentageCompleted,
  selectedCompaniesCount,
  successfulCount,
  cancel,
  getTemporaryData,
  getListId,
  getListType,
};
