import { Dictionary } from 'lodash';
import { createSelector } from 'reselect';
import { GroupedOptionsType } from 'react-select';
import createCachedSelector from 're-reselect';
import { AppState } from '@optx/redux/interfaces';
// models
import {
  CompanyFilterColumn,
  FilterOptions,
  BaseFilter,
  CompanyFormRationale,
} from '@optx/models/filters';
import { FinancialFields } from '@optx/models/Financial';
import { SelectOption } from '@optx/models/Option';
// constants
import { addonManagementQuickFilters } from '@redux/company/filters/constants';
// utils
import { preProcessSearch } from '@optx/utils/search';
import { getFilterSourceData } from '@optx/utils/filters/filterHelpers';
// redux
import { selectors as userInformationSelectors } from '@redux/user/information';

const selectCompanyFilters = (state: AppState) => state.filters;

export const isLoading = createSelector(selectCompanyFilters, state => state.loading);
export const shouldNotFetch = createSelector(selectCompanyFilters, state => state.shouldNotFetch);

export const shouldFetchCompanyFilters = createSelector(
  selectCompanyFilters,
  shouldNotFetch,
  userInformationSelectors.loaded,
  (state, shouldNotFetch, loadedUserInformation) => {
    return loadedUserInformation && !state.fetchedAt && !state.loading && !shouldNotFetch;
  }
);

export const filtersLoaded = createSelector(selectCompanyFilters, state => !!state.fetchedAt);

export const getCompanyFilters = createSelector(selectCompanyFilters, state => state.data || []);

export const getDefaultViews = createSelector(selectCompanyFilters, state => state.defaultViews);

export const getDefaultViewData = createSelector(
  selectCompanyFilters,
  state => state.defaultViews.data
);

export const getDefaultFilters = createSelector(
  selectCompanyFilters,
  state => state.defaultViews.filters
);

export const getDefaultSearchkey = createSelector(selectCompanyFilters, state =>
  preProcessSearch(state.defaultViews.searchKey)
);

export const getDefaultSorting = createSelector(
  selectCompanyFilters,
  state => state.defaultViews.sortBy
);

export const getDefaultColumns = createSelector(
  selectCompanyFilters,
  state => state.defaultViews.columns
);

export const getDefaultColumnOrder = createSelector(
  selectCompanyFilters,
  state => state.defaultViews.columnOrder
);

export const getDefaultPinnedColumns = createSelector(
  selectCompanyFilters,
  state => state.defaultViews.pinnedColumns
);

export const getFilterData = createCachedSelector(
  selectCompanyFilters,
  (state: AppState, column: CompanyFilterColumn) => column,
  (state, column) => {
    const filter = state.filters[column];
    const filterData = filter ? filter.data! : [];

    return filterData;
  }
)((state, column) => column);

export const getFormRationales = createCachedSelector(
  selectCompanyFilters,
  (state: AppState, column: CompanyFormRationale) => column,
  (state, column) => {
    const rationale = state.formRationale[column];

    return rationale;
  }
)((state, column) => column);

export const getAlertSettings = createSelector(selectCompanyFilters, state => state.alertSettings);

export const getFilterLocations = createSelector(selectCompanyFilters, state => {
  const column: CompanyFilterColumn = 'location';
  const filter = state.filters[column];
  const filterData = filter ? filter.data! : [];

  return filterData;
});

export const getLocationName = createCachedSelector(
  selectCompanyFilters,
  (state: AppState, shortName: string) => shortName,
  (state, shortName) => {
    const locations = state.normalizedFilters.location;

    return locations[shortName] || '';
  }
)((state, shortName) => shortName);

export const getPreselectedFilters = createSelector(
  selectCompanyFilters,
  state => state.preselected
);

function getSecondaryQuickFilters(filters: Dictionary<BaseFilter<any>>) {
  return Object.fromEntries(
    Object.entries(filters).filter(([column, filterOptions]) => filterOptions.is_quick_filter)
  );
}

export const getFiltersMap = createSelector(selectCompanyFilters, state => state.filters);
export const getSecondaryQuickFiltersMap = createSelector(selectCompanyFilters, state =>
  getSecondaryQuickFilters(state.filters)
);
export const selectOptions = createSelector(selectCompanyFilters, state => state.options);
export const filterSearchOrigin = createSelector(selectCompanyFilters, state => state.searchOrigin);

function getFilterOptions(column: string, filtersOptions: Dictionary<FilterOptions | undefined>) {
  return filtersOptions[column];
}

// Multi select filters
export const getSelectOptions = createCachedSelector(
  selectOptions,
  (state: AppState, filterKey: string) => filterKey,
  (filtersOptions, filterKey) => {
    const options = getFilterOptions(filterKey, filtersOptions) as
      | Array<SelectOption>
      | GroupedOptionsType<SelectOption>
      | undefined;

    return options || [];
  }
)((state, filterKey) => filterKey);

export const getFilterSearchOrigin = createCachedSelector(
  filterSearchOrigin,
  (state: AppState, searchOriginKey: string) => searchOriginKey,
  (filterSearchOrigin, searchOriginKey) => {
    const searchOrigin = filterSearchOrigin?.find(
      searchOriginItem => searchOriginItem.name === searchOriginKey
    );

    return searchOrigin?.value;
  }
)((state, searchOriginKey) => searchOriginKey);

export const getSecondaryQuickFiltersMapAddonManagement = createSelector(
  selectCompanyFilters,
  state => {
    return Object.fromEntries(
      Object.entries(state.filters).filter(([column, filterOptions]) =>
        addonManagementQuickFilters.includes(filterOptions.column)
      )
    );
  }
);

export const getNextSteps = createSelector(selectCompanyFilters, state => state.nextSteps);

export const getFinancialFilterOptions = (filterName: FinancialFields) =>
  createSelector(getCompanyFilters, filters => {
    const filterData = getFilterSourceData(filters, filterName);

    let options: SelectOption[] = [];

    if (filterData) {
      if (filterName === 'ebitda_numeric') {
        const ebitdaOptions: SelectOption[] = (options = filterData.data[1].filter(
          (option: SelectOption) => option.value !== 'blank' && option.value !== 'not_blank'
        ));

        options = [...ebitdaOptions];
      }

      if (filterData.range_values) {
        options = [...options, ...filterData.range_values];
      }
    }

    return options;
  });

export const companyTypeOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('company_type', state) as
    | Array<SelectOption>
    | GroupedOptionsType<SelectOption>
    | undefined;

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).map(item => ({
      value: item.value,
      label: item.label,
    }));

    return filterData;
  }

  return [];
});

export const getRevenueModelOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('revenue_model', state);

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});
export const getTouches = createSelector(getFiltersMap, state => {
  const scheduleTouches = state.schedule_touch;

  if (scheduleTouches) {
    const options = scheduleTouches.data;

    if (options?.length === 0) return [];

    return [{ value: 'All', name: 'All', label: 'All' }, ...options];
  }

  return [];
});

export const getDeliveryModelOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('delivery_model', state);

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});

export const getCashflowOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('cashflow', state);

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).map(item => ({
      value: item.value,
      label: item.label,
    }));

    return filterData;
  }

  return [];
});

export const getDealTypeOptions = createSelector(selectOptions, state => {
  const options = (getFilterOptions('deal_type', state) ?? []) as SelectOption[];

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});

export const getCustomerTypeOptions = createSelector(selectOptions, state => {
  const options = (getFilterOptions('customer_type', state) ?? []) as SelectOption[];

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});

export const getSalesAndMarketingOptions = createSelector(selectOptions, state => {
  const options = (getFilterOptions('sales_and_marketing', state) ?? []) as SelectOption[];

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});

export const getSalesCycleOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('sales_cycle', state);

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});

export const getArchitectureOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('architecture', state);

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});

export const getCloudProviderOptions = createSelector(selectOptions, state => {
  const options = getFilterOptions('cloud_provider', state);

  if (options && options.length !== 0) {
    const filterData = (options as SelectOption<string>[]).filter(item => {
      if (item.value !== 'blank') {
        return {
          value: item.value,
          label: item.label,
        };
      }
    });

    return filterData;
  }

  return [];
});
