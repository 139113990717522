import React, { ReactText, useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, uniqBy } from 'lodash';
// constants
import { defaultColumns } from '@components/common/table/TableContactsEquityTouch';
// utils
import { validateContactLimit } from '@optx/utils/contact';
// redux
import { selectors as primaryContactsSelectors } from '@redux/company/primary-contacts';
import { actions as equityTouchActions, selectors } from '@redux/company/equity-touch';
import { actions as userActions } from '@redux/user/information';

const EquityTouchContactsTable: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(primaryContactsSelectors.getPrimaryContacts);
  const selectedContacts = useSelector(selectors.getSelectedContacts);
  const temporaryFormValues = useSelector(selectors.getTemporaryFormValues);
  const formData = useSelector(selectors.getData);

  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>();

  useEffect(() => {
    const contacts = selectedContacts as number[];

    setSelectedRowKeys(contacts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedContacts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    debounce((selectedRowKeys: ReactText[]) => {
      dispatch(equityTouchActions.updateEquityTouchSelectedContacts(selectedRowKeys as number[]));

      dispatch(
        equityTouchActions.updateTemporaryFormValues({
          ...temporaryFormValues,
          contacts: uniqBy(selectedRowKeys as number[], (item: number) => item),
        })
      );

      dispatch(
        userActions.updateUserSettings({
          et_form_auto_save: {
            values: { ...(temporaryFormValues as any), contacts: selectedRowKeys },
            formData: formData as any,
          },
        } as Partial<any>)
      );
    }, 1000),
    [dispatch, temporaryFormValues, formData]
  );

  const onSelectChange = (selectedRowKeys: ReactText[]) => {
    if (validateContactLimit(selectedRowKeys.length)) {
      setSelectedRowKeys(selectedRowKeys);
      debouncedDispatch(selectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      columns={defaultColumns}
      dataSource={data}
      rowKey={contact => contact.person_id as number}
      rowSelection={rowSelection}
      bordered
      pagination={false}
      tableLayout="fixed"
      className="overflow-auto primary-contacts-grid legacy-table"
    />
  );
};

export default EquityTouchContactsTable;
