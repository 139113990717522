import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Result, Table, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import styled from 'styled-components';
// constants
import appRoutes from '@constants/routes';
// models
import { SalesloftResponse } from '@optx/models/Salesloft';
// utils
import { getOriginPath } from '@optx/utils/routes';
// redux
import { selectors as primaryContactsSelectors } from '@redux/company/primary-contacts';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import { TableCellStatus } from '@components/common/table/AntTable/cells';

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

interface SalesloftColumns {
  name: string;
  url: string;
  status: boolean;
  error: string | null;
}

const columns: ColumnType<SalesloftColumns>[] = [
  {
    dataIndex: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'status',
    title: 'Status',
    render: TableCellStatus,
  },
  {
    dataIndex: 'error',
    title: 'Error Reason',
    render: value => {
      if (value === 'SalesLoft API request failed: email_address: has already been taken') {
        return <Tooltip title="Email is already in use">Email is already in use</Tooltip>;
      }

      return <Tooltip title={value}>{value}</Tooltip>;
    },
  },
  {
    dataIndex: 'url',
    title: 'Sales Loft URL',
    render: (value, record, index) => {
      return (
        value && (
          <Button key={index} href={value} target="_blank">
            View in Sales Loft
          </Button>
        )
      );
    },
  },
];

interface ConfirmationProps {
  res: SalesloftResponse;
}

const Confirmation: React.FC<ConfirmationProps> = ({ res }) => {
  const history = useHistory();
  const { advancedSearchPathname } = useIsAnalyst();

  const contacts = useSelector(primaryContactsSelectors.getPrimaryContacts);
  const contactRes: SalesloftColumns[] = [];

  res.contacts.forEach(item => {
    const contact = contacts.find(contact => contact.person_id === item.person_id);

    if (contact) {
      contactRes.push({
        name: `${contact.first_name} ${contact.last_name}`,
        url: item.sl_url,
        status: item.sl_sync_status,
        error: item.sl_message,
      });
    }
  });

  const handleClick = () => {
    if (getOriginPath(document.referrer) === appRoutes.chromeExtension) {
      history.push(advancedSearchPathname);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      <Result
        key="sl-confirmation-key"
        className="add-to-salesloft__confirmation"
        status="success"
        title="Contact successfully Added to SL Cadence"
        subTitle="It may take up to 5 minutes for the company to show in Sales Loft"
        extra={[
          <Button key="1" onClick={handleClick}>
            Return to Previous Screen
          </Button>,
          <TableWrapper>
            <Table
              tableLayout="fixed"
              columns={columns}
              rowKey={(row, index) => row.name + index}
              dataSource={contactRes}
              pagination={false}
              bordered
              className="legacy-table"
              style={{ maxHeight: '45vh', overflowY: 'auto' }}
            />
          </TableWrapper>,
        ]}
      />
    </>
  );
};

export default Confirmation;
