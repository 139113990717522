import React, { useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Table } from 'antd';
// constants
import { LISTS_ROUTES as routes } from '@constants/routes';
// redux
import {
  selectors as searchesSelectors,
  actions as savedSearchesActions,
} from '@redux/contacts/saved-searches';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
// hooks
import { useScrollPosition } from '@features/scroll-history';
// components
import { MainHeader } from '@components/common/layout';
import { contactsColumns } from '@components/common/table/TableSavedSearches/columns';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import SavedSearchesSearch from './SavedSearchesSearch';
import FilterTags from '../FilterTags';
import { GridPagination } from '@features/grid/pagination/components';

const ContactsSavedSearchesTable: React.FC = () => {
  const dispatch = useDispatch();

  const data = useSelector(searchesSelectors.getSavedSearches);
  const query = useSelector(searchesSelectors.getQuery);
  const pageNumber = useSelector(searchesSelectors.getPageNumber);
  const totalItemsCount = useSelector(searchesSelectors.getTotal);
  const pageSize = useSelector(listsAndSearchesSelectors.pageSize);
  const tableRef = useRef<HTMLDivElement>(null);
  const formattedData = useMemo(() => data.map((item, index) => ({ ...item, key: index })), [data]);

  useScrollPosition(routes.myWatchList, tableRef, pageNumber, query, pageSize);

  const handlePageChange = useCallback(
    (pageNumber: number, perPage: number = pageSize) =>
      dispatch(
        savedSearchesActions.savedContactsSearchPagination({ pageNumber, pageSize: perPage })
      ),
    [dispatch, pageSize]
  );

  return (
    <>
      <MainHeader className="main-header">
        <div className="search-header-content">
          <SavedSearchesSearch className="antd-search-input" autoComplete="off" />
          <FilterTags listType="Saved Contact Search" />
          <Space size={4} style={{ marginLeft: 'auto' }}>
            <ClearSearchButton
              isVisible={!!query}
              title="Clear Search"
              onClick={() => dispatch(savedSearchesActions.fetchContactSavedSearches(''))}
            />
          </Space>
        </div>
      </MainHeader>
      <div ref={tableRef}>
        <Table
          columns={contactsColumns}
          rowKey={'unique_id'}
          dataSource={formattedData}
          className="table-contacts-searches search-results legacy-table"
          tableLayout="fixed"
          pagination={false}
          bordered
        />
      </div>
      <GridPagination
        onPageChange={handlePageChange}
        totalItemsCount={totalItemsCount || 0}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </>
  );
};

export default React.memo(ContactsSavedSearchesTable);
