import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OptionsType } from 'react-select';
// models
import { SelectOption } from '@models/Option';
import { BaseFilterDataType } from '@models/filters';
import { interfaces } from '@components/feature/company-individual-edit/state/companyIndividualEdit';
// utils
import { mapToSelectOption } from '@utils/option';
// redux
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as companySelectors } from '@components/feature/company-individual-edit/state';

const optionsFields: interfaces.IndividualEditFieldType[] = [
  'rank',
  'fund',
  'stage',
  'source',
  'sector',
  'sub_sector',
];

/**
 * This is a hook for getting all necessary data in the add company flow.
 * @param {number} step Step number where we have form data, either 3 or 4.
 * @returns An object containing all the fields' data.
 */
const useGetFilterData = (step: 3 | 4) => {
  const filters = useSelector(companyFiltersSelectors.getCompanyFilters);
  const {
    rank,
    fund,
    stage,
    source,
    sector,
    sub_sector: subSector,
  } = useSelector(companySelectors.companyIndividualEdit.multipleOptions(optionsFields));

  const countries: OptionsType<SelectOption> = useMemo(() => {
    if (step === 3) {
      const countriesFilter: BaseFilterDataType[] =
        filters[1].data.find(filter => filter.column === 'geo_country')?.data ?? [];

      return countriesFilter.map(option => mapToSelectOption(option));
    }

    return [];
  }, [filters, step]);

  const sectors: OptionsType<SelectOption> = useMemo(() => {
    if (step === 3 && sector) {
      const data = [{ value: 'blank', label: 'None' }, ...sector];

      return data;
    }

    return [];
  }, [sector, step]);

  const subSectors: OptionsType<SelectOption> = useMemo(() => {
    if (step === 3 && subSector) {
      const data = [{ value: 'blank', label: 'None' }, ...subSector];

      return data;
    }

    return [];
  }, [subSector, step]);

  const analysts: OptionsType<SelectOption> = useMemo(() => {
    if (step === 4) {
      let analystsFilter: SelectOption[] =
        filters[3].data.find(filter => filter.column === 'company_owner_id')?.data ?? [];

      return analystsFilter.reduce((analystsArray: SelectOption[], analystValue: SelectOption) => {
        if (
          analystValue.value !== 'blank' &&
          analystValue.value !== 'everybody else' &&
          analystValue.value !== 'active sourcing team'
        ) {
          analystsArray.push(mapToSelectOption(analystValue));
        }

        return analystsArray;
      }, []);
    }

    return [];
  }, [filters, step]);

  const stages: OptionsType<SelectOption> = useMemo(() => {
    if (step === 4 && stage) return stage;

    return [];
  }, [stage, step]);

  const funds: OptionsType<SelectOption> = useMemo(() => {
    if (step === 4 && fund) return fund;

    return [];
  }, [step, fund]);

  const sources: OptionsType<SelectOption> = useMemo(() => {
    if (step === 4 && source) return source;

    return [];
  }, [source, step]);

  const ranks: OptionsType<SelectOption> = useMemo(() => {
    if (step === 4 && rank) return rank;

    return [];
  }, [step, rank]);

  const opportunityTypes: OptionsType<SelectOption> = useMemo(() => {
    if (step === 4) {
      const opportunityFilter: BaseFilterDataType[] =
        filters[1].data.find(filter => filter.column === 'opportunity_type')?.data ?? [];

      const opportunityTypes = opportunityFilter
        .map(option => mapToSelectOption(option))
        .filter(option => option.value !== 'blank');

      return opportunityTypes;
    }

    return [];
  }, [filters, step]);

  return {
    countries,
    sectors,
    subSectors,
    analysts,
    stages,
    funds,
    sources,
    ranks,
    opportunityTypes,
  };
};

export default useGetFilterData;
