import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// redux
import { actions as userInformationActions } from '../../../../redux/user/information';
import { selectors, actions } from '@features/pipeline-report/state';
// components
import ToggleButton from '@components/common/molecules/buttons/ToggleButton';

const CardsToggle = () => {
  const dispatch = useDispatch();
  const getCardStatus = useSelector(selectors.getCardStatus);

  const handleClick = useCallback(() => {
    dispatch(actions.hideCardsToggle());
    dispatch(
      userInformationActions.updateUserSettings({
        bi_weekly_pipeline_report_show_cards: !getCardStatus,
      })
    );
  }, [dispatch, getCardStatus]);

  const title = `${getCardStatus ? 'Show' : 'Hide'} Cards`;

  return <ToggleButton onClick={handleClick} hide={!getCardStatus} title={title} />;
};

export default React.memo(CardsToggle);
