/* eslint-disable max-len */
import { CardInfo } from '@optx/models/feature/analystsLeaderboard/CarfInfo';
import { CriteriaKeys } from '@optx/models/feature/analystsLeaderboard/CardCriteria';

const touches: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Total Touches',
      description:
        'This includes completed, past due, and future touches for the time range selected',
    },
    {
      title: 'Completed Touches',
      description: 'All completed touches during the time range selected',
    },
    {
      title: 'Past Due Touches',
      description: 'Incomplete touches with schedule dates that have passed',
    },
    {
      title: 'Scheduled Touches',
      description:
        "Touches that are scheduled that haven't been completed and aren't past due in the time range selected",
    },
  ],
};

const companiesReached: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Companies Touched',
      description: 'This is the total number of companies touched over the time range selected',
    },
    {
      title: 'Rank of 5',
      description:
        'This is the number of companies touched over the time range selected with a pipeline rank of 5',
    },
    {
      title: 'New',
      description:
        "This is the number of companies that had it's first touch over the time range selected",
    },
  ],
};

const stageChanges: CardInfo = {
  title: 'Calculation Explanations',
  content: [
    {
      title: 'Stage Changes',
      description:
        'This is the total number of positive and negative stage changes over the time range selected',
    },
    {
      title: 'Positive',
      description:
        'This is the number of companies that go from (Lead, Track, Pass or Dead) to (Qualified Lead, VWIA, Target, Priority, IOI Presented, LOI Presented/Live, LOI Signed or Portfolio Company)',
    },
    {
      title: 'Negative',
      description:
        'This is the number of companies that go from LOI Signed, LOI Presented/Live or IOI Presented to any stage that is “lower” (i.e. Priority, Target, VWIA, Track, Qualified Lead) is consider a negative change. This would provide a measure of “hit rate” success.',
    },
    {
      title: 'Deals',
      description:
        'This is the number of companies that went to the "Portfolio Company" stage in the time range selected',
    },
  ],
};

const cardInfo: Record<CriteriaKeys, CardInfo | null> = {
  touches,
  companies_reached: companiesReached,
  stage_changes: stageChanges,
};

export default cardInfo;
