import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import { Button, Row, Typography } from 'antd';
import Overflow from 'rc-overflow';
import { DownOutlined } from '@ant-design/icons';
import { debounce, isEqual } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
// constants
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// hooks
import useLoadOptions from '../../hooks/useLoadOptions';
// components
import AsyncMultiSelect from '@optx/shared/view/molecules/Select/AsyncMultiSelect';
import DropdownIndicator from '../DropdownIndicator';
// styled
import { Styled } from './ProductCategoryField.styled';

const AddonForFieldMultiSelectAsyncField: React.FC = () => {
  const [isAddonField] = useField<SelectOption<number> | null>('isAddon');
  const [addonFor, , addonForHelpers] = useField<SelectOption[]>('addonFor');

  const [selected, setSelected] = useState<SelectOption[]>(addonFor.value);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [fetchedOptions, setFetchedOptions] = useState<SelectOption[]>([]);
  const [filterTagValues, setFilterTagValues] = useState<SelectOption[]>(addonFor.value);
  const [isVisible, setIsVisible] = useState(false);
  const [applyDisabled, setApplyDisabled] = useState(true);

  const { loadCompanyOptions } = useLoadOptions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAsyncSearch = useCallback(
    debounce((query: string, callback: (options: SelectOption[]) => void) => {
      if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
        loadCompanyOptions(query, options => {
          options.unshift();
          setFetchedOptions(options);
          callback(options);
        });
      } else {
        callback([]);
      }
    }, 300),
    []
  );

  const handleCancel = useCallback(() => {
    setIsVisible(false);
    setSelectedOptions(selected);
    setApplyDisabled(true);
  }, [selected]);

  const handleApply = () => {
    setIsVisible(false);
    setSelected(selectedOptions);
    setTimeout(() => {
      addonForHelpers.setTouched(true, true);
    });
    setApplyDisabled(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const handleSelectChange = (value: SelectOption[]) => {
    const mappedOptions = value.map(option => ({
      ...option,
      label: option.value,
    }));

    setSelectedOptions(mappedOptions);
    setApplyDisabled(false);
  };

  useEffect(() => {
    setSelectedOptions(selected);
    setFilterTagValues(selected ?? []);

    if (!isEqual(selected, addonFor.value)) {
      addonForHelpers.setValue(selected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (isAddonField.value?.value === 0) {
      setSelectedOptions([]);
      setFilterTagValues([]);
    }
  }, [handleCancel, isAddonField.value?.value]);

  const menu = (
    <Styled.MenuWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div>
        <Row className="form-item-wrapper" gutter={[0, 0]}>
          <Typography.Text>Add-on for</Typography.Text>
        </Row>
        <Row>
          <div role="presentation" className="touch--multiselect-async-wrapper">
            <AsyncMultiSelect
              name={addonFor.name}
              className="touch--multiselect-async"
              onChange={options => {
                handleSelectChange(options as SelectOption[]);
              }}
              loadOptions={handleAsyncSearch}
              defaultOptions={fetchedOptions}
              defaultValue={selectedOptions}
              components={{ DropdownIndicator }}
              // addon values can repeat causing multiselect issues
              getOptionValue={(option: SelectOption) => option.id?.toString() || ''}
              onMenuOpen={() => setFetchedOptions([])}
              loadingMessage={({ inputValue }) =>
                inputValue.length > 2 ? 'Loading...' : 'Begin typing'
              }
              onBlur={() => addonForHelpers.setTouched(true, true)}
            />
          </div>
        </Row>
        <Row justify="end" className="menu-buttons-wrapper">
          <Button style={{ marginRight: 8 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleApply} disabled={applyDisabled}>
            Apply
          </Button>
        </Row>
      </div>
    </Styled.MenuWrapper>
  );

  return (
    <Styled.DropdownWrapper
      visible={isVisible}
      onVisibleChange={flag => {
        !flag ? handleCancel() : setIsVisible(flag);
      }}
      overlay={menu}
      trigger={['click']}
      placement="topRight"
      disabled={isAddonField.value?.value === 0}
    >
      <Button>
        <Styled.TriggerWrapper>
          <Overflow
            data={
              filterTagValues &&
              filterTagValues.map(item => ({
                label: item.label,
                value: item.value,
                uniqueKey: item.value,
              }))
            }
            renderItem={(item: SelectOption) => (
              <div className="option-label">
                {item.label}
                <span className="comma">,</span>
              </div>
            )}
            renderRest={items => <div className="more-label">+ {items.length} more</div>}
            maxCount="responsive"
            style={{ justifyContent: 'flex-start' }}
          />
        </Styled.TriggerWrapper>
        <DownOutlined />
      </Button>
    </Styled.DropdownWrapper>
  );
};

export default React.memo(AddonForFieldMultiSelectAsyncField);
