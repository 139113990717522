import React, { useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
// models
import { CompanyWatchList } from '@optx/models/WatchList';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { SortRule } from '@optx/models/table/sorting';
// constants
import { LISTS_ROUTES as routes } from '@constants/routes';
// utils
import { mapSorting } from '@utils/table/sorting';
// redux
import { selectors as listsSelectors, actions as listsActions } from '@redux/favorite-lists/lists';
import { selectors as listSelectors } from '@redux/lists/details';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
// hooks
import { useScrollPosition } from '@features/scroll-history';
// components
import defaultColumns from '@components/common/table/TableMyWatchlists/columns';

const MyWatchlistsTable: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(listSelectors.getLists);
  const pageNumber = useSelector(listsSelectors.getPageNumber);
  const pageSize = useSelector(listsAndSearchesSelectors.pageSize);
  const sortBy = useSelector(listsSelectors.getSortBy);
  const query = useSelector(listsSelectors.getQuery);
  const columns = useSelector(listsSelectors.getGridColumns(defaultColumns));
  const tableRef = useRef<HTMLDivElement>(null);
  const formattedData = useMemo(
    () => data.map((item, index) => ({ ...item, key: index, id: item.unique_id })),
    [data]
  );

  const onChange: TableChangeCallback<CompanyWatchList> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const newSorting = mapSorting(sorter, sortBy);
        let sortingCopy = { ...(newSorting as SortRule<any>) };

        if (sortingCopy?.sortBy?.[0]?.id === 'date') {
          sortingCopy = {
            sortBy: [{ id: 'created_at', desc: sortingCopy?.sortBy?.[0]?.desc }],
          };
        }

        dispatch(listsActions.sortFavoriteLists({ data: sortingCopy }));
        break;
      }

      default:
        break;
    }
  };

  useScrollPosition(routes.myWatchList, tableRef, pageNumber, query, pageSize);

  return (
    <div ref={tableRef}>
      <Table
        columns={columns}
        rowKey={'unique_id'}
        dataSource={formattedData}
        showSorterTooltip={false}
        onChange={onChange}
        tableLayout="fixed"
        bordered
        pagination={false}
        className="search-results legacy-table"
      />
    </div>
  );
};

export default React.memo(MyWatchlistsTable);
