import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { CompanyProfile } from '@optx/models/Company';
import {
  ChartData,
  ChartBarExtra,
  ChartSuccess,
  ChartIntervalKeys,
  ChartBars,
} from '@optx/models/charts/data';
// constants
import { INITIAL_STATE_CHART } from '@optx/constants/charts';
// utils
import { parseLineGraphDataWithPosition } from '@optx/utils/charts';
import { getIsPsgCompany } from '@optx/utils/company';
// redux
import { actions as employeeGrowthActions } from '@optx/redux/company/employee-growth';
import graphSelectors from '@features/company/graphs/state/selectors';
import { selectors as userSelectors } from '@redux/user/information';
// components
import LinearCombinedChart from '@optx/components/feature/charts/LinearCombinedChart';
import BarChartIntervals from '@optx/components/feature/charts/BarChartIntervals';
import LinearChart from '@optx/components/feature/charts/LinearChart';
import ChartLegend from '@optx/components/feature/charts/ChartLegend';

interface ProfileGraphsProps {
  company: CompanyProfile;
  fundingGraphData: ChartBars;
  extraFunding?: Array<ChartBarExtra>;
  isFundingGraphLoading: boolean;
  fundingGraphMessage: string;
}

const ProfileGraphs: React.FC<ProfileGraphsProps> = ({
  company,
  fundingGraphData,
  extraFunding,
  isFundingGraphLoading,
  fundingGraphMessage,
}) => {
  const dispatch = useDispatch();
  const [employeeGrowth, setEmployeeGrowth] = useState<ChartData>(INITIAL_STATE_CHART);
  const [isEmployeeGrowthLoading, setEmployeeGrowthLoading] = useState<boolean>(true);

  const scoreGraphData = useSelector(graphSelectors.score.getScoreGrowthData);
  const getPSGDate = useSelector(graphSelectors.score.getPSGDate);
  const isScoreGraphLoading = useSelector(graphSelectors.score.isLoading);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  const getEmployeeGrowth = useCallback(
    (companyId: number, cb: ChartSuccess) =>
      dispatch(employeeGrowthActions.fetchCompanyEmployeeGrowth(companyId, cb)),
    [dispatch]
  );

  useEffect(() => {
    if (company) {
      getEmployeeGrowth(company.company_id, growthList => {
        // for this graph the points accurately represent the dates within the
        // time period, by using the "position" property,
        // for which the value is calculated and sent from API
        if (growthList[Object.keys(growthList)[0] as ChartIntervalKeys].data.length) {
          setEmployeeGrowth(parseLineGraphDataWithPosition(growthList));
          setEmployeeGrowthLoading(false);
        } else {
          setEmployeeGrowth(INITIAL_STATE_CHART);
          setEmployeeGrowthLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.emp_growth_percent]);

  return (
    <div className="profile-charts">
      <BarChartIntervals
        data={fundingGraphData}
        isLoading={isFundingGraphLoading}
        label="x_axis"
        title="Funding Rounds"
        dataKey="total_investment"
        extra={extraFunding}
        emptyText={fundingGraphMessage}
        className="profile-chart funding-chart"
      />
      {defaultOptxScore === 'il' ? (
        <LinearChart
          data={scoreGraphData}
          isLoading={isScoreGraphLoading}
          dataKey="score_value"
          labelKey="position"
          title="IL OPTX Score"
          kpiLabel="IL OPTX Score"
          psgDate={getPSGDate}
          headerValue={company.il_optx_score}
          growth={company.il_score_growth as string}
          isTrending
          className="profile-chart score-chart"
          isPSGCompany={getIsPsgCompany(company)}
        />
      ) : (
        <LinearChart
          data={scoreGraphData}
          isLoading={isScoreGraphLoading}
          dataKey="score_value"
          dataSecondaryKey="score_v3_value"
          labelKey="position"
          psgDate={getPSGDate}
          title="OPTX Score"
          kpiLabel="OPTX Score"
          newKPILabel="OPTX Beta"
          headerValue={company.score}
          newHeaderValue={company.score_v3}
          growth={company.score_growth as string}
          newGrowth={company.score_v3_growth as string}
          isTrending
          className="profile-chart score-chart"
          scoreType="us"
          isPSGCompany={getIsPsgCompany(company)}
          legend={<ChartLegend type="score" />}
        />
      )}
      <LinearCombinedChart
        data={employeeGrowth}
        isLoading={isEmployeeGrowthLoading}
        dataKey="count_emp"
        dataSecondaryKey="count_job"
        labelKey="position"
        title="Employee Count"
        kpiLabel="%Growth"
        className="profile-chart employee-chart"
        legend={<ChartLegend type="employee" />}
      />
    </div>
  );
};

export default ProfileGraphs;
