import React from 'react';
import { Row, Col, Space } from 'antd';
import { useSelector } from 'react-redux';
import { RowProps } from 'antd/lib/row';
import { useLocation } from 'react-router-dom';
// redux
import { selectors as viewSelectors, actions as viewActions } from '@features/grid/view';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
// components
import { BulkActionsButton } from '@features/bulk-actions/actions-menu';
import GridView from '@optx/features/grid/view/components/GridView';
import GridDropdown from '../../Home/SearchGridControls/GridDropdown';
import CardsToggle from './CardsToggle';
import SaveSearch from '../../Home/Header/SaveSearch/SaveSearch';
import ToggleTableViewContainer from './ToggleTableViewContainer';
import ToggleLongCardViewContainer from './ToggleLongCardViewContainer';
import CardPannelSorting from './CardPannelSorting';
import { MyCompaniesContext } from '../MyCompaniesContext';
import { TrendingControls } from '../../Home/SearchGridControls';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import SearchCount from '@optx/features/grid/search-count/components/SearchCount';
import { GridActionMenu } from '@components/feature/drop-down/grid-action-menu';

const SecondaryHeader: React.FC<RowProps> = props => {
  const location = useLocation();
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const activeView = useSelector(viewSelectors.getView('myCompanies'));
  const applyView = viewActions.applyView;
  const viewType = useSelector(viewTypeSelectors.searchView('myCompanies'));
  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies('myCompanies')
  );

  const tableView = viewType === 'table';

  return (
    <Row justify="space-between" align="middle" className="my-companies-second-header" {...props}>
      <SearchCount gridName="myCompanies" searchViewType={viewType} />
      <Col>
        <Space size={4}>
          <SaveSearch showButton={false} viewId={activeView.id} />
          <TrendingControls />
          {tableView && (
            <GridView gridKey="myCompanies" activeView={activeView} applyView={applyView} />
          )}
          {!tableView && <CardPannelSorting />}
          <ToggleTableViewContainer />
          <ToggleLongCardViewContainer />
          {tableView && !isFullscreen && <GridFullScreen />}
          <CardsToggle />
          {!tableView && <GridActionMenu />}
          {tableView && (
            <>
              <GridDropdown />
              <GridActionMenu />
              {!!selectedCompanies.length && viewType === 'table' && (
                <BulkActionsButton
                  context={MyCompaniesContext}
                  selectedCompanies={selectedCompanies}
                  referrer={encodeURIComponent(location.pathname)}
                />
              )}
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default React.memo(SecondaryHeader);
