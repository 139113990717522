import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useDispatch } from 'react-redux';
// models
import { FieldPropertyInfoType } from '@optx/models/Financial';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { filterOption } from '@optx/utils/select/select';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
// redux
import { actions } from '@redux/company/opportunity-presentation';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

interface EditFieldSingleSelectProps {
  companyId: number;
  hidePenIcon?: boolean;
  fieldKey: string;
  options?: OptionTypeBase[];
  fieldPropietaryInfoType: FieldPropertyInfoType;
  initialValue: string;
  isFromAuditField?: boolean;
  actionPendo?: string;
}

const EditFieldSingleSelect: React.FC<EditFieldSingleSelectProps> = ({
  companyId,
  fieldKey,
  initialValue,
  fieldPropietaryInfoType,
  options,
  hidePenIcon = false,
  isFromAuditField,
  actionPendo,
}) => {
  const dispatch = useDispatch();
  const { updateHistoryFields } = useHistoryTab();

  const title = `Edit "${fieldKey}" value`;
  const [selectedValue, setSelectedValue] = useState<string>(initialValue as string);
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const afterValue = useRef<string>();

  const onChange = (label: string) => {
    const key = fieldKey?.replaceAll(' ', '_').toLowerCase();

    if (selectedValue !== label) {
      setSelectedValue(label);
      afterValue.current = label;
    }

    const payload = {
      company_id: companyId,
      [fieldPropietaryInfoType]: {
        [key as string]: label,
      },
    };

    dispatch(actions.updateOpportunityPresentation(payload));

    updateHistoryFields({
      afterValue: label,
      beforeValue: initialValue,
      fieldChanged: fieldKey,
    });
    setClosePopup(true);
  };

  useEffect(() => {
    if (afterValue.current) {
      setSelectedValue(afterValue.current);
    }
  }, []);

  useEffect(() => {
    setSelectedValue(initialValue as string);
  }, [initialValue]);

  const content = (
    <Select
      autoFocus
      defaultOpen
      placeholder="select an option"
      filterOption={filterOption}
      onChange={onChange}
      defaultValue={selectedValue}
      dropdownMatchSelectWidth={250}
      style={{ width: 250 }}
      showSearch
    >
      {options?.map((dealType: OptionTypeBase) => (
        <Select.Option key={dealType.value} value={dealType.value}>
          <TruncateTooltip title={dealType.label}>{dealType.label}</TruncateTooltip>
        </Select.Option>
      ))}
    </Select>
  );

  if (isFromAuditField) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        hidePenIcon
        destroyOnHide
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
      >
        Edit Field
      </EditPopover>
    );
  }

  return (
    <EditPopover
      content={content}
      title={title}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePenIcon}
      actionPendo={actionPendo}
      destroyOnHide
      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
    >
      <TruncateTooltip
        title={
          ['None', '', undefined, null].includes(initialValue) ? DEFAULT_EMPTY_VALUE : initialValue
        }
      >
        <span>
          {['None', '', undefined, null].includes(initialValue)
            ? DEFAULT_EMPTY_VALUE
            : initialValue}
        </span>
      </TruncateTooltip>
    </EditPopover>
  );
};

export default React.memo(EditFieldSingleSelect);
