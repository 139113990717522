import { FieldInputProps } from 'formik';
// models
import { SelectOption } from '@models/Option';
// constants
import {
  STATE_SEARCH_ENDPOINT,
  CITY_SEARCH_ENDPOINT,
  PRODUCT_CATEGORY_SEARCH_ENDPOINT,
  COMPANY_NAME_SEARCH_ENDPOINT,
} from '@constants/search';
// utils
import { getCityDependenciesOptions } from '../utils';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';

interface UseLoadOptionsProps {
  countryField: FieldInputProps<SelectOption | undefined>;
  stateField: FieldInputProps<SelectOption | undefined>;
  secondaryCountryField: FieldInputProps<SelectOption | undefined>;
  secondaryStateField: FieldInputProps<SelectOption | undefined>;
}

/**
 * A hook for managing the async search of dropdown options
 */
const useLoadOptions = (data?: UseLoadOptionsProps) => {
  const { loadOptions: loadPrimaryStateOptions } = useAsyncSearch({
    endpoint: STATE_SEARCH_ENDPOINT,
    dependenciesOptions: data?.countryField.value
      ? { country: [data.countryField.value.value] }
      : {},
  });

  const { loadOptions: loadSecondaryStateOptions } = useAsyncSearch({
    endpoint: STATE_SEARCH_ENDPOINT,
    dependenciesOptions: data?.secondaryCountryField.value
      ? { country: [data.secondaryCountryField.value.value] }
      : {},
  });

  const { loadOptions: loadPrimaryCityOptions } = useAsyncSearch({
    endpoint: CITY_SEARCH_ENDPOINT,
    dependenciesOptions: getCityDependenciesOptions(data?.countryField, data?.stateField),
  });

  const { loadOptions: loadSecondaryCityOptions } = useAsyncSearch({
    endpoint: CITY_SEARCH_ENDPOINT,
    dependenciesOptions: getCityDependenciesOptions(
      data?.secondaryCountryField,
      data?.secondaryStateField
    ),
  });

  const { loadOptions: loadProductCategoryOptions } = useAsyncSearch({
    endpoint: PRODUCT_CATEGORY_SEARCH_ENDPOINT,
  });

  const { loadOptions: loadCompanyOptions } = useAsyncSearch({
    endpoint: COMPANY_NAME_SEARCH_ENDPOINT,
  });

  return {
    loadPrimaryStateOptions,
    loadSecondaryStateOptions,
    loadPrimaryCityOptions,
    loadSecondaryCityOptions,
    loadProductCategoryOptions,
    loadCompanyOptions,
  };
};

export default useLoadOptions;
