import React, { useRef } from 'react';
import { Select, Input, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
// hooks
import useAutoFocusSelect from '@optx/common/hooks/useAutoFocusSelect';
// components
import CheckboxIcon from '@features/field-factory/CheckboxIcon';
// styles
import { SelectWrapper } from '@features/field-factory/MultiSelectField.styled';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

interface MultiSelectWithRationaleProps {
  $selectedValues: string[];
  $list: { value: string; label: string }[];
  $renderRationaleField: boolean;
  $rationaleValue: string;
  $handleGridMultiSelect: (value: string, type: 'select' | 'deselect') => void;
  $setRationaleValue: (value: string) => void;
}

const MultiSelectWithRationale: React.FC<MultiSelectWithRationaleProps> = ({
  $selectedValues,
  $list,
  $renderRationaleField,
  $rationaleValue,
  $handleGridMultiSelect,
  $setRationaleValue,
}) => {
  const selectRef = useRef<HTMLElement | null>(null);

  useAutoFocusSelect(selectRef, true, 'ant-select-selection-search-input');

  return (
    <SelectWrapper>
      <Select
        ref={selectRef}
        defaultOpen={true}
        showSearch
        showArrow
        style={{ width: '300px' }}
        value={$selectedValues as string[]}
        menuItemSelectedIcon={CheckboxIcon}
        onSelect={(value, option) => $handleGridMultiSelect(value as string, 'select')}
        onDeselect={(value, option) => $handleGridMultiSelect(value as string, 'deselect')}
        optionFilterProp="children"
        mode="multiple"
        getPopupContainer={trigger => trigger.parentElement!}
        tagRender={props => {
          return <span>{props.label || ''} </span>;
        }}
        filterOption={(input: string, option: OptionTypeBase | undefined) => {
          const fullName = `${option?.children.props.children}`;

          return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {$list?.map((option: OptionTypeBase) => (
          <Option
            key={option.value as string}
            value={option.value as string}
            className="left-checkbox-option"
          >
            <span>{option.label}</span>
          </Option>
        ))}
      </Select>

      {$renderRationaleField && (
        <div style={{ width: '300px' }}>
          <Text>Edit "Other Rationale" field</Text>
          <TextArea
            rows={3}
            placeholder={'Please Explain'}
            value={$rationaleValue}
            maxLength={500}
            onChange={e => $setRationaleValue(e.target.value)}
          />
        </div>
      )}
    </SelectWrapper>
  );
};

export default MultiSelectWithRationale;
