import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import { Button, Row, Typography } from 'antd';
import Overflow from 'rc-overflow';
import { DownOutlined } from '@ant-design/icons';
import { debounce, isEqual } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
// constants
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// hooks
import useLoadOptions from '../../hooks/useLoadOptions';
// components
import AsyncMultiSelect from '@optx/shared/view/molecules/Select/AsyncMultiSelect';
// styled
import { Styled } from './ProductCategoryField.styled';

// is_valid may be a property on loaded option but will not be kept on selected (we don't always have it)
// isValid will be kept on selected and it's value should be set based on is_valid field existence/value
interface AsyncSelectOption extends SelectOption {
  is_valid?: boolean;
  isValid?: boolean;
}

const ProductCategoryMultiSelectAsyncField: React.FC = () => {
  const [selectedList, , setSelectedList] = useField<AsyncSelectOption[]>('productCategory');
  const [selected, setSelected] = useState<AsyncSelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AsyncSelectOption[]>([]);
  const [fetchedOptions, setFetchedOptions] = useState<AsyncSelectOption[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filterTagValues, setFilterTagValues] = useState<AsyncSelectOption[]>(selectedList.value);
  const [applyDisabled, setApplyDisabled] = useState(true);

  const { loadProductCategoryOptions } = useLoadOptions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAsyncSearch = useCallback(
    debounce((query: string, callback: (options: SelectOption[]) => void) => {
      if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
        loadProductCategoryOptions(query, options => {
          options.unshift();
          setFetchedOptions(options);
          callback(options);
        });
      } else {
        callback([]);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (selected.values.length !== 0) {
      setSelectedOptions(selected.values as any);
    } else {
      setSelectedOptions(selectedList.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedOptions(selected.map(item => ({ ...item, isValid: item.is_valid ?? true })));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleCancel = () => {
    setIsVisible(false);
    setSelectedOptions(selected);
    setApplyDisabled(true);
  };

  const handleApply = () => {
    const selectedValue = selectedOptions.map(item => ({
      ...item,
      isValid: item.is_valid ?? true,
    }));

    setIsVisible(false);

    setSelected(selectedValue);
    setSelectedList.setValue(selectedValue);
    setFilterTagValues(selectedValue);
    setApplyDisabled(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const handleSelectChange = (value: SelectOption[]) => {
    setSelectedOptions(value);
    setApplyDisabled(false);
  };

  useEffect(() => {
    if (isVisible && !isEqual(selectedOptions, selectedList.value)) {
      setSelectedOptions(selectedList.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const menu = (
    <Styled.MenuWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div>
        <Row className="form-item-wrapper" gutter={[0, 0]}>
          <Typography.Text>Product Category</Typography.Text>
        </Row>
        <Row>
          <div role="presentation" className="touch--multiselect-async-wrapper">
            <AsyncMultiSelect
              className="touch--multiselect-async"
              onChange={options => {
                handleSelectChange(options as SelectOption[]);
              }}
              loadOptions={handleAsyncSearch}
              defaultOptions={fetchedOptions}
              autoFocus
              defaultValue={selectedOptions}
              onMenuOpen={() => setFetchedOptions([])}
              loadingMessage={({ inputValue }) =>
                inputValue.length > 2 ? 'Loading...' : 'Begin typing'
              }
            />
          </div>
        </Row>
        <Row justify="end" className="menu-buttons-wrapper">
          <Button style={{ marginRight: 8 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleApply} disabled={applyDisabled}>
            Apply
          </Button>
        </Row>
      </div>
    </Styled.MenuWrapper>
  );

  return (
    <Styled.DropdownWrapper
      visible={isVisible}
      onVisibleChange={flag => {
        !flag ? handleCancel() : setIsVisible(flag);
      }}
      overlay={menu}
      trigger={['click']}
      placement="topRight"
    >
      <Button>
        <Styled.TriggerWrapper>
          <Overflow
            data={
              filterTagValues &&
              filterTagValues.map(item => ({
                label: item.label,
                value: item.value,
                uniqueKey: item.value,
              }))
            }
            renderItem={(item: SelectOption) => (
              <div className="option-label">
                {item.label}
                <span className="comma">,</span>
              </div>
            )}
            renderRest={items => <div className="more-label">+ {items.length} more</div>}
            maxCount="responsive"
            style={{ justifyContent: 'flex-start' }}
          />
        </Styled.TriggerWrapper>
        <DownOutlined />
      </Button>
    </Styled.DropdownWrapper>
  );
};

export default React.memo(ProductCategoryMultiSelectAsyncField);
