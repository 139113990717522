import { CloseCircleTwoTone } from '@ant-design/icons';
import { Button, DatePicker, Tooltip } from 'antd';
import styled from 'styled-components';

const DefaultTooltip = styled(Tooltip)`
  display: block;
`;

const CustomDatePicker = styled(DatePicker)`
  margin: 5px 0;
  cursor: text;

  .ant-picker-input > input {
    pointer-events: none;
  }
`;

const CloseIcon = styled(CloseCircleTwoTone)`
  font-size: 19px;
  margin-top: 12px;
  margin-left: 10px;
`;

const AddDateButton = styled(Button)`
  margin-top: 10px;
  width: 90%;
  color: #1890ff;
`;

export const Styled = {
  DefaultTooltip,
  CustomDatePicker,
  CloseIcon,
  AddDateButton,
};
