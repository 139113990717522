import { useDispatch, useSelector } from 'react-redux';
import { GetComponentProps, DefaultRecordType } from 'rc-table/lib/interface';
// models
import { AppState } from '@optx/redux/interfaces';
// constants
import { COMPANY_PAGE_ACTION } from '@optx/constants/pendoActions';
// redux
import { actions as scrollActions, selectors as scrollSelectors } from '@features/scroll-history';

type GetRecordId<R> = (record: R) => string | number;
type RowKey<R> = keyof R | GetRecordId<R>;

const useActiveTableRow = <R = DefaultRecordType>(rowKey: RowKey<R>, pageKey: string) => {
  const dispatch = useDispatch();
  const tableRow = useSelector((state: AppState) => scrollSelectors.getTableRow(state, pageKey));

  const updateActiveRow = (event: React.MouseEvent<HTMLElement, MouseEvent>, record: R) => {
    window.pendo.track(COMPANY_PAGE_ACTION);
    const rowId = event.currentTarget.getAttribute('data-row-key') as string;

    dispatch(scrollActions.saveTableRow(pageKey, { tableRow: rowId }));
  };

  const determineClassName = (record: R) => {
    const id: string = String(typeof rowKey === 'function' ? rowKey(record) : record[rowKey]);

    if (id === tableRow) {
      return 'active-row';
    }

    return '';
  };

  const onRow: GetComponentProps<R> = (record: R) => {
    return {
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => updateActiveRow(event, record),
      className: determineClassName(record),
    };
  };

  return onRow;
};

export default useActiveTableRow;
