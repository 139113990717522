import React from 'react';
import { Tooltip } from 'antd';
// styled
import Styled from './Info.styled';

export interface InfoProps {
  info: string;
  className?: string;
}

const Info: React.FC<InfoProps> = ({ info, className }) => {
  return (
    <Tooltip
      arrowPointAtCenter={true}
      placement={className === 'extension-info' ? 'bottomRight' : 'bottom'}
      title={info}
      overlayInnerStyle={{
        textAlign: 'center',
        width: 113,
        fontSize: 10,
        lineHeight: '11px',
        padding: 6,
      }}
    >
      <Styled.Icon className={className} />
    </Tooltip>
  );
};

export default Info;
