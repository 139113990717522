/* eslint-disable no-irregular-whitespace */
import styled from 'styled-components';
import { ButtonProps } from 'antd/lib/button';
import { SubmitButton } from 'formik-antd';
import { Card, Col, Modal, Row, Typography } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Link } from 'react-router-dom';

export const SubmitDealButton = styled<React.FC<ButtonProps>>(SubmitButton)`
  position: absolute !important;
  right: 0;
  top: -40px;
`;

interface DealPresentationWrapperProps {
  $isChromeExtension?: boolean;
}

export const ContainerButtons = styled.div`
  position: relative;
  bottom: 3.2rem;
  left: 14%;
`;

export const FinancialsCard = styled(Card)`
  border-radius: 8px;
  margin-top: -4px;
`;

export const DealPreparationCard = styled(Card)`
  border-radius: 8px;
`;

export const FundingCard = styled(Card)`
  margin-right: -20px;
  border-radius: 8px;
`;

export const CustomerKPICard = styled(Card)`
  margin-top: 19px;
  margin-right: -20px;
  border-radius: 8px;
`;

export const TechnologyCard = styled(Card)`
  margin-top: 22px;
  margin-right: -20px;
  border-radius: 8px;
`;

export const PropietaryInfoContainer = styled.div`
  display: grid;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 50% 50%;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 50% 50%;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 50% 50%;
  }

  @media screen and (min-width: 1441px) {
    grid-template-columns: 35% 65%;
  }

  @media screen and(min-width:1023px) {
    grid-template-columns: 100%;
  }

  margin-top: 20px;
`;

export const DealPresentationWrapper = styled.div<DealPresentationWrapperProps>`
  margin-left: 24px;
  .deal-preparation-information {
    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }

    .icon-pen {
      width: 18px;
      height: 18px;
    }

    &__label {
      width: ${props => (props.$isChromeExtension ? '25%' : '16em')};

      @media screen and (min-width: 1024px) {
        width: ${props => (props.$isChromeExtension ? '25%' : '15em')};
      }

      padding-right: 4%;
      line-height: 22px;
      font-size: 14px;
      font-weight: 600;
      color: #90a0b7;
    }

    .no-edit {
      color: #374961;
    }

    &__edit {
      color: #1890ff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      max-width: 47%;
      width: fit-content;

      .edit-field--container {
        display: flex;

        .edit-field--value {
          width: 100%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
      &:hover {
        cursor: pointer;
        .edit-pen {
          visibility: visible;
        }
      }
    }
    &__notes {
      color: #1890ff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;

      .edit-field--container {
        .edit-pen {
          padding-left: 10px;
          visibility: hidden;
        }
        &:hover {
          cursor: pointer;
          .edit-pen {
            visibility: visible;
          }
        }
      }
    }
  }
`;

export const OtherFinancialsInformationTitle = styled(Typography.Title)`
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  font-style: 'normal';
  color: rgba(38, 38, 38, 1);
  line-height: 24px;
`;

export const OtherFinancialsInformationWrapper = styled.div`
  .edit-field--container {
    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }

    .icon-pen {
      width: 18px;
      height: 18px;
    }

    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }
`;

export const OtherFinancialsInformationRow = styled(Row)``;

interface OtherFinancialsInformationLabelProps {
  $isChromeExtension?: boolean;
}

export const OtherFinancialsInformationLabel = styled(Col)<OtherFinancialsInformationLabelProps>`
  padding-right: 4%;
  line-height: 22px;
  @media screen and (max-width: 1023px) {
    width: ${props => (props.$isChromeExtension ? '30%' : '44%')};
  }
  @media screen and (min-width: 1024px) {
    width: ${props => (props.$isChromeExtension ? '30%' : '44%')};
  }
  @media screen and (min-width: 1280px) {
    width: ${props => (props.$isChromeExtension ? '30%' : '36%')};
  }
  @media screen and (min-width: 1440px) {
    width: ${props => (props.$isChromeExtension ? '30%' : '34%')};
  }
  @media screen and (min-width: 1600px) {
    width: ${props => (props.$isChromeExtension ? '30%' : '32%')};
  }
  @media screen and (min-width: 1900px) {
    width: ${props => (props.$isChromeExtension ? '30%' : '30%')};
  }
  font-size: 14px;
  font-weight: 600;
  color: #90a0b7;
`;

interface OtherFinancialsInformationValueProps {
  $isChromeExtension?: boolean;
}

export const OtherFinancialsInformationValue = styled(Col)<OtherFinancialsInformationValueProps>`
  padding-right: 4%;
  max-width: 45%;
  margin-left: ${({ $isChromeExtension }) => (!$isChromeExtension ? '-18%' : '0')};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  color: #1890ff;
`;

interface FinancialsInformationValueProps {
  $isChromeExtension?: boolean;
}

export const FinancialsWrapper = styled.div<FinancialsInformationValueProps>`
  position: relative;
  margin-top: ${props => (props.$isChromeExtension ? '0' : '-20px')};

  .add-years-column {
    background-color: rgba(250, 250, 250, 1) !important;
  }

  .ant-table-thead > tr > th {
    background: white !important;
  }
  .add-years-button {
    font-size: 14px;
    font-weight: 500;
  }
  .edit-field--container {
    display: flex;
    width: fit-content;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  table {
    border-bottom: 1px solid #f0f0f0;
    table-layout: fixed !important; /* rewrite inline styles */
  }

  .ant-table-thead .ant-table-cell:not(.ant-table-cell-fix-left) {
    border-left: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    font-size: 14px;
    color: #262626;
    font-weight: 400;
  }

  .ant-table-cell {
    padding: 0 0 0 16px;
  }

  .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    padding: 0 0 0 25px;
  }

  .ant-table-tbody .ant-table-cell {
    border-bottom: 0px;
    font-size: 14px;
    color: #262626;
    font-weight: 600;

    &:not(.ant-table-cell-fix-left) {
      border-left: 1px solid #f0f0f0;
      font-weight: 400;
      padding: 5px 16px;
    }

    .edit-field--container  {
      font-size: 14px;
      font-weight: 500;
      color: #595959;
      display: flex;
    }
    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }
    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }
  .financial-value-row {
    font-weight: 500 !important;
  }
  .financial-value {
    display: flex;
    gap: 0 5px;
    font-weight: 400;
    font-size: 14px;
    .value {
      font-weight: 600;
      color: #1890ff;
    }
  }
  .growth {
    color: #595959;
    &.positive {
      color: #52c41a;
    }
    &.negative {
      color: #f5222d;
    }
    &.neutral {
      color: #595959;
    }
  }

  .ant-table-thead {
    .ant-table-cell-fix-left {
      border-top: 1px solid #f0f0f0;
      height: 45px;
    }
    .edit-pen {
      padding-left: 10px;
      visibility: hidden;
    }
    &:hover {
      cursor: pointer;
      .edit-pen {
        visibility: visible;
      }
    }
  }
  .ant-select-arrow {
    color: black;
  }
`;

export const DropDownExportContainer = styled.div`
  min-width: 200px;
  width: 50%;
  margin-bottom: 30px;
`;

export const DeleteFinancialYearButton = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

export const ModalWrapper = styled(Modal)<ModalProps>`
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  .ant-modal-header,
  .ant-modal-footer {
    border-color: #e8e8e8;
  }

  .ant-modal-body {
    background: #f3f6f9;
    height: 376px;
  }

  th.ant-table-cell {
    padding: 19px 7px;
  }

  .ant-table-wrapper {
    max-height: 60vh;
    overflow-y: auto;
  }

  .error-message {
    color: #f5222d;
    display: block;
    font-size: 0.9em;
    padding-top: 180px;
  }

  .error-message-right {
    padding-top: 0;
    position: absolute;
    left: 230px;
    top: 0;
    min-width: 200px;
  }

  .ant-select-selection-item  {
    color: rgba(0, 0, 0) !important;
    font-weight: 500;
  }

  .ant-input {
    width: 220px;
    display: block;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-left: 12px;
`;

export const TooltipLink = styled(Link)`
  display: block;
  color: #1890ff !important;
  margin-top: 18px;
  cursor: pointer;
`;
