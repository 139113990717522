import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
// models
import {
  CompanyActivityCardData,
  CompanyActivityCard as CompanyActivityCardInterface,
  SearchCompaniesCard,
  AnalystSearchCardId,
} from '@optx/features/analyst-home/state/interfaces';
import { SearchPayload } from '@optx/models/api/contacts';
// constants
import appRoutes from '@constants/routes';
// utils
import { parseFilter } from '@utils/filters/parseFilters';
// redux
import {
  actions as analystHomeActions,
  useInject as useInjectAnalystHome,
} from '@optx/features/analyst-home';
import { selectors as companyActivitySelectors } from '@optx/features/analyst-home/state';
import { selectors as userSelectors } from '@redux/user/information';
import { actions as searchActions } from '@features/grid/search';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { actions as listsActions } from '@redux/favorite-lists/lists';
// hooks
import { useFetchSuggestedSearches } from '@optx/common/hooks/init';
import {
  useInjectCompanySavedSearches,
  useInjectSuggestedSearches,
  useInjectUI,
} from '@optx/common/hooks/inject';
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import { PrivateComponent } from '@optx/components/common/PrivateComponent';
import {
  Sidebar,
  RecentlyAccessedGroupCardSkeleton,
  CompanyActivityCardSkeleton,
} from '@optx/features/analyst-home/components';
import ErrorComponent from '@components/common/ErrorComponent';
import Styled from './AnalystHome.styled';
import {
  AnalystSearchCard,
  CompanyActivityCard,
  RecentlyAccessedGroupCard,
} from '@optx/features/analyst-home/components';

const listsWithoutNotifications = ['Recently Accessed Lists'];

const AnalystHome = () => {
  useInjectUI();
  useInjectSuggestedSearches();
  useInjectAnalystHome();
  useInjectCompanySavedSearches();
  useFetchSuggestedSearches();

  const history = useHistory();
  const dispatch = useDispatch();
  const companyActivity = useSelector(companyActivitySelectors.getCompanyActivityData);
  const searchCardsBlocks = useSelector(companyActivitySelectors.getSearchCardsBlocks);
  const companyActivityDataIsLoading = useSelector(
    companyActivitySelectors.getCompanyActivityDataLoading
  );
  const userName = useSelector(userSelectors.getFirstName);
  const isUserInfoLoaded = useSelector(userSelectors.loaded);
  const { advancedSearchPathname } = useIsAnalyst();
  const filterSources = useSelector(filterSourcesSelectors.getCompanyFilters);

  const companiesBlocks = useMemo(
    () => companyActivity?.blocks?.filter(block => block.type === 'companies'),
    [companyActivity?.blocks]
  );

  const listsBlocks = useMemo(
    () => companyActivity?.blocks?.filter(block => block.type === 'lists'),
    [companyActivity?.blocks]
  );

  useEffect(() => {
    dispatch(analystHomeActions.init());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewMore = (searchParamsQuery: string) => {
    const payloadFilter = parseFilter(searchParamsQuery, filterSources)[0];

    const payloadData: Partial<SearchPayload> = {
      searchKey: '',
      filter: payloadFilter,
      shouldResetPageNumber: true,
      shouldBeAnalystHomePageSorting: true,
      shouldResetSearchTitle: true,
    };

    dispatch(searchActions.searchCompanies({ gridKey: 'advancedSearch', data: payloadData }));
    history.push(advancedSearchPathname);
  };

  const handleViewMoreListsBlock = () => {
    dispatch(
      listsActions.sortFavoriteLists({
        data: {
          sortBy: [{ id: 'modified_at', desc: true }],
        },
        hideHistory: true,
      })
    );
    history.push(appRoutes.lists.myWatchList);
  };

  if (isUserInfoLoaded && !advancedSearchPathname) {
    return <ErrorComponent />;
  }

  return (
    <PrivateComponent accessFor="analyst_general">
      <Styled.Wrapper className="analyst-home">
        <Styled.MainContent className="main-content">
          <Styled.PageTitle $isGreetingsTitle={true}>Welcome, {userName}</Styled.PageTitle>
          <Styled.PageTitle level={2}>Find New Companies</Styled.PageTitle>
          <Styled.SearchCardsWrapper>
            {searchCardsBlocks &&
              (searchCardsBlocks.data as SearchCompaniesCard[]).map(card => {
                const { id, title, subtitle, search_params: searchParamsQuery } = card;

                return (
                  <AnalystSearchCard
                    key={id}
                    id={id as AnalystSearchCardId}
                    title={title}
                    subTitle={subtitle}
                    searchQuery={searchParamsQuery}
                  />
                );
              })}
          </Styled.SearchCardsWrapper>
          {(companyActivityDataIsLoading || (listsBlocks && listsBlocks.length > 0)) && (
            <>
              <Styled.PageTitle level={2} $isRegularTitle>
                Manage My Companies
              </Styled.PageTitle>

              {listsBlocks ? (
                listsBlocks.map(block => {
                  return (
                    <Styled.StatsWrapper key={block.id} isRecentlyAccessedLists>
                      <Styled.TitleWrapper>
                        <Styled.SecondaryTitle>{block.label}</Styled.SecondaryTitle>
                        <Button onClick={handleViewMoreListsBlock}>
                          View All {block.total_count && `(${block.total_count})`}
                        </Button>
                      </Styled.TitleWrapper>
                      <Styled.MyCompaniesCardsWrapper cardCount={block.data.length}>
                        {(block.data as CompanyActivityCardInterface[]).map(list => {
                          const { unique_id: uniqueId, title, notifications, count, date } = list;

                          return (
                            <RecentlyAccessedGroupCard
                              key={uniqueId}
                              id={uniqueId}
                              title={title}
                              notificationsCount={
                                !listsWithoutNotifications.includes(block.label)
                                  ? notifications
                                  : undefined
                              }
                              companiesCount={count}
                              date={date}
                              isLoading={companyActivityDataIsLoading}
                            />
                          );
                        })}
                      </Styled.MyCompaniesCardsWrapper>
                    </Styled.StatsWrapper>
                  );
                })
              ) : (
                <Styled.MyCompaniesCardsWrapper cardCount={7}>
                  {Array.from({ length: 7 }).map((_, index) => (
                    <RecentlyAccessedGroupCardSkeleton key={index} />
                  ))}
                </Styled.MyCompaniesCardsWrapper>
              )}
            </>
          )}

          <Styled.PageTitle level={2} $isRegularTitle>
            My Company Activity
          </Styled.PageTitle>
          {companiesBlocks ? (
            companiesBlocks.map(block => {
              if (block.data?.length === 0) {
                return null;
              }

              return (
                <Styled.StatsWrapper key={block.id}>
                  <Styled.TitleWrapper>
                    <Styled.SecondaryTitle>{block.label}</Styled.SecondaryTitle>
                    {block.search_params &&
                      typeof block.total_count === 'number' &&
                      block.total_count > 0 && (
                        <Button onClick={() => handleViewMore(block.search_params as string)}>
                          View All {block.total_count}
                        </Button>
                      )}
                  </Styled.TitleWrapper>
                  <Styled.StatsCardsWrapper>
                    {(block.data as CompanyActivityCardData[]).map(company => {
                      return <CompanyActivityCard key={company.company_id} data={company} />;
                    })}
                  </Styled.StatsCardsWrapper>
                </Styled.StatsWrapper>
              );
            })
          ) : (
            <Styled.StatsCardsWrapper>
              {Array.from({ length: 4 }).map((_, index) => (
                <CompanyActivityCardSkeleton key={index} />
              ))}
            </Styled.StatsCardsWrapper>
          )}
        </Styled.MainContent>
        <Sidebar />
      </Styled.Wrapper>
    </PrivateComponent>
  );
};

export default AnalystHome;
