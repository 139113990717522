import React from 'react';
// models
import Company from '@optx/models/Company';
import { Cell } from '@models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { cellIsEqual } from '@components/common/table/AntTable/cells/memoize';

const TableCellList: React.FC<Cell<Company>> = ({ value }) => {
  if (value && value.length && value[0] !== '') {
    const formattedValue = value.join(', ');

    return (
      <TruncateTooltip title={formattedValue} placement="left">
        {formattedValue}
      </TruncateTooltip>
    );
  }

  return <>{DEFAULT_CELL_VALUE}</>;
};

export default React.memo(TableCellList, cellIsEqual);
