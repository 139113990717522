import React from 'react';
// models
import { TableCellSourceScrubLists } from '@optx/models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import CellLink from '../CellLink';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const TableCellDefaultTooltip: React.FC<TableCellSourceScrubLists> = ({ value, row }) => {
  return (
    <CellLink id={row.id?.toString()}>
      <TruncateTooltip placement="topLeft" title={value}>
        {value || DEFAULT_CELL_VALUE}
      </TruncateTooltip>
    </CellLink>
  );
};

export default TableCellDefaultTooltip;
