import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
// models
import { RouteRedirectStateProps } from '@models/routes';
// constants
import { CompanyPageRelationshipManagement } from '@optx/constants/pendoActions';
// utils
import { resetHistoryState } from '@utils/routes';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
// components
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';

const AddContactProfile = () => {
  let { state: locationState, search, pathname } = useLocation<RouteRedirectStateProps | null>();
  const history = useHistory();

  const [visible, setVisible] = useState(locationState?.addContacts ?? false);
  const [firstLoad, setFirstLoad] = useState(true);
  const company = useSelector(profileSelectors.getProfile);

  useEffect(() => {
    setFirstLoad(false);

    if (search.endsWith('tab=2&addContact=true') && !locationState) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      locationState = { addTouch: true };
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (!visible && search.endsWith('tab=2&addContact=true') && !firstLoad) {
      history.push({
        pathname,
        search: search.split('&')[0],
      });
    }
  }, [visible, search, firstLoad, history, pathname]);

  // reset history state data in order to hide add contact modal after refresh
  resetHistoryState(history, locationState, 'addContacts');

  const contactData = company
    ? {
        company_id: company.company_id,
        company_name: company.company_name,
        company_url: company.company_url,
      }
    : {};

  return (
    <>
      <Button
        onClick={() => {
          window.pendo.track(CompanyPageRelationshipManagement.primaryContacts_addContact);
          setVisible(true);
        }}
      >
        {' '}
        Add Contact
      </Button>
      <SalesloftAddContact
        visible={visible}
        contactData={contactData}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default AddContactProfile;
