import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Radio, Row, Button } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useDispatch, useSelector } from 'react-redux';
// models
import { AppState } from '@optx/redux/interfaces';
import {
  CustomValue,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';
import { EditFieldRadioRationaleProps } from '@optx/models/feature/edit-field/EditFieldRadioProps';
// constants
import { DEFAULT_FIT_VALUE } from '@optx/constants/table/company';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// components
import VerifyButton from '@optx/shared/view/molecules/Buttons/VerifyButton';
import EditPopover from '@optx/components/common/popover/EditPopover';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import RationaleSection from './RationaleSection';
// redux
import { getFormRationales } from '@optx/redux/company/filters/selectors';
import { actions } from '@optx/features/company/edit-fields/state';
import { getIsPartner } from '@optx/redux/user/information/selectors';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useUpdateFields from '../hooks/useUpdateFields';
// styles
import { Styled } from './EditFieldRadioRationale.styled';

const EditFieldRadioRationale: React.FC<EditFieldRadioRationaleProps> = ({
  record,
  value,
  fieldName,
  labelValue,
  isFromGrid,
  showOnlyRadio,
  service,
  successMessage,
  errorMessage,
  onClick,
  hidePen,
  getPopupContainer,
  isDynamicPopoverHeight,
  destroyOnHide,
  isFromHistory,
  placement,
  updateRadioValue,
  children,
  onChangeRadioValue,
  displayOnlyOptions = false,
  actionPendo,
}) => {
  const dispatch = useDispatch();
  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    service,
    successMessage,
    errorMessage,
    isFromHistory,
  });

  let currRationaleValue: string | null = null;

  if (record)
    currRationaleValue =
      fieldName === 'Software Company'
        ? record.is_software_rationale
        : record.interesting_rationale;

  const [closePopup, setClosePopup] = useState(false);
  const [radioValue, setRadioValue] = useState<boolean>(value as boolean);
  const [selectedValue, setSelectedValue] = useState<string>();
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);
  const [rationaleValue, setRationaleValue] = useState<string | null>(currRationaleValue);

  const isPartnerUser = useSelector(getIsPartner);
  const popoverContentRef = useRef(null);
  const afterValue = useRef<string>();

  const rationaleOptions = useSelector((state: AppState) => {
    switch (fieldName) {
      case 'Software Company':
        return getFormRationales(state, 'is_software_rationales') ?? [];
      case 'PSG Fit':
        return getFormRationales(state, 'psg_fit_rationales') ?? [];
      default:
        return [];
    }
  });

  useEffect(() => {
    setRadioValue(value as boolean);

    if (fieldName === 'Software Company' && record) {
      setRationaleValue(record.is_software_rationale);
    }
  }, [value, record, record?.is_software_rationale, fieldName]);

  useEffect(() => {
    setRadioValue(value as boolean);

    if (fieldName === 'PSG Fit' && record) {
      setRationaleValue(record.interesting_rationale);
    }
  }, [value, record, record?.interesting_rationale, fieldName]);

  useEffect(() => {
    if (fieldName === 'PSG Fit' && updateRadioValue !== undefined) {
      setRadioValue(updateRadioValue);
      setSaveBtnDisabled(false);
      setRationaleValue('');
      setSelectedValue(updateRadioValue as unknown as string);
    }
  }, [fieldName, updateRadioValue]);

  useEffect(() => {
    /* if user change Sowtware value, we shouldn't show any rationale,
     if user switch back, we should show previous value
    */

    if (radioValue !== value) {
      setRationaleValue('');
      setSaveBtnDisabled(false);
    }

    if (
      radioValue === value ||
      (value === null && (radioValue as unknown as string) === DEFAULT_FIT_VALUE)
    ) {
      setRationaleValue(currRationaleValue);
      setSaveBtnDisabled(true);
    }
  }, [currRationaleValue, radioValue, value]);

  const verifyIcon = useMemo(() => {
    const onChangeVerified = () => {
      if (!record?.is_software_verified) {
        dispatch(
          actions.updateVerifyCompanySoftware({
            companyId: record?.company_id ?? 0,
            value: !record?.is_software_verified,
          })
        );

        if (
          afterValue.current !== undefined &&
          afterValue.current !== (record?.is_software as unknown as string)
        ) {
          const payload: UpdateFieldsPayload = {
            value: afterValue.current as unknown as CustomValue,
            companyId: record?.company_id ?? 0,
          };
          dispatch(
            actions.updateFields({
              companyUrl: record?.company_url ?? null,
              companyId: record?.company_id ?? 0,
              value: payload,
              service,
              fieldName,
              isFromHistory,
            })
          );
        }

        setClosePopup(true);
      }
    };

    return (
      <VerifyButton onClick={onChangeVerified} isVerified={record?.is_software_verified ?? false} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, record?.company_id, record?.is_software_verified]);

  const handleCancel = () => {
    setClosePopup(true);
    setSaveBtnDisabled(true);
    afterValue.current = undefined;

    if (!closePopup) {
      setTimeout(() => {
        if (fieldName === 'PSG Fit' && updateRadioValue !== undefined) {
          setRadioValue(updateRadioValue);
          setRationaleValue('');

          return;
        }

        setRadioValue(value as boolean);
        setRationaleValue(currRationaleValue);
      }, 200);
    }
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  if (!record) return null;

  const customLabel = isFromGrid && fieldName === 'Software Company' ? 'Software' : fieldName;

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (key === 'tag') {
      return !!(
        currRationaleValue &&
        currRationaleValue !== DEFAULT_EMPTY_VALUE &&
        currRationaleValue !== null &&
        !values.includes(currRationaleValue)
      );
    }

    if (!rationaleValue) {
      return false;
    }

    return !!(
      !values.includes(rationaleValue) &&
      rationaleValue !== undefined &&
      rationaleValue !== DEFAULT_EMPTY_VALUE &&
      rationaleValue.length > 0
    );
  };

  const customRationaleValue = showIsOtherOrTag() ? 'other' : rationaleValue;

  const handlePopoverLabel = () => {
    if (typeof value === 'boolean') {
      if (fieldName === 'PSG Fit' && isFromGrid) {
        if (value) {
          return <Styled.ThumbsUpIcon iconName="thumbs-up" />;
        }

        return <Styled.ThumbsIcon iconName="thumbs-down" />;
      }

      return value ? 'Yes' : 'No';
    }

    return 'Unknown';
  };

  const handleChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value !== DEFAULT_FIT_VALUE ? e.target.value : null);

    onChangeRadioValue && onChangeRadioValue(e.target.value);
    setRadioValue(e.target.value);

    afterValue.current = e.target.value;
  };

  const handleRationaleChange = (value: string) => {
    setRationaleValue(value);

    setSaveBtnDisabled(false);

    setSelectedValue(radioValue as unknown as string);
  };

  const handleOtherChange = (option: React.SetStateAction<string | null>) => {
    setSelectedValue(radioValue as unknown as string);
    setRationaleValue(option);

    if (option !== value) {
      setSaveBtnDisabled(false);
    }
  };

  const handleSave = () => {
    onClick && onClick();
    updateField(selectedValue, undefined, undefined, undefined, rationaleValue as string);

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    setClosePopup(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <Styled.ContentContainer
      className="profile-rationale"
      ref={popoverContentRef}
      isSoftware={fieldName === 'Software Company'}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Row>
        <Radio.Group onChange={handleChange} value={radioValue}>
          <div style={{ display: 'flex', flexDirection: 'column' }} role="presentation">
            <Radio value>Yes{radioValue && fieldName === 'Software Company' && verifyIcon}</Radio>
            <Radio value={false}>
              No{!radioValue && fieldName === 'Software Company' && verifyIcon}
            </Radio>
            {fieldName === 'PSG Fit' && (
              <Radio value={radioValue === null ? null : DEFAULT_FIT_VALUE}>Unknown</Radio>
            )}
          </div>
        </Radio.Group>
      </Row>
      {(rationaleValue !== DEFAULT_EMPTY_VALUE || rationaleValue !== null) &&
        radioValue === false &&
        displayOnlyOptions === false &&
        !isPartnerUser && (
          <RationaleSection
            rationaleOptions={rationaleOptions}
            rationaleValue={customRationaleValue}
            currRationaleValue={currRationaleValue}
            handleRationaleChange={handleRationaleChange}
            showIsOtherOrTag={showIsOtherOrTag}
            handleOtherChange={handleOtherChange}
          />
        )}
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
          disabled={saveBtnDisabled}
        >
          Save
        </Button>
      </div>
    </Styled.ContentContainer>
  );

  const gridContent = (
    <Styled.TableCellInner>
      {handlePopoverLabel()}
      {!labelValue && record.is_software_verified && fieldName === 'Software Company' && (
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 19, marginLeft: '5px' }} />
      )}
    </Styled.TableCellInner>
  );

  if (isFromGrid) {
    return (
      <InlineEdit
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <EditCellPen
            setClosePopup={setClosePopup}
            closePopup={closePopup}
            content={content}
            title={`Edit "${customLabel}" value`}
          />
        }
        fill
      >
        <div>{gridContent}</div>
      </InlineEdit>
    );
  }

  return showOnlyRadio ? (
    <EditPopover
      content={content}
      title={`Edit "${fieldName}" value`}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePen}
      getPopupContainer={getPopupContainer}
      isDynamicPopoverHeight={isDynamicPopoverHeight}
      destroyOnHide={destroyOnHide}
      placement={placement}
      actionPendo={actionPendo}
    >
      {children}
    </EditPopover>
  ) : (
    <EditPopover
      content={content}
      title={`Edit "${fieldName}" value`}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      hidePenIcon={hidePen}
      getPopupContainer={getPopupContainer}
      isDynamicPopoverHeight={isDynamicPopoverHeight}
      destroyOnHide={destroyOnHide}
      placement={placement}
    >
      {labelValue || handlePopoverLabel()}
      {!labelValue && record.is_software_verified && fieldName === 'Software Company' && (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: 21, marginLeft: 6, position: 'relative', top: 2 }}
        />
      )}
    </EditPopover>
  );
};

export default React.memo(EditFieldRadioRationale);
