import React from 'react';
import { Button } from 'antd';
// components
import Icon from '@optx/components/common/Icon';
import { Styled } from './FormErrorMask.styled';

interface FormErrorMaskProps {
  isChromeExtension: boolean;
}

const FormErrorMask: React.FC<FormErrorMaskProps> = ({ isChromeExtension }) => {
  return (
    <Styled.FormErrorMask $isChromeExtension={!!isChromeExtension}>
      <div>
        <Icon iconName="autosave-refresh" style={{ fontSize: 50 }} />
        <p>Sorry, something is not right.</p>
        <Button
          type="link"
          onClick={event => {
            event.preventDefault();
            window.location.reload();
          }}
        >
          Please try refreshing the page.
        </Button>
      </div>
    </Styled.FormErrorMask>
  );
};

export default FormErrorMask;
