import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import createCachedSelector from 're-reselect';
// models
import { AppState } from '@optx/redux/interfaces';
// redux
import { localSelectors as histogramLocalSelectors } from '@optx/features/histograms/base-histograms';
import { getListsSearchKey } from '@optx/features/grid/searchkey/state/selectors';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import {
  getSearchCountLoading,
  getSearchCount as searchCount,
} from '@optx/features/grid/search-count/state/selectors';
import { getTotalCount } from '@features/grid/search/state/selectorsReusable';

export const selectListsSearchState = (state: AppState) => state.lists.search;

export const isLoadingSearchCount = (isSourceScrub: boolean) =>
  getSearchCountLoading(isSourceScrub ? 'sslists' : 'watchlists');

export const getSorting = createSelector(selectListsSearchState, state => state.sort.sortBy);

export const isMultiSort = createSelector(selectListsSearchState, state => state.sort.multiSort);

export const getClearedFilterValue = createCachedSelector(
  filterSelectors.getClearedFilter('watchlists'),
  (state: AppState, column: string) => column,
  (state, column) => {
    return state[column];
  }
)((state, column) => column);

export const getSearchKey = getListsSearchKey;

/**
 * Show if current search has seach term or a filter set.
 */
export const searchHasValues = createSelector(
  getSearchKey,
  filterSelectors.getFilter('watchlists'),
  (searchKey, filter) => !!searchKey || !isEmpty(filter)
);

// Histogram filters.
const selectHistogramsState = (state: AppState) => state.lists.search.histogramFilters;

export const selectEditHistograms = createSelector(selectHistogramsState, (state: any) => {
  return histogramLocalSelectors.selectEditHistograms(state);
});

export const selectHistograms = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.histograms
);

export const selectAllFiltersWithHistograms = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.allFiltersWithHistograms
);

export const getHistogramSearchCount = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.searchCount
);

export const selectChangedFilters = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.changedFilters
);

export const loadingHistograms = createSelector(selectHistogramsState, state => state.loading);

export const histogramsInitialized = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.isInitialized
);

export const selectHistogramRequestFilters = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.histogramRequestFilters
);

export const selectDefaultHistograms = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.defaultHistograms
);

export const histogramsEnabled = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.isEnabled
);

export const histogramsAreEmpty = createSelector(
  selectHistogramsState,
  histogramLocalSelectors.histogramsAreEmpty
);

export const getSearchCount = (isSourceScrub: boolean) =>
  searchCount(isSourceScrub ? 'sslists' : 'watchlists');

/**
 * Search count for filters modal. Usually if histograms are enabled the call will update this count.
 * When the search is applied the count will be the one in search.
 */
export const getEditModeSearchCount = createSelector(
  getHistogramSearchCount,
  getTotalCount('lists'),
  (histogramSearchCount, searchCount) =>
    typeof histogramSearchCount === 'number' ? histogramSearchCount : searchCount
);
