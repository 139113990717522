import React, { useEffect } from 'react';
import { Row, Typography } from 'antd';
import { useField } from 'formik';
import { useDispatch } from 'react-redux';
// models
import Company from '@models/Company';
// constants
import { similarCompaniesColumns } from './table/columns';
// redux
import { actions } from '../state';
// components
import { Styled } from './AddCompanyModal.styled';

interface Step2Props {
  similarCompanies: Company[] | null;
  handleDisableNextButton: (disabled: boolean) => void;
  changeStep: () => void;
  shouldLoadCompanies: boolean;
}

const rowKey: keyof Company = 'company_id';

/**
 * In Step 2 we show the similar companies.
 */
const AddCompanyStep2: React.FC<Step2Props> = ({
  similarCompanies,
  handleDisableNextButton,
  changeStep,
  shouldLoadCompanies,
}) => {
  const [urlField] = useField<string>('primaryUrl');

  const dispatch = useDispatch();

  useEffect(() => {
    // in case we come back from step 3 to step 2, we should re-enable the next button
    handleDisableNextButton(false);
  }, [handleDisableNextButton]);

  useEffect(() => {
    if (urlField.value?.length && !similarCompanies) {
      shouldLoadCompanies && dispatch(actions.checkSimilarCompanies(urlField.value));
    } else if (similarCompanies?.length === 0) {
      changeStep();
    }
  }, [similarCompanies, urlField.value, dispatch, changeStep, shouldLoadCompanies]);

  if (!similarCompanies) {
    return null;
  }

  return (
    <Styled.Step2>
      <Row className="spacer-bottom">
        <Typography.Text>
          {similarCompanies.length
            ? 'We found these similar companies. Click "Next" if none are a match.'
            : 'No similar companies, you can proceed to the next step.'}
        </Typography.Text>
      </Row>

      <Row>
        <Styled.Table
          columns={similarCompaniesColumns}
          dataSource={similarCompanies}
          className="legacy-table"
          rowKey={rowKey}
          bordered
          showSorterTooltip={false}
          tableLayout={'fixed'}
        />
      </Row>
    </Styled.Step2>
  );
};

export default React.memo(AddCompanyStep2);
