// models
import { SuccessErrorCallback } from '@optx/models/callback';
import { UserInformation } from '@models/user';
import { UpdateUserSettingsPayload } from '@models/api/user';
// redux
import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';

// Update user settings - general

export const updateUserSettings = createAction(
  types.UPDATE_USER_SETTINGS,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsSuccess = createAction<Partial<UpdateUserSettingsPayload>>(
  types.UPDATE_USER_SETTINGS_SUCCESS
);

export const updateUserSettingsFail = createAction<string>(types.UPDATE_USER_SETTINGS_FAIL);

// Update users settings from autosave

export const updateUserSettingsFromAutoSave = createAction(
  types.UPDATE_USER_SETTINGS_FROM_AUTO_SAVE,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsFromAutoSaveSuccess = createAction<
  Partial<UpdateUserSettingsPayload>
>(types.UPDATE_USER_SETTINGS_FROM_AUTO_SAVE_SUCCESS);

export const updateUserSettingsFromAutoSaveFail = createAction<string>(
  types.UPDATE_USER_SETTINGS_FROM_AUTO_SAVE_FAIL
);

// Update users settings for user lists

export const updateUserSettingsForUserList = createAction(
  types.UPDATE_USER_SETTINGS_FOR_USER_LISTS,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsForUserListSuccess = createAction<
  Partial<UpdateUserSettingsPayload>
>(types.UPDATE_USER_SETTINGS_FOR_USER_LISTS_SUCCESS);

export const updateUserSettingsForUserListFail = createAction<string>(
  types.UPDATE_USER_SETTINGS_FOR_USER_LISTS_FAIL
);

// Update users settings for watchlists

export const updateUserSettingsForFavoritesList = createAction(
  types.UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsForFavoritesListSuccess = createAction<
  Partial<UpdateUserSettingsPayload>
>(types.UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS_SUCCESS);

export const updateUserSettingsForFavoritesListFail = createAction<string>(
  types.UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS_FAIL
);

// Update user settings for source scrub list

export const updateUserSettingsForSSList = createAction(
  types.UPDATE_USER_SETTINGS_FOR_SS_LISTS,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);
export const updateUserSettingsForSSListSuccess = createAction<Partial<UpdateUserSettingsPayload>>(
  types.UPDATE_USER_SETTINGS_FOR_SS_LISTS_SUCCESS
);

export const updateUserSettingsForSSListFail = createAction<string>(
  types.UPDATE_USER_SETTINGS_FOR_SS_LISTS_FAIL
);

// Update user settings for saved contact search

export const updateUserSettingsForSavedContactSearches = createAction(
  types.UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsForSavedContactSearchesSuccess = createAction<
  Partial<UpdateUserSettingsPayload>
>(types.UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES_SUCCESS);

export const updateUserSettingsForSavedContactSearchesFail = createAction<string>(
  types.UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES_FAIL
);

// Update users settings for suggested searches

export const updateUserSettingsForSuggestedSearches = createAction(
  types.UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES,
  (payload: Partial<UpdateUserSettingsPayload>, callback?: SuccessErrorCallback) => ({
    payload,
    meta: callback,
  })
);

export const updateUserSettingsForSuggestedSearchesSuccess = createAction<
  Partial<UpdateUserSettingsPayload>
>(types.UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES_SUCCESS);

export const updateUserSettingsForSuggestedSearchesFail = createAction<string>(
  types.UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES_FAIL
);

// Updates for user information

export const fetchUserInformation = createAction(types.FETCH_USER_INFORMATION);

export const fetchUserInformationSuccess = createAction<UserInformation>(
  types.FETCH_USER_INFORMATION_SUCCESS
);

export const fetchUserInformationFail = createAction<string>(types.FETCH_USER_INFORMATION_FAIL);
