import React, { useCallback, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// models
import { SourcingOutreachColumnNamesKey } from '@optx/features/sourcing-outreach-summary/models/SourcingOutreach';
import { SelectOption } from '@optx/models/Option';
import { TableGridQuery } from './interface';
// constants
import { LIST_OF_COLUMN_NAMES } from '@constants/sourcingOutReachColumnNames';
import appRoutes from '@constants/routes';
import { HTML } from '@constants/fullScreen';
// utils
import { getAnalystNames } from '@optx/utils/utils';
// redux
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import {
  selectors as fullscreenSelectors,
  actions as fullScreenActions,
} from '@redux/ui/settings/fullscreen';
import { selectors as outreachSearchSelectors } from '@redux/company-outreach/search';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import {
  actions as filtersActions,
  selectors as filtersSelectors,
} from '@redux/company-outreach/filters';
import { selectors as userInformationSelectors } from '@redux/user/information';
// hooks
import { useInject as useInjectCompanyCard } from '@features/long-card/company-card';
import { useReduxFullScreen } from '@optx/common/hooks/fullscreen';
import { useInject as useInjectCompanyGraphs } from '@features/company/graphs';
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@optx/components/feature/company-individual-edit';
import { useInject as useInjectTouches } from '@features/company-touches';
import { useShouldFetch } from '@optx/common/hooks/fetch';
import { useInjectEditFields } from '@optx/features/company/edit-fields';
import { useInjectCompanySavedSearches } from '@optx/common/hooks/inject';
import { shouldFetchCompaniesOutReach } from './selectors';
import { useFetchSavedSearches, useFetchUserList, useInitFavorites } from '@optx/common/hooks/init';
import { useInjectAddCompany } from '@features/company/add-company';
// components
import SearchGridControls from './components/SearchGridControls';
import { SourcingOutReachContext } from './components/SearchGridControls/SourcingOutReachContext';
import {
  BackButtonText,
  CompanyCardInfo,
  ContainerAnalystName,
  AnalystName,
  CompanyCardContainer,
  FilterContainer,
  FilterSelectContainer,
  FilterDateContainer,
  Container,
} from './CompanyListSourcingOutReach.styled';
import ReportBreadcrumb from './components/ReportBreadcrumb';
import DisplayColumnModal from './components/SearchGridControls/DisplayColumnModal';
import CompanySearchDisplay from './components/CompanySearchDisplay';
import SearchHeader from './components/SearchHeader';
import PageLoader from './PageLoader';
import { SourcingOutReachFilters } from './components/styled';
import CompanyNotes from '@optx/components/CompanyNotes';
import CompanyTouches from '@features/add-touch/components';
import FilterSingleSelectDateRanges from './components/FilterSingleSelectDateRanges';
import Styled from '@features/sourcing-outreach-summary/components/FilterSingleSelectDateFilter.styled';
import FiltersModal from '@optx/features/grid/filter/components';
import EditAllDialog from '@optx/features/edit-all-info/components/EditAllDialog';
import DocumentUploadModal from '@optx/features/add-touch/components/DocumentUploadModal';

const CompanyListSourcingOutReach: React.FC = () => {
  useInjectCompanyCard();
  useInjectCompanyGraphs();
  useInjectCompanySavedSearches();
  useInjectEditFields();
  useInjectCompanyIndividualEdit();
  useFetchUserList();
  useInitialFetchEditOptions();
  useFetchSavedSearches();
  useInjectTouches();
  useInitFavorites();
  useInjectCompanyIndividualEdit();
  useInjectAddCompany();

  const dispatch = useDispatch();
  const filter = useSelector(outreachSearchSelectors.getFilters);
  const persistedTableGridQuery = useSelector(searchSelectors.getTableGridQuery('outreach'));
  const shouldUpdateSourcingOutReachAnalystInfo = useSelector(
    searchSelectors.shouldUpdateSourcingOutReachAnalystInfo
  );
  const [selectedData, setSelectedData] = useState<SelectOption[] | string | boolean>([]);
  const [hasSelectViewChanged, setHasSelectViewChanged] = useState<boolean>(false);
  const [rationaleValue, setRationaleValue] = useState<string>();
  const selectedColumnName = useSelector(
    filtersSelectors.getSourcingOptitonsFilterValues('column_name')
  );
  const selectedAnalystIds = useSelector(
    filtersSelectors.getSourcingOptitonsFilterValues('analyst_id')
  );
  const listOfAnalystIds = useSelector(filtersSelectors.getSourcingFiltersOptions('analyst_id'));
  const fullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const toggle = useCallback(() => dispatch(fullScreenActions.toggleFullScreen()), [dispatch]);
  const keySearch = useSelector(searchSelectors.getSearchKey('outreach'));
  const searchView = useSelector(viewTypeSelectors.searchView('outreach'));
  const shouldFetchCompanies = useSelector(shouldFetchCompaniesOutReach);
  const sessionSettings = useSelector(userInformationSelectors.getSessionSettings);
  const isFirstRender = useRef<boolean>(true);
  const { location, replace } = useHistory();

  const { isFullscreen } = useReduxFullScreen({ dom: HTML, fullscreen, toggle });

  const analystNamesList = listOfAnalystIds.map((analyst: { label: string }) => analyst.label);

  const analystNameSelected = (selectedAnalystIds as unknown as SelectOption<string>[])?.map(
    (analyst: { label: string }) => analyst.label
  );

  const listOfAnalystNamesSelected = analystNamesList.filter((analystName: string) =>
    analystNameSelected?.includes(analystName)
  );

  const query: TableGridQuery | undefined | null = location.state as
    | TableGridQuery
    | undefined
    | null;

  const columnName = `&column_name=${query?.column_name}`;

  const payload = query?.filters?.concat(columnName);

  const tableGridQuery = payload as string;

  const parsed = queryString.parse(sessionSettings.sourcing_outreach_detail_filters!);

  const sessionTableGridQuery = `analyst_id=${parsed.analyst_id}&column_name=${
    parsed.column_name
  }&${sessionSettings.sourcing_outreach_filters?.replace(/analyst_id=[\d,]+&/, '')}`;

  const fetchCompaniesList = useCallback(() => {
    if (shouldUpdateSourcingOutReachAnalystInfo) {
      if (query?.panel_filters) {
        dispatch(
          filtersActions.updateSourcingOutReachFilters({ panelFiltersQuery: query.panel_filters })
        );
      }

      dispatch(
        searchActions.updateSourcingOutReachAnalystInfo({
          tableGridQuery: tableGridQuery || persistedTableGridQuery || sessionTableGridQuery,
        })
      );

      dispatch(
        searchActions.initialCompaniesSearch({
          gridKey: 'outreach',
          data: {
            tableGridQuery: tableGridQuery || persistedTableGridQuery || sessionTableGridQuery,
          },
        })
      );
    }
  }, [
    dispatch,
    persistedTableGridQuery,
    sessionTableGridQuery,
    tableGridQuery,
    shouldUpdateSourcingOutReachAnalystInfo,
    query?.panel_filters,
  ]);

  useEffect(() => {
    if (persistedTableGridQuery && isFirstRender.current) {
      fetchCompaniesList();
    }

    isFirstRender.current = false;
  }, [persistedTableGridQuery, fetchCompaniesList]);

  useShouldFetch(shouldFetchCompanies && !persistedTableGridQuery, () => fetchCompaniesList());

  // function created to handle when user press the back arrow on browser
  useEffect(() => {
    return () => {
      dispatch(filtersActions.resetSessionFilter());
    };
  }, [dispatch]);

  const handleGoBack = () => {
    replace(appRoutes.defaultReports.sourcingOutreachSummary);
    dispatch(filtersActions.resetSessionFilter());
    isFullscreen && dispatch(fullScreenActions.toggleFullScreen());
  };

  const handleColumnName = useCallback(() => {
    const columnName = selectedColumnName as SelectOption<string> | undefined;

    const columnNameValue = columnName?.value;

    return LIST_OF_COLUMN_NAMES[columnNameValue as SourcingOutreachColumnNamesKey];
  }, [selectedColumnName]);

  return (
    <SourcingOutReachContext.Provider
      value={{
        selectedData,
        setSelectedData,
        gridName: 'companiesOutReach',
        hasSelectViewChanged,
        setHasSelectViewChanged,
        rationaleValue,
        setRationaleValue,
      }}
    >
      <Container>
        <PageLoader />
        <SearchHeader />
        <CompanyCardInfo
          style={{
            position: 'sticky',
            top: '62px',
            zIndex: 1,
          }}
        >
          <CompanyCardContainer isLongCard={searchView === 'long-card'}>
            <ReportBreadcrumb
              onClick={() => isFullscreen && dispatch(fullScreenActions.toggleFullScreen())}
              hasSourcingOutReach
              reportPage="Report Details"
            />
            <ContainerAnalystName>
              <ArrowLeftOutlined style={{ fontSize: '25px' }} onClick={() => handleGoBack()} />
              <BackButtonText onClick={() => handleGoBack()}>Back</BackButtonText>
              <Tooltip
                overlayStyle={{ maxWidth: '500px' }}
                title={`Number of ${
                  handleColumnName() ||
                  LIST_OF_COLUMN_NAMES[query?.column_name as SourcingOutreachColumnNamesKey]
                } by ${getAnalystNames(listOfAnalystNamesSelected)}`}
              >
                <AnalystName>
                  {`| Number of ${
                    handleColumnName() ||
                    LIST_OF_COLUMN_NAMES[query?.column_name as SourcingOutreachColumnNamesKey]
                  } by ${getAnalystNames(listOfAnalystNamesSelected)}`}
                </AnalystName>
              </Tooltip>
            </ContainerAnalystName>
            <FilterContainer>
              <FilterSelectContainer>
                <SourcingOutReachFilters filterKey="analyst_id" allowSelectAll />
                <SourcingOutReachFilters filterKey="column_name" />
              </FilterSelectContainer>
              <FilterDateContainer>
                <Styled.StyledSelect>
                  <FilterSingleSelectDateRanges filterKey="ddate" />
                </Styled.StyledSelect>
              </FilterDateContainer>
            </FilterContainer>
            <SearchGridControls />
            <div style={{ marginTop: isFullscreen && searchView === 'table' ? '2rem' : '0' }}>
              {searchView === 'table' && <CompanySearchDisplay />}
            </div>
            <CompanyNotes />
            <CompanyTouches />
            <EditAllDialog />
            <DisplayColumnModal />
          </CompanyCardContainer>
          <FiltersModal
            filter={filter}
            keySearch={keySearch}
            gridName="Companies OutReach"
            gridKey={'outreach'}
          />
        </CompanyCardInfo>
        {searchView === 'long-card' && <CompanySearchDisplay />}
      </Container>
      <DocumentUploadModal />
    </SourcingOutReachContext.Provider>
  );
};

export default CompanyListSourcingOutReach;
