import { ColumnType } from 'antd/lib/table';
// models
import Company, { CompanyProfile } from '@models/Company';
import { CompanyKeys } from '@models/table/Columns';
// components
import { TableCellCompanyURL } from '@components/common/table/Companies/styled-cells';
import { TableCellDefaultTooltip } from '@optx/components/common/table/AntTable/cells';
import { TableCellCompanyName } from './cells';

interface CompanyColumnType extends ColumnType<CompanyProfile> {
  dataIndex: CompanyKeys;
}

const columns: CompanyColumnType[] = [
  {
    dataIndex: 'company_url',
    title: '',
    className: 'company_url',
    render: (value: string | null, record: Company, index: number) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 42,
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    className: 'company_name',
    render: TableCellCompanyName,
    width: 230,
    align: 'left',
  },
  {
    dataIndex: 'year_founded',
    title: 'Year Founded',
    className: 'year_founded',
    width: 114,
    align: 'right',
    render: TableCellDefaultTooltip,
  },
  {
    dataIndex: 'display_location',
    title: 'Location',
    className: 'display_location',
    width: 160,
    align: 'left',
    render: TableCellDefaultTooltip,
  },
  {
    dataIndex: 'company_url',
    title: 'URL',
    className: 'company_url',
    width: 180,
    align: 'right',
    render: TableCellDefaultTooltip,
  },
];

export default columns;
