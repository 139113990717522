import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';

/**
 * Utility hook that checks if a text will overflow a given div element by comparing its widths.
 * @param ref reference to the div element.
 * @param elementText text that will have its width compared to the div element.
 * @param callback if present, this function will executed after every overflow check.
 * @returns a boolean indicating whether the text has overflow or not.
 */
export const useHasOverflow = (
  ref: React.RefObject<HTMLDivElement>,
  elementText: string,
  callback?: (isOverflow: boolean) => void
) => {
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  const checkOverflow = useCallback(() => {
    const { current } = ref;

    if (!current) return;

    const elementWidth = current.offsetWidth;
    const contentWidth = current.scrollWidth;

    if (elementText === DEFAULT_EMPTY_VALUE) {
      setHasOverflow(false);
      if (callback) callback(false);
    } else if (contentWidth && elementWidth) {
      const isContentWider = elementWidth < contentWidth;

      setHasOverflow(isContentWider);
      if (callback) callback(isContentWider);
    }
  }, [callback, elementText, ref]);

  useEffect(() => {
    checkOverflow();

    const currentRef = ref.current;
    const handleResize = debounce(checkOverflow, 100);
    let resizeObserver: ResizeObserver | null = null;

    if (currentRef) {
      resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (resizeObserver && currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [checkOverflow, ref]);

  return hasOverflow;
};
