import styled, { css } from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';
import { Row, Col } from 'antd';

const SearchGridWrapper = styled(Row)<{ $cardView?: boolean }>`
  background-color: rgb(240, 242, 245);
  min-height: 66px;

  padding: 1rem 40px;

  ${props =>
    props.$cardView &&
    css`
      margin-bottom: 0;
    `}
`;

const SearchColWrapper = styled(Col)`
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ControlsWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    margin-left: 8px;
  }

  .ant-btn-icon-only {
    margin-left: 4px;
  }

  .ant-select-selector {
    padding: 0 11px 0 0 !important;
  }
`;

const PageTitle = styled(Title)`
  h1& {
    max-width: 70%;
    margin: 0 20px 0 0;
    min-width: fit-content;
  }
`;

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export default {
  SearchGridWrapper,
  PageTitleWrapper,
  ControlsWrapper,
  SearchColWrapper,
  PageTitle,
};
