import React, { useState } from 'react';
import { Dropdown, Button, Tooltip, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// constants
import { ADD_NEW_COMPANY_MENU_TITLE } from '@constants/titles';
import { MODAL_WRAPPER_TITLE } from '@features/company/add-company/constants';
// utils
import { getDataFromPlugin } from '@features/company/add-company/utils';
// redux
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
// components
import { AddCompanyModalWrapper } from '@features/company/add-company/components';

interface ActionMenuProps {
  isEllipsisWhite?: boolean;
}

/**
 * Action Menu after the settings icon, this is different than the one from bulk companies.
 */
const ActionMenu: React.FC<ActionMenuProps> = ({ isEllipsisWhite }) => {
  const { addCompany } = getDataFromPlugin();
  const filters = useSelector(companyFiltersSelectors.getCompanyFilters);
  const shouldModalBeVisible = filters.length > 0 && addCompany;
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(shouldModalBeVisible);
  const [isAddCompanyModalVisible, setIsAddCompanyModalVisible] =
    useState<boolean>(shouldModalBeVisible);

  const openAddCompany = () => {
    setIsAddCompanyModalVisible(true);
    setIsDropdownVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="add-company-menu" onClick={openAddCompany}>
        {MODAL_WRAPPER_TITLE}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Tooltip title={ADD_NEW_COMPANY_MENU_TITLE} placement="topRight">
        <Dropdown
          visible={isDropdownVisible && !isAddCompanyModalVisible}
          overlay={menu}
          trigger={['click']}
          onVisibleChange={setIsDropdownVisible}
          placement="bottomRight"
          getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        >
          <Button
            type="text"
            icon={
              <EllipsisOutlined
                style={isEllipsisWhite ? { fontSize: 26, color: '#fff' } : { fontSize: 26 }}
              />
            }
          />
        </Dropdown>
      </Tooltip>

      <AddCompanyModalWrapper
        visible={isAddCompanyModalVisible}
        setIsVisible={setIsAddCompanyModalVisible}
        showDropdown={setIsDropdownVisible}
      />
    </>
  );
};

export default React.memo(ActionMenu);
