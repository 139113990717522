import { takeLatest, call, put, select, take } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
// models
import { FilterSource } from '@optx/models/filters';
import { SearchAutocomplete } from '@optx/models/search-autocomplete';
// utils
import { getLocationGridKey } from '@optx/utils/grid';
import { mapPreselectedFilters } from '@utils/filters/preselectedValues';
// services
import { SearchService, SavedSearchesService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import {
  actions as filterSourcesActions,
  selectors as filterSourcesSelectors,
} from '@redux/company/filters';
import { actions as suggestedSearchActions } from '@redux/company/suggested-searches';
import { fetchSearchAutocomplete, fetchSearchAutocompleteSuccess } from './actions';

export function* fetchSearchAutocompleteSaga() {
  try {
    const gridKey = getLocationGridKey();

    if (gridKey === 'advancedSearch') {
      let filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);

      if (!filterSources.length) {
        yield take(filterSourcesActions.fetchCompanyFiltersSuccess);
        filterSources = yield select(filterSourcesSelectors.getCompanyFilters);
      }

      // @ts-ignore
      const res = yield call(SavedSearchesService.getSuggestedSearches);

      if (res.data) {
        yield put(
          suggestedSearchActions.fetchSuggestedSearchesSuccess(
            res.data.lists,
            filterSources,
            mapPreselectedFilters(filterSources, false),
            res.data.total_list_count
          )
        );
      }
    }

    const res: AxiosResponse<Array<SearchAutocomplete>> = yield call(
      SearchService.getCompanySearchAutocomplete
    );

    yield put(fetchSearchAutocompleteSuccess(res.data));
  } catch (error: any) {
    NotificationService.error('Error fetching search autocomplete!');
  }
}

export default function* searchAutocompleteSaga() {
  yield takeLatest(fetchSearchAutocomplete, fetchSearchAutocompleteSaga);
}
