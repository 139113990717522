export const sessionPropList = [
  'contact_filters',
  'company_filters',
  'sourcing_outreach_detail_filters',
  'sourcing_outreach_detail_sorting',
  'my_companies_filters',
  'analysts_leaderboard_filters',
  'company_display_columns',
  'my_companies_columns',
  'user_list_filters',
  'user_list_display_columns',
  'user_list_show_quick_filters',
  'search_view_home',
  'search_view_my_companies',
  'search_view_list',
  'company_sorting',
  'user_list_sorting',
  'favorites_list_sorting',
  'favorites_list_popup_sorting',
  'saved_company_serches_sorting',
  'source_scrub_list_sorting',
  'profile_lists_tab_sorting',
  'my_companies_sorting',
  'contact_sorting',
  'et_form_auto_save',
  'search_results_page_number',
  'contact_results_page_number',
  'my_companies_results_page_number',
  'user_list_search_results_page_number',
  'ss_list_search_results_page_number',
  'source_scrub_list_page_number',
  'favorites_list_page_number',
  'saved_company_serches_page_number',
  'saved_contact_serches_page_number',
  'new_et_companies_page_number',
  'new_et_companies_sorting',
];
