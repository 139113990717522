import { FC } from 'react';
import { Menu, MenuProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
// constants
import { FIND_NEW_COMPANIES_BROWSE_BY_RECENTLY_FUNDED } from '@optx/constants/pendoActions';
// redux
import { actions as searchActions } from '@features/grid/search';
import { isLoading } from './selectors';

interface SuggestedSearchProps extends MenuItemProps {
  title: string;
  label: string;
}

const SuggestedSearch: FC<SuggestedSearchProps> = ({ title, label, onClick, ...restProps }) => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoading) || undefined;

  const handleClick: MenuProps['onClick'] = info => {
    window.pendo.track(FIND_NEW_COMPANIES_BROWSE_BY_RECENTLY_FUNDED);

    dispatch(
      searchActions.loadSuggestedSearch({
        gridKey: 'advancedSearch',
        data: { title },
      })
    );

    onClick && onClick(info);
  };

  return (
    <Menu.Item onClick={handleClick} disabled={loading} {...restProps}>
      {loading && <LoadingOutlined style={{ color: '#4ce1b6' }} />}
      {label}
    </Menu.Item>
  );
};

export default SuggestedSearch;
