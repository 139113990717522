import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import moment, { Moment } from 'moment';
import queryString from 'query-string';
// constants
import globalConfig from '@optx/constants/config';
import { CompanyProfileTabs } from '@optx/constants/routes';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { CompanyPageHistory } from '@optx/constants/pendoActions';
// utils
import { getInternalValue, getOPTXValue } from '@utils/auditFields';
// redux
import { actions as auditActions } from '../state';
import { ChangedCompanyFieldsPayload } from '../state/interfaces';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
// components
import { Separator } from './Separator';
import { Styles } from './AuditTrail.styled';

interface AuditTrailHeaderProps {
  companyId: number;
  activeTab: string;
  activeProfileKey: string;
}

type EventValue<DateType> = DateType | null;
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

export const AuditTrailHeader: React.FC<AuditTrailHeaderProps> = ({
  companyId,
  activeTab,
  activeProfileKey,
}) => {
  const filtersLoaded = useSelector(companyFiltersSelectors.filtersLoaded);
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const fetchFields = useCallback(
    (payload: ChangedCompanyFieldsPayload) => {
      dispatch(auditActions.fetchChangedCompanyFields(payload));
    },
    [dispatch]
  );

  const changeDate = (value: RangeValue<Moment>) => {
    if (value) {
      const [startDate, endDate] = value;

      if (startDate) {
        window.pendo.track(CompanyPageHistory.auditTrail_startDate);
        setStartDate(moment(startDate).format(ISO_DATE_FORMAT));
      } else {
        setStartDate(undefined);
      }

      if (endDate) {
        window.pendo.track(CompanyPageHistory.auditTrail_endDate);
        setEndDate(moment(endDate).format(ISO_DATE_FORMAT));
      } else {
        setEndDate(undefined);
      }
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  useEffect(() => {
    const { field } = queryString.parse(location.search);
    setSearchQuery(field as string);
  }, [location.search]);

  useEffect(() => {
    if (activeProfileKey === CompanyProfileTabs.HISTORY && filtersLoaded) {
      const delayDebounceFn = setTimeout(() => {
        window.pendo.track(CompanyPageHistory.auditTrail_search);

        const currentURL = queryString.parse(location.search);

        if (!searchQuery) {
          delete currentURL.field;
          window.history.replaceState(
            null,
            '',
            `${location.pathname}?${queryString.stringify(currentURL)}`
          );
        }

        fetchFields({
          companyId,
          startDate,
          endDate,
          pagination: { pageNumber: 1, pageSize: 25 },
          field: searchQuery,
          internal: getInternalValue(activeTab),
          optx: getOPTXValue(activeTab),
        });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }

    return () => null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, activeProfileKey, startDate, endDate, filtersLoaded]);

  return (
    <Row gutter={[20, 0]}>
      <Col>
        <Styles.SearchField
          placeholder="Search by field name"
          onChange={e => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </Col>
      <Col>
        <Styles.CustomRangePicker
          placeholder={['Start', 'End']}
          onChange={changeDate}
          format={globalConfig.short_date.DATE_FORMAT}
          separator={<Separator />}
          suffixIcon={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <CalendarOutlined />
              <CalendarOutlined className="left-calendar" />
            </>
          }
        />{' '}
      </Col>
    </Row>
  );
};
