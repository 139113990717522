import React, { useState, useCallback, useEffect, useMemo, MouseEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
// models
import { ParsedUpcomingTouchesNotificationData } from '@optx/models/notifications';
// utils
import { parseDataByNextTime, parseDataByPastTime } from '@utils/helpers';
// redux
import { selectors as filterSelectors } from '@redux/company/filters';
import { selectors as savedSearchSelectors } from '@redux/company/saved-searches';
import { selectors as alertSelectors, actions as alertActions } from '@features/alerts';
import { initialState } from '@features/alerts/state/reducer';
// hooks
import useRecentActivityModal from '@optx/common/hooks/init/useRecentActivityModal';
// components
import { RecentActivity } from '@features/alerts/components';

interface NotificationsProps {
  clickedOutsideNotifications: boolean;
  setClickedOutsideNotifications: (value: React.SetStateAction<boolean>) => void;
}

const Notifications = ({
  clickedOutsideNotifications,
  setClickedOutsideNotifications,
  ...restProps
}: NotificationsProps) => {
  // load filters when first opening the modal
  const dispatch = useDispatch();
  const location = useLocation();

  const filtersLoading = useSelector(filterSelectors.isLoading);
  const [recentActivityModal, toogleRecentActivityModal] = useRecentActivityModal();

  const [inboxNewAlertsCount, setInboxNewAlertsCount] = useState(0);

  const savedSearchLoading = useSelector(savedSearchSelectors.isLoading);
  // load Inbox Alerts
  const fetchInboxAlerts = useCallback(
    () => dispatch(alertActions.fetchAlertsInbox(initialState.inbox)),
    [dispatch]
  );

  // load Upcoming Touches
  const fetchUpcomingTouches = useCallback(
    () => dispatch(alertActions.fetchTouches(initialState.touches.upcoming)),
    [dispatch]
  );

  const views = useSelector(savedSearchSelectors.getViews).views;

  // alerts
  const alertsInboxState = useSelector(alertSelectors.alertsInboxState);
  const alertsHistoryState = useSelector(alertSelectors.alertsHistoryState);
  const alertsUpcomingTouchesState = useSelector(alertSelectors.alertsUpcomingTouchesState);
  const alertsPastDueTouchesState = useSelector(alertSelectors.alertsPastDueTouchesState);
  const alertsNewList = useSelector(alertSelectors.alertsNewList);

  const upcomingTouchesData: ParsedUpcomingTouchesNotificationData | boolean = useMemo(
    () =>
      alertsUpcomingTouchesState.data &&
      alertsUpcomingTouchesState.data.length !== 0 &&
      parseDataByNextTime(alertsUpcomingTouchesState.data),
    [alertsUpcomingTouchesState.data]
  );

  const pastDueTouchesData: ParsedUpcomingTouchesNotificationData | boolean = useMemo(
    () =>
      alertsPastDueTouchesState.data &&
      alertsPastDueTouchesState.data.length !== 0 &&
      parseDataByPastTime(alertsPastDueTouchesState.data),
    [alertsPastDueTouchesState.data]
  );

  const handleDismissNotification = useCallback(
    (id: number) => dispatch(alertActions.dismissAlert(id)),
    [dispatch]
  );

  const handleDeleteUpcomingTouch = useCallback(
    (id: number) => dispatch(alertActions.deleteUpcomingTouch(id)),
    [dispatch]
  );
  const handleDeletePastDueTouch = useCallback(
    (id: number) => dispatch(alertActions.deletePastDueTouch(id)),
    [dispatch]
  );

  const toggleModalHandler = () => {
    toogleRecentActivityModal();

    if (alertsNewList.length && recentActivityModal) {
      dispatch(alertActions.updateAlerts(alertsNewList));
    }
  };

  const handleClick: MouseEventHandler = () => {
    toggleModalHandler();
  };

  useEffect(() => {
    if (recentActivityModal) {
      toogleRecentActivityModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setInboxNewAlertsCount(alertsInboxState.newAlertCount);
  }, [alertsInboxState.newAlertCount]);

  useEffect(() => {
    fetchUpcomingTouches();
    fetchInboxAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clickedOutsideNotifications && recentActivityModal) {
      toggleModalHandler();
    }

    setClickedOutsideNotifications(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedOutsideNotifications, recentActivityModal]);

  return (
    <>
      <span className="ant-menu-title-content-inner" onClick={handleClick} {...restProps}>
        <span className="notifications-anticon-wrapper">
          <Badge
            className="ant-badge--notifications"
            count={inboxNewAlertsCount + ((upcomingTouchesData && upcomingTouchesData.count) || 0)}
            overflowCount={99}
          />
          <BellOutlined />
        </span>
        <span>Notifications</span>
      </span>
      <RecentActivity
        isOpen={recentActivityModal}
        toggleModal={toggleModalHandler}
        alertsInboxState={alertsInboxState}
        alertsUpcomingTouchesState={alertsUpcomingTouchesState}
        alertsPastDueTouchesState={alertsPastDueTouchesState}
        alertsPastDueTouchesData={pastDueTouchesData}
        alertsUpcomingTouchesData={upcomingTouchesData}
        alertsHistoryState={alertsHistoryState}
        alertsNewList={alertsNewList}
        onDismissNotification={handleDismissNotification}
        onDismissUpcomingTouch={handleDeleteUpcomingTouch}
        onDismissPastDueTouch={handleDeletePastDueTouch}
        filtersLoading={filtersLoading || savedSearchLoading}
        views={views}
      />
    </>
  );
};

export default Notifications;
