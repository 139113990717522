import styled from 'styled-components';
import { Typography, Tag } from 'antd';

const { Paragraph } = Typography;

const CardTitle = styled.span`
  /* Blue */
  color: #334d6e;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow: hidden;
  position: relative;
  z-index: 2;
  line-height: 24px;
  min-height: 72px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;

  & > p {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    overflow-wrap: break-word;
    margin: 0;
  }
`;

const SubTitle = styled(Paragraph)`
  /* Grey */
  color: #595959;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const TextBlock = styled(Paragraph)`
  margin-bottom: 11px !important;

  @media (max-width: 1440px) {
    margin-bottom: 0 !important;
  }
`;

const HighlightedBlock = styled(Paragraph)`
  font-size: 12px;
  font-weight: 600;
  color: #1890ff;
  margin-bottom: 0 !important;
`;

const DateBlock = styled(Paragraph)`
  font-size: 12px;
  color: #90a0b7;
  margin-bottom: 0 !important;
`;

const StyledTag = styled(Tag)`
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
`;

const Styled = {
  CardTitle,
  SubTitle,
  StyledTag,
  TextBlock,
  HighlightedBlock,
  DateBlock,
};

export default Styled;
