import React, { useMemo, useRef, useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import { ValueType } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
// interface
import { SelectOption } from '@optx/models/Option';
// hooks
import useAutoFocusSelect from '@optx/common/hooks/useAutoFocusSelect';
// components
import {
  MultiValueRemove,
  MultiValueLabel,
  ValueContainer,
  DropdownIndicator,
  Option,
  MultiSelectTags,
} from '../components';
// Local
import { AsyncSelectProps } from '../interfaces';
import { isOptionDisabled } from '../utils';
import { Styled } from '../MultiSelect/MultiSelect.styled';

const defaultComponents: Partial<SelectComponents<SelectOption>> = {
  MultiValueLabel,
  MultiValueRemove,
  ValueContainer,
  DropdownIndicator,
  Option,
};

interface AsyncMultiSelectProps extends AsyncSelectProps {
  isCreatable?: boolean;
  autoFocus?: boolean;
}

const AsyncMultiSelect: React.FC<AsyncMultiSelectProps> = ({
  onChange,
  className,
  components,
  defaultValue,
  isCreatable,
  autoFocus = true,
  ...restProps
}) => {
  // Compute value needed for tags. In AsyncSelect the props value and options can't control select.
  const [value, setValue] = useState<ValueType<SelectOption>>(defaultValue);
  const selectRef = useRef<AsyncSelect<SelectOption> | null>(null);

  useAutoFocusSelect(selectRef, autoFocus, 'multiselect__input');

  const handleChange = (value: ValueType<SelectOption>, actionMeta: any) => {
    setValue(value);
    onChange && onChange(value, actionMeta);
  };

  const handleTagChange = (value: ValueType<SelectOption>, actionMeta: any) => {
    if (selectRef.current && selectRef.current.select) {
      // @ts-ignore select is actually StateManager,
      // but the Typescript is different for some reason and thinks is a Ref object.
      selectRef.current.select.onChange(value, actionMeta);
    }
  };

  const selectComponents = useMemo(() => {
    if (components) {
      return {
        ...defaultComponents,
        ...components,
      };
    }

    return defaultComponents;
  }, [components]);

  useEffect(() => {
    if (!defaultValue) {
      setValue([]);
    } else {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Styled.MultiSelectWrapper className={className}>
      {isCreatable ? (
        <AsyncCreatable
          ref={selectRef as any}
          onChange={handleChange}
          components={selectComponents}
          defaultValue={defaultValue}
          value={value}
          {...restProps}
          // Leave properties at the end to not be overriden.
          backspaceRemovesValue={false}
          isMulti
        />
      ) : (
        <AsyncSelect
          ref={selectRef}
          onChange={handleChange}
          components={selectComponents}
          defaultValue={defaultValue}
          value={value}
          {...restProps}
          // Leave properties at the end to not be overriden.
          backspaceRemovesValue={false}
          isMulti
        />
      )}
      <MultiSelectTags
        value={value as Array<SelectOption>}
        options={[]}
        onChange={handleTagChange}
      />
    </Styled.MultiSelectWrapper>
  );
};

AsyncMultiSelect.defaultProps = {
  placeholder: 'search',
  classNamePrefix: 'multiselect',
  isClearable: false,
  hideSelectedOptions: false,
  closeMenuOnSelect: false,
  controlShouldRenderValue: false,
  isOptionDisabled,
};

export default AsyncMultiSelect;
