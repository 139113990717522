import React from 'react';
import { Tooltip } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// utils
import { breakLines } from '@utils/breakLines';
// components
import CopyButton from '../../../CopyButton';
import { RecommendedContainer } from '@optx/shared/view/atoms/Typography';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
// styles
import { InlineBlockLink, TooltipDefaultLabel } from './TableCellLink.styled';

export const TableCellEmail = (value: any, record: any) => {
  if (!value) {
    return (
      <TruncateTooltip
        title={
          record.recommended_email && (
            <CopyButton
              copyText={record.recommended_email}
              text={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <RecommendedContainer>
                  <b>SUGGESTED: </b>
                  {record.recommended_email}
                </RecommendedContainer>
              }
            />
          )
        }
      >
        <TooltipDefaultLabel>{DEFAULT_CELL_VALUE}</TooltipDefaultLabel>
      </TruncateTooltip>
    );
  }

  return (
    <>
      <Tooltip title={<CopyButton list={breakLines(value)} />}>
        <InlineBlockLink href={`mailto:${value}`} target="_blank" rel="noopener noreferrer">
          <span className="text-ellipsis">{value}</span>
        </InlineBlockLink>
      </Tooltip>
    </>
  );
};

export default TableCellEmail;
