import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import Overflow from 'rc-overflow';
import { Typography, Button, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
// models
import { TouchFieldProps } from './interface';
import { SelectOption } from '@optx/models/Option';
// components
import MultiSelectAsyncCreatableField from './MultiSelectAsyncCreatableField';
// styles
import Styled from './MultiSelectField.styled';

const MultiSelectAsyncFieldWrapper: React.FC<TouchFieldProps> = ({ field, fieldUsedFor }) => {
  const [selectedList, , setSelectedList] = useField<string[] | null>(field.id);
  const [selected, setSelected] = useState<SelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filterTagValues, setFilterTagValues] = useState<SelectOption[]>(field.value ?? []);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    setSelectedOptions(selected);
    setFilterTagValues(selected ?? []);

    const selectedArr = selected.map(item => item.value);

    if (!isEqual(selectedArr, selectedList.value)) {
      setSelectedList.setValue(selectedArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (fieldUsedFor === 'editAllInfo' && selectedList.value && selectedList.value.length) {
      setSelected(selectedList.value.map((item: string) => ({ label: item, value: item })));
    } else if (field.value && field.value.length) {
      setSelected(field.value.map((item: string) => ({ label: item, value: item })));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  const handleCancel = () => {
    setIsVisible(false);
    setSelectedOptions(selected);
    setApplyDisabled(true);
  };

  const handleApply = () => {
    setIsVisible(false);
    setSelected(selectedOptions);
    setApplyDisabled(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const changeHandler = (value: SelectOption[]) => {
    setSelectedOptions(value);
    setApplyDisabled(false);
  };

  const menu = (
    <Styled.MenuWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div>
        <MultiSelectAsyncCreatableField
          label={field.label}
          endpoint={field.endpoint ?? ''}
          selectedOptions={selectedOptions}
          changeHandler={changeHandler}
        />
        <Row justify="end" className="menu-buttons-wrapper">
          <Button style={{ marginRight: 8 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleApply} disabled={applyDisabled}>
            Apply
          </Button>
        </Row>
      </div>
    </Styled.MenuWrapper>
  );

  return (
    <Styled.SelectWrapper span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <Styled.DropdownWrapper
        visible={isVisible}
        onVisibleChange={flag => {
          !flag ? handleCancel() : setIsVisible(flag);
        }}
        overlay={menu}
        trigger={['click']}
        placement="topRight"
        getPopupContainer={trigger => trigger.parentElement!}
      >
        <Button>
          <Styled.TriggerWrapper>
            <Overflow
              data={
                filterTagValues &&
                filterTagValues.map(item => ({
                  label: item.label,
                  value: item.value,
                  uniqueKey: item.value,
                }))
              }
              renderItem={(item: SelectOption) => (
                <div className="option-label">
                  {item.label}
                  <span className="comma">,</span>
                </div>
              )}
              renderRest={items => <div className="more-label">+ {items.length} more</div>}
              maxCount="responsive"
              style={{ justifyContent: 'flex-start' }}
            />
          </Styled.TriggerWrapper>
          <DownOutlined />
        </Button>
      </Styled.DropdownWrapper>
    </Styled.SelectWrapper>
  );
};

export default React.memo(MultiSelectAsyncFieldWrapper);
