import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const StyledDefaultCell = styled(Typography.Text)`
  max-width: 100%;
  margin-bottom: 0 !important;
`;

interface TableCellDefaultProps {
  value: any;
}

export const TableCellDefault: React.FC<TableCellDefaultProps> = ({ value, ...restProps }) => {
  return (
    <StyledDefaultCell {...restProps}>
      {value ? <TruncateTooltip title={value}>{value}</TruncateTooltip> : DEFAULT_CELL_VALUE}
    </StyledDefaultCell>
  );
};

/**
 *
 * @param value Default cell render for table column.
 */
const TableCellDefaultRender = (value: any) => {
  return <TableCellDefault value={value} />;
};

export default TableCellDefaultRender;
