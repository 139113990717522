import queryString from 'query-string';
import { Dictionary } from 'lodash';
import { CompanyUserContact, SearchPayloadRequest } from '@models/api/contacts';
import { SearchAutocomplete } from '@optx/models/search-autocomplete';
import { SearchType } from '@optx/models/api/search';
import { GeoRangePayload } from '../../redux/geo-range/interfaces';

import ApiClient, { API_URL } from './axios-client';
import { mapSearchQuery, mapSearchCounterQuery } from '../../utils/search';

class SearchService {
  static client: ApiClient = new ApiClient(`${API_URL}/search`);

  static searchSource?: SearchType;

  static get searchHeaders() {
    if (SearchService.searchSource) {
      return {
        headers: {
          'search-source': SearchService.searchSource,
        },
      };
    }

    return {};
  }

  // company
  static searchCompanyCount = (
    searchKey: string,
    filter: Dictionary<string | Array<string | number>>,
    fromSavedList?: number | string,
    listType?: string
  ) => {
    const payload = mapSearchCounterQuery(searchKey, filter, undefined, undefined, listType);

    if (fromSavedList) {
      payload.from_saved_list = fromSavedList;

      if (listType === 'combined') {
        payload.combined_search_id = fromSavedList;
      }
    }

    if (payload.search_title === '') {
      delete payload.search_title;
    }

    const queryData = queryString.stringify(payload, { arrayFormat: 'comma' });

    return SearchService.client.get<number>(`/count?${queryData}`, SearchService.searchHeaders);
  };

  static searchCompanies = (searchData: SearchPayloadRequest, addSearchHeader = true) => {
    const performanceTesting = localStorage.getItem('performance_testing');
    const payload = mapSearchQuery(searchData, undefined, performanceTesting);
    const queryData = queryString.stringify(payload, { arrayFormat: 'comma' });

    const query =
      searchData.listType === 'combined'
        ? `combined_search_id=${searchData.fromSavedList}&${queryData}`
        : queryData;

    // save 'search-source' header received from search request
    return SearchService.client
      .get(`?${query}`, addSearchHeader ? SearchService.searchHeaders : {})
      .then(response => {
        // @ts-ignore
        SearchService.searchSource = response.headers['search-source'];

        return response;
      });
  };

  // contacts
  static searchContacts(searchData: SearchPayloadRequest) {
    const payload = mapSearchQuery(searchData, undefined, null, false);

    return SearchService.client.get<Array<CompanyUserContact>>(
      `/contacts?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  }

  static searchContactsCounter = (
    searchKey: string,
    filter: Dictionary<string | Array<string | number>>
  ) => {
    const payload = mapSearchCounterQuery(searchKey, filter);

    return SearchService.client.get<boolean>(
      `/count_contacts?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  };

  static getCompanySearchAutocomplete() {
    return SearchService.client.get<Array<SearchAutocomplete>>('/recent');
  }

  /**
   * This endpoint exists to calculate the postal code using the calc "country + zip" of
   * different countries than USA. It was implemented as a GET, but in reality it's
   * value it's not used in the UI.
   */
  static getGeoRangeOptions(payload: GeoRangePayload) {
    const query = queryString.stringify(payload, { arrayFormat: 'comma' });

    return SearchService.client.get(`/postal_code?${query}`);
  }

  static getIsUrlAvailable = (url: string) => {
    const payload = { url };

    return SearchService.client.get(`/is_url_available?${queryString.stringify(payload)}`);
  };

  static getSimilarCompaniesByURL = (url: string) => {
    const payload = { url };

    return SearchService.client.get(`/get_similar_urls?${queryString.stringify(payload)}`);
  };
}

export default SearchService;
