export const OPTX_SCORE_RATIONALE_KEY = 'optx_score_rationales';
export const OPTX_SCORE_LOWER_RATIONALE_KEY = 'optx_score_lower_rationales';
export const OPTX_SCORE_UPPER_RATIONALE_KEY = 'optx_score_upper_rationales';
export const NEW_OPTX_SCORE_RATIONALE_KEY = 'optx_score_v3_rationales';
export const NEW_OPTX_SCORE_LOWER_RATIONALE_KEY = 'optx_score_v3_lower_rationales';
export const NEW_OPTX_SCORE_UPPER_RATIONALE_KEY = 'optx_score_v3_upper_rationales';

export const SOFTWARE_RATIONALE_KEY = 'is_software_rationales';
export const PSG_FIT_RATIONALE_KEY = 'psg_fit_rationales';
export const STAGE_RATIONALE_KEY = 'stage_pass_dead_rationales';

export const SOFTWARE_CARD_NAME = 'Software';
export const FIT_CARD_NAME = 'PSG Fit';
export const SCORE_CARD_NAME = 'Score';
export const NEW_SCORE_CARD_NAME = 'New Score';
