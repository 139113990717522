import { useEffect } from 'react';
import { Space } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { actions as scheduledTouchesActions } from '@optx/redux/scheduled-touches';
import { showClearedFilter } from '@optx/redux/scheduled-touches/selectors';
// hooks
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@optx/components/feature/company-individual-edit';
import { useInjectScheduledTouches } from '@hooks/inject';
// components
import { MainHeader, MainContent } from '@optx/components/common/layout';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import {
  ScheduledTouchesSearch,
  ShowcasePanel,
  TouchesList,
  ScheduledTouchesPageLoader,
  TouchTypeInsight,
} from './components';
import { Wrapper } from './ScheduledTouches.styled';
import FilterTags from './components/FilterTags';

const ScheduledTouches = () => {
  const dispatch = useDispatch();
  useInjectCompanyIndividualEdit();
  useInitialFetchEditOptions();
  useInjectScheduledTouches();

  const contentClassNames = classNames('main-content grid-page has-search');
  const showClearButton = useSelector(showClearedFilter);

  useEffect(() => {
    dispatch(scheduledTouchesActions.fetchScheduledTouchesDashboard());
  }, [dispatch]);

  const handleClearScheduledTouchesFilters = () => {
    dispatch(scheduledTouchesActions.resetScheduledTouchesFilter());
    dispatch(scheduledTouchesActions.fetchScheduledTouchesDashboard());
  };

  // redirecting from other pages can cause the page to be out of visible reach
  // if user has a big scroll gap, so we scroll back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainHeader className="main-header">
        <div className="search-header-content">
          <ScheduledTouchesSearch className="antd-search-input" autoComplete="off" disabled />
          <FilterTags />
          <ClearSearchButton
            title="Clear All"
            placement="bottom"
            isVisible={showClearButton}
            onClick={() => handleClearScheduledTouchesFilters()}
          />
          <Space size={4} style={{ marginLeft: 'auto' }}>
            <ClearSearchButton title="Clear Search" isVisible={false} onClick={() => {}} />
          </Space>
        </div>
      </MainHeader>
      <Wrapper>
        <MainContent className={contentClassNames}>
          <ShowcasePanel />
          <TouchesList />
        </MainContent>
        <TouchTypeInsight />
      </Wrapper>
      <ScheduledTouchesPageLoader />
    </div>
  );
};

export default ScheduledTouches;
