import { useCallback } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
// models
import { PreselectedFilter } from '@optx/models/filters';
// redux
import {
  actions as contactsSearchActions,
  selectors as contactsSearchSelectors,
} from '@redux/contacts/search/search';
import { selectors as contactsFilters } from '@redux/contacts/filters';
import { selectors as modalSelectors, actions as modalActions } from '@redux/ui/modals/gridModals';
// components
import FilterForm from '../FilterForm';

const FilterModal = () => {
  const dispatch = useDispatch();

  const filterSources = useSelector(contactsFilters.getFilterSources);
  const searchKey = useSelector(contactsSearchSelectors.getSearchKey);

  const search = useCallback(
    (searchKey?: string, filter?: Dictionary<PreselectedFilter>) =>
      dispatch(contactsSearchActions.searchContacts({ searchKey, filter })),
    [dispatch]
  );
  const reset = useCallback(
    (clearFilter: boolean) => dispatch(contactsSearchActions.reset(clearFilter)),
    [dispatch]
  );

  // modal
  const modalIsOpen = useSelector(modalSelectors.isOpen('contacts'));
  const toggleModalFilters = () => dispatch(modalActions.toggleModal({ gridKey: 'contacts' }));

  const handleSearch = (key: string, preselectedFilter?: Dictionary<PreselectedFilter>) => {
    search(key, preselectedFilter);
    toggleModalFilters();
  };

  return (
    <Modal
      className="ant-modal-full ant-modal--filters"
      title={null}
      visible={modalIsOpen}
      footer={null}
      onCancel={toggleModalFilters}
      closable={false}
    >
      <FilterForm
        filterSources={filterSources}
        keySearch={searchKey}
        onClearFilter={reset}
        onSearch={handleSearch}
        onClose={toggleModalFilters}
      />
    </Modal>
  );
};

export default FilterModal;
