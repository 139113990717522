import moment from 'moment';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { Form } from 'antd';
//models
import { CompanyProfile } from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
import { ValueType } from 'react-select';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
//redux
import { actions, selectors } from '@redux/company/opportunity-presentation';
//components
import InputNumberField from '@optx/features/field-factory/InputNumberField';
import InputField from '@optx/features/field-factory/InputField';
import DatepickerField from '@optx/features/field-factory/DatepickerField';
import SingleSelectAsyncField from '@optx/features/field-factory/SingleSelectAsyncField';
import AsyncCreatableSelectLastInvestors from '@optx/features/field-factory/AsyncCreatableSelectLastInvestors';
import {
  FundingOwnershipStructureWrapper,
  FundingOwnershipStructureValue,
  FundingOwnershipStructureLabel,
  FirstFundingOwnershipStructureRowContainer,
  SecondFundingOwnershipStructureRowContainer,
} from '../styles/ProprietaryInfoCards.styled';

interface FundingOwnershipStructureProps {
  company: CompanyProfile;
  isChromeExtension: boolean;
}

const FundingOwnershipStructure: React.FC<FundingOwnershipStructureProps> = ({
  company,
  isChromeExtension,
}) => {
  const dispatch = useDispatch();
  const fundingOwnershipInfo = useSelector(selectors.getFundingOwnershipData);
  const formRef = useRef<FormikProps<any> | null>(null);
  let fieldName: string | undefined = undefined;
  let parentCompany: ValueType<SelectOption<string>> | undefined;

  const handleUpdateField = (
    fieldType?: string,
    acquiringCompany?: ValueType<SelectOption<string>>
  ) => {
    fieldName = fieldType;
    parentCompany = acquiringCompany;

    if (formRef?.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = () => {
    if (formRef?.current) {
      const lastRoundDate =
        formRef.current?.values.last_round_date ||
        fundingOwnershipInfo?.last_round_date ||
        moment().format(ISO_DATE_FORMAT);

      // last round amount inside input gets formatted to millions (ex: 1M),
      // so we need to convert it back before send to endpoint
      const lastRoundAmount =
        fundingOwnershipInfo?.last_round_amount !== formRef.current?.values.last_round_amount
          ? formRef.current?.values.last_round_amount * 1000000
          : fundingOwnershipInfo?.last_round_amount;

      let fundingPayload = null;

      if (formRef.current?.values[fieldName as string]?.label === 'Acquisition') {
        fundingPayload = {
          last_round_amount: lastRoundAmount,
          last_round_date: lastRoundDate,
          [fieldName as string]: formRef.current?.values[fieldName as string],
          acquiring_company: (parentCompany as SelectOption).value,
        };
      } else if (fieldName === 'capital_raised') {
        fundingPayload = {
          [fieldName as string]: formRef.current?.values[fieldName as string],
        };
      } else {
        fundingPayload = {
          last_round_amount: lastRoundAmount,
          last_round_date: lastRoundDate,
          [fieldName as string]: formRef.current?.values[fieldName as string],
        };
      }

      if (fundingPayload) {
        dispatch(
          actions.updateOpportunityPresentation({
            company_id: company.company_id,
            funding: fundingPayload,
          })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{ acc: null }}
      onSubmit={handleSubmit}
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      <Form>
        <FundingOwnershipStructureWrapper $isChromeExtension={isChromeExtension}>
          <FirstFundingOwnershipStructureRowContainer>
            <FundingOwnershipStructureLabel $isChromeExtension={isChromeExtension}>
              Total Raised
            </FundingOwnershipStructureLabel>
            <FundingOwnershipStructureValue $isChromeExtension={isChromeExtension}>
              <InputNumberField
                fieldName="Total Raised"
                value={fundingOwnershipInfo?.capital_raised}
                fieldType="capital_raised"
                hasUnknown={company.is_capital_raise_has_unknown}
                onSave={handleUpdateField}
              />
            </FundingOwnershipStructureValue>
            <FundingOwnershipStructureLabel $isChromeExtension={isChromeExtension}>
              Cap Table
            </FundingOwnershipStructureLabel>
            <FundingOwnershipStructureValue $isChromeExtension={isChromeExtension}>
              <InputField
                fieldName="Cap Table"
                value={fundingOwnershipInfo?.cap_table as unknown as string}
                fieldType="cap_table"
                onSave={handleUpdateField}
              />
            </FundingOwnershipStructureValue>
            <FundingOwnershipStructureLabel $isChromeExtension={isChromeExtension}>
              Date of Last Round
            </FundingOwnershipStructureLabel>
            <FundingOwnershipStructureValue $isChromeExtension={isChromeExtension}>
              <DatepickerField
                value={fundingOwnershipInfo?.last_round_date as string}
                fieldName="Date of Last Round"
                fieldType="last_round_date"
                onSave={handleUpdateField}
              />
            </FundingOwnershipStructureValue>
          </FirstFundingOwnershipStructureRowContainer>

          <SecondFundingOwnershipStructureRowContainer $isChromeExtension={isChromeExtension}>
            <FundingOwnershipStructureLabel
              $isChromeExtension={isChromeExtension}
              style={{ minWidth: `${isChromeExtension ? '65%' : '55%'}` }}
            >
              Last Round Type
            </FundingOwnershipStructureLabel>
            <FundingOwnershipStructureValue $isChromeExtension={isChromeExtension}>
              <SingleSelectAsyncField
                fieldName="Last Round Type"
                fieldType="last_round"
                parentCompany={company.parentcompany}
                value={fundingOwnershipInfo?.last_round.value as any}
                isChromePlugin={isChromeExtension}
                options={fundingOwnershipInfo?.last_round.data}
                onSave={handleUpdateField}
              />
            </FundingOwnershipStructureValue>
            <FundingOwnershipStructureLabel
              $isChromeExtension={isChromeExtension}
              style={{ minWidth: `${isChromeExtension ? '65%' : '55%'}` }}
            >
              Last Round Amount
            </FundingOwnershipStructureLabel>
            <FundingOwnershipStructureValue $isChromeExtension={isChromeExtension}>
              <InputNumberField
                fieldName="Last Round Amount"
                value={fundingOwnershipInfo?.last_round_amount}
                isChromePlugin={isChromeExtension}
                fieldType="last_round_amount"
                onSave={handleUpdateField}
              />
            </FundingOwnershipStructureValue>
            <FundingOwnershipStructureLabel
              $isChromeExtension={isChromeExtension}
              style={{ minWidth: `${isChromeExtension ? '65%' : '55%'}` }}
            >
              Investor Name
            </FundingOwnershipStructureLabel>
            <FundingOwnershipStructureValue $isChromeExtension={isChromeExtension}>
              <AsyncCreatableSelectLastInvestors
                fieldName="Investor Name"
                fieldType="last_investors"
                value={company.last_investors?.[0]}
                isChromePlugin={isChromeExtension}
                onSave={handleUpdateField}
              />
            </FundingOwnershipStructureValue>
          </SecondFundingOwnershipStructureRowContainer>
        </FundingOwnershipStructureWrapper>
      </Form>
    </Formik>
  );
};

export default FundingOwnershipStructure;
