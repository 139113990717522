/* eslint-disable react/jsx-wrap-multilines */

import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// models
import { UserList } from '@optx/redux/user/user-list/interfaces';
import { ListAccessRights } from '@optx/models/WatchList';
// constants
import { FAVORITE_LIST_ID } from '@constants/lists';
import { FindNewCompaniesSourceScrubLists } from '@optx/constants/pendoActions';
// components
import DropdownInput from '../../../../common/DropdownInput';
import { useToggle } from '../../../../../common/hooks/modal';
import ListActionsMenu from './ListActionsMenu';

interface ListActionsProps {
  isRight: boolean;
  type?: string;
  title: string;
  listId: number | string;
  userListShare?: Array<UserList>;
  sharedWith?: Array<number>;
  handleDeleteList: (listId: number | string) => void;
  handleRenameList: (title: string, listId: number | string) => void;
  handleRemoveList?: (listId: number | string) => void;
  handleGetInput?: (title: string) => void;
  onDownloadList?: (listId: string, fileName: string, includeDescription?: boolean) => void;
  accessRights: ListAccessRights;
  isContacts?: boolean;
  ownerName?: string;
  listAssociation?: string | undefined;
  isOwner?: boolean;
  hasFilters?: boolean;
}

const ListActions: React.FC<ListActionsProps> = ({
  isRight,
  type,
  title,
  listId,
  userListShare,
  sharedWith,
  handleDeleteList,
  handleRemoveList,
  handleRenameList,
  handleGetInput,
  onDownloadList,
  accessRights,
  isContacts,
  ownerName,
  listAssociation,
  isOwner,
  hasFilters,
}) => {
  const [dropdownInput, toggleInput] = useToggle(false);
  const [visible, setVisible] = useState(false);

  const renameList = (title: string) => {
    handleRenameList(title, listId);
  };

  const handleConfirm = (includeDescription = false) => {
    onDownloadList &&
      onDownloadList(
        title === 'Favorites' ? FAVORITE_LIST_ID : listId.toString(),
        title,
        includeDescription
      );
    setVisible(false);
  };

  const handleVisible = () => {
    if (onDownloadList) {
      window.pendo.track(FindNewCompaniesSourceScrubLists.source_scrub_lists_action);
    }

    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="dropdown-btn dropdown-dot dropdown-dot-list">
      <Dropdown
        overlay={
          <ListActionsMenu
            type={type}
            listId={listId}
            title={title}
            onVisible={() => setVisible(false)}
            userListShare={userListShare}
            sharedWith={sharedWith}
            toggleInput={toggleInput}
            handleDeleteList={listId => {
              setVisible(false);
              handleDeleteList(listId);
            }}
            handleRemoveList={listId => {
              setVisible(false);
              handleRemoveList && handleRemoveList(listId);
            }}
            onDownloadList={onDownloadList}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            accessRights={accessRights}
            isContacts={isContacts}
            ownerName={ownerName}
            listAssociation={listAssociation}
            isOwner={isOwner}
            hasFilters={hasFilters}
          />
        }
        getPopupContainer={() => document.body}
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        onVisibleChange={handleVisible}
      >
        <EllipsisOutlined className="dropdown-dot__icon" />
      </Dropdown>
      <DropdownInput
        title={title}
        name={`Rename ${type}`}
        isRight={isRight}
        isOpen={dropdownInput}
        onToggle={toggleInput}
        onRenameList={renameList}
        onInputChange={handleGetInput}
      />
    </div>
  );
};

ListActions.defaultProps = {
  isRight: false,
  type: '',
  title: '',
  listId: 0,
  handleDeleteList: () => {},
  handleRenameList: () => {},
  handleGetInput: () => {},
};

export default ListActions;
