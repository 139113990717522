import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Radio, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
// models
import { DateRangeOption } from '@optx/models/Option';
// utils
import { formatDateInterval } from '@optx/utils/format';
// redux
import { getDates, getSelectedDate } from '@redux/scheduled-touches/selectors';
import { changeSelectedDate, changeSelectedPeriod } from '@redux/scheduled-touches/actions';
// components
import RadioButtonPicker from './RadioButtonPicker';

interface SelectDateProps {
  selectedCard: string;
  setSelectedCard: React.Dispatch<React.SetStateAction<string>>;
}

const SelectDate: React.FC<SelectDateProps> = ({ selectedCard, setSelectedCard }) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hoverDate, setHoverDate] = useState<string>();
  const data = useSelector(getDates) as Array<DateRangeOption>;
  const value = useSelector(getSelectedDate);
  const lastIndex = data.length - 1;

  const handleChange = (event: RadioChangeEvent) => {
    const index = event.target.value;
    const option = data[index];
    setSelectedIndex(index);
    dispatch(changeSelectedPeriod(option.label!));
    setSelectedCard(option.label!);

    // Don't call change action when clicking on date picker toggler.
    if (index === data.length - 1) {
      return;
    }

    dispatch(changeSelectedDate([option.start, option.end]));
  };

  const onMouseEnter = (date: string) => {
    // waiting for 100ms because of the tooltip delay
    setTimeout(() => {
      setHoverDate(date);
    }, 100);
  };

  const selectedValue = useMemo(() => {
    const index = data.findIndex(option => option.start === value[0] && option.end === value[1]);

    return index;
  }, [value, data]);

  return (
    <Radio.Group onChange={handleChange} value={selectedValue}>
      {data.map((option, index) => {
        if (lastIndex === index) {
          return (
            <RadioButtonPicker
              option={option}
              key={index}
              value={index}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              setSelectedCard={setSelectedCard}
            />
          );
        }

        return (
          <Tooltip key={index} title={hoverDate} mouseLeaveDelay={0}>
            <Radio.Button
              key={index}
              value={index}
              onMouseEnter={() => {
                onMouseEnter(formatDateInterval(data[index].start!, data[index].end!)!);
              }}
            >
              {option.label}
            </Radio.Button>
          </Tooltip>
        );
      })}
    </Radio.Group>
  );
};

export default SelectDate;
