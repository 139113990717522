import React, { useEffect, useState } from 'react';
import { Button, Col, Typography } from 'antd';
import { useField } from 'formik';
import { Select, FormItem, Input } from 'formik-antd';
import { OptionTypeBase } from 'react-select';
import { Dictionary, isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
// utils
import { getTooltipText } from '@utils/utils';
import { checkSaveButton } from '@utils/proprietaryInfo';
// components
import EditPopover from '@components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import CheckboxIcon from './CheckboxIcon';
// styles
import Styled from './MultiSelectField.styled';

const { Option } = Select;
const { TextArea } = Input;

const CUSTOMER_OTHER_RATIONALE_FIELD_NAME = 'customer_other_rationale';

interface MultiSelectPopupField {
  field?: BaseField;
  fieldValue?: string[];
  fieldName?: string;
  fieldType?: string;
  fieldOptions?: OptionTypeBase[];
  fieldExtraData?: Dictionary<any>;
  colSpan?: number;
  hidePenIcon?: boolean;
  popupWidth?: number;
  onSave?: (fieldType?: string) => void;
}

const MultiSelectPopupField: React.FC<MultiSelectPopupField> = ({
  field,
  colSpan,
  hidePenIcon,
  fieldValue,
  fieldName,
  fieldType,
  fieldOptions,
  fieldExtraData,
  popupWidth = 250,
  onSave,
}) => {
  let value: string[] = [],
    label: string = '',
    id: string = '',
    data: OptionTypeBase[] = [],
    extra_data: Dictionary<any> | undefined = undefined;

  if (field) {
    const {
      value: fieldValue,
      label: fieldLabel,
      id: fieldType,
      data: fieldData,
      extra_data: fieldExtraData,
    } = field;

    value = fieldValue;
    label = fieldLabel;
    id = fieldType;
    data = fieldData;

    if (fieldExtraData) {
      extra_data = fieldExtraData;
    }
  } else if (fieldValue && fieldName && fieldType && fieldOptions) {
    value = fieldValue;
    label = fieldName;
    id = fieldType;
    data = fieldOptions;

    if (fieldExtraData) {
      extra_data = fieldExtraData;
    }
  }

  const isCustomerType = id === 'customer_type';
  const defaultCustomerRationaleValue = extra_data?.customer_other_rationale.value ?? '';

  const [selectedOptions, , setSelectedOptions] = useField<string[] | undefined>(
    field?.id || fieldType || ''
  );
  const [customerTypeRationaleField, , setCustomerTypeRationaleField] = useField<string>(
    CUSTOMER_OTHER_RATIONALE_FIELD_NAME
  );
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(true);

  const renderTextField =
    isCustomerType && extra_data && (selectedOptions.value ?? []).includes('Other');

  useEffect(() => {
    if (!selectedOptions.value?.length && value && !isEqual(selectedOptions.value, value)) {
      setSelectedOptions.setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleVisibilityChange = (visibility: boolean) => {
    if (visibility) {
      if (!selectedOptions.value?.length) {
        setSelectedOptions.setValue(value ? value : []);
      }

      setIsSaved(false);
    } else {
      handleCancel();
    }
  };

  const handleChange = (value: string, type: 'select' | 'deselect') => {
    if (type === 'select') {
      setSelectedOptions.setValue([...(selectedOptions.value ?? []), value]);

      return;
    }

    const filteredSelectedOptions = (selectedOptions.value ?? []).filter(
      option => option !== value
    );

    setSelectedOptions.setValue([...filteredSelectedOptions]);
  };

  const handleCancel = () => {
    if (!isSaved) {
      setSelectedOptions.setValue(value);

      if (isCustomerType && extra_data?.customer_other_rationale) {
        setCustomerTypeRationaleField.setValue(extra_data.customer_other_rationale.value);
      }
    }

    setClosePopup(true);
  };

  const handleSave = () => {
    setIsSaved(true);

    if (onSave) {
      onSave(id);

      if (renderTextField) {
        onSave(CUSTOMER_OTHER_RATIONALE_FIELD_NAME);
      }
    }

    setClosePopup(true);
  };

  const isSaveBtnDisabled = renderTextField
    ? checkSaveButton(selectedOptions.value, value) &&
      checkSaveButton(customerTypeRationaleField.value, defaultCustomerRationaleValue)
    : checkSaveButton(selectedOptions.value, value);

  const content = (
    <Styled.SelectWrapper span={24} className={id}>
      <FormItem name={id}>
        <Typography.Text>Edit "{label}" field</Typography.Text>
        <Select
          style={{ width: '300px' }}
          showSearch
          defaultOpen
          autoFocus
          showArrow
          name={id}
          value={selectedOptions.value}
          menuItemSelectedIcon={CheckboxIcon}
          onSelect={(value, option) => handleChange(value, 'select')}
          onDeselect={(value, option) => handleChange(value, 'deselect')}
          optionFilterProp="children"
          mode="multiple"
          getPopupContainer={trigger => trigger.parentElement!}
          tagRender={props => <span>{props.label || ''}</span>}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = `${option?.children.props.children}`;

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          fast
        >
          {data?.map((option: OptionTypeBase) => (
            <Option
              key={option.value as string}
              value={option.value as string}
              className="left-checkbox-option"
            >
              <span>{option.label}</span>
            </Option>
          ))}
        </Select>

        {renderTextField && (
          <div style={{ width: '300px' }}>
            <Typography.Text>
              Edit "{extra_data?.customer_other_rationale.label}" field
            </Typography.Text>

            <FormItem name="customer_other_rationale">
              <TextArea
                rows={3}
                name="customer_other_rationale"
                placeholder={extra_data?.customer_other_rationale.placeholder}
                defaultValue={defaultCustomerRationaleValue}
                maxLength={500}
                fast
              />
            </FormItem>
          </div>
        )}

        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={isSaveBtnDisabled}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </FormItem>
    </Styled.SelectWrapper>
  );

  const popup = (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      customOverlayStyle={{ width: `${popupWidth}px` }}
      content={content}
      onVisibilityUpdate={handleVisibilityChange}
      hidePenIcon={hidePenIcon}
      destroyOnHide
      getPopupContainer={trigger => trigger.parentElement!}
    >
      {colSpan ? (
        <Styled.PopupTooltipContainer>
          <TruncateTooltip title={getTooltipText(selectedOptions.value)}>
            {getTooltipText(selectedOptions.value)}
          </TruncateTooltip>
        </Styled.PopupTooltipContainer>
      ) : (
        <TruncateTooltip title={getTooltipText(selectedOptions.value)}>
          {getTooltipText(selectedOptions.value)}
        </TruncateTooltip>
      )}
    </EditPopover>
  );

  if (colSpan) {
    return (
      <Col span={colSpan}>
        <Typography.Text>{label}</Typography.Text>
        {popup}
      </Col>
    );
  }

  return popup;
};

export default React.memo(MultiSelectPopupField);
