import React from 'react';
import { Tag, Col } from 'antd';
// models
import { CompanyContactIcons, CompanyUserContact } from '@models/api/contacts';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
// styled
import Styled from './TableContactName.styled';

export const TableContactName = (value: CompanyContactIcons, row: Partial<CompanyUserContact>) => {
  if (!value) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <Styled.RowWrapper justify="space-between">
      <Col>
        <TruncateTooltip title={value}>{value}</TruncateTooltip>
      </Col>
      <div style={{ marginLeft: '4px' }}>
        {row.primary_contact && <Tag color="blue">Primary</Tag>}
        {row.is_edited && <Tag color="default">edited</Tag>}
      </div>
    </Styled.RowWrapper>
  );
};

export default TableContactName;
