import React from 'react';
import { Select } from 'formik-antd';
import { useField } from 'formik';
// utils
import { filterOption } from '@utils/select/select';
// models
import { EquityTouchDatabaseMatch, EquityTouchFetchedField } from '@models/equityTouch';

const { Option } = Select;

interface BulkEquityTouchLeadProps {
  field: string;
  fieldItem: EquityTouchFetchedField;
  readOnly?: boolean;
}

const BulkEquityTouchLead: React.FC<BulkEquityTouchLeadProps> = ({
  field,
  fieldItem,
  readOnly,
}) => {
  const [, , helpers] = useField('Groups');

  const handleChange = () => {
    helpers.setValue([]);
  };

  return (
    <Select
      name={field}
      placeholder={`Please select ${field}`}
      onChange={handleChange}
      defaultValue={fieldItem?.database_matched?.itemId}
      disabled={readOnly}
      filterOption={filterOption}
      showSearch
    >
      {!fieldItem.is_required && <Option value="">{`Please select ${field}`}</Option>}

      {fieldItem.received_values.map((value, index) => (
        <Option value={(value as EquityTouchDatabaseMatch).itemId} key={index}>
          {(value as EquityTouchDatabaseMatch).text}
        </Option>
      ))}
    </Select>
  );
};

export default BulkEquityTouchLead;
