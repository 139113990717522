/* eslint-disable max-len */
import { Dictionary } from 'lodash';
import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import { SortByRule } from '@optx/models/table/sorting';
import { PreselectedFilter } from '@optx/models/filters';
import { FilterTag } from '@optx/models/tags';
import { FilterMultiTextOption, SelectOption } from '@optx/models/Option';
// constants
import { COMPANY_DEFAULT_SORT } from '@optx/constants/table/sort/defaultSort';
// utils
import { mapPreselectedFilters } from '@optx/utils/filters/preselectedValues';
import { parseFilter } from '@optx/utils/filters/parseFilters';
import { normalizeFiltersByColumn } from '@utils/filters/normalize';
import { injectFilter } from '@utils/filters/injectFilter';
// redux
import * as fetchReducers from '@redux/feature/fetch/reducers';
import { actions as paginationActions } from '@features/grid/pagination';
import { AdditionalFiltersResponse, AddonManagementState } from './interfaces';
import * as actions from './actions';
import {
  sendNewOptxScoreRationaleSuccess,
  sendOptxScoreRationaleSuccess,
} from '@redux/company/score/actions';
import { OptxScoreRationale } from '../score/interfaces';

const initialState: AddonManagementState = {
  byId: {},
  allIds: [],
  data: null,
  loading: false,
  error: '',
  fetchedAt: null,
  sortBy: COMPANY_DEFAULT_SORT,
  additionalFiltersData: [],
  additionalFilter: {},
  preselectedAdditionalFilters: {},
  normalizedAdditionalFilters: {},
  persistedAdditionalFilters: '',
  additionalFiltersValues: {},
  clearAdditionalFilters: {},
};

const changeSortCompanyAddonManagementReducer: CaseReducer<
  AddonManagementState,
  PayloadAction<Array<SortByRule<any>>>
> = (draftState, action) => {
  draftState.sortBy = action.payload;
};

const clearSearchReducer: CaseReducer<
  AddonManagementState,
  PayloadAction<Dictionary<PreselectedFilter> | undefined>
> = draftState => {
  return {
    ...initialState,
    additionalFilter: draftState.clearAdditionalFilters,
    additionalFiltersValues: draftState.clearAdditionalFilters,
    additionalFiltersData: draftState.additionalFiltersData,
    preselectedAdditionalFilters: draftState.preselectedAdditionalFilters,
    normalizedAdditionalFilters: draftState.normalizedAdditionalFilters,
    persistedAdditionalFilters: draftState.persistedAdditionalFilters,
    clearAdditionalFilters: draftState.clearAdditionalFilters,
  };
};

export const sendOptxScoreRationaleSuccessReducer = (
  draftState: AddonManagementState,
  action: PayloadAction<OptxScoreRationale>
) => {
  const {
    companyId,
    rationale,
    defaultScore,
    optxScoreVerified,
    optxScoreRationaleReason,
    optxScoreRationaleReasonType,
    scoreType,
  } = action.payload;

  if (draftState.byId[companyId]) {
    if (defaultScore === 'us') {
      if (scoreType === 'score_v3') {
        draftState.byId[companyId]!.optx_score_v3_rationale = rationale;
        draftState.byId[companyId]!.optx_score_v3_verified = optxScoreVerified;
        draftState.byId[companyId]!.optx_score_v3_rationale_reason = optxScoreRationaleReason;
        draftState.byId[companyId]!.optx_score_v3_rationale_reason_type =
          optxScoreRationaleReasonType;
      } else {
        draftState.byId[companyId]!.optx_score_rationale = rationale;
        draftState.byId[companyId]!.optx_score_verified = optxScoreVerified;
        draftState.byId[companyId]!.optx_score_rationale_reason = optxScoreRationaleReason;
        draftState.byId[companyId]!.optx_score_rationale_reason_type = optxScoreRationaleReasonType;
      }
    }

    if (defaultScore === 'il') {
      draftState.byId[companyId]!.il_optx_score_rationale = rationale;
      draftState.byId[companyId]!.il_optx_score_verified = optxScoreVerified;
      draftState.byId[companyId]!.il_optx_score_rationale_reason = optxScoreRationaleReason;
      draftState.byId[companyId]!.il_optx_score_rationale_reason_type =
        optxScoreRationaleReasonType;
    }
  }
};

const fetchAdditionalFiltersSuccessReducer: CaseReducer<
  AddonManagementState,
  PayloadAction<AdditionalFiltersResponse>
> = (draftState, action) => {
  const { filterSource } = action.payload;
  const sourceData = filterSource[0].data;

  const sources = filterSource.flatMap(item => ({ ...item, data: sourceData }));

  const preselected = mapPreselectedFilters(sources);
  draftState.preselectedAdditionalFilters = preselected;
  const clearedFilter = mapPreselectedFilters(sources, false);
  const normalized = normalizeFiltersByColumn(filterSource);
  // @ts-ignore
  draftState.normalizedAdditionalFilters = normalized;

  const defaultOrigin = normalized.origin.data;

  if (draftState.persistedAdditionalFilters !== undefined) {
    const persistedFilter = draftState.persistedAdditionalFilters;
    const [parsedFilters] = parseFilter(persistedFilter, filterSource);
    const parsedFiltersData = {
      ...parsedFilters,
      origin: parsedFilters.origin
        ? parsedFilters.origin
        : parsedFilters.origin === undefined && persistedFilter.includes('origin')
        ? []
        : defaultOrigin,
    };

    draftState.additionalFiltersValues = parsedFiltersData;
    draftState.additionalFilter = parsedFiltersData;
  }

  if (draftState.persistedAdditionalFilters === '') {
    const preselectedData = {
      ...preselected,
      origin:
        (preselected.origin as FilterMultiTextOption[]).length === 0
          ? defaultOrigin
          : preselected.origin,
    };
    draftState.additionalFiltersValues = preselectedData;
    draftState.additionalFilter = preselectedData;
  }

  draftState.clearAdditionalFilters = {
    ...clearedFilter,
    origin: defaultOrigin,
  };

  draftState.preselectedAdditionalFilters = {
    ...preselected,
    origin: draftState.additionalFilter.origin,
  };

  draftState.additionalFiltersData = sources;
  draftState.loading = false;
};

const updateValueReducer: CaseReducer<
  AddonManagementState,
  PayloadAction<{ key: string; value: PreselectedFilter }>
> = (draftState, action) => {
  const { key, value } = action.payload;
  draftState.additionalFilter = { ...draftState.additionalFilter, [key]: value };
  draftState.additionalFiltersValues = { ...draftState.additionalFiltersValues, [key]: value };
  const normalizedValue = (value as Array<SelectOption>).map(item => item.value);
  draftState.persistedAdditionalFilters = queryString.stringify(
    { [key]: normalizedValue },
    {
      arrayFormat: 'comma',
    }
  );
};

const resetAdditionalFilterReducer: CaseReducer<AddonManagementState, PayloadAction<FilterTag>> = (
  draftState,
  action
) => {
  const tag = action.payload;
  const {
    normalizedAdditionalFilters: normalized,
    clearAdditionalFilters: clear,
    additionalFilter: filter,
  } = draftState;

  //@ts-ignore
  const newFilters = injectFilter(normalized, filter, clear, tag.filter, tag.optionId);
  draftState.additionalFilter = newFilters;
  draftState.additionalFiltersValues = newFilters;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.changeSortCompanyAddonManagement, changeSortCompanyAddonManagementReducer)
    .addCase(actions.clearSearch, clearSearchReducer)
    .addCase(actions.resetFilter, fetchReducers.fetchReducer)
    .addCase(actions.fetchAdditionalFilters, fetchReducers.fetchReducer)
    .addCase(actions.fetchAdditionalFiltersSuccess, fetchAdditionalFiltersSuccessReducer)
    .addCase(actions.updateAdditionalFilters, updateValueReducer)
    .addCase(actions.resetAdditionalFilter, resetAdditionalFilterReducer)
    .addCase(paginationActions.changePagination, fetchReducers.fetchReducer)
    .addCase(sendOptxScoreRationaleSuccess, sendOptxScoreRationaleSuccessReducer)
    .addCase(sendNewOptxScoreRationaleSuccess, sendOptxScoreRationaleSuccessReducer)
);

export default reducer;
