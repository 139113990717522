import React, { useEffect, useState } from 'react';
import { Button, Divider, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useField } from 'formik';
// models
import Company from '@optx/models/Company';
import { SalesloftContact } from '@optx/models/Salesloft';
import { CompanyUserContact } from '@optx/models/api/contacts';
// utils
import { contactFilterOption, reorderPrimaryContact } from '@optx/utils/select/select';
// components
import ContactOptionIcon from '@optx/shared/view/molecules/contacts/ContactOptionIcon';
import Styled from '@optx/shared/view/molecules/Select/Styled/ContactSelectWrapper.styled';
import ContactOption from '@optx/shared/view/molecules/contacts/ContactOption';
import ContactTags from '@optx/shared/view/molecules/contacts/ContactTags';

const { Option } = Select;

interface SelectContactProps {
  contacts: SalesloftContact[];
  selectedContacts?: number[];
  company: Company;
  handleChange: (selectedValues: number[]) => void;
  isCell?: boolean;
  showAddContactForm?: () => void;
  selectedPrimary?: boolean;
  size?: SizeType;
}

const SelectContacts: React.FC<SelectContactProps> = ({
  contacts,
  selectedContacts,
  company,
  handleChange,
  isCell,
  showAddContactForm,
  selectedPrimary = true,
  size,
}) => {
  const companyData = company;
  const [selected, setSelectedContacts] = useState<number[]>([]);
  const [externalAttendees] = useField<number[]>('externalAttendees');

  useEffect(() => {
    if (externalAttendees.value) {
      setSelectedContacts(externalAttendees.value);
    }
  }, [externalAttendees.value]);

  useEffect(() => {
    if (
      !selectedPrimary &&
      companyData &&
      selectedContacts &&
      selectedContacts?.length !== selected.length
    ) {
      const onlyCompanyContacts =
        (companyData?.contacts as CompanyUserContact[])
          .filter(contact =>
            contact.person_id ? selectedContacts.includes(contact.person_id) : false
          )
          .map(contact => contact.person_id as number) || [];

      setSelectedContacts(reorderPrimaryContact(onlyCompanyContacts, contacts));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContacts]);

  const handleSelection = (value: number, type: 'select' | 'deselect') => {
    if (type === 'select' && companyData && companyData?.contacts) {
      setSelectedContacts(reorderPrimaryContact([...selected, value], contacts));
      handleChange && handleChange([...selected, value]);
    } else if (type === 'deselect') {
      const updatedContacts = selected.filter(personId => personId !== value);
      setSelectedContacts([...updatedContacts]);
      handleChange && handleChange([...updatedContacts]);
    }
  };

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <Styled.SelectWrapper
        className={'ant-select-customize-input'}
        showArrow
        placeholder="Select contacts"
        onSelect={(value, option) => handleSelection(Number(value), 'select')}
        onDeselect={(value, option) => handleSelection(Number(value), 'deselect')}
        optionFilterProp="children"
        filterOption={contactFilterOption}
        mode="multiple"
        value={selected}
        tagRender={props => <ContactTags {...props} />}
        menuItemSelectedIcon={ContactOptionIcon}
        dropdownMatchSelectWidth={250}
        $isCell={isCell}
        size={size}
        getPopupContainer={trigger => trigger.parentNode.parentNode}
        dropdownRender={menu => (
          <>
            {menu}
            {showAddContactForm && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  type="link"
                  onClick={showAddContactForm}
                  style={{ color: 'rgba(0,0,0,.85)', fontWeight: 600 }}
                >
                  <PlusOutlined style={{ color: '#1890FF', margin: '0 5px 0 -3px' }} /> Add new
                  contact
                </Button>
              </>
            )}
          </>
        )}
      >
        {contacts?.map(contact => (
          <Option
            key={contact.person_id as number}
            value={contact.person_id as number}
            className="contact-option"
          >
            <ContactOption contact={contact as CompanyUserContact} />
          </Option>
        ))}
      </Styled.SelectWrapper>
      <Styled.ContactSelectGlobal />
    </div>
  );
};

export default SelectContacts;
