import moment from 'moment';
import { Dictionary } from 'lodash';
// models
import { PreselectedFilter, BaseFilter } from '@optx/models/filters';
import {
  FormCheckableRangeOption,
  FormCheckboxOption,
  SelectOption,
  LogicOption,
} from '@optx/models/Option';
import { CheckableRangeOption, SingleCheckboxRangeOption } from '@optx/models/RangeOption';
// constants
import { ISO_DATE_FORMAT } from '../../constants/format/date';
// utils
import { lowerRangeKey, upperRangeKey } from './rangeKey';

const mapFiltersToURLParams = (
  normalizedSources: Dictionary<BaseFilter<any>>,
  filters: Dictionary<PreselectedFilter>,
  histogramFilters?: Array<string>,
  options?: {
    dateRangeFormatUpperLower?: boolean;
  }
) => {
  const mappedFilters: Dictionary<string | Array<number | string>> = {};

  // map current sources
  const keys = Object.keys(filters);
  keys.forEach(filterColumn => {
    const filter = normalizedSources[filterColumn];

    if (filter) {
      const currentFilterData = filters[filterColumn];

      switch (filter.type) {
        case 'range_input': {
          const data = currentFilterData as Array<FormCheckableRangeOption>;
          const lowerOptions: Array<number | string> = [];
          const upperOptions: Array<number | string> = [];

          const histogramFiltersLength = histogramFilters ? histogramFilters.length : 0;
          const histogramList = histogramFilters || [];
          const histogramListFieldIndex = histogramList.indexOf(filterColumn);
          // The following code validate these situations:
          // - if histogramList doesn't exist or has no values, it should validate only the min/max/range 'if' below
          // - if histogramList exists the filter should be in the list, otherwise
          //   even though the range might be different, if it's not in the list, don't include
          let shouldValidateHistogramFilters = !histogramFiltersLength;

          if (histogramFiltersLength && histogramListFieldIndex > -1) {
            shouldValidateHistogramFilters = true;
          }

          data.forEach((option, index) => {
            // this code gets the original min/max and compare the option with it
            // instead of comparing with the newer min/max values
            const originalMinMax: Array<number> = [];
            filter.data.forEach((filterItem: any) => {
              if (typeof filterItem.min === 'number' && typeof filterItem.max === 'number') {
                originalMinMax.push(filterItem.min);
                originalMinMax.push(filterItem.max);
              }
            });

            if (
              (originalMinMax[0] !== option.range[0] || originalMinMax[1] !== option.range[1]) &&
              shouldValidateHistogramFilters
            ) {
              lowerOptions.push(option.range[0]);
              upperOptions.push(option.range[1]);
            }

            const ebitdaString: string[] = [];

            if (option.check && index === 0) {
              option.check.forEach(item => {
                // special case for ebitda_numeric, api cannot handle sending all possible checkbox
                // options with existing parameters. it's required that UI sends some of these in a separate
                // parameter called ebitda_string.
                // TODO if we need to apply this to another column, then we need to make some api changes
                // for the parameter names and make this functionality generic
                if (item.checked) {
                  if (filterColumn !== 'ebitda_numeric') {
                    if (
                      filterColumn === 'last_ebitda_margin' ||
                      filterColumn === 'last_gross_margin'
                    ) {
                      if (
                        item.value === 'not_blank' ||
                        item.value === 'blank' ||
                        item.value === 'include_below'
                      ) {
                        lowerOptions.push(item.value);
                        upperOptions.push(item.value);
                      }
                    } else {
                      lowerOptions.push(item.value);
                      upperOptions.push(item.value);
                    }
                  } else {
                    if (item.value === 'not_blank') {
                      lowerOptions.push(item.value);
                      upperOptions.push(item.value);
                    } else {
                      ebitdaString.push(item.value);
                      mappedFilters.ebitda_string = ebitdaString.join(',');
                    }
                  }
                }
              });
            }
          });

          mappedFilters[lowerRangeKey(filter.column)] = lowerOptions;
          mappedFilters[upperRangeKey(filter.column)] = upperOptions;
          break;
        }

        case 'checkbox': {
          const data = currentFilterData as Array<FormCheckboxOption>;
          const options: Array<string | number> = [];

          data.forEach(item => {
            if (item.checked) {
              options.push(item.value);
            }
          });

          // add custom option if available
          const customOption = filters[`${filterColumn}_custom`] as
            | SingleCheckboxRangeOption
            | undefined;

          if (customOption && customOption.checked && customOption.value) {
            options.push(customOption.value);
          }

          mappedFilters[filter.column] = options;
          break;
        }

        case 'multi_text': {
          const data = currentFilterData as Array<SelectOption>;
          const options: Array<string> = [];

          data.forEach(item => {
            if (item.value) {
              options.push(item.value);
            }
          });

          // we have a special filter called geo_range with two text fields, that is connected to
          // a helper filter called geo_country, which is a single select dropdown.
          // they're connected with "used_by" and "used_for" properties.
          // the helper select value must be sent within the "multi_text" filter parameter, in this case:
          // geo_range=<zip_code>,<range>,<country>
          // if this filter type has a helper select filter, add it's value at the end
          if (filter.used_for.length) {
            filter.used_for.forEach(columnName => {
              if (filters[columnName] && (filters[columnName] as SelectOption).value) {
                options.push((filters[columnName] as SelectOption).value);
              }
            });
          }

          mappedFilters[filter.column] = options;
          break;
        }

        case 'logic_checkbox': {
          const data = currentFilterData as Array<LogicOption>;
          const options: Array<string> = [];

          data.forEach(item => {
            if (item.in) {
              options.push(`${item.value}-1-0`);
            }

            if (item.notIn) {
              options.push(`${item.value}-0-1`);
            }
          });

          mappedFilters[filter.column] = options;
          break;
        }

        case 'multiple_range_nr': {
          const data = currentFilterData as Array<CheckableRangeOption>;
          const lowerOptions: Array<number> = [];
          const upperOptions: Array<number> = [];

          data.forEach(item => {
            if (item.checked) {
              lowerOptions.push(item.min);
              upperOptions.push(item.max);
            }
          });

          // add custom option if available
          const customOption = filters[`${filterColumn}_custom`] as
            | CheckableRangeOption
            | undefined;

          if (customOption && customOption.checked && customOption.min && customOption.max) {
            lowerOptions.push(customOption.min);
            upperOptions.push(customOption.max);
          }

          mappedFilters[lowerRangeKey(filter.column)] = lowerOptions;
          mappedFilters[upperRangeKey(filter.column)] = upperOptions;
          break;
        }

        case 'date_range': {
          const [start, end] = currentFilterData as Array<SelectOption>;
          const lowerOptions: Array<string> = [];
          const upperOptions: Array<string> = [];

          if (typeof start === 'object' && typeof end === 'object') {
            if (start.value) {
              lowerOptions.push(moment(start.value).format(ISO_DATE_FORMAT));
            }

            if (end.value) {
              upperOptions.push(moment(end.value).format(ISO_DATE_FORMAT));
            }
          }

          if (typeof start === 'string' && typeof end === 'string') {
            if (start) {
              lowerOptions.push(moment(start).format(ISO_DATE_FORMAT));
            }

            if (end) {
              upperOptions.push(moment(end).format(ISO_DATE_FORMAT));
            }
          }

          mappedFilters[lowerRangeKey(filter.column)] = lowerOptions;
          mappedFilters[upperRangeKey(filter.column)] = upperOptions;
          break;
        }

        case 'radio': {
          if (currentFilterData) {
            mappedFilters[filter.column] = currentFilterData as string;
          }

          break;
        }

        case 'endpoint_query': {
          if (currentFilterData) {
            mappedFilters[filter.column] = (currentFilterData as Array<SelectOption>).map(
              item => item.value
            );
          }

          break;
        }

        case 'multi_select': {
          if (currentFilterData) {
            mappedFilters[filter.column] = (currentFilterData as Array<SelectOption>)?.map(
              option => option?.value ?? option
            );
          }

          break;
        }

        case 'single_select': {
          if (currentFilterData) {
            mappedFilters[filter.column] = (currentFilterData as Array<SelectOption>).map(
              option => option.value
            );
          }

          break;
        }

        case 'date_range_radio': {
          if (currentFilterData) {
            mappedFilters[filter.column] = currentFilterData as [string, string];
          }

          break;
        }

        default:
          break;
      }
    }
  });

  if (filters.similarCompanies) {
    mappedFilters.company_id = (filters.similarCompanies as SelectOption).value?.split(',');
  }

  // Handle special filter cases.
  // add current list id
  if (filters.saved_list_id) {
    mappedFilters.saved_list_id = filters.saved_list_id as string;
  }

  // add source scrub list id
  if (filters.ss_list_id) {
    mappedFilters.ss_list_id = filters.ss_list_id as string;
  }

  if (filters.queryParsed) {
    const values = Object.values(filters.queryParsed);
    Object.keys(filters.queryParsed).forEach((key, index) => {
      if (key === 'column_name' || key === 'analyst_id') {
        return;
      }

      if (key === 'touch_type' || key === 'so_stage') {
        mappedFilters[key] = values[index]?.toString().split(',');
      } else {
        mappedFilters[key] = values[index];
      }
    });
  }

  // Handle special case for IVC or Israel tag use case.
  if (filters.is_in_israel) {
    mappedFilters.is_in_israel = filters.is_in_israel as string;
  }

  // Special case for is portfolio/platform.
  if (filters.is_platform) {
    mappedFilters.is_platform = filters.is_platform as string;
  }

  // Special case for parent company.
  if (filters.parentcompany) {
    const formatedValue: string =
      filters.parentcompany instanceof Array
        ? filters.parentcompany.join()
        : (filters.parentcompany as string);
    mappedFilters.parentcompany = formatedValue;
  }

  // Special case for query.
  if (filters.query) {
    const formatedValue: string =
      filters.query instanceof Array ? filters.query.join() : (filters.query as string);
    mappedFilters.query = formatedValue;
  }

  if (filters.ddate) {
    const sourcingDatesFilter = filters.ddate as unknown as string[];
    mappedFilters.ddate_lower = sourcingDatesFilter[0];
    mappedFilters.ddate_upper = sourcingDatesFilter[1];
  }

  // handle special case for next and last touch date (hidden from filters modal)
  if (filters.next_touch_date) {
    const nextTouchDateFilter = filters.next_touch_date as string | string[];
    let nextTouchDate: string[] = [];

    if (typeof nextTouchDateFilter === 'string') {
      mappedFilters.next_touch_date = nextTouchDateFilter;
    } else if ((nextTouchDateFilter as string[]).every(filter => typeof filter === 'string')) {
      nextTouchDate = nextTouchDateFilter as string[];
      mappedFilters.next_touch_date = nextTouchDate;
    } else {
      nextTouchDate = (filters.next_touch_date as SingleCheckboxRangeOption[]).map(
        filter => filter.value
      ) as string[];
      mappedFilters.next_touch_date = nextTouchDate;
    }
  }

  if (filters.last_touch_date) {
    const lastTouchDateFilter = filters.last_touch_date as string | string[];
    let lastTouchDate: string[] = [];

    if (typeof lastTouchDateFilter === 'string') {
      mappedFilters.last_touch_date = lastTouchDateFilter;
    } else if ((lastTouchDateFilter as string[]).every(filter => typeof filter === 'string')) {
      lastTouchDate = lastTouchDateFilter as string[];
      mappedFilters.last_touch_date = lastTouchDate;
    } else {
      lastTouchDate = (filters.last_touch_date as SingleCheckboxRangeOption[]).map(
        filter => filter.value
      ) as string[];
      mappedFilters.last_touch_date = lastTouchDate;
    }
  }

  // if column type is date_range_radio and if we want to send to api as
  // lower and upper you can pass the option
  // dateRangeFormatUpperLower: true
  // this will send the date range as [KEY]_lower and [KEY]_upper
  if (options?.dateRangeFormatUpperLower) {
    keys.forEach(filterColumn => {
      const column = normalizedSources[filterColumn];

      if (column) {
        if (column.type === 'date_range_radio') {
          const findFilter = filters[column.column];
          const sourcingDatesFilter = findFilter as unknown as string[];
          mappedFilters[lowerRangeKey(filterColumn)] = sourcingDatesFilter[0];
          mappedFilters[upperRangeKey(filterColumn)] = sourcingDatesFilter[1];

          delete mappedFilters[filterColumn];
        }
      }
    });
  }

  if (filters.addon_bool) {
    mappedFilters.addon_bool = filters.addon_bool as string;
  }

  return mappedFilters;
};

export default mapFiltersToURLParams;
