import { createAction } from '@reduxjs/toolkit';
import * as types from './types';
import { CompanyIsInBusinessResponse } from './interfaces';

export const addCompanyInBusinessEvaluation = createAction(
  types.ADD_COMPANY_IN_BUSINESS_EVALUATION,
  (companyId: number, isInBusiness = true, isFromHistory?: boolean) => ({
    payload: {
      companyId,
      isInBusiness,
      isFromHistory,
    },
  })
);

export const addCompanyInBusinessEvaluationSuccess = createAction(
  types.ADD_COMPANY_IN_BUSINESS_EVALUATION_SUCCESS,
  (res: CompanyIsInBusinessResponse, companyId: number, isInBusiness = true) => ({
    payload: {
      score: res.optx_score,
      score_v3: res.score_v3,
      il_score: res.il_optx_score,
      il_score_growth: res.il_optx_score_growth,
      scoreGrowth: res.optx_score_growth,
      newScoreGrowth: res.score_v3_growth,
      companyId,
      isInBusiness,
    },
  })
);

export const addCompanyInBusinessEvaluationFail = createAction<string>(
  types.ADD_COMPANY_IN_BUSINESS_EVALUATION_FAIL
);
