import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Typography } from 'antd';
// redux
import { selectors, useInject } from '@features/custom-global-loader';
// components
import { LoadIcon32 } from '@components/LoadingIcon';

interface GlobalLoaderProps {
  $isChromePlugin: boolean | undefined;
}

const Wrapper = styled.div<GlobalLoaderProps>` {
  position: fixed;
  background: rgba(223, 223, 223, 0.5);
  top: 0;
  left: 0;
  ${({ $isChromePlugin }) => css`
    width: ${$isChromePlugin ? '60vw' : '100vw'};
  `}
  height: 100vh;
  z-index: 3000;
`;

const Content = styled.div` {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .load__icon {
    animation: linear load 2s infinite;
    width: 32px;
    height: 32px;
  }

  .ant-typography {
      margin-top: 25px;
      display: block;
      line-height: 1.5715;
      background-image: none;
      border: 1px solid #d9d9d9;
      box-shadow: 0 2px #00000004;
      padding: 4px 15px;
      border-radius: 2px;
      color: ##000000d9;
      background: #fff;
  }

  @keyframes load {
    from {
      transform: rotate(0deg) scale(2);
    }
    to {
      transform: rotate(360deg) scale(2);
    }
  }
`;

const CustomGlobalLoader: React.FC = () => {
  useInject();
  const loading = useSelector(selectors.loading);
  const customText = useSelector(selectors.customText);
  const isChromePlugin = window.location.pathname.startsWith('/chrome-extension-new');

  if (loading) {
    return (
      <Wrapper $isChromePlugin={isChromePlugin}>
        <Content>
          <LoadIcon32 />
          {customText && <Typography.Text>{customText}</Typography.Text>}
        </Content>
      </Wrapper>
    );
  }

  return null;
};

export default CustomGlobalLoader;
