import { Skeleton } from 'antd';
import styled from 'styled-components';

const SkeletonInput = styled(Skeleton.Input)`
  height: 24px;
  width: 170px;
`;

// create styled wrapper with div
const SkeletonWrapper = styled.div<{ $align: 'flex-start' | 'flex-end' }>`
  display: flex;
  justify-content: ${props => props.$align};
  width: 100%;
  gap: 20px;
`;

const FilterTagsSkeleton = ({
  length = 1,
  align = 'flex-end',
}: {
  length: number;
  align?: 'flex-start' | 'flex-end';
}) => {
  return (
    <SkeletonWrapper $align={align}>
      {[...Array(length)].map((_, index) => (
        <SkeletonInput key={index} active />
      ))}
    </SkeletonWrapper>
  );
};

export default FilterTagsSkeleton;
