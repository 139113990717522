import styled, { css } from 'styled-components';
import { Modal } from 'antd';
// components
import DefaultTable from '@shared/view/organims/Table';

const ModalWrapper = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;

    .left-container {
      button {
        margin-right: 10px;
      }
    }

    .additional-info {
      font-weight: bold;
      font-size: 16px;
    }

    .proceed-text {
      margin-right: 8px;
    }
  }

  .ant-modal-content {
    min-height: 700px;
  }

  .ant-form-vertical {
    .ant-form-item-label {
      padding-bottom: 0;
    }
  }
`;

const Step1 = styled.div`
  .spacer-bottom {
    margin-bottom: 30px;
  }

  .step-1-col {
    min-width: 230px;
  }

  .ant-form-item {
    &.primary-url-form-item {
      margin-bottom: 0;
      position: relative;

      .url-status-icon-container {
        position: absolute;
        top: 1px;
        right: 10px;
        font-size: 20px;
        width: 20px;
        height: 20px;
      }

      input {
        padding-right: 35px;
      }
    }

    &-control {
      max-width: 230px;
    }

    &-explain-error {
      white-space: nowrap;
    }

    input {
      padding-right: 25px;
    }
  }

  .url-info {
    font-size: 14px;
    color: #bfbfbf;
  }
`;

const Step2 = styled.div`
  .spacer-bottom {
    margin-bottom: 30px;
  }

  .ant-table-wrapper {
    width: 100%;
  }
`;

const Table = styled(DefaultTable)`
  .ant-table-container {
    max-height: 490px;
    overflow-y: auto;
  }

  td.ant-table-cell:not(.sticky):not(.editable-cell) {
    cursor: default;
  }

  .ant-table-cell.company_name {
    .company-name__profile-link {
      display: inline;
    }

    .d-flex > * {
      height: 22px;
    }
  }
`;

const AsyncSelect = css`
  .ant-form-item-has-error {
    .ant-dropdown-trigger,
    .async-single-select .multiselect__control {
      border-color: #ff4d4f;

      &:hover {
        border-color: #ff4d4f;
      }

      &--is-focused {
        border-color: #ff4d4f;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
      }
    }
  }

  .async-single-select {
    .multiselect__control {
      min-height: 30px;
      border-radius: 2px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        border-color: #40a9ff;
        outline: 0;
      }

      &--is-focused {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }

      .multiselect__value-container {
        height: 30px;
        padding: 0 11px;

        & > span {
          display: none;
        }
      }

      .multiselect__dropdown-indicator {
        color: rgba(0, 0, 0, 0.25);
        font-size: 12px;
        padding-right: 11px;
      }
    }

    .multiselect__menu-list :first-child:hover {
      color: hsl(0, 0%, 60%);
    }

    .multiselect__option {
      height: max-content;
      padding: 5px 12px;

      &.multiselect__option--is-focused:hover {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: rgba(0, 0, 0, 0.85);
        background: #f5f5f5;
      }

      &.multiselect__option--is-selected {
        font-weight: 600;
        background: #e6f4ff;

        &:hover {
          background: #e6f4ff;
        }
      }
    }
  }
`;

const Step3 = styled.div`
  .spacer-bottom {
    margin-bottom: 30px;
    align-items: center;
  }

  .add-address-button {
    height: 100%;
    padding: 2px 15px;

    .plus-icon {
      font-size: 11px;
    }
  }
  ${AsyncSelect}
`;

const Step4 = styled.div`
  .spacer-bottom {
    margin-bottom: 30px;
  }

  textarea {
    line-height: 1.59;
  }
  ${AsyncSelect}
`;

const StepCompleted = styled.div`
  text-align: center;

  .spacer-bottom {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 32px;
  }

  .anticon-check-circle {
    color: #82b128;
    font-size: 64px;
    width: 100%;
  }
`;

export const Styled = {
  ModalWrapper,
  Step1,
  Step2,
  Table,
  Step3,
  Step4,
  StepCompleted,
};
