import React, { useEffect, useState } from 'react';
import { Select, Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { FormItem } from 'formik-antd';
import { SelectValue } from 'antd/lib/select';
// models
import { TouchFieldProps } from './interface';

const { Option } = Select;

const SingleSelectBankerField: React.FC<TouchFieldProps> = ({ tabKey, field, fieldUsedFor }) => {
  const [banker, , setBanker] = useField<boolean>(field.id);
  const [selectedValue, setSelectedValue] = useState<boolean>();

  const handleChange = (value: SelectValue) => {
    setSelectedValue(value as unknown as boolean);

    if (typeof value === 'boolean') {
      setBanker.setValue(value as unknown as boolean);
    }
  };

  useEffect(() => {
    if (fieldUsedFor === 'editAllInfo' && typeof banker.value === 'boolean') {
      setSelectedValue(banker.value);
    } else if (typeof field.value === 'boolean') {
      setSelectedValue(field.value);
    }

    if (
      fieldUsedFor === 'companyTouches' &&
      banker &&
      banker.value === undefined &&
      typeof field.value === 'boolean'
    ) {
      setBanker.setValue(field.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  return (
    <>
      {tabKey !== 'editAllFieldsTab' && <Col span={24} className="grid-spacer"></Col>}
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            value={selectedValue as SelectValue}
            onChange={handleChange}
            getPopupContainer={trigger => trigger.parentElement!}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = option ? `${option?.children.props.children}` : '';

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {field.data &&
              field.data.length &&
              field.data?.map((option: OptionTypeBase) => (
                <Option key={option.label as string} value={option.value}>
                  <span>{option.label}</span>
                </Option>
              ))}
          </Select>
        </FormItem>
      </Col>
    </>
  );
};

export default React.memo(SingleSelectBankerField);
