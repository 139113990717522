import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Col, Button, Row, Collapse, Input, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
// models
import { BaseField, CompanyFieldsGroup } from '@optx/models/companyFields';
import { SearchFieldLabels } from '@optx/models/api/editAllInfo';
// utils
import { focusNextElActions } from '@utils/focusActions';
// redux
import {
  actions as companyEditAllActions,
  selectors as companyEditAllSelectors,
} from '@optx/features/edit-all-info/state';
// hooks
import { useIsChromePlugin } from '@optx/common/hooks/useIsChromePlugin';
import useDebouncedSearch from '@optx/common/hooks/useDebouncedSearch';
// components
import FieldFactory from '@features/field-factory';
import SearchInput from '@components/common/form/input/SearchInput';
import AddYearBox from '@optx/features/field-factory/AddYearBox';
import { LocalStorageAutoSaveStatus } from '@optx/features/local-storage-autosave';
// styles
import { Styled } from './EditAllCompanyInfo.styled';

interface EditAllCompanyInfoProps {
  companyFields: CompanyFieldsGroup<BaseField>[];
  searchFields: (BaseField<any> | null)[];
  title: string;
  hasSearch?: boolean;
  className: string;
  tabKey?: string;
  searchFieldsLabels?: SearchFieldLabels;
}

const EditAllCompanyInfo: React.FC<EditAllCompanyInfoProps> = ({
  companyFields,
  searchFields,
  title,
  hasSearch,
  className,
  tabKey,
  searchFieldsLabels,
}) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext();
  // @ts-ignore
  const secondaryUrls = (values?.secondary_urls || []) as string[];

  const saveBtnStatus = useSelector(companyEditAllSelectors.getDisableSaveBtnFor);

  const keyArray = useMemo(
    () => companyFields.map((fieldsSource: CompanyFieldsGroup) => fieldsSource.id.toString()),
    [companyFields]
  );
  const [accordionKey, setAccordionKey] = useState<string | string[]>([keyArray[0]]);
  const [addYear, setAddYear] = useState(false);
  const [expandBtnText, setExpandBtnText] = useState<string>('Expand All');
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<(BaseField<any> | null)[]>([]);
  const { isChromePlugin } = useIsChromePlugin();
  const [showAddYear, setShowAddYear] = useState<boolean>(true);
  const inputRef = useRef<Input | null>(null);

  const handleSearch = useCallback(
    (searchValue: string) => {
      if (!searchValue.trim()) {
        setFilteredData([]);

        return;
      }

      const searchLower = searchValue.toLowerCase().trim();

      const filteredFields = searchFields.filter(data => {
        let searchableTextLower = '';

        if (data?.label) {
          searchableTextLower = data.label.toLowerCase();
        }

        const requiredFor = data?.requiredFor?.field;

        if (requiredFor && typeof requiredFor === 'string') {
          searchableTextLower += ' ' + requiredFor.toLowerCase();

          if (searchFieldsLabels && searchFieldsLabels[requiredFor]) {
            searchableTextLower += ' ' + searchFieldsLabels[requiredFor].toLocaleLowerCase();
          }
        }

        if (!searchableTextLower.length) {
          return false;
        }

        return searchableTextLower.includes(searchLower);
      });

      setFilteredData(filteredFields);
    },
    [searchFields, searchFieldsLabels]
  );

  const debouncedSearch = useDebouncedSearch(handleSearch, 300);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);

    if (!value.trim()) {
      // Immediate update if search value is cleared
      setFilteredData([]);
    } else {
      // Use debounced search for other input values
      debouncedSearch(value);
    }
  };

  const handleCardToggle = (key: string | string[]) => {
    setAccordionKey(key);

    setShowAddYear(
      (typeof key === 'string' && key === '1') || (Array.isArray(key) && key.includes('1'))
    );

    if (key.length !== keyArray.length) {
      setExpandBtnText('Expand All');
    } else {
      setExpandBtnText('Collapse All');
    }

    const lastKey = keyArray[keyArray.length - 1];

    if (lastKey && key.includes(lastKey) && !accordionKey.includes(lastKey)) {
      setTimeout(() => {
        const drawerHTML = document.querySelector('.ant-drawer-body') as HTMLElement;
        const drawerInnerHTML = document.querySelector('.ant-drawer-body-inner') as HTMLElement;
        drawerHTML?.scrollTo({ top: drawerInnerHTML.clientHeight, behavior: 'smooth' });
      }, 300);
    }

    focusNextElActions(
      '.edit-all-prop-info .ant-collapse-header[aria-expanded="true"]:focus',
      '.ant-col:first-child input'
    );
  };

  const toggleCollapseAll = () => {
    if (accordionKey.length === keyArray.length) {
      setAccordionKey([]);

      if (showAddYear) {
        setShowAddYear(false);
      }

      setExpandBtnText('Expand All');
    } else {
      setAccordionKey(keyArray);

      if (!showAddYear) {
        setShowAddYear(true);
      }

      setExpandBtnText('Collapse All');
    }
  };

  useEffect(() => {
    if (keyArray.length === 1) {
      setExpandBtnText('Collapse All');
    }
  }, [keyArray]);

  useEffect(() => {
    if (saveBtnStatus && saveBtnStatus !== 'editAllInfo') {
      setAddYear(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBtnStatus]);

  const saveYearHandler = () => {
    setAddYear(!addYear);

    dispatch(companyEditAllActions.changeSaveBtnStatus(null));
  };

  const addYearHandler = () => {
    setAddYear(!addYear);

    const btnStatus = !addYear ? 'editAllInfo' : null;

    dispatch(companyEditAllActions.changeSaveBtnStatus(btnStatus));
  };

  const showAddUrlButton = (fieldsSourceId: number) => {
    const allFieldsTabId = 14;
    const idInfoTabId = 2;

    const showInAllFieldsTab =
      fieldsSourceId === allFieldsTabId &&
      tabKey === 'editAllFieldsTab' &&
      accordionKey.includes(allFieldsTabId.toString());
    const showInIdInfoTab =
      fieldsSourceId === idInfoTabId &&
      tabKey === 'editIdInfoTab' &&
      accordionKey.includes(idInfoTabId.toString());

    return showInAllFieldsTab || showInIdInfoTab;
  };

  return (
    <Styled.FinancialEditAllContainer>
      <Row
        gutter={[48, isChromePlugin ? 24 : 48]}
        className={
          isChromePlugin
            ? `edit-all-prop-info edit-all-chrome-plugin ${className}`
            : `edit-all-prop-info ${className}`
        }
      >
        <Col span={24}>
          <Row justify="space-between">
            <Col className="edit-all-prop-info--title-wrapper">
              <span className="edit-all-prop-info--title">{title}</span>
              <LocalStorageAutoSaveStatus />
            </Col>
            <Col>
              <Button onClick={toggleCollapseAll} className="link-btn">
                {expandBtnText}
              </Button>
            </Col>
          </Row>
          {hasSearch && (
            <Row>
              <Typography.Text>Search by field name</Typography.Text>
              <SearchInput
                ref={inputRef}
                onChange={handleChange}
                value={searchValue}
                placeholder="Start Typing"
                allowClear
                autoComplete="off"
              />
            </Row>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[48, 48]}>
            <Collapse
              bordered={false}
              expandIconPosition="right"
              activeKey={accordionKey}
              onChange={key => handleCardToggle(key)}
            >
              {!searchValue &&
                companyFields.map((fieldsSource: CompanyFieldsGroup) => {
                  if (fieldsSource.label === 'Company URL') return null;

                  return (
                    <Collapse.Panel
                      header={
                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography>{fieldsSource.label}</Typography>
                          {fieldsSource.id === 1 &&
                            tabKey === 'proprietaryFieldsTab' &&
                            showAddYear && (
                              <Button
                                style={{ marginRight: '10px' }}
                                className="link-btn"
                                onClick={e => {
                                  e.stopPropagation();
                                  addYearHandler();
                                }}
                                tabIndex={-1}
                              >
                                +Add Years
                              </Button>
                            )}
                          {showAddUrlButton(fieldsSource.id) && (
                            <Button
                              style={{ marginRight: '10px' }}
                              className="link-btn"
                              disabled={secondaryUrls?.some(url => url === '')}
                              onClick={e => {
                                e.stopPropagation();

                                if (secondaryUrls) {
                                  setFieldValue('secondary_urls', [...secondaryUrls, '']);
                                }
                              }}
                              tabIndex={-1}
                            >
                              +Add URL
                            </Button>
                          )}
                        </Row>
                      }
                      key={fieldsSource.id}
                      forceRender={true}
                    >
                      {fieldsSource.id === 1 && addYear && tabKey === 'proprietaryFieldsTab' && (
                        <AddYearBox
                          addYearHandler={addYearHandler}
                          saveYearHandler={saveYearHandler}
                        />
                      )}
                      <Row gutter={[12, tabKey?.toString() === 'gridOrderTab' ? 0 : 16]}>
                        {fieldsSource &&
                          fieldsSource.data?.map((field: BaseField) => {
                            return tabKey?.toString() === 'gridOrderTab' ? (
                              <Styled.CustomCol key={field.id} span={24}>
                                <FieldFactory
                                  key={field.id}
                                  field={field as BaseField}
                                  fieldUsedFor="editAllInfo"
                                  tabKey={tabKey}
                                />
                              </Styled.CustomCol>
                            ) : (
                              <FieldFactory
                                key={field.id}
                                field={field as BaseField}
                                fieldUsedFor="editAllInfo"
                                tabKey={tabKey}
                              />
                            );
                          })}
                      </Row>
                    </Collapse.Panel>
                  );
                })}
              {searchValue &&
                (filteredData as BaseField<any>[]).map((field: BaseField) => (
                  <Row gutter={[12, 16]} key={field.id}>
                    <Styled.SearchedFieldContainer>
                      <FieldFactory
                        key={field.id}
                        field={field as BaseField}
                        fieldUsedFor="editAllInfo"
                      />
                    </Styled.SearchedFieldContainer>
                  </Row>
                ))}
            </Collapse>
          </Row>
        </Col>
      </Row>
    </Styled.FinancialEditAllContainer>
  );
};

export default EditAllCompanyInfo;
