/* eslint-disable no-nested-ternary */
import React, {
  ChangeEvent,
  useState,
  ReactNode,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Typography, Row, DatePicker } from 'antd';
import { OptionsType, ValueType } from 'react-select';
import { TagOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import numeral from 'numeral';
import moment, { Moment } from 'moment';
import { debounce } from 'lodash';
// models
import Company, { CompanyProfile } from '@optx/models/Company';
import { SuccessErrorCallback } from '@optx/models/callback';
import { ServiceType } from '../state/interfaces';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { SELECT_UNKNOWN } from '@optx/components/common/select/constants';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { COMPANY_NAME_SEARCH_ENDPOINT, LAST_INVESTORS_SEARCH_ENDPOINT } from '@constants/search';
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
import { regexNegativeAbbreviation } from '@optx/constants/regex';
// utils
import { normalizeMonetaryMinusValues, parseNumberFromAbbreviation } from '@utils/number';
import { normalizeNegativeSignValues } from '@optx/utils/utils';
import { getLastRoundDisabledDate } from '@optx/utils/date';
// redux
import {
  selectors as fundingRoundsSelectors,
  actions as fundingRoundsActions,
} from '@redux/company/funding-rounds';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { useHistoryTab } from '@optx/common/hooks/history';
import useUpdateFields from '../hooks/useUpdateFields';
// components
import EditPopover from '@components/common/popover/EditPopover';
import { AsyncSingleSelect, SingleSelect } from '@optx/shared/view/molecules/Select';
import AsyncCreatableSelect from '@optx/components/common/select/AsyncCreatableSelect';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

interface EditFieldSingleSelectTextProps {
  company: CompanyProfile | Company;
  fieldName: 'Last Round';
  service: ServiceType;
  isFromAuditField?: boolean;
  validation?: (value: string) => boolean;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  formatValue?: (value: number | string | null | undefined) => string;
  lastTemporaryFieldValue?: string | number;
  isProfileInformation?: boolean;
  hidePenIcon?: boolean;
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
  actionPendo?: string;
}

interface SelectType {
  label: string | null;
  value: string | number | null;
}

const ContentContainer = styled.div`
  .ant-row {
    padding: 2px 0 !important;

    .ant-typography {
      padding-bottom: 2px;
    }
  }
`;

const SelectAsyncContainer = styled(AsyncSingleSelect)`
  width: 100%;
`;
const SelectContainer = styled(SingleSelect)`
  width: 100%;
`;

const loadValue = (
  value: string | number | null,
  formatValue?: (value: number | string | null | undefined) => string
) => {
  if (!value) return '';

  const formattedValue = formatValue && formatValue(value);

  return formattedValue === '0' ? '' : formattedValue || value || '';
};

const EditFieldSingleSelectText: React.FC<EditFieldSingleSelectTextProps> = ({
  company,
  validation,
  formatValue,
  getPopupContainer,
  isProfileInformation = false,
  hidePenIcon,
  fieldName,
  service,
  isFromAuditField,
  lastTemporaryFieldValue,
  isDynamicPopoverHeight,
  destroyOnHide,
  actionPendo,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateHistoryFields } = useHistoryTab();
  const { updateField } = useUpdateFields({
    fieldName,
    companyUrl: company.company_url,
    companyId: company.company_id,
    service,
  });
  const [value, setValue] = useState<string | number>(
    loadValue(company.last_raised_amount, formatValue)
  );

  const title = `Edit "${fieldName}" value`;

  const lastTemporaryFieldValueFormatted =
    formatValue && company.last_raised_amount !== null
      ? formatValue(lastTemporaryFieldValue ?? company.last_raised_amount)
      : DEFAULT_EMPTY_VALUE;
  const [label, setLabel] = useState(loadValue(company.last_raised_amount, formatValue));
  const [extraValue, setExtraValue] = useState<string | ReactNode>(
    company.last_round ? `(${company.last_round})` : ''
  );
  const popoverContentRef = useRef(null);
  const lastTemporarySelectedValue = {
    label: company.last_round,
    value: company.last_round_id,
  };

  const [selectedType, setSelectedType] = useState<SelectType>({
    label: company.last_round,
    value: company.last_round_id,
  });
  const [selectedAcquiredCompany, setSelectedAcquiredCompany] = useState<SelectType>({
    label: company.parentcompany,
    value: company.parentcompany,
  });
  const [acquiredCompanyFetchedOptions, setAcquiredCompanyFetchedOptions] = useState<
    OptionsType<SelectOption>
  >([SELECT_UNKNOWN]);
  const { loadOptions: loadAcquiredCompanyOptions } = useAsyncSearch({
    endpoint: COMPANY_NAME_SEARCH_ENDPOINT,
  });

  const [selectedInvestorName, setSelectedInvestorName] = useState<SelectOption>({
    label: company.last_investors?.[0] || '',
    value: company.last_investors?.[0] || '',
  });
  const [investorNameFetchedOptions, setInvestorNameFetchedOptions] = useState<
    OptionsType<SelectOption>
  >([]);
  const { loadOptions: loadInvestorNameOptions } = useAsyncSearch({
    endpoint: LAST_INVESTORS_SEARCH_ENDPOINT,
  });

  const [raiseDate, setRaiseDate] = useState<Moment>(moment(company.raise_date || undefined));

  const [closePopup, setClosePopup] = useState(false);

  const fundingTypes = useSelector(fundingRoundsSelectors.getFundingTypes);
  const secondToLastRoundDate = useSelector(fundingRoundsSelectors.getSecondToLastRoundDate);
  const numberOfRounds = useSelector(fundingRoundsSelectors.getNumberOfRounds);

  const updateValue = useCallback(() => {
    setValue(loadValue(company.last_raised_amount, formatValue));
    setSelectedType({
      label: company.last_round,
      value: company.last_round_id,
    });
    setSelectedAcquiredCompany({
      label: company.parentcompany,
      value: company.parentcompany,
    });
    setSelectedInvestorName({
      label: company.last_investors?.[0] || '',
      value: company.last_investors?.[0] || '',
    });
    setRaiseDate(moment(company.raise_date || undefined));
  }, [company, formatValue]);

  useOnClickOutside(popoverContentRef, () => updateValue());

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (validation) {
      if (validation(value)) {
        setValue(value);
      } else {
        return;
      }
    } else {
      setValue(value);
    }
  };

  const handleSaveCallback = (data?: SuccessErrorCallback, showHistory?: boolean) => {
    if (data) {
      setLabel(value ? (parseNumberFromAbbreviation(value.toString()) as number) : '$0.0');

      if (selectedType?.label) {
        setExtraValue(`(${selectedType?.label})`);
      }

      if (showHistory) {
        if (lastTemporaryFieldValueFormatted !== value && formatValue) {
          updateHistoryFields({
            afterValue: value === '' ? null : value,
            beforeValue:
              lastTemporaryFieldValueFormatted === DEFAULT_EMPTY_VALUE
                ? null
                : lastTemporaryFieldValueFormatted,
            fieldChanged: 'Last Raised Amount',
          });
        }

        if (
          lastTemporarySelectedValue.value !== undefined &&
          lastTemporarySelectedValue.value !== selectedType.value
        ) {
          updateHistoryFields({
            afterValue: selectedType.label,
            beforeValue: lastTemporarySelectedValue.label,
            fieldChanged: 'Last Round',
          });
        }

        updateHistoryFields({
          afterValue: selectedAcquiredCompany.label,
          beforeValue: company.parentcompany,
          fieldChanged: 'Parent Company',
        });
      }

      if (location.pathname.includes('profile')) {
        dispatch(fundingRoundsActions.getCompanyFundingRounds(company.company_id));
      }
    }
  };

  const handleSave = () => {
    const lastInvestorValue = selectedInvestorName?.value ? [selectedInvestorName?.value] : [];

    const lastRound = {
      last_round: (selectedType?.label as string) || 'Unknown',
      last_round_id: (selectedType?.value as number) || 0,
      parentcompany: (selectedAcquiredCompany?.value ?? '') as string,
      last_investors: lastInvestorValue,
      raise_date: raiseDate.format(ISO_DATE_FORMAT),
    };
    const formattedValue = parseNumberFromAbbreviation(value.toString());

    const isValueAbbreviated = typeof value === 'string' && regexNegativeAbbreviation.test(value);

    updateField(
      isValueAbbreviated ? formattedValue : Number((formattedValue! * 1000000).toFixed(0)),
      'last_raised_amount',
      lastRound,
      handleSaveCallback
    );

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    setClosePopup(true);
  };

  const handleCancel = () => setClosePopup(true);

  const handleDashLastRoundAmount = () => {
    if (company.last_round === null) {
      if (company.last_raised_amount !== 'Unknown' && company.last_raised_amount !== null) {
        return normalizeNegativeSignValues(
          numeral(company.last_raised_amount).format(`$${NUMBER_FORMAT}`)
        );
      }

      if (company.last_raised_amount === 'Unknown' || company.last_raised_amount === 0) {
        return 'Unknown';
      }

      return DEFAULT_EMPTY_VALUE;
    }

    if (company.last_raised_amount === 'Unknown' || company.last_raised_amount === 0) {
      return `Unknown ${extraValue}`;
    }

    if (company.last_round) {
      return `${normalizeNegativeSignValues(
        numeral(company.last_raised_amount).format(`$${NUMBER_FORMAT}`)
      )} ${extraValue}`;
    }

    const lastRoundValue = label
      ? formatValue
        ? `${normalizeMonetaryMinusValues(formatValue(label))}`
        : label
      : DEFAULT_EMPTY_VALUE;

    return `${lastRoundValue} ${extraValue}`;
  };

  const onVisibilityChange = () => {
    setValue(loadValue(company.last_raised_amount, formatValue));
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    setValue(loadValue(company.last_raised_amount, formatValue));
    setLabel(loadValue(company.last_raised_amount, formatValue));
    setSelectedType({
      label: company.last_round,
      value: company.last_round_id,
    });
    setSelectedInvestorName({
      label: company.last_investors?.[0] || '',
      value: company.last_investors?.[0] || '',
    });
    setRaiseDate(moment(company.raise_date || undefined));
    setExtraValue(company.last_round ? `(${company.last_round})` : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const loadAcquiredCompanyOptionsDelay = debounce(
    (query: string, callback: SuccessErrorCallback) => {
      if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
        loadAcquiredCompanyOptions(query, options => {
          options.unshift(SELECT_UNKNOWN);
          const callbackResult = callback(options);

          // set current filter to a cached variable.
          setAcquiredCompanyFetchedOptions(options);

          return callbackResult;
        });
      }
    },
    300
  );

  const loadInvestorNameOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadInvestorNameOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setInvestorNameFetchedOptions(options);

        return callbackResult;
      });
    }
  };

  // when menu is closed, clear cached values.
  const handleAcquiringCompanyMenuClose = () => {
    setAcquiredCompanyFetchedOptions([SELECT_UNKNOWN]);
  };

  // when menu is closed, clear cached values.
  const handleInvestorNameMenuClose = () => {
    setInvestorNameFetchedOptions([{ label: '', value: '' }]);
  };

  const handleFundingRoundDateChange = (value: moment.Moment | null) => {
    if (value) setRaiseDate(value);
  };

  const handleInvestorNameChange = (option: ValueType<SelectOption<string>> | null) => {
    // avoid label with 'Create' prefix from CreatableSelect
    const value = option ? (option as SelectOption).value : '';
    setSelectedInvestorName({ label: value, value });
  };

  const isButtonActive = useMemo(() => {
    const isRaiseDateChanged =
      raiseDate.format(ISO_DATE_FORMAT) !==
      moment(company.raise_date || undefined).format(ISO_DATE_FORMAT);
    const isInvestorNameChanged =
      !(
        selectedInvestorName.value === '' &&
        (!company.last_investors || company.last_investors?.length === 0)
      ) && !company.last_investors?.includes(selectedInvestorName.value);

    const someValueChanged =
      selectedType.value !== company.last_round_id ||
      selectedAcquiredCompany.value !== company.parentcompany ||
      isInvestorNameChanged ||
      isRaiseDateChanged ||
      value !== loadValue(company.last_raised_amount, formatValue);

    const isAcquiredCompanyFilled =
      selectedType?.label !== 'Acquisition' ||
      (selectedType?.label === 'Acquisition' && selectedAcquiredCompany?.value !== null);

    return someValueChanged && isAcquiredCompanyFilled;
  }, [
    selectedInvestorName,
    selectedType.value,
    selectedType?.label,
    company.last_round_id,
    company.parentcompany,
    company.last_investors,
    company.raise_date,
    company.last_raised_amount,
    selectedAcquiredCompany.value,
    raiseDate,
    value,
    formatValue,
  ]);

  const lastRoundDisabledDate = getLastRoundDisabledDate(
    raiseDate,
    moment(secondToLastRoundDate),
    numberOfRounds
  );

  const content = (
    <ContentContainer className="profile-information__financial" ref={popoverContentRef}>
      <Row>
        <Typography.Text>Funding Type</Typography.Text>
        <SelectContainer
          options={fundingTypes}
          onChange={(input: SelectType) => {
            if (input.label !== 'Acquisition' && selectedAcquiredCompany.label !== null) {
              setSelectedAcquiredCompany({
                label: null,
                value: null,
              });
            }

            setSelectedType(input);
          }}
          value={selectedType}
          placeholder="Please Select"
          closeMenuOnSelect
          SearchIcon={null}
          onKeyDown={handleDropdownKeyEvent}
          tabIndex={0}
        />
      </Row>
      {selectedType?.label === 'Acquisition' && (
        <Row>
          <Typography.Text>Acquiring company</Typography.Text>
          <SelectAsyncContainer
            className="addon--multiselect-async"
            onChange={(value: SelectOption) => setSelectedAcquiredCompany(value)}
            loadOptions={loadAcquiredCompanyOptionsDelay}
            controlShouldRenderValue
            closeMenuOnSelect
            defaultOptions={acquiredCompanyFetchedOptions}
            required={selectedType?.label === 'Acquisition'}
            defaultValue={selectedAcquiredCompany}
            value={selectedAcquiredCompany}
            closeOnSelect
            onSelectResetsInput={false}
            blurInputOnSelect={false}
            onMenuClose={handleAcquiringCompanyMenuClose}
            onKeyDown={handleDropdownKeyEvent}
            tabIndex={0}
            autoFocus
          />
        </Row>
      )}
      <Row>
        <Typography.Text>Funding Amount</Typography.Text>
        <Input
          value={value}
          onChange={handleChangeInput}
          placeholder={'in millions of dollars'}
          onKeyDown={handleDropdownKeyEvent}
          tabIndex={0}
        />
      </Row>
      <Row>
        <Typography.Text>Investor Name</Typography.Text>
        <AsyncCreatableSelect
          value={selectedInvestorName}
          loadOptions={loadInvestorNameOptionsDelay}
          options={investorNameFetchedOptions}
          onChange={handleInvestorNameChange}
          onMenuClose={handleInvestorNameMenuClose}
          controlShouldRenderValue
          closeMenuOnSelect
          disableValueRemove
          autoFocus={false}
          onKeyDown={e => e && e.key === 'Enter' && handleSave()}
          tabIndex={0}
        />
      </Row>
      <Row>
        <Typography.Text>Funding Round Date</Typography.Text>
        <DatePicker
          value={raiseDate}
          defaultValue={raiseDate}
          onChange={handleFundingRoundDateChange}
          allowClear={false}
          disabledDate={lastRoundDisabledDate}
          format={globalConfig.short_date.DATE_FORMAT}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
          tabIndex={0}
        />
      </Row>
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isButtonActive}
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </ContentContainer>
  );

  if (isFromAuditField) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        onVisibilityChange={onVisibilityChange}
        hidePenIcon={hidePenIcon}
        getPopupContainer={getPopupContainer}
        placement="top"
        isDynamicPopoverHeight={isDynamicPopoverHeight}
        destroyOnHide={destroyOnHide}
        customOverlayStyle={{ zIndex: 1060 }} // make it stay above "Edit Field" menu item
      >
        Edit Field
      </EditPopover>
    );
  }

  if (isProfileInformation) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        onVisibilityChange={onVisibilityChange}
        getPopupContainer={getPopupContainer}
        hidePenIcon={hidePenIcon}
      >
        <TruncateTooltip title={handleDashLastRoundAmount()}>
          {handleDashLastRoundAmount()}
        </TruncateTooltip>
      </EditPopover>
    );
  }

  return (
    <div className="media-object company-kpi">
      <div className="media-object-section">
        <div className="company-kpi__icon">
          <TagOutlined />
        </div>
      </div>
      <div className="media-object-section">
        <div className="company-kpi__title company-kpi__edit">
          <EditPopover
            content={content}
            title={title}
            closePopup={closePopup}
            setClosePopup={setClosePopup}
            onVisibilityChange={onVisibilityChange}
            hidePenIcon={false}
            getPopupContainer={getPopupContainer}
            placement="bottom"
            actionPendo={actionPendo}
          >
            {handleDashLastRoundAmount()}
          </EditPopover>
        </div>
        <div className="company-kpi__label">Last Round</div>
      </div>
    </div>
  );
};

export default React.memo(EditFieldSingleSelectText);
