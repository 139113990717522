import React from 'react';
import { Row, Col, Space } from 'antd';
import { RowProps } from 'antd/lib/row';
// components
import GridDropdown from '@components/pages/Home/SearchGridControls/GridDropdown';
import CardsToggle from './Table/CardsToggle';
import SearchCount from '@optx/features/grid/search-count/components/SearchCount';

const SearchGridControls: React.FC<RowProps> = props => {
  return (
    <Row justify="space-between" align="middle" className="my-companies-second-header" {...props}>
      <SearchCount gridName="pipelineReport" />
      <Col>
        <Space size={4}>
          <CardsToggle />
          <GridDropdown />
        </Space>
      </Col>
    </Row>
  );
};

export default React.memo(SearchGridControls);
