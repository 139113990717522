import { CaseReducer, PayloadAction, createReducer } from '@reduxjs/toolkit';
// models
import Company from '@models/Company';
import { AddCompanyState, UrlDataResponse } from './interfaces';
// constants
import { STATE_INITIAL_VALUES } from '../constants';
// redux
import * as actions from './actions';
import { AddedCompany } from '../models';

const setStateLoading = (draftState: AddCompanyState, loading: boolean) => {
  draftState.loading = loading;
};

const checkSimilarCompaniesReducer: CaseReducer<AddCompanyState> = draftState => {
  setStateLoading(draftState, true);
};

const checkSimilarCompaniesSuccessReducer: CaseReducer<
  AddCompanyState,
  PayloadAction<Company[]>
> = (draftState, action) => {
  draftState.similarCompanies = action.payload;
  setStateLoading(draftState, false);
};

const checkSimilarCompaniesFailReducer: CaseReducer<AddCompanyState> = draftState => {
  setStateLoading(draftState, false);
};

const checkUrlReducer: CaseReducer<AddCompanyState> = draftState => {
  draftState.urlData = {
    loading: true,
    isValid: false,
  };
};

const checkUrlSuccessReducer: CaseReducer<AddCompanyState, PayloadAction<UrlDataResponse>> = (
  draftState,
  action
) => {
  const { available, company_id, message } = action.payload;

  draftState.urlData = {
    loading: false,
    isValid: available,
    foundCompanyId: company_id ?? null,
    message: message ?? null,
  };
  draftState.similarCompanies = null;
};

const checkUrlFailReducer: CaseReducer<AddCompanyState> = draftState => {
  draftState.urlData = {
    loading: false,
    isValid: false,
  };
};

const resetUrlDataReducer: CaseReducer<AddCompanyState> = draftState => {
  draftState.urlData = null;
};

const addCompanyReducer: CaseReducer<AddCompanyState> = draftState => {
  setStateLoading(draftState, true);
};

const addCompanySuccessReducer: CaseReducer<AddCompanyState, PayloadAction<AddedCompany>> = (
  draftState,
  action
) => {
  const { company_name, company_id, company_et_id, company_url, company_et_url } = action.payload;

  draftState.addedCompany = {
    company_name,
    company_id,
    company_url,
    company_et_id,
    company_et_url,
  };

  setStateLoading(draftState, false);
};

const addCompanyFailReducer: CaseReducer<AddCompanyState> = draftState => {
  setStateLoading(draftState, false);
};

const reducer = createReducer<AddCompanyState>(STATE_INITIAL_VALUES, builder =>
  builder
    .addCase(actions.checkSimilarCompanies, checkSimilarCompaniesReducer)
    .addCase(actions.checkSimilarCompaniesSuccess, checkSimilarCompaniesSuccessReducer)
    .addCase(actions.checkSimilarCompaniesFail, checkSimilarCompaniesFailReducer)
    .addCase(actions.checkUrl, checkUrlReducer)
    .addCase(actions.checkUrlSuccess, checkUrlSuccessReducer)
    .addCase(actions.checkUrlFail, checkUrlFailReducer)
    .addCase(actions.resetUrlData, resetUrlDataReducer)
    .addCase(actions.addCompany, addCompanyReducer)
    .addCase(actions.addCompanySuccess, addCompanySuccessReducer)
    .addCase(actions.addCompanyFail, addCompanyFailReducer)
);

export default reducer;
