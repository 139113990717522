import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Col } from 'antd';
// redux
import { selectors as modalSelectors } from '@redux/ui/modals/company-touches';

interface TouchModalTitleProps {
  isSaveDisabled?: boolean;
  handleCancel: () => void;
  handleSave: () => void;
}

const TouchModalTitle: React.FC<TouchModalTitleProps> = ({
  isSaveDisabled,
  handleCancel,
  handleSave,
}) => {
  const saveBtnStatus = useSelector(modalSelectors.getDisableSaveBtnFor);
  const saveBtnStatusByAdditionalFields = useSelector(
    modalSelectors.getDisableSaveBtnByAdditionalFields
  );
  const modalTitle = useSelector(modalSelectors.getModalTitle);

  return (
    <Row justify="space-between">
      <Col span={12}>
        <div className="modal-title">
          <span>{modalTitle}</span>
        </div>
      </Col>
      <Col>
        <div className="company-buttons">
          <Button className="btn-cancel" onClick={handleCancel}>
            CANCEL
          </Button>{' '}
          <Button
            type="primary"
            className="btn-send"
            onClick={handleSave}
            disabled={isSaveDisabled || !!saveBtnStatus || saveBtnStatusByAdditionalFields}
          >
            SAVE
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(TouchModalTitle);
