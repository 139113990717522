import { FC, useState, memo } from 'react';
import { Row, Col, Card } from 'antd';
import { useSelector } from 'react-redux';
// models
import { CompanyProfile } from '@optx/models/Company';
import { ReferrerUrlOrigin } from '@optx/models/routes';
import { GridKeysMain } from '@optx/models/grid';
// utils
import { getIsPsgCompany } from '@utils/company';
import { searchInNewTab } from '@optx/utils/search';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import CompanyReview from '@optx/features/company-review/components/CompanyReview';
import { CompanyAvatar } from '@components/common/avatar';
import { ScoreTrending } from '@components/common/trending';
import MainBreadcrumb from '@components/common/breadcrumbs/MainBreadcrumb';
import EditFieldOPTX from '@optx/features/company/edit-fields/components/EditFieldOPTX';
import EditParentCompanyInfluence from '@optx/features/company/edit-fields/components/EditParentCompanyInfluence';
import CompanyRank from './CompanyRank';
import ProfileNavigation from './ProfileNavigation';
import CompanyProfileLinks from './CompanyProfileLinks';
import CompanyKPIList from './CompanyKPIList';
import CompanySourceDescriptions from './CompanySourceDescriptions';
import PSGFit from './PSGFit';
import CompanyTagsCollapsable from '@optx/features/tags/overflow-tags/CompanyTagsCollapsable';
import { ListTagsCollapsable } from '@optx/features/tags/overflow-tags';
// styles
import './styles.scss';

interface ProfileBannerProps {
  company: CompanyProfile;
}

const ProfileBanner: FC<ProfileBannerProps> = ({ company }) => {
  const [areTagsCollapsed, setTagsCollapsed] = useState<boolean>(true);
  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);
  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  const getGridKey = (): GridKeysMain => {
    if (window.location.search.includes('my-companies')) {
      return 'myCompanies';
    }

    if (window.location.search.includes('lists')) {
      return 'lists';
    }

    if (window.location.search.includes('sourcing-outreach')) {
      return 'outreach';
    }

    return 'advancedSearch';
  };

  const companiesIds = useSelector(
    searchSelectors.getPreviousNextCompanyIds(getGridKey(), company.company_id)
  );

  const hideProfileNavigation =
    window.location.search.includes('notes') || window.location.search.includes('contacts');

  const companyWatchLists =
    company.et_watchlists && company.regular_watchlists
      ? [...company.et_watchlists, ...company.regular_watchlists]
      : company.et_watchlists
      ? company.et_watchlists
      : company.regular_watchlists;

  const { advancedSearchPathname } = useIsAnalyst();

  const handleParentCompanyClick = () => {
    // some parent companies have ' dba ' in their names
    // in that case we only search for name after ' dba '
    // ex: 'Football1x2, Ltd. dba 1X2 Network'
    const [firstPart, secondPart] = company.parentcompany.split(' dba ');

    searchInNewTab(
      clearedFilter,
      sources,
      'query',
      secondPart || firstPart,
      {
        referrer: ReferrerUrlOrigin.parentCompanySearch,
      },
      advancedSearchPathname
    );
  };

  const handleShowParentCompany = () => {
    if (
      (company.parentcompany && company.last_round) ||
      (company.parentcompany && company.last_round === null)
    ) {
      return (
        <Row>
          <Col xs={24}>
            <div className="profile__parent-company">
              <EditParentCompanyInfluence
                company={company}
                handleParentCompanyClick={handleParentCompanyClick}
              />
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <div className="profile-hero">
      <Card>
        <div className="media-object">
          <div className="media-object-section">
            <CompanyAvatar
              className="company-avatar--profile"
              logoUrl={company.logo_url}
              companyName={company.company_name}
            />
            {isAdminOrAnalyst ? (
              <EditFieldOPTX record={company}>
                {defaultOptxScore === 'il' ? (
                  <ScoreTrending
                    score={company.il_optx_score}
                    growth={company.il_score_growth as number}
                    label="IL OPTX SCORE"
                    isPSGCompany={getIsPsgCompany(company)}
                    customFontSize="20px"
                  />
                ) : (
                  <ScoreTrending
                    score={company.score}
                    growth={company.optx_score_growth}
                    label="OPTX SCORE"
                    isPSGCompany={getIsPsgCompany(company)}
                    customFontSize="20px"
                  />
                )}
              </EditFieldOPTX>
            ) : (
              <>
                {defaultOptxScore === 'il' ? (
                  <ScoreTrending
                    score={company.il_optx_score}
                    growth={company.il_score_growth as number}
                    label="IL OPTX SCORE"
                    isPSGCompany={getIsPsgCompany(company)}
                    customFontSize="20px"
                  />
                ) : (
                  <ScoreTrending
                    score={company.score}
                    growth={company.optx_score_growth}
                    label="OPTX SCORE"
                    isPSGCompany={getIsPsgCompany(company)}
                    customFontSize="20px"
                  />
                )}
              </>
            )}
          </div>
          <div className="media-object-section" style={{ width: '100%' }}>
            <Row style={{ position: 'relative' }}>
              <Col span={24} style={{ position: 'absolute', top: -60, width: '100%' }}>
                <Row className="profile-breadcrumbs" justify="space-between">
                  <Col className="profile-breadcrumbs--wrapper" flex="auto">
                    <MainBreadcrumb inverted goBack />
                  </Col>
                  <Col className="profile-breadcrumbs--column">
                    <CompanyReview
                      companyId={company.company_id}
                      reviewedBy={company.company_reviewed_by}
                      reviewedDate={company.company_reviewed_date}
                      isReviewed={company.is_company_reviewed}
                    />
                    {!hideProfileNavigation && (companiesIds[0] || companiesIds[1]) ? (
                      <ProfileNavigation
                        previousCompanyId={companiesIds[0]}
                        nextCompanyId={companiesIds[1]}
                      />
                    ) : (
                      <CompanyRank company={company} />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CompanyProfileLinks company={company} />
                {handleShowParentCompany()}
                <Row className="tags-owner-row">
                  <Col flex="auto">
                    <CompanySourceDescriptions company={company} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="owner-fit-wrapper">
              <Col style={{ width: 'calc(100% - 210px)' }}>
                <CompanyTagsCollapsable
                  company={company}
                  isAdd
                  closable
                  showPSGFit
                  setTagsCollapsed={setTagsCollapsed}
                />
                {companyWatchLists && <ListTagsCollapsable tags={companyWatchLists} />}
              </Col>
              {!areTagsCollapsed && (
                <Col>
                  <PSGFit isFromHistory={false} />
                </Col>
              )}
            </Row>
          </div>
        </div>

        <CompanyKPIList company={company} />
      </Card>
    </div>
  );
};

export default memo(ProfileBanner);
