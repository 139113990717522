import { call, put, takeLatest, takeLeading, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { FilterResponse } from '@optx/models/filters';
import { UserSettings } from '@optx/models/user';
// services
import { FilterService } from '@services/api';
import NotificationService from '@optx/services/NotificationService';
// utils
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import * as actions from './actions';

function* getCompanyFiltersSaga(action: PayloadAction) {
  const settings: UserSettings = yield select(userSelectors.getSettings);
  const isAnalyst: boolean = yield select(userSelectors.getIsAnalyst);
  const isForNotifications = action.type === actions.fetchCompanyFiltersForNotifications.type;

  try {
    const res: AxiosResponse<FilterResponse> = yield call(FilterService.getOptions);

    if (res.data) {
      const data = {
        defaultViews: res.data.default_views,
        searchOrigin: res.data.search_origin,
        settings,
        isAnalyst,
      };

      if (isForNotifications) {
        yield put(actions.fetchCompanyFiltersForNotificationsSuccess(res.data.filters, data));
      } else {
        yield put(actions.fetchCompanyFiltersSuccess(res.data.filters, data));
      }

      yield put(actions.setFormRationales(res.data?.forms?.rationales || {}));
      yield put(actions.setNextSteps(res.data?.forms?.next_steps?.next_step_choices || []));
      yield put(actions.setAlertSettings(res.data?.forms?.alert_settings || {}));
    } else {
      const errorMessage = 'Error fetch company filters!';

      if (isForNotifications) {
        yield put(actions.fetchCompanyFiltersForNotificationsFail(errorMessage));
      } else {
        yield put(actions.fetchCompanyFiltersFail(errorMessage));
      }

      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Error fetch company filters!');

    if (isForNotifications) {
      yield put(actions.fetchCompanyFiltersForNotificationsFail(errorMessage));
    } else {
      yield put(actions.fetchCompanyFiltersFail(errorMessage));
    }

    NotificationService.error(errorMessage);

    if (e.response.status === 401) {
      yield put(actions.stopFetchingFilters(true));
    }
  }
}

export default function* companyFiltersSaga() {
  yield takeLeading(actions.fetchCompanyFilters, getCompanyFiltersSaga);
  yield takeLatest(actions.fetchCompanyFiltersForNotifications, getCompanyFiltersSaga);
}
