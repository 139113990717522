import React, { useMemo } from 'react';
// models
import Company from '@models/Company';
// constants
import defaultColumnsFast from '@components/common/table/Companies/columns/virtual-column-config-fast-load';

interface SearchGridTableProps {
  companies: Company[];
  columns: any[];
}

const updateFinalColumns = (columns: any[], defaultColumnsFast: any[]) => {
  const finalColumns = defaultColumnsFast.filter(col =>
    columns.some(activeCol => activeCol.dataIndex === col.dataIndex)
  );

  return finalColumns;
};

const SearchGridTable: React.FC<SearchGridTableProps> = ({ companies, columns }) => {
  const mergedColumns = useMemo(() => {
    return updateFinalColumns(columns, defaultColumnsFast);
  }, [columns]);

  if (columns.length === 0 || companies.length === 0) return null;

  return (
    <div
      style={{
        overflow: 'auto',
        maxWidth: '100%',
        maxHeight: 'calc(100vh - 270px)',
        background: '#fff',
      }}
    >
      <table>
        <thead className="ant-table-thead">
          <tr className="ant-table-cell">
            {mergedColumns.map((col, index) => {
              return (
                <th
                  key={index}
                  style={{
                    padding: '15px',
                    maxWidth: col.width + 'px',
                    width: col.width + 'px',
                    background: 'rgb(250, 250, 250)',
                  }}
                >
                  {col.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {companies.map((row: any, index: number) => {
            return (
              <tr className="ant-table-row" key={index}>
                {mergedColumns.map((col, index) => {
                  return (
                    <td
                      key={`td-${index}`}
                      className={`ant-table-cell ${col.className}`}
                      style={{
                        padding: '15px',
                        maxWidth: col.width + 'px',
                        width: col.width + 'px',
                        minHeight: '220px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {col?.render && col?.dataIndex
                        ? col.render(row[col.dataIndex], row, index)
                        : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SearchGridTable;
