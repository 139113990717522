export interface ReferrerUrlParam {
  referrer?: string;
}

export type RouteAliases =
  | 'advancedSearch'
  | 'myCompanies'
  | 'companyOutReach'
  | 'pipelineReport'
  | 'userLists'
  | 'allReports'
  | 'sourceScrubLists'
  | 'companyProfile'
  | 'watchList'
  | 'chromeExtension'
  | 'companyAddonManagement'
  | 'contacts'
  | 'companyFiles'
  | 'companyHistory'
  | 'checklistResearchInsights'
  | 'touchManagement';

export enum ReferrerUrlOrigin {
  parentCompanySearch = 'parentCompanySearch',
}

/**
 * Types used for location's state general data in order to support Typescript.
 */
export type RouteRedirectStateField = 'addContacts' | 'addTouch';
export type RouteRedirectStateProps = Partial<Record<RouteRedirectStateField, boolean>>;
