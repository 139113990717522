import React from 'react';
import { components as DefaultComponents } from 'react-select';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';

const DropdownIndicator = (props: any) => {
  return (
    <DefaultComponents.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <SearchOutlined /> : <DownOutlined />}
    </DefaultComponents.DropdownIndicator>
  );
};

export default React.memo(DropdownIndicator);
