/* eslint-disable max-len */
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Menu, Button, Dropdown, Typography, Tooltip } from 'antd';
// models
import { ProductCategory, DatePresented } from '@optx/models/Company';
import { ChangedCompanyField, FieldChangedValue } from '../state/interfaces';
// constants
import globalConfig from '@optx/constants/config';
import { CompanyPageHistory } from '@constants/pendoActions';
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
// utils
import {
  renderAfterValue,
  renderFormerValue,
  isEmptyRevenue,
  renderFormerOPTX,
  renderAfterOPTX,
} from '@optx/utils/auditFields';
import { showRationaleForStage, showOpinionForNextSteps } from '@optx/utils/helpers';
import { validateField } from '@optx/utils/editFields';
import { formatValueToMillions } from '@optx/utils/format';
// hooks
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@optx/components/feature/company-individual-edit';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
import { CompanyService, UserService } from '@optx/services/api';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';
import { selectors as auditSelectors } from '@features/company/audit-trail/state/index';
import { selectors as opportunitySelectors } from '@redux/company/opportunity-presentation';
// components
import Icon from '@components/common/Icon';
import {
  EditFieldCheckBoxSelectAsync,
  EditFieldRadio,
  EditFieldSingleSelect,
  EditFieldSingleSelectOther,
  EditFieldSingleSelectText,
  EditFieldFinancial,
  EditFieldRadioRationale,
  EditFieldMultiSelect,
  EditFieldMultiSelectAsyncProductCategory,
  EditFieldCheckboxText,
  EditFieldMultiSelectAsyncCreatable,
} from '../../edit-fields/components';
import EditFieldText from '../../edit-fields/components/EditFieldText';
import EditFieldDate from '../../edit-fields/components/EditFieldDate';
import EditFieldNumber from '@optx/components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/inputs/EditFieldNumber';
import EditPreparationFieldSingleSelect from '@components/pages/CompanyProfile/ProfileTabs/DealPresentation/components/inputs/EditFieldSingleSelect';
// styles
import { Styles } from './AuditTrail.styled';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  position: relative;

  .ant-popover {
    z-index: 1060;
  }
`;

interface AuditFieldProps {
  field: ChangedCompanyField;
}

export const AuditField: React.FC<AuditFieldProps> = ({ field }) => {
  const containerRef = useRef(null);
  const company = useSelector(profileSelectors.getProfile);
  const fields = useSelector(auditSelectors.getFields);
  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);
  const opportunityData = useSelector(opportunitySelectors.getOpportunityPresentation);
  const opportunityDataDealPreparation = useSelector(opportunitySelectors.getDealPreparationValue);
  const dealValues = useSelector(opportunitySelectors.getDealPreparationValue);

  const arrOptions = useSelector(getFinancialFilterOptions('last_arr_value'));
  const ebitdaOptions = useSelector(getFinancialFilterOptions('ebitda_numeric'));
  const revenueOptions = useSelector(getFinancialFilterOptions('last_rev_update_amount'));
  const gmOptions = useSelector(getFinancialFilterOptions('last_gross_margin'));

  const fieldOptions = {
    [FieldChangedValue.revenue]: revenueOptions,
    [FieldChangedValue.lastRevenue]: revenueOptions,
    [FieldChangedValue.lastRevUpdateAmount]: revenueOptions,
    [FieldChangedValue.ebitda]: ebitdaOptions,
    [FieldChangedValue.arr]: arrOptions,
    [FieldChangedValue.gm]: gmOptions,
  };

  const optxScoreImpactLabel =
    field.score_type === 'il'
      ? 'IL OPTX Score Impact: '
      : field.score_type === 'us'
      ? 'OPTX Score Impact: '
      : 'OPTX Score Beta Impact: ';

  const isMoney =
    field.field_changed === FieldChangedValue.revenue ||
    field.field_changed === FieldChangedValue.lastRaisedAmount ||
    field.field_changed === FieldChangedValue.lastRevUpdateAmount ||
    field.field_changed === FieldChangedValue.ebitda ||
    field.field_changed === FieldChangedValue.arr ||
    field.field_changed === FieldChangedValue.capitalRaised;
  const shouldDisplayRationale = field.field_changed === FieldChangedValue.stage;
  const shouldDisplayOpinion = field.field_changed === FieldChangedValue.nextSteps;
  const options = fieldOptions[field.field_changed as keyof typeof fieldOptions];
  const afterValue = renderAfterValue(field, isMoney, options);
  const fromValue = renderFormerValue(field, isMoney, options);
  const afterOPTX = renderAfterOPTX(field);
  const fromOPTX = renderFormerOPTX(field);

  useInjectCompanyIndividualEdit();
  useInitialFetchEditOptions();

  const lastTemporaryFieldValue = useMemo(() => {
    return fields.find(item => item.field_changed === field.field_changed)?.after_value;
  }, [field.field_changed, fields]);

  const showDropdown = useMemo(() => {
    if (!company) return false;

    if (
      (company.source_tag.includes('et') &&
        (field.field_changed === FieldChangedValue.pipelineRank ||
          field.field_changed === FieldChangedValue.stage ||
          field.field_changed === FieldChangedValue.companyOwner ||
          field.field_changed === FieldChangedValue.seniorAdvisor ||
          field.field_changed === FieldChangedValue.fund ||
          field.field_changed === FieldChangedValue.leadSource ||
          field.field_changed === FieldChangedValue.nextSteps)) ||
      field.field_changed === FieldChangedValue.sector ||
      field.field_changed === FieldChangedValue.subSector ||
      field.field_changed === FieldChangedValue.subSectorAPI ||
      field.field_changed === FieldChangedValue.psgFit ||
      field.field_changed === FieldChangedValue.softwareCompany ||
      field.field_changed === FieldChangedValue.aiMl ||
      field.field_changed === FieldChangedValue.datePresented ||
      field.field_changed === FieldChangedValue.icStatusDate ||
      field.field_changed === FieldChangedValue.expectedCloseDate ||
      field.field_changed === FieldChangedValue.addon ||
      field.field_changed === FieldChangedValue.lastFundingDate ||
      field.field_changed === FieldChangedValue.capitalRaised ||
      field.field_changed === FieldChangedValue.numberOfEmployees ||
      field.field_changed === FieldChangedValue.addonBool ||
      field.field_changed === FieldChangedValue.revenue ||
      field.field_changed === FieldChangedValue.lastRevenue ||
      field.field_changed === FieldChangedValue.arr ||
      field.field_changed === FieldChangedValue.gm ||
      field.field_changed === FieldChangedValue.lastRaisedAmount ||
      field.field_changed === FieldChangedValue.psgPlatformCompany ||
      field.field_changed === FieldChangedValue.lastRound ||
      field.field_changed === FieldChangedValue.lastInvestors ||
      field.field_changed === FieldChangedValue.parentCompany ||
      field.field_changed === FieldChangedValue.isInBusiness ||
      field.field_changed === FieldChangedValue.psgPlatformCompany ||
      field.field_changed === FieldChangedValue.productCategory ||
      field.field_changed === FieldChangedValue.subVertical ||
      field.field_changed === FieldChangedValue.dealTeam ||
      field.field_changed === FieldChangedValue.opsTeam ||
      field.field_changed === FieldChangedValue.seniorDealTeamLead ||
      field.field_changed === FieldChangedValue.opportunityType ||
      field.field_changed === FieldChangedValue.activeOnPipeline ||
      field.field_changed === FieldChangedValue.ebitda ||
      field.field_changed === FieldChangedValue.askingAmount ||
      field.field_changed === FieldChangedValue.banker ||
      field.field_changed === FieldChangedValue.bankerName ||
      field.field_changed === FieldChangedValue.dealType ||
      field.field_changed === FieldChangedValue.addonCompany ||
      field.field_changed === FieldChangedValue.linkedinUrl ||
      field.field_changed === FieldChangedValue.useParentCompany ||
      field.field_changed === FieldChangedValue.companyType ||
      field.field_changed === FieldChangedValue.yearFounded ||
      field.field_changed === FieldChangedValue.websiteActive
    ) {
      return true;
    }

    return false;
  }, [company, field.field_changed]);

  const EditTrigger = useMemo(() => {
    if (!company) return <></>;

    switch (field.field_changed) {
      case FieldChangedValue.revenue:
        if (!isEmptyRevenue(field))
          return (
            <EditFieldFinancial
              companyId={company.company_id}
              companyUrl={company.company_url}
              fieldKey="revenue_list"
              extraKeys={['last_rev_update_amount', 'last_rev_update_year']}
              fieldListValues={company.revenue_list}
              fieldName="Revenue"
              service={UserService.updateCompanyUserValues}
              isFromGrid={false}
              hidePenIcon
              isFromHistory
              options={revenueOptions}
            />
          );
        break;
      case FieldChangedValue.sector:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            record={company}
            isFromGrid={false}
            isFromHistory
            hidePenIcon
            service={CompanyService.updateCompanyData}
            successMessage="Company sector updated successfully!"
            errorMessage="Company sector update failed, Server error!"
            fieldName="Sector"
            fieldKey="sector"
            getPopupContainer={element => containerRef.current || element}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );

      case FieldChangedValue.subSector:
      case FieldChangedValue.subSectorAPI:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            record={company}
            isFromGrid={false}
            isFromHistory
            hidePenIcon
            service={CompanyService.updateCompanyData}
            successMessage="Company sub-sector updated successfully!"
            errorMessage="Company sub-sector update failed, Server error!"
            fieldName="Sub-Sector"
            fieldKey="sub_sector"
            getPopupContainer={element => containerRef.current || element}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );

      case FieldChangedValue.numberOfEmployees:
        return (
          <EditFieldText
            fieldName="Number of Employees"
            isFromGrid={false}
            record={company}
            isFromHistory
            hidePenIcon
            service={CompanyService.updateCompanyData}
            value={field.after_value as string}
            triggerLabel="Edit Field"
          />
        );

      case FieldChangedValue.capitalRaised:
        return (
          <EditFieldText
            fieldName="Capital Raised"
            isFromGrid={false}
            record={company}
            isFromHistory
            service={UserService.updateCompanyUserValues}
            value={field.after_value as string}
            triggerLabel="Edit Field"
            hidePenIcon
          />
        );

      case FieldChangedValue.lastRound:
        return (
          <EditFieldSingleSelectText
            company={company}
            hidePenIcon
            lastTemporaryFieldValue={lastTemporaryFieldValue as string}
            isFromAuditField
            formatValue={formatValueToMillions}
            isProfileInformation
            validation={value => validateField(value, 'Last Round', undefined)}
            service={UserService.updateCompanyUserValues}
            fieldName="Last Round"
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
          />
        );

      case FieldChangedValue.lastInvestors:
        return (
          <EditFieldSingleSelectText
            company={company}
            hidePenIcon
            lastTemporaryFieldValue={lastTemporaryFieldValue as string}
            isFromAuditField
            formatValue={formatValueToMillions}
            isProfileInformation
            validation={value => validateField(value, 'Last Round', undefined)}
            service={UserService.updateCompanyUserValues}
            fieldName="Last Round"
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
          />
        );

      case FieldChangedValue.lastRaisedAmount:
        return (
          <EditFieldSingleSelectText
            company={company}
            hidePenIcon
            lastTemporaryFieldValue={lastTemporaryFieldValue as string}
            isProfileInformation
            isFromAuditField
            formatValue={formatValueToMillions}
            service={UserService.updateCompanyUserValues}
            validation={value => validateField(value, 'Last Round', undefined)}
            fieldName="Last Round"
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
          />
        );

      case FieldChangedValue.parentCompany:
        return (
          <EditFieldSingleSelectText
            company={company}
            hidePenIcon
            lastTemporaryFieldValue={lastTemporaryFieldValue as string}
            isFromAuditField
            formatValue={formatValueToMillions}
            isProfileInformation
            service={UserService.updateCompanyUserValues}
            fieldName="Last Round"
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
          />
        );

      case FieldChangedValue.lastFundingDate:
        return (
          <EditFieldDate
            fieldName="Last Funding Date"
            value={field.after_value as string}
            fieldKey="raise_date"
            isFromGrid={false}
            service={UserService.updateCompanyUserValues}
            hidePenIcon
            getPopupContainer={element => containerRef.current || element}
            record={company}
            format={globalConfig.short_date.DATE_FORMAT}
            isFromHistory
            destroyOnHide
          />
        );

      case FieldChangedValue.pipelineRank:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            record={company}
            isFromGrid={false}
            isFromHistory
            hidePenIcon
            service={CompanyService.updateCompanyData}
            successMessage="Pipeline rank updated successfully!"
            errorMessage="Pipeline rank update failed, Server error!"
            fieldName="Pipeline Rank"
            fieldKey="rank"
            getPopupContainer={element => containerRef.current || element}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );

      case FieldChangedValue.nextSteps:
        return (
          <EditFieldSingleSelectOther
            value={field.after_value as string}
            record={company}
            fieldOtherKey={'next_steps_opinion'}
            fieldOtherValue={field.after_value_opinion as string}
            isFromGrid={false}
            isFromHistory
            hidePenIcon
            service={UserService.updateNextSteps}
            successMessage="Company next steps updated successfully!"
            errorMessage="Company next steps update failed, Server error!"
            fieldName="Next Steps"
            otherEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
            destroyOnHide
            autoFocus
            defaultMenuIsOpen
          />
        );

      case FieldChangedValue.stage:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            rationaleAfterValue={
              typeof field.after_value_rationale === 'string'
                ? field.after_value_rationale.toLowerCase()
                : field.after_value_rationale
            }
            record={company}
            isFromGrid={false}
            isFromHistory
            hidePenIcon
            service={CompanyService.updateCompanyData}
            successMessage="Company stage updated successfully!"
            errorMessage="Company stage update failed, Server error!"
            fieldName="Stage"
            fieldKey="stage"
            getPopupContainer={element => containerRef.current || element}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );

      case FieldChangedValue.companyOwner:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            fieldName="Company Owner"
            fieldKey="deal_team_leader"
            isFromGrid={false}
            hidePenIcon
            service={CompanyService.updateCompanyData}
            isFromHistory
            successMessage="Company owner updated successfully!"
            errorMessage="Company owner update failed, Server error!"
            record={company}
            getPopupContainer={element => containerRef.current || element}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );

      case FieldChangedValue.fund:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            fieldName="Fund"
            fieldKey="fund"
            isFromGrid={false}
            hidePenIcon
            service={CompanyService.updateCompanyData}
            isFromHistory
            successMessage="Company fund updated successfully!"
            errorMessage="Company fund update failed, Server error!"
            getPopupContainer={element =>
              containerRef.current || (element.parentElement as HTMLElement)
            }
            record={company}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );

      case FieldChangedValue.addon:
      case FieldChangedValue.addonCompany:
      case FieldChangedValue.addonBool:
        return (
          <EditFieldCheckBoxSelectAsync
            fieldName="Add-on"
            isFromGrid={false}
            record={company}
            hidePenIcon
            service={CompanyService.updateAddon}
            successMessage="Add-on updated successfully!"
            getPopupContainer={element => containerRef.current || element}
            errorMessage=""
            value={company.addon}
            isFromHistory
          />
        );

      case FieldChangedValue.datePresented:
        return (
          <EditFieldDate
            fieldName="Date Presented"
            value={(field.after_value as DatePresented[])?.map(date => date.date as string)}
            fieldKey="date_presented"
            isFromGrid={false}
            service={CompanyService.updateDatePresented}
            successMessage="Success updating company presentation!"
            hidePenIcon
            record={company}
            format={globalConfig.short_date.DATE_FORMAT}
            isFromHistory
            getPopupContainer={element => containerRef.current || element}
            destroyOnHide
          />
        );

      case FieldChangedValue.askingAmount:
        return (
          <EditFieldNumber
            companyId={company.company_id}
            fieldPropietaryInfoType="deal_preparation"
            initialValue={(field.after_value as number) / 1000000}
            fieldKey="Asking Amount"
            isFromAuditField
          />
        );

      case FieldChangedValue.banker:
        return (
          <EditFieldCheckboxText
            companyId={company.company_id}
            checkboxKey="banker"
            textInputKey="banker_name"
            checkValue={(opportunityDataDealPreparation.banker?.value as boolean) ?? null}
            textValue={opportunityDataDealPreparation.banker_name ?? undefined}
            isCheckbox
            getPopupContainer={element => containerRef.current || element}
            isFromHistory
          />
        );

      case FieldChangedValue.bankerName:
        return (
          <EditFieldCheckboxText
            companyId={company.company_id}
            checkboxKey="banker"
            textInputKey="banker_name"
            checkValue={(opportunityDataDealPreparation.banker?.value as boolean) ?? null}
            textValue={opportunityDataDealPreparation.banker_name ?? undefined}
            getPopupContainer={element => containerRef.current || element}
            isFromHistory
          />
        );

      case FieldChangedValue.dealType:
        return (
          <EditPreparationFieldSingleSelect
            companyId={company.company_id}
            fieldKey="Deal Type"
            options={dealValues.deal_type?.data}
            fieldPropietaryInfoType="deal_preparation"
            initialValue={field.after_value as string}
            isFromAuditField
          />
        );

      case FieldChangedValue.softwareCompany:
        return isAdminOrAnalyst ? (
          <EditFieldRadioRationale
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit field"
            fieldName="Software Company"
            isFromGrid={false}
            service={UserService.addCompanySoftwareEvaluation}
            successMessage="Company software company updated successfully!"
            errorMessage="Failed to update value!"
            hidePen
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
            isFromHistory
          />
        ) : (
          <EditFieldRadio
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit field"
            fieldName="Software Company"
            isFromGrid={false}
            service={UserService.addCompanySoftwareEvaluation}
            successMessage="Company software company updated successfully!"
            errorMessage="Failed to update value!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.aiMl:
        return (
          <EditFieldRadio
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit field"
            isFromGrid={false}
            fieldName="AI / ML"
            service={UserService.updateAiMl}
            successMessage="Company AI / ML updated successfully!"
            errorMessage="Failed to update value!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.psgFit:
        return isAdminOrAnalyst ? (
          <EditFieldRadioRationale
            value={field.after_value}
            record={company}
            labelValue="Edit field"
            fieldName="PSG Fit"
            isFromGrid={false}
            service={UserService.updateFitEvaluation}
            successMessage="Company PSG fit updated successfully!"
            errorMessage="Failed to update value!"
            hidePen
            getPopupContainer={element => containerRef.current || element}
            isFromHistory
            isDynamicPopoverHeight
            destroyOnHide
          />
        ) : (
          <EditFieldRadio
            value={field.after_value}
            record={company}
            labelValue="Edit field"
            fieldName="PSG Fit"
            isFromGrid={false}
            service={UserService.updateFitEvaluation}
            successMessage="Company PSG fit updated successfully!"
            errorMessage="Failed to update value!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.psgPlatformCompany:
        return (
          <EditFieldRadio
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit Field"
            fieldName="PSG Platform"
            isFromGrid={false}
            service={CompanyService.updatePortfolio}
            successMessage="PSG platform company successfully updated!"
            errorMessage="Error updating company portfolio!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.useParentCompany:
        return (
          <EditFieldRadio
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit Field"
            fieldName="Use Parent Company"
            isFromGrid={false}
            service={UserService.updateCompanyInfo}
            successMessage="Parent company influence successfully updated!"
            errorMessage="Error updating parent company influence!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.arr:
        return (
          <EditFieldFinancial
            companyId={company.company_id}
            companyUrl={company.company_url}
            fieldKey="annual_recurring_revenue"
            service={null}
            fieldName="ARR"
            fieldListValues={opportunityData?.financial?.annual_recurring_revenue || []}
            isFromGrid={false}
            hidePenIcon
            isFromHistory
            options={arrOptions}
          />
        );

      case FieldChangedValue.gm:
        return (
          <EditFieldFinancial
            companyId={company.company_id}
            companyUrl={company.company_url}
            fieldKey="gross_margin_list"
            service={CompanyService.addOpportunityPresentation}
            fieldName="GM %"
            fieldListValues={opportunityData?.financial?.gm || []}
            isFromGrid={false}
            hidePenIcon
            isFromHistory
            options={gmOptions}
          />
        );

      case FieldChangedValue.lastRevenue:
        return (
          <EditFieldFinancial
            companyId={company.company_id}
            fieldKey="revenue_list"
            companyUrl={company.company_url}
            extraKeys={['last_rev_update_amount', 'last_rev_update_year']}
            fieldListValues={company.revenue_list}
            fieldName="Revenue"
            service={UserService.updateCompanyUserValues}
            isFromGrid={false}
            hidePenIcon
            isFromHistory
            options={revenueOptions}
          />
        );

      case FieldChangedValue.ebitda:
        return (
          <EditFieldFinancial
            companyId={company.company_id}
            companyUrl={company.company_url}
            fieldKey="ebitda_list"
            extraKeys={['ebitda_amount', 'ebitda_year']}
            fieldListValues={company.ebitda_list}
            service={UserService.updateCompanyUserValues}
            fieldName="EBITDA"
            options={ebitdaOptions}
            hidePenIcon
            isFromHistory
          />
        );

      case FieldChangedValue.seniorAdvisor: {
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            fieldName="Senior Advisor Thesis"
            fieldKey="senior_advisor_thesis"
            isFromGrid={false}
            isFromGridDropDown
            dropdownLabel="Edit Field"
            hidePenIcon
            service={CompanyService.updateCompanyData}
            isFromHistory
            successMessage="Senior Advisor Thesis updated successfully!"
            errorMessage="Senior Advisor Thesis update failed, Server error!"
            record={company}
            getPopupContainer={element => containerRef.current || element}
          />
        );
      }

      case FieldChangedValue.leadSource:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            fieldName="Lead Source"
            fieldKey="lead_source"
            isFromGrid={false}
            dropdownLabel="Edit Field"
            hidePenIcon
            service={CompanyService.updateCompanyData}
            isFromHistory
            successMessage="Lead Source updated successfully!"
            errorMessage="Lead Source update failed, Server error!"
            record={company}
            getPopupContainer={element => containerRef.current || element}
          />
        );

      case FieldChangedValue.isInBusiness:
        return (
          <EditFieldRadio
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit Field"
            fieldName="In Business"
            isFromGrid={false}
            service={UserService.addCompanyInBusinessEvaluation}
            successMessage="Company in business state updated successfully!"
            errorMessage="Failed to update company in business state!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.productCategory:
        return (
          <EditFieldMultiSelectAsyncProductCategory
            record={company}
            value={
              company.product_category === null
                ? []
                : (company.product_category as ProductCategory[])
            }
            lastTemporaryFieldValue={lastTemporaryFieldValue}
            isFromGrid={false}
            successMessage="Product category updated successfully!"
            asyncSearchEndpoint="/search/company_product_category"
            service={CompanyService.updateCompanyData}
            hidePenIcon
            fieldName="Product Category"
            fieldKey="product_category"
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
          />
        );

      case FieldChangedValue.subVertical:
        return (
          <EditFieldMultiSelectAsyncCreatable
            record={company}
            value={company.sub_vertical === null ? [] : (company.sub_vertical as string[])}
            lastTemporaryFieldValue={lastTemporaryFieldValue}
            successMessage="Sub Vertical updated successfully!"
            errorMessage="Sub Vertical update failed, Server error!"
            asyncSearchEndpoint="/search/sub_vertical"
            service={UserService.subVertical}
            hidePenIcon
            fieldName="Sub Vertical"
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
          />
        );

      case FieldChangedValue.icStatusDate:
        return (
          <EditFieldDate
            fieldName="IC Status Date"
            value={field.after_value as string}
            fieldKey="ic_status_date"
            isFromGrid={false}
            service={UserService.updateCompanyInfo}
            successMessage="IC Status Date updated successfully!"
            errorMessage="IC Status Date update failed, Server error"
            hidePenIcon
            getPopupContainer={element => containerRef.current || element}
            record={company}
            format={globalConfig.short_date.DATE_FORMAT}
            isFromHistory
            destroyOnHide
          />
        );

      case FieldChangedValue.opsTeam:
        return (
          <EditFieldMultiSelect
            record={company}
            value={(field.after_value as string)?.split(', ')}
            fieldName="OPS Team"
            labelValue="Edit Field"
            isFromGrid={false}
            hidePenIcon
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
            isFromHistory
            successMessage="Ops team added successfully!"
            errorMessage="Ops team update failed, Server error!"
            service={UserService.updateOpsTeam}
          />
        );

      case FieldChangedValue.opportunityType:
        return (
          <EditFieldSingleSelect
            value={field.after_value as string}
            fieldName="Opportunity Type"
            fieldKey="opportunity_type"
            isFromGrid={false}
            hidePenIcon
            service={CompanyService.updateCompanyData}
            isFromHistory
            successMessage="Opportunity Type updated successfully!"
            errorMessage="Opportunity Type update failed, Server error!"
            record={company}
            getPopupContainer={element => containerRef.current || element}
          />
        );

      case FieldChangedValue.activeOnPipeline:
        return (
          <EditFieldRadio
            value={field.after_value as boolean}
            record={company}
            labelValue="Edit Field"
            fieldName="Active on Pipeline"
            isFromGrid={false}
            service={UserService.updateActive}
            successMessage="Company active state updated successfully!"
            errorMessage="Failed to update company in business state!"
            hidePen
            isFromHistory
          />
        );

      case FieldChangedValue.dealTeam:
        return (
          <EditFieldMultiSelect
            record={company}
            value={(field.after_value as string)?.split(', ')}
            fieldName="Deal Team"
            labelValue="Edit Field"
            isFromGrid={false}
            hidePenIcon
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
            isFromHistory
            successMessage="Deal team added successfully!"
            errorMessage="Deal team update failed, Server error!"
            service={UserService.updateDealTeam}
          />
        );

      case FieldChangedValue.seniorDealTeamLead:
        return (
          <EditFieldMultiSelect
            value={(field.after_value as string)?.split(', ')}
            fieldName="Senior Deal Team Lead"
            labelValue="Edit Field"
            isFromGrid={false}
            hidePenIcon
            getPopupContainer={element => containerRef.current || element}
            isDynamicPopoverHeight
            destroyOnHide
            service={UserService.updateSeniorDealTeamLead}
            isFromHistory
            successMessage="Senior Deal Team Lead updated successfully!"
            errorMessage="Senior Deal Team Lead  update failed, Server error!"
            record={company}
          />
        );

      case FieldChangedValue.expectedCloseDate:
        return (
          <EditFieldDate
            fieldName="Expected Close Date"
            value={field.after_value as string}
            fieldKey="expected_close_date"
            isFromGrid={false}
            service={UserService.updateCompanyInfo}
            successMessage="Expected Close Date updated successfully!"
            errorMessage="Expected Close Date update failed, Server error!"
            hidePenIcon
            getPopupContainer={element => containerRef.current || element}
            record={company}
            format={globalConfig.short_date.DATE_FORMAT}
            isFromHistory
            destroyOnHide
          />
        );

      case FieldChangedValue.linkedinUrl:
        return (
          <EditFieldText
            fieldName="Linkedin Profile"
            isFromGrid={false}
            record={company}
            isFromHistory
            service={CompanyService.updateLinkedinURL}
            value={field.after_value as string}
            triggerLabel="Edit Field"
            entityType="company"
            hidePenIcon
            isURL
          />
        );

      case FieldChangedValue.websiteActive:
        return (
          <EditFieldRadio
            value={field.after_value as string}
            record={company}
            fieldName="Website Status"
            service={UserService.updateWebsiteActive}
            successMessage="Company website status updated successfully!"
            errorMessage="Failed to update value!"
            isFromGrid={false}
            isFromHistory
            hidePen
            labelValue="Edit Field"
          />
        );
      case FieldChangedValue.companyType:
        return (
          <EditFieldSingleSelect
            isFromGrid={false}
            service={CompanyService.updateCompanyData}
            fieldName="Company Type"
            fieldKey="company_type"
            value={field.after_value as string}
            successMessage="Company Type updated successfully!"
            errorMessage="Company Type update failed, Server error!"
            isFromHistory
            hidePenIcon
            isFromGridDropDown
            record={company}
            getPopupContainer={element => containerRef.current || element}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );
      case FieldChangedValue.yearFounded:
        return (
          <EditFieldSingleSelect
            isFromGrid={false}
            fieldName="Year Founded"
            fieldKey="year_founded"
            service={CompanyService.updateCompanyData}
            value={field.after_value as string}
            successMessage="Company year founded updated successfully!"
            errorMessage="Company year founded update failed, Server error!"
            isFromHistory
            hidePenIcon
            isFromGridDropDown
            getPopupContainer={element => containerRef.current || element}
            record={company}
            openMenuOnFocus
            autoFocus
            destroyOnHide
          />
        );
      default:
        break;
    }

    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, field, containerRef.current]);

  const menu = () => (
    <Menu>
      <Menu.Item key="1">{EditTrigger}</Menu.Item>
    </Menu>
  );

  return (
    <Container ref={containerRef}>
      <Typography.Paragraph>
        <Styles.Label>{field.field_changed}: </Styles.Label>
        {field.field_changed === FieldChangedValue.generatedDescription ? (
          <Styles.Value isDescription>
            <Tooltip title={field.after_value} overlayStyle={{ minWidth: '550px' }}>
              {afterValue}
            </Tooltip>
          </Styles.Value>
        ) : (
          <Styles.Value>
            {afterValue !== 'Invalid date' ? afterValue : 'Date was removed'}
            {shouldDisplayRationale &&
              afterValue &&
              showRationaleForStage(afterValue as string) && (
                <Styles.Rationale isAfterRationale>
                  (rationale: {!field.after_value_rationale ? 'None' : field.after_value_rationale})
                </Styles.Rationale>
              )}
            {shouldDisplayOpinion &&
              afterValue &&
              showOpinionForNextSteps(afterValue as string) && (
                <Styles.Opinion>
                  {field.after_value_opinion && ': ' + field.after_value_opinion}
                </Styles.Opinion>
              )}
          </Styles.Value>
        )}{' '}
        {field.field_changed === FieldChangedValue.generatedDescription ? (
          field.opinion !== 'Positive' && (
            <Styles.FormerValue isDescription>
              <Tooltip title={field.before_value} overlayStyle={{ minWidth: '550px' }}>
                {fromValue}
              </Tooltip>
            </Styles.FormerValue>
          )
        ) : (
          <Styles.FormerValue>
            {fromValue !== 'Invalid date' ? fromValue : 'Date was removed'}
            {shouldDisplayRationale &&
              field.before_value &&
              showRationaleForStage(field.before_value as string) && (
                <>
                  {' '}
                  (rationale:{' '}
                  {!field.before_value_rationale ? 'None' : field.before_value_rationale}
                  ))
                </>
              )}
            {shouldDisplayOpinion &&
              field.before_value &&
              showOpinionForNextSteps(field.before_value as string) && (
                <>{!field.before_value_opinion ? '' : ': ' + field.before_value_opinion + ')'}</>
              )}
          </Styles.FormerValue>
        )}
        {field.opinion && (
          <div>
            <Styles.Label>OPINION: </Styles.Label> <Styles.Value>{field.opinion}</Styles.Value>
          </div>
        )}
        {field.regeneration_type && (
          <div>
            <Styles.Label>REGENERATION TYPE: </Styles.Label>
            <Styles.Value>{field.regeneration_type}</Styles.Value>
          </div>
        )}
        <Styles.User>{field.user}</Styles.User>
        {field.optx && (
          <>
            <Styles.Label>{optxScoreImpactLabel}</Styles.Label>
            <Styles.Value>{afterOPTX}</Styles.Value>{' '}
            <Styles.FormerValue>{fromOPTX}</Styles.FormerValue>
          </>
        )}
      </Typography.Paragraph>
      {showDropdown && (
        <Dropdown
          overlay={menu}
          overlayClassName="dropdown--save-list dropdown--audit-field"
          trigger={['click']}
          placement="bottomLeft"
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          <Button
            icon={<Icon iconName="three-dots" />}
            onClick={() => window.pendo.track(CompanyPageHistory.auditTrail_editField)}
          />
        </Dropdown>
      )}
    </Container>
  );
};
