export const regexCsvMimeType = /(text\/csv)|(application\/vnd\.ms-excel)/;
export const regexValidEmail =
  /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
// eslint-disable-next-line max-len
export const regexLinkedinURL =
  /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
/**
 * regex for validating an abbreviated value. valid examples: 100k, 123.456K, 1000000
 * -? - optional, user can enter the minus symbol at the start of the string
 * ^[0-9] - string must start with number characters
 * \.? - optional, user can enter decimal point
 * [0-9]+ - after decimal point at least one number must be entered
 * (K?|M?|B?|T?) - after numbers, string can contain only one of these letters
 * /i - string is case insensitive
 */
export const regexFloatAbbreviation = /^[0-9]*\.?[0-9]+(K?|M?|B?|T?)$/i;
export const regexFloatAndNegativeAbbreviation = /^-?[0-9]*\.?[0-9]+(K?|M?|B?|T?)$/i;

// similar to above regex, except it doesn't accept decimal values
export const regexIntegerAbbreviation = /^[0-9]+(K?|M?|B?|T?)$/i;

// for Phone Numbers in validation schema
// eslint-disable-next-line max-len
export const phoneRegExp = /^[0-9.+-]{4,20}$/g;

// for numbers in validation schema
export const numberRegExp = /^\d+([.,]\d+)?$/;

// check if a value is a number or if it is a number with percentage symbol
export const numberPercentageRegex = /^-?\d+(\.\d+)?%?$/;

// for numbers in validation schema also negative numbers
export const numberBigRegExp = /^[-0-9\s]+([.,]\d+)?$/;

// for numbers in validation schema also negative numbers that can also end in dot
export const numberBigRegExpEndingDot = /^[-0-9\s]+([.,]\d+)?\.?$/;

// check if it is a number with percentage symbol
export const numberPercentageMatchRegex = /^-?\d+(\.\d+)?%$/;

/**
 * Regular expression for matching abbreviations of numbers with optional negative sign.
 * Matches numbers followed by K (thousand), M (million), B (billion), or T (trillion) in a case-insensitive manner.
 */
export const regexAbbreviation = /^[0-9]*\.?[0-9]+(K|M|B|T)$/i;
export const regexNegativeAbbreviation = /^-?[0-9]*\.?[0-9]+(K|M|B|T)$/i;
// for urls with or without http/https
export const urlRegExp = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
