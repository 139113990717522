import { createSelector } from 'reselect';
// models
import { AppState } from '@optx/redux/interfaces';
import { BaseField } from '@optx/models/companyFields';
// redux
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';

const getTouchState = (state: AppState) => state.ui.modals.companyTouches;

export const shouldInitialFetch = createSelector(getTouchState, state => state.shouldInitialFetch);

export const isOpen = createSelector(getTouchState, state => state.open);

export const getPsgParticipants = createSelector(getTouchState, state => {
  return state.psgParticipants;
});

export const getTouchCompany = createSelector(getTouchState, state => state.company);

export const getTouchFilters = createSelector(
  getTouchState,
  getIsAdminOrAnalyst,
  (state, getIsAdminOrAnalyst) => {
    const filters = state.filters;

    if (filters !== null) {
      const touchesTypesData = filters!.touches_types.data;

      const mappedData = touchesTypesData.filter((item: any) => {
        if (!getIsAdminOrAnalyst && item.name === 'Document Upload') {
          return false;
        }

        return item;
      });

      return { ...filters, touches_types: { ...filters!.touches_types, data: mappedData } };
    }

    return {};
  }
);

export const getTouchFinancials = createSelector(getTouchState, state => state.financials);

export const editTouch = createSelector(getTouchState, state => state.editTouch);

export const getModalTitle = createSelector(getTouchState, state => state.modalTitle);

export const getDisableSaveBtnFor = createSelector(getTouchState, state => state.disableSaveBtnFor);

export const getDisableSaveBtnByAdditionalFields = createSelector(
  getTouchState,
  state => state.disableSaveBtnByAdditionalFields
);

export const getAdditionalFieldsError = createSelector(
  getTouchState,
  state => state.additionalFieldsError
);

export const getTouchCompanyRank = createSelector(
  getTouchState,
  state => state.company.pipeline_rank
);

export const getTouchCompanyStage = createSelector(getTouchState, state => state.company.stage);
export const getTouchCompanyStageRationale = createSelector(
  getTouchState,
  state => state.company.stage_rationale
);

export const getTouchCompanyOwner = createSelector(
  getTouchState,
  state => state.company.company_owner
);

export const getTouchCompanyContacts = createSelector(
  getTouchState,
  state => state.company.contacts
);

export const hasCompanyCard = (state: AppState) => {
  // @ts-ignore
  if (state['company-card']) {
    return true;
  } else {
    return false;
  }
};

export const hasFinancialsLoaded = createSelector(
  getTouchState,
  state => state.hasFinancialsLoaded
);
export const getInitialFinancialState = createSelector(
  getTouchState,
  state => state.initialFinancialState
);

export const getNoErrorsOnSubmitState = createSelector(getTouchState, state => {
  return (
    state.validationErrors.noTouchErrors &&
    state.validationErrors.noEditFieldErrors &&
    state.validationErrors.noFinancialErrors
  );
});

export const getHasAllFormDataLoaded = createSelector(getTouchState, state => {
  if (!state.filters || !state.psgParticipants || !state.psgParticipants.length || !state.company) {
    return false;
  }

  const allFinancialsFields = state.financials.reduce((acc, curr) => {
    if (curr.data) {
      return [...acc, ...curr.data];
    }

    return acc;
  }, [] as BaseField<any>[]);

  const isSomeDataMissing = allFinancialsFields.some(field => {
    if (
      field.id !== 'sub_sector' && // sub-sector options comes from redux state
      field.id !== 'primary_contact' &&
      (field.type === 'multi-input' ||
        field.type === 'multi-select' ||
        field.type === 'single-select') &&
      (!field.data || !field.data.length)
    ) {
      return true;
    }

    return false;
  });

  return !isSomeDataMissing;
});
