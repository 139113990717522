import React from 'react';
// constats
import { DEFAULT_LAST_TOUCH, DEFAULT_NEXT_TOUCH } from '@optx/constants/table/cells';
import globalConfig from '@optx/constants/config';
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
// components
import Company, { CompanyProfile } from '@optx/models/Company';
import {
  EditFieldSingleSelect,
  EditFieldSingleSelectOther,
  EditFieldRadio,
} from '@optx/features/company/edit-fields/components';
import { UserService, CompanyService } from '@optx/services/api';
import {
  TableCellOPTXScore,
  TableCellActions,
  TableCellScoreAdmin,
  TableCellFitRationale,
} from '@optx/components/common/table/Companies/cells';
import { TableCellDate, TableCellListDate } from '@optx/components/common/table/AntTable/cells';
import {
  TableCellCompanyURL,
  TableCellName,
} from '@optx/components/common/table/Companies/styled-cells';
import { CompanyColumnType } from '@optx/components/common/table/Companies/columns/virtual-column-config';

export const columns: Array<CompanyColumnType> = [
  {
    dataIndex: 'company_url',
    className: 'company_url',
    title: '',
    width: 60,
    fixed: 'left',
    align: 'center',
    render: (value: string | null, record: Company, index: number) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    className: 'company_name',
    sorter: true,
    render: (value: string, record: CompanyProfile, index: number) => (
      <TableCellName value={value} record={record} />
    ),
    width: 240,
    fixed: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'is_interesting',
    title: 'PSG Fit',
    className: 'is_interesting',
    sorter: true,
    render: (value: boolean | null, record: Company) => (
      <TableCellFitRationale value={value} record={record} />
    ),
    width: 110,
    align: 'center',
    ellipsis: true,
  },
  {
    dataIndex: 'score',
    title: 'OPTX Score',
    className: 'score',
    sorter: true,
    width: 120,
    render: (value: number, record: CompanyProfile) => (
      <TableCellScoreAdmin record={record}>
        <TableCellOPTXScore company={record} />
      </TableCellScoreAdmin>
    ),
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'score_v3',
    title: 'OPTX Score BETA',
    className: 'score_v3',
    sorter: true,
    width: 120,
    render: (value: number, record: CompanyProfile) => (
      <TableCellScoreAdmin record={record} isNewScore>
        <TableCellOPTXScore company={record} isNewScore />
      </TableCellScoreAdmin>
    ),
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'il_optx_score',
    title: 'IL OPTX Score',
    className: 'il_optx_score',
    sorter: true,
    width: 120,
    render: (value: number, record: CompanyProfile) => (
      <TableCellScoreAdmin record={record}>
        <TableCellOPTXScore company={record} />
      </TableCellScoreAdmin>
    ),
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'stage',
    title: 'Stage',
    className: 'stage',
    sorter: true,
    render: (value: string, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          service={CompanyService.updateCompanyData}
          fieldName="Stage"
          fieldKey="stage"
          value={value}
          successMessage="Company stage updated successfully"
          errorMessage="Company stage update failed, Server error!"
          record={record}
          getPopupContainer={triggerNode => triggerNode.parentElement!}
        />
      ) : (
        value
      ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'next_steps',
    title: 'Next Steps',
    className: 'next_steps',
    sorter: true,
    render: (value: string, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelectOther
          isFromGrid
          service={UserService.updateNextSteps}
          fieldName="Next Steps"
          value={value}
          successMessage="Company next steps updated successfully!"
          errorMessage="Company next steps update failed, Server error!"
          record={record}
          fieldOtherKey={'next_steps_opinion'}
          fieldOtherValue={record.next_steps_opinion}
          otherEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
        />
      ) : (
        value
      ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'last_touch_date',
    title: 'Last Touch',
    className: 'last_touch_date',
    sorter: true,
    render: (value: string, record: Company) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={
          value !== DEFAULT_LAST_TOUCH && value !== DEFAULT_NEXT_TOUCH ? undefined : value
        }
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'next_touch_date',
    title: 'Scheduled Touch',
    className: 'next_touch_date',
    sorter: true,
    render: (value: string | string[], record: Company, index: number) => (
      <TableCellListDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={typeof value === 'string' ? value : undefined}
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'active',
    title: 'Active on Pipeline',
    className: 'active',
    sorter: true,
    render: (value, record, index) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="Active on Pipeline"
        service={UserService.updateActive}
        successMessage="Company active state updated successfully!"
        errorMessage="Failed to update company active state!"
        isFromGrid
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    className: 'row_actions',
    render: (value: unknown, record: CompanyProfile, index: number) => (
      <TableCellActions value={value} record={record} index={index} />
    ),
    width: 65,
    fixed: 'right',
  },
];
