import React, { useState, useEffect } from 'react';
import { Input, Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import unescape from 'lodash/unescape';
// constants
import config from '@optx/constants/config';
// services
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

interface LocationSearchInputProps {
  address?: string;
  placeholder: string;
  handleChange: (location: string, locationId: string) => void;
  allowClear?: boolean;
  autoFocus?: boolean;
}

interface PredictionItem {
  place_id: string;
  description: string;
  index?: number;
  hasStreet?: boolean;
  chinaAddress?: boolean;
}

const LocationSearchInput: React.FC<LocationSearchInputProps> = ({
  address,
  handleChange,
  placeholder,
  allowClear,
  autoFocus = true,
}) => {
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: config.GOOGLE_LOCATION_API_KEY || '<<LOCATION_API_KEY>>',
    options: { input: '', types: ['locality', 'route'] },
    debounce: 500,
  });
  const [menuVisible, setMenuVisible] = useState<boolean>(true);
  const [value, setValue] = useState<string>(address || '');
  const [latestPredictions, updateLatestPredictions] = useState<PredictionItem[]>([]);

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    if (address || allowClear) {
      handleChange('', '');
    }

    if (event.target.value?.length > 2) {
      setMenuVisible(true);
      getPlacePredictions({ input: event.target.value });
    }
  };

  const onSelect: MenuProps['onClick'] = item => {
    const { key } = item;
    setMenuVisible(false);
    const selectedItem = latestPredictions.filter((item: PredictionItem) => item.place_id === key);
    setValue(selectedItem[0].description);
    handleChange(selectedItem[0].description, key);
  };

  useEffect(() => {
    if (placePredictions.length !== 0) {
      let updatedPredictions: PredictionItem[] = [];

      const updateStateLocally = (predLenght: number, item: PredictionItem) => {
        updatedPredictions.push(item);

        if (updatedPredictions.length === predLenght) {
          updateLatestPredictions(
            updatedPredictions.sort((a: PredictionItem, b: PredictionItem) => {
              if (a.index && b.index && a.index < b?.index) {
                return -1;
              }

              return 1;
            })
          );
        }
      };

      placePredictions.map((item: PredictionItem, index: number) => {
        placesService?.getDetails(
          {
            placeId: item.place_id,
          },
          placeDetails => {
            if (placeDetails && placeDetails.address_components) {
              const updatedAddress = placeDetails.adr_address
                ?.replace(/<span[^>]*class="postal-code"[^>]*>(.*?)<\/span>/g, '')
                .replace(/ ,/g, '')
                .replace(/<[^>]*>/g, '');

              const newPrediction = {
                description: unescape(updatedAddress || ''),
                place_id: item.place_id,
                index: index,
              };
              updateStateLocally(placePredictions.length, newPrediction);
            }
          }
        );
      });
    }
  }, [placePredictions, placesService]);

  useEffect(() => {
    if (allowClear) {
      setValue(address || '');
    }
  }, [address, allowClear]);

  return (
    <div className="location-input-wrapper">
      {config.GOOGLE_LOCATION_API_KEY === '<<LOCATION_API_KEY>>' && (
        <span className="error-message">Google API key required!</span>
      )}
      <Input
        placeholder={placeholder}
        onChange={handleChangeText}
        value={value}
        allowClear={allowClear}
        autoFocus={autoFocus}
      />
      {value && menuVisible && placePredictions.length > 0 && (
        <Menu onClick={onSelect} className="location-search-menu">
          {latestPredictions &&
            latestPredictions.map((item: PredictionItem) => (
              <Menu.Item key={item.place_id} attr-value={item.place_id}>
                {item.description}
              </Menu.Item>
            ))}
        </Menu>
      )}
    </div>
  );
};

export default React.memo(LocationSearchInput);
