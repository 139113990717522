import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Button, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router';
// models
import { FormValues } from '../models';
import { Option } from '@models/Option';
// constants
import {
  FORM_INITIAL_VALUES,
  FORM_TOTAL_STEPS,
  MODAL_WRAPPER_TITLE,
  VIEW_AND_CLOSE,
} from '../constants';
import { regexAbbreviation } from '@constants/regex';
// utils
import validationSchema from '../utils/validation';
import { getCompanyProfileURL, resetHistoryState } from '@utils/routes';
import { getDataFromPlugin } from '../utils/';
import { parseNumberFromAbbreviation } from '@optx/utils/number';
// redux
import { selectors, actions } from '@features/company/add-company';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as companySelectors } from '@components/feature/company-individual-edit/state';
// components
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import StepCompleted from './StepCompleted';
import FixedLoader from '@components/common/loader/FixedLoader';
import { Styled } from './AddCompanyModal.styled';

interface AddCompanyModalWrapperProps {
  visible: boolean;
  setIsVisible: (visibility: boolean) => void;
  showDropdown: (visibility: boolean) => void;
}

const dataAndManagementFiltersIndex = 3;

/**
 * This is the main wrapper for the add company modal, here we handle the steps
 */
const ModalWrapper: React.FC<AddCompanyModalWrapperProps> = ({
  visible,
  setIsVisible,
  showDropdown,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const similarCompanies = useSelector(selectors.getSimilarCompanies);
  const addedCompany = useSelector(selectors.getAddedCompany);
  const isLoading = useSelector(selectors.isLoading);
  const urlData = useSelector(selectors.getUrlData);
  const userFullName = useSelector(userInformationSelectors.getFullName);
  const userId = useSelector(userInformationSelectors.getUserId);
  const userEtId = useSelector(userInformationSelectors.getUserEtId);
  const userRegion = useSelector(userInformationSelectors.getUserRegion);
  const filters = useSelector(companyFiltersSelectors.getCompanyFilters);
  const { fund } = useSelector(companySelectors.companyIndividualEdit.multipleOptions(['fund']));

  const formRef = useRef<FormikProps<FormValues> | null>(null);

  const { addCompany, companyUrl } = getDataFromPlugin();

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [moveForward, setMoveForward] = useState<boolean>(true);
  const [urlValidated, setUrlValidated] = useState<boolean>(false);
  const [shouldChangeStep, setShouldChangeStep] = useState<boolean>(false);

  const defaultFund: string = userRegion === 'EU' ? 'PSG Europe II' : 'PSG VI';
  const companyOwners = useMemo(
    () =>
      filters[dataAndManagementFiltersIndex]?.data.find(
        filter => filter.column === 'company_owner_id'
      )?.data ?? [],
    [filters]
  );

  const isUserCompanyOwner = companyOwners.some(
    (owner: Option<string>) => owner.name === userFullName
  );

  const companyOwnerInitialValues = {
    label: isUserCompanyOwner ? userFullName : '',
    value: isUserCompanyOwner ? String(userEtId ?? userId ?? 0) : '',
  };

  const formInitialValues: FormValues = {
    ...FORM_INITIAL_VALUES,
    companyOwner: companyOwnerInitialValues,
    fund: {
      label: defaultFund,
      value: defaultFund,
    },
  };

  const companyUrlFromPlugin = useRef(companyUrl);
  const addCompanyFromPlugin = useRef(addCompany);
  const initialVisibilityToggled = useRef(false);

  useEffect(() => {
    if (filters.length > 0 && !initialVisibilityToggled.current && addCompany) {
      initialVisibilityToggled.current = true;

      setIsVisible(true);
      showDropdown(false);
      resetHistoryState(history, null, null);
      setStep(3);
    }
  }, [addCompany, setIsVisible, showDropdown, history, filters]);

  useEffect(() => {
    if (step === 1 && urlValidated && urlData?.isValid && shouldChangeStep) {
      setStep(2);
      setMoveForward(true);
    }
  }, [urlData, urlValidated, step, shouldChangeStep]);

  const changeStep = (isNext: boolean) => {
    setShouldChangeStep(false);

    if ((step === 1 && urlValidated) || step >= 2) {
      setStep(isNext ? step + 1 : step - 1);
    }
  };

  const handleSecondaryButton = (shouldChangeStep: boolean) => {
    if (shouldChangeStep && !isFormCompleted) {
      changeStep(false);
      setMoveForward(false);
    } else {
      closeModal();
    }
  };

  const goToCompanyProfile = (companyId: number) => {
    history.push(getCompanyProfileURL(companyId));
  };

  const handlePrimaryButton = () => {
    if (urlData?.foundCompanyId && urlValidated) {
      goToCompanyProfile(urlData.foundCompanyId);
    } else {
      if (isFormCompleted && addedCompany) {
        goToCompanyProfile(addedCompany.company_id);
      } else {
        if (step === FORM_TOTAL_STEPS) {
          formRef.current?.submitForm();
          setIsFormCompleted(true);
        } else {
          changeStep(true);
          setMoveForward(true);
        }
      }
    }
  };

  // Triggers on final submit, when trying to add the company
  const handleSubmit = (values: FormValues) => {
    let fundValue;
    let etCheckValue = values.etCheck;
    let ftesValue = values.ftes;

    if (typeof values.fund!.value === 'string') {
      fundValue = fund!.find(item => item.label === values.fund!.value)!.value;
    } else {
      fundValue = values.fund!.value;
    }

    if (typeof values.etCheck === 'string' && regexAbbreviation.test(values.etCheck)) {
      etCheckValue = parseNumberFromAbbreviation(values.etCheck);
    }

    if (typeof values.ftes === 'string' && regexAbbreviation.test(values.ftes)) {
      ftesValue = parseNumberFromAbbreviation(values.ftes);
    }

    const companyData = {
      company_name: values.companyName,
      alt_names: values.alternativeName,
      company_url: values.primaryUrl,
      secondary_urls: values.secondaryUrl?.length ? [values.secondaryUrl] : [],
      primary_address: values.primaryAddress,
      primary_address_cont: values.primaryAddressSecondLine,
      secondary_address: values.secondaryAddress,
      secondary_address_cont: values.secondaryAddressSecondLine,
      country: values.country!.value,
      state: values.state!.value,
      city: values.city!.value,
      zip_code: values.zipCode,
      secondary_country: values.secondaryCountry?.value,
      secondary_state: values.secondaryState?.value,
      secondary_city: values.secondaryCity?.value,
      secondary_zip_code: values.secondaryZipCode,
      ftes: ftesValue,
      company_owner_id: values.companyOwner!.value,
      product_category: values.productCategory?.length
        ? values.productCategory.map(category => category.value)
        : null,
      rationale: values.rationale,
      equity_check: etCheckValue,
      addon: values.isAddon?.value === 1,
      addon_for: values.addonFor?.length ? values.addonFor.map(company => company.value) : [],
      description: values.description,
      opportunity_type: values.opportunityType!.value,
      stage: values.stage!.value,
      stage_rationale: values.stageRationale || '',
      rank: values.rank!.value === '' ? null : values.rank!.value,
      source: values.source!.value === '' ? null : values.source!.value,
      sector: values.sector!.value === 'blank' ? null : values.sector!.value,
      sub_sector:
        values.subSector!.value === '' || values.subSector!.value === 'blank'
          ? null
          : values.subSector!.value,
      fund: fundValue,
    };

    dispatch(actions.addCompany(companyData));
  };

  const closeModal = () => {
    setIsVisible(false);
    setStep(1);
    setIsFormCompleted(false);
    companyUrlFromPlugin.current = '';
    addCompanyFromPlugin.current = false;

    // Reset URL Data so it doesn't keep the previous one when opening the modal again
    dispatch(actions.resetUrlData());
  };

  return (
    <Styled.ModalWrapper
      title={MODAL_WRAPPER_TITLE}
      visible={visible}
      onCancel={closeModal}
      width={840}
      destroyOnClose
      maskClosable={false}
      footer={[
        <div className="left-container">
          {step !== 1 && !isFormCompleted && (
            <Button key="back" onClick={() => handleSecondaryButton(false)}>
              Cancel
            </Button>
          )}
          <Typography.Text className="additional-info">
            {isFormCompleted ? 'Complete' : `Step ${step}/${FORM_TOTAL_STEPS}`}
          </Typography.Text>
        </div>,
        <div className="right-container">
          {step === 2 && similarCompanies !== null && similarCompanies.length > 0 && (
            <Typography.Text className="additional-info proceed-text">
              Proceed anyway?
            </Typography.Text>
          )}
          <Button key="back" onClick={() => handleSecondaryButton(step !== 1)}>
            {isFormCompleted ? 'Close' : step === 1 ? 'Cancel' : 'Previous'}
          </Button>
          <Button
            key="submit"
            type="primary"
            className={step === 1 && !urlData?.foundCompanyId ? 'add-company-primary' : undefined}
            onClick={handlePrimaryButton}
            disabled={isNextButtonDisabled || (isFormCompleted && !addedCompany) || isLoading}
          >
            {urlData?.foundCompanyId && !urlData.isValid && urlValidated
              ? VIEW_AND_CLOSE
              : isFormCompleted
              ? 'Finish and View Company'
              : 'Next'}
          </Button>
        </div>,
      ]}
    >
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
      >
        {formikProps => {
          if (
            !formikProps.values.primaryUrl &&
            addCompanyFromPlugin.current &&
            companyUrlFromPlugin.current
          )
            formikProps.setFieldValue('primaryUrl', companyUrlFromPlugin.current);

          return (
            <Form className="ant-form ant-form-vertical">
              {!isFormCompleted && (
                <>
                  {step === 1 && (
                    <Step1
                      formikProps={formikProps}
                      handleDisableNextButton={setIsNextButtonDisabled}
                      shouldGoToNextStep={() => setShouldChangeStep(true)}
                      canGoToNextStep={setUrlValidated}
                    />
                  )}
                  {step === 2 && (
                    <Step2
                      similarCompanies={similarCompanies}
                      handleDisableNextButton={setIsNextButtonDisabled}
                      changeStep={() => changeStep(moveForward)}
                      shouldLoadCompanies={!isLoading}
                    />
                  )}
                  {step === 3 && (
                    <Step3
                      formikProps={formikProps}
                      handleDisableNextButton={setIsNextButtonDisabled}
                    />
                  )}
                  {step === 4 && (
                    <Step4
                      formikProps={formikProps}
                      handleDisableNextButton={setIsNextButtonDisabled}
                    />
                  )}
                </>
              )}

              {(isLoading || (isFormCompleted && !addedCompany)) && (
                <FixedLoader loading={isLoading} isAbsolute />
              )}

              {!isLoading && isFormCompleted && addedCompany && (
                <StepCompleted addedCompany={addedCompany} />
              )}
            </Form>
          );
        }}
      </Formik>
    </Styled.ModalWrapper>
  );
};

export default React.memo(ModalWrapper);
