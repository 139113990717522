import React from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import { KeyboardEventHandler, OptionsType, ValueType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// components
import { DropdownIndicator, ClearIndicator } from './components';
// styles
import './style.scss';

interface AsyncCreatableSelectProps {
  value: SelectOption<string>;
  options: OptionsType<SelectOption<string>>;
  controlShouldRenderValue: boolean;
  closeMenuOnSelect: boolean;
  disableValueRemove: boolean;
  autoFocus?: boolean;
  loadOptions: (
    inputValue: string,
    callback: SuccessErrorCallback
  ) => Promise<SelectOption[]> | void;
  onChange: (value: ValueType<SelectOption<string>>) => void;
  onMenuClose: () => void;
  onKeyDown?: KeyboardEventHandler | undefined;
  tabIndex: number;
}

const AsyncCreatableSelect: React.FC<AsyncCreatableSelectProps> = ({
  value,
  options,
  controlShouldRenderValue,
  closeMenuOnSelect,
  autoFocus,
  loadOptions,
  onChange,
  onMenuClose,
  onKeyDown,
  tabIndex,
}) => {
  const showClearIcon = value && value.value !== '';

  return (
    <AsyncCreatable
      value={value}
      options={options}
      controlShouldRenderValue={controlShouldRenderValue}
      closeMenuOnSelect={closeMenuOnSelect}
      autoFocus={autoFocus}
      loadOptions={loadOptions}
      onChange={onChange}
      isClearable={showClearIcon}
      onMenuClose={onMenuClose}
      onKeyDown={onKeyDown}
      onSelectResetsInput={false}
      blurInputOnSelect={false}
      bodyDisplay={false}
      isMulti={false}
      placeholder="search"
      className="async-create-select"
      classNamePrefix="async-create-select"
      tabIndex={tabIndex + ''}
      components={{ DropdownIndicator, ClearIndicator }}
    />
  );
};

export default AsyncCreatableSelect;
