import React from 'react';
import Icon from '@optx/components/common/Icon';
import AntIcon from '@ant-design/icons';

import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import Styles from './ThumbActions.styled';

interface ThumbActionsProps {
  onUpdateThumbsChecked: (value: boolean | null, optionType: string) => void;
  collapsableOptions: 'PSG Fit' | 'Software' | 'Score' | 'New Score';
  isChecked?: boolean | null | string;
  activeKey: string[];
}

const ThumbActions: React.FC<ThumbActionsProps> = ({
  onUpdateThumbsChecked,
  isChecked,
  collapsableOptions,
  activeKey,
}) => {
  const handleClickThumbDown = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();

    const isInActiveKey = activeKey.includes(collapsableOptions);

    if (isChecked === false && isInActiveKey) {
      onUpdateThumbsChecked(null, collapsableOptions);
    } else if (isChecked === true || !isChecked || isChecked === DEFAULT_EMPTY_VALUE) {
      onUpdateThumbsChecked(false, collapsableOptions);
    }
  };

  const handleClickThumbUp = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();

    if (isChecked === true) {
      onUpdateThumbsChecked(null, collapsableOptions);
    } else if (!isChecked || isChecked === DEFAULT_EMPTY_VALUE) {
      onUpdateThumbsChecked(true, collapsableOptions);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Styles.ThumbUp onClick={e => handleClickThumbUp(e)} checked={isChecked}>
        <AntIcon component={() => <Icon iconName="thumbs-up" style={{ fontSize: 27 }} />} />
      </Styles.ThumbUp>

      <Styles.ThumbDown onClick={e => handleClickThumbDown(e)} checked={isChecked}>
        <AntIcon component={() => <Icon iconName="thumbs-down" style={{ fontSize: 27 }} />} />
      </Styles.ThumbDown>
    </div>
  );
};

export default ThumbActions;
