import React, { useRef, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Row, Col } from 'antd';
import { Form, Input, Select, Field, Checkbox, DatePicker } from 'formik-antd';
import moment, { Moment } from 'moment';
import { Formik, FormikProps, FieldArray, FormikErrors, FieldProps } from 'formik';
import { CloseCircleTwoTone } from '@ant-design/icons';
// models
import { ContactEmails, ContactPhone, CompanyUserContact } from '@models/api/contacts';
import { SelectOption } from '@models/Option';
import { EquityTouchContactForm } from '@optx/models/equityTouch';
// constants
import { psgFunctionOptions, slackChannelOptions } from '@constants/contacts';
import { ISO_DATE_FORMAT } from '@constants/format/date';
import globalConfig from '@optx/constants/config';
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// utils
import { convertLinkedinURL } from '@utils/helpers';
import { transformStringForActionPendo } from '@utils/utils';
// redux
import { selectors } from '@components/feature/company-individual-edit/state';
// components
import EmailValidationMessage from '@components/common/email';
import { AddContactHeader } from '@optx/shared/view/molecules/contacts/AddContactHeader';
import MultiSelect from '@optx/components/common/form/formik/FormikMultiSelect';
// style components
import Styles from '@components/pages/EquityTouch/components/EquityTouch.style';
// local
import validationSchema from './validation';

const { Option } = Select;

interface ContactFormProps {
  type: 'add' | 'edit';
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: Partial<EquityTouchContactForm>) => void;
  initialValues: EquityTouchContactForm;
  maxEmails?: number;
  isExtension?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({
  type,
  visible,
  onCancel,
  onSubmit,
  initialValues,
  maxEmails = 4,
  isExtension,
}) => {
  const [hideAddEmailBtn, setHideAddEmailBtn] = useState(false);
  const [emailFieldsVisible, setEmailFieldsVisible] = useState(1);
  const [primaryCheckbox, setPrimaryCheckbox] = useState(false);

  const formRef = useRef<FormikProps<Partial<CompanyUserContact>> | null>(null);

  const selectOptions = [...useSelector(selectors.companyIndividualEdit.phoneOptions)];

  useEffect(() => {
    setEmailFieldsVisible(initialValues.emails.length);
  }, [initialValues.emails.length]);

  useEffect(() => {
    if (type === 'add' && visible) {
      formRef.current?.resetForm();
    }

    if (initialValues.primary_contact !== undefined) {
      setPrimaryCheckbox(initialValues.primary_contact);
    } else {
      setPrimaryCheckbox(false);
    }
  }, [initialValues, type, visible]);

  useEffect(() => {
    if (maxEmails && emailFieldsVisible >= maxEmails) {
      setHideAddEmailBtn(true);
    } else {
      setHideAddEmailBtn(false);
    }
  }, [emailFieldsVisible, maxEmails]);

  useEffect(() => {
    // closes browser autocomplete for modal input fields on scroll
    setTimeout(() => {
      const modalWrapper = document.getElementsByClassName('ant-modal-wrap')[0];
      const inputs = document.getElementsByClassName('autocomplete-input');

      if (modalWrapper && inputs.length) {
        modalWrapper.addEventListener('scroll', () => {
          for (let i = 0; i < inputs.length; i++) {
            if (inputs[i] === document.activeElement) {
              // @ts-ignore
              inputs[i].blur();
              // @ts-ignore
              inputs[i].focus();
            }
          }
        });
      }
    }, 1000);

    return () => {
      resetPrimaryCheckbox();
    };
  }, [visible]);

  const resetPrimaryCheckbox = () => setPrimaryCheckbox(false);

  const handleCancel = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    setHideAddEmailBtn(false);
    setEmailFieldsVisible(1);
    onCancel();
  };

  const handleSubmit = (values: Partial<CompanyUserContact>) => {
    const psgValues = (values.psg_function as SelectOption<string>[])?.map(item => item.value);
    const slackChannelValues = (values.slack_channel as SelectOption<string>[])?.map(
      item => item.value
    );
    const linkedinValue = convertLinkedinURL(values.linkedin);

    if (psgValues !== undefined && isExtension) {
      window.pendo.track(OPTXChromePlugin.companyPage.psgFunctions.psgFunction);

      if (psgValues.length === psgFunctionOptions.length) {
        window.pendo.track(OPTXChromePlugin.companyPage.psgFunctions.selectAllNone);
      } else {
        psgValues.forEach(item => {
          const transformedValue = transformStringForActionPendo(item);

          setTimeout(() => {
            window.pendo.track(
              OPTXChromePlugin.companyPage.psgFunctions[
                transformedValue as keyof typeof OPTXChromePlugin.companyPage.psgFunctions
              ]
            );
          }, 1000);
        });
      }
    }

    if (slackChannelValues !== undefined && isExtension) {
      window.pendo.track(OPTXChromePlugin.companyPage.slackChannels.slackChannel);

      if (slackChannelValues.length === slackChannelOptions.length) {
        window.pendo.track(OPTXChromePlugin.companyPage.slackChannels.selectAllNone);
      } else {
        slackChannelValues.forEach(item => {
          const transformedValue = transformStringForActionPendo(item);

          setTimeout(() => {
            window.pendo.track(
              OPTXChromePlugin.companyPage.slackChannels[
                transformedValue as keyof typeof OPTXChromePlugin.companyPage.slackChannels
              ]
            );
          }, 1000);
        });
      }
    }

    onSubmit({
      ...values,
      psg_function: psgValues,
      slack_channel: slackChannelValues,
      linkedin: linkedinValue,
    });
  };

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }

    if (formRef.current?.isValid) {
      setHideAddEmailBtn(false);
      setEmailFieldsVisible(1);
    }
  };

  const handlePrimaryContactChange = (value: boolean) => {
    window.pendo.track(OPTXChromePlugin.companyPage.primaryContactButton);

    formRef.current?.setFieldValue('primary_contact', value);
    setPrimaryCheckbox(prev => !prev);
  };

  const handleInitialValues = () => {
    if (formRef.current?.values) {
      if (isEqual(formRef.current.values, initialValues)) {
        return formRef.current.values;
      }

      return initialValues;
    }

    return initialValues;
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      formRef.current?.submitForm();
    }
  };

  const handleTenureDateChange = (
    field: 'tenure_start_date' | 'tenure_end_date',
    value: Moment | null
  ) => {
    if (formRef.current) {
      if (value) {
        const normalizedValue = moment(value).format(ISO_DATE_FORMAT);
        formRef.current.setFieldValue(field, normalizedValue);
      } else {
        formRef.current.setFieldValue(field, null);
      }
    }
  };

  return (
    <Styles.Modal
      title={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <AddContactHeader
          isPrimary={primaryCheckbox}
          title={type === 'add' ? 'Add Contact' : 'Edit Contact'}
          handleChange={handlePrimaryContactChange}
        />
      }
      visible={visible}
      onCancel={handleCancel}
      okButtonProps={{ onClick: submitForm }}
      okText="Save"
      isExtension={isExtension}
    >
      <Formik
        initialValues={handleInitialValues()}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
        component={formikProps => (
          <Styles.FormWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
            <Form layout="vertical" style={{ position: 'relative' }}>
              <Row gutter={20}>
                <Col>
                  <Form.Item name="first_name" label="First Name">
                    <Input name="first_name" className="autocomplete-input" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="last_name" label="Last Name">
                    <Input name="last_name" className="autocomplete-input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <Form.Item name="title" label="Title">
                    <Input name="title" className="autocomplete-input" />
                  </Form.Item>
                </Col>
              </Row>
              <FieldArray
                name="emails"
                render={arrayHelpers => {
                  const errors = arrayHelpers.form.errors.emails as FormikErrors<ContactEmails>[];

                  return (
                    <>
                      {(arrayHelpers.form.values.emails as ContactEmails[]).map((email, index) => (
                        <Row key={index} gutter={20} align="middle">
                          <Col
                            lg={12}
                            style={{
                              display: 'flex',
                            }}
                          >
                            <Form.Item
                              name={`emails.${index}.email`}
                              label="Email Address"
                              className="email"
                            >
                              <Input
                                name={`emails.${index}.email`}
                                className="autocomplete-input"
                                allowClear={index === 0}
                              />
                              {email.email && (
                                <Styles.ValidationMessage>
                                  <EmailValidationMessage
                                    showMessage={!!(errors && errors[index])}
                                  />
                                </Styles.ValidationMessage>
                              )}
                            </Form.Item>
                            {index > 0 && (
                              <CloseCircleTwoTone
                                className="remove-icon"
                                twoToneColor="#f5222d"
                                style={{
                                  color: '#f5222d',
                                  fontSize: 19,
                                  marginTop: 35,
                                  marginLeft: 10,
                                }}
                                onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  setEmailFieldsVisible(prevState => prevState - 1);

                                  return arrayHelpers.remove(index);
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      ))}
                      <Styles.AddEmailButton>
                        {!hideAddEmailBtn && (
                          <Button
                            onClick={() => {
                              window.pendo.track(OPTXChromePlugin.companyPage.addNewEmailAddress);

                              setEmailFieldsVisible(prevState => prevState + 1);
                              arrayHelpers.push({ email: '' });
                            }}
                          >
                            Add new email address
                          </Button>
                        )}
                      </Styles.AddEmailButton>
                    </>
                  );
                }}
              />
              <FieldArray
                name="additional_phones"
                render={arrayHelpers => {
                  return (
                    <>
                      {(arrayHelpers.form.values.additional_phones as ContactPhone[]).map(
                        (phones, index) => (
                          <Row key={index} gutter={20} align="top">
                            <Col lg={11}>
                              <Form.Item
                                key={index}
                                name={`additional_phones.${index}.phone_number`}
                                label="Phone Number"
                              >
                                <Input
                                  name={`additional_phones.${index}.phone_number`}
                                  className="autocomplete-input"
                                  allowClear={index === 0}
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={11} style={{ display: 'flex' }}>
                              <Form.Item
                                key={index}
                                name={`additional_phones.${index}.phone_type_id`}
                                label="Phone Number Type"
                                className="phone"
                              >
                                <Select
                                  name={`additional_phones.${index}.phone_type_id`}
                                  placeholder="Select phone type"
                                  getPopupContainer={trigger => trigger.parentElement!}
                                >
                                  <Option value="">Select phone type</Option>
                                  {selectOptions.map(phone => (
                                    <Option key={phone.value} value={phone.value}>
                                      {phone.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              {index > 0 && (
                                <CloseCircleTwoTone
                                  className="remove-icon"
                                  twoToneColor="#f5222d"
                                  style={{
                                    color: '#f5222d',
                                    fontSize: 19,
                                    marginTop: 35,
                                    marginLeft: 10,
                                  }}
                                  onClick={
                                    () => arrayHelpers.remove(index)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                        )
                      )}
                      <Styles.AddPhoneButton>
                        <Button
                          onClick={() => {
                            window.pendo.track(OPTXChromePlugin.companyPage.addNewPhoneNumber);
                            arrayHelpers.push({ phone_number: '', phone_type_id: '' });
                          }}
                        >
                          Add new phone number
                        </Button>
                      </Styles.AddPhoneButton>
                    </>
                  );
                }}
              />
              <Row gutter={20}>
                <Col>
                  <Form.Item name="linkedin" label="Linkedin Profile">
                    <Input name="linkedin" className="autocomplete-input" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <Form.Item
                    name="tenure_start_date"
                    label="Tenure Start Date"
                    className="tenure_date"
                  >
                    <DatePicker
                      picker="date"
                      name="tenure_start_date"
                      onChange={value => handleTenureDateChange('tenure_start_date', value)}
                      format={globalConfig.short_date.DATE_FORMAT}
                      getPopupContainer={trigger => trigger.parentElement!}
                      fast
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="tenure_end_date" label="Tenure End Date" className="tenure_date">
                    <DatePicker
                      picker="date"
                      name="tenure_end_date"
                      onChange={value => handleTenureDateChange('tenure_end_date', value)}
                      format={globalConfig.short_date.DATE_FORMAT}
                      getPopupContainer={trigger => trigger.parentElement!}
                      fast
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="psg_function" label="PSG Function">
                    <Field name="psg_function">
                      {(fieldProps: FieldProps) => (
                        <MultiSelect
                          {...fieldProps}
                          isContactForm={true}
                          options={psgFunctionOptions}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="slack_channel" label="Slack Channel">
                    <Field name="slack_channel">
                      {(fieldProps: FieldProps) => (
                        <MultiSelect
                          {...fieldProps}
                          isContactForm={true}
                          options={slackChannelOptions}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20} align="bottom">
                <Col span={12}>
                  <Checkbox
                    className="no-wrap-checkbox"
                    name="portfolio_executive"
                    onClick={() =>
                      window.pendo.track(OPTXChromePlugin.companyPage.portfolioExecutiveButton)
                    }
                  >
                    Portfolio Executive
                  </Checkbox>
                </Col>
              </Row>
            </Form>
          </Styles.FormWrapper>
        )}
      />
    </Styles.Modal>
  );
};

export default ContactForm;
