// components
import { ColumnType } from 'antd/lib/table';
import { CompanyMerge } from '@optx/models/company/CompanyMerges';
import { TableCellDefault } from '../AntTable/cells';
import TableCellLink from './cells/TableCellLink';

const columns: ColumnType<CompanyMerge>[] = [
  {
    dataIndex: 'url',
    title: 'URL',
    key: 'url',
    width: 80,
    render: TableCellLink,
  },
  {
    dataIndex: 'name',
    title: 'Company Name',
    key: 'name',
    width: 100,
    render: TableCellDefault,
  },
  {
    dataIndex: 'founded',
    title: 'Year Founded',
    key: 'founded',
    width: 100,
    render: TableCellDefault,
  },
  {
    dataIndex: 'location',
    title: 'Location',
    key: 'location',
    width: 100,
    render: TableCellDefault,
  },
  {
    dataIndex: 'source_db',
    title: 'Source',
    key: 'source',
    width: 60,
    render: TableCellDefault,
  },
  {
    dataIndex: 'source_id',
    title: 'Source ID',
    key: 'id',
    width: 60,
    render: TableCellDefault,
  },
  {
    dataIndex: 'merge_type',
    title: 'Merge Type',
    key: 'type',
    width: 80,
    render: TableCellDefault,
  },
];

export default columns;
