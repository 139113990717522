const CB = 'CompanyBanner';

export const CP = 'CompanyPage';

const OW = 'Overview';

const RM = 'RelationshipManagement';

export const COMPANY_PAGE_NEWS_FEED_GOOGLE_NEWS = `${CP}_NewsFeed_GoogleNews`;

export const PROPRIETARY_INFO = 'ProprietaryInfo_Financials_Edit';

export const COMPANY_PAGE_ACTION = `${CP}_ActionMenu`;

export const NavigationPane = {
  notifications: 'Navigation_Notifications',
  advancedSearch: 'Navigation_AdvancedSearch',
  myCompanies: 'Navigation_MyCompanies',
  myWatchlist: 'Navigation_MyWatchlist',
  savedCompanySearches: 'Navigation_SavedCompanySearches',
  touchManagement: 'Navigation_TouchManagement',
  suggestedSearches: 'Navigation_SuggestedSearches',
  sourceScrubLists: 'Navigation_SourceScrubLists',
  browseByRecentlyFunded: 'Navigation_BrowseByRecentlyFunded',
  myProfilePage: 'Navigation_MyProfilePage',
  logout: 'Naviagtion_LogOut',
};

export const companyPageTop = {
  website: `${CP}_Top_WebsiteIcon`,
  linkedin: `${CP}_Top_LinkedInIcon`,
  twitter: `${CP}_Top_TwitterIcon`,
  equitytouch: `${CP}_Top_EquityTouchIcon`,
  sourcescrub: `${CP}_Top_SourceScrubIcon`,
  g2crowd: `${CP}_Top_G2Icon`,
  crunchbase: `${CP}_Top_CrunchbaseIcon`,
  facebook: `${CP}_Top_FacebookIcon`,
  addTouchNote: `${CP}_Top_AddTouchNoteButton`,
  editCompanyInfo: `${CP}_Top_EditCompanyInfoButton`,
  changeDescription: `${CP}_Top_ChangeDescription`,
  psgFitThumbsUp: `${CP}_Top_PSGFit_ThumbsUp`,
  psgFitThumbsDown: `${CP}_Top_PSGFit_ThumbsDown`,
  addTagButton: `${CP}_Top_AddTagButton`,
  MarkAsReviewedButton: `${CP}_Top_MarkAsReviewedButton`,
  editDateFounded: `${CP}_Top_EditDateFounded`,
  addToFavorites: `${CP}_Top_AddToFavorites`,
  searchResultsBreadcrumb: `${CP}_Top_SearchResultsBreadcrumb`,
};

export const CompanyPageOverviewPendo = {
  active: `${CP}_${OW}_KeyInformation_Active`,
  stage: `${CP}_${OW}_KeyInformation_Stage`,
  rank: `${CP}_${OW}_KeyInformation_Pipeline Rank`,
  owner: `${CP}_${OW}_KeyInformation_Company Owner`,
  fit: `${CP}_${OW}_KeyInformation_PSG Fit`,
  date: `${CP}_${OW}_KeyInformation_Date Presented`,
  psgPlatformCompany: `${CP}_${OW}_KeyInformation_PSG Platform Company`,
  addOn: `${CP}_${OW}_KeyInformation_Add-on`,
  addOnFor: `${CP}_${OW}_KeyInformation_Add-on for`,
  leadSource: `${CP}_${OW}_KeyInformation_Lead Source`,
  seniorAdvisorThesis: `${CP}_${OW}_KeyInformation_Senior Advisor Thesis`,
  seniorDealTeamLead: `${CP}_${OW}_KeyInformation_Senior Deal Team Lead`,
  inBusiness: `${CP}_${OW}_KeyInformation_In Business`,
  opsTeam: `${CP}_${OW}_KeyInformation_OPS Team`,
  icStatusDate: `${CP}_${OW}_KeyInformation_IC Status Date`,
  expectedCloseDate: `${CP}_${OW}_KeyInformation_Expected Close Date`,
  dealTeam: `${CP}_${OW}_KeyInformation_Deal Team`,
  fund: `${CP}_${OW}_KeyInformation_Fund`,
  nextSteps: `${CP}_${OW}_KeyInformation_Next Steps`,
  lastRound: `${CP}_${OW}_Financial_LastRound`,
  revenue: `${CP}_${OW}_Financial_Revenue`,
  lastFundingDate: `${CP}_${OW}_Financial_LastFundingDate`,
  ebitda: `${CP}_${OW}_Financial_EBITDA`,
  softwareCompany: `${CP}_${OW}_Taxonomy_SoftwareCompany`,
  companyType: `${CP}_${OW}_Taxonomy_CompanyType`,
  opportunityType: `${CP}_${OW}_Taxonomy_OpportunityType`,
  sector: `${CP}_${OW}_Taxonomy_Sector`,
  subsector: `${CP}_${OW}_Taxonomy_SubSector`,
  productCategory: `${CP}_${OW}_Taxonomy_ProductCategory`,
  fundingRounds3M: `${CP}_${OW}_FundingRounds_3M`,
  fundingRounds6M: `${CP}_${OW}_FundingRounds_6M`,
  fundingRounds1Y: `${CP}_${OW}_FundingRounds_1Y`,
  fundingRounds2Y: `${CP}_${OW}_FundingRounds_2Y`,
  fundingRoundsALL: `${CP}_${OW}_FundingRounds_ALL`,
  optxScore3M: `${CP}_${OW}_OPTXScore_3M`,
  optxScore6M: `${CP}_${OW}_OPTXScore_6M`,
  optxScore1Y: `${CP}_${OW}_OPTXScore_1Y`,
  optxScore2Y: `${CP}_${OW}_OPTXScore_2Y`,
  employeeCount3M: `${CP}_${OW}_EmployeeCount_3M`,
  employeeCount6M: `${CP}_${OW}_EmployeeCount_6M`,
  employeeCount1Y: `${CP}_${OW}_EmployeeCount_1Y`,
  employeeCount2Y: `${CP}_${OW}_EmployeeCount_2Y`,
  similarCompanies_profileLink: `${CP}_${OW}_SimilarCompanies_CompanyProfileLink`,
};

export const CompanyPageRelationshipManagement = {
  equityTouchHistory_addTouchNote: `${CP}_${RM}_EquityTouchHistory_AddTouchNote`,
  equityTouchHistory_touchType_all: `${CP}_${RM}_EquityTouchHistory_TouchType_All`,
  equityTouchHistory_touchType_call: `${CP}_${RM}_EquityTouchHistory_TouchType_Call`,
  equityTouchHistory_touchType_email: `${CP}_${RM}_EquityTouchHistory_TouchType_Email`,
  equityTouchHistory_touchType_internalNote: `${CP}_${RM}_EquityTouchHistory_TouchType_InternalNote`,
  equityTouchHistory_touchType_stageModified: `${CP}_${RM}_EquityTouchHistory_TouchType_StageModified`,
  equityTouchHistory_createdBy: `${CP}_${RM}_EquityTouchHistory_CreatedBy`,
  equityTouchHistory_scheduledTouches: `${CP}_${RM}_EquityTouchHistory_ScheduledTouches`,
  equityTouchHistory_previousTouches_touchpoint: `${CP}_${RM}_EquityTouchHistory_PreviousTouches_Touchpoint`,
  equityTouchHistory_editTouch: `${CP}_${RM}_EquityTouchHistory_EditTouch`,
  equityTouchHistory_emailAttendees: `${CP}_${RM}_EquityTouchHistory_EmailAttendees`,
  equityTouchHistory_expandTouchPointArrow: `${CP}_${RM}_EquityTouchHistory_ExpandTouchPointArrow`,
  primaryContacts_addContact: `${CP}_${RM}_PrimaryContacts_AddContact`,
  primaryContacts_editContact: `${CP}_${RM}_PrimaryContacts_EditContact`,
};

export const CompanyPageFilesEquityTouchFilesDownload = `${CP}_Files_EquityTouchFilesDownload`;

export const CompanyPageProprietaryInfo = {
  dealPreparation_datePresented: `${CP}_ProprietaryInfo_DealPreparation_DatePresented`,
  dealPreparation_equityCheck: `${CP}_ProprietaryInfo_DealPreparation_EquityCheck`,
  dealPreparation_banker: `${CP}_ProprietaryInfo_DealPreparation_Banker`,
  dealPreparation_nameOfBanker: `${CP}_ProprietaryInfo_DealPreparation_NameOfBanker`,
  dealPreparation_dealType: `${CP}_ProprietaryInfo_DealPreparation_DealType`,
  financials_addYears: `${CP}_ProprietaryInfo_Financials_AddYears`,
};

const ADDON_MGMT = `${CP}_AddOnMGMT`;

export const CompanyPageAddOnMGMT = {
  search: `${ADDON_MGMT}_Search`,
  stageType: `${ADDON_MGMT}_StageType`,
  showActiveCompanies: `${ADDON_MGMT}_ShowActiveCompanies`,
  nextSteps: `${ADDON_MGMT}_NextSteps`,
  origin: `${ADDON_MGMT}_Origin`,
  filter: `${ADDON_MGMT}_Filter`,
  exportSaveSearch: `${ADDON_MGMT}_ExportSaveSearch`,
  fullScreen: `${ADDON_MGMT}_FullScreen`,
  sortByCompanyName: `${ADDON_MGMT}_SortByCompanyName`,
  sortByPsgFit: `${ADDON_MGMT}_SortByPSGFit`,
  sortByOptxScore: `${ADDON_MGMT}_SortByOPTXScore`,
  sortByStage: `${ADDON_MGMT}_SortByStage`,
  sortByNextsteps: `${ADDON_MGMT}_SortByNextSteps`,
  sortByLastTouch: `${ADDON_MGMT}_SortByLastTouch`,
  sortByFutureTouch: `${ADDON_MGMT}_SortByFutureTouch`,
  sortByAction: `${ADDON_MGMT}_SortByAction`,
};

const LISTS = `${CP}_Lists`;

export const CompanyPageLists = {
  SortByDateUpdated: `${LISTS}_SortByDateUpdated`,
  ListName: `${LISTS}_ListName`,
};

const HISTORY = `${CP}_History`;

export const CompanyPageHistory = {
  auditTrail_search: `${HISTORY}_AuditTrail_Search`,
  auditTrail_startDate: `${HISTORY}_AuditTrail_StartDate`,
  auditTrail_endDate: `${HISTORY}_AuditTrail_EndDate`,
  auditTrail_all: `${HISTORY}_AuditTrail_All`,
  auditTrail_editField: `${HISTORY}_AuditTrail_EditField`,
  mergeHistory_checkBox_unmergeCompanies: `${HISTORY}_MergeHistory_CheckBox_UnmergeCompanies`,
};

export const MenuNotifications = {
  viewOptxRoadmap: 'Notifications_ViewOPTXRoadmap',
  inbox: 'Notifications_Inbox',
  history: 'Notifications_History',
  history_newestToOldest: 'Notifications_History_NewestToOldest',
  upcomingTouches: 'Notifications_UpcomingTouches',
  pastDue: 'Notifications_PastDue',
};

const FNC = 'FindNewCompanies';

export const MenuFindNewCompaniesSuggestedSearches = {
  SuggestedSearches: `${FNC}_SuggestedSearches`,
  MyWatchlists: `${FNC}_SuggestedSearches_MyWatchlists`,
  SourceScrubLists: `${FNC}_SuggestedSearches_SourceScrubLists`,
  SavedCompanySearches: `${FNC}_SuggestedSearches_SavedCompanySearches`,
  SavedContactSearches: `${FNC}_SuggestedSearches_SavedContactSearches`,
};

const FNCSSL = 'FindNewCompanies_SourceScrubLists';

export const FindNewCompaniesSourceScrubLists = {
  source_scrub_lists: `${FNCSSL}`,
  title: `${FNCSSL}_ListName`,
  startdate: `${FNCSSL}_StartDate`,
  enddate: `${FNCSSL}_EndDate`,
  last_refreshed_at: `${FNCSSL}_LastRefreshedDate`,
  number_of_companies: `${FNCSSL}_NumberOfCompanies`,
  number_of_companies_in_et: `${FNCSSL}_NumberOfCompaniesInET`,
  number_of_companies_not_in_et: `${FNCSSL}_NumberNotInET`,
  number_of_last_touch_over_6_months: `${FNCSSL}_NumberWOaTouchLessThan6M`,
  number_of_future_touches: `${FNCSSL}_NumberOfFutureTouches`,
  source_scrub_lists_action: `${FNCSSL}_Action`,
};

export const FIND_NEW_COMPANIES_BROWSE_BY_RECENTLY_FUNDED = `${FNC}_BrowseByRecentlyFunded`;

const TR = 'TopRibbon';
const TRATN = `${TR}_Actions_AddTouchNote`;

const LOG = 'LogCompleteTouch';

const SCHEDULE = 'ScheduleFollowUpTouch';

const ADD_NEW_CONTACT = `${CP}_Contacts_AddNewContact`;

const TOUCHES = `${CP}_Touches`;

const ACTION_VERIFY = `${TR}_Actions_VerifyKeyInfo`;

export const OPTXChromePlugin = {
  topHeader: {
    addTouchNote: {
      note: `${TRATN}`,
      stages: {
        stage: `${TRATN}_Stage`,
        portfolioCompany: `${TRATN}_Stage_PortfolioCompany`,
        dead: `${TRATN}_Stage_Dead`,
        pass: `${TRATN}_Stage_Pass`,
        lead: `${TRATN}_Stage_Lead`,
        qualifiedLead: `${TRATN}_Stage_QualifiedLead`,
        track: `${TRATN}_Stage_Track`,
        vwia: `${TRATN}_Stage_VWIA`,
        target: `${TRATN}_Stage_Target`,
        priority: `${TRATN}_Stage_Priority`,
        ioiPresented: `${TRATN}_Stage_IOIPresented`,
        loiPresented: `${TRATN}_Stage_LOIPresented`,
        loiSigned: `${TRATN}_Stage_LOISigned`,
      },
      ranks: {
        rank: `${TRATN}_Rank`,
        none: `${TRATN}_Rank_None`,
        1: `${TRATN}_Rank_1`,
        2: `${TRATN}_Rank_2`,
        3: `${TRATN}_Rank_3`,
        4: `${TRATN}_Rank_4`,
        5: `${TRATN}_Rank_5`,
      },
      cancel: `${TRATN}_Cancel`,
      save: `${TRATN}_Save`,
      logs: {
        log: `${TRATN}_${LOG}`,
        type: `${TRATN}_${LOG}_Type`,
        type_call: `${TRATN}_${LOG}_Type_Call`,
        type_contactImport: `${TRATN}_${LOG}_Type_ContactImport`,
        type_crunchbase: `${TRATN}_${LOG}_Type_Crunchbase`,
        type_documentUpload: `${TRATN}_${LOG}_Type_DocumentUpload`,
        type_email: `${TRATN}_${LOG}_Type_Email`,
        type_internalNote: `${TRATN}_${LOG}_Type_InternalNote`,
        type_meeting: `${TRATN}_${LOG}_Type_Meeting`,
        type_ndaSigned: `${TRATN}_${LOG}_Type_NDASigned`,
        type_stageModified: `${TRATN}_${LOG}_Type_StageModified`,
        type_transferred: `${TRATN}_${LOG}_Type_Transferred`,
        type_voiceMail: `${TRATN}_${LOG}_Type_Voicemail`,
        initator: `${TRATN}_${LOG}_Initator`,
        otherpsgAttendees: `${TRATN}_${LOG}_OtherPSGAttendees`,
        date: `${TRATN}_${LOG}_Date`,
        schedule: `${TRATN}_${LOG}_${SCHEDULE}`,
        schedule_1week: `${TRATN}_${LOG}_${SCHEDULE}_1Week`,
        schedule_2weeks: `${TRATN}_${LOG}_${SCHEDULE}_2weeks`,
        schedule_1month: `${TRATN}_${LOG}_${SCHEDULE}_1Month`,
        schedule_3months: `${TRATN}_${LOG}_${SCHEDULE}_3months`,
        schedule_6months: `${TRATN}_${LOG}_${SCHEDULE}_6months`,
        schedule_1year: `${TRATN}_${LOG}_${SCHEDULE}_1year`,
        schedule_customdate: `${TRATN}_${LOG}_${SCHEDULE}_CustomRange`,
        schedule_type: `${TRATN}_${LOG}_${SCHEDULE}_Type`,
        schedule_type_call: `${TRATN}_${LOG}_${SCHEDULE}_Type_Call`,
        schedule_type_contactImport: `${TRATN}_${LOG}_${SCHEDULE}_Type_ContactImport`,
        schedule_type_crunchbase: `${TRATN}_${LOG}_${SCHEDULE}_Type_Crunchbase`,
        schedule_type_documentUpload: `${TRATN}_${LOG}_${SCHEDULE}_Type_DocumentUpload`,
        schedule_type_email: `${TRATN}_${LOG}_${SCHEDULE}_Type_Email`,
        schedule_type_internalNote: `${TRATN}_${LOG}_${SCHEDULE}_Type_InternalNote`,
        schedule_type_meeting: `${TRATN}_${LOG}_${SCHEDULE}_Type_Meeting`,
        schedule_type_ndaSigned: `${TRATN}_${LOG}_${SCHEDULE}_Type_NDASigned`,
        schedule_type_stageModified: `${TRATN}_${LOG}_${SCHEDULE}_Type_StageModified`,
        schedule_type_transferred: `${TRATN}_${LOG}_${SCHEDULE}_Type_Transferred`,
        schedule_type_voiceMail: `${TRATN}_${LOG}_${SCHEDULE}_Type_Voicemail`,
        schedule_date: `${TRATN}_${LOG}_${SCHEDULE}_Date`,
        schedule_subject: `${TRATN}_${LOG}_${SCHEDULE}_Subject`,
      },
    },
    view: `${TR}_ViewinOPTX `,
    xout: `${TR}_XOut `,
    actions: {
      action: `${TR}_Actions `,
      verifyKeyInfos: {
        verifyKeyInfo: `${ACTION_VERIFY}`,
        cancel: `${ACTION_VERIFY}_Cancel`,
        save: `${ACTION_VERIFY}_Save`,
        softwareCompanyThumbsUp: `${ACTION_VERIFY}_SoftwareCompanyThumbsUp`,
        softwareCompanyThumbsDown: `${ACTION_VERIFY}_SoftwareCompanyThumbsDown`,
        psgFitThumbsUp: `${ACTION_VERIFY}_PSGFitThumbsUp`,
        psgFitThumbsDown: `${ACTION_VERIFY}_PSGFitThumbsDown`,
      },
      openInEquityTouch: `${TR}_Actions_OpenInEquityTouch`,
      addtoSalesloft: `${TR}_Actions_AddtoSalesloft`,
      addtoList: `${TR}_Actions_AddtoList`,
      hideOPTXTab: `${TR}_Actions_HideOPTXTab`,
      logout: `${TR}_Actions_LogOut`,
    },
  },
  companyBanner: {
    company_icon: `${CB}_CompanyLink`,
    website_icon: `${CB}_WebsiteIcon`,
    linkedin_icon: `${CB}_LinkedinIcon`,
    twitter_icon: `${CB}_TwitterIcon`,
    equitytouch_icon: `${CB}_EquityTouchIcon`,
    sourcescrub_icon: `${CB}_SourceScrubIcon`,
    g2crowd_icon: `${CB}_G2Icon`,
    bvdorbis_icon: `${CB}_BvdorbisIcon`,
    capterra_icon: `${CB}_CapterraIcon`,
    crunchbase_icon: `${CB}_CrunchbaseIcon`,
  },
  companyPage: {
    stats: `${CP}_OPTXStats`,
    click_ceo_email: `${CP}_OPTXStats_Click_CEO_Email`,
    descriptions: {
      description: `${CP}_OPTXStats_Description`,
      equityTouch: `${CP}_OPTXStats_Description_EquityTouch`,
      sourceScrub: `${CP}_OPTXStats_Description_SourceScrub`,
      crunchbaseShort: `${CP}_OPTXStats_Description_CrunchbaseShort`,
      crunchbaseLong: `${CP}_OPTXStats_Description_CrunchbaseLong`,
      glassDoor: `${CP}_OPTXStats_Description_Glassdoor`,
      capterra: `${CP}_OPTXStats_Description_Capterra`,
      addTag: `${CP}_OPTXStats_Description_AddTag`,
    },
    employeeCount3M: `${CP}_OPTXStats_EmployeeCount_3M`,
    employeeCount6M: `${CP}_OPTXStats_EmployeeCount_6M`,
    employeeCount1Y: `${CP}_OPTXStats_EmployeeCount_1Y`,
    employeeCount2Y: `${CP}_OPTXStats_EmployeeCount_2Y`,
    optxScore3M: `${CP}_OPTXStats_OPTXScore_3M`,
    optxScore6M: `${CP}_OPTXStats_OPTXScore_6M`,
    optxScore1Y: `${CP}_OPTXStats_OPTXScore_1Y`,
    optxScore2Y: `${CP}_OPTXStats_OPTXScore_2Y`,
    touches: TOUCHES,
    clickTouchpoint: `${TOUCHES}_ClickTouchpoint`,
    threeDotsAction: `${TOUCHES}_ThreeDotsAction`,
    threeDotsAction_editTouch: `${TOUCHES}_ThreeDotsAction_EditTouch`,
    threeDotsAction_emailAttendees: `${TOUCHES}_ThreeDotsAction_EmailAttendees`,
    arrowToExpand: `${TOUCHES}_ArrowtoExpand`,
    funding: `${CP}_Funding`,
    contacts: `${CP}_Contacts`,
    contacts_addNewContact: ADD_NEW_CONTACT,
    primaryContactButton: `${ADD_NEW_CONTACT}_PrimaryContactButton`,
    psgFunctions: {
      psgFunction: `${ADD_NEW_CONTACT}_PSGFunction`,
      selectAllNone: `${ADD_NEW_CONTACT}_PSGFunction_SelectAllNone`,
      ceo: `${ADD_NEW_CONTACT}_PSGFunction_CEO`,
      coo: `${ADD_NEW_CONTACT}_PSGFunction_COO`,
      corporateDevelopment: `${ADD_NEW_CONTACT}_PSGFunction_CorporateDevelopment`,
      cto: `${ADD_NEW_CONTACT}_PSGFunction_CTO`,
      customerSuccessSupport: `${ADD_NEW_CONTACT}_PSGFunction_CustomerSuccessSupport`,
      executiveChairman: `${ADD_NEW_CONTACT}_PSGFunction_ExecutiveChairman`,
      finance: `${ADD_NEW_CONTACT}_PSGFunction_Finance`,
      hr: `${ADD_NEW_CONTACT}_PSGFunction_HR`,
      legal: `${ADD_NEW_CONTACT}_PSGFunction_Legal`,
      marketing: `${ADD_NEW_CONTACT}_PSGFunction_Marketing`,
      presidentCoo: `${ADD_NEW_CONTACT}_PSGFunction_PresidentCOO`,
      product: `${ADD_NEW_CONTACT}_PSGFunction_Product`,
      sales: `${ADD_NEW_CONTACT}_PSGFunction_Sales`,
      technology: `${ADD_NEW_CONTACT}_PSGFunction_Technology`,
    },
    slackChannels: {
      slackChannel: `${ADD_NEW_CONTACT}_SlackChannel`,
      selectAllNone: `${ADD_NEW_CONTACT}_SlackChannel_SelectAllNone`,
      psgCeo: `${ADD_NEW_CONTACT}_SlackChannel_PSGCEO`,
      psgCfo: `${ADD_NEW_CONTACT}_SlackChannel_PSGCFO`,
      psgExecutiveTeams: `${ADD_NEW_CONTACT}_SlackChannel_PSGExecutiveTeams`,
      psgGtm: `${ADD_NEW_CONTACT}_SlackChannel_PSGGTM`,
      psgHr: `${ADD_NEW_CONTACT}_SlackChannel_PSGHR`,
      psgTechleader: `${ADD_NEW_CONTACT}_SlackChannel_PSGTechLeader`,
    },
    portfolioExecutiveButton: `${ADD_NEW_CONTACT}_PortfolioExecutiveButton`,
    addNewEmailAddress: `${ADD_NEW_CONTACT}_AddNewEmailAddress`,
    addNewPhoneNumber: `${ADD_NEW_CONTACT}_AddNewPhoneNumber`,
    cancel: `${ADD_NEW_CONTACT}_Cancel`,
    save: `${ADD_NEW_CONTACT}_Save`,
    contacts_threeDotsAction: `${CP}_Contacts_ThreeDotsAction`,
    contacts_threeDotsAction_editContact: `${CP}_Contacts_ThreeDotsAction_EditContact`,
    lists: `${CP}_Lists`,
    lists_startDate: `${CP}_Lists_Startdate`,
    lists_dateUpdated: `${CP}_Lists_DateUpdated`,
    lists_clickCompanyName: `${CP}_Lists_ClickCompanyName`,
    news: `${CP}_News`,
    news_clickNewsIcon: `${CP}_News_ClickNewsIcon`,
  },
};
