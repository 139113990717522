import styled from 'styled-components';

interface LegendProps {
  type: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const LegendWrapper = styled.div<LegendProps>`
  background: ${props => (props.type === 'employee' ? '#f9f0ff' : '#fff')};
  border: ${props => (props.type === 'employee' ? '1px solid #722ed1' : '1px solid #219653')};
  width: 75px;
  height: 20px;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

const Legend = styled.span<LegendProps>`
  border: ${props => (props.type === 'employee' ? '1px dashed #722ed1' : '3px solid #219653')};
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
`;

const LegendText = styled.div<LegendProps>`
  color: #595959;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  font-weight: ${props => (props.type === 'employee' ? '400' : '600')};
`;

const Styles = {
  Wrapper,
  LegendWrapper,
  Legend,
  LegendText,
};

export default Styles;
