import { ColumnType } from 'antd/lib/table';
// models
import { SelectedCompanies } from '@optx/models/bulkActions';
// components
import { TableCellCompanyName, TableCellBoolean } from '@components/common/table/AntTable/cells';
import { TableCellFit } from './cells';
import TableCellContact from '../AntTable/cells/TableCellContact/TableCellContact';

const columns = (defaultOptxScore: string): ColumnType<SelectedCompanies>[] => {
  const defaultColumns = [
    {
      dataIndex: 'company_name',
      title: 'Company Name',
      render: TableCellCompanyName,
      width: 220,
    },
    {
      dataIndex: 'is_in_et',
      title: 'In ET',
      className: 'is_in_et',
      render: TableCellBoolean,
      width: 60,
    },
    {
      dataIndex: 'is_interesting',
      title: 'PSG Fit',
      className: 'is_interesting',
      render: TableCellFit,
      width: 90,
    },
    {
      dataIndex: 'score',
      title: `${defaultOptxScore !== 'il' ? 'OPTX Score' : 'IL OPTX Score'}`,
      className: 'score',
      width: 70,
      render: (value: number) => (isNaN(Number(value)) ? 'N/A' : Number(value)),
    },
    {
      dataIndex: 'primary_contact',
      title: 'Contacts for Sales Loft Cadences (optional)',
      className: 'contact-cell',
      render: TableCellContact,
    },
  ];

  const newScoreColumn = {
    dataIndex: 'score_v3',
    title: 'OPTX Score BETA',
    className: 'score_v3',
    width: 70,
    render: (value: number) => (isNaN(Number(value)) ? 'N/A' : Number(value)),
  };

  if (defaultOptxScore !== 'il') {
    defaultColumns.splice(4, 0, newScoreColumn);
  }

  return defaultColumns;
};

export default columns;
