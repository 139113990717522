import React, { useCallback } from 'react';
import PrivateComponent from '@optx/components/common/PrivateComponent';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, MenuProps } from 'antd';
// models
import { SelectOption } from '@optx/models/Option';
import { FieldChangedValue } from '@features/company/audit-trail/state/interfaces';
// redux
import { companyOwnerActions } from '@optx/components/feature/company-individual-edit';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state';
import { useHistoryTab } from '@optx/common/hooks/history';

interface ClaimAsOwnerMenuItemProps extends MenuItemProps {
  companyId: number;
  companyOwnerName?: null | string;
}

export const ClaimAsOwnerMenuItem: React.FC<ClaimAsOwnerMenuItemProps> = ({
  companyId,
  companyOwnerName,
  onClick,
  children,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const { updateHistoryFields } = useHistoryTab();
  const userLastName = useSelector(userSelectors.getLastName);
  const userFullName = useSelector(userSelectors.getFullName);
  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const owner: SelectOption | undefined = companyOwnerOptions.find(
    option => option.label === userLastName
  );
  const components = useSelector(userSelectors.authorizedComponents);
  const addOwner = useCallback(
    (companyOwnerId, companyOwnerName, companyId) => {
      dispatch(
        companyOwnerActions.addCompanyOwner({
          companyId,
          companyOwnerId: companyOwnerId || 'blank',
          companyOwnerName: companyOwnerName || '',
        })
      );
    },
    [dispatch]
  );

  const handleClick: MenuProps['onClick'] = info => {
    addOwner(owner?.value, owner?.label, companyId);

    updateHistoryFields({
      afterValue: owner?.label as string,
      beforeValue: companyOwnerName as string,
      fieldChanged: FieldChangedValue.companyOwner,
    });
    onClick && onClick(info);
  };

  const isAdmin = Object.keys(components).includes('admin_portal_link');

  return (
    <PrivateComponent accessFor="analyst_general">
      {userFullName !== companyOwnerName && !isAdmin && (
        <Menu.Item
          key="claim-as-owner-menu"
          onClick={handleClick}
          className="ant-dropdown-menu-item"
          {...restProps}
        >
          {children}
        </Menu.Item>
      )}
    </PrivateComponent>
  );
};

ClaimAsOwnerMenuItem.defaultProps = {
  children: 'Claim as owner',
};
