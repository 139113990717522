import React, { useCallback, useRef } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import AsyncCreatable from 'react-select/async-creatable';
import { OptionsType, ActionMeta, InputActionMeta } from 'react-select';
// models
import { SpecialFilterKey } from '@optx/models/filters';
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { OPTXChromePlugin, companyPageTop } from '@optx/constants/pendoActions';
import { COMPANY_TAG_ENDPOINT } from '@constants/asyncEndpoints';
// utils
import { isNotOnlyEmptySpaces } from '@optx/utils/string';
// redux
import { actions as tagsActions } from '@redux/company/tags';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
import useFilterSearch from '@optx/common/hooks/filters/useSpecialFilter';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
// components
import { PlusOutlined } from '@ant-design/icons';
import AsyncCreatableMultiSelect from '@optx/components/common/select/AsyncCreatableMultiSelect';
// styles
import './style.scss';

interface AddTagsProps {
  companyId: number;
  value: SelectOption[];
  isCollapsed?: boolean;
}

const AddTags: React.FC<AddTagsProps> = ({ companyId, value, isCollapsed }) => {
  const addTagRef = useRef(null);
  const [showAddButton, toggleShowAddButton] = useToggle(false);
  const { loadCompaniesByFilter } = useFilterSearch('company_tag' as SpecialFilterKey);
  useOnClickOutside(addTagRef, () => toggleShowAddButton());

  // when a tag is clicked
  const handleTagClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    loadCompaniesByFilter(event.currentTarget.innerText, true);
  };

  const dispatch = useDispatch();
  const addTag = useCallback(
    (companyId: number, tag: string) =>
      dispatch(tagsActions.addCompanyTag({ companyId, name: tag })),
    [dispatch]
  );

  const deleteTag = useCallback(
    (companyId: number, tagId: number, label?: string) =>
      dispatch(tagsActions.deleteCompanyTag({ companyId, id: tagId, label })),
    [dispatch]
  );

  const onChange = (
    values: OptionsType<SelectOption<string | number>>,
    actionMeta: ActionMeta<SelectOption<string | number>>
  ) => {
    switch (actionMeta.action) {
      case 'select-option':

      // eslint-disable-next-line no-fallthrough
      case 'create-option': {
        const option = values[values.length - 1];
        addTag(companyId, option.label);

        toggleShowAddButton();
        break;
      }

      case 'remove-value': {
        const { removedValue } = actionMeta;
        deleteTag(companyId, Number.parseInt(removedValue!.value as string), removedValue!.label);
        break;
      }

      default:
        break;
    }
  };

  const { loadOptions } = useAsyncSearch({
    endpoint: COMPANY_TAG_ENDPOINT,
  });

  const selectRef = useRef<AsyncCreatable<SelectOption> | null>(null);

  const handleClick = () => {
    const chromeExtensionExists = document.getElementsByClassName('extension-tabs').length;

    if (chromeExtensionExists) {
      window.pendo.track(OPTXChromePlugin.companyPage.descriptions.addTag);
    } else {
      window.pendo.track(companyPageTop.addTagButton);
    }

    toggleShowAddButton();

    // focus select after is shown.
    setTimeout(() => {
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }, 0);
  };

  const handleMenuClose = () => {
    if (!showAddButton) {
      toggleShowAddButton();
    }
  };

  const handleInputChange = (newValue: string, { action }: InputActionMeta) => {
    switch (action) {
      case 'input-change': {
        // This prevents a bug where the query is called without the last letter of previous query
        // eg: If user writes 'auto', query is 'auto'. If the second time user writes 'auto', query will be 'aut'.
        // This seems to fix above problem;
        loadOptionsAfterInputChange(newValue, () => {});
        break;
      }

      default:
        break;
    }
  };

  const loadOptionsAfterInputChange = (query: string, callback: SuccessErrorCallback) => {
    loadOptions(query, options => {
      const callbackResult = callback(options);

      return callbackResult;
    });
  };

  if (!showAddButton) {
    return (
      <Button
        className={classNames('btn--add-tag', isCollapsed === false && 'not-collapsed__btn')}
        type="primary"
        ghost
        onClick={handleClick}
        icon={<PlusOutlined />}
        size="small"
      >
        Add Tag
      </Button>
    );
  }

  return (
    <div
      ref={addTagRef}
      className={classNames('btn--add-tag', isCollapsed === false && 'not-collapsed')}
    >
      <AsyncCreatableMultiSelect
        loadOptions={loadOptionsAfterInputChange}
        onChange={onChange}
        value={value}
        onInputChange={handleInputChange}
        selectRef={selectRef}
        onMenuClose={handleMenuClose}
        onTagClick={handleTagClick}
        closeMenuOnSelect
        wrapperClassName="company-tags"
        bodyDisplay={false}
        disableValueRemove
        validateCreateOption={isNotOnlyEmptySpaces}
      />
    </div>
  );
};

export default AddTags;
