import React from 'react';
// models
import Company, { Addon } from '@optx/models/Company';
// services
import { CompanyService } from '@optx/services/api';
// utils
import { formatAddon } from '@optx/utils/format';
// components
import { EditFieldCheckBoxSelectAsync } from '@optx/features/company/edit-fields/components';

type TableCellAddonProps = {
  value: Addon | null;
  record: Company;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
};

const TableCellAddon: React.FC<TableCellAddonProps> = ({ value, record, getPopupContainer }) => {
  if (record.source_tag?.includes('et')) {
    return (
      <EditFieldCheckBoxSelectAsync
        fieldName="Add-on"
        record={record}
        service={CompanyService.updateAddon}
        isFromGrid
        successMessage="Add-on updated successfully!"
        errorMessage=""
        value={value}
      />
    );
  }

  return <>{formatAddon(value)}</>;
};

export default React.memo(TableCellAddon);
