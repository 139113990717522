import { createAction } from '@reduxjs/toolkit';
import { CompanyFiltersMeta, SearchSaveResponse } from '@models/search';
import { FilterSource } from '@models/filters';
import * as types from './actionTypes';
import { NextSteps, AlertSettingsResponse, FormRationaleResponse } from './interfaces';

export const fetchCompanyFilters = createAction(types.FETCH_COMPANY_FILTERS);

export const fetchCompanyFiltersForNotifications = createAction(
  types.FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS
);

export const fetchCompanyFiltersSuccess = createAction(
  types.FETCH_COMPANY_FILTERS_SUCCESS,
  (filters: Array<FilterSource>, meta: CompanyFiltersMeta) => ({
    payload: filters,
    meta,
  })
);

export const fetchCompanyFiltersForNotificationsSuccess = createAction(
  types.FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS_SUCCESS,
  (filters: Array<FilterSource>, meta: CompanyFiltersMeta) => ({
    payload: filters,
    meta,
  })
);

export const fetchCompanyFiltersFail = createAction<string>(types.FETCH_COMPANY_FILTERS_FAILURE);

export const fetchCompanyFiltersForNotificationsFail = createAction<string>(
  types.FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS_FAILURE
);

// This action stops saga for calling company filters when we get an authorization (401) error.
export const stopFetchingFilters = createAction<boolean>(types.STOP_FETCHING_FILTERS);

export const changeDefaultView = createAction<SearchSaveResponse | undefined>(
  types.CHANGE_DEFAULT_VIEW
);

export const setFormRationales = createAction<FormRationaleResponse>(types.SET_FORM_RATIONALES);

export const setAlertSettings = createAction<AlertSettingsResponse>(types.SET_ALERT_SETTINGS);
export const setNextSteps = createAction<NextSteps[]>(types.SET_NEXT_STEPS);
