import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';

// styles
import Styled from './SingleSelectFieldContact.styled';

const { Option } = Select;

interface SingleSelectFieldContactProps {
  field: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const SingleSelectFieldContact: React.FC<SingleSelectFieldContactProps> = ({
  field,
  fieldUsedFor,
}) => {
  const [selected, , setSelectedOption] = useField<string>(field?.id || '');

  const handleChange = (value: string) => {
    setSelectedOption.setValue(value);
  };

  useEffect(() => {
    if (
      (field?.value || field?.value === '') &&
      fieldUsedFor !== 'editAllInfo' &&
      !selected.value
    ) {
      if (typeof field.value === 'object' && field.value.value) {
        setSelectedOption.setValue(field.value.value);
      } else {
        setSelectedOption.setValue(field.value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  return (
    <Styled.SelectContactWrapper span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <FormItem name={field.id}>
        <Select
          showSearch
          name={field.id}
          value={selected.value}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullLabel = option ? `${option?.children.props.children}` : '';

            return fullLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {field.data &&
            field.data.length &&
            field.data?.map((option: OptionTypeBase) => (
              <Option key={option.value as string} value={option.value as string}>
                <span>{option.label + ' - ' + option.title}</span>
              </Option>
            ))}
        </Select>
      </FormItem>
    </Styled.SelectContactWrapper>
  );
};

export default React.memo(SingleSelectFieldContact);
