import { selectors as fetchSelectors } from '@redux/feature/fetch';
import { EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyIndividualEditState, IndividualEditFieldType } from './interfaces';
// utils
import { getYearsList } from '@utils/date';

export const isLoading = (state: CompanyIndividualEditState) =>
  fetchSelectors.isLoadingSelector(state);

export const getError = (state: CompanyIndividualEditState) => fetchSelectors.errorSelector(state);

export const isSubmitting = (state: CompanyIndividualEditState) => !!state.data && state.loading;

export const failedLoadingInfo = (state: CompanyIndividualEditState) =>
  !state.data && !!state.error;
export const getData = (state: CompanyIndividualEditState) => state.data;

export const getMultipleOptions =
  (fields: IndividualEditFieldType[]) => (state: CompanyIndividualEditState) => {
    const options: Partial<{
      [key in IndividualEditFieldType]: { value: string; label: string }[];
    }> = {};

    fields.forEach(field => {
      options[field] = getSelectOptionsByField(field)(state);
    });

    return options;
  };

export const getSelectOptionsByField =
  (field: IndividualEditFieldType) => (state: CompanyIndividualEditState) => {
    if (!state.data || !state.data[field]) {
      return [];
    }

    return state.data[field].received_values.map(item => ({
      value: (item as EquityTouchDatabaseMatch).itemId,
      label: (item as EquityTouchDatabaseMatch).text,
    }));
  };

export const shouldInitialFetch = (state: CompanyIndividualEditState) =>
  !state.loading && !state.fetchedAt;

export const getYearFoundedOptions = () => getYearsList();
