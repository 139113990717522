import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
// redux
import { actions } from '@redux/company/opportunity-presentation';
// components
import { TableYearTitleContainer } from './TableYearTitle.styled';

interface TableYearTitleProps {
  year: number;
  estimated: boolean;
  actionPendo?: string;
}

const TableYearTitle: React.FC<TableYearTitleProps> = ({ year, estimated, actionPendo }) => {
  const dispatch = useDispatch();

  const handlePopoverLabel = () => {
    return `${year} ${estimated ? 'Estimated' : 'Actual'}`;
  };

  return (
    <TableYearTitleContainer>
      <span className="financial-value financial-value-row">{handlePopoverLabel()}</span>
      <EditOutlined
        onClick={() => {
          if (actionPendo) {
            window.pendo.track(actionPendo);
          }

          dispatch(actions.editAddYearsOpenModal({ year }));
        }}
        className="edit-icon"
      ></EditOutlined>
    </TableYearTitleContainer>
  );
};

export default React.memo(TableYearTitle);
