import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
// utils
import { contactFilterOption, reorderPrimaryContact } from '@utils/select/select';
import { validateContactLimit } from '@optx/utils/contact';
// redux
import { selectors as primaryContactsSelectors } from '@redux/company/primary-contacts';
import { actions as salesloftActions } from '@redux/contacts/salesloft';
// components
import Styled from '@optx/shared/view/molecules/Select/Styled/ContactSelectWrapper.styled';
import ContactOption from '@optx/shared/view/molecules/contacts/ContactOption';
import ContactOptionIcon from '@optx/shared/view/molecules/contacts/ContactOptionIcon';
import ContactTags from '@optx/shared/view/molecules/contacts/ContactTags';
import { SalesloftContext } from '../SalesloftContext';

const { Option } = Select;

const SelectContacts: React.FC = () => {
  const dispatch = useDispatch();
  const { errors, setErrors } = useContext(SalesloftContext);
  const contacts = useSelector(primaryContactsSelectors.getPrimaryContacts);
  const [value, setValue] = useState<number[]>([]);

  // when using styled components with Select, typescript throws a weird error
  // "parameters value and value are incompatible". with just the Select component
  // we can set the type just fine
  const handleChange = (value: any) => {
    if (errors.length) {
      setErrors([]);
    }

    if (value && validateContactLimit(value.length)) {
      dispatch(salesloftActions.salesloftSelectContacts(value));
      setValue(reorderPrimaryContact(value as number[], contacts));
    }
  };

  useEffect(() => {
    const primaryContact = contacts.filter(contact => contact.primary_contact)[0];

    if (primaryContact) {
      handleChange(Array.from(new Set([primaryContact.person_id])));
    } else {
      setValue([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts.length]);

  return (
    <>
      <Styled.SelectWrapper
        showArrow
        placeholder="Select contacts"
        onChange={handleChange}
        optionFilterProp="children"
        filterOption={contactFilterOption}
        mode="multiple"
        tagRender={props => <ContactTags {...props} />}
        menuItemSelectedIcon={ContactOptionIcon}
        $isCell={false}
        dropdownMatchSelectWidth={250}
        value={value}
      >
        {contacts.map(contact => (
          <Option
            key={contact.person_id as number}
            value={contact.person_id as number}
            className="contact-option"
          >
            <ContactOption contact={contact} />
          </Option>
        ))}
      </Styled.SelectWrapper>

      <Styled.ContactSelectGlobal />
    </>
  );
};

export default SelectContacts;
