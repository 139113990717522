import React, { useEffect, useState } from 'react';
import { Card, Radio } from 'antd';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, LabelList } from 'recharts';
// models
import {
  ChartBarIntervalKeys,
  ChartBarItem,
  ChartBars,
  ChartBarExtra,
} from '@optx/models/charts/data';
import { RadioChangeEvent } from 'antd/lib/radio';
// constants
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { CompanyPageOverviewPendo } from '@optx/constants/pendoActions';
// utils
import { roundNumber } from '@optx/utils/number';
// components
import { MinimumSizeBar } from '@optx/components/common/charts/components';
import KeyPerformanceIndicator from '../../common/Text/KeyPerformanceIndicator';
import EmptyChart from './EmptyChart';
import BarChartTooltip from './BarChartTooltip';
// styles
import Styles from './ChartHeader.style';
import BarCustomizedLabel from './BarCustomizedLabel';

interface BarChartIntervalsProps {
  data: ChartBars;
  isLoading?: boolean;
  title: string;
  dataKey: string;
  label: string;
  extra?: Array<ChartBarExtra>;
  emptyText?: string;
  className?: string;
}

const BarChartIntervals: React.FC<BarChartIntervalsProps> = ({
  data,
  isLoading = false,
  title,
  dataKey,
  label,
  extra,
  emptyText,
  className,
}) => {
  const keys = Object.keys(data).map(key => key);
  const lastKey = keys[keys.length - 1] as ChartBarIntervalKeys;
  const [interval, setInterval] = useState('');
  const [chartPoints, setChartPoints] = useState<ChartBarItem[]>([]);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  const handleSizeChange = (e: RadioChangeEvent) => {
    const key = e.target.value as ChartBarIntervalKeys;
    setInterval(() => key);
    setChartPoints(data[key]?.data || []);
  };

  useEffect(() => {
    if (data[lastKey].data.length) {
      setInterval(() => lastKey);
      setChartPoints(data[lastKey].data);
    } else {
      setChartPoints(data[lastKey].data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleActionPendo = (action: keyof typeof CompanyPageOverviewPendo) => {
    if (CompanyPageOverviewPendo[action]) {
      window.pendo.track(CompanyPageOverviewPendo[action]);
    }
  };

  const header = (
    <Styles.ChartHeaderWrapper>
      <Styles.ChartTitleWrapper>{title}</Styles.ChartTitleWrapper>
      <Radio.Group value={interval} onChange={handleSizeChange}>
        {Object.keys(data).map((key, index) => (
          <Radio.Button
            key={index}
            value={key}
            onClick={() =>
              handleActionPendo(`fundingRounds${key}` as keyof typeof CompanyPageOverviewPendo)
            }
          >
            {key}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Styles.ChartHeaderWrapper>
  );

  const extraContent = (
    <>
      <div>
        {extra!.map((item, index) => {
          if (item.format) {
            if (item.value === 'Unknown') {
              return (
                <KeyPerformanceIndicator
                  key={index}
                  label={item.label || ''}
                  value={item.value || ''}
                />
              );
            }

            return (
              <KeyPerformanceIndicator
                key={index}
                label={item.label || ''}
                value={item.value || ''}
                formatValue={val => roundNumber(val, `$${NUMBER_FORMAT}`).toUpperCase()}
              />
            );
          }

          return (
            <KeyPerformanceIndicator
              key={index}
              label={item.label || ''}
              value={item.value || ''}
            />
          );
        })}
      </div>
    </>
  );

  return (
    <Card title={header} extra={extraContent} className={`profile-chart-card extra ${className}`}>
      {isLoading ? (
        <Styles.LoadingIconWrapper>
          <Styles.LoadingIcon />
        </Styles.LoadingIconWrapper>
      ) : (
        <div
          className="profile-chart-card__container"
          onMouseEnter={() => setTooltipVisibility(true)}
          onMouseLeave={() => setTooltipVisibility(false)}
        >
          <ResponsiveContainer>
            <BarChart data={chartPoints} margin={{ top: 5 }}>
              <XAxis
                dataKey={label}
                tickLine={false}
                stroke="#C2CFE0"
                tickSize={15}
                interval="preserveStartEnd"
                padding={{ left: 0, right: 0 }}
              />
              <YAxis
                orientation="right"
                tickCount={2}
                tickMargin={10}
                tickLine={false}
                axisLine={false}
                stroke="#C2CFE0"
                tickFormatter={val => roundNumber(val, '$0,0a').toUpperCase()}
              />
              <Tooltip
                wrapperStyle={{
                  visibility: `${tooltipVisibility ? 'visible' : 'hidden'}`,
                  zIndex: '3',
                }}
                content={<BarChartTooltip data={chartPoints} />}
                cursor={{ fill: 'rgba(0,0,0,0)' }}
              />
              <Bar dataKey={dataKey} fill="#1890FF" shape={MinimumSizeBar}>
                <LabelList dataKey={label} content={<BarCustomizedLabel data={chartPoints} />} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          {!chartPoints.length && <EmptyChart text={emptyText} />}
        </div>
      )}
    </Card>
  );
};

export default BarChartIntervals;
