import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Button } from 'antd';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
import styled from 'styled-components';
// models
import { PreselectedFilter } from '@optx/models/filters';
//constants
import { PIPELINE_REPORTS } from '@optx/constants/exportedFileName';
// utils
import { exportFileCSV } from '@utils/csvHandlerPipeline';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
// redux
import { selectors } from '@features/pipeline-report/state';

const ExportCSVBtn = styled(Button)`
  display: inline-flex;
  width: 100%;
  span {
    margin-left: -3px;
  }
`;

interface ExportCSVButtonProps {
  onChangeVisible?: () => void;
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({ onChangeVisible }) => {
  const { normalized, filter } = useSelector(selectors.getFilters);

  const pipelineReportCompanies = useSelector(selectors.pipelineReportCompanies);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = isLoading || pipelineReportCompanies.length === 0;

  const handleExportCSV = () => {
    let queryData: Dictionary<PreselectedFilter | number> = {};

    const mappedFiltersURLParams = mapFiltersToURLParams(normalized, filter);

    queryData = {
      ...mappedFiltersURLParams,
      country: undefined,
    };

    if (filter?.country === 'Israel') {
      delete queryData.fund;
      queryData.is_il_optx_score = true;
    }

    if (queryData.fund === '') {
      delete queryData.fund;
    }

    const query = queryString.stringify(queryData, {
      arrayFormat: 'comma',
    });

    setIsLoading(true);

    exportFileCSV(PIPELINE_REPORTS, query).then(() => {
      setIsLoading(false);
    });

    onChangeVisible && onChangeVisible();
  };

  return (
    <Tooltip title="Up to 100K rows" placement="left" trigger={['click', 'hover']}>
      <ExportCSVBtn
        type="text"
        style={{
          display: 'inline-flex',
          width: '100%',
        }}
        onClick={handleExportCSV}
        disabled={isDisabled}
      >
        {isLoading ? 'Exporting' : 'Export to .csv'}
      </ExportCSVBtn>
    </Tooltip>
  );
};

export default ExportCSVButton;
