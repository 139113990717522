import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
// models
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { SuccessErrorCallback } from '@optx/models/callback';
// services
import NotificationService from '@services/NotificationService';
import { UserService } from '@services/api';
// redux
import { AxiosResponse } from 'axios';
import { UserInformation } from '@optx/models/user';
import * as actions from './actions';

export function* updateUserSettingsSaga(
  action: PayloadAction<Partial<UpdateUserSettingsPayload>, any, SuccessErrorCallback>
) {
  const { meta: callback } = action;

  try {
    yield call(UserService.updateSettings, action.payload);

    if (action.type === actions.updateUserSettingsFromAutoSave.type) {
      yield put(actions.updateUserSettingsFromAutoSaveSuccess(action.payload));
    } else if (action.type === actions.updateUserSettingsForFavoritesList.type) {
      yield put(actions.updateUserSettingsForFavoritesListSuccess(action.payload));
    } else if (action.type === actions.updateUserSettingsForSSList.type) {
      yield put(actions.updateUserSettingsForSSListSuccess(action.payload));
    } else if (action.type === actions.updateUserSettingsForUserList.type) {
      yield put(actions.updateUserSettingsForUserListSuccess(action.payload));
    } else if (action.type === actions.updateUserSettingsForSavedContactSearches.type) {
      yield put(actions.updateUserSettingsForSavedContactSearchesSuccess(action.payload));
    } else if (action.type === actions.updateUserSettingsForSuggestedSearches.type) {
      yield put(actions.updateUserSettingsForSuggestedSearchesSuccess(action.payload));
    } else {
      yield put(actions.updateUserSettingsSuccess(action.payload));
    }

    callback && callback(true);
  } catch (e: any) {
    const errorMsg = `Failed to update user settings!!! (${Object.keys(action.payload).join(
      ' | '
    )})`;
    callback && callback(null, errorMsg);
    !callback && NotificationService.error(errorMsg);
  }
}

export function* fetchUserInformationSaga() {
  try {
    const res: AxiosResponse<UserInformation> = yield call(UserService.getUserInfo);

    if (res.data) {
      yield put(actions.fetchUserInformationSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch User Information!!!';
      yield put(actions.fetchUserInformationFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch User Information!!!';
    yield put(actions.fetchUserInformationFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* userSaga() {
  yield takeLatest(actions.updateUserSettings, updateUserSettingsSaga);
  yield takeLatest(actions.updateUserSettingsFromAutoSave, updateUserSettingsSaga);
  yield takeLatest(actions.updateUserSettingsForSSList, updateUserSettingsSaga);
  yield takeLatest(actions.updateUserSettingsForFavoritesList, updateUserSettingsSaga);
  yield takeLatest(actions.updateUserSettingsForUserList, updateUserSettingsSaga);
  yield takeLatest(actions.updateUserSettingsForSavedContactSearches, updateUserSettingsSaga);
  yield takeLatest(actions.updateUserSettingsForSuggestedSearches, updateUserSettingsSaga);
  yield takeLatest(actions.fetchUserInformation, fetchUserInformationSaga);
}
