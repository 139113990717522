import { AppState } from '@optx/redux/interfaces';
import { createSelector } from 'reselect';

const getModalState = (state: AppState) => state.researchRationale;

export const isOpen = createSelector(getModalState, state => state.open);

export const getCompanyInfo = createSelector(getModalState, state => {
  return state.company;
});

export const getFields = createSelector(getModalState, state => {
  return state.fields;
});

export const getRationale = createSelector(getModalState, state => {
  return state.rationale;
});

export const getInitialFields = createSelector(getModalState, state => {
  return state.initialFieldsValues;
});

export const getInitialRationale = createSelector(getModalState, state => {
  return state.initialRationaleValues;
});

export const getActiveRationaleReasonType = createSelector(getModalState, state => {
  return state.activeScoreRationaleReasonType;
});

export const getNewActiveRationaleReasonType = createSelector(getModalState, state => {
  return state.activeNewScoreRationaleReasonType;
});
