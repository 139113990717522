import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ValueType } from 'react-select';
// models
import { CompanyProfile } from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
import { ServiceType } from '@optx/features/company/edit-fields/state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSaveButton } from '@utils/proprietaryInfo';
// redux
import { selectors } from '@redux/user/user-list';
import { selectors as filterSelectors } from '@redux/company/filters';
import { actions as opportunityPresentationActions } from '@redux/company/opportunity-presentation';
// hooks
import useUpdateFields from '@optx/features/company/edit-fields/hooks/useUpdateFields';
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
// components
import MultiSelect from '@optx/shared/view/molecules/Select/MultiSelect';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import MultiSelectWithRationale from '@optx/features/company/edit-fields/components/MultiSelectWithRationale';
import Styled from './CellPopoverMultiselect.styled';

const presentationFields = [
  'Revenue Model',
  'Sales Cycle',
  'Delivery Model',
  'Architecture',
  'Customer Type',
];
const fieldsWithRationale = ['Customer Type'];

interface EditFieldMultiSelectProps {
  fieldName:
    | 'OPS Team'
    | 'Deal Team'
    | 'Senior Deal Team Lead'
    | 'Customer Type'
    | 'Revenue Model'
    | 'Sales Cycle'
    | 'Delivery Model'
    | 'Architecture';
  record: CompanyProfile;
  value: string[] | null;
  fieldRationaleValue?: string;
  labelValue?: string;
  hidePenIcon?: boolean;
  successMessage?: string;
  errorMessage?: string;
  isFromGrid: boolean;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
  isFromHistory?: boolean;
  service?: ServiceType;
  actionPendo?: string;
}

const EditFieldMultiSelect: React.FC<EditFieldMultiSelectProps> = ({
  value,
  fieldRationaleValue,
  fieldName,
  isFromGrid,
  isFromHistory,
  labelValue,
  errorMessage,
  successMessage,
  hidePenIcon,
  destroyOnHide,
  getPopupContainer,
  isDynamicPopoverHeight,
  service,
  record,
  actionPendo,
}) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const popoverContentRef = useRef(null);

  const { updateField } = useUpdateFields({
    companyUrl: record.company_url,
    companyId: record.company_id,
    service: service as ServiceType,
    fieldName,
    successMessage,
    errorMessage,
    isFromHistory,
  });

  const listSelectors = {
    'OPS Team': selectors.getUserListOptions,
    'Deal Team': selectors.getPsgUsers,
    'Senior Deal Team Lead': selectors.getSeniorDealTeamLeadOptions,
    'Revenue Model': filterSelectors.getRevenueModelOptions,
    'Sales Cycle': filterSelectors.getSalesCycleOptions,
    'Delivery Model': filterSelectors.getDeliveryModelOptions,
    'Customer Type': filterSelectors.getCustomerTypeOptions,
    Architecture: filterSelectors.getArchitectureOptions,
  };

  const list = useSelector(listSelectors[fieldName]);
  const [selectedValues, setSelectedValues] = useState<SelectOption<string>[] | string[]>([]);
  const [rationaleValue, setRationaleValue] = useState<string>('');
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const renderRationaleField =
    isFromGrid &&
    fieldsWithRationale.includes(fieldName) &&
    (selectedValues as string[]).includes('Other');

  const valueSelected = useCallback(() => {
    if (value && !isFromGrid) {
      const membersName: { value: string; label: string }[][] = [];
      value.forEach((_, index) => {
        const element = value[index];
        const options = list.filter(option => option.label === element);
        membersName.push(options);
      });

      return membersName.flat();
    }

    return value || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, value]);

  const handleMultiSelect = (option: ValueType<SelectOption>) => {
    const findBlankValue = (option as SelectOption[]).find(
      (item: SelectOption) => item.value === 'blank'
    );

    if (presentationFields.includes(fieldName) && findBlankValue) {
      setSelectedValues([findBlankValue]);
    } else {
      setSelectedValues(option as SelectOption[]);
    }
  };

  const handleGridMultiSelect = (value: string, type: 'select' | 'deselect') => {
    if (type === 'select') {
      setSelectedValues([...((selectedValues as string[]) || []), value]);
    } else if (type === 'deselect') {
      const updatedSelected = (selectedValues as string[]).filter(option => option !== value);
      setSelectedValues(updatedSelected);
    }
  };

  const handleSave = () => {
    const values = isFromGrid
      ? selectedValues
      : (selectedValues as SelectOption<string>[]).map(item => item.value);

    if (presentationFields.includes(fieldName)) {
      const financialValues = {
        financial: {
          revenue_model: values,
        },
      };

      const customerKpisValues = {
        customer_kpis: {
          ...(fieldName === 'Sales Cycle' && { sales_cycle: values }),
          ...(fieldName === 'Customer Type' && {
            customer_type: values,
            ...((values as string[]).includes('Other')
              ? { customer_other_rationale: rationaleValue }
              : {}),
          }),
        },
      };

      const technologyValues = {
        technology: {
          ...(fieldName === 'Delivery Model' && { delivery_model: values }),
          ...(fieldName === 'Architecture' && { architecture: values }),
        },
      };

      const payload = {
        company_id: record.company_id,
        ...(fieldName === 'Revenue Model' && financialValues),
        ...(fieldName === 'Sales Cycle' || fieldName === 'Customer Type' ? customerKpisValues : {}),
        ...(fieldName === 'Delivery Model' || fieldName === 'Architecture' ? technologyValues : {}),
      };
      dispatch(opportunityPresentationActions.updateOpportunityPresentation(payload));
    } else if (value?.toString() !== values.toString()) {
      updateField(selectedValues);
      setClosePopup(true);
    }

    setClosePopup(true);
  };

  const handleCancel = () => {
    setClosePopup(true);
    setSelectedValues(valueSelected);

    if (fieldsWithRationale.includes(fieldName) && fieldRationaleValue !== undefined) {
      setRationaleValue(fieldRationaleValue);
    }
  };

  const handleAllValuesSelected = () => {
    if (value?.length === list.length) {
      return 'ALL';
    }

    return value ? value.join(', ') : value;
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    if (!closePopup) {
      setSelectedValues(valueSelected);

      if (fieldRationaleValue !== undefined && fieldsWithRationale.includes(fieldName)) {
        setRationaleValue(fieldRationaleValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup, value, fieldRationaleValue]);

  const getIsDisabled = () => {
    if (!isFromGrid) {
      if (!selectedValues.length && !value?.length) {
        return true;
      }

      let allValuesExist = true;
      value?.forEach(item =>
        (selectedValues as SelectOption<string>[]).find(option => option.label === item)
          ? false
          : (allValuesExist = false)
      );

      return allValuesExist && selectedValues.length === value?.length;
    } else {
      const disabled = fieldsWithRationale.includes(fieldName)
        ? checkSaveButton(selectedValues as string[], value) &&
          checkSaveButton(rationaleValue, fieldRationaleValue as string)
        : checkSaveButton(selectedValues as string[], value);

      return disabled;
    }
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  const content = (
    <div
      className="addon-popover"
      role="presentation"
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
      ref={popoverContentRef}
    >
      <Styled.CellPopoverMultiselect>
        {!isFromGrid && (
          <MultiSelect
            value={selectedValues as ValueType<SelectOption<string>>}
            getPopupContainer={(trigger: any) => trigger.parentElement}
            onChange={handleMultiSelect}
            onMenuClose={() => containerRef.current && containerRef.current.focus()}
            options={list}
            hasButtons
            defaultMenuIsOpen={true}
            autoFocus={true}
          />
        )}
        {isFromGrid && (
          <MultiSelectWithRationale
            $selectedValues={selectedValues as string[]}
            $list={list}
            $renderRationaleField={renderRationaleField}
            $rationaleValue={rationaleValue}
            $handleGridMultiSelect={handleGridMultiSelect}
            $setRationaleValue={setRationaleValue}
          />
        )}
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={getIsDisabled()}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Styled.CellPopoverMultiselect>
    </div>
  );

  if (isFromGrid) {
    let normalizedValue = DEFAULT_EMPTY_VALUE;

    if (value?.length) {
      normalizedValue = value.join(', ');
    }

    return (
      <InlineEdit
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <EditCellPen
            setClosePopup={setClosePopup}
            closePopup={closePopup}
            content={content}
            title={`Edit "${fieldName}" value`}
          />
        }
        fill
      >
        <TruncateTooltip placement="topLeft" className="edit-field--text" title={normalizedValue}>
          {normalizedValue}
        </TruncateTooltip>{' '}
      </InlineEdit>
    );
  }

  return (
    <EditPopover
      content={content}
      title={`Edit "${fieldName}" value `}
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      onVisibilityChange={handleCancel}
      getPopupContainer={getPopupContainer}
      destroyOnHide={destroyOnHide}
      isDynamicPopoverHeight={isDynamicPopoverHeight}
      hidePenIcon={hidePenIcon}
      actionPendo={actionPendo}
    >
      {labelValue !== undefined ? (
        labelValue
      ) : (
        <TruncateTooltip
          placement="topLeft"
          title={handleAllValuesSelected() || DEFAULT_EMPTY_VALUE}
        >
          {handleAllValuesSelected() || DEFAULT_EMPTY_VALUE}
        </TruncateTooltip>
      )}
    </EditPopover>
  );
};

export default React.memo(EditFieldMultiSelect);
