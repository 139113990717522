import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { FormItem, Input } from 'formik-antd';
import { Row, Col, Typography, Input as AntdInput } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { debounce } from 'lodash';
// models
import { FormValues } from '../models';
// constants
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
import { VIEW_AND_CLOSE } from '../constants';
// utils
import { validateUrl } from '@optx/utils/validation';
// redux
import { selectors, actions } from '@features/company/add-company';
// components
import OverlayLoader from '@components/common/loader/OverlayLoader';
import { Styled } from './AddCompanyModal.styled';

interface Step1Props {
  formikProps: FormikProps<FormValues>;
  handleDisableNextButton: (disabled: boolean) => void;
  shouldGoToNextStep: () => void;
  canGoToNextStep: (confirm: boolean) => void;
}

/**
 * In Step 1 we check for URL validation on blur and handle the status accordingly.
 */
const AddCompanyStep1: React.FC<Step1Props> = ({
  formikProps,
  handleDisableNextButton,
  shouldGoToNextStep,
  canGoToNextStep,
}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState<string>(formikProps.values.primaryUrl || '');
  const data = useSelector(selectors.getUrlData);
  const inputRef = useRef<AntdInput>(null);
  const urlData = useMemo(() => {
    return !data && url.length >= SEARCH_MINIMUM_CHAR_COUNT && validateUrl(url)
      ? { loading: false, isValid: true }
      : { ...data };
  }, [url, data]);

  const [validUrl, setValidUrl] = useState<boolean>(urlData?.isValid || validateUrl(url));
  const shouldShowValidationResult = useMemo(
    () => urlData && !urlData.loading && validUrl && url.length >= SEARCH_MINIMUM_CHAR_COUNT,
    [url, urlData, validUrl]
  );

  const handleChange = (inputValue: string) => {
    if (inputValue.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      const isValidUrl = validateUrl(inputValue);

      setValidUrl(isValidUrl);

      if (isValidUrl) {
        dispatch(actions.checkUrl(inputValue));
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleChange = useCallback(debounce(handleChange, 3000), []);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidUrl(false);
    setUrl(event.target.value);

    debouncedHandleChange(event.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    debouncedHandleChange.cancel();
    setTimeout(() => {
      const canMoveToNext = document.activeElement?.classList.contains('add-company-primary');

      if (document.activeElement?.childNodes[0]?.textContent === VIEW_AND_CLOSE) return;

      handleChange(url);
      canMoveToNext && shouldGoToNextStep();
    });
  };

  useEffect(() => {
    const { primaryUrl: primaryUrlError, companyName: companyNameError } = formikProps.errors;
    const { primaryUrl, companyName } = formikProps.values;
    const shouldDisableNextButton =
      primaryUrlError ||
      companyNameError ||
      !primaryUrl ||
      !companyName ||
      urlData?.loading ||
      (!urlData.foundCompanyId && shouldShowValidationResult && !urlData?.isValid);

    if (shouldDisableNextButton) {
      handleDisableNextButton(true);
    } else {
      handleDisableNextButton(false);
    }
  }, [formikProps, handleDisableNextButton, urlData, validUrl, shouldShowValidationResult]);

  useEffect(() => {
    if (shouldShowValidationResult && !urlData?.isValid) {
      inputRef.current?.focus();
    }
  }, [shouldShowValidationResult, urlData?.isValid]);

  useEffect(() => {
    canGoToNextStep(validUrl);
  }, [canGoToNextStep, validUrl]);

  return (
    <Styled.Step1>
      <Row className="spacer-bottom">
        <Typography.Text>
          First, let's check to see if this company already exists in OPTX.
        </Typography.Text>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col className="step-1-col">
              <FormItem name="companyName" label="Company Name" required>
                <Input name="companyName" />
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col className="step-1-col">
              <FormItem
                name="primaryUrl"
                className="primary-url-form-item"
                label="Primary URL"
                required
              >
                <Input
                  name="primaryUrl"
                  onChange={handleUrlChange}
                  onBlur={handleBlur}
                  disabled={urlData?.loading}
                  value={url}
                  ref={inputRef}
                />
                {url && (
                  <div className="url-status-icon-container">
                    {urlData?.loading && <OverlayLoader loading={true} isOverlay />}

                    {shouldShowValidationResult && (
                      <>
                        {urlData?.isValid && <CheckCircleFilled style={{ color: '#82b128' }} />}
                        {!urlData?.isValid && <CloseCircleFilled style={{ color: '#c53132' }} />}
                      </>
                    )}
                  </div>
                )}
              </FormItem>
              {shouldShowValidationResult && (
                <Typography.Text className="url-info">
                  {urlData.message
                    ? urlData.message
                    : urlData.isValid
                    ? 'Unique URL'
                    : 'This URL already exists'}
                </Typography.Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Styled.Step1>
  );
};

export default React.memo(AddCompanyStep1);
