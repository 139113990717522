import React, { useContext } from 'react';
import { Card, Space, Row, Col, Typography } from 'antd';
import { FieldArrayRenderProps } from 'formik';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useHover } from '@umijs/hooks';
// models
import { RangeOptionBase } from '@optx/models/RangeOption';
import { RangeFilter } from '@optx/models/filters';
import { FormCheckableRangeOption } from '@optx/models/Option';
import FilterRangeContent from './FilterRangeContent';
import FilterRangeLogContent from './FilterRangeLogContent';
import { FiltersContext } from '../../FiltersContext';

const { Meta } = Card;

export interface FilterRangeProps {
  arrayHelpers: FieldArrayRenderProps;
  filter: RangeFilter<Array<RangeOptionBase>>;
  option: FormCheckableRangeOption;
  index: number;
}

const FilterRange: React.FC<FilterRangeProps> = ({ arrayHelpers, filter, option, index }) => {
  const [isHovering, hoverRef] = useHover<HTMLDivElement>();
  const displayActions =
    filter.allow_multiple &&
    isHovering &&
    index === arrayHelpers.form.values[filter.column].length - 1;
  const { onManualFilterChange } = useContext(FiltersContext);

  const addFilter = () => {
    // changed .push to .unshift bug with multiple components
    arrayHelpers.unshift({
      ...option,
      range: [option.min, option.max],
    });
    arrayHelpers.move(0, arrayHelpers.form.values[filter.column].length);
  };

  const removeFilter = () => {
    arrayHelpers.remove(index);

    // update getHistogram when removing extra slider on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  const descriptionContent =
    filter.slider_type === 'log' ? (
      <FilterRangeLogContent filter={filter} index={index} />
    ) : (
      <FilterRangeContent arrayHelpers={arrayHelpers} filter={filter} index={index} />
    );

  return (
    <div ref={hoverRef} className="filter-form__card-wrapper">
      <Card className="filter-form__card">
        <Meta
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Row align="middle" justify="space-between">
              <Col xs={16} md={20}>
                <Typography.Text
                  title={filter.label}
                  style={{ maxWidth: '100%' }}
                  ellipsis
                  className="filter-form__card-title"
                >
                  {filter.label}
                </Typography.Text>
              </Col>
              {displayActions && (
                <Col
                  xs={8}
                  md={4}
                  style={{ textAlign: 'right' }}
                  className="filter-form__extra-content"
                >
                  <Space>
                    {index !== 0 && (
                      <CloseOutlined onClick={removeFilter} style={{ color: '#F5222D' }} />
                    )}
                    <PlusOutlined onClick={addFilter} style={{ color: '#1890FF' }} />
                  </Space>
                </Col>
              )}
            </Row>
          }
          description={descriptionContent}
        />
      </Card>
    </div>
  );
};

export default FilterRange;
