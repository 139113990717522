import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// redux
import { selectors } from '@redux/user/user-list';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

interface TableCellDealTeamsProps {
  fieldType: 'Deal Team' | 'Senior Deal Team Lead';
  value: number[] | null | string;
}

const TableCellDealTeams: React.FC<TableCellDealTeamsProps> = ({ fieldType, value }) => {
  const listSelectors = {
    'Deal Team': selectors.getPsgUsers,
    'Senior Deal Team Lead': selectors.getSeniorDealTeamLeadOptions,
  };

  const list = useSelector(listSelectors[fieldType]);

  const handleRenderValue = useMemo(() => {
    if (value === null || value === DEFAULT_CELL_VALUE) {
      return DEFAULT_CELL_VALUE;
    }

    if (typeof value === 'string') {
      const listOfIds = value.split(',');
      const listOfNames = list
        .reduce((acc, item) => {
          if (listOfIds.includes(item.value)) {
            acc.push(item.label);
          }

          return acc;
        }, [] as string[])
        .join(', ');

      return listOfNames;
    }

    if (Array.isArray(value)) {
      const listOfIds = value.map(item => item.toString());
      const listOfNames = list
        .reduce((acc, item) => {
          if (listOfIds.includes(item.value)) {
            acc.push(item.label);
          }

          return acc;
        }, [] as string[])
        .join(', ');

      return listOfNames;
    }
  }, [list, value]);

  return (
    <TruncateTooltip placement="topLeft" title={handleRenderValue}>
      {handleRenderValue}
    </TruncateTooltip>
  );
};

export default React.memo(TableCellDealTeams);
