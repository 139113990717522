import React from 'react';
import { ColumnType } from 'antd/es/table';
// models
import { CompanyUserContact } from '@models/api/contacts';
// components
import {
  TableCellDefault,
  TableCellDateInterval,
  TableCellEmail,
  TableCellCeoRating,
  TableCellPhones,
  TableCellLinkedin,
  TableCellBoolean,
} from '../AntTable/cells';
import { TableCellActions, TableCellName, TableCellList } from './cells';
import { TableCellIcons } from '../TableContactsEquityTouch/cells';

const columns: ColumnType<CompanyUserContact>[] = [
  {
    dataIndex: 'source_db',
    title: 'Source',
    width: 70,
    render: TableCellDefault,
    className: 'not-interactive',
  },
  {
    dataIndex: 'full_name',
    title: 'Name',
    width: 250,
    render: TableCellName,
    className: 'not-interactive',
  },
  {
    dataIndex: 'title',
    title: 'Title',
    width: 130,
    render: TableCellDefault,
    className: 'not-interactive',
  },
  {
    dataIndex: 'ceo_approval',
    title: 'CEO Rank',
    width: 80,
    render: value => TableCellCeoRating(value, '-'),
    className: 'not-interactive',
  },
  {
    dataIndex: 'tenure_start_date',
    title: 'Tenure',
    width: 170,
    render: TableCellDateInterval,
    className: 'not-interactive',
  },
  {
    dataIndex: 'email',
    title: 'Email',
    width: 100,
    render: TableCellEmail,
  },
  {
    dataIndex: 'additional_phones',
    title: 'Phones',
    width: 100,
    render: TableCellPhones,
    className: 'not-interactive',
  },
  {
    dataIndex: 'linkedin',
    title: 'LinkedIn Hyperlink',
    width: 100,
    render: TableCellLinkedin,
  },
  {
    dataIndex: 'psg_function',
    title: 'PSG Function',
    width: 100,
    ellipsis: false,
    render: (value: any, record: any, index: number) => (
      <TableCellList value={value} record={record} />
    ),
    className: 'not-interactive',
  },
  {
    dataIndex: 'slack_channel',
    title: 'Slack Channel',
    width: 100,
    ellipsis: false,
    render: (value: any, record: any, index: number) => (
      <TableCellList value={value} record={record} />
    ),
    className: 'not-interactive',
  },
  {
    dataIndex: 'portfolio_executive',
    title: 'Portfolio Executive',
    width: 100,
    render: TableCellBoolean,
    className: 'not-interactive',
  },
  {
    dataIndex: 'sources',
    title: 'Sources',
    className: 'sources',
    width: 180,
    render: TableCellIcons,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    width: 65,
    className: 'row_actions sticky sticky-right',
    render: (value: any, record: CompanyUserContact, index: number) => (
      <TableCellActions value={value} record={record} index={index} />
    ),
  },
];

export default columns;
