import { Menu } from 'antd';
// components
import { ResetColumnWidthsMenu } from '@features/columns/update-columns/components';

const SearchMenu = () => {
  return (
    <Menu>
      <ResetColumnWidthsMenu key={2} />
    </Menu>
  );
};

export default SearchMenu;
