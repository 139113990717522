export const FETCH_COMPANY_FILTERS = 'FETCH_COMPANY_FILTERS';
export const FETCH_COMPANY_FILTERS_SUCCESS = 'FETCH_COMPANY_FILTERS_SUCCESS';
export const FETCH_COMPANY_FILTERS_FAILURE = 'FETCH_COMPANY_FILTERS_FAILURE';
export const STOP_FETCHING_FILTERS = 'STOP_FETCHING_FILTERS';

export const FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS = 'FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS';
export const FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS_SUCCESS =
  'FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS_SUCCESS';
export const FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS_FAILURE =
  'FETCH_COMPANY_FILTERS_FOR_NOTIFICATIONS_FAILURE';

export const CHANGE_DEFAULT_VIEW = 'CHANGE_DEFAULT_VIEW';

export const SET_FORM_RATIONALES = 'SET_FORM_RATIONALES';
export const SET_NEXT_STEPS = 'SET_NEXT_STEPS';

export const SET_ALERT_SETTINGS = 'SET_ALERT_SETTINGS';
