import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { OptionsType, InputActionMeta } from 'react-select';
import { Button } from 'antd';
import styled from 'styled-components';
import { debounce } from 'lodash';
// models
import { FieldValue } from '@features/company/audit-trail/state/interfaces';
import { ServiceType } from '@features/company/edit-fields/state/interfaces';
import { SuccessErrorCallback } from '@optx/models/callback';
import { CompanyProfile, Company, CompanyUserValuesKeys } from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useUpdateFields from '../hooks/useUpdateFields';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { Styled } from '@optx/features/company/edit-fields/components/TableCellFit.styled';

const DefaultTooltip = styled(TruncateTooltip)`
  display: block;
`;

const MultiSelectWrapper = styled.div`
  margin-right: 160px;
`;

interface EditFieldMultiSelectAsyncCreatableProps {
  fieldName: 'Sub Vertical';
  fieldKey?: CompanyUserValuesKeys;
  record: Company | CompanyProfile;
  isFromGrid?: boolean;
  hidePenIcon?: boolean;
  lastTemporaryFieldValue?: FieldValue;
  service: ServiceType;
  asyncSearchEndpoint: string;
  successMessage: string;
  errorMessage: string;
  value: string[];
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

const EditFieldMultiSelectAsyncCreatable: React.FC<EditFieldMultiSelectAsyncCreatableProps> = ({
  fieldName,
  value,
  record,
  fieldKey,
  hidePenIcon,
  service,
  successMessage,
  errorMessage,
  asyncSearchEndpoint,
  isFromGrid,
  getPopupContainer,
  isDynamicPopoverHeight,
  destroyOnHide,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record.company_id,
    companyUrl: record.company_url,
    service,
    successMessage,
    errorMessage,
  });
  const { updateHistoryFields } = useHistoryTab();
  const { loadOptions } = useAsyncSearch({ endpoint: asyncSearchEndpoint });
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const popoverContentRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectOption>>([]);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const title = `Edit "${fieldName}" value`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCallback = (data?: SuccessErrorCallback, showHistory?: boolean) => {
    if (data && showHistory) {
      const beforeValue = Array.isArray(value) && value.length ? value.join(',') : 'None';

      updateHistoryFields({
        afterValue:
          Array.isArray(selectedOptions) && !selectedOptions.length
            ? 'None'
            : selectedOptions.map(option => option.value).join(','),
        beforeValue,
        fieldChanged: fieldName,
      });
    }
  };

  const handleSave = useCallback(() => {
    const mappedValues = selectedOptions.reduce((acc: string[], curr: SelectOption<string>) => {
      if (curr.value !== '') {
        acc.push(curr.value);
      }

      return acc;
    }, []);

    const alphabeticalMappedValues = mappedValues.sort((a, b) => {
      return a.localeCompare(b);
    });

    updateField(alphabeticalMappedValues, fieldKey, undefined, handleCallback);
    setClosePopup(true);
  }, [handleCallback, selectedOptions, updateField, fieldKey]);

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.trim().length) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  const values = useMemo(
    () => value.length && !value.includes(undefined as never) && value.join(', '),
    [value]
  );

  const handleDefaultValues = useCallback(() => {
    setSelectedOptions(
      (value as string[]).map(item => ({
        value: item,
        label: item,
      }))
    );
  }, [value]);

  const getIsValueChanged = () => {
    if (selectedOptions.length !== value.length) return false;

    return value.every(item => selectedOptions.some(option => option.value === item));
  };

  useEffect(() => {
    handleDefaultValues();
  }, [handleDefaultValues]);

  useOnClickOutside(popoverContentRef, () => {
    handleDefaultValues();
  });

  const handleCancel = useCallback(() => {
    setClosePopup(true);
    handleDefaultValues();
  }, [handleDefaultValues]);

  const handleDropdownKeyEvent = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (!closePopup) {
      setFetchedOptions([]);
    }
  }, [closePopup]);

  const content = (
    <div
      className="addon-popover"
      role="presentation"
      onKeyDown={handleDropdownKeyEvent}
      ref={containerRef}
      tabIndex={0}
    >
      <MultiSelectWrapper>
        <AsyncMultiSelect
          className="multiselect-async-creatable"
          onChange={(options, action) => {
            const optionSet = new Set(
              (options as SelectOption[]).map(option => option.value.trim())
            );
            const updatedOptions = Array.from(optionSet).map(option => ({
              value: option,
              label: option,
            }));
            setSelectedOptions(updatedOptions);
          }}
          isValidNewOption={(
            inputValue: string,
            value: SelectOption[],
            options: SelectOption[]
          ) => {
            const itExists = options.some(option => inputValue.trim() === option.value.trim());

            return inputValue.trim() && !itExists;
          }}
          formatCreateLabel={(inputValue: string) => {
            return <span>Create "{inputValue.trim()}"</span>;
          }}
          openMenuOnFocus
          loadOptions={getLoadOptionsDelay}
          defaultOptions={fetchedOptions}
          defaultValue={selectedOptions}
          loadingMessage={({ inputValue }) =>
            inputValue.trim().length ? 'Loading...' : 'Begin typing'
          }
          closeMenuOnSelect={false}
          onMenuClose={() => containerRef.current && containerRef.current.focus()}
          blurInputOnSelect={false}
          controlShouldRenderValue
          maxLength={500}
          closeOnSelect
          isCreatable
          inputValue={inputValue}
          onInputChange={(newValue: string, { action }: InputActionMeta) => {
            if (action === 'input-change') {
              setInputValue(newValue);
            } else if (action === 'menu-close') {
              setInputValue('');
            }
          }}
          onKeyDown={e => handleDropdownKeyEvent(e as React.KeyboardEvent<HTMLElement>)}
        />
      </MultiSelectWrapper>
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          disabled={getIsValueChanged()}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );

  if (!isFromGrid) {
    return (
      <EditPopover
        content={content}
        title={title}
        hidePenIcon={hidePenIcon}
        onVisibilityChange={handleCancel}
        getPopupContainer={getPopupContainer}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        isDynamicPopoverHeight={isDynamicPopoverHeight}
        destroyOnHide={destroyOnHide}
      >
        {hidePenIcon ? (
          'Edit Field'
        ) : values ? (
          <TruncateTooltip className="edit-field--text" title={values}>
            {values}
          </TruncateTooltip>
        ) : (
          <span>{DEFAULT_EMPTY_VALUE}</span>
        )}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditCellPen
          content={content}
          title={title}
          closePopup={closePopup}
          onVisibilityChange={handleCancel}
          setClosePopup={setClosePopup}
          placement={'topLeft'}
        />
      }
      fill
    >
      {values ? (
        <DefaultTooltip title={values}>
          <Styled.TableCellInner>{values}</Styled.TableCellInner>
        </DefaultTooltip>
      ) : (
        <Styled.TableCellInner>{DEFAULT_EMPTY_VALUE}</Styled.TableCellInner>
      )}
    </InlineEdit>
  );
};

export default React.memo(EditFieldMultiSelectAsyncCreatable);
