import { Dictionary, cloneDeep } from 'lodash';
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { UserInformation } from '@optx/models/user';
import { FilterMultiTextOption, SelectOption } from '@optx/models/Option';
import { PreselectedFilter } from '@optx/models/filters';
import {
  CustomValue,
  EditFieldSuccess,
  UpdateFieldsPayload,
  MultipleFieldsValue,
} from '@optx/features/company/edit-fields/state/interfaces';
import { EditCompanyFund } from '@optx/components/feature/company-individual-edit/state/fund/interfaces';
import { PipelineReportCompanies, PipelineReportCompaniesColumns } from '../models';
import { ColumnWidth } from '@optx/models/table/Columns';
import { Addon, OpportunityPresentation } from '@optx/models/Company';
// constants
import {
  columnDisplayInitialStatePipelineGrid,
  initialColumnOrderPipelineGrid,
} from '@optx/constants/table/columnDisplay/company-search';
// utils
import { mapPreselectedFilters } from '@optx/utils/filters/preselectedValues';
import { injectFilter } from '@optx/utils/filters/injectFilter';
import { normalizeFiltersByColumn } from '@optx/utils/filters/normalize';
import { parseFilter } from '@optx/utils/filters/parseFilters';
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import { fetchReducer } from '@redux/feature/fetch/reducers';
import { actions as fundActions } from '@components/feature/company-individual-edit/state/fund';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as companyEditFields } from '@features/company/edit-fields/state';
import { actions as opportunityPresentationActions } from '@redux/company/opportunity-presentation';
// local
import {
  PipelineReportState,
  PipelineReportFundAssociation,
  PipelineReportCardResponse,
  PipelineReportSingleSelectPayload,
  PipelineReportResetFilterPayload,
  PipelineReportFetchFiltersSuccessPayload,
} from './interfaces';
import * as actions from './actions';

const initialState: PipelineReportState = {
  companies: {
    data: [],
    count: 0,
    columnDisplay: cloneDeep(columnDisplayInitialStatePipelineGrid),
    columnOrder: initialColumnOrderPipelineGrid,
    loading: true,
  },
  filters: {
    data: [],
    filter: {},
    values: {},
    clear: {},
    preselected: {},
    persistedFilter: '',
    normalized: {},
    fetchedAt: null,
  },
  fundAssociation: [],
  totalFund: 0,
  cardHidden: false,
  opportunities: [],
  loading: false,
  error: '',
  modalIsOpen: false,
  saveSearchLoading: false,
};

const fetchPipelineReportCompanyReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<
    boolean | undefined | PipelineReportFundAssociation | PipelineReportSingleSelectPayload
  >
> = (draftState, action) => {
  if (
    !action.payload &&
    (!action.payload as unknown as PipelineReportFundAssociation)?.isFundEdited
  ) {
    fetchReducer(draftState);
  }
};

const fetchPipelineReportCardReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<boolean | undefined | PipelineReportSingleSelectPayload>
> = (draftState, action) => {
  draftState.loading = true;
  draftState.error = '';
};

const fetchPipelineReportCompanyFailReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<string>
> = (draftState, action) => {
  draftState.companies.loading = false;
  draftState.error = action.payload;
};

const fetchPipelineReportCardFailReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<string>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.error = action.payload;
};

const fetchFiltersSuccessReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<PipelineReportFetchFiltersSuccessPayload>
> = (draftState, action) => {
  const { filters, defaultScore } = action.payload;

  const sourceData = filters[0].data;
  const sources = filters.flatMap(item => ({ ...item, data: sourceData }));
  const preselected = mapPreselectedFilters(sources, true, 2);

  draftState.filters.preselected = preselected;
  const clearedFilter = mapPreselectedFilters(sources, false);
  const normalized = normalizeFiltersByColumn(filters);
  // @ts-ignore for now
  draftState.filters.normalized = normalized;

  const defaultAnalysts: PreselectedFilter = normalized.company_owner_id
    ? normalized.company_owner_id?.data
    : [];
  const defaultFund = normalized.fund.data;

  if (draftState.filters.persistedFilter !== undefined) {
    const persistedFilter = draftState.filters.persistedFilter;

    const [parsedFilters] = parseFilter(persistedFilter, filters);

    const parsedFiltersData = {
      ...parsedFilters,
      company_owner_id: parsedFilters.company_owner_id
        ? parsedFilters.company_owner_id
        : parsedFilters.company_owner_id === undefined &&
          persistedFilter.includes('company_owner_id')
        ? []
        : defaultAnalysts,
      ...(Array.isArray(parsedFilters.fund) && parsedFilters.fund?.length !== 0
        ? { fund: parsedFilters.fund }
        : { fund: defaultFund }),
      ...(parsedFilters.is_addon ? { is_addon: parsedFilters.is_addon } : {}),
      country: undefined,
    };

    draftState.filters.values = parsedFiltersData;
    draftState.filters.filter = parsedFiltersData;
  }

  if (draftState.filters.persistedFilter !== '') {
    const preselectedData = {
      ...preselected,
      company_owner_id:
        (preselected.company_owner_id as FilterMultiTextOption[]).length === 0
          ? defaultAnalysts
          : preselected.company_owner_id,
      country: defaultScore === 'il' ? 'Israel' : 'All Regions',
      ...(Array.isArray(preselected.fund) && preselected.fund?.length !== 0
        ? { fund: preselected.fund }
        : { fund: defaultFund }),
    };
    draftState.filters.values = preselectedData;
    draftState.filters.filter = preselectedData;
  }

  draftState.filters.clear = {
    ...clearedFilter,
    company_owner_id: normalized.company_owner_id.data,
    country: defaultScore === 'il' ? 'Israel' : 'All Regions',
    fund: defaultFund,
  };
  draftState.filters.preselected = {
    ...preselected,
    company_owner_id: draftState.filters.filter.company_owner_id,
    country: defaultScore === 'il' ? 'Israel' : 'All Regions',
  };
  draftState.filters.data = sources;

  draftState.error = '';
};

const fetchETCompaniesSuccessReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<{ companies: PipelineReportCompanies[]; total_count: number }>
> = (draftState, action) => {
  const { companies, total_count: count } = action.payload;

  // companies
  draftState.companies.data = companies;
  draftState.companies.count = count;
  // standard
  draftState.error = '';
  draftState.companies.loading = false;
};

const fetchPipelineReportCardSuccessReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<PipelineReportCardResponse>
> = (draftState, action) => {
  const {
    fund_association: fundAssociation,
    total_fund: totalFund,
    opportunities,
  } = action.payload;

  // opportunities
  draftState.opportunities = opportunities;

  // fund Association
  draftState.fundAssociation = fundAssociation;
  draftState.totalFund = totalFund;

  // standard
  draftState.error = '';
  draftState.loading = false;
};

const clearFiltersReducer: CaseReducer<PipelineReportState> = draftState => {
  draftState.filters.filter = draftState.filters.clear;
  draftState.filters.values = draftState.filters.clear;
};

const resetFilterReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<PipelineReportResetFilterPayload>
> = (draftState, action) => {
  const { tag, defaultScore } = action.payload;
  const { normalized, clear, filter } = draftState.filters;
  fetchReducer(draftState);

  if (tag.filter === 'country') {
    const newRegion = defaultScore === 'il' ? 'Israel' : 'All Regions';

    draftState.filters.filter.fund = undefined;
    draftState.filters.filter.country = newRegion;

    draftState.filters.values.fund = undefined;
    draftState.filters.values.country = newRegion;
  } else {
    const newFilters = injectFilter(normalized, filter, clear, tag.filter, tag.optionId);
    draftState.filters.filter = newFilters;
    draftState.filters.values = newFilters;
  }
};

const toggleFiltersModalReducer: CaseReducer<PipelineReportState> = draftState => {
  draftState.modalIsOpen = !draftState.modalIsOpen;
};

const updateValueReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<PipelineReportSingleSelectPayload>
> = (draftState, action) => {
  const { key, value } = action.payload.filter;

  const newData = { [key]: value };

  const { filters } = draftState;
  const { filter, values } = filters;

  const deleteFundAndCountry = () => {
    delete filter.fund;
    delete values.fund;
    delete filter.country;
    delete values.country;
  };

  const deleteIlOptxScore = () => {
    delete filter.is_il_optx_score;
    delete values.is_il_optx_score;
  };

  if (key === 'is_il_optx_score') {
    deleteFundAndCountry();
    newData.country = 'Israel';
  } else if (key === 'fund') {
    deleteIlOptxScore();
    deleteFundAndCountry();
  } else if (key === 'country') {
    deleteIlOptxScore();

    if ((value as string).includes('Europe')) {
      newData.country = 'Europe';
    } else if (value === '') {
      newData.country = 'All Regions';
    } else {
      newData.country = 'North America + Israel';
    }
  }

  draftState.filters.filter = { ...draftState.filters.filter, ...newData };
  draftState.filters.values = { ...draftState.filters.values, ...newData };
  draftState.loading = true;
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<
  PipelineReportState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const { columnDisplay, columnOrder } = draftState.companies;
  const columnWidths = action.payload.settings.column_widths_bi_weekly_pipeline_report;
  const sessionSettings = action.payload.settings.session_settings;
  const persistedFilter = sessionSettings ? sessionSettings.pipeline_report : undefined;
  const hiddenCard = sessionSettings.bi_weekly_pipeline_report_show_cards
    ? sessionSettings.bi_weekly_pipeline_report_show_cards
    : false;
  const persistedRegionFilter = sessionSettings
    ? sessionSettings.bi_weekly_pipeline_report_region
    : undefined;

  draftState.cardHidden = hiddenCard;

  if (persistedFilter !== undefined) {
    draftState.filters.persistedFilter = persistedFilter;
  }

  if (persistedRegionFilter !== undefined) {
    draftState.filters.filter.country = persistedRegionFilter;
    draftState.filters.values.country = persistedRegionFilter;
  }

  const columns = { columnDisplay, columnOrder };

  if (!Object.keys(columnWidths).length) {
    const newColumns = { ...columns.columnDisplay };

    columnOrder.forEach(columnId => {
      if (columnDisplay[columnId].width !== columnDisplayInitialStatePipelineGrid[columnId].width) {
        newColumns[columnId as PipelineReportCompaniesColumns].width =
          columnDisplayInitialStatePipelineGrid[columnId].width;
      }
    });

    columns.columnDisplay = newColumns;
  } else {
    const newColumns = { ...columns.columnDisplay };

    columnOrder.forEach(columnId => {
      if (columnWidths[columnId]) {
        newColumns[columnId as PipelineReportCompaniesColumns].width = columnWidths[columnId];
      } else {
        newColumns[columnId as PipelineReportCompaniesColumns].width =
          columnDisplayInitialStatePipelineGrid[columnId].width;
      }
    });

    columns.columnDisplay = newColumns;
  }
};

const resetToDefaultReducer: CaseReducer<PipelineReportState> = draftState => {
  draftState.filters.filter = draftState.filters.preselected;
  draftState.filters.values = draftState.filters.preselected;
};

const applyFiltersReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<Dictionary<PreselectedFilter>>
> = (draftState, action) => {
  draftState.filters.filter = action.payload;
  draftState.filters.values = action.payload;
  draftState.loading = true;
};

const loadingActiveReducer: CaseReducer<PipelineReportState> = draftState => {
  draftState.saveSearchLoading = true;
};

const loadingDeActiveReducer: CaseReducer<PipelineReportState> = draftState => {
  draftState.saveSearchLoading = false;
};

const updateFieldsSuccessReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<EditFieldSuccess>
> = (draftState, action) => {
  const { companyId, fieldName, value } = action.payload;
  const companyIndex: number = draftState.companies.data.findIndex(
    item => item.company_id === companyId
  );

  if (companyIndex !== -1) {
    const optionSelected = (value as UpdateFieldsPayload).value as SelectOption;
    const rationale = (value as UpdateFieldsPayload).rationale;

    if (!Array.isArray(fieldName)) {
      if (fieldName === 'Asking Amount') {
        draftState.companies.data[companyIndex].asking_amount = (value as UpdateFieldsPayload)
          .value as string;
      }

      if (fieldName === 'Lead Source') {
        draftState.companies.data[companyIndex].lead_source = optionSelected.label;
      }

      if (fieldName === 'Stage') {
        draftState.companies.data[companyIndex].stage = optionSelected.label;

        if (showRationaleForStage(optionSelected.label)) {
          draftState.companies.data[companyIndex].stage_rationale = rationale as string;
        } else {
          draftState.companies.data[companyIndex].stage_rationale = null;
        }
      }

      if (fieldName === 'Fund') {
        const company = draftState.companies.data[companyIndex];
        const fundList = draftState.filters.filter.fund as string[] | undefined;
        const fund = optionSelected.label;

        company.fund = fund;

        if (fund === 'PEP Flagship' || (fundList && !fundList.includes(fund))) {
          draftState.companies.data.splice(companyIndex, 1);
        }
      }

      if (fieldName === 'Add-on') {
        const optionalValue = ((value as UpdateFieldsPayload).value as CustomValue).optionalValue;

        if (optionalValue === null) {
          draftState.companies.data[companyIndex].addon = null;
          draftState.companies.data[companyIndex].addon_company_id = null;
        } else {
          draftState.companies.data[companyIndex].addon = optionalValue as Addon;
          draftState.companies.data[companyIndex].addon_company_id = (
            (value as UpdateFieldsPayload).value as CustomValue
          ).additionalValue as number;
        }
      }

      if (fieldName === 'Website Status') {
        draftState.companies.data[companyIndex].url_status = optionSelected
          ? 'active'
          : 'not active';
      }

      if (fieldName === 'Next Steps') {
        draftState.companies.data[companyIndex].next_steps = optionSelected.label;
        draftState.companies.data[companyIndex].next_steps_opinion = (value as UpdateFieldsPayload)
          .extraValues
          ? ((value as UpdateFieldsPayload).extraValues as string[])[0]
          : null;
      }
    } else {
      if (fieldName.includes('Stage')) {
        const optionSelected = (value as MultipleFieldsValue).value.Stage;

        draftState.companies.data[companyIndex].stage = optionSelected.label;

        if (showRationaleForStage(optionSelected.label)) {
          draftState.companies.data[companyIndex].stage_rationale = rationale as string;
        } else {
          draftState.companies.data[companyIndex].stage_rationale = null;
        }
      }
    }
  }
};

const addCompanyFundSuccessReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<EditCompanyFund>
> = (draftState, action) => {
  const { companyId, fundName } = action.payload;

  const companyIndex: number = draftState.companies.data.findIndex(
    item => item.company_id === companyId
  );

  if (companyIndex !== -1) {
    draftState.companies.data[companyIndex].fund = fundName;
  }
};

export const gridResetColumnWidthsReducer = (draftState: PipelineReportState) => {
  draftState.companies.columnDisplay = cloneDeep(columnDisplayInitialStatePipelineGrid);
};

export const saveColumnWidthReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<ColumnWidth<PipelineReportCompaniesColumns>>
> = (draftState, action) => {
  const { columnId, width } = action.payload;

  if (columnId && draftState.companies.columnDisplay[columnId]) {
    draftState.companies.columnDisplay[columnId].width = width;
  }
};

export const updateValueAskingAmount: CaseReducer<
  PipelineReportState,
  PayloadAction<OpportunityPresentation>
> = (draftState, action) => {
  const { company_id: companyId, deal_preparation } = action.payload;

  if (deal_preparation) {
    const { asking_amount: askingAmount } = deal_preparation;

    const companyIndex = draftState.companies.data.findIndex(item => item.company_id === companyId);

    if (companyIndex !== -1) {
      draftState.companies.data[companyIndex].asking_amount = askingAmount as string;
    }
  }
};

export const fetchPipelineReportCompanyCountSuccessReducer: CaseReducer<
  PipelineReportState,
  PayloadAction<number>
> = (draftState, action) => {
  draftState.companies.count = action.payload;
};

export const hideCardsToggleReducer: CaseReducer<PipelineReportState> = draftState => {
  draftState.cardHidden = !draftState.cardHidden;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchPipelineReportCompany, fetchPipelineReportCompanyReducer)
    .addCase(actions.fetchPipelineReportCompanySuccess, fetchETCompaniesSuccessReducer)
    .addCase(actions.fetchPipelineReportCompanyFail, fetchPipelineReportCompanyFailReducer)
    .addCase(actions.fetchPipelineReportCard, fetchPipelineReportCardReducer)
    .addCase(actions.fetchPipelineReportCardSuccess, fetchPipelineReportCardSuccessReducer)
    .addCase(actions.fetchPipelineReportCardFail, fetchPipelineReportCardFailReducer)
    .addCase(
      actions.fetchPipelineReportCompanyCountSuccess,
      fetchPipelineReportCompanyCountSuccessReducer
    )
    // filters
    .addCase(actions.fetchFilters, fetchReducer)
    .addCase(actions.fetchFiltersSuccess, fetchFiltersSuccessReducer)
    .addCase(actions.clearFilters, clearFiltersReducer)
    .addCase(actions.resetFilter, resetFilterReducer)
    .addCase(actions.updateValue, updateValueReducer)
    .addCase(actions.resetToDefault, resetToDefaultReducer)
    .addCase(actions.applyFilters, applyFiltersReducer)
    // save search
    .addCase(actions.saveSearchAsList, loadingActiveReducer)
    .addCase(actions.saveSearchAsListSuccess, loadingDeActiveReducer)
    .addCase(actions.saveSearchAsListFail, loadingDeActiveReducer)
    // external
    .addCase(companyEditFields.updateFieldsSuccess, updateFieldsSuccessReducer)
    .addCase(fundActions.addFundSuccess, addCompanyFundSuccessReducer)
    // ui
    .addCase(actions.toggleFiltersModal, toggleFiltersModalReducer)
    // actions
    // columns
    .addCase(actions.saveColumnWidth, saveColumnWidthReducer)
    .addCase(actions.resetColumnWidths, gridResetColumnWidthsReducer)
    // card hide
    .addCase(actions.hideCardsToggle, hideCardsToggleReducer)
    // External reducers
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
    .addCase(
      opportunityPresentationActions.updateOpportunityPresentationSuccess,
      updateValueAskingAmount
    )
);

export default reducer;
