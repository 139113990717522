import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// models
import { AddedCompany } from '../models';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { getNewRoute } from '../utils';
// components
import { Styled } from './AddCompanyModal.styled';

interface AddCompanyStepCompletedProps {
  addedCompany: AddedCompany;
}

const AddCompanyStepCompleted: React.FC<AddCompanyStepCompletedProps> = ({ addedCompany }) => {
  const removeButtonFocus = (e: React.FocusEvent<HTMLAnchorElement>) => {
    if (e.target.classList.contains('ant-btn')) {
      setTimeout(() => e.target.blur());
    }
  };

  return (
    <Styled.StepCompleted>
      <Row className="spacer-bottom">
        <Col span={24}>
          <CheckCircleFilled />
        </Col>
      </Row>

      <Row className="spacer-bottom">
        <Col span={24}>
          <Typography.Title>"{addedCompany.company_name}" has been added</Typography.Title>
          <Typography.Text>
            It may take up to 5 minutes for the company to show in Equity Touch
          </Typography.Text>
        </Col>
      </Row>

      <Row className="spacer-bottom">
        <Col span={24} className="spacer-bottom">
          <Link
            to={() =>
              getNewRoute(
                addedCompany,
                { addContacts: true },
                `?tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}&addContacts=true`
              )
            }
            onFocus={removeButtonFocus}
          >
            <Button>Add Contacts</Button>
          </Link>
        </Col>

        <Col span={24} className="spacer-bottom">
          <Link
            to={() =>
              getNewRoute(
                addedCompany,
                { addTouch: true },
                `?tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}&addTouch=true`
              )
            }
            onFocus={removeButtonFocus}
          >
            <Button>Add Touch</Button>
          </Link>
        </Col>

        <Col span={24} className="spacer-bottom">
          <Link
            to={() =>
              getNewRoute(addedCompany, null, `?tab=${CompanyProfileTabs.PROPRIETARY_INFO}`)
            }
            onFocus={removeButtonFocus}
          >
            <Button>Add Proprietary Info</Button>
          </Link>
        </Col>

        <Col span={24} className="spacer-bottom">
          <Link to={() => getNewRoute(addedCompany, null, '', true)} onFocus={removeButtonFocus}>
            <Button>Add to Sales Loft Cadence</Button>
          </Link>
        </Col>
      </Row>
    </Styled.StepCompleted>
  );
};

export default React.memo(AddCompanyStepCompleted);
