import { createSelector } from 'reselect';
// constants
import { DEFAULT_SORTING_USER_SETTINGS } from '@optx/constants/filters';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as companyFiltersSelectors } from '../../company/filters';
import { selectors as userInformationSelectors } from '../../user/information';
import { selectors as savedSearchesSelector } from '../../company/saved-searches';

export const shouldInitialSearch = createSelector(
  userInformationSelectors.getSessionSettings,
  userInformationSelectors.loaded,
  companyFiltersSelectors.filtersLoaded,
  searchSelectors.shouldInitialFetch('advancedSearch'),
  savedSearchesSelector.loaded,
  userInformationSelectors.getDefaultViewId,
  (
    persistFilters,
    loadedUserInfo,
    loadedFilters,
    shouldFetchCompanies,
    savedSearchLoaded,
    defaultViewId
  ) => {
    if (
      !defaultViewId &&
      ((!persistFilters.company_filters && !persistFilters.company_sorting) ||
        (persistFilters.company_filters === '' &&
          persistFilters.company_sorting === DEFAULT_SORTING_USER_SETTINGS))
    ) {
      return loadedUserInfo && shouldFetchCompanies;
    }

    return loadedUserInfo && loadedFilters && shouldFetchCompanies && savedSearchLoaded;
  }
);
