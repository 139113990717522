import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
import { Button, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
//constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
//utils
import { normalizeCommaValues } from '@optx/utils/utils';
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
// components
import CheckboxIcon from './CheckboxIcon';
import EditPopover from '@optx/components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import Styled from './MultiSelectField.styled';

const { Option } = Select;

interface MultiSelectFieldProps {
  field?: BaseField;
  value?: string[];
  fieldName?: string;
  fieldType?: string;
  options?: OptionTypeBase[];
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  field,
  value,
  fieldName,
  fieldType,
  options,
  isChromePlugin,
  onSave,
  onCancel,
  fieldUsedFor,
}) => {
  const [selected, , helpers] = useField<string[]>(field?.id || fieldType || '');
  const [closePopup, setClosePopup] = useState(false);

  const handleChange = (value: string, type: 'select' | 'deselect') => {
    if (type === 'select') {
      // somehow the value has null || undefined value, just filtered
      const selectedValue = selected.value.filter(v => v !== null && v !== undefined);

      helpers.setValue([...selectedValue, value]);
    } else if (type === 'deselect') {
      const updatedSelected = selected.value.filter(option => option !== value);
      helpers.setValue([...updatedSelected]);
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      helpers.setValue(value);
    }

    if (!value && visibility) {
      helpers.setValue([]);
    }
  };

  const handleConfirmationValues = () => {
    helpers.setValue(selected.value);
  };

  useEffect(() => {
    if (fieldUsedFor !== 'editAllInfo' && (!selected.value || !selected.value.length)) {
      if (field?.value && Array.isArray(field.value)) {
        if (!isEqual(field.value, selected.value)) {
          helpers.setValue(field.value);
        }
      } else if (value && Array.isArray(value)) {
        helpers.setValue(value);
      } else {
        helpers.setValue([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  if (field) {
    return (
      <>
        <Styled.SelectWrapper span={8} className={field.id}>
          <Typography.Text>{field.label}</Typography.Text>
          <FormItem name={field.id}>
            <Select
              showSearch
              showArrow
              name={field.id}
              value={selected.value}
              menuItemSelectedIcon={CheckboxIcon}
              onSelect={(value, option) => handleChange(value, 'select')}
              onDeselect={(value, option) => handleChange(value, 'deselect')}
              optionFilterProp="children"
              mode="multiple"
              getPopupContainer={trigger => trigger.parentElement!}
              disabled={!field.editable}
              tagRender={props => {
                return <span>{props.label || ''}</span>;
              }}
              filterOption={(input: string, option: OptionTypeBase | undefined) => {
                const fullName = `${option?.children.props.children}`;

                return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              fast
            >
              {field.data?.map((option: OptionTypeBase) => (
                <Option
                  key={option.value as string}
                  value={option.value as string}
                  className="left-checkbox-option"
                >
                  <span>{option.label}</span>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Styled.SelectWrapper>
        <Styled.ContactSelectGlobal />
      </>
    );
  }

  const content = (
    <Styled.SelectWrapper>
      <FormItem name={fieldType ?? ''}>
        <div
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
            handleDropdownKeyEvent(
              event,
              setClosePopup,
              onSave,
              fieldType,
              undefined,
              handleConfirmationValues
            )
          }
          tabIndex={0}
        >
          <Typography.Text>Edit "{fieldName}" value</Typography.Text>
          <Select
            autoFocus
            defaultOpen
            showSearch
            showArrow
            style={{ width: '300px' }}
            name={fieldType ?? ''}
            value={selected.value}
            menuItemSelectedIcon={CheckboxIcon}
            onSelect={(value, option) => handleChange(value, 'select')}
            onDeselect={(value, option) => handleChange(value, 'deselect')}
            optionFilterProp="children"
            mode="multiple"
            getPopupContainer={trigger => trigger.parentElement!}
            tagRender={props => {
              return <span>{props.label || ''}</span>;
            }}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = `${option?.children.props.children}`;

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {options?.map((option: OptionTypeBase) => (
              <Option
                key={option.value as string}
                value={option.value as string}
                className="left-checkbox-option"
              >
                <span>{option.label}</span>
              </Option>
            ))}
          </Select>
          <div className="profile-information__popover-buttons">
            <Button className="profile-information__cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="profile-information__save"
              type="primary"
              disabled={checkSaveButton(selected.value, value)}
              onClick={() => {
                onSave && onSave(fieldType);
                setClosePopup(true);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </FormItem>
      <Styled.ContactSelectGlobal />
    </Styled.SelectWrapper>
  );

  return (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      customOverlayStyle={{ width: '480px' }}
      content={content}
      onVisibilityUpdate={handleVisibilityChange}
      destroyOnHide={true}
      getPopupContainer={trigger => trigger.parentNode?.parentNode as HTMLElement}
    >
      <TruncateTooltip title={value?.length ? normalizeCommaValues(value) : DEFAULT_EMPTY_VALUE}>
        {value?.length ? normalizeCommaValues(value) : DEFAULT_EMPTY_VALUE}
      </TruncateTooltip>
    </EditPopover>
  );
};

export default React.memo(MultiSelectField);
