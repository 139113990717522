import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  SyntheticEvent,
  useMemo,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVT } from 'virtualizedtableforantd4';
import { TableProps } from 'antd/es/table';
import { useSize } from '@umijs/hooks';
import { ResizeCallbackData } from 'react-resizable';
import { ColumnType } from 'antd/lib/table';
// models
import { PipelineReportCompaniesColumns } from '../../models';
// redux
import { selectors, actions } from '@optx/features/pipeline-report/state';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
// hooks
import {
  VirtualTableScrollYContext,
  useVirtualScrollPosition,
} from '@optx/features/scroll-history';
import useIsMount from '@optx/common/hooks/useIsMount';
import useNonVirtualizedTable from '@optx/common/hooks/useNonVirtualizedTable';
import useTableLayoutHeight from '@optx/common/hooks/useTableLayoutHeight';
// components
import Table from '@shared/view/organims/Table';
import { GridPagination } from '@optx/features/grid/pagination/components';
import defaultColumns from './columns';
import { ResizeColumn } from '@optx/features/company-search/lib/Table/columns';
import { Styled } from './Grid.styled';

const paginationId = 'pipeline-report-pagination';

const PipelineTable: React.FC<TableProps<any>> = props => {
  const dispatch = useDispatch();

  const data = useSelector(selectors.pipelineReportCompanies);
  const reduxColumns = useSelector(selectors.getColumns);
  const totalItemsCount = useSelector(selectors.companiesCount);
  const { pageNumber, pageSize } = useSelector(paginationSelectors.getPagination('pipelineReport'));

  const vtImperativeScroll = useRef<any>();
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const [tableHeader] = useSize(document.querySelector('.ant-table-header') as HTMLElement);
  const [mainHeaderHeight] = useSize(document.getElementById('mainHeader'));

  const virtualizedActive = useNonVirtualizedTable();
  const isMount = useIsMount();
  const { onScroll, initialScrollPosition } = useVirtualScrollPosition('pipelinereport');

  const [windowSize] = useSize(window.document.documentElement);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [columns, setColumns] = useState(defaultColumns);

  /**
   * Each time new pagination is done - resets the scroll.
   */
  useEffect(() => {
    if (!isMount) {
      vtImperativeScroll.current?.scrollTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = useCallback(
    (pageNumber: number, perPage: number = pageSize) =>
      dispatch(
        paginationActions.changePagination({
          gridKey: 'pipelineReport',
          data: { pageNumber, pageSize: perPage },
        })
      ),
    [dispatch, pageSize]
  );

  const ctx = useContext(VirtualTableScrollYContext);

  useEffect(() => {
    // fix table being cropped when navigating to other pages and coming back
    setTimeout(() => {
      vtImperativeScroll.current?.scrollTo(initialScrollPosition);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [vt, setVt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll]
  );

  const { tableScroll } = useTableLayoutHeight(
    tableWrapperRef.current,
    paginationId,
    windowSize.height,
    tableHeader.height,
    mainHeaderHeight.height,
    [ctx.height]
  );

  useEffect(() => {
    setCurrentScroll(tableScroll);
  }, [tableScroll]);

  const handleResizeStop = (e: SyntheticEvent, data: ResizeCallbackData) => {
    dispatch(
      actions.saveColumnWidth({
        userSetting: 'column_widths_bi_weekly_pipeline_report',
        columnId: data.node.offsetParent?.classList[1] as PipelineReportCompaniesColumns,
        width: data.size.width,
      })
    );
  };

  useMemo(
    () =>
      setVt({
        header: {
          cell: props => {
            return <ResizeColumn handleResizeStop={handleResizeStop} {...props} />;
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVt]
  );

  const updateFinalColumns = (columns: ColumnType<any>[]) => {
    return columns.map((col: any, index: number) => ({
      ...col,
      onHeaderCell: (column: any) => ({
        width: column.width,
        height: 60,
        onResize: handleResize(index),
      }),
      ...(virtualizedActive
        ? {}
        : {
            onCell: () => ({
              style: {
                'max-width': '409px',
                'min-width': '120px',
              },
            }),
          }),
    }));
  };

  const mergedColumns = useMemo(() => {
    return updateFinalColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, virtualizedActive]);

  const handleResize = (index: number) => (e: any, other: any) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: other.size.width,
    };

    setColumns(nextColumns);
  };

  useEffect(() => {
    // @ts-ignore
    setColumns(reduxColumns);
  }, [reduxColumns]);

  return (
    <Styled.CompanyTableWrapper>
      <div
        className="pipeline-report-grid"
        ref={tableWrapperRef}
        style={{ marginTop: 20, paddingBottom: 20 }}
      >
        <Table
          size="small"
          rowKey={record => record.company_id}
          columns={mergedColumns}
          className="pipeline-table"
          dataSource={data}
          bordered
          pagination={false}
          showSorterTooltip={false}
          {...props}
          // virtualization
          scroll={{ x: true, y: currentScroll }}
          components={virtualizedActive ? vt : undefined}
        />
      </div>
      <GridPagination
        onPageChange={handlePageChange}
        id={paginationId}
        totalItemsCount={totalItemsCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </Styled.CompanyTableWrapper>
  );
};

export default PipelineTable;
