import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { CompanyProfile, SocialMediaProps } from '@optx/models/Company';

/**
 * Create the structure for all social media icons.
 */
export const socialMediaMapping = (company: CompanyProfile) => {
  const socialMediaList: Array<SocialMediaProps> = [];

  if (company.raw_url) {
    socialMediaList.push({
      title: 'Website',
      url: company.raw_url,
      icon: 'website',
    });
  }

  if (company.linkedin && company.linkedin !== DEFAULT_EMPTY_VALUE) {
    socialMediaList.push({
      title: 'LinkedIn',
      url: `//${company.linkedin}`,
      icon: 'linkedin',
    });
  }

  if (company.facebook_url) {
    socialMediaList.push({
      title: 'Facebook',
      url: `//${company.facebook_url}`,
      icon: 'facebook',
    });
  }

  if (company.twitter_url) {
    socialMediaList.push({
      title: 'Twitter',
      url: `//${company.twitter_url}`,
      icon: 'twitter',
    });
  }

  if (company.links && company.links['Equity Touch']) {
    socialMediaList.push({
      title: 'EquityTouch',
      url: company.links!['Equity Touch'],
      icon: 'equitytouch',
    });
  }

  if (company.links && company.links['Source Scrub']) {
    socialMediaList.push({
      title: 'Source Scrub',
      url: company.links!['Source Scrub'],
      icon: 'sourcescrub',
    });
  }

  if (company.links && company.links['G2 Crowd']) {
    socialMediaList.push({
      title: 'G2Crowd',
      url: company.links!['G2 Crowd'],
      icon: 'g2crowd',
    });
  }

  if (company.links && company.links.SimilarWeb && company.links.SimilarWeb) {
    socialMediaList.push({
      title: 'Similar Web',
      url: company.links!.SimilarWeb,
      icon: 'similarweb',
    });
  }

  if (company.links && company.links.Orbis) {
    socialMediaList.push({
      title: 'BVD Orbis',
      url: company.links!.Orbis,
      icon: 'bvdorbis',
    });
  }

  if (company.links && company.links.Capterra) {
    socialMediaList.push({
      title: 'Capterra',
      url: company.links!.Capterra,
      icon: 'capterra',
    });
  }

  if (company.links && company.links.Crunchbase) {
    socialMediaList.push({
      title: 'Crunchbase',
      url: company.links!.Crunchbase,
      icon: 'crunchbase',
    });
  }

  if (company.links && company.links.Grata) {
    socialMediaList.push({
      title: 'Grata',
      url: company.links!.Grata,
      icon: 'grata',
    });
  }

  if (company.links && company.links.Pitchbook) {
    socialMediaList.push({
      title: 'Pitchbook',
      url: company.links!.Pitchbook,
      icon: 'pitchbook',
    });
  }

  return socialMediaList;
};
