import React, { useEffect, useState, ChangeEvent, useMemo, useRef } from 'react';
import { Button, Checkbox, Typography, Input, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// redux
import { actions } from '@redux/company/opportunity-presentation';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useAutoFocusCheckbox from '@optx/common/hooks/useAutoFocusCheckbox';
// components
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import EditPopover from '@optx/components/common/popover/EditPopover';

const { Text } = Typography;

const Error = styled(Text)`
  position: absolute;
  bottom: 70px;
  left: 16px;
`;

const GlobalBankerFieldStyle = createGlobalStyle`
.ant-popover.banker_field {
.ant-popover-inner-content {
   padding: 0;
  }
}
`;

interface EditFieldCheckboxTextProps {
  companyId: number;
  checkValue: boolean | null;
  textValue: string | undefined;
  checkboxKey: string;
  textInputKey: string;
  isCheckbox?: boolean;
  isFromHistory?: boolean;
  actionPendo?: string;
  isFromGrid?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

const EditFieldCheckboxText: React.FC<EditFieldCheckboxTextProps> = ({
  companyId,
  checkValue,
  textValue,
  checkboxKey,
  textInputKey,
  isCheckbox,
  isFromHistory,
  isFromGrid,
  actionPendo,
  getPopupContainer,
}) => {
  const dispatch = useDispatch();
  const popoverContentRef = useRef(null);
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  const [isFocus, setIsFocus] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [checked, setChecked] = useState<boolean>(!!checkValue);
  const [fieldValue, setFieldValue] = useState<string | undefined>(textValue ?? undefined);

  const label = useMemo(() => {
    if (!isFromGrid && isCheckbox) {
      return !!textValue ? 'Yes' : 'No';
    }

    if (
      !textValue &&
      ((isCheckbox && (checkValue === false || checkValue === null)) ||
        (!isCheckbox && textValue === undefined))
    ) {
      return DEFAULT_EMPTY_VALUE;
    }

    return textValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue, checkValue]);

  const disabledSave = useMemo(() => {
    const conditionChecked =
      checked &&
      (!fieldValue ||
        (checked === checkValue && textValue === fieldValue) ||
        textValue === fieldValue);

    const conditionNotChecked = !checked && !textValue;

    return conditionChecked || conditionNotChecked;
  }, [checked, fieldValue, checkValue, textValue]);

  useAutoFocusCheckbox(checkboxRef, isFocus);

  useEffect(() => {
    setChecked(!!textValue);
  }, [checkValue, textValue]);

  useEffect(() => {
    setFieldValue(textValue);
  }, [textValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value);
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;

    if (!value) {
      setFieldValue('');
    }

    setChecked(value);
  };

  const handleCancel = () => {
    setFieldValue(textValue);
    setChecked(!!textValue);
    setClosePopup(true);
    setIsFocus(false);
  };

  const handleSave = () => {
    if (fieldValue !== textValue || checked !== checkValue) {
      const payload = {
        company_id: companyId,
        deal_preparation: {
          [checkboxKey]: checked,
          [textInputKey]: fieldValue || null,
        },
      };
      dispatch(actions.updateOpportunityPresentation(payload, isFromHistory));
    }

    setClosePopup(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  const content = (
    <div ref={popoverContentRef}>
      <div className="ant-popover-title">Edit "Banker" value</div>
      <Space direction="vertical" style={{ padding: '12px 16px' }}>
        <Checkbox
          ref={el => {
            checkboxRef.current = el;
          }}
          checked={checked}
          onChange={handleCheckbox}
          autoFocus={!isFromGrid}
        >
          Banker
        </Checkbox>
        <div style={{ width: '300px' }} onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
          <Text>Name of Banker</Text>

          <Input
            type="text"
            placeholder="Enter text"
            onChange={handleChange}
            value={fieldValue as string | undefined}
            disabled={!checked}
            style={{ marginBottom: '10px' }}
          />
          {checked && !fieldValue && <Error type="danger">Input a banker name</Error>}
          <div className="profile-information__popover-buttons">
            <Button className="profile-information__cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="profile-information__save"
              type="primary"
              onClick={handleSave}
              disabled={disabledSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Space>
    </div>
  );

  if (isFromHistory) {
    return (
      <>
        <EditPopover
          content={content}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
          actionPendo={actionPendo}
          hidePenIcon
          destroyOnHide
          getPopupContainer={getPopupContainer}
          overlayClassName="banker_field"
        >
          Edit Field
        </EditPopover>
        <GlobalBankerFieldStyle />
      </>
    );
  }

  if (!isFromGrid) {
    return (
      <>
        <EditPopover
          content={content}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
          actionPendo={actionPendo}
          destroyOnHide
          getPopupContainer={getPopupContainer}
          overlayClassName="banker_field"
        >
          {isFromHistory ? 'Edit Field' : <TruncateTooltip title={label}>{label}</TruncateTooltip>}
        </EditPopover>
        <GlobalBankerFieldStyle />
      </>
    );
  }

  return (
    <InlineEdit
      action={
        <>
          <EditCellPen
            content={content}
            closePopup={closePopup}
            setClosePopup={setClosePopup}
            actionPendo={actionPendo}
            getPopupContainer={getPopupContainer}
            overlayClassName="banker_field"
            onVisibilityShow={() => {
              setIsFocus(true);
            }}
            destroyOnHide={false}
          />
          <GlobalBankerFieldStyle />
        </>
      }
      fill
    >
      {isFromHistory ? (
        'Edit Field'
      ) : (
        <TruncateTooltip title={label}>
          <span>{label}</span>
        </TruncateTooltip>
      )}
    </InlineEdit>
  );
};

export default React.memo(EditFieldCheckboxText);
