import React from 'react';
// models
import Option, { FormCheckableRangeOption, FilterMultiSelectOption } from '@optx/models/Option';
import {
  BaseFilter,
  RangeFilter,
  MultiselectAsyncFilter,
  MultiRangeNumberFilter,
  MultiSelectFilter,
  MultiCheckboxFilter,
  Filter,
  FilterSource,
} from '../../../../models/filters';
// components
import FilterCheckbox from './FilterCheckbox';
import FilterDateRange from './FilterDateRange';
import FilterMultiRange from './FilterMultiRange';
import FilterMultiSelect from './FilterMultiSelect';
import FilterMultiSelectHelper from './FilterMultiSelectHelper';
import FilterMultiSelectAsync from './FilterMultiSelectAsync';
import FilterSingleSelectHelper from './FilterSingleSelectHelper';
import FilterRange from './FilterRange';
import FilterLogicCheckbox from './FilterLogicCheckbox';
import FilterMultiText from './FilterMultiText';
import FilterRadio from './FilterRadio';
import FilterGroup from './FilterGroup';
import FilterGeoRange from './FilterGeoRange';
import FilterMultiSelectGroups from './FilterMultiSelectGroups';
import FilterGroupCustom from './FilterGroupCustom/FilterGroupCustom';

interface FilterFactoryProps {
  source: FilterSource<Filter<any>>;
  filter: BaseFilter;
  isAddon?: boolean;
  autoFocus?: boolean;
}

const FilterFactory: React.FC<FilterFactoryProps> = ({
  source,
  filter,
  isAddon,
  autoFocus = false,
}) => {
  switch (filter.type) {
    case 'checkbox':
      return <FilterCheckbox filter={filter as MultiCheckboxFilter} />;

    case 'logic_checkbox':
      return <FilterLogicCheckbox filter={filter} />;
    case 'range_input':
      return <FilterRange filter={filter as RangeFilter<Array<FormCheckableRangeOption>>} />;
    case 'date_range':
      return <FilterDateRange filter={filter as RangeFilter<null>} />;

    case 'multi_select': {
      const isGroup: boolean = !!filter.data[0]?.group;
      if (isGroup)
        return (
          <FilterMultiSelectGroups
            filter={filter as MultiSelectFilter<Array<Option>>}
            source={source}
          />
        );

      return (
        <FilterMultiSelect
          filter={filter as MultiSelectFilter<Array<Option>>}
          source={source}
          isAddon={isAddon}
        />
      );
    }

    case 'multi_select_helper':
      return (
        <FilterMultiSelectHelper
          filter={filter as MultiSelectFilter<Array<FilterMultiSelectOption>>}
          autoFocus={autoFocus}
        />
      );
    case 'single_select_helper':
      return (
        <FilterSingleSelectHelper
          filter={filter as MultiSelectFilter<Array<Option>>}
          autoFocus={autoFocus}
        />
      );
    case 'single_select':
      return (
        <FilterSingleSelectHelper
          filter={filter as MultiSelectFilter<Array<Option>>}
          autoFocus={autoFocus}
        />
      );
    case 'endpoint_query':
      return <FilterMultiSelectAsync filter={filter as MultiselectAsyncFilter} />;
    case 'multiple_range_nr':
      return <FilterMultiRange filter={filter as MultiRangeNumberFilter} />;
    case 'multi_text':
      return <FilterMultiText filter={filter} />;
    case 'radio':
      return <FilterRadio filter={filter} />;
    case 'filter_group':
      return <FilterGroup filter={filter} source={source} />;
    case 'filter_group_georange':
      return <FilterGeoRange filter={filter} source={source} />;
    case 'filter_group_custom':
      return <FilterGroupCustom filter={filter} source={source} />;
    default:
      return null;
  }
};

export default FilterFactory;
