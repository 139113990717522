import { memo, useCallback, useEffect, useState } from 'react';
import { FormikContextType, useFormik, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// constants
import { AUTOSAVE_DEBOUNCE_IN_MS, SAVE_FREQUENCY_IN_MINUTES } from '@optx/constants/autosave';
// services
import NotificationService from '@optx/services/NotificationService';
// utils
import { getSavedValues, saveValues } from '@optx/storage/localStorage/autoSaveStorage';
// redux
import * as actions from '../state/actions';
// hooks
import { useDebounceEffect } from '@optx/common/hooks/useDebounceEffect';

interface AutoSaveProps {
  storageKeyPrefix: string;
  companyId: number;
}

type FormikType = FormikContextType<any> | ReturnType<typeof useFormik>;

const useAddTouchAutoSave = (
  formik: FormikType,
  storageKeyPrefix: string,
  companyId: number
): void => {
  const dispatch = useDispatch();

  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [lastSave, setLastSave] = useState<moment.Moment | null>(null);

  const { values, setValues, initialValues, isSubmitting } = formik;
  const currentValues = JSON.stringify(values);

  const handleSave = useCallback(() => {
    const isInitialValues = currentValues === JSON.stringify(initialValues);
    const isDifferentFromSaved = currentValues !== getSavedValues(storageKeyPrefix, companyId);

    if (!isInitialValues && isDifferentFromSaved && !isFirstSave) {
      dispatch(actions.updateAutosaveStatus('saving'));
      saveValues(storageKeyPrefix, companyId, values);
      setLastSave(moment());

      setTimeout(() => {
        dispatch(actions.updateAutosaveStatus('saved'));
      }, 1500);
    }

    // ignores first handleSave call that runs when component renders
    if (isFirstSave) {
      setIsFirstSave(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues, getSavedValues]);

  useEffect(() => {
    const savedValues = getSavedValues(storageKeyPrefix, companyId);

    if (savedValues) {
      try {
        const persistedValues = JSON.parse(savedValues);
        const newValues = { ...initialValues, ...persistedValues };

        if (currentValues !== JSON.stringify(newValues)) {
          setValues(newValues);
          dispatch(actions.updateAutosaveStatus('saved'));
        }
      } catch (error) {
        NotificationService.error('Failed to load autosaved data into the form!');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSavedValues]);

  useDebounceEffect(
    () => {
      if (
        !lastSave ||
        moment().diff(lastSave, 'minutes', true) >= SAVE_FREQUENCY_IN_MINUTES ||
        isSubmitting
      ) {
        handleSave();
      }
    },
    AUTOSAVE_DEBOUNCE_IN_MS,
    // @ts-ignore
    [handleSave, lastSave, isSubmitting]
  );
};

const AutoSave: React.FC<AutoSaveProps> = ({ storageKeyPrefix, companyId }) => {
  const formik = useFormikContext();

  useAddTouchAutoSave(formik, storageKeyPrefix, companyId);

  return null;
};

export default memo(AutoSave);
