import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';
// redux
import { selectors } from '@features/pipeline-report/state';
// components
import Cards from '@optx/components/common/cards';
import { HorizontalBarChart } from '@optx/features/charts/';

const matchData = {
  platform: { title: 'New PSG Platform Opportunities', labelKey: 'label', dataKey: 'platform' },
  addon: { title: 'New PSG Add-On Opportunities', labelKey: 'label', dataKey: 'addon' },
};

const OpportunitiesBarChart = () => {
  const data = useSelector(selectors.pipelineReportOpportunities);

  return (
    <Cards $transparent className="opportunities-chart-wrapper">
      <Row gutter={8}>
        {['platform', 'addon'].map((item: string, index: number) => {
          const { title, dataKey, labelKey } = matchData[item as keyof typeof matchData];

          return (
            <Col span={12} key={index}>
              <Typography.Title level={5} style={{ marginLeft: 19 }}>
                {title}
              </Typography.Title>
              <HorizontalBarChart
                labelKey={labelKey}
                data={data}
                dataKey={dataKey}
                chartHeight={42 * data.length} // each item height is 24px
              />
            </Col>
          );
        })}
      </Row>
    </Cards>
  );
};

export default OpportunitiesBarChart;
