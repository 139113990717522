import React from 'react';
import { Typography, Popover } from 'antd';
import { SalesloftBulkContactResponse, SalesloftBulkResultCellUrl } from '@models/bulkActions';
import { SalesloftContact } from '@optx/models/Salesloft';

interface SalesloftUrlProps {
  contactsResponse: SalesloftBulkContactResponse[];
  sentContacts: SalesloftContact[];
}

const SalesloftUrl: React.FC<SalesloftUrlProps> = ({ contactsResponse, sentContacts }) => {
  const successfulContacts = contactsResponse.filter(contact => contact.sl_url);
  const succesfulResults: SalesloftBulkResultCellUrl[] = [];

  successfulContacts.forEach(contact => {
    const sentContact = sentContacts.find(
      sentContact => sentContact.person_id === contact.person_id
    );
    succesfulResults.push({
      name: `${sentContact?.first_name} ${sentContact?.last_name}`,
      url: contact.sl_url as string,
    });
  });

  const content = succesfulResults.map((result, index) => (
    <div key={index}>
      <Typography.Link href={result.url} target="_blank">
        {result.name}
      </Typography.Link>
    </div>
  ));

  if (succesfulResults.length === 1) {
    return (
      <Typography.Link ellipsis href={succesfulResults[0].url} target="_blank">
        {succesfulResults[0].name}
      </Typography.Link>
    );
  }

  return (
    <Popover content={content}>
      <Typography.Text ellipsis style={{ width: '100%' }}>
        {succesfulResults.map((result, index) => (
          <div key={index}>
            <Typography.Link href={result.url} target="_blank">
              {result.name}
            </Typography.Link>
            {succesfulResults.length - 1 > index && ', '}
          </div>
        ))}
      </Typography.Text>
    </Popover>
  );
};

export default SalesloftUrl;
