import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
// constants
import {
  numberRegExp,
  numberBigRegExp,
  numberBigRegExpEndingDot,
  regexAbbreviation,
} from '@constants/regex';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { numberToMillions } from '@optx/utils/number';

interface InputNumberFieldProps {
  id: string;
  value: string | number;
}

const InputNumberField: React.FC<InputNumberFieldProps> = ({ id, value }) => {
  const [field, , fieldHelpers] = useField<number | string | null>(id);
  const [amount, setAmount] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    if (id === 'etCheck') {
      if (
        numberBigRegExp.test(inputValue) ||
        inputValue === '' ||
        inputValue === DEFAULT_EMPTY_VALUE ||
        regexAbbreviation.test(inputValue)
      ) {
        const isInputValueAbbreviated = regexAbbreviation.test(inputValue);
        setAmount(isNaN(parseInt(inputValue)) && !isInputValueAbbreviated ? '' : inputValue);
      } else if (numberBigRegExpEndingDot.test(inputValue)) {
        setAmount(inputValue);
      }
    } else {
      if (
        numberRegExp.test(inputValue) ||
        regexAbbreviation.test(inputValue) ||
        inputValue === ''
      ) {
        setAmount(inputValue);
      }
    }
  };

  useEffect(() => {
    if (id === 'etCheck') {
      setAmount(
        field.value === null || isNaN(Number(field.value))
          ? ''
          : (Number(field.value)! / 1000000).toString()
      );
    } else {
      setAmount(field.value ? String(field.value) : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (id === 'etCheck') {
      const normalizedAmount = typeof amount === 'string' ? amount : '';

      if (amount && numberBigRegExp.test(amount)) {
        if (!isEqual(field.value, Number(numberToMillions(amount).toFixed(2)))) {
          fieldHelpers.setValue(Number(numberToMillions(amount).toFixed(2)), true);
        }
      } else if (amount && numberBigRegExpEndingDot.test(amount)) {
        const amountRemovedDot = amount.slice(0, -1);
        fieldHelpers.setValue(Number(numberToMillions(amountRemovedDot).toFixed(2)));
      } else if (amount === '' || amount === DEFAULT_EMPTY_VALUE) {
        if (field.value !== null) {
          fieldHelpers.setValue(null);
        }
      } else if (regexAbbreviation.test(normalizedAmount)) {
        fieldHelpers.setValue(amount);
      } else {
        fieldHelpers.setValue(Number(amount));
      }
    } else {
      if (typeof amount === 'string' && regexAbbreviation.test(amount)) {
        fieldHelpers.setValue(amount);
      } else {
        fieldHelpers.setValue(Number(amount));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => {
    if (field.value) {
      const fieldValue = field.value;
      const fieldValueAsStr = typeof fieldValue === 'string' ? fieldValue : '';

      let newAmount;

      if (id === 'etCheck') {
        if (isNaN(fieldValue as number) && !regexAbbreviation.test(fieldValueAsStr as string)) {
          newAmount = '';
        } else if (regexAbbreviation.test(fieldValueAsStr as string)) {
          newAmount = fieldValueAsStr;
        } else {
          newAmount = (Number(fieldValue)! / 1000000).toString();
        }
      } else {
        newAmount = fieldValue.toString();
      }

      if (newAmount !== amount) {
        setAmount(newAmount as string);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <Input
      value={amount ?? ''}
      onChange={handleChange}
      placeholder=""
      name={id}
      onBlur={() => {
        setTimeout(() => {
          fieldHelpers.setTouched(true, true);
        });
      }}
    />
  );
};

export default React.memo(InputNumberField);
