import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Typography } from 'antd';
import numeral from 'numeral';
import { merge } from 'lodash';
import { TextProps } from 'recharts';
import { LoadingOutlined } from '@ant-design/icons';
//models
import { GridKeys } from '@optx/models/grid';
import { SearchViewType } from '@optx/models/user';
//utils
import { getPageSize } from '@optx/utils/table/pagination';
//redux
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { selectors as paginationLongCardSelectors } from '@features/grid/pagination-long-card';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as filterCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { isLoading } from '@components/pages/MyCompanies/selectors';
//components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { searchCountFullScreenStyle, searchCountOwnStyles } from './SearchCountOwnStyles';

interface SearchCountProps extends TextProps {
  gridName: GridKeys;
  searchViewType?: SearchViewType;
  contactsSearchName?: string;
  isFullScreen?: boolean;
}

const SearchCount: React.FC<SearchCountProps> = ({
  gridName,
  style,
  isFullScreen,
  contactsSearchName,
  searchViewType = 'table',
  ...restProps
}) => {
  const searchCompanyCount = useSelector(searchCountSelectors.getSearchCount(gridName));
  const loadingCount = useSelector(searchCountSelectors.getSearchCountLoading(gridName));
  const searchName = useSelector(searchSelectors.getSearchName(gridName));
  const isLoadingPage = useSelector(isLoading);
  const selectedCard = useSelector(filterCardsSelectors.getSelectedCard);
  const tableViewPagination = useSelector(paginationSelectors.getPagination(gridName));
  const paginationLongCardView = useSelector(
    paginationLongCardSelectors.getLongCardPagination(gridName)
  );
  const pageNumber = useSelector(paginationSelectors.getPageNumber(gridName));

  const { pageSize } =
    searchViewType === 'table'
      ? tableViewPagination
      : paginationLongCardView || tableViewPagination;

  const title = `Showing ${searchName || contactsSearchName || ''}`;

  const actualPageSize = getPageSize(pageSize, searchCompanyCount, pageNumber);
  const formattedSearchCompanyCount = numeral(searchCompanyCount).format('0,0');
  const gridType =
    searchCompanyCount !== 1
      ? `${gridName !== 'contacts' ? 'Companies' : 'Contacts'}`
      : `${gridName !== 'contacts' ? 'Company' : 'Contact'}`;

  const subTitle = `${actualPageSize} of ${formattedSearchCompanyCount} ${gridType}`;

  const styleProps: React.CSSProperties = merge(
    style,
    isFullScreen ? searchCountFullScreenStyle : searchCountOwnStyles[gridName],
    {}
  );

  if (isFullScreen) {
    return (
      <Typography.Text title={`${title} (${subTitle})`} ellipsis style={styleProps} {...restProps}>
        {loadingCount ? (
          <>Loading...</>
        ) : (
          <>
            Showing{' '}
            {searchName && (
              <TruncateTooltip title={searchName}>
                <span
                  style={{
                    color: '#1890ff',
                  }}
                >
                  &quot;{searchName}&quot;
                </span>
              </TruncateTooltip>
            )}{' '}
            ({loadingCount ? <LoadingOutlined style={{ color: '#4ce1b6' }} /> : `${subTitle}`})
          </>
        )}
      </Typography.Text>
    );
  }

  if (gridName === 'pipelineReport') {
    return (
      <Col>
        <div className="text-ellipsis">
          <Typography.Text className="pipeline-report-second-header__count">
            {isLoadingPage ? (
              <>Loading...</>
            ) : (
              <>
                <b>Active Companies</b>{' '}
                <span>
                  (Showing{' '}
                  {loadingCount ? (
                    <LoadingOutlined style={{ color: '#4ce1b6' }} />
                  ) : (
                    `${getPageSize(pageSize, searchCompanyCount, pageNumber)}
                  of ${numeral(searchCompanyCount).format('0,0')}`
                  )}
                  )
                </span>
              </>
            )}
          </Typography.Text>
        </div>
      </Col>
    );
  }

  if (gridName !== 'outreach' && gridName !== 'myCompanies') {
    const { color, fontSize } = styleProps;

    return (
      <>
        {loadingCount ? (
          <Typography.Text style={{ ...styleProps, flex: '1 1 0%' }} {...restProps}>
            Loading...
          </Typography.Text>
        ) : (
          <Col
            style={{
              ...styleProps,
              fontWeight: 'normal',
              display: 'flex',
              gap: '4px',
            }}
          >
            <Typography.Text
              className="text-ellipsis"
              style={{
                color,
                fontSize,
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                maxWidth: 'fit-content',
                flex: '1 1 auto',
              }}
              {...restProps}
            >
              Showing{' '}
              {(searchName || contactsSearchName) && (
                <TruncateTooltip
                  placement="topLeft"
                  title={`Showing "${searchName || contactsSearchName}" (${subTitle})`}
                  maxWidth={gridName === 'contacts' ? '200px' : '100%'}
                >
                  <span
                    style={{
                      color: '#1890ff',
                      fontWeight: 'Bold',
                    }}
                  >
                    &quot;{searchName || contactsSearchName}&quot;
                  </span>
                </TruncateTooltip>
              )}
            </Typography.Text>
            ({loadingCount ? <LoadingOutlined style={{ color: '#4ce1b6' }} /> : `${subTitle}`})
          </Col>
        )}
      </>
    );
  }

  if (gridName === 'myCompanies') {
    return (
      <Col>
        <div className="text-ellipsis">
          <Typography.Text className="my-companies-second-header__count">
            {isLoadingPage || selectedCard?.title === undefined ? (
              <>Loading...</>
            ) : (
              <>
                Showing (
                {loadingCount ? (
                  <LoadingOutlined style={{ color: '#4ce1b6' }} />
                ) : (
                  `${getPageSize(pageSize, searchCompanyCount, pageNumber)}
                  of ${numeral(searchCompanyCount).format('0,0')}
                  ${searchCompanyCount !== 1 ? 'Companies' : 'Company'}`
                )}
                ) {selectedCard?.title}
              </>
            )}
          </Typography.Text>
        </div>
      </Col>
    );
  }

  return (
    <Typography.Text style={styleProps}>
      {loadingCount ? (
        <>Loading...</>
      ) : (
        <>
          (Showing
          {loadingCount ? (
            <LoadingOutlined style={{ color: 'white' }} />
          ) : (
            ` ${actualPageSize} of ${formattedSearchCompanyCount}`
          )}
          )
        </>
      )}
    </Typography.Text>
  );
};

export default SearchCount;
