import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';
import classnames from 'classnames';
import { useField } from 'formik';
import { isEqual } from 'lodash';
//models
import { SelectOption } from '@optx/models/Option';
import { FieldOptions } from '@optx/features/company/edit-fields/state/interfaces';
// redux
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state/index';
// components
import { SingleSelect } from '@shared/view/molecules/Select';
import SingleValueContainer from '@optx/shared/view/molecules/Select/SingleSelect/components/SingleValueContainer';

interface CustomSelectProps {
  type: FieldOptions;
  initialValue: SelectOption;
  setNewValue: (value: SelectOption, type: FieldOptions) => void;
  isError: boolean;
  disabled?: boolean;
}

const CustomSelect: FC<CustomSelectProps> = ({
  type,
  initialValue,
  setNewValue,
  isError,
  disabled,
}) => {
  const [selected, , helpers] = useField(type);

  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const companyStageOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.stageOptions
  );
  const companyRankOptions = useSelector(individualEditSelectors.companyIndividualEdit.rankOptions);

  const companyOwnerTypesOptions = useMemo(() => {
    const normalizedCompanyOwnerOptions = [
      { label: 'Unowned', value: 'blank' },
      ...companyOwnerOptions,
    ];

    return normalizedCompanyOwnerOptions.map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }, [companyOwnerOptions]);

  const stageTypesOptions = useMemo(() => {
    const normalizedCompanyStageOptions = [
      { label: 'None', value: 'blank' },
      ...companyStageOptions,
    ];

    return normalizedCompanyStageOptions.map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }, [companyStageOptions]);

  const rankTypesOptions = useMemo(() => {
    const normalizedCompanyRankOptions = [{ label: 'None', value: 'blank' }, ...companyRankOptions];

    return normalizedCompanyRankOptions.map(({ label, value }) => ({
      label: label.toString(),
      value: value.toString(),
    }));
  }, [companyRankOptions]);

  let selectOptions: any[] = [];
  let placeHolder: string = '';

  switch (type) {
    case 'Stage':
      selectOptions = stageTypesOptions;
      placeHolder = 'Stage';
      break;
    case 'Company Owner':
      selectOptions = companyOwnerTypesOptions;
      placeHolder = 'PSG Owner';
      break;
    case 'Pipeline Rank':
      selectOptions = rankTypesOptions;
      placeHolder = 'Rank';
      break;
  }

  const handleChange = (value: ValueType<SelectOption<string>>) => {
    setNewValue(value as SelectOption, type);
    helpers.setValue(value as SelectOption);
  };

  useEffect(() => {
    if (initialValue && !selected.value) {
      helpers.setValue(initialValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (selected.value && !isEqual(selected.value, initialValue)) {
      setNewValue(selected.value, type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.value]);

  return (
    <div
      className={classnames('add-touch-select', { 'is-error': isError, 'is-disabled': disabled })}
    >
      <SingleSelect
        options={selectOptions}
        onChange={handleChange}
        value={initialValue}
        onBlur={() => {}}
        styles={{
          valueContainer: () => ({
            display: 'flex',
            alignItems: 'center',
            marginLeft: '18px',
          }),
        }}
        components={{
          SingleValue: SingleValueContainer,
        }}
        SearchIcon={false}
        searchable={false}
        placeholder={null}
        valuePrefix={placeHolder}
        isDisabled={disabled}
      />
    </div>
  );
};

export default CustomSelect;
