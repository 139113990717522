import React, { useContext, Context, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from '@umijs/hooks';
import { useLocation } from 'react-router';
import { Table, Typography, Modal, Alert, Tooltip } from 'antd';
// models
import { ProductCategory } from '@optx/models/Company';
import {
  BulkEditFieldContext,
  SelectedCompanies,
  SelectedCompaniesKeys,
} from '@optx/models/bulkActions';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { MERGE_COMPANIES_LIMIT } from '@optx/config/bulkConfig';
// utils
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import { BULK_EDIT_DEFAULT_COLUMNS } from '@features/bulk-actions/edit-fields/state/constants';
import {
  resetProgress,
  updateProgress,
  bulkEditField,
  cancel,
} from '@features/bulk-actions/edit-fields/state/actions';
import { selectors as listSearchSelectors } from '@redux/lists/search/search';
import { actions as searchActions } from '@features/grid/search';
import { actions as addonActions } from '@redux/company/addon-management';
import {
  actions as addToWatchListActions,
  actions as removeFromWatchListActions,
} from '@features/bulk-actions/bulk-watchlist';
import { actions as bulkSoftwareActions } from '@features/bulk-actions/bulk-software';
import { actions as bulkAiMlActions } from '@features/bulk-actions/bulk-ai-ml';

import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';

import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';

import { actions as listsActions, selectors as listSelectors } from '@redux/favorite-lists/lists';

import { selectors as listsSelectors } from '@redux/lists/details';

import { actions as bulkMergeCompaniesActions } from '@optx/features/bulk-actions/bulk-merge-companies';
// components
import { BULK_MERGE_COMPANIES_COLUMNS } from '@optx/features/bulk-actions/bulk-merge-companies/constants';
import BulkEditCompletion from '../bulk-edit-progress/BulkEditCompletion';
// styles
import { Styled } from './BulkEditModal.styled';

interface BulkEditModalProps {
  title: string;
  visible: boolean;
  changeVisibility: (visible: boolean) => void;
  editField: SelectedCompaniesKeys;
  editFieldTitle: string;
  column: string;
  context: Context<BulkEditFieldContext>;
  selectedCompanies: SelectedCompanies[];
  isValid: boolean;
  validation: (isValid: boolean) => void;
}

const renderProductCategoriesTitle = (productCategory: ProductCategory[] | null | string[]) => {
  if (productCategory?.length === 0) {
    return DEFAULT_EMPTY_VALUE;
  }

  return (productCategory as ProductCategory[])?.map(category => category.category).join(', ');
};

export const BulkEditModal: React.FC<BulkEditModalProps> = ({
  title,
  visible,
  changeVisibility,
  editField,
  editFieldTitle,
  column,
  context,
  selectedCompanies,
  children,
  isValid,
  validation,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSourceScrub = useSelector(listsSelectors.isSourceScrubRoute);
  const { selectedData, setSelectedData, gridName } = useContext(context);
  const { rationaleValue } = useContext(context);
  const [confirmationVisibility, setConfirmationVisibility] = useState(false);
  const isInProgress = useSelector(bulkActionsSelectors.editFields.isInProgress);
  const listId = location.pathname.split('/')[2];
  const searchCompanyCount = useSelector(listSearchSelectors.getSearchCount(isSourceScrub));
  const isCompleted = useSelector(bulkActionsSelectors.editFields.isCompleted);
  const isAddonGridRefreshed = column === 'addon' && gridName === 'addonManagement';

  const isFetchingInProgress = useSelector(listSelectors.isLoading);
  const [isWatchlistStateUpdating, setIsWatchlistStateUpdating] = useState(isFetchingInProgress);

  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);

  const okText = useMemo(() => {
    switch (isCompleted) {
      case true:
        return 'Close';

      case false:
        if (editField === 'merge_companies') return 'Merge';
        if (editField === 'remove_from_watchlist' || editField === 'remove_from_addon_management')
          return 'Remove';

        return 'Add';

      default:
        return 'Add';
    }
  }, [editField, isCompleted]);

  useEffect(() => {
    setIsWatchlistStateUpdating(isFetchingInProgress);
  }, [isFetchingInProgress]);

  const selectedCompaniesNumber = useMemo(() => {
    const { length } = selectedCompanies;

    if (editField === 'merge_companies') {
      switch (true) {
        case length >= MERGE_COMPANIES_LIMIT:
          return MERGE_COMPANIES_LIMIT;

        default:
          return length;
      }
    } else {
      return length;
    }
  }, [editField, selectedCompanies]);

  const handleOk = () => {
    if (isCompleted) {
      dispatch(resetProgress());
      changeVisibility(false);
    } else if (isValid && editField === 'watchlist') {
      dispatch(updateProgress(true));
      dispatch(
        addToWatchListActions.bulkAddToWatchList({
          selectedCompanies,
          selectedData: selectedData as SelectOption<string>[],
          field: editField,
        })
      );
    } else if (isValid && editField === 'remove_from_watchlist') {
      dispatch(updateProgress(true));
      dispatch(
        removeFromWatchListActions.bulkRemoveFromWatchList({
          selectedCompanies,
          listId,
          listDetailCount: searchCompanyCount,
        })
      );
    } else if (isValid && editField === 'remove_from_addon_management') {
      dispatch(updateProgress(true));
      dispatch(
        addonActions.bulkRemoveAddon({
          selectedCompanies,
        })
      );
    } else if (isValid && editField === 'is_ai_ml') {
      dispatch(updateProgress(true));
      dispatch(
        bulkAiMlActions.bulkAiMl({
          selectedCompanies,
          isAiMl: selectedData === DEFAULT_EMPTY_VALUE ? null : (selectedData as boolean),
        })
      );
    } else if (isValid && ((selectedData as SelectOption[]).length || editField === 'rationale')) {
      dispatch(updateProgress(true));
      dispatch(
        bulkEditField({
          selectedCompanies,
          // special case for rationale, if user wants to send an empty value,
          // we must pass 'blank' text to the endpoint
          selectedData: (editField === 'rationale' && !(selectedData as SelectOption[]).length
            ? 'blank'
            : selectedData) as SelectOption[] | string,
          field: editField,
          ...(editField === 'stage' ? { rationale: rationaleValue } : {}),
        })
      );
    } else if (editField === 'merge_companies') {
      dispatch(updateProgress(true));
      dispatch(
        bulkMergeCompaniesActions.bulkMergeCompanies({
          selectedCompanies: selectedCompanies.slice(0, MERGE_COMPANIES_LIMIT),
        })
      );
    } else if (editField === 'is_software') {
      dispatch(updateProgress(true));
      dispatch(
        bulkSoftwareActions.bulkSoftware({
          selectedCompanies,
          isSoftware: selectedData as boolean,
          rationale: rationaleValue,
          isAdminOrAnalyst,
        })
      );
    }

    if (column === 'watchlist' && isCompleted) {
      dispatch(listsActions.resetFetchedAt());
    }

    if (column === 'remove_from_watchlist' && isCompleted) {
      setTimeout(() => {
        dispatch(searchActions.initialCompaniesSearch({ gridKey: 'lists', data: undefined }));
      }, 200);
    }

    if ((column === 'remove_from_addon_management' || isAddonGridRefreshed) && isCompleted) {
      dispatch(
        addonActions.fetchAdditionalFilters({
          companyId: Number(listId),
          shouldBeAddedToLoading: true,
          isBulkRemoved: true,
        })
      );
    }
  };

  const handleOkConfirmation = () => {
    dispatch(cancel(true));
    dispatch(customGlobalLoaderActions.toggle({ loading: true, customText: 'Canceling...' }));
    setConfirmationVisibility(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationVisibility(false);
  };

  useUnmount(() => {
    handleCancel();
  });

  useEffect(() => {
    if (isInProgress === false) {
      setConfirmationVisibility(false);
    }
  }, [isInProgress]);

  const handleCancel = () => {
    if (isInProgress) {
      setConfirmationVisibility(true);
    } else {
      dispatch(resetProgress());

      if (column === 'watchlist' && isCompleted) {
        dispatch(listsActions.resetFetchedAt());
      }

      if (column === 'remove_from_watchlist' && isCompleted) {
        setTimeout(() => {
          dispatch(searchActions.initialCompaniesSearch({ gridKey: 'lists', data: undefined }));
        }, 200);
      }

      setSelectedData([]);
      changeVisibility(false);
    }
  };

  const notDisplayedColumns = [
    'fund',
    'tags',
    'rationale',
    'remove_from_watchlist',
    'remove_from_addon_management',
    'is_software',
    'is_ai_ml',
    'watchlist',
  ];

  const defaultColumnRender = {
    dataIndex: column,
    title: editFieldTitle,
    render: (column: string) => (
      <Tooltip placement="topLeft" title={column}>
        {column}
      </Tooltip>
    ),
  };

  const customColumnRenders = {
    product_category: {
      dataIndex: ['product_category', 'category'],
      title: editFieldTitle,
      render: (column: string, record: SelectedCompanies) => (
        <Tooltip placement="topLeft" title={renderProductCategoriesTitle(record.product_category)}>
          {renderProductCategoriesTitle(record.product_category)}
        </Tooltip>
      ),
    },
    addon: {
      dataIndex: 'addon',
      title: editFieldTitle,
      render: (column: string, record: SelectedCompanies) =>
        Object.keys(record.addon || {}).join(', '),
    },
  };

  const columnRender =
    customColumnRenders[column as keyof typeof customColumnRenders] || defaultColumnRender;

  const mergeCompaniesColumns = [...BULK_MERGE_COMPANIES_COLUMNS];
  const columns = [
    ...BULK_EDIT_DEFAULT_COLUMNS,
    ...(!notDisplayedColumns.includes(column) ? [columnRender] : []),
  ];

  useEffect(() => {
    // when closing the modal reset selected data
    !visible && setSelectedData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (editField === 'merge_companies' && isCompleted) {
      changeVisibility(false);
      dispatch(resetProgress());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editField, isCompleted]);

  // sort selectedCompanies in bulkEditModal

  const sortedSelected = () => {
    const sortedSelectedCompanies = [...selectedCompanies];

    return sortedSelectedCompanies.sort((CompanyA, CompanyB) =>
      CompanyA.company_name > CompanyB.company_name ? 1 : -1
    );
  };

  const dataSource =
    editField === 'merge_companies'
      ? selectedCompanies.slice(0, MERGE_COMPANIES_LIMIT)
      : sortedSelected();

  return (
    <>
      <Styled.ModalWrapper
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={editField === 'is_software' ? 1045 : 840}
        okText={okText}
        okButtonProps={{
          loading: isInProgress || isWatchlistStateUpdating,
          disabled:
            (selectedData.toString() === '' && editField === 'watchlist') ||
            (editField === 'merge_companies' && dataSource.length !== MERGE_COMPANIES_LIMIT) ||
            (editField === 'stage' &&
              Array.isArray(selectedData) &&
              selectedData.length !== 0 &&
              showRationaleForStage((selectedData as SelectOption<string>[])[0].label) &&
              !rationaleValue),
        }}
        destroyOnClose
        isCompleted={isCompleted}
      >
        {(isInProgress || isCompleted) && editField !== 'merge_companies' ? (
          <BulkEditCompletion
            visibilityChange={setConfirmationVisibility}
            gridName={gridName}
            fieldName={editFieldTitle}
            editField={editField}
          />
        ) : (
          <Styled.RowWrapper>
            <Styled.BoxWrapper
              width={
                editField === 'merge_companies' ||
                editField === 'remove_from_watchlist' ||
                editField === 'remove_from_addon_management'
                  ? '100%'
                  : undefined
              }
            >
              <Typography.Text className="ant-modal-title">
                {selectedCompaniesNumber} Selected Companies
              </Typography.Text>
              <Table
                tableLayout="fixed"
                columns={editField === 'merge_companies' ? mergeCompaniesColumns : columns}
                rowKey={'company_id'}
                dataSource={dataSource}
                pagination={false}
                bordered
                className="legacy-table"
              />
              {editField === 'merge_companies' &&
                selectedCompanies.length !== MERGE_COMPANIES_LIMIT && (
                  <Alert
                    type="error"
                    message={`Only ${MERGE_COMPANIES_LIMIT} companies can be merged.`}
                    className="mt-4"
                  />
                )}
            </Styled.BoxWrapper>
            {editField !== 'merge_companies' &&
              editField !== 'remove_from_watchlist' &&
              editField !== 'remove_from_addon_management' && (
                <Styled.BoxWrapper $isStageField={editField === 'stage'}>
                  {children}
                </Styled.BoxWrapper>
              )}
          </Styled.RowWrapper>
        )}
      </Styled.ModalWrapper>
      <Modal
        title="Confirmation"
        visible={confirmationVisibility}
        onOk={handleOkConfirmation}
        onCancel={handleCancelConfirmation}
        destroyOnClose
      >
        Are you sure you want to cancel the operation?
      </Modal>
    </>
  );
};
