import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
import { actions as userInformationActions } from '@redux/user/information';
// components
import ToggleButton from '@optx/components/common/molecules/buttons/ToggleButton';

interface QuickFiltersToggleProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const QuickFiltersToggle: React.FC<QuickFiltersToggleProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);

  const handleClick = () => {
    dispatch(userInformationActions.updateUserSettings({ show_quick_filters: !open }));

    setOpen(!open);
  };

  if (isFullscreen) {
    return null;
  }

  const title = `${open ? 'Hide' : 'Show'} Quick Filters`;

  return <ToggleButton onClick={handleClick} hide={open} title={title} />;
};

export default QuickFiltersToggle;
