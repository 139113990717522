import { FormikValues } from 'formik';

/**
 * function that get Form data from autosave storage for a specific company
 * @param companyId company ID
 * @returns the stored string with values or null
 */
export const getSavedValues = (keyPrefix: string, companyId: number) => {
  return window.localStorage.getItem(`${keyPrefix}${companyId}`);
};

/**
 * function that saves Form data to autosave storage for a specific company
 * @param companyId company ID
 * @param values form values that will be saved (formik.values)
 */
export const saveValues = (keyPrefix: string, companyId: number, values: FormikValues) => {
  window.localStorage.setItem(`${keyPrefix}${companyId}`, JSON.stringify(values));
};

/**
 * function that deletes Form data from autosave storage for a specific company
 * @param companyId company ID
 */
export const deleteSavedValues = (keyPrefix: string, companyId: number) => {
  window.localStorage.removeItem(`${keyPrefix}${companyId}`);
};
