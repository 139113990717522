import styled from 'styled-components';

const CompanyActivityCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  min-height: 85px;
  padding: 18px 20px;
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 15px;
  margin-right: 20px;
  cursor: pointer !important;

  &:nth-child(2n) {
    margin-right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &:hover {
    cursor: auto;

    &::before {
      background-color: #1890ff;
    }

    .company > a.company_link {
      visibility: visible !important;
    }
  }

  .company-avatar {
    width: 50px;
    height: 50px;
    background: #ffffff;
    border-radius: 6px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    svg {
      width: 60%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-right: 10px;

    @media (1360px <= width < 1380px) {
      padding-right: 8px;
    }

    .company {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #1890ff;
      display: flex;
      gap: 8px;
      align-items: center;

      a.company_name {
        display: block;
      }

      a.company_link {
        color: #000;
        display: inline-block;
        visibility: hidden;

        &:hover {
          color: #007bff;
        }
      }
    }

    .location {
      color: #c2cfe0;
      font-size: 10px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .stats {
    display: flex;
    align-items: center;
    gap: 25px;

    @media (1360px <= width < 1380px) {
      gap: 14px;
    }

    .last-touch,
    .rank,
    .stage,
    .optx {
      font-weight: 600;
      font-size: 14px;
      text-align: right;
      text-transform: uppercase;
      margin: 0px auto;
    }

    .optx {
      span:last-child {
      }
      span:first-child {
        color: #1890ff;
        margin-right: 5px;
      }
    }

    div > span:last-child {
      font-weight: 600;
      font-size: 10px;
      text-align: right;
      text-transform: uppercase;
      color: #334d6e;
    }
  }
`;

const PSGCompanyLabel = styled.div`
  color: rgb(30, 58, 81);
  font: bold 32px 'Times New Roman';
`;

export default {
  CompanyActivityCard,
  PSGCompanyLabel,
};
