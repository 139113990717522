import React, { useMemo } from 'react';
import { Switch, Button, Radio } from 'antd';
import styled from 'styled-components';
import { RadioChangeEvent } from 'antd/lib/radio';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { CompanyPageOverviewPendo } from '@optx/constants/pendoActions';
// components
import { useActiveEditableField } from '@components/feature/company-individual-edit/hooks';
import EditPopover from '@optx/components/common/popover/EditPopover';

const Text = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #1890ff;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  height: fit-content;
`;

interface ProfileActiveProps {
  company: CompanyProfile;
  hidePenIcon?: boolean;
  labelValue?: string;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  isFromHistory?: boolean;
}

const ProfileActive: React.FC<ProfileActiveProps> = ({
  company,
  hidePenIcon,
  labelValue,
  getPopupContainer,
  isFromHistory,
}) => {
  const { addCompanyActive, loading } = useActiveEditableField(isFromHistory);
  const value = company.active;

  const handleSwitch = () => {
    window.pendo.track(CompanyPageOverviewPendo.active);
    addCompanyActive(company.company_id, !value);
  };

  const content = useMemo(() => {
    const onChange = (e: RadioChangeEvent) => {
      addCompanyActive(company.company_id, e.target.value);
    };

    return (
      <Radio.Group
        className="popover-radio ant-popover-content-wrapper"
        onChange={onChange}
        value={value}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, company, addCompanyActive]);

  if (labelValue !== undefined) {
    return (
      <EditPopover
        content={content}
        hidePenIcon={hidePenIcon}
        title='Edit "Active on Pipeline"'
        getPopupContainer={getPopupContainer}
      >
        {labelValue}
      </EditPopover>
    );
  }

  return (
    <FlexContainer>
      <Switch loading={loading} checked={value} onClick={handleSwitch} />
      <StyledButton type="text" onClick={handleSwitch} size="small">
        <Text>Active on Pipeline</Text>
      </StyledButton>
    </FlexContainer>
  );
};

export default React.memo(ProfileActive);
