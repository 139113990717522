import React, { useEffect, useMemo, useContext, useRef, useState } from 'react';
import { Button } from 'antd';
import { ValueType } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
// models
import { SelectOption } from '@optx/models/Option';
import { EditFieldSingleSelectProps } from '@optx/models/feature/edit-field/EditFieldSingleSelectProps';
import { AppState } from '@optx/redux/interfaces';
import { FieldValue } from '@features/company/audit-trail/state/interfaces';
import { ServiceType } from '@features/company/edit-fields/state/interfaces';
import { typeOptionsEditFieldSingleSelect } from '../state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSurName } from '@optx/utils/utils';
import { showRationaleForStage } from '@optx/utils/helpers';
import { renderLabel } from '@optx/utils/editFields';
// redux
import { selectors as filterSelectors } from '@redux/company/filters';
import { actions as opportunityPresentationActions } from '@redux/company/opportunity-presentation';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useUpdateFields from '@optx/features/company/edit-fields/hooks/useUpdateFields';
import { selectors as filtersSelector } from '@redux/company/filters';
import { getFormRationales, getNextSteps } from '@optx/redux/company/filters/selectors';
import { selectors } from '@components/feature/company-individual-edit/state/index';
// components
import SingleSelect from '@optx/components/common/select/SingleSelect';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
import RationaleSection from './RationaleSection';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
// styles
import { Styled as EditFieldStyled } from './EditFieldRadioRationale.styled';
import { Styled as TableCellStyled } from '@optx/features/company/edit-fields/components/TableCellFit.styled';
import Styled from './CellPopover.styled';

const fieldsWithoutBlankOption = [
  'Deal Type',
  'Customer Type',
  'Sales and Marketing',
  'Cloud Provider',
  'Cashflow',
];

const getBlankOptionLabel = (fieldName: typeOptionsEditFieldSingleSelect) => {
  switch (fieldName) {
    case 'Cloud Provider':
      return 'Unknown';
    case 'Deal Type':
    case 'Customer Type':
    case 'Sales and Marketing':
      return 'Unknown';

    default:
      return 'None';
  }
};

const EditFieldSingleSelect: React.FC<EditFieldSingleSelectProps> = ({
  record,
  fieldName,
  fieldKey,
  value,
  rationaleAfterValue,
  isFromGrid,
  hidePenIcon,
  isFromGridDropDown,
  isFromHistory,
  service,
  successMessage,
  dropdownLabel,
  useTooltip,
  dropdownPlacement,
  getPopupContainer,
  actionPendo,
}) => {
  const dispatch = useDispatch();
  const canBeRationale = fieldName === 'Stage';
  const blankOptionLabel = getBlankOptionLabel(fieldName);

  let currRationaleValue: string | null = null;

  if (record && canBeRationale) {
    if (!isFromHistory) {
      currRationaleValue = record.stage_rationale;
    } else {
      currRationaleValue = rationaleAfterValue as string | null;
    }
  }

  const [selectedValue, setSelectedValue] = useState<FieldValue>(checkSurName(value, fieldName));
  const [closePopUp, setClosePopUp] = useState<boolean>(false);
  const [rationaleValue, setRationaleValue] = useState<string | null>(currRationaleValue);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);
  const rationaleOptions = useSelector((state: AppState) => {
    switch (fieldName) {
      case 'Stage':
        return getFormRationales(state, 'stage_pass_dead_rationales') ?? [];
      default:
        return [];
    }
  });

  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    service: service as ServiceType,
    successMessage,
    isFromHistory,
  });

  const editFieldSingleSelectSelectors = {
    'Company Owner': selectors.companyIndividualEdit.companyOwnerOptions,
    'Deal Type': filtersSelector.getDealTypeOptions,
    'Senior Advisor Thesis': selectors.companyIndividualEdit.seniorAdvisorOptions,
    'Lead Source': selectors.companyIndividualEdit.leadSourceOptions,
    'Pipeline Rank': selectors.companyIndividualEdit.rankOptions,
    Stage: selectors.companyIndividualEdit.stageOptions,
    Sector: selectors.companyIndividualEdit.sectorOptions,
    'Sub-Sector': selectors.companyIndividualEdit.subSectorOptions,
    Fund: selectors.companyIndividualEdit.fundOptions,
    'Opportunity Type': selectors.companyIndividualEdit.opportunityTypeOptions,
    'Next Steps': getNextSteps,
    'Company Type': filterSelectors.companyTypeOptions,
    'Year Founded': selectors.companyIndividualEdit.yearFoundedOptions,
    'Customer Type': filtersSelector.getCustomerTypeOptions,
    Cashflow: filterSelectors.getCashflowOptions,
    'Cloud Provider': filterSelectors.getCloudProviderOptions,
    'Sales and Marketing': filtersSelector.getSalesAndMarketingOptions,
  };
  const isDefaultValue = fieldName !== 'Company Owner' ? DEFAULT_EMPTY_VALUE : 'Unowned';

  let label = value === null ? isDefaultValue : value;
  label = fieldName === 'Year Founded' ? `(Founded in ${label})` : label;

  const afterValue = useRef<SelectOption | undefined>();
  const isLoadingOptions = useSelector(selectors.companyIndividualEdit.isLoadingInfo);
  const options = useSelector(editFieldSingleSelectSelectors[fieldName]);
  const popoverContentRef = useRef(null);

  const { dropdownVisible, setDropdownVisible } = useContext(DropdownAddContext);

  const blankOption = fieldsWithoutBlankOption.includes(fieldName)
    ? []
    : [{ value: 'blank', label: blankOptionLabel }];

  const filteredOptions = options.filter(
    item => item.value !== 'blank' || fieldName !== 'Cashflow'
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectOptions = [...blankOption, ...filteredOptions];

  const handleOptionsList = useMemo(() => {
    if (['Fund', 'Company Type', 'Opportunity Type', 'Year Founded'].includes(fieldName)) {
      return selectOptions.filter(value => value.label !== blankOptionLabel);
    }

    return selectOptions;
  }, [fieldName, selectOptions, blankOptionLabel]);

  const handleInitialValue = useMemo(() => {
    if (fieldName === 'Fund' && (value === DEFAULT_EMPTY_VALUE || !value)) {
      return [];
    }

    return handleOptionsList[0];
  }, [fieldName, handleOptionsList, value]);

  const handleUnownedValue = (value: FieldValue) => {
    if (['Sector', 'Sub-Sector'].includes(fieldName) && value === blankOptionLabel) {
      return DEFAULT_EMPTY_VALUE;
    }

    if (fieldName === 'Fund' && !value) {
      return DEFAULT_EMPTY_VALUE;
    }

    return value;
  };

  let initialSelectValue;
  let valueForComparison;

  if (!canBeRationale) {
    valueForComparison = value;
  } else {
    valueForComparison = selectedValue;
  }

  initialSelectValue =
    !valueForComparison || valueForComparison === DEFAULT_EMPTY_VALUE
      ? handleInitialValue
      : handleOptionsList.find(
          option =>
            selectedValue?.toString().toLocaleLowerCase() === option.label?.toLocaleLowerCase()
        );

  const onChange = (newValue: ValueType<SelectOption>) => {
    const label = (newValue as SelectOption).label;

    if (canBeRationale && showRationaleForStage(label)) {
      setSelectedValue(label);

      if (label !== value && rationaleValue) {
        setRationaleValue(null);
      } else {
        setRationaleValue(currRationaleValue);
      }

      setSaveBtnDisabled(true);
      afterValue.current = newValue as SelectOption;

      return;
    }

    if (selectedValue?.toString() !== label) {
      if (!canBeRationale || (canBeRationale && label !== value)) {
        setSelectedValue(label);
        afterValue.current = newValue as SelectOption;

        if (
          (fieldName === 'Cashflow' ||
            fieldName === 'Cloud Provider' ||
            fieldName === 'Sales and Marketing') &&
          record
        ) {
          let fieldKey = '';
          let valueKey = '';

          if (fieldName === 'Cashflow') {
            fieldKey = 'financial';
            valueKey = 'cashflow';
          }

          if (fieldName === 'Cloud Provider') {
            fieldKey = 'technology';
            valueKey = 'cloud_provider';
          }

          if (fieldName === 'Sales and Marketing') {
            fieldKey = 'customer_kpis';
            valueKey = 'sales_and_marketing';
          }

          const payload = {
            company_id: record.company_id,
            [fieldKey]: {
              [valueKey]: afterValue.current.value,
            },
          };

          dispatch(opportunityPresentationActions.updateOpportunityPresentation(payload));
        } else if (fieldKey === 'deal_type') {
          const payload = {
            company_id: record?.company_id || 0,
            deal_preparation: {
              [fieldKey]: label,
            },
          };
          dispatch(opportunityPresentationActions.updateOpportunityPresentation(payload));
        } else {
          updateField(newValue as SelectOption, fieldKey);
        }
      }
    }

    setClosePopUp(true);
    setDropdownVisible(!dropdownVisible);
  };

  const handleDefaultEmptyValue = () => {
    if (selectedValue === DEFAULT_EMPTY_VALUE) {
      setSelectedValue(blankOptionLabel);
    }
  };

  useEffect(() => {
    handleDefaultEmptyValue();

    if (afterValue.current) {
      setSelectedValue(afterValue.current.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [value, record]);

  useEffect(() => {
    if (currRationaleValue !== rationaleValue) {
      setRationaleValue(currRationaleValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, record, currRationaleValue]);

  const handleRationaleChange = (newValue: string) => {
    setRationaleValue(newValue);

    if (
      (newValue === currRationaleValue && value !== selectedValue) ||
      newValue !== currRationaleValue
    ) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  };

  const handleCancel = () => {
    setClosePopUp(true);
    setSaveBtnDisabled(true);

    if (selectedValue !== value) {
      setSelectedValue(value);
    }

    if (fieldName === 'Stage' && record && rationaleValue !== currRationaleValue) {
      setRationaleValue(currRationaleValue);
    }
  };

  const handleSave = () => {
    const currentOption = selectOptions.find(option => option.label === selectedValue);

    if (currentOption) {
      updateField(currentOption, fieldKey, undefined, undefined, rationaleValue as string);
    }

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    setClosePopUp(true);
    setSaveBtnDisabled(true);
    setDropdownVisible(!dropdownVisible);
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  const handleOtherChange = (option: React.SetStateAction<string | null>) => {
    if (option !== DEFAULT_EMPTY_VALUE) {
      setRationaleValue(option);

      if (option !== value) {
        setSaveBtnDisabled(false);
      }
    }
  };

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (key === 'tag') {
      return !!(
        currRationaleValue &&
        currRationaleValue !== DEFAULT_EMPTY_VALUE &&
        currRationaleValue !== null &&
        !values.includes(currRationaleValue) &&
        value === selectedValue
      );
    }

    if (!rationaleValue) {
      return false;
    }

    return !!(
      !values.includes(rationaleValue) &&
      rationaleValue !== DEFAULT_EMPTY_VALUE &&
      rationaleValue.length > 0
    );
  };

  const customRationaleValue = showIsOtherOrTag() ? 'other' : rationaleValue;

  const rationaleFieldContent = (
    <EditFieldStyled.ContentContainer
      className="profile-rationale"
      isSoftware={false}
      tabIndex={0}
      ref={popoverContentRef}
    >
      <SingleSelect
        openMenuOnFocus
        options={handleOptionsList as any}
        onChange={onChange}
        isLoading={isLoadingOptions}
        value={initialSelectValue as any}
        maxMenuHeight={190}
        closeMenuOnSelect
      />
      {showRationaleForStage(selectedValue as string) && (
        <RationaleSection
          rationaleOptions={rationaleOptions}
          rationaleValue={customRationaleValue}
          handleRationaleChange={handleRationaleChange}
          currRationaleValue={currRationaleValue}
          showIsOtherOrTag={showIsOtherOrTag}
          handleOtherChange={handleOtherChange}
          isRationaleRequired
          isHeightOverflow
        />
      )}
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
          disabled={saveBtnDisabled}
        >
          Save
        </Button>
      </div>
    </EditFieldStyled.ContentContainer>
  );

  const commonContent = (
    <Styled.CellPopover>
      <SingleSelect
        openMenuOnFocus
        options={handleOptionsList as any}
        onChange={onChange}
        isLoading={isLoadingOptions}
        value={initialSelectValue as any}
        closeMenuOnSelect
      />
    </Styled.CellPopover>
  );

  const content = canBeRationale ? rationaleFieldContent : commonContent;

  if (!isFromGrid || isFromGridDropDown) {
    return (
      <EditPopover
        content={content}
        title={`Edit "${fieldName}" value`}
        closePopup={closePopUp}
        setClosePopup={setClosePopUp}
        hidePenIcon={hidePenIcon}
        actionPendo={actionPendo}
        destroyOnHide
        getPopupContainer={getPopupContainer}
      >
        {renderLabel({
          isFromGridDropDown,
          isFromHistory,
          dropdownLabel,
          value,
          label,
          useTooltip: useTooltip,
        })}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditCellPen
          content={content}
          title={`Edit "${fieldName}" value`}
          closePopup={closePopUp}
          setClosePopup={setClosePopUp}
          placement={dropdownPlacement}
        />
      }
      fill
    >
      {value && value !== 'Unowned' ? (
        <TruncateTooltip title={value === blankOptionLabel ? DEFAULT_EMPTY_VALUE : value}>
          <TableCellStyled.TableCellInner>
            {value === blankOptionLabel ? DEFAULT_EMPTY_VALUE : value}
          </TableCellStyled.TableCellInner>
        </TruncateTooltip>
      ) : (
        handleUnownedValue(value)
      )}
    </InlineEdit>
  );
};

export default React.memo(EditFieldSingleSelect);
