import styled from 'styled-components';
import { Col } from 'antd';

const InputSecondaryUrlFieldWrapper = styled(Col)`
  &.secondary_urls {
    flex: 0 0 75.3%;
    max-width: 75.3%;
  }

  .secondary_url_input_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }
`;

export default {
  InputSecondaryUrlFieldWrapper,
};
