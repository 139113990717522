import React, { useCallback, useEffect, useRef, useState } from 'react';
import { OptionsType } from 'react-select';
import { Button } from 'antd';
// models
import { FieldValue } from '@features/company/audit-trail/state/interfaces';
import { ServiceType } from '@features/company/edit-fields/state/interfaces';
import { SuccessErrorCallback } from '@optx/models/callback';
import {
  CompanyProfile,
  Company,
  ProductCategory,
  CompanyUserValuesKeys,
  UpdateCompanyDataFieldKeys,
} from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
import { debounce } from 'lodash';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// utils
import { hasBlankOrNoneLabel } from '@optx/utils/helpers';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useUpdateFields from '../hooks/useUpdateFields';
// components
import { Styled } from '@optx/features/company/edit-fields/components/TableCellFit.styled';
import EditPopover from '@optx/components/common/popover/EditPopover';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

interface EditFieldMultiSelectAsyncProductCategoryProps {
  fieldName: 'Product Category';
  fieldKey?: CompanyUserValuesKeys | UpdateCompanyDataFieldKeys;
  record: Company | CompanyProfile;
  isFromGrid: boolean;
  hidePenIcon?: boolean;
  lastTemporaryFieldValue?: FieldValue;
  service: ServiceType;
  asyncSearchEndpoint: string;
  successMessage: string;
  errorMessage?: string;
  value: ProductCategory[] | null;
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  actionPendo?: string;
}

const EditFieldMultiSelectAsyncProductCategory: React.FC<
  EditFieldMultiSelectAsyncProductCategoryProps
> = ({
  fieldName,
  value,
  record,
  fieldKey,
  hidePenIcon,
  service,
  successMessage,
  asyncSearchEndpoint,
  isFromGrid,
  getPopupContainer,
  isDynamicPopoverHeight,
  destroyOnHide,
  actionPendo,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record.company_id,
    companyUrl: record.company_url,
    service,
    successMessage,
  });
  const { updateHistoryFields } = useHistoryTab();
  const { loadOptions } = useAsyncSearch({ endpoint: asyncSearchEndpoint });
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const popoverContentRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectOption>>([]);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);
  const title = `Edit "${fieldName}" value`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCallback = (data?: SuccessErrorCallback, showHistory?: boolean) => {
    if (data) {
      if (showHistory) {
        const beforeValue =
          Array.isArray(value) && value.length
            ? value.map(item => item.category).join(',')
            : 'None';

        updateHistoryFields({
          afterValue:
            Array.isArray(selectedOptions) && !selectedOptions.length
              ? 'None'
              : selectedOptions.map(option => option.value).join(','),
          beforeValue,
          fieldChanged: fieldName,
        });
      }
    }
  };

  const handleSave = useCallback(() => {
    const mappedValues = selectedOptions.reduce((acc: string[], curr: SelectOption<string>) => {
      if (curr.value !== '') {
        acc.push(curr.value);
      }

      return acc;
    }, []);

    const alphabeticalMappedValues = mappedValues
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      })
      .toString();

    const value = alphabeticalMappedValues.length === 0 ? 'blank' : alphabeticalMappedValues;
    updateField(value, fieldKey, undefined, handleCallback);
    setClosePopup(true);
  }, [handleCallback, selectedOptions, updateField, fieldKey]);

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  const valueExceptBlank =
    value?.length &&
    !value?.includes(undefined as never) &&
    value
      ?.map(item =>
        Object.prototype.hasOwnProperty.call(item, 'category') &&
        !hasBlankOrNoneLabel(item.category)
          ? item.category
          : item
      )
      .join(', ');

  const handleDefaultValues = useCallback(() => {
    if (!value) {
      setSelectedOptions([]);

      return;
    }

    const mappedValues = value.map(item => ({
      value: item?.category,
      label: item?.category,
      isValid: item?.is_valid,
    }));

    setSelectedOptions(mappedValues);
  }, [value]);

  const getIsValueChanged = () => {
    if (selectedOptions.length !== value?.length) return false;

    let valid = true;
    value.forEach(item =>
      selectedOptions.find(option => option.value === item.category) ? true : (valid = false)
    );

    return valid;
  };

  useEffect(() => {
    handleDefaultValues();
  }, [handleDefaultValues]);

  useOnClickOutside(popoverContentRef, () => {
    handleDefaultValues();
  });

  const handleCancel = useCallback(() => {
    setClosePopup(true);
    handleDefaultValues();
  }, [handleDefaultValues]);

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <div
      className="addon-popover"
      role="presentation"
      onKeyDown={handleDropdownKeyEvent}
      ref={containerRef}
      tabIndex={0}
    >
      <AsyncMultiSelect
        defaultMenuIsOpen={true}
        className="addon--multiselect-async"
        onChange={(options, action) => {
          setSelectedOptions(options as SelectOption[]);
        }}
        loadOptions={getLoadOptionsDelay}
        defaultOptions={fetchedOptions}
        defaultValue={selectedOptions}
        loadingMessage={({ inputValue }) => (inputValue.length > 2 ? 'Loading...' : 'Begin typing')}
        closeMenuOnSelect={false}
        onMenuClose={() => containerRef.current && containerRef.current.focus()}
        onSelectResetsInput={false}
        blurInputOnSelect={false}
        controlShouldRenderValue
        maxLength={500}
        closeOnSelect
      />
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          disabled={getIsValueChanged()}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );

  if (!isFromGrid) {
    return (
      <EditPopover
        content={content}
        title={title}
        hidePenIcon={hidePenIcon}
        onVisibilityChange={handleCancel}
        getPopupContainer={getPopupContainer}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        isDynamicPopoverHeight={isDynamicPopoverHeight}
        destroyOnHide={destroyOnHide}
        actionPendo={actionPendo}
      >
        {hidePenIcon ? (
          'Edit Field'
        ) : (
          <TruncateTooltip
            className="edit-field--text"
            placement="topLeft"
            title={valueExceptBlank || DEFAULT_EMPTY_VALUE}
          >
            {valueExceptBlank || DEFAULT_EMPTY_VALUE}
          </TruncateTooltip>
        )}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditCellPen
          content={content}
          title={title}
          closePopup={closePopup}
          onVisibilityChange={handleCancel}
          setClosePopup={setClosePopup}
          placement={'topLeft'}
        />
      }
      fill
    >
      <TruncateTooltip title={valueExceptBlank || DEFAULT_EMPTY_VALUE}>
        <Styled.TableCellInner>{valueExceptBlank || DEFAULT_EMPTY_VALUE}</Styled.TableCellInner>
      </TruncateTooltip>
    </InlineEdit>
  );
};

export default React.memo(EditFieldMultiSelectAsyncProductCategory);
