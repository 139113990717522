import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// models
import { ListAccessRights } from '@optx/models/WatchList';
import { UserList } from '@optx/redux/user/user-list/interfaces';
import { CustomUIViewIds } from '@optx/models/user';
// constants
import ROUTES from '@optx/constants/routes';
import { FAVORITE_LIST_ID } from '@optx/constants/lists';
import { customUIViewIds } from '@optx/constants/table/columnDisplay/company-search';
// redux
import { selectors as userSelectors } from '@redux/user/information';
// components
import { ShareWatchListMenuItem } from '@features/list-share';
import {
  ShareListMenuItem,
  MakeDefaultFilter,
  AddListAssociationMenuItem,
  RemoveListAssociationMenuItem,
  CombineSearchMenuItem,
} from './menu-items';
import DownloadMenuItem from './menu-items/DownloadMenuItem';
import ActionMenuItem from './menu-items/ActionMenuItem';
import Styled from './ListActionsMenu.styled';

interface ListActionsMenuProps {
  type?: string;
  listId: number | string;
  title: string;
  userListShare?: Array<UserList>;
  sharedWith?: Array<number>;
  toggleInput: VoidFunction;
  handleDeleteList: (listId: number | string) => void;
  handleRemoveList?: (listId: number | string) => void;
  onDownloadList?: (listId: string, fileName: string, includeDescription?: boolean) => void;
  onConfirm?: (includeDescription?: boolean) => void;
  onCancel?: () => void;
  onVisible?: () => void;
  accessRights: ListAccessRights;
  isContacts?: boolean;
  ownerName?: string;
  listAssociation?: string;
  isOwner?: boolean;
  hasFilters?: boolean;
}

const ListActionsMenu: React.FC<ListActionsMenuProps> = ({
  type,
  listId,
  title,
  userListShare,
  toggleInput,
  handleDeleteList,
  handleRemoveList,
  onDownloadList,
  onConfirm,
  onCancel,
  accessRights,
  isContacts,
  sharedWith,
  ownerName,
  onVisible,
  listAssociation,
  isOwner,
  hasFilters,
  ...restProps
}) => {
  const location = useLocation();

  const userFullName = useSelector(userSelectors.getFullName);
  const isShareable =
    (accessRights === ListAccessRights.Edit || accessRights === ListAccessRights.ShareOnly) &&
    userListShare;

  const showCombineSearchesMenuItem = location.pathname === '/lists/company-searches';

  const showRemove = () => {
    if (window.location.pathname !== ROUTES.lists.myWatchList) return false;

    if (
      ownerName?.toLocaleLowerCase() === 'optx' ||
      ownerName?.toLocaleLowerCase() === 'equity touch'
    ) {
      return false;
    }

    if (userFullName === ownerName) return false;

    return true;
  };

  const handleClick = () => {
    onVisible && onVisible();
  };

  const showDelete = () => {
    if (accessRights !== ListAccessRights.Edit) return false;

    if (
      ownerName?.toLocaleLowerCase() === 'optx' ||
      ownerName?.toLocaleLowerCase() === 'equity touch'
    ) {
      return false;
    }

    return true;
  };

  const showListAssociation = () => {
    if (window.location.pathname !== ROUTES.lists.myWatchList) return false;

    if (accessRights !== ListAccessRights.Edit) return false;

    if (
      ownerName?.toLocaleLowerCase() === 'optx' ||
      ownerName?.toLocaleLowerCase() === 'equity touch'
    ) {
      return false;
    }

    if (listId === FAVORITE_LIST_ID) return false;

    return true;
  };

  return (
    <Styled.MenuList {...restProps} onClick={handleClick}>
      {showDelete() && (
        <Menu.Item key="0" onClick={toggleInput}>
          Rename
        </Menu.Item>
      )}

      {showCombineSearchesMenuItem && !customUIViewIds.includes(listId as CustomUIViewIds) && (
        <CombineSearchMenuItem key="1" listId={listId} listType={type} hasFilters={hasFilters}>
          Combine Search
        </CombineSearchMenuItem>
      )}

      {showDelete() && (
        <ActionMenuItem
          key="2"
          action="Delete"
          title={title}
          onConfirm={() => handleDeleteList(listId)}
          onCancel={() => onCancel && onCancel()}
        />
      )}

      {showRemove() && (
        <ActionMenuItem
          key="3"
          action="Remove"
          title={title}
          onConfirm={() => handleRemoveList && handleRemoveList(listId)}
          onCancel={() => onCancel && onCancel()}
        />
      )}

      {isShareable && (
        <>
          {type === 'list' ? (
            <ShareWatchListMenuItem key="4" listId={listId} listType="watchlist">
              Share with another user
            </ShareWatchListMenuItem>
          ) : (
            <ShareListMenuItem
              key="4"
              listId={listId}
              listType={type}
              options={userListShare}
              sharedWith={sharedWith}
            >
              Share with another user
            </ShareListMenuItem>
          )}
        </>
      )}

      {isShareable && isOwner && type === 'list' && (
        <ShareWatchListMenuItem key="5" listId={listId} listType="watchlist">
          Make List Public
        </ShareWatchListMenuItem>
      )}

      {onDownloadList && <DownloadMenuItem key="6" onConfirm={onConfirm} />}
      {type !== 'list' && !isContacts && <MakeDefaultFilter key="7" id={listId} />}
      {showListAssociation() && (
        <div key="88">
          <AddListAssociationMenuItem key="8" listId={listId} companyName={listAssociation} />
          {listAssociation && <RemoveListAssociationMenuItem key="9" listId={listId} />}
        </div>
      )}
    </Styled.MenuList>
  );
};

export default ListActionsMenu;
