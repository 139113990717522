import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
// models
import { AxiosResponse } from 'axios';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { ViewOption, SearchStateData } from '@models/search';
import { PreselectedFilter } from '@optx/models/filters';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { GridPayload } from '@optx/models/grid';
import { GridKeys } from '@models/grid';
import { LogicOption } from '@models/Option';
import { PageInfo } from '@models/table/Pagination';
import { SortByRule } from '@models/table/sorting';
// constants
import { myCompaniesPreselectedFilter } from '@optx/features/grid/filter/state/constants';
// services
import NotificationService from '@services/NotificationService';
import { UserService } from '@optx/services/api';
// utils
import { mapSortQuery, hasFilters, preProcessSearch } from '@utils/search';
import { processCardList, parseSourceTagFilter } from '@optx/utils/myCompanies';
// redux
import { actions as histogramActions } from '@features/histograms/histograms-my-companies';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { selectors as myCompaniesSearchSelectors } from '@redux/my-companies/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as sortSelectors, actions as sortActions } from '@redux/my-companies/sort';
import {
  selectors as filterCardsSelectors,
  actions as filterCardsActions,
} from '@optx/redux/my-companies/filters-cards';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as columnActions } from '@redux/my-companies/columns';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { selectors as filterSelectors } from '@optx/features/grid/filter';

export function* applyViewSaga(action: PayloadAction<GridPayload<ViewOption>>) {
  const {
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
    filters: viewFilters,
    searchKey: viewSearchKey,
    sortBy,
    search_criteria: searchCriteria,
    unique_id: id,
    title,
    search_info: searchInfo,
  } = action.payload.data;

  const gridKey: GridKeys = 'myCompanies';

  const currentFilter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter(gridKey)
  );
  const pagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const isCombinedSearch = searchInfo?.type === 'combined';
  const userPageNumber: number = yield select(
    userInformationSelectors.getMyCompaniesResultsPageNumber
  );
  let filter: Dictionary<PreselectedFilter> | undefined;
  let searchKey: string = yield select(searchSelectors.getSearchKey(gridKey));

  if (hasFilters(searchCriteria) || id === 'default') {
    filter = viewFilters;
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';

    // before we separated watchlist from advanced search page it is possible that
    // some saved searches include the list id
    // if we have old saved searches that include list id, remove it.
    // when applying a view we should not include the list id
    filter = {
      ...filter,
      ss_list_id: undefined,
      saved_list_id: undefined,
    };
  }

  if (!hasFilters(searchCriteria) && viewSearchKey) {
    filter = yield select(filterSelectors.getClearedFilter(gridKey));
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
  }

  yield put(columnActions.updateColumns(columns, columnOrder, pinnedColumns));

  if (isCombinedSearch || filter || sortBy) {
    const currentSortBy: SortByRule<any>[] = yield select(sortSelectors.getSorting);
    const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getCustomClearedFilter(gridKey)
    );
    const filterQuery: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      isCombinedSearch ? customClearedFilter : filter || currentFilter
    );
    const selectedCard: SearchStateData = yield select(filterCardsSelectors.getSelectedCard);
    const subFilter: string = yield select(filterCardsSelectors.getSubFilter);
    const searchCriteria = queryString.parse(action.payload.data.search_criteria, {
      arrayFormat: 'comma',
    }) as Dictionary<string[]>;
    // when applying a view we need to get the specific filters for that card.
    // unfortunately from api all the applied filters are returned for the card, instead of just the specific ones
    const cardFilters: Dictionary<string[]> = {
      source_tag_filter: ['et-1-0'],
      last_touch_date_days: selectedCard.filter?.last_touch_date_days,
      next_touch_date_days: selectedCard.filter?.next_touch_date_days,
      saved_list_id: selectedCard.filter?.saved_list_id,
      n_touch_date_with_past_due: selectedCard.filter?.n_touch_date_with_past_due,
      // hidden and specific filters for first card
      ...(selectedCard.id === 'card1' &&
        (!filterQuery.stage || selectedCard.filter?.stage) && {
          stage: searchCriteria?.stage || selectedCard.filter?.stage,
        }),
      ...(selectedCard.id === 'card1' &&
        selectedCard?.filter?.next_touch_date && {
          next_touch_date: searchCriteria?.next_touch_date,
        }),
    };

    if (filterQuery.source_tag_filter.length) {
      (filterQuery.source_tag_filter as string[]).forEach((sourceTagValue: string) => {
        if (
          // @ts-ignore
          sourceTagValue !== selectedCard.filter.source_tag_filter &&
          sourceTagValue !== 'et-1-0'
        ) {
          cardFilters.source_tag_filter.push(sourceTagValue);
        }
      });
    }

    // my companies will always show companies that are at least in equity touch
    // if a view is filtering for companies not in equity touch, remove
    // the filter value and display a message
    if (cardFilters.source_tag_filter.includes('et-0-1')) {
      cardFilters.source_tag_filter = cardFilters.source_tag_filter.filter(
        (value: string) => value !== 'et-0-1'
      );

      NotificationService.warn('Can only filter for companies that are at least in Equity Touch.');
    }

    const searchData: SearchPayloadRequest = {
      searchKey,
      fromSavedList: typeof id === 'number' ? id : undefined,
      listType: searchInfo?.type || '',
      filter: subFilter
        ? {
            ...filterQuery,
            ...cardFilters,
            // @ts-ignore
            ...selectedCard.data[subFilter],
            query: searchKey,
          }
        : { ...filterQuery, ...cardFilters, query: searchKey },
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      sortBy: sortBy || currentSortBy,
    };

    const cardSearchFilter = { ...filterQuery, source_tag_filter: cardFilters.source_tag_filter };

    const sessionSettings: Partial<UpdateUserSettingsPayload> = {};

    if (sortBy) {
      const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
        arrayFormat: 'comma',
      });
      sessionSettings.my_companies_sorting = sortQuery;
    }

    if (columns !== null) {
      sessionSettings.my_companies_columns = columns;

      if (columnOrder) {
        sessionSettings.my_companies_column_order = columnOrder;
      }
    }

    if (pinnedColumns !== null) {
      sessionSettings.my_companies_pinned_columns = pinnedColumns;
    }

    const pageNumberChanged = searchData.pagination.pageNumber !== userPageNumber;

    if (pageNumberChanged) {
      sessionSettings.my_companies_results_page_number = searchData.pagination.pageNumber;
    }

    const updateSession =
      filter && !sortBy && !columns && !pinnedColumns && !pageNumberChanged
        ? true
        : sessionSettings;

    try {
      const selectedCardId: string = yield select(filterCardsSelectors.getSelectedCardId);
      const res: AxiosResponse<Dictionary<SearchStateData>> = yield call(
        UserService.fetchMyCompaniesCards,
        searchKey,
        cardSearchFilter,
        selectedCardId ? (selectedCardId as string) : 'card1'
      );

      if (res.data) {
        const cardsList = processCardList(res.data);

        yield put(filterCardsActions.fetchMyCompaniesCardsSuccess(cardsList));

        // we need to parse the source tag filter to save it in the store
        if (filter) {
          filter = {
            ...filter,
            source_tag_filter: parseSourceTagFilter(
              myCompaniesPreselectedFilter.source_tag_filter as LogicOption[],
              cardFilters.source_tag_filter
            ),
          };
        } else {
          filter = { ...currentFilter };
        }

        // @ts-ignore
        yield call(searchCompanies, searchData, gridKey, filter, undefined, title, updateSession);

        const allFiltersWithHistograms: string[] = yield select(
          myCompaniesSearchSelectors.selectAllFiltersWithHistograms
        );

        yield put(histogramActions.fetchHistogramFilters(filter || {}, allFiltersWithHistograms));
      }

      if (sortBy && sortBy.length > 1) {
        yield put(sortActions.multiSortAction(true));
      }
    } catch (error: any) {
      yield call(searchCompaniesFail, gridKey, error);
    }
  } else {
    yield put(
      userInformationActions.updateUserSettings({
        my_companies_columns: columns as string,
        my_companies_column_order: columnOrder as string,
        my_companies_pinned_columns: pinnedColumns as string,
      })
    );
    yield put(savedSearchesActions.applyViewSuccess({ id, label: title, pageAlias: gridKey }));
  }
}
