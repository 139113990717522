import React from 'react';
import { Menu, MenuProps } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useInjectSaga } from 'redux-injectors';
// redux
import logOutSaga from '@redux/auth/authentication/saga';
import * as authActions from '@redux/auth/authentication/actions';
import { useDispatch } from 'react-redux';
// hooks

// Inject reducers/sagas
const useInject = () => {
  useInjectSaga({ key: 'logOut', saga: logOutSaga });
};

/**
 * Logout Menu Item.
 * @param param0
 */
const LogOut = ({ onClick, ...restProps }: MenuItemProps) => {
  useInject();
  const dispatch = useDispatch();

  const logOut = () => dispatch(authActions.logOut({ isChromeExtenison: true }));

  const handleClick: MenuProps['onClick'] = info => {
    logOut();
    onClick && onClick(info);
  };

  return (
    <Menu.Item
      className="ant-dropdown-menu-item"
      key="logout-menu"
      onClick={handleClick}
      {...restProps}
    >
      Log Out
    </Menu.Item>
  );
};

export default LogOut;
