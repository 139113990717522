import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Table } from 'antd';
import { Form } from 'formik-antd';
import { Formik, FormikProps } from 'formik';
import { Dictionary } from 'lodash';
import * as yup from 'yup';
// models
import { SelectOption } from '@models/Option';
// constants
import { defaultColumns } from '@components/common/table/TableBulkEquityTouch';
import { psgContext, sector } from '@constants/equitytouch';
// services
import NotificationService from '@optx/services/NotificationService';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as bulkSalesloftActions } from '@optx/features/bulk-actions/salesloft';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as bulkEqtActions } from '@features/bulk-actions/equity-touch';
import { selectors as userSelectors } from '@redux/user/information';
// components
import { FormFields } from '@features/company/add-to-equity-touch';
import { SalesloftAddContact } from '@optx/screens/App/SalesloftBulk/components/salesloft/SalesloftAddContact';
import { actions as selectedCompaniesActions } from '@features/bulk-actions/selected-companies';
import AutoSave from '../common/AutoSave';
import { Context } from '../common/Context';
import { RowWrapper, CardWrapper } from '../EquityTouchBulk.styled';
import AddCadence from '../salesloft/AddCadence';
import CardTitle from '../common/CardTitle';

const message = 'Field is required!';

const validationSchema = yup
  .object()
  .shape<
    Dictionary<
      string | number | boolean | number[] | string[] | null | SelectOption | SelectOption[]
    >
  >(
    {
      Fund: yup.string().required(message),
      Sector: yup.string().required(message),
      Rank: yup.string().required(message),
      Rationale: yup.string().max(500, 'Maximum  500 characters'),
      'Add-On For': yup
        .array<SelectOption>()
        .nullable()
        .when('Add-On', {
          is: addon => addon,
          then: yup.array<SelectOption>().required(message),
          otherwise: yup.array<SelectOption>().nullable(),
        }),
      'Add-On': yup.bool().when('Add-On For', {
        is: addonFor => addonFor === undefined || addonFor?.length === 0,
        then: yup.bool(),
        otherwise: yup.bool().oneOf([true], message).required(message),
      }),
    },
    [['Add-On For', 'Add-On']]
  );

const Step1: React.FC = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const { grid } = useParams();
  const { submitForm, setSubmitForm, cadence, setStep, setAddon, setIsAddon, setTitle, addon } =
    useContext(Context);
  const modalVisible = useSelector(bulkActionsSelectors.salesloft.modalVisible);
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);
  const formRef = useRef<FormikProps<
    Dictionary<string | number | boolean | number[] | SelectOption>
  > | null>(null);
  const count = useSelector(bulkActionsSelectors.editFields.selectedCompaniesCount);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  useEffect(() => {
    if (formRef.current) {
      if (!formRef.current.isValid) {
        setSubmitForm(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current]);

  if (formRef && formRef?.current) {
    formRef?.current?.validateForm(temporaryData?.step1);
  }

  if (formRef?.current && !formRef?.current?.isValid) {
    formRef.current?.setTouched(
      Object.fromEntries(Object.keys(formRef?.current?.errors ?? {}).map(key => [key, true]))
    );
  }

  useEffect(() => {
    setTitle(`Bulk Add these ${count} Companies to Equity Touch`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm(temporaryData?.step1);

      if (!formRef.current.isValid) {
        formRef.current?.setTouched(
          Object.fromEntries(Object.keys(formRef?.current?.errors ?? {}).map(key => [key, true]))
        );

        if (submitForm) {
          NotificationService.error('Please provide information for required fields');
        }
      } else if (submitForm === 'bulk') {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      } else if (submitForm === 'bulk-single') {
        dispatch(
          bulkEqtActions.initializeIndividualEditing({
            ...formRef.current.values,
            ...(cadence !== undefined && { cadence }),
          } as any)
        );
        setAddon(formRef.current.values['Add-On For'] as unknown as SelectOption[]);
        setIsAddon(formRef.current.values['Add-On'] as boolean);
        setStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  const selectedCompanies = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(grid)
  );
  const companiesInAdvancedSearch = useSelector(searchSelectors.getCompanies('advancedSearch'));
  const companiesInMyWatchList = useSelector(searchSelectors.getCompanies('lists'));
  const formData = useSelector(bulkActionsSelectors.equityTouch.getFormData);
  const initialFormValues = useSelector(bulkActionsSelectors.equityTouch.getInitialFormValues);
  const [initialFormData, setInitialFormData] = useState(initialFormValues);
  const [isSorted, setSorted] = useState(false);

  const handleCompaniesSelectors = () => {
    if (grid === 'advancedSearch') {
      return companiesInAdvancedSearch;
    }

    if (grid === 'watchlists') {
      return companiesInMyWatchList;
    }

    return [];
  };

  const handleSort = () => {
    const filteredCompanies = handleCompaniesSelectors().map((company, index) => {
      return selectedCompanies.filter(
        company => company.company_id === handleCompaniesSelectors()[index].company_id
      );
    });

    if (handleCompaniesSelectors().length === 0) {
      return selectedCompanies;
    }

    const selectedCompaniesUpdated = filteredCompanies.flat();

    if (selectedCompaniesUpdated.length > 0 && !isSorted) {
      setSorted(true);
      dispatch(
        selectedCompaniesActions.selectCompanyIds({
          gridName: grid,
          selectedCompanies: selectedCompaniesUpdated,
        })
      );
    }

    return selectedCompaniesUpdated;
  };

  useEffect(() => {
    const firstStepTemporaryData = temporaryData?.step1;
    const firstStepDataReceived = Object.keys(firstStepTemporaryData || {}).length !== 0;

    if (firstStepDataReceived) {
      setInitialFormData({ ...firstStepTemporaryData, ...(cadence !== undefined && { cadence }) });

      if (firstStepTemporaryData) {
        if (firstStepTemporaryData['Add-On For']) {
          setAddon((firstStepTemporaryData as Dictionary<any>)['Add-On For'] as SelectOption[]);
        }

        if (firstStepTemporaryData['Add-On']) {
          setIsAddon((firstStepTemporaryData as Dictionary<any>)['Add-On'] as boolean);
        }
      }
    } else {
      setInitialFormData({ ...initialFormValues, ...(cadence !== undefined && { cadence }) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryData, initialFormValues]);

  const handleSubmit = (
    values: Dictionary<string | number | boolean | number[] | SelectOption>
  ) => {
    dispatch(bulkEqtActions.addToEquityTouchBulk({ formValues: values, cadence }));
    setStep(2);
  };

  const setActiveAddon = () => {
    formRef?.current?.setTouched({ 'Add-On For': true });
    formRef?.current?.setTouched({ 'Add-On': true });
    formRef?.current?.setTouched({ Fund: true });
  };

  if (temporaryData.step1 && temporaryData.step1['Add-On For']) {
    formRef?.current?.setFieldValue('Add-On For', temporaryData.step1['Add-On For']);
    setActiveAddon();
  }

  if (temporaryData.step1 && temporaryData.step1['Add-On']) {
    formRef?.current?.setFieldValue('Add-On', temporaryData.step1['Add-On']);
    setActiveAddon();
  }

  return (
    <>
      <RowWrapper gutter={20}>
        <Col span={24} xxl={12}>
          <CardWrapper
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <CardTitle
                title="Selected Companies"
                subtitle="Primary contacts will be selected by default"
              />
            }
          >
            <Table
              tableLayout="fixed"
              columns={defaultColumns(defaultOptxScore)}
              rowKey={'company_id'}
              dataSource={handleSort()}
              pagination={false}
              bordered
              className="legacy-table eqt-bulk__table-selected overflow-auto"
            />
            <SalesloftAddContact
              onCancel={() => {
                dispatch(bulkSalesloftActions.setModalVisible(false));
              }}
              visible={modalVisible}
              contactData={{}}
            />
          </CardWrapper>
        </Col>
        <Col span={24} xxl={12}>
          <Formik
            initialValues={initialFormData as any}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
            enableReinitialize
            innerRef={instance => {
              formRef.current = instance;
            }}
          >
            <Form layout="vertical">
              <AutoSave step={1} />
              <CardWrapper
                title={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <CardTitle
                    title="Company Info"
                    subtitle="These values will be applied to all companies. You can customize in the next step"
                  />
                }
              >
                <FormFields
                  fields={sector}
                  initialFormData={{
                    ...formData,
                    'Add-On For': {
                      ...formData['Add-On For'],
                      received_values: addon ? [{ ...addon }] : [],
                    },
                  }}
                  step={1}
                />
              </CardWrapper>
              <CardWrapper
                title={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <CardTitle
                    title="PSG Context and Rank"
                    subtitle="These values will be applied to all companies. You can override values in the next step"
                  />
                }
              >
                <FormFields
                  fields={psgContext}
                  initialFormData={{
                    ...formData,
                    'Add-On For': {
                      ...formData['Add-On For'],
                      received_values: addon ? [{ ...addon }] : [],
                    },
                  }}
                  step={1}
                />
              </CardWrapper>
              <AddCadence />
            </Form>
          </Formik>
        </Col>
      </RowWrapper>
    </>
  );
};

export default Step1;
