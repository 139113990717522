import React, { useCallback, useEffect, useState } from 'react';
// import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Form, Input } from 'formik-antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
// styles
import Styled from './InputSecondaryUrlField.styled';

interface InputSecondaryUrlFieldProps {
  field: BaseField;
}

const InputSecondaryUrlField: React.FC<InputSecondaryUrlFieldProps> = ({ field }) => {
  const [selected, , setSelected] = useField<string[]>(field?.id || '');
  const [secondaryUrls, setSecondaryUrls] = useState<string[]>(['']);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const index = Number(e.target.name.split('-')[1]);

      setSecondaryUrls(prev => {
        const newSelected = [...prev];

        newSelected[index] = value;

        setSelected.setValue(newSelected);

        return newSelected;
      });
    },
    [setSelected]
  );

  // const handleRemoveDate = (itemIndex: number) => {
  //   let updatedValues = secondaryUrls
  //     ? [...secondaryUrls].filter((item, index) => index !== itemIndex)
  //     : null;

  //   if (updatedValues) {
  //     setSelected.setValue(updatedValues);
  //   }
  // };

  // updates url list when user clicks "+Add URL" button
  useEffect(() => {
    if (selected.value && !isEqual(selected.value, secondaryUrls)) {
      setSecondaryUrls(selected.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.value]);

  useEffect(() => {
    if (field?.value && secondaryUrls.length === 0 && !isEqual(field.value, secondaryUrls)) {
      setSecondaryUrls(field.value);
      setSelected.setValue(field.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.value]);

  if (!secondaryUrls || secondaryUrls.length === 0) {
    return null;
  }

  return (
    <Styled.InputSecondaryUrlFieldWrapper span={8} className={field.id}>
      <Form.Item name={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        {secondaryUrls?.map((url, index) => (
          <span key={`${field.id}-${index}`} className="secondary_url_input_wrapper">
            <Input
              name={`${field.id}-${index}`}
              placeholder={field.placeholder}
              // disabled={!field.editable}
              disabled
              value={url}
              defaultValue={url}
              onChange={handleChange}
            />
            {/* <CloseCircleOutlined
              className="remove_icon"
              twoToneColor="#898989"
              style={{ color: '#898989', fontSize: 19 }}
              onClick={() => handleRemoveDate(index)}
            /> */}
          </span>
        ))}
      </Form.Item>
    </Styled.InputSecondaryUrlFieldWrapper>
  );
};

export default React.memo(InputSecondaryUrlField);
