import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
// models
import { SelectOption } from '@optx/models/Option';
//constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
import { renderValueOther } from '@optx/utils/editFields';
// components
import CreateOtherOptions from '@features/company/edit-fields/components/CreateOtherOptions';
import EditPopover from '@optx/components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
// styled
import { Styled } from './SingleSelectOtherField.styled';
import { SingleSelectFieldWrapper } from './SingleSelectField.styled';

const { Option } = Select;

interface SingleSelectOtherFieldProps {
  value: string;
  fieldName: string;
  fieldType: string;
  otherValue: string | null;
  otherType: string;
  otherAsyncEndpoint: string;
  options: OptionTypeBase[];
  onSave?: (fieldType?: string, secondFieldName?: string) => void;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  onCancel?: () => void;
}

const SingleSelectOtherField: React.FC<SingleSelectOtherFieldProps> = ({
  value,
  fieldName,
  fieldType,
  options,
  otherValue,
  otherType,
  otherAsyncEndpoint,
  onSave,
  getPopupContainer,
  onCancel,
}) => {
  const [selected, , setSelectedOption] = useField<string>(fieldType);
  const [otherSelected, , setOtherSelected] = useField<string | null>(otherType);
  const [closePopup, setClosePopup] = useState(false);

  const blankValue = fieldType === 'next_steps' ? 'blank' : '';
  const tooltipTitle =
    value && value !== blankValue ? renderValueOther(value, otherValue) : DEFAULT_EMPTY_VALUE;
  const selectValue = selected.value || value || blankValue;

  const handleChange = (value: string) => {
    setSelectedOption.setValue(value);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      setSelectedOption.setValue(value);
    }

    if (!value && visibility) {
      setSelectedOption.setValue('');
    }

    if (otherValue && visibility) {
      setOtherSelected.setValue(otherValue);
    } else if (visibility) {
      setOtherSelected.setValue('');
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedOption.setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (otherValue !== null) {
      setOtherSelected.setValue(otherValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherValue]);

  const handleOtherChange = (option: SelectOption) => {
    if (option.isDefault) {
      setOtherSelected.setValue(null);
      setSelectedOption.setValue(option.value);
    } else {
      setOtherSelected.setValue(option.value);
    }
  };

  const handleTagRemoval = () => {
    setOtherSelected.setValue(null);
  };

  const onSaveHandler = () => {
    onSave && onSave(fieldType, otherType);
  };

  const content = (
    <FormItem name={fieldType ?? ''}>
      <SingleSelectFieldWrapper
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSaveHandler, fieldType)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <Select
          defaultOpen
          autoFocus
          showSearch
          name={fieldType ?? ''}
          style={{ width: '300px' }}
          value={selectValue}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = `${option?.children.props.children}`;

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {options?.map((option: OptionTypeBase) => (
            <Option key={option.value as string} value={option.value as string}>
              <span>{option.label}</span>
            </Option>
          ))}
        </Select>
        {selectValue === 'Other' && (
          <Styled.OtherContainer>
            <Styled.InputWrap>
              <Typography.Text>Please Explain</Typography.Text>
              <CreateOtherOptions
                onInputChange={handleOtherChange}
                apiEndpoint={otherAsyncEndpoint}
              />
              {otherSelected.value && (
                <Styled.Tag color="blue">
                  {otherSelected.value}{' '}
                  <span className="remove-tag" onClick={handleTagRemoval}>
                    X
                  </span>
                </Styled.Tag>
              )}
            </Styled.InputWrap>
          </Styled.OtherContainer>
        )}
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={
              checkSaveButton(selected.value, value) &&
              checkSaveButton(otherSelected.value, otherValue)
            }
            onClick={() => {
              onSave && onSave(fieldType, otherType);
              setClosePopup(true);
            }}
          >
            Save
          </Button>
        </div>
      </SingleSelectFieldWrapper>
    </FormItem>
  );

  return (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      customOverlayStyle={{ width: '480px' }}
      content={content}
      onVisibilityUpdate={handleVisibilityChange}
      getPopupContainer={getPopupContainer}
      destroyOnHide
    >
      <TruncateTooltip title={tooltipTitle}>{tooltipTitle}</TruncateTooltip>
    </EditPopover>
  );
};

export default React.memo(SingleSelectOtherField);
