import { Card } from 'antd';
import styled from 'styled-components';
import { CardProps } from 'antd/lib/card';
import ROUTES from '@optx/constants/routes';

const CardPannelWrapper = styled<
  React.FC<CardProps & { $location?: string; $topOffset?: string | number }>
>(Card)`
  overflow-y: scroll;
  max-height: ${props =>
    `calc(100vh - ${
      props.$location === ROUTES.myCompanies
        ? 203
        : props.$location?.includes('/all-reports')
        ? 297
        : props.$topOffset
    }px)`};
  position: sticky;
  top: ${props =>
    props.$location === ROUTES.myCompanies
      ? '203px'
      : props.$location?.includes('/all-reports')
      ? '297px'
      : `${props.$topOffset}px`};
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  @media only screen and (max-width: 1780px) {
    .profile-chart-card {
      .ant-card-head-wrapper {
        display: block;
      }

      .ant-card-extra {
        float: none;
        justify-content: flex-start;
        padding-bottom: 16px;
      }

      .ant-card-head-title {
        padding-bottom: 0;
      }
    }

    .ant-timeline.ant-timeline-label .timeline-item--touch-future .ant-timeline-item-label {
      padding: 0 23px 0 0;
    }
  }

  @media (min-width: 1200px) and (max-width: 1780px) {
    .profile-chart-card.us {
      .beta-score-extra-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .recharts-legend-wrapper {
          position: static !important;

          & > div {
            margin-top: 0;
            margin-bottom: 20px;
          }
        }
      }

      .beta-score-extra {
        margin-left: 0;
        position: relative;
        padding-left: 30px;

        .score-trending__growth-score {
          color: #7cb305;
        }
      }

      .ant-tag {
        margin-right: 10px;
        position: absolute;
        left: -22px;
      }
    }

    .profile-chart-card.combined {
      .recharts-legend-wrapper {
        position: static !important;
        display: flex;
        justify-content: flex-end;

        & > div {
          margin-top: 0;
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .profile-chart-card.us {
      .ant-tag {
        margin-right: 25px;
        position: static;
        left: auto;
      }
    }
  }

  .funding-chart {
    .recharts-tooltip-wrapper {
      top: auto !important;
      bottom: 0;
    }
  }
`;

export const Styled = {
  CardPannelWrapper,
};
