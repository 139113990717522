import React from 'react';
import { Row, Col } from 'antd';
// models
import { EquityTouchKeys } from '@models/equityTouch';
// components
import { FieldItems } from './FieldItems';

interface FormFieldsProps {
  fields: Array<Array<EquityTouchKeys>>;
  initialFormData: any;
  readOnly?: boolean;
  step?: number;
}

const removeTitleAttributes = () => {
  const labelElements = document.querySelectorAll('div.ant-col.ant-form-item-label label');
  labelElements.forEach(label => {
    label.removeAttribute('title');
  });

  const spanElements = document.querySelectorAll('span.ant-select-selection-item');
  spanElements.forEach(span => {
    span.removeAttribute('title');
  });
};

export const FormFields: React.FC<FormFieldsProps> = ({
  fields,
  initialFormData,
  readOnly,
  step,
}) => {
  removeTitleAttributes();

  return (
    <>
      {fields.map((fieldList: Array<EquityTouchKeys>, index) => {
        // depending on how many items we have inside the array determine how
        // to create the structure to display the form fields
        switch (fieldList.length) {
          case 2: {
            return (
              <Row gutter={50} key={index}>
                <Col span={12}>
                  <FieldItems
                    readOnly={readOnly}
                    field={fieldList[0]}
                    initialFormData={initialFormData}
                    step={step}
                  />
                </Col>
                <Col span={12}>
                  <FieldItems
                    readOnly={readOnly}
                    field={fieldList[1]}
                    initialFormData={initialFormData}
                    step={step}
                  />
                </Col>
              </Row>
            );
          }

          case 3: {
            return (
              <Row gutter={50} key={index}>
                <Col span={12}>
                  <FieldItems
                    readOnly={readOnly}
                    field={fieldList[0]}
                    initialFormData={initialFormData}
                    step={step}
                  />
                </Col>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <FieldItems
                        readOnly={readOnly}
                        field={fieldList[1]}
                        initialFormData={initialFormData}
                        step={step}
                      />
                    </Col>
                    <Col span={12}>
                      <FieldItems
                        readOnly={readOnly}
                        field={fieldList[2]}
                        initialFormData={initialFormData}
                        step={step}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }

          default:
            return (
              <Row key={index}>
                <Col span={24}>
                  <FieldItems
                    readOnly={readOnly}
                    field={fieldList[0]}
                    initialFormData={initialFormData}
                    step={step}
                  />
                </Col>
              </Row>
            );
        }
      })}
    </>
  );
};
