import styled, { keyframes, css } from 'styled-components';

interface LoaderProps {
  $isChromePlugin?: boolean;
}

const load = keyframes`
    0% {
      transform: rotate(0deg) scale(2);
    }
    100% {
      transform: rotate(360deg) scale(2);
    }
`;

const Loader = styled.div<LoaderProps>`
  width: 100%;
  height: 300px;
  position: relative;

  &.fixed {
    ${({ $isChromePlugin }) => css`
      width: ${$isChromePlugin ? '60vw' : '100vw'};
    `}
    height: 100%;
    background: rgba(223, 223, 223, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
  }

  .load__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -16px;
    z-index: 1000;
    transform: translate(-50%, -50%);

    animation: linear ${load} 2s infinite;
    width: 32px;
    height: 32px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(223, 223, 223, 0.5);
`;

export const Styled = {
  Loader,
  Overlay,
};
