import { createAction } from '@reduxjs/toolkit';
import * as types from './actionTypes';
import { OptxScoreRationale } from './interfaces';

export const sendOptxScoreRationale = createAction<OptxScoreRationale>(
  types.SEND_OPTX_SCORE_RATIONALE
);

export const sendNewOptxScoreRationale = createAction<OptxScoreRationale>(
  types.SEND_NEW_OPTX_SCORE_RATIONALE
);

export const sendOptxScoreRationaleSuccess = createAction<OptxScoreRationale>(
  types.SEND_OPTX_SCORE_RATIONALE_SUCCESS
);

export const sendNewOptxScoreRationaleSuccess = createAction<OptxScoreRationale>(
  types.SEND_NEW_OPTX_SCORE_RATIONALE_SUCCESS
);
