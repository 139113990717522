import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, omit } from 'lodash';
import queryString from 'query-string';
// models
import { SearchState as GridSearchState } from './interfaces';
import Company, {
  ProductCategory,
  CompanyUserValue,
  FinancialFieldType,
  OpportunityPresentation,
  UpdateOpporutunityFinancials,
  CompanyUserValuesKeys,
  UpdateOpportunityDealPreparation,
  UpdateOpportunityTechnology,
  UpdateOpportunityCustomerKpis,
  CompanyFundingRounds,
  Addon,
} from '@models/Company';
import {
  AdditionalFiltersPayload,
  AdditionalFiltersResponse,
  AdditionalFiltersFailPayload,
} from '@optx/redux/company/addon-management/interfaces';
import { GridPayloadMain, GridKeys } from '@models/grid';
import { ViewSuccess, SearchState } from '@models/search';
import { RouteAliases } from '@models/routes';
import { ChartBarIntervalKeys } from '@models/charts/data';
import { SelectOption } from '@models/Option';
import { UpdateCompanyET } from '@models/equityTouch';
import { PreselectedFilter } from '@models/filters';
import { EquityTouchBulkSuccess } from '@models/bulkActions';
import {
  AddCompanyToWatchListBulk,
  AddCompanyToWatchList,
  DeleteCompanyFromWatchList,
  CompanyWatchList,
} from '@models/WatchList';
import { UserInformation } from '@models/user';
import {
  SaveResearchRationaleResponse,
  ScoreRationaleResponse,
} from '@features/add-research-rationale/state/interfaces';
import { SearchPayload } from '@models/api/contacts';
import { BulkEditFieldsSuccess } from '@features/bulk-actions/edit-fields/state/interfaces';
import { BulkSoftwareSuccess } from '@features/bulk-actions/bulk-software/state/interfaces';
import { BulkAiMlSuccess } from '@features/bulk-actions/bulk-ai-ml/state/interfaces';
import { EditCompanyFund } from '@components/feature/company-individual-edit/state/fund/interfaces';
import { EditOpportunityType } from '@components/feature/company-individual-edit/state/oportunityType/interfaces';
import { AiDescriptionResponse } from '@optx/redux/company/profile/interfaces';
import {
  CustomValue,
  EditFieldSuccess,
  MultipleFieldsValue,
  UpdateFieldsPayload,
  UpdateVerifyCompanyPayload,
} from '@features/company/edit-fields/state/interfaces';
import {
  TrendingLinesUpdate,
  EmployeeGrowthPercentage,
} from '@redux/company/user-values/interfaces';
import { DeleteTagPayload, SuccessCompanyTag } from '@redux/company/tags/interfaces';
import { CompanyActivePayload } from '@redux/company/active/interfaces';
import { OptxScoreRationale } from '@redux/company/score/interfaces';
import { TouchesFinancialPayload } from '@models/api/touches';
import { CompanyTouchSuccessResponse } from '@optx/models/company/CompanyTouch';
// constants
import { GRID_KEYS_SEARCH, SEARCH_INITIAL_STATE } from '@constants/grid';
import { YEARS_LABELS } from '@optx/constants/search';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import {
  DEFAULT_FUND_VALUE,
  DEFAULT_COMPANY_OWNER_VALUE,
  DEFAULT_OPPORTUNITY_TYPE,
} from '@constants/table/company';
import { ADD_TOUCH_LOCALSTORAGE_KEY_PREFIX } from '@optx/constants/autosave';
// utils
import {
  generateGridReducerInitialState,
  getLocationMainGridKey,
  getGridKeyFromReferral,
} from '@utils/grid';
import { generateDynamicColumnKeys } from '@optx/utils/columns';
import { updateRevenueList } from '@utils/company';
import { getRouteAlias } from '@utils/routes';
import { updateFinancialValues } from '@utils/editFields';
import { showRationaleForStage } from '@optx/utils/helpers';
import { deleteSavedValues } from '@optx/storage/localStorage/autoSaveStorage';
// redux
import * as actions from './actions';
import * as fetchReducers from '@redux/feature/fetch/reducers';
import { actions as paginationActions } from '@features/grid/pagination';
import { actions as viewActions } from '@features/grid/view';
import {
  createCompanyToListSuccess,
  deleteCompanyToListSuccess,
} from '@redux/common/list/reducers';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as researchRationaleActions } from '@features/add-research-rationale/state';
// eslint-disable-next-line max-len
import { actions as companyOportunityTypeActions } from '@components/feature/company-individual-edit/state/oportunityType';
import { actions as fundActions } from '@components/feature/company-individual-edit/state/fund';
import { actions as bulkEditActions } from '@features/bulk-actions/edit-fields';
import { actions as bulkSoftwareActions } from '@features/bulk-actions/bulk-software';
import { actions as bulkAiMlActions } from '@features/bulk-actions/bulk-ai-ml';
import { actions as bulkEqtActions } from '@features/bulk-actions/equity-touch';
import { actions as outreachSortActions } from '@redux/company-outreach/sort';
import { actions as outreachFilterActions } from '@redux/company-outreach/filters';
import { actions as myCompaniesFilterCardsActions } from '@optx/redux/my-companies/filters-cards';
import { actions as myCompaniesSortActions } from '@redux/my-companies/sort';
import * as favoriteListsActions from '@redux/favorite-lists/lists/actions';
import * as favoriteListsActionsPopup from '@redux/favorite-lists/lists-popup/actions';
import { actions as companyProfileActions } from '@redux/company/profile';
import { actions as fundingRoundActions } from '@redux/company/funding-rounds';
import { actions as companyEditFields } from '@features/company/edit-fields/state';
import { actions as companySortActions } from '@redux/company/search/sort';
import { applyViewSuccess as savedSearchesApplyViewSuccess } from '@redux/company/saved-searches/actions';
import { actions as inBusinessEvaluationActions } from '@redux/company/in-business';
import { actions as companyUserActions } from '@redux/company/user-values';
import { actions as equityTouchActions } from '@redux/company/equity-touch';
import { updateCompanyETData } from '@redux/company/equity-touch/reducer';
import { actions as tagsActions } from '@redux/company/tags';
import { actions as portfolioActions } from '@redux/company/portfolio';
import { addCompanyActiveSuccess } from '@redux/company/active/actions';
import {
  sendNewOptxScoreRationaleSuccess,
  sendOptxScoreRationaleSuccess,
} from '@redux/company/score/actions';
import { actions as addonActions } from '@redux/company/addon-management';
import { actions as updateCompanyInfoActions } from '@redux/ui/modals/company-touches';
import { actions as paginationLongCardActions } from '@features/grid/pagination-long-card';
import { actions as listsSortActions } from '@redux/lists/search/sort';
import { actions as editAllInfoActions } from '@optx/features/edit-all-info/state';
import { actions as opportunityPresentationActions } from '@redux/company/opportunity-presentation';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as presentationActions } from '@redux/company/opportunity-presentation/index';

const initialState: GridSearchState = generateGridReducerInitialState(
  GRID_KEYS_SEARCH,
  SEARCH_INITIAL_STATE
);

const initialSearchCompaniesReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<Partial<SearchPayload> | undefined>>
> = (draftState, action) => {
  const { gridKey } = action.payload;

  fetchReducers.fetchReducer(draftState[gridKey]);
};

const searchCompaniesReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<Partial<SearchState> | undefined>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  if (gridKey === 'addons' && data?.companyId) draftState[gridKey].companyId = data.companyId;

  fetchReducers.fetchReducer(draftState[gridKey]);
};

export const searchCompaniesSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<Company[], any, GridPayloadMain<Partial<SearchPayload>>>
) => {
  const {
    payload: gridSearchCompaniesResponse,
    meta: { gridKey, data },
  } = action;

  const allGridSearchCompaniesById = {} as Dictionary<Company>;
  let searchCompaniesResponse: Company[] = gridSearchCompaniesResponse;

  if (gridKey === 'advancedSearch') {
    const gridSearchCompaniesResponseMapped = gridSearchCompaniesResponse.map(company => {
      updateRevenueList(company);

      return {
        ...company,
        ebitda_year:
          (company.ebitda_year as unknown as string) === '-' ? null : company.ebitda_year,
        last_rev_update_year:
          (company.last_rev_update_year as unknown as string) === '-'
            ? null
            : company.last_rev_update_year,
      };
    });

    searchCompaniesResponse = gridSearchCompaniesResponseMapped;
  }

  searchCompaniesResponse.forEach((listItem: Company) => {
    allGridSearchCompaniesById[listItem.company_id] = listItem;
  });

  draftState[gridKey].byId = allGridSearchCompaniesById;
  draftState[gridKey].allIds = gridSearchCompaniesResponse.map(listItem => listItem.company_id);
  draftState[gridKey].loading = false;
  draftState[gridKey].fetchedAt = new Date().toISOString();
  draftState[gridKey].searchTitle = data.searchTitle;
  draftState[gridKey].listType = data.listType;

  if (typeof data.searchKey === 'string') draftState[gridKey].searchKey = data.searchKey;

  if (gridKey === 'addons') {
    draftState[gridKey].companyId = data.companyId;
  }
};

export const searchCompaniesFailReducer = (
  draftState: GridSearchState,
  action: PayloadAction<GridPayloadMain<string>>
) => {
  const { gridKey, data } = action.payload;
  fetchReducers.fetchFailReducer(draftState[gridKey], { payload: data, type: action.type });
};

export const fetchReducer = (draftState: GridSearchState) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  fetchReducers.fetchReducer(draftState[gridKey]);
};

export const fetchFailReducer = (draftState: GridSearchState, action: PayloadAction<string>) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  fetchReducers.fetchFailReducer(draftState[gridKey], action);
};

export const fetchSuccessLoadingReducer = (
  draftState: GridSearchState,
  action: PayloadAction<ViewSuccess>
) => {
  const { pageAlias } = action.payload;

  const gridKey = getLocationMainGridKey(pageAlias);
  if (!gridKey) return;

  fetchReducers.fetchSuccessLoadingReducer(draftState[gridKey]);
};

const cancelCompaniesSearchReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<boolean>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  draftState[gridKey].loading = data;

  draftState[gridKey].cancelled = data;
};

const shouldUpdateSourcingOutReachAnalystInfoReducer: CaseReducer<
  GridSearchState,
  PayloadAction<boolean>
> = (draftState, action) => {
  draftState.outreach.shouldUpdateSourcingOutReachAnalystInfo = action.payload;
};

const saveSearchTitleReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<string>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;
  const pageAlias: RouteAliases | null = getRouteAlias();

  if (pageAlias === 'advancedSearch') {
    draftState[gridKey].searchTitle = data;
  }
};

const deleteSearchTitleReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<string>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  if (draftState[gridKey].searchTitle === data) {
    draftState[gridKey].searchTitle = '';
  }
};

const hideCancelSearchReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<boolean>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  draftState[gridKey].hideCancelSearch = data;
};

const addToEquityTouchBulkSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<EquityTouchBulkSuccess>
> = (draftState, action) => {
  const gridKey = getLocationMainGridKey();
  if (gridKey) draftState[gridKey].fetchedAt = '';
};

const updateTrendingReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<ChartBarIntervalKeys>>
> = (draftState, action) => {
  const { gridKey, data } = action.payload;

  draftState[gridKey].trending = data;
};

const resetSearchKeyReducer: CaseReducer<GridSearchState> = draftState => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  draftState[gridKey].searchKey = '';
};

const updateSourcingOutReachAnalystInfoReducer: CaseReducer<
  GridSearchState,
  PayloadAction<{ tableGridQuery: string }>
> = (draftState, action) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  if (gridKey === 'outreach') draftState[gridKey].tableGridQuery = action.payload.tableGridQuery;
};

const fetchCompaniesByFilterReducer: CaseReducer<
  GridSearchState,
  PayloadAction<GridPayloadMain<PreselectedFilter>>
> = (draftState, action) => {
  const { gridKey } = action.payload;

  fetchReducers.fetchReducer(draftState[gridKey]);
};

// GRID Reducers

export const inBusinessEvaluationSuccessReducer = (
  draftState: GridSearchState, // advancedSearch && myCompanies
  action: PayloadAction<{
    score: number;
    score_v3: number;
    companyId: number;
    il_score: number;
    il_score_growth: number;
    isInBusiness: boolean;
  }>
) => {
  const { companyId, isInBusiness, score, il_score: IlScore, score_v3 } = action.payload;

  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    draftState[gridKey].byId[companyId]!.is_in_business = isInBusiness || false;
    draftState[gridKey].byId[companyId]!.score = score;
    draftState[gridKey].byId[companyId]!.score_v3 = score_v3;
    draftState[gridKey].byId[companyId]!.il_optx_score = IlScore;
  }
};

export const editFieldsSuccessReducer = (
  draftState: GridSearchState, // advancedSearch && myCompanies && addons
  action: PayloadAction<EditFieldSuccess>
) => {
  // if company is found in any of these grids, we
  // need to update the edited field with the new value
  const gridKeys: GridKeys[] = ['advancedSearch', 'lists', 'myCompanies', 'addons', 'outreach'];

  const {
    companyId,
    fieldName,
    value,
    score,
    scoreGrowth,
    newScore,
    newScoreGrowth,
    isPartner,
    companyOwnerData,
    ilScore,
    ilScoreGrowth,
    defaultOptxScore,
    isPsgCompany,
    raiseDate,
    lastRevenueGrowth,
  } = action.payload;

  const optionSelected = (value as UpdateFieldsPayload).value as SelectOption;
  const fieldKeyValue = (value as UpdateFieldsPayload).value;
  const fieldKey = (value as UpdateFieldsPayload).fieldKey as CompanyUserValuesKeys;
  const rationale = (value as UpdateFieldsPayload).rationale;

  gridKeys.forEach(gridKey => {
    const company = draftState[gridKey].byId[companyId];

    if (company) {
      if (defaultOptxScore === 'il') {
        if (ilScore !== undefined) {
          company.il_optx_score = ilScore;
        }

        if (typeof ilScoreGrowth === 'number') {
          company.il_score_growth = ilScoreGrowth.toString();
        }
      } else {
        if (score !== undefined) {
          company.score = score;
        }

        if (newScore !== undefined) {
          company.score_v3 = newScore;
        }

        if (typeof scoreGrowth === 'number') {
          company.score_growth = scoreGrowth.toString();
        }

        if (typeof newScoreGrowth === 'number') {
          company.score_v3_growth = newScoreGrowth.toString();
        }
      }

      if (!Array.isArray(fieldName)) {
        if (fieldName === 'Software Company') {
          company.is_software =
            ((value as UpdateFieldsPayload).value as unknown as boolean) || false;

          company.is_software_rationale = rationale as string;

          if (isPartner) {
            company.is_software_verified = false;
          } else {
            company.is_software_verified = true;
          }

          company.score = score as number;
          company.score_v3 = newScore as number;
        }

        if (fieldName === 'PSG Fit' && typeof score === 'number') {
          company.is_interesting = (value as UpdateFieldsPayload).value as unknown as boolean;

          company.interesting_rationale = rationale as string;

          company.score = score;

          if (typeof newScore === 'number') {
            company.score_v3 = newScore;
          }
        }

        if (fieldName === 'Company Owner') {
          if (companyOwnerData) {
            company.company_owner =
              companyOwnerData.company_owner_name === null
                ? DEFAULT_COMPANY_OWNER_VALUE
                : companyOwnerData.company_owner_name;
          } else {
            company.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
          }
        }

        if (fieldName === 'Sub-Sector') {
          company.sub_sector = optionSelected.label;
        }

        if (fieldName === 'Sector') {
          company.sector = optionSelected.label;
        }

        if (fieldName === 'Pipeline Rank') {
          company.pipeline_rank = optionSelected.label;
        }

        if (fieldName === 'Senior Advisor Thesis') {
          company.senior_advisor_thesis = optionSelected.label;
        }

        if (fieldName === 'Lead Source') {
          company.lead_source = optionSelected.label;
        }

        if (fieldName === 'Fund') {
          company.fund = optionSelected.label;
          company.is_psg_company = isPsgCompany as boolean;
        }

        if (fieldName === 'Stage') {
          // update previous stage before
          company.prev_stage = company.stage;
          company.stage = optionSelected.label;
          company.is_psg_company = isPsgCompany as boolean;

          if (showRationaleForStage(optionSelected.label)) {
            company.stage_rationale = rationale as string;
          } else {
            company.stage_rationale = null;
          }
        }

        if (fieldName === 'Number of Employees') {
          company[fieldKey!] = fieldKeyValue as never;
          company.score = score as number;
          company.score_v3 = newScore as number;
        }

        if (fieldName === 'Last Raised Amount') {
          company[fieldKey!] = fieldKeyValue as never;

          if (raiseDate) {
            company.raise_date = raiseDate;
          }
        }

        if (fieldName === 'Opportunity Type') {
          company.opportunity_type = optionSelected.label;
        }

        if (fieldName === 'Active on Pipeline') {
          company.active = (value as UpdateFieldsPayload).value as unknown as boolean;
        }

        if (fieldName === 'In Business') {
          company.is_in_business = (value as UpdateFieldsPayload).value as unknown as boolean;
        }

        if (fieldName === 'Last Funding Date') {
          company[fieldKey!] = fieldKeyValue as never;
        }

        if (fieldKey === 'ebitda_list' || fieldKey === 'revenue_list') {
          updateFinancialValues(company, fieldKey, fieldKeyValue as CustomValue);
          company.last_rev_growth =
            lastRevenueGrowth || lastRevenueGrowth === 0 ? lastRevenueGrowth?.toString() : null;
        }

        if (fieldKey === 'annual_recurring_revenue') {
          company[fieldKey!] = fieldKeyValue as never;
        }

        if (fieldKey === 'gross_margin_list') {
          company[fieldKey!] = fieldKeyValue as never;
        }

        if (fieldName === 'Capital Raised') {
          company[fieldKey!] = fieldKeyValue as never;
        }

        if (fieldName === 'Product Category') {
          const newCategories = ((value as UpdateFieldsPayload).value as string)
            .split(',')
            .map(item => ({
              category: item,
              is_valid: true,
            }));

          const previousCategories = [...(company.product_category || [])] as ProductCategory[];

          const combinedArray = newCategories.map(value => {
            const duplicateValue = previousCategories.find(
              item => item.category === value.category
            );

            return duplicateValue !== undefined ? duplicateValue : value;
          });

          company.product_category = ['blank'].includes(
            (value as UpdateFieldsPayload).value as string
          )
            ? null
            : combinedArray;
        }

        if (fieldName === 'Sub Vertical') {
          company.sub_vertical = (value as UpdateFieldsPayload).value as string[];
        }

        if (fieldName === 'Add-on') {
          const newAddon = ((value as UpdateFieldsPayload).value as CustomValue)
            .optionalValue as Addon;

          company.addon = newAddon;
          company.addon_bool = ((value as UpdateFieldsPayload).value as CustomValue)
            .value as boolean;
          draftState[gridKey].byId[companyId].addon = newAddon;
          draftState[gridKey].byId[companyId].addon_bool = (
            (value as UpdateFieldsPayload).value as CustomValue
          ).value as boolean;
        }

        if (fieldName === 'Date Presented') {
          const selectedValue = (value as UpdateFieldsPayload).value as string[];
          company.date_presented = selectedValue;
        }

        if (fieldName === 'Next Steps') {
          company.next_steps = optionSelected.label;
          company.next_steps_opinion = (value as UpdateFieldsPayload).extraValues
            ? ((value as UpdateFieldsPayload).extraValues as string[])[0]
            : null;
        }

        if (fieldName === 'Company Type') {
          company.company_type = optionSelected.label;
        }

        if (fieldName === 'Linkedin Profile') {
          company.linkedin = fieldKeyValue as never;
        }

        if (fieldName === 'Website Status') {
          company.url_status = fieldKeyValue ? 'active' : 'not active';
        }

        if (fieldName === 'Location') {
          const { city, country, state } = action.payload;
          company.city = city as string;
          company.country = country as string;
          company.state = state as string;
        }

        if (fieldName === 'AI / ML') {
          company.is_ai_ml = fieldKeyValue as boolean;
        }
      } else {
        if (fieldName.includes('Stage')) {
          const optionSelected = (value as MultipleFieldsValue).value.Stage;
          company.stage = optionSelected.label;
          company.is_psg_company = isPsgCompany as boolean;

          if (showRationaleForStage(optionSelected.label)) {
            company.stage_rationale = rationale as string;
          } else {
            company.stage_rationale = null;
          }
        }

        if (fieldName.includes('Pipeline Rank')) {
          const optionSelected = (value as MultipleFieldsValue).value['Pipeline Rank'];
          company.pipeline_rank = optionSelected.label;
        }

        if (fieldName.includes('Company Owner')) {
          if (companyOwnerData) {
            company.company_owner =
              companyOwnerData.company_owner_name === null
                ? DEFAULT_COMPANY_OWNER_VALUE
                : companyOwnerData.company_owner_name;
          } else {
            company.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
          }
        }
      }

      if (fieldName === 'Year Founded') {
        company.year_founded = optionSelected.value.toString();
      }
    }
  });
};

export const updateVerifiedSoftwareSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<UpdateVerifyCompanyPayload>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, value: isVerified } = action.payload;

  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    draftState[gridKey].byId[companyId]!.is_software_verified = isVerified || false;
  }
};

export const updateCompanyUserValuesSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<CompanyUserValue>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, fieldKey, value, lastRoundData, financialList } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    // when updating a company user value, the value returned
    // to update the store is either a string or a number
    if (fieldKey === 'last_raised_amount') {
      draftState[gridKey].byId[companyId]![fieldKey] = Number(value);
      draftState[gridKey].byId[companyId]!.last_round = lastRoundData?.last_round as string;
      draftState[gridKey].byId[companyId]!.last_round_id = lastRoundData?.last_round_id as number;

      if (lastRoundData?.parentcompany) {
        let data: string;
        if (lastRoundData?.parentcompany === 'blank') data = '';
        else data = lastRoundData.parentcompany;

        draftState[gridKey].byId[companyId]!.parentcompany = data;
      } else draftState[gridKey].byId[companyId]!.parentcompany = '';
    }

    if (financialList) {
      (draftState[gridKey].byId[companyId]![fieldKey] as FinancialFieldType[]) = financialList;
    }

    if (value === null) {
      (draftState[gridKey].byId[companyId]![fieldKey] as null) = value;
    }
  }
};

export const updateCompanyTrendLinesReducer = (
  draftState: GridSearchState,
  action: PayloadAction<TrendingLinesUpdate>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { employeeCountTrends, optxScoreTrends, companyId, newOptxScoreTrends } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    if (employeeCountTrends)
      draftState[gridKey].byId[companyId]!.employee_count_trends = employeeCountTrends;
    if (optxScoreTrends) draftState[gridKey].byId[companyId]!.optx_score_trends = optxScoreTrends;
    if (newOptxScoreTrends)
      draftState[gridKey].byId[companyId]!.optx_score_v3_trends = newOptxScoreTrends;
  }
};

export const updateCompanyEmployeeGrowthPercentageReducer = (
  draftState: GridSearchState,
  action: PayloadAction<EmployeeGrowthPercentage>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { empGrowthPercent, companyId } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    if (empGrowthPercent)
      draftState[gridKey].byId[companyId]!.emp_growth_percent = empGrowthPercent;
  }
};

export const updateCompanyETDataReducer = (
  draftState: GridSearchState,
  action: PayloadAction<UpdateCompanyET>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const {
    updateData: { companyId },
  } = action.payload;

  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    updateCompanyETData(draftState[gridKey].byId[companyId]!, action.payload);
  }
};

export const bulkSoftwareSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<{ data: BulkSoftwareSuccess[]; count: number; isAdminOrAnalyst?: boolean }>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { payload } = action;

  if (payload) {
    payload.data.forEach(item => {
      const {
        company_id: companyId,
        optx_score_growth: scoreGrowth,
        optx_score_trends: optxScoreTrends,
        optx_score: score,
        score_v3: newScore,
        optx_score_v3_trends: newOptxScoreTrends,
        score_v3_growth: newScoreGrowth,
        is_software: software,
        is_software_rationale: rationale,
      } = item;
      const company: Company | undefined = draftState[gridKey].byId[companyId];

      if (company) {
        draftState[gridKey].byId[companyId]!.optx_score_trends = optxScoreTrends;
        draftState[gridKey].byId[companyId]!.score = score;
        draftState[gridKey].byId[companyId]!.is_software = software;
        draftState[gridKey].byId[companyId]!.score_growth = scoreGrowth;
        draftState[gridKey].byId[companyId]!.is_software_rationale = rationale as string;
        draftState[gridKey].byId[companyId]!.score_v3 = newScore;
        draftState[gridKey].byId[companyId]!.score_v3_growth = newScoreGrowth;
        draftState[gridKey].byId[companyId]!.optx_score_v3_trends = newOptxScoreTrends;

        if (payload.isAdminOrAnalyst) {
          draftState[gridKey].byId[companyId]!.is_software_verified = true;
        } else {
          draftState[gridKey].byId[companyId]!.is_software_verified = false;
        }
      }
    });
  }
};

export const bulkAiMlSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<{ data: BulkAiMlSuccess[]; count: number }>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { payload } = action;

  if (payload && payload.data) {
    payload.data.forEach(item => {
      const { company_id: companyId, is_ai_ml: aiMl } = item;
      const company: Company | undefined = draftState[gridKey].byId[companyId];

      if (company) {
        draftState[gridKey].byId[companyId]!.is_ai_ml = aiMl;
      }
    });
  }
};

export const addCompanyTagSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<SuccessCompanyTag>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, ...tag } = action.payload;

  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    // Add empty list if is null.
    if (!draftState[gridKey].byId[companyId]!.specialties) {
      draftState[gridKey].byId[companyId]!.specialties = [];
    }

    if (Array.isArray(draftState[gridKey].byId[companyId]!.specialties)) {
      draftState[gridKey].byId[companyId]!.specialties?.push(tag);
    }

    draftState[gridKey].byId[companyId]!.cs_tags += `|${tag.tag}`;
  }
};

export const deleteCompanyTagSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<DeleteTagPayload>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { label, companyId } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    const csTagsArray = draftState[gridKey].byId[companyId]!.cs_tags?.split('|').slice(1);
    const tagIndex = csTagsArray.findIndex(item => item === label);

    if (tagIndex !== -1) {
      const removeTag = csTagsArray?.splice(tagIndex, 1);
      draftState[gridKey].byId[companyId]!.cs_tags = draftState[gridKey].byId[
        companyId
      ]!.cs_tags.replace(`|${removeTag.join('|')}`, '');
    }
  }
};

export const addFundReducer = (
  draftState: GridSearchState,
  action: PayloadAction<EditCompanyFund>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, fundName } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    draftState[gridKey].byId[companyId]!.fund = fundName === null ? DEFAULT_FUND_VALUE : fundName;
  }
};

export const addCompanyPortfolioSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<{ company_id: number; is_portfolio?: boolean }>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { company_id: companyId, is_portfolio: isPortfolio } = action.payload;

  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    if (draftState[gridKey].byId[companyId]!.company_id === companyId) {
      draftState[gridKey].byId[companyId]!.is_portfolio = isPortfolio ?? false;
      draftState[gridKey].byId[companyId]!.is_platform = isPortfolio ?? false;
      // its for tags. is_portfolio and is_platform used for the same thing.
    }
  }
};

export const addCompanyToListBulkSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<AddCompanyToWatchListBulk>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyIds, watchLists, ownerName } = action.payload;

  companyIds.map(companyId => {
    const company: Company | undefined = draftState[gridKey].byId[companyId];

    if (company) {
      const companyData = createCompanyToListSuccess(
        draftState[gridKey].byId[companyId]!,
        watchLists,
        true,
        ownerName
      );
      draftState[gridKey].byId[companyId] = companyData;
    }

    return null;
  });
};

export const addCompanyToListSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<AddCompanyToWatchList>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { watchLists, companyId } = action.payload;
  let company = draftState[gridKey].byId[companyId];

  if (company) {
    watchLists.forEach(list => {
      company = createCompanyToListSuccess(company as Company, list);
    });

    draftState[gridKey].byId[companyId] = company;
  }
};

export const deleteCompanyToListSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<DeleteCompanyFromWatchList>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { listId, companyId } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    const companyData = deleteCompanyToListSuccess(draftState[gridKey].byId[companyId]!, listId);
    draftState[gridKey].byId[companyId] = companyData;
  }
};

export const createCompanyToListSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<{
    companyId: number;
    list: CompanyWatchList;
  }>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, list } = action.payload;
  const company: Company | undefined =
    companyId !== undefined ? draftState[gridKey].byId[companyId] : undefined;

  if (company) {
    const companyData = createCompanyToListSuccess(
      draftState[gridKey].byId[companyId as number]!,
      list
    );
    draftState[gridKey].byId[companyId as number] = companyData;
  }
};

export const fetchUserInformationSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<UserInformation>
) => {
  const pageAlias: RouteAliases | null = getRouteAlias();
  const userSettings = action.payload.settings;

  if (pageAlias === 'companyOutReach') {
    const persistedSearch = userSettings.session_settings
      ? action.payload.settings.session_settings.sourcing_outreach_detail_filters
      : undefined;

    if (persistedSearch !== undefined && /^.*query.*$/.test(persistedSearch)) {
      const filter = queryString.parse(persistedSearch);
      draftState.outreach.searchKey = filter.query as string;
    }
  }

  if (pageAlias === 'myCompanies') {
    const persistedSearch = userSettings.session_settings
      ? action.payload.settings.session_settings.my_companies_filters
      : undefined;

    if (userSettings.session_settings.my_companies_trending) {
      draftState.myCompanies.trending = userSettings.session_settings.my_companies_trending;
    }

    if (persistedSearch !== undefined && /^.*query.*$/.test(persistedSearch)) {
      const filter = queryString.parse(persistedSearch);
      draftState.myCompanies.searchKey = filter.query as string;
    }
  }

  if (pageAlias === 'advancedSearch') {
    if (userSettings.session_settings.search_trending) {
      draftState.advancedSearch.trending = userSettings.session_settings.search_trending;
    }
  }

  if (pageAlias === 'userLists') {
    if (userSettings.session_settings.user_list_trending) {
      draftState.lists.trending = userSettings.session_settings.user_list_trending;
    }
  }
};

export const activeSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<CompanyActivePayload>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, active } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    draftState[gridKey].byId[companyId]!.active = active || false;
  }
};

export const addOpportunityTypeSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<EditOpportunityType>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { companyId, opportunityTypeName, scoreValues } = action.payload;
  const company: Company | undefined = draftState[gridKey].byId[companyId];

  if (company) {
    draftState[gridKey].byId[companyId]!.senior_advisor_thesis =
      opportunityTypeName === null ? DEFAULT_OPPORTUNITY_TYPE : opportunityTypeName;

    if (scoreValues) {
      draftState[gridKey].byId[companyId]!.score = scoreValues.optx_score;
      draftState[gridKey].byId[companyId]!.score_growth =
        scoreValues.optx_score_growth?.toString() || null;
      draftState[gridKey].byId[companyId]!.score_v3 = scoreValues.score_v3;
      draftState[gridKey].byId[companyId]!.score_v3_growth =
        scoreValues.score_v3_growth?.toString() || null;
      draftState[gridKey].byId[companyId]!.il_optx_score = scoreValues.il_optx_score;
      draftState[gridKey].byId[companyId]!.il_score_growth =
        scoreValues.il_optx_score_growth?.toString() || null;
    }
  }
};

export const submitResearchRationaleSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<SaveResearchRationaleResponse>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const {
    company_id: companyId,
    fields,
    scoreValues,
    scoreRationale,
    newScoreRationale,
  } = action.payload;

  if (draftState[gridKey].byId[companyId]) {
    if (fields.psg_fit) {
      draftState[gridKey].byId[companyId]!.is_interesting = fields.psg_fit.value as boolean | null;
      draftState[gridKey].byId[companyId]!.interesting_rationale = fields.psg_fit.rationale as
        | string
        | null;
      // score values for default score
      draftState[gridKey].byId[companyId]!.score = scoreValues?.fitScore as number;
      draftState[gridKey].byId[companyId]!.score_growth =
        scoreValues?.fitScoreGrowth?.toString() || null;
      // core values for new optx score
      draftState[gridKey].byId[companyId]!.score_v3 = scoreValues?.fitNewScore as number;
      draftState[gridKey].byId[companyId]!.score_v3_growth =
        scoreValues?.fitNewScoreGrowth?.toString() || null;
      // score values for israel score
      draftState[gridKey].byId[companyId]!.il_optx_score = scoreValues?.fitIsraelScore as number;
      draftState[gridKey].byId[companyId]!.il_score_growth =
        scoreValues?.fitIsraelScoreGrowth?.toString() || null;
    }

    if (fields.software_company) {
      if (!draftState[gridKey].byId[companyId]!.is_software_verified) {
        draftState[gridKey].byId[companyId]!.is_software_verified =
          !draftState[gridKey].byId[companyId]!.is_software_verified;
      }

      draftState[gridKey].byId[companyId]!.is_software = fields.software_company.value as boolean;
      draftState[gridKey].byId[companyId]!.is_software_rationale = fields.software_company
        .rationale as string | null;
      // score values for default score
      draftState[gridKey].byId[companyId]!.score = scoreValues?.softwareScore as number;
      draftState[gridKey].byId[companyId]!.score_growth =
        scoreValues?.softwareScoreGrowth?.toString() || null;
      // score values for new opt score
      draftState[gridKey].byId[companyId]!.score_v3 = scoreValues?.softwareNewScore as number;
      draftState[gridKey].byId[companyId]!.score_v3_growth =
        scoreValues?.softwareNewScoreGrowth?.toString() || null;
      // score values for israel score
      draftState[gridKey].byId[companyId]!.il_optx_score =
        scoreValues?.softwareIsraelScore as number;
      draftState[gridKey].byId[companyId]!.il_score_growth =
        scoreValues?.softwareIsraelScoreGrowth?.toString() || null;
    }

    // if api returns false for this property, it means no verification was sent for optx score
    if (scoreRationale !== false) {
      if ((scoreRationale as ScoreRationaleResponse).score_type === 'il_optx_score') {
        draftState[gridKey].byId[companyId]!.il_optx_score_rationale = (
          scoreRationale as ScoreRationaleResponse
        ).rationale;
        draftState[gridKey].byId[companyId]!.il_optx_score_rationale_reason = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason;
        draftState[gridKey].byId[companyId]!.il_optx_score_rationale_reason_type = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason_type;
        draftState[gridKey].byId[companyId]!.il_optx_score_verified = (
          scoreRationale as ScoreRationaleResponse
        ).optx_score_verified;
      } else {
        draftState[gridKey].byId[companyId]!.optx_score_rationale = (
          scoreRationale as ScoreRationaleResponse
        ).rationale;
        draftState[gridKey].byId[companyId]!.optx_score_rationale_reason = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason;
        draftState[gridKey].byId[companyId]!.optx_score_rationale_reason_type = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason_type;
        draftState[gridKey].byId[companyId]!.optx_score_verified = (
          scoreRationale as ScoreRationaleResponse
        ).optx_score_verified;
      }
    }

    if (
      newScoreRationale !== false &&
      (newScoreRationale as ScoreRationaleResponse).score_type === 'score_v3'
    ) {
      draftState[gridKey].byId[companyId]!.optx_score_v3_rationale = (
        newScoreRationale as ScoreRationaleResponse
      ).rationale;
      draftState[gridKey].byId[companyId]!.optx_score_v3_rationale_reason = (
        newScoreRationale as ScoreRationaleResponse
      ).rationale_reason;
      draftState[gridKey].byId[companyId]!.optx_score_v3_rationale_reason_type = (
        newScoreRationale as ScoreRationaleResponse
      ).rationale_reason_type;
      draftState[gridKey].byId[companyId]!.optx_score_v3_verified = (
        newScoreRationale as ScoreRationaleResponse
      ).optx_score_verified;
    }
  }
};

export const gridSendOptxScoreRationaleSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<OptxScoreRationale>
) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const {
    companyId,
    rationale,
    defaultScore,
    optxScoreRationaleReason,
    optxScoreRationaleReasonType,
    optxScoreVerified,
    scoreType,
  } = action.payload;

  if (
    draftState[gridKey].byId[companyId] &&
    draftState[gridKey].byId[companyId]!.company_id === companyId
  ) {
    if (defaultScore === 'us') {
      if (scoreType === 'score_v3') {
        draftState[gridKey].byId[companyId]!.optx_score_v3_rationale = rationale;
        draftState[gridKey].byId[companyId]!.optx_score_v3_verified = optxScoreVerified;
        draftState[gridKey].byId[companyId]!.optx_score_v3_rationale_reason =
          optxScoreRationaleReason;
        draftState[gridKey].byId[companyId]!.optx_score_v3_rationale_reason_type =
          optxScoreRationaleReasonType;
      } else {
        draftState[gridKey].byId[companyId]!.optx_score_rationale = rationale;
        draftState[gridKey].byId[companyId]!.optx_score_verified = optxScoreVerified;
        draftState[gridKey].byId[companyId]!.optx_score_rationale_reason = optxScoreRationaleReason;
        draftState[gridKey].byId[companyId]!.optx_score_rationale_reason_type =
          optxScoreRationaleReasonType;
      }
    }

    if (defaultScore === 'il') {
      draftState[gridKey].byId[companyId]!.il_optx_score_rationale = rationale;
      draftState[gridKey].byId[companyId]!.il_optx_score_verified = optxScoreVerified;
      draftState[gridKey].byId[companyId]!.il_optx_score_rationale_reason =
        optxScoreRationaleReason;
      draftState[gridKey].byId[companyId]!.il_optx_score_rationale_reason_type =
        optxScoreRationaleReasonType;
    }
  }
};

// BULK

// reducer used to update company grids with new values for edited fields
const bulkEditFieldSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<BulkEditFieldsSuccess>
) => {
  const gridKey = getLocationMainGridKey(null, action.payload.companyIds);
  if (!gridKey) return;

  let { field, companyIds, selectedData, additionalInfo, rationale, additionalAddonId } =
    action.payload;
  const companies = Object.values(draftState[gridKey].byId);

  // special case for company owner.
  // we need to send company_owner_id to api,
  // but in grid we need to update company_owner value
  if (field === 'company_owner_id') {
    field = 'company_owner';
  }

  if (companies.length && field !== 'rationale') {
    companyIds.forEach(id => {
      const company: Company | undefined = draftState[gridKey].byId[id];
      let companyInfo = null;

      if (field === 'company_owner' && additionalInfo) {
        companyInfo = additionalInfo.find(company => company.company_id === id);
      }

      if (company) {
        const label =
          (selectedData[0] as SelectOption).label === 'None'
            ? '-'
            : (selectedData[0] as SelectOption).label;

        if (field === 'product_category') {
          // typescript throws an error because properties fund and rationale don't exist
          // on type Company. we are inside a condition that checks for these two properties

          const categories = (selectedData as SelectOption[]).map(data => ({
            category: data.label,
            is_valid: true,
          }));

          const alphabeticalCategories = categories.sort((a, b) => {
            if (a.category < b.category) {
              return -1;
            }

            if (a.category > b.category) {
              return 1;
            }

            return 0;
          });

          draftState[gridKey].byId[id]![field] = [...alphabeticalCategories];
        }

        if (field === 'cs_tags') {
          const tags = `|${(selectedData as SelectOption<string>[])
            .map(data => data.label)
            .join('|')}`;

          if (draftState[gridKey].byId[id]![field] === '-') {
            draftState[gridKey].byId[id]![field] = tags;
          } else {
            draftState[gridKey].byId[id]![field] += tags;
          }
        } else if (field !== 'product_category') {
          if (field === 'stage') {
            // update previous stage before
            const prevStage = draftState[gridKey].byId[id].stage;
            draftState[gridKey].byId[id].prev_stage = prevStage;

            if (showRationaleForStage(label)) {
              // @ts-ignore
              draftState[gridKey].byId[id].stage_rationale = rationale as string;
            } else {
              // @ts-ignore
              draftState[gridKey].byId[id].stage_rationale = null;
            }
          } else if (field === 'addon') {
            const hasBlankOption = (selectedData as SelectOption[]).some(
              option => option.value === 'None'
            );

            let addons: Addon | null = null;

            if (!hasBlankOption) {
              const currentAddons = draftState[gridKey].byId[id][field];

              const newAddons = (selectedData as SelectOption[]).reduce((acc, curr) => {
                return { ...acc, [curr.value]: curr.id };
              }, {});

              addons = { ...currentAddons, ...newAddons };
            }

            draftState[gridKey].byId[id][field] = addons;
            draftState[gridKey].byId[id].addon_bool = additionalAddonId ? true : false;
          } else if (field === 'company_owner' && companyInfo) {
            if (typeof companyInfo.score === 'number') {
              draftState[gridKey].byId[id].score = companyInfo.score;
            }

            if (typeof companyInfo.score_v3 === 'number') {
              draftState[gridKey].byId[id].score_v3 = companyInfo.score_v3;
            }

            if (typeof companyInfo.il_optx_score !== 'undefined') {
              draftState[gridKey].byId[id].il_optx_score = isNaN(Number(companyInfo.il_optx_score))
                ? draftState[gridKey].byId[id].il_optx_score
                : Number(companyInfo.il_optx_score);
            }

            if (typeof companyInfo.score_growth === 'number') {
              draftState[gridKey].byId[id].score_growth = companyInfo.score_growth.toString();
            }

            if (typeof companyInfo.score_v3_growth === 'number') {
              draftState[gridKey].byId[id].score_v3_growth = companyInfo.score_v3_growth.toString();
            }

            if (typeof companyInfo.il_score_growth === 'number') {
              draftState[gridKey].byId[id].il_score_growth = companyInfo.il_score_growth.toString();
            }

            if (typeof companyInfo.optx_score_trends !== 'undefined') {
              draftState[gridKey].byId[id].optx_score_trends = companyInfo.optx_score_trends;
            }

            if (typeof companyInfo.optx_score_v3_trends !== 'undefined') {
              draftState[gridKey].byId[id].optx_score_v3_trends = companyInfo.optx_score_v3_trends;
            }

            if (typeof companyInfo.il_optx_score_trends !== 'undefined') {
              draftState[gridKey].byId[id].il_optx_score_trends = companyInfo.il_optx_score_trends;
            }
          } else {
            // @ts-ignore
            draftState[gridKey].byId[id][field] = label;
          }
        }
      }
    });
  }
};

const removeCompanyFromAddonManagementReducer = (draftState: GridSearchState) => {
  fetchReducers.fetchReducer(draftState.addons);
};

const removeCompanyFromAddonManagementSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<{ companyId: number }>
) => {
  const { companyId } = action.payload;

  const companyIsPresent = draftState.addons.allIds?.find(id => id === companyId);

  if (companyIsPresent) {
    draftState.addons.allIds = draftState.addons.allIds?.filter(id => id !== companyId) ?? null;
    draftState.addons.byId = omit(draftState.addons.byId, companyId);

    draftState.addons.total -= 1;
  }

  draftState.addons.loading = false;
};

const removeCompanyFromAddonManagementFailReducer: CaseReducer<
  GridSearchState,
  PayloadAction<string>
> = (draftState, action) => {
  fetchReducers.fetchFailReducer(draftState.addons, {
    payload: action.payload,
    type: action.type,
  });
};

const fetchAddonAdditionalFiltersReducer = (
  draftState: GridSearchState,
  action: PayloadAction<AdditionalFiltersPayload>
) => {
  const { shouldBeAddedToLoading } = action.payload;

  if (shouldBeAddedToLoading) {
    fetchReducers.fetchReducer(draftState.addons);
  }
};

const fetchAddonAdditionalFiltersFailReducer = (
  draftState: GridSearchState,
  action: PayloadAction<AdditionalFiltersFailPayload>
) => {
  const { error, shouldBeAddedToLoading } = action.payload;

  if (shouldBeAddedToLoading) {
    fetchReducers.fetchFailReducer(draftState.addons, {
      payload: error,
      type: action.type,
    });
  }
};

const fetchAddonAdditionalFiltersSuccessReducer = (
  draftState: GridSearchState,
  action: PayloadAction<AdditionalFiltersResponse>
) => {
  const { shouldBeAddedToLoading } = action.payload;

  if (shouldBeAddedToLoading) {
    draftState.addons.loading = false;
  }
};

const clearAddonManagementFetchedAtReducer = (draftState: GridSearchState) => {
  draftState.addons.fetchedAt = '';
};

const updateCompanyInfoSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { company_id: companyId } = payload;

  if (companyId && draftState[gridKey].byId[companyId]) {
    if (payload.general_info) {
      draftState[gridKey].byId[companyId]!.num_employees =
        payload.general_info?.num_employees ?? null;
    }

    if (payload.funding) {
      draftState[gridKey].byId[companyId]!.raise_date = payload.funding.last_round_date ?? '';
      draftState[gridKey].byId[companyId]!.last_round = payload.funding.last_round?.label ?? '';
      draftState[gridKey].byId[companyId]!.last_round_id =
        payload.funding.last_round?.value ?? null;
      draftState[gridKey].byId[companyId]!.last_raised_amount =
        payload.funding.last_round_amount ?? null;
      draftState[gridKey].byId[companyId]!.last_investors = payload.funding.last_investors ?? [];
      draftState[gridKey].byId[companyId]!.parentcompany = payload.funding.acquiring_company ?? '';
    }
  }
};

const addTouchToCompanySuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<CompanyTouchSuccessResponse>
> = (draftState, { payload }) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const {
    companyid: companyId,
    next_touch_date: nextTouchDate,
    last_touch_date: lastTouchDate,
    iscomplete: isComplete,
  } = payload;

  if (companyId && draftState[gridKey].byId[companyId]) {
    if (isComplete) {
      draftState[gridKey].byId[companyId]!.last_touch_date = lastTouchDate;
    } else {
      draftState[gridKey].byId[companyId]!.next_touch_date = nextTouchDate;
    }
  }

  deleteSavedValues(ADD_TOUCH_LOCALSTORAGE_KEY_PREFIX, companyId);
};

const updateAllCompanyEditAllInfoSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const { company_id: companyId } = payload;

  if (companyId && draftState[gridKey].byId[companyId]) {
    draftState[gridKey].byId[companyId] = {
      ...draftState[gridKey].byId[companyId],
      ...payload,
    };
  }
};

const companyFundingRoundsSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<CompanyFundingRounds, any, number>
> = (draftState, action) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;

  const companyId = action.meta;

  if (draftState[gridKey].byId[companyId]) {
    const { total_raised, last_round } = action.payload;

    if (total_raised !== null) {
      draftState[gridKey].byId[companyId].capital_raised = total_raised;
      draftState[gridKey].byId[companyId].is_capital_raise_has_unknown =
        total_raised.includes('(plus Unknown)');
    }

    if (last_round?.amount !== null) {
      draftState[gridKey].byId[companyId].last_raised_amount =
        last_round?.amount as unknown as number;
    }
  }
};

const aiDescriptionSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<AiDescriptionResponse>
> = (draftState, action) => {
  const gridKey = getLocationMainGridKey();
  if (!gridKey) return;
  const { description, companyId } = action.payload;

  if (typeof companyId === 'number') {
    if (draftState[gridKey].byId[companyId]) {
      draftState[gridKey].byId[companyId].company_description = description;
    }
  }
};

const updateGridFinancialSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<OpportunityPresentation>
> = (draftState, { payload }) => {
  const gridKey = getGridKeyFromReferral();
  const updatedGridKey = gridKey === 'sslists' || gridKey === 'watchlists' ? 'lists' : gridKey;

  if (!gridKey) return;

  const { company_id: companyId, financial, deal_preparation } = payload;

  const company = draftState[updatedGridKey].byId[companyId];

  if (company) {
    if (financial) {
      const { revenue_model: revenueModel, cashflow } = financial as UpdateOpporutunityFinancials;
      const {
        banker,
        banker_name: bankerName,
        asking_amount: askingAmount,
      } = payload.deal_preparation as UpdateOpportunityDealPreparation;

      const {
        delivery_model: deliveryModel,
        architecture,
        cloud_provider: cloudProvider,
      } = payload.technology as UpdateOpportunityTechnology;

      const {
        sales_cycle: salesCycle,
        customer_type: customerType,
        sales_and_marketing: salesAndMarketing,
      } = payload.customer_kpis as UpdateOpportunityCustomerKpis;

      if (revenueModel) {
        company.revenue_model = revenueModel.value as string[] | null;
      }

      if (salesCycle) {
        company.sales_cycle = salesCycle.value as string[] | null;
      }

      if (customerType) {
        company.customer_type = (customerType.value as string[]) || [];

        if (
          customerType.extra_data &&
          typeof customerType.extra_data.customer_other_rationale === 'string'
        ) {
          company.customer_other_rationale = customerType.extra_data.customer_other_rationale;
        }
      }

      if (salesAndMarketing) {
        company.sales_and_marketing = salesAndMarketing.value as string;
      }

      if (deliveryModel) {
        company.delivery_model = deliveryModel.value as string[] | null;
      }

      if (cloudProvider) {
        company.cloud_provider = cloudProvider.value as string;
      }

      if (architecture) {
        company.architecture = architecture.value as string[] | null;
      }

      if (banker || bankerName) {
        company.banker = banker.value as boolean | null;
        company.banker_name = bankerName;
      }

      if (cashflow) {
        company.cashflow = cashflow.value as string;
      }

      if (askingAmount) {
        company.asking_amount = askingAmount as string;
      }

      if (financial.revenue) {
        company.revenue_list = financial.revenue
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }

      if (financial.ebitda) {
        company.ebitda_list = financial.ebitda
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }

      if (financial.annual_recurring_revenue) {
        company.annual_recurring_revenue = financial.annual_recurring_revenue
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }

      if (financial.gm) {
        company.gross_margin_list = financial.gm
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }
    }

    if (deal_preparation) {
      if (deal_preparation.asking_amount || deal_preparation.asking_amount === null) {
        company.asking_amount = deal_preparation.asking_amount as string;
      }

      if (deal_preparation.deal_type) {
        company.deal_type = deal_preparation.deal_type.value as string;
      }
    }
  }
};

const fetchOpportunityPresentationSuccessReducer: CaseReducer<
  GridSearchState,
  PayloadAction<OpportunityPresentation | null>
> = (draftState, { payload }) => {
  const gridKey = getLocationMainGridKey();

  if (!payload?.financial) return;

  if (!gridKey) return;

  const { company_id: companyId, financial } = payload;

  const company = draftState[gridKey].byId[companyId];

  const currentYearGrowth = financial.growth_full.find(
    item => item.year === Number(YEARS_LABELS.current)
  )?.value;
  const listOfYearsGrowth = financial.growth_full.map(item => item.year);

  const mostRecentYear = Math.max(...listOfYearsGrowth);

  const mostRecentYearGrowth = financial.growth_full.find(
    item => item.year === mostRecentYear
  )?.value;

  if (company) {
    const currentGrowthYear = `${generateDynamicColumnKeys('growth', 'current')}` as keyof Company;

    (company[currentGrowthYear] as number | string) = currentYearGrowth || DEFAULT_CELL_VALUE;
    company.last_rev_growth = mostRecentYearGrowth?.toString() || null;
  }
};

const setLoaderReducer: CaseReducer<GridSearchState> = draftState => {
  draftState.advancedSearch.loading = true;
};

const reducer = createReducer<GridSearchState>(initialState, builder =>
  builder
    .addCase(actions.initialCompaniesSearch, initialSearchCompaniesReducer)
    .addCase(actions.searchCompanies, searchCompaniesReducer)
    .addCase(actions.searchCompaniesSuccess, searchCompaniesSuccessReducer)
    .addCase(actions.searchCompaniesFail, searchCompaniesFailReducer)
    .addCase(actions.cancelCompanySearch, cancelCompaniesSearchReducer)
    .addCase(actions.hideCancelSearch, hideCancelSearchReducer)
    .addCase(actions.updateTrending, updateTrendingReducer)
    .addCase(actions.clearSearch, fetchReducer)
    .addCase(actions.resetSearch, fetchReducer)
    .addCase(actions.loadSavedSearch, fetchReducer)
    .addCase(actions.loadSuggestedSearch, fetchReducer)
    .addCase(actions.deleteSavedList, deleteSearchTitleReducer)
    // clear filter
    .addCase(actions.resetFilter, fetchReducer)
    // load autocomplete search
    .addCase(actions.loadAutocompleteSearch, fetchReducer)
    .addCase(actions.loadCompaniesByFilter, fetchCompaniesByFilterReducer)
    // add the search title to the store
    .addCase(actions.saveSearchTitle, saveSearchTitleReducer)
    // external reducers
    .addCase(actions.removeFilter, fetchReducer)
    .addCase(actions.updateSourcingOutReachAnalystInfo, updateSourcingOutReachAnalystInfoReducer)
    .addCase(
      actions.shouldUpdateSourcingOutReachAnalystInfo,
      shouldUpdateSourcingOutReachAnalystInfoReducer
    )
    .addCase(viewActions.applyView, fetchReducer)

    .addCase(outreachSortActions.changeSortAction, fetchReducer)
    .addCase(outreachSortActions.resetSortAction, fetchReducer)
    .addCase(outreachFilterActions.resetStoreFilter, resetSearchKeyReducer)
    .addCase(outreachFilterActions.changeCardSubfilter, fetchReducer)
    .addCase(outreachFilterActions.changeCard, fetchReducer)

    .addCase(myCompaniesFilterCardsActions.initializeMyCompaniesCards, fetchReducer)
    .addCase(myCompaniesFilterCardsActions.fetchMyCompaniesCards, fetchReducer)
    .addCase(myCompaniesFilterCardsActions.changeCardSubfilter, fetchReducer)
    .addCase(myCompaniesFilterCardsActions.changeCard, fetchReducer)
    .addCase(myCompaniesFilterCardsActions.fetchMyCompaniesCardsFail, fetchFailReducer)
    .addCase(myCompaniesSortActions.changeSortAction, fetchReducer)

    .addCase(listsSortActions.changeSortAction, fetchReducer)
    .addCase(companySortActions.resetSortAction, fetchReducer)
    .addCase(paginationActions.changePagination, fetchReducer)
    .addCase(savedSearchesApplyViewSuccess, fetchSuccessLoadingReducer)
    .addCase(
      inBusinessEvaluationActions.addCompanyInBusinessEvaluationSuccess,
      inBusinessEvaluationSuccessReducer
    )
    .addCase(companyEditFields.updateFieldsSuccess, editFieldsSuccessReducer)
    .addCase(
      updateCompanyInfoActions.updateTouchCompanyInfoSuccess,
      updateCompanyInfoSuccessReducer
    )
    .addCase(updateCompanyInfoActions.addTouchToCompanySuccess, addTouchToCompanySuccessReducer)

    .addCase(editAllInfoActions.updateCompanyEditAllInfoSuccess, updateCompanyInfoSuccessReducer)
    .addCase(
      editAllInfoActions.updateAllCompanyEditAllInfoSuccess,
      updateAllCompanyEditAllInfoSuccessReducer
    )
    .addCase(
      companyEditFields.updateVerifyCompanySoftwareSuccess,
      updateVerifiedSoftwareSuccessReducer
    )
    .addCase(
      companyUserActions.updateCompanyUserValuesSuccess,
      updateCompanyUserValuesSuccessReducer
    )
    .addCase(companyUserActions.updateCompanyTrendingLines, updateCompanyTrendLinesReducer)
    .addCase(
      companyUserActions.updateCompanyEmployeeGrowthPercentage,
      updateCompanyEmployeeGrowthPercentageReducer
    )
    .addCase(equityTouchActions.updateCompanyETData, updateCompanyETDataReducer)
    .addCase(bulkEditActions.bulkEditFieldSuccess, bulkEditFieldSuccessReducer)
    .addCase(bulkSoftwareActions.bulkSoftwareSuccess, bulkSoftwareSuccessReducer)
    .addCase(bulkAiMlActions.bulkAiMlSuccess, bulkAiMlSuccessReducer)
    .addCase(bulkEqtActions.addToEquityTouchBulkSuccess, addToEquityTouchBulkSuccessReducer)
    .addCase(tagsActions.addCompanyTagSuccess, addCompanyTagSuccessReducer)
    .addCase(tagsActions.deleteCompanyTagSuccess, deleteCompanyTagSuccessReducer)
    .addCase(fundActions.addFund, addFundReducer)
    .addCase(portfolioActions.updateCompanyPortfolioSuccess, addCompanyPortfolioSuccessReducer)
    .addCase(
      favoriteListsActions.addCompanyToListBulkActionSuccess,
      addCompanyToListBulkSuccessReducer
    )
    .addCase(favoriteListsActions.addCompanyToListSuccess, addCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.deleteCompanyFromListSuccess, deleteCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.createFavoriteListSuccess, createCompanyToListSuccessReducer)
    .addCase(
      favoriteListsActionsPopup.addCompanyToListPopupBulkActionSuccess,
      addCompanyToListBulkSuccessReducer
    )
    .addCase(favoriteListsActionsPopup.addCompanyToListPopupSuccess, addCompanyToListSuccessReducer)
    .addCase(
      favoriteListsActionsPopup.deleteCompanyFromListPopupSuccess,
      deleteCompanyToListSuccessReducer
    )
    .addCase(
      favoriteListsActionsPopup.createFavoriteListPopupSuccess,
      createCompanyToListSuccessReducer
    )
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(addCompanyActiveSuccess, activeSuccessReducer)
    .addCase(
      companyOportunityTypeActions.addOpportunityTypeSuccess,
      addOpportunityTypeSuccessReducer
    )
    .addCase(
      opportunityPresentationActions.updateOpportunityPresentationSuccess,
      updateGridFinancialSuccessReducer
    )
    .addCase(
      presentationActions.fetchOpportunityPresentationSuccess,
      fetchOpportunityPresentationSuccessReducer
    )
    .addCase(
      researchRationaleActions.submitResearchRationaleSuccess,
      submitResearchRationaleSuccessReducer
    )
    .addCase(sendOptxScoreRationaleSuccess, gridSendOptxScoreRationaleSuccessReducer)
    .addCase(sendNewOptxScoreRationaleSuccess, gridSendOptxScoreRationaleSuccessReducer)
    .addCase(addonActions.removeCompanyFromAddonManagement, removeCompanyFromAddonManagementReducer)
    .addCase(
      addonActions.removeCompanyFromAddonManagementSuccess,
      removeCompanyFromAddonManagementSuccessReducer
    )
    .addCase(
      addonActions.removeCompanyFromAddonManagementFail,
      removeCompanyFromAddonManagementFailReducer
    )
    .addCase(paginationLongCardActions.changeLongCardPagination, fetchReducer)
    .addCase(addonActions.fetchAdditionalFilters, fetchAddonAdditionalFiltersReducer)
    .addCase(addonActions.fetchAdditionalFiltersSuccess, fetchAddonAdditionalFiltersSuccessReducer)
    .addCase(addonActions.fetchAdditionalFiltersFail, fetchAddonAdditionalFiltersFailReducer)
    .addCase(addonActions.clearAddonManagementFetchedAt, clearAddonManagementFetchedAtReducer)
    .addCase(fundingRoundActions.getCompanyFundingRoundsSuccess, companyFundingRoundsSuccessReducer)
    .addCase(companyProfileActions.regenerateAiDescriptionSuccess, aiDescriptionSuccessReducer)
    .addCase(savedSearchesActions.setLoader, setLoaderReducer)
);

export default reducer;
