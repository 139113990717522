import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
// utils
import { getEnv } from '@optx/utils/env';
// redux
import { selectors as userInformationSelectors } from '@redux/user/information';

const Pendo = () => {
  const email = useSelector(userInformationSelectors.getEmail);
  const fullName = useSelector(userInformationSelectors.getFullName);

  const currentEnv = useMemo(() => getEnv(), []);

  useEffect(() => {
    if (window.pendo && email && fullName) {
      window.pendo.initialize({
        visitor: {
          id: email.split('@')[0],
          email: email,
          full_name: fullName,
        },
        account: {
          id: currentEnv,
          name: currentEnv,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, fullName]);

  return null;
};

export default React.memo(Pendo);
