import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Radio, Row, Typography, Col, Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import classnames from 'classnames';
// models
import { AppState } from '@optx/redux/interfaces';
import { CompanyProfile, Company } from '@optx/models/Company';
import { ChartBarIntervalKeys } from '@optx/models/charts/data';
import { CompanyFormRationale } from '@models/filters';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import {
  getRationaleDefaultValue,
  getRangeValues,
  getValuesRalation,
  getRange,
} from '@optx/utils/researchRationale';
// redux
import { getFormRationales } from '@optx/redux/company/filters/selectors';
import { selectors as searchSelectors } from '@features/grid/search';
import { sendOptxScoreRationale } from '@redux/company/score/actions';
import { selectors as userSelectors } from '@redux/user/information';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
// components
import { TrendingLine } from '@optx/components/common/charts';
import { ScoreTrending } from '@optx/components/common/trending';
import EditPopover from '@optx/components/common/popover/EditPopover';
import CreateOtherRaionale from '@optx/features/company/edit-fields/components/CreateOtherRationale';
import { ReactComponent as LogoIcon } from '../../../../assets/svg/icons/logo.svg';
// styles
import Styled from './EditFieldOPTX.styled';

const getRationaleReasonKey = (rationaleValue: string, score: number) => {
  const rangeValues = getRangeValues(rationaleValue);

  let scoreTransformed;

  if (typeof score !== 'number') {
    scoreTransformed = 'N/A';
  } else {
    scoreTransformed = score;
  }

  const scoreValuesRelation =
    typeof scoreTransformed !== 'string'
      ? getValuesRalation(rangeValues, score as number)
      : 'upper';

  return (
    // eslint-disable-next-line no-nested-ternary
    scoreValuesRelation === 'lower'
      ? 'optx_score_lower_rationales'
      : scoreValuesRelation === 'upper'
      ? 'optx_score_upper_rationales'
      : null
  );
};

interface EditFieldOPTXProps {
  record: Company | CompanyProfile;
  children: React.ReactNode;
  isFromGrid?: boolean;
}

const EditFieldOPTX: React.FC<EditFieldOPTXProps> = ({ record, children, isFromGrid }) => {
  const dispatch = useDispatch();
  const [closePopup, setClosePopup] = useState(false);

  const [period, setPeriod] = useState<ChartBarIntervalKeys>('1Y');
  const { pathname } = useLocation();
  const myCompaniesTrending = useSelector(searchSelectors.getTrending('myCompanies'));
  const searchTrending = useSelector(searchSelectors.getTrending('advancedSearch'));
  const watchlistTrending = useSelector(searchSelectors.getTrending('lists'));
  const popoverContentRef = useRef(null);
  const defautlOptxScore = useSelector(userSelectors.getDefaultScore);
  const score = defautlOptxScore === 'il' ? record.il_optx_score : record.score;
  const title = defautlOptxScore === 'il' ? 'IL OPTX Score' : 'OPTX Score';
  const defaultRationaleScore =
    defautlOptxScore === 'us' ? record.optx_score_rationale : record.il_optx_score_rationale;
  const defaultRationaleScoreReasonType =
    defautlOptxScore === 'us'
      ? record.optx_score_rationale_reason_type
      : record.il_optx_score_rationale_reason_type;
  const defaultScoreVerified =
    defautlOptxScore === 'us' ? record.optx_score_verified : record.il_optx_score_verified;
  const defaultRationaleReasonValue =
    defautlOptxScore === 'us'
      ? record.optx_score_rationale_reason
      : record.il_optx_score_rationale_reason;

  const [radioValue, setRadioValue] = useState<boolean | undefined>(undefined);
  const [rationaleValue, setRationaleValue] = useState<string>(defaultRationaleScore as string);
  const [rationaleReasonValue, setRationaleReasonValue] = useState<string | null>(
    defaultRationaleReasonValue
  );

  const [rationaleReasonKey, setRationaleReasonKey] = useState<CompanyFormRationale | null>(null);
  const [disabledRange, setDisabledRange] = useState('none');
  const [isOptionsDisabled, setIsOptionsDisabled] = useState(false);

  const isSaveBtnDisabled = useMemo(() => {
    if (
      !radioValue &&
      (rationaleValue === undefined ||
        (rationaleValue === defaultRationaleScore &&
          rationaleReasonValue === defaultRationaleReasonValue))
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    radioValue,
    rationaleValue,
    defaultRationaleScore,
    rationaleReasonValue,
    defaultRationaleReasonValue,
  ]);

  const rationaleOptions = useSelector((state: AppState) =>
    getFormRationales(state, 'optx_score_rationales')
  );

  const rationaleReasonOptions = useSelector((state: AppState) => {
    if (!rationaleReasonKey) {
      return null;
    }

    return getFormRationales(state, rationaleReasonKey);
  });

  useEffect(() => {
    setRationaleValue(defaultRationaleScore as string);
  }, [defaultRationaleScore]);

  useEffect(() => {
    setRadioValue(defaultScoreVerified === null ? undefined : defaultScoreVerified);
  }, [defaultScoreVerified]);

  useEffect(() => {
    setRationaleReasonValue(defaultRationaleReasonValue);
  }, [defaultRationaleReasonValue]);

  useEffect(() => {
    let reasonKey =
      // eslint-disable-next-line no-nested-ternary
      defaultRationaleScoreReasonType === 'lower'
        ? 'optx_score_lower_rationales'
        : defaultRationaleScoreReasonType === 'upper'
        ? 'optx_score_upper_rationales'
        : null;

    if (reasonKey === null && typeof rationaleValue === 'string') {
      reasonKey = getRationaleReasonKey(rationaleValue, score);
    }

    setRationaleReasonKey(reasonKey as CompanyFormRationale | null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRationaleScoreReasonType]);

  const growth = () => {
    if (defautlOptxScore === 'il') {
      const ilScoreGrowth = (record as CompanyProfile).il_score_growth as string;

      return isNaN(parseInt(ilScoreGrowth ?? ''))
        ? null
        : Number((record as CompanyProfile).il_score_growth);
    }

    if (Object.prototype.hasOwnProperty.call(record, 'optx_score_growth')) {
      return (record as CompanyProfile).optx_score_growth;
    }

    if (Object.prototype.hasOwnProperty.call(record, 'score_growth')) {
      return isNaN(parseInt((record as Company).score_growth ?? ''))
        ? null
        : Number((record as Company).score_growth);
    }

    return null;
  };

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleReasonOptions?.map(option => option.value).slice(0, -1) || [];

    if (key === 'tag') {
      return (
        defaultRationaleReasonValue &&
        defaultRationaleReasonValue !== DEFAULT_EMPTY_VALUE &&
        defaultRationaleReasonValue !== null &&
        !values.includes(defaultRationaleReasonValue)
      );
    }

    if (!rationaleReasonValue) {
      return false;
    }

    return (
      !values.includes(rationaleReasonValue) &&
      rationaleReasonValue !== undefined &&
      rationaleReasonValue !== DEFAULT_EMPTY_VALUE &&
      rationaleReasonValue.length > 0
    );
  };

  const customRationaleReasonValue = showIsOtherOrTag() ? 'other' : rationaleReasonValue;

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const handleRationaleChange = (e: RadioChangeEvent) => {
    const reasonKey = getRationaleReasonKey(e.target.value, score);

    setRationaleReasonKey(reasonKey);

    setRationaleValue(e.target.value);
    setRationaleReasonValue(null);
  };

  const handleRationaleReasonChange = (e: RadioChangeEvent) => {
    setRationaleReasonValue(e.target.value);
  };

  const handleSave = () => {
    const rationaleReasonKeyTransformed =
      // eslint-disable-next-line no-nested-ternary
      rationaleReasonKey === 'optx_score_upper_rationales'
        ? 'upper'
        : rationaleReasonKey === 'optx_score_lower_rationales'
        ? 'lower'
        : null;

    dispatch(
      sendOptxScoreRationale({
        companyId: record.company_id,
        rationale: !radioValue ? rationaleValue : DEFAULT_EMPTY_VALUE,
        optxScoreVerified: radioValue === undefined ? null : radioValue,
        optxScoreRationaleReason: radioValue === true ? '' : rationaleReasonValue,
        optxScoreRationaleReasonType: radioValue === true ? '' : rationaleReasonKeyTransformed,
        scoreType: defautlOptxScore === 'il' ? 'il_score' : 'score',
      })
    );
    setClosePopup(true);
  };

  const handleOtherChange = (option: React.SetStateAction<string | null>) => {
    setRationaleReasonValue(option);
  };

  const handleCancel = () => {
    setRadioValue(defaultScoreVerified !== null ? defaultScoreVerified : undefined);
    setRationaleValue(defaultRationaleScore as string);

    let reasonKey =
      // eslint-disable-next-line no-nested-ternary
      defaultRationaleScoreReasonType === 'lower'
        ? 'optx_score_lower_rationales'
        : defaultRationaleScoreReasonType === 'upper'
        ? 'optx_score_upper_rationales'
        : null;

    if (reasonKey === null && typeof defaultRationaleScore === 'string') {
      reasonKey = getRationaleReasonKey(defaultRationaleScore, score);
    }

    setRationaleReasonKey(reasonKey as CompanyFormRationale | null);
    setRationaleReasonValue(defaultRationaleReasonValue);
    setClosePopup(true);
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  useEffect(() => {
    if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      setPeriod(watchlistTrending);
    } else if (pathname.startsWith('/my-companies')) {
      setPeriod(myCompaniesTrending);
    } else {
      setPeriod(searchTrending);
    }
  }, [myCompaniesTrending, pathname, searchTrending, watchlistTrending]);

  useEffect(() => {
    const rangeValues =
      rationaleValue && rationaleValue.length > 1 ? getRangeValues(rationaleValue) : null;

    if (typeof score !== 'number') {
      setDisabledRange('none');
    } else {
      setDisabledRange(getRange(score));
    }

    if (
      rangeValues &&
      typeof score === 'number' &&
      getValuesRalation(rangeValues, score) === 'equal'
    ) {
      setIsOptionsDisabled(true);
    } else {
      setIsOptionsDisabled(false);
    }
  }, [score, rationaleValue]);

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <Styled.ContentContainer
      ref={popoverContentRef}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Row>
        <Styled.ScoreContainer>
          <LogoIcon className="navigation-logo__icon" />
          {defautlOptxScore === 'il' && record?.il_optx_score_trends !== null ? (
            <TrendingLine points={record?.il_optx_score_trends[period]} color="#52C41A" />
          ) : null}
          {defautlOptxScore !== 'il' && record?.optx_score_trends !== null ? (
            <TrendingLine points={record?.optx_score_trends[period]} color="#52C41A" />
          ) : null}
          <ScoreTrending
            score={typeof score !== 'number' ? 'N/A' : score}
            growth={growth()}
            isPSGCompany={record.is_psg_company}
            customFontSize="16px"
          />
        </Styled.ScoreContainer>
      </Row>
      <Row className="profile-information__subtitle">
        <Typography.Text>Do you agree with the score?</Typography.Text>
        <Radio.Group
          onChange={onChange}
          value={radioValue}
          className="d-flex"
          style={{ gap: '5px', marginTop: '15px' }}
        >
          <Styled.RadioButton
            value
            className="d-block"
            style={{ borderColor: '#52C41A' }}
            checked={radioValue}
          >
            <Styled.ThumbsUpIcon
              iconName="thumbs-up"
              className="icon"
              color="#F5222D"
              checked={radioValue}
            />
          </Styled.RadioButton>
          <Styled.RadioButton value={false} style={{ borderColor: '#F5222D' }} checked={radioValue}>
            <Styled.ThumbsDownIcon iconName="thumbs-down" className="icon" checked={radioValue} />
          </Styled.RadioButton>
        </Radio.Group>
      </Row>
      {radioValue === false && (
        <div>
          <Row className="profile-information__subtitle flex-column">
            <Typography.Text>What score would you assign?</Typography.Text>
          </Row>
          <Row>
            <Styled.CustomRadioGroup
              buttonStyle="solid"
              onChange={handleRationaleChange}
              value={rationaleValue}
              columns={rationaleOptions?.length || 0}
            >
              {rationaleOptions?.map(option => (
                <Col
                  key={option.value}
                  style={{ textAlign: 'center', marginTop: 10, flexDirection: 'column' }}
                >
                  <Styled.CustomRadio
                    disabled={option.value === disabledRange}
                    value={option.value}
                  >
                    {option.label}
                  </Styled.CustomRadio>
                </Col>
              ))}
            </Styled.CustomRadioGroup>
          </Row>
          {rationaleReasonKey && rationaleReasonOptions && (
            <>
              <Row className="profile-information__subtitle">
                <Typography.Text>
                  {rationaleReasonKey.includes('upper')
                    ? 'Why score should be higher? (optional)'
                    : 'Why score should be lower? (optional)'}
                </Typography.Text>
              </Row>
              <Radio.Group
                disabled={isOptionsDisabled}
                buttonStyle="solid"
                onChange={handleRationaleReasonChange}
                value={customRationaleReasonValue}
              >
                {rationaleReasonOptions?.map(option => (
                  <Col
                    key={option.value}
                    style={{ textAlign: 'center', marginTop: 10, padding: 0 }}
                  >
                    <Radio.Button value={option.value}>{option.label}</Radio.Button>
                  </Col>
                ))}
              </Radio.Group>
              {showIsOtherOrTag() && (
                <Row style={{ marginTop: 20, padding: 0, flexDirection: 'column' }}>
                  <Typography.Text>Please Explain</Typography.Text>
                  <CreateOtherRaionale
                    onInputChange={handleOtherChange}
                    isDisabled={isOptionsDisabled}
                  />
                  {showIsOtherOrTag('tag') && (
                    <Styled.Tag color="blue">{defaultRationaleReasonValue}</Styled.Tag>
                  )}
                </Row>
              )}
            </>
          )}
        </div>
      )}
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
          disabled={
            radioValue
              ? radioValue === getRationaleDefaultValue(defaultRationaleScore)
              : isSaveBtnDisabled
          }
        >
          Save
        </Button>
      </div>
    </Styled.ContentContainer>
  );

  return isFromGrid ? (
    <Styled.Wrapper
      className={classnames('edit-popover-wrapper', { 'psg-company': record.is_psg_company })}
    >
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        placement="bottom"
        destroyOnHide
      >
        {children}
      </EditPopover>
    </Styled.Wrapper>
  ) : (
    <Styled.ContainerProfileBanner>
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        placement="bottom"
        destroyOnHide
      >
        {children}
      </EditPopover>
    </Styled.ContainerProfileBanner>
  );
};

export default React.memo(EditFieldOPTX);
