import React from 'react';
import { Typography } from 'antd';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { stringListToView } from '@optx/utils/format';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const TableCellStringList = (value: Array<string> | null) => {
  const viewValue = stringListToView(value, 'None');

  return viewValue ? (
    <TruncateTooltip title={viewValue}>
      <Typography.Text style={{ maxWidth: '100%' }}>{viewValue}</Typography.Text>
    </TruncateTooltip>
  ) : (
    DEFAULT_EMPTY_VALUE
  );
};

export default TableCellStringList;
