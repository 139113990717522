import queryString from 'query-string';
import { Dictionary, values as _values } from 'lodash';
import fileDownload from 'js-file-download';
// models
import {
  CompanyProfile,
  ScoreGrowth,
  CompanyDatePresented,
  UpdateOpportunity,
  Addon,
} from '@models/Company';
import {
  EquityTouchSubmitField,
  GroupsSubmitField,
  EquityTouchContactPost,
  WatchlistsSubmitField,
} from '@models/equityTouch';
import { CompanyMergesResponse } from '@models/company/CompanyMerges';
import { CompanyTouchesResponse } from '@models/company/CompanyTouch';
import { PreselectedFilter } from '@models/filters';
import { PinNote, EditNote, FetchNote } from '@models/Notes';
import { CompanyUserContact } from '@models/api/contacts';
import { SelectedCompaniesKeys } from '@models/bulkActions';
import { CompanyPortfolio } from '@redux/company/portfolio/interfaces';
import {
  SearchTouchesPayload,
  TouchesDocumentUploadPayload,
} from '@redux/company/touches/search/interfaces';
import { CompanyImportPayload } from '@optx/features/list-import/state/interfaces';
import { CompanyMergesPayload } from '@features/company/merges/state/interfaces';
import { WebsiteRankPayload } from '@optx/models/company/SocialMedia';
import { ChangedCompanyFieldsPayload } from '@optx/features/company/audit-trail/state/interfaces';
import {
  CustomValue,
  UpdateFieldsPayload,
} from '@optx/features/company/edit-fields/state/interfaces';
import { SelectOption } from '@optx/models/Option';
import {
  AddonManagementPayload,
  BulkRemoveAddonPayload,
} from '@optx/redux/company/addon-management/interfaces';
import { UpcomingTouchesNotificationDetails } from '@optx/models/notifications';
import { AddYearSuccessPayload } from '@optx/redux/company/opportunity-presentation/interfaces';
import { SalesloftEditData } from '../../models/Salesloft';
import { CompanyTabListPaginationState } from '../../redux/company/lists/interfaces';
import { TouchesCreateForm, TouchesFinancialPayload } from '../../models/api/touches';
import { FilesPayload } from '@optx/models/files';
import { CompanyEditAllDataPayload } from '@models/api/editAllInfo';
import {
  OpinionAiDescription,
  RegenerateAiDescription,
  AiRelationshipSummary,
} from '@optx/redux/company/profile/interfaces';
import { AddCompanyPayload } from '@optx/features/company/add-company/state/interfaces';
// constants
import SORT_ORDER from '@optx/constants/table/sortOrder';
// services
import SearchService from './SearchService';
import ApiClient, { API_URL } from './axios-client';
// utils
import { mapSearchQuery } from '@optx/utils/search';

class CompanyService {
  static client: ApiClient = new ApiClient(`${API_URL}/company`);

  static getProfile(companyId: number) {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get<CompanyProfile>(`/profile?${queryString.stringify(payload)}`);
  }

  static getPluginPrimaryContact(companyId: number) {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get<CompanyProfile>(
      `/plugin_primary_contact_data?${queryString.stringify(payload)}`
    );
  }

  static getUpcomingTouches(payload: { company_id?: string } = {}) {
    let query = queryString.stringify(payload);
    query = query && `?${query}`;

    return CompanyService.client.get<Array<UpcomingTouchesNotificationDetails>>(
      `/reminders${query}`
    );
  }

  static deleteTouch(touchId: number) {
    const payload = {
      unique_id: touchId,
    };

    return CompanyService.client.put<void>(`/reminders/dismiss?${queryString.stringify(payload)}`);
  }

  static getEmployeeGrowth(companyId: number) {
    const payload = {
      company_id: companyId,
    };
    const query = queryString.stringify(payload);

    return CompanyService.client.get(`/employee_growth_series?${query}`);
  }

  static revenueGrowth(companyId: number) {
    const payload = {
      company_id: companyId,
    };
    const query = queryString.stringify(payload);

    return CompanyService.client.get(`/revenue_growth_series?${query}`);
  }

  static getCompaniesInET = (query: string) => {
    return CompanyService.client.get(`/et_merge_history?${query}`);
  };

  // Opportunity Presentation
  static getOpportunityPresentation(companyId: number) {
    return CompanyService.client.get(`/presentation/latest?company_id=${companyId}`);
  }

  static addYearToOpportunityPresentation(payload: AddYearSuccessPayload) {
    return CompanyService.client.post('/presentation/add_year', payload);
  }

  static deleteYearToOpportunityPresentation(query: string) {
    return CompanyService.client.delete(`/presentation/year?${query}`);
  }

  static exportPropietaryInfoToExcel(payload: {}) {
    return CompanyService.client.post('/presentation/excel_export', payload, {
      responseType: 'blob',
    });
  }

  static addOpportunityPresentation(presentation: UpdateOpportunity | UpdateFieldsPayload) {
    let payload = presentation;

    if ('value' in presentation) {
      const fieldName = Object.keys((presentation as UpdateFieldsPayload).value!);
      payload = { company_id: presentation.companyId };

      fieldName.forEach(field => {
        payload = {
          ...payload,
          //@ts-ignore
          [field]: (presentation as UpdateFieldsPayload).value![field],
        };
      });
    }

    return CompanyService.client.put('/presentation', payload);
  }

  // update date presented
  static updateDatePresented(date: CompanyDatePresented | UpdateFieldsPayload) {
    const payload = {
      company_id: date.companyId,
      new_date: (date as CompanyDatePresented).newDate
        ? (date as CompanyDatePresented).newDate
        : (date as UpdateFieldsPayload).value,
    };

    const formattedPayload =
      'value' in date && Array.isArray(date.value) ? payload : queryString.stringify(payload);

    return CompanyService.client.put('/presentation/date_presented', formattedPayload);
  }

  static updateCompanyData = (payload: UpdateFieldsPayload) => {
    let customPayload = null;

    if (payload.customData) {
      customPayload = {
        company_id: payload.companyId,
        ...payload.customData,
      };
    } else {
      const isSelectOption =
        payload.value &&
        typeof payload.value === 'object' &&
        'label' in payload.value &&
        'value' in payload.value;

      const value =
        payload.fieldKey === 'num_employees' ? (payload.value as number) ?? 'blank' : payload.value;

      customPayload = {
        company_id: payload.companyId,
        ...(payload.fieldKey && {
          [payload.fieldKey]: isSelectOption ? (payload.value as SelectOption).value : value,
        }),
      };
    }

    return CompanyService.client.post(
      `/update_company_data?${queryString.stringify(customPayload)}`
    );
  };

  // addon
  static updateAddon = (payload: UpdateFieldsPayload) => {
    const customPayload = {
      company_id: payload.companyId,
      addon_bool: ((payload as UpdateFieldsPayload).value as CustomValue).value,
      addon_company: Object.keys(
        ((payload as UpdateFieldsPayload).value as CustomValue).optionalValue as Addon
      ),
    };

    return CompanyService.client.put(
      `/set_addon?${queryString.stringify(customPayload, { arrayFormat: 'comma' })}`
    );
  };

  // addon checkbox
  static updateAddonCheckbox = (companyId: number, addonChecked: boolean) => {
    const payload = {
      company_id: companyId,
      addon_bool: addonChecked,
    };

    return CompanyService.client.put(`/set_addon_checkbox?${queryString.stringify(payload)}`);
  };

  // portfolio
  static updatePortfolio = (portfolioData: CompanyPortfolio | UpdateFieldsPayload) => {
    const customPayload: CompanyPortfolio = {
      company_id:
        (portfolioData as CompanyPortfolio).company_id ||
        (portfolioData as UpdateFieldsPayload).companyId,
      is_portfolio:
        (portfolioData as CompanyPortfolio).is_portfolio ||
        ((portfolioData as UpdateFieldsPayload).value as unknown as boolean),
    };

    return CompanyService.client.post('/portfolio', customPayload);
  };

  // equity touch
  static checkEquityTouchAvailability = (companyId: string) =>
    CompanyService.client.get(`/equity_touch/available?company_id=${companyId}`);

  static fetchEquityTouchFormData = (payload: { companyId: number; isFirstStep?: boolean }) => {
    const { companyId, isFirstStep } = payload;

    return CompanyService.client.get(
      `/equity_touch/form_data?company_id=${companyId}${
        isFirstStep ? `&is_first_step=${isFirstStep}` : ''
      }`
    );
  };

  static addEquityTouch = (payload: {
    id: string;
    formData: Dictionary<
      | string
      | EquityTouchSubmitField
      | GroupsSubmitField
      | EquityTouchContactPost[]
      | WatchlistsSubmitField[]
    >;
  }) => {
    const { id } = payload;

    return CompanyService.client.post(`/equity_touch?company_id=${id}`, payload.formData);
  };

  static addEquityTouchBulk = (
    bulkData: Dictionary<
      string | number | EquityTouchSubmitField | GroupsSubmitField | WatchlistsSubmitField[]
    >[]
  ) => CompanyService.client.post('/equity_touch_bulk', bulkData);

  // touches
  static fetchTouches(searchPayload: SearchTouchesPayload) {
    const payload = {
      company_id: searchPayload.companyId,
      per_page: searchPayload.pagination.pageSize,
      page: searchPayload.pagination.pageNumber,
      initiator_id: searchPayload.initiatorId !== '*' ? searchPayload.initiatorId : undefined,
      touch_type: searchPayload.touchType !== '*' ? searchPayload.touchType : undefined,
    };

    return CompanyService.client.get<CompanyTouchesResponse>(
      `/touches?${queryString.stringify(payload)}`
    );
  }

  static fetchTouchesFilters = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/touches/options?${queryString.stringify(payload)}`);
  };

  static addTouches(touch: TouchesCreateForm) {
    const payload = {
      touchId: touch.touchId ? touch.touchId : null,
      editable: true,
      touchType: touch.type,
      date: touch.touchDate,
      time: touch.touchTime[0],
      endDate: touch.touchDate,
      endTime: touch.touchTime[1],
      isComplete: touch.touchType === 'complete',
      initiatorId: touch.initiatorId?.toString(),
      is_optx_id: touch.is_optx_id,
      companyId: touch.companyId.toString(),
      psgParticipants: touch.attendees?.length ? [...touch.attendees] : [],
      externalParticipants: touch.externalAttendees?.length
        ? [...touch.externalAttendees].join().split(',')
        : [],
      subject: touch.subject,
      notesHTML: touch.textarea,
      attachments: null,
    };

    return CompanyService.client.put('/touches/upsert_note', payload);
  }

  static fetchAddonFilters = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(
      `/addon_management/data_settings?${queryString.stringify(payload)}`
    );
  };

  // Update Company Touches Informations
  static updateCompanyInfo(touchFinancialData: Partial<TouchesFinancialPayload>) {
    return CompanyService.client.post('/log_notes', touchFinancialData);
  }

  static getCompanyInfo(companyId: string | number) {
    const payload = {
      company_id: companyId.toString(),
    };

    return CompanyService.client.get(`/log_notes?${queryString.stringify(payload)}`);
  }

  // Update Company Informations
  static updateEditAllCompanyInfo(companyEditAllData: Partial<CompanyEditAllDataPayload>) {
    return CompanyService.client.put('/update_all_fields', companyEditAllData);
  }

  static getEditAllCompanyInfo(companyId: string | number) {
    const payload = {
      company_id: companyId.toString(),
    };

    return CompanyService.client.get(`/all_fields_tabs?${queryString.stringify(payload)}`);
  }

  // export list of companies to CSV
  static exportToCSV = (
    filters: Dictionary<string>,
    body: {
      columns: Dictionary<string>;
    }
  ) => {
    const filterValues = Object.values(filters).filter(item => item !== '');
    const queryString = filterValues.join('&');

    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    return CompanyService.client.post<string>(`/csv_export?${queryString}`, body, {
      headers,
    });
  };

  // export list of addons companies to CSV
  static exportCompanyAddonsToCSV = (
    filters: Dictionary<string>,
    body: {
      columns: Dictionary<string>;
    }
  ) => {
    const queryString = _values(filters).join('&');

    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    return CompanyService.client.post<string>(`/addon_management/csv_export?${queryString}`, body, {
      headers,
    });
  };

  // export list of ETIntegration companies to CSV
  static exportETIntegrationToCSV = (
    query: string,
    body: {
      columns: Dictionary<string>;
    }
  ) => {
    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    return CompanyService.client.post<string>(`/et_merge_history/csv_export?${query}`, body, {
      headers,
    });
  };

  // export list of contacts to CSB
  static exportContactsToCSV = (
    filters: Dictionary<string>,
    body: {
      columns: Dictionary<string>;
    }
  ) => {
    const filterValues = Object.values(filters).filter(item => item !== '');
    const queryString = filterValues.join('&');

    const headers = {
      responseType: 'blob',
      ...SearchService.searchHeaders.headers,
    };

    return CompanyService.client.post<string>(`/contacts_csv_export?${queryString}`, body, {
      headers,
    });
  };

  /**
   * Import favorite list from CSV file.
   * @param payload company import payload.
   */
  static importFavoriteList = (payload: CompanyImportPayload) => {
    const { title } = payload;

    return CompanyService.client.put<string>(
      `/csv_import?title=${encodeURIComponent(title)}`,
      payload
    );
  };

  // get updated filters and updated histograms
  static getHistograms = (parameters: {
    histogramList: Array<string>;
    filterQuery: Dictionary<PreselectedFilter>;
    companyId?: number;
    filterOrigin?: string;
  }) => {
    const payload: Dictionary<PreselectedFilter> = {};
    const histogramList = parameters.histogramList.toString();

    Object.keys(parameters.filterQuery).forEach(key => {
      payload[key] = parameters.filterQuery[key];
    });

    const query = queryString.stringify(payload, { arrayFormat: 'comma' });

    return CompanyService.client.get(
      `/getHistograms?histograms=${histogramList}&${query}${
        parameters.companyId !== undefined ? `&company_id=${parameters.companyId}` : ''
      }${parameters.filterOrigin !== undefined ? `&filter_origin=${parameters.filterOrigin}` : ''}`
    );
  };

  // get files
  static getFiles({ companyId, sortBy, filters }: FilesPayload) {
    const payload = {
      company_id: companyId,
      sort_field: sortBy[0].id,
      sort_order: sortBy[0].desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING,
      ...(filters ? filters : {}),
    };

    const query = queryString.stringify(payload, { arrayFormat: 'comma' });

    return CompanyService.client.get(`/files?${query}`);
  }

  static getFilesDataSettings(companyId: number) {
    const payload = {
      company_id: companyId,
    };

    const query = queryString.stringify(payload);

    return CompanyService.client.get(`/touches/document_upload/data_settings?${query}`);
  }

  // download file
  static downloadFile(data: { id: number; name: string }) {
    const { id, name } = data;
    const payload = {
      file_id: id,
    };

    return CompanyService.client
      .get<Blob>(`/files/download?${queryString.stringify(payload)}`, { responseType: 'blob' })
      .then(response => {
        fileDownload(response.data, name);

        return response;
      });
  }

  static touchesDocumentUpload(payload: TouchesDocumentUploadPayload) {
    return CompanyService.client.post('/touches/document_upload', payload);
  }

  static getCompanyFundingRounds = (companyId: number) => {
    return CompanyService.client.get(`/funding_rounds?company_id=${companyId}`);
  };

  static getCompanyTabLists = (data: CompanyTabListPaginationState) => {
    const payload = {
      company_id: data.companyId,
      page: data.pageNumber,
      per_page: data.pageSize,
      ...(data.sortBy.length && { sort_by: data.sortBy[0].id }),
      ...(data.sortBy.length && { sort_order: data.sortBy[0].desc ? 'desc' : 'asc' }),
    };
    const query = queryString.stringify(payload);

    return CompanyService.client.get(`/list_tab?${query}`);
  };

  static getCompaniesContacts(companyIds: number[]) {
    return CompanyService.client.post('/get_companies_primary_contacts', {
      company_ids: companyIds,
    });
  }

  // get primary contacts
  static getPrimaryContacts(companyId: string | number) {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/get_contacts?${queryString.stringify(payload)}`);
  }

  // get PSG primary contacts
  static getPsgContacts() {
    return CompanyService.client.get('/get_contacts?company_id=1&psg=True');
  }

  // edit primary contact
  static editPrimaryContact(contact: Partial<CompanyUserContact>) {
    return CompanyService.client.put('/add_contact', contact);
  }

  // add new contact
  static addContactToOptx(contact: Partial<CompanyUserContact>) {
    return CompanyService.client.put('/add_contact_to_optx', contact);
  }

  // set company primary contact
  static setPrimaryContact(contact: SalesloftEditData) {
    return CompanyService.client.post('/set_contact_as_primary', {
      company_id: contact.company_id,
      person_id: contact.person_id,
    });
  }

  // get notes for profile page
  static getProfileNotes(data: FetchNote) {
    const payload = {
      company_id: data.companyId,
      sorting: data.sorting,
    };

    return CompanyService.client.get(`/notes?${queryString.stringify(payload)}`);
  }

  // pin note
  static pinNote(note: PinNote) {
    const payload = {
      pin_id: note.pinId,
      action: note.action,
    };

    return CompanyService.client.post('/notes/pin', queryString.stringify(payload));
  }

  // edit note
  static editNote(note: EditNote) {
    const payload = {
      pin_id: note.pinId,
      new_text: note.newText,
    };

    return CompanyService.client.put('/notes/edit', queryString.stringify(payload));
  }

  // delete profile note
  static deleteProfileNote(pinId: number) {
    const payload = {
      pin_id: pinId,
    };

    return CompanyService.client.delete(`/notes/delete?${queryString.stringify(payload)}`);
  }

  // fetch optx score growth
  static getScoreGrowth(payload: ScoreGrowth, scoreType?: string) {
    const queryData = {
      company_id: payload.company_id,
      company_url: payload.company_url,
      score: scoreType === 'il' ? scoreType : undefined,
    };

    return CompanyService.client.get(`/score_history?${queryString.stringify(queryData)}`);
  }

  static getEquityTouchSelectOptions(fields: string | null) {
    return CompanyService.client.get(`/get_et_form_data?${queryString.stringify({ fields })}`);
  }

  static updateCompanyRationale = (companyId: number, rationale: string) => {
    const payload = {
      company_id: companyId,
      rationale,
    };

    return CompanyService.client.post(
      `/bulk_update_company_data?${queryString.stringify(payload)}`
    );
  };

  /**
   * Delete company user added tag.
   * Tags added by the system can't be deleted.
   * @param id tag identifier.
   * @param companyId company identifier.
   */
  static deleteTag = (id: number, companyId: number) => {
    const queryPayload = {
      company_id: companyId,
      tag_id: id,
    };
    const query = queryString.stringify(queryPayload);

    return CompanyService.client.delete(`/tags?${query}`);
  };

  static bulkEdit = (
    companyIds: number[],
    field: SelectedCompaniesKeys,
    fieldValue: string | string[] | number,
    extraOptions?: Dictionary<string>
  ) => {
    const payload = {
      company_id: companyIds,
      [field === 'cs_tags' ? 'tags' : field]: fieldValue,
      ...extraOptions,
    };

    return CompanyService.client.post(
      `/bulk_update_company_data?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  };

  static getBulkEditData = (companyIds: number[]) => {
    const payload = {
      company_id: companyIds,
    };

    return CompanyService.client.post(
      `/equity_touch/get_et_data?${queryString.stringify(payload, { arrayFormat: 'comma' })}`
    );
  };

  static bulkRemoveAddon = (payload: BulkRemoveAddonPayload[]) => {
    return CompanyService.client.put('/bulk_remove_company_addon', payload);
  };

  static getCompanyMerges = (searchPayload: CompanyMergesPayload) => {
    const payload = {
      company_id: searchPayload.companyId,
      page: searchPayload.page,
    };

    return CompanyService.client.get<CompanyMergesResponse>(
      `/merge_history?${queryString.stringify(payload)}`
    );
  };

  // SOCIAL MEDIA

  static getCompanySocialMediaWebsiteRank = (searchPayload: WebsiteRankPayload) => {
    const payload = {
      company_id: searchPayload.companyId,
      us_only: searchPayload.usOnly,
    };

    return CompanyService.client.get(
      `/social_media_website_rank?${queryString.stringify(payload)}`
    );
  };

  static getCompanySocialMediaG2 = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_g2?${queryString.stringify(payload)}`);
  };

  static getCompanySocialMediaCapterra = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_capterra?${queryString.stringify(payload)}`);
  };

  static getCompanySocialMediaTwitter = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_twitter?${queryString.stringify(payload)}`);
  };

  static getCompanySocialMediaLinkedin = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_linkedin?${queryString.stringify(payload)}`);
  };

  static getCompanySocialMediaFacebook = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_facebook?${queryString.stringify(payload)}`);
  };

  static getCompanySocialMediaCrunchbase = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_crunchbase?${queryString.stringify(payload)}`);
  };

  static getCompanySocialMediaGlassdoor = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/social_media_glassdoor?${queryString.stringify(payload)}`);
  };

  static fetchCompanyChangedFields = (searchPayload: ChangedCompanyFieldsPayload) => {
    const payload = {
      company_id: searchPayload.companyId,
      start_date: searchPayload.startDate,
      end_date: searchPayload.endDate,
      internal: searchPayload.internal,
      optx: searchPayload.optx,
      field: searchPayload.field,
      page: searchPayload.pagination.pageNumber,
      per_page: searchPayload.pagination.pageSize,
    };

    return CompanyService.client.get(`/history/fields?${queryString.stringify(payload)}`);
  };

  // update LinkedIn URL

  static updateLinkedinURL = (payload: UpdateFieldsPayload) => {
    const customPayload = {
      linkedin_url: payload.value,
      entity_type: payload.entityType,
      id: payload.companyId,
    };

    return CompanyService.client.post('/edit_linkedin_url', customPayload);
  };

  // Extension
  static getExtensionCompanies = (companyURL: string) => {
    return CompanyService.client.get(`/extension_url?company_url=${companyURL}`);
  };

  static fetchCompanyAddonManagement = (searchPayload: AddonManagementPayload) => {
    const payload = {
      company_id: searchPayload.companyId,
      ...mapSearchQuery(searchPayload),
    };

    return CompanyService.client.get(
      `/addon_management?${queryString.stringify(payload, {
        arrayFormat: 'comma',
      })}`
    );
  };

  static fetchCompanyAddonManagementCount = (searchPayload: AddonManagementPayload) => {
    const payload = {
      company_id: searchPayload.companyId,
      ...mapSearchQuery(searchPayload),
    };
    const { page, per_page, ...searchCountPayload } = payload;

    return CompanyService.client.get(
      `/addon_management/count?${queryString.stringify(searchCountPayload, {
        arrayFormat: 'comma',
      })}`
    );
  };

  static fetchRelationshipManagement = (companyId: number) => {
    const payload = {
      company_id: companyId,
    };

    return CompanyService.client.get(`/relationship_management?${queryString.stringify(payload)}`);
  };

  static fetchRegenerateAiDescription = (searchPayload: RegenerateAiDescription) => {
    const payload = {
      company_id: searchPayload.companyId,
      company_name: searchPayload.companyName,
      company_url: searchPayload.companyUrl,
    };

    return CompanyService.client.get(
      `/ai_description/regenerate?${queryString.stringify(payload)}`
    );
  };

  static fetchOpinionAiDescription = (searchPayload: OpinionAiDescription) => {
    const body = {
      company_id: searchPayload.companyId,
      company_name: searchPayload.companyName,
      company_url: searchPayload.companyUrl,
      opinion_type: searchPayload.opinion,
    };

    return CompanyService.client.put('/ai_description/opinion', body);
  };

  static addCompany = (payload: AddCompanyPayload) => {
    return CompanyService.client.post('/create_company', payload);
  };

  static fetchAiRelationshipSummary = (searchPayload: AiRelationshipSummary) => {
    const body: {
      company_id: number;
      company_name: string;
      opinion_status: boolean;
      company_url: string | null;
      company_owner?: string;
    } = {
      company_id: searchPayload.companyId,
      company_name: searchPayload.companyName,
      opinion_status: searchPayload.opinion,
      company_url: searchPayload.companyUrl,
    };

    if (searchPayload.companyOwner) {
      body.company_owner = searchPayload.companyOwner;
    }

    return CompanyService.client.put('/ai_highlights/opinion', body);
  };
}

export default CompanyService;
