import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { useField } from 'formik';
import { Form, DatePicker } from 'formik-antd';
import { Button, Col, Typography } from 'antd';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
// constants
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT } from '@constants/format/date';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { LIST_OF_FUTURE_DATE_FIELDS } from '@optx/constants/future-date-fields';
// utils
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
import { isFutureDate, getLastRoundDisabledDate } from '@optx/utils/date';
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
// redux
import { selectors as fundingRoundsSelectors } from '@redux/company/funding-rounds';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import Styled from './DatepickerField.styled';

interface DatepickerFieldProps {
  field?: BaseField;
  value?: string;
  fieldName?: string;
  fieldType?: string;
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const DatepickerField: React.FC<DatepickerFieldProps> = ({
  field,
  value,
  fieldName,
  fieldType,
  isChromePlugin,
  onSave,
  onCancel,
  fieldUsedFor,
}) => {
  const [selectedDate, , helpersDate] = useField<string | null>(field?.id || fieldType || '');
  const [displayedDate, setDisplayedDate] = useState<Moment | null>(null);
  const [popUpDatapicker, setPopUpDatapicker] = useState(true);
  const [closePopup, setClosePopup] = useState(false);

  const secondToLastRoundDate = useSelector(fundingRoundsSelectors.getSecondToLastRoundDate);
  const numberOfRounds = useSelector(fundingRoundsSelectors.getNumberOfRounds);

  function handleDateChange(date: moment.Moment | null) {
    if (date && date.toString() !== 'Invalid Date') {
      setDisplayedDate(date);
      helpersDate.setValue(date.format(ISO_DATE_FORMAT));
    } else if (date === null) {
      setDisplayedDate(null);
      helpersDate.setValue(null);
    }

    setPopUpDatapicker(false);
  }

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
    setPopUpDatapicker(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      setDisplayedDate(moment(value));
    }

    if (!value && visibility) {
      setDisplayedDate(null);
    }

    setPopUpDatapicker(true);
  };

  useEffect(() => {
    if (
      field?.value &&
      displayedDate === null &&
      fieldUsedFor !== 'editAllInfo' &&
      !selectedDate.value
    ) {
      setDisplayedDate(moment(field.value));

      if (!isEqual(field.value, selectedDate.value)) {
        helpersDate.setValue(field.value);
      }
    } else if (field && !isEqual(displayedDate, selectedDate.value)) {
      if (selectedDate.value) {
        setDisplayedDate(moment(selectedDate.value));
      } else {
        setDisplayedDate(null);
      }
    }

    if (value) {
      setDisplayedDate(moment(value));
      helpersDate.setValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  useEffect(() => {
    if (selectedDate.value && !isEqual(displayedDate, selectedDate.value)) {
      setDisplayedDate(moment(selectedDate.value));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate.value]);

  const lastRoundDisabledDate = getLastRoundDisabledDate(
    field?.value ? moment(field.value) : moment(value),
    moment(secondToLastRoundDate),
    numberOfRounds
  );

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Form.Item name={field.id}>
          <Styled.DatepickerWrapper>
            <Typography.Text>{field.label}</Typography.Text>

            <DatePicker
              value={displayedDate ? displayedDate : undefined}
              defaultValue={displayedDate ? displayedDate : undefined}
              onChange={handleDateChange}
              format={globalConfig.short_date.DATE_FORMAT}
              name="financialDate"
              disabledDate={field.id === 'last_round_date' ? lastRoundDisabledDate : undefined}
              allowClear={fieldUsedFor === 'editAllInfo' && field.id !== 'last_round_date'}
              getPopupContainer={trigger => trigger.parentElement!}
            />
          </Styled.DatepickerWrapper>
        </Form.Item>
      </Col>
    );
  }

  // Last Round Date should not allow date less than round previous to last one (lastRoundDisabledDate function),
  // fields in LIST_OF_FUTURE_DATE_FIELDS allow future dates (undefined)
  // and the default is to disable future dates (isFutureDate function)
  const isLastRoundDate = fieldName === 'Date of Last Round';
  const allowFutureDate = LIST_OF_FUTURE_DATE_FIELDS.includes(fieldName || '');
  const futureDisabledDate = allowFutureDate ? undefined : isFutureDate;
  const disabledDate = isLastRoundDate ? lastRoundDisabledDate : futureDisabledDate;

  const content = (
    <Form.Item name={fieldType ?? ''}>
      <div
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType)
        }
        tabIndex={0}
      >
        <Styled.DatepickerWrapper>
          <Typography.Text>Edit "{fieldName}" value</Typography.Text>

          <DatePicker
            value={displayedDate ? displayedDate : undefined}
            defaultValue={displayedDate ? displayedDate : undefined}
            onClick={() => setPopUpDatapicker(true)}
            onChange={handleDateChange}
            disabledDate={disabledDate}
            format={globalConfig.short_date.DATE_FORMAT}
            autoFocus={popUpDatapicker}
            open={popUpDatapicker}
            onBlur={() => setPopUpDatapicker(false)}
            name={fieldType ?? ''}
            allowClear={false}
            getPopupContainer={trigger => trigger.parentElement!}
          />
          <div className="profile-information__popover-buttons">
            <Button className="profile-information__cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="profile-information__save"
              type="primary"
              disabled={checkSaveButton(displayedDate, moment(value))}
              onClick={() => {
                onSave && onSave(fieldType);
                setClosePopup(true);
              }}
            >
              Save
            </Button>
          </div>
        </Styled.DatepickerWrapper>
      </div>
    </Form.Item>
  );

  return (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      content={content}
      onVisibilityUpdate={handleVisibilityChange}
      destroyOnHide
      getPopupContainer={trigger => trigger.parentElement?.parentElement?.parentElement!}
    >
      <TruncateTooltip
        title={
          value ? moment(value).format(globalConfig.short_date.DATE_FORMAT) : DEFAULT_EMPTY_VALUE
        }
      >
        {value ? moment(value).format(globalConfig.short_date.DATE_FORMAT) : DEFAULT_EMPTY_VALUE}
      </TruncateTooltip>
    </EditPopover>
  );
};

export default React.memo(DatepickerField);
