import React, { useEffect, useState } from 'react';
import { components, IndicatorProps, OptionsType, SingleValueProps, ValueType } from 'react-select';
import { useField } from 'formik';
import { DownOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
// models
import { SuccessErrorCallback } from '@models/callback';
import { SelectOption } from '@models/Option';
import { EquityTouchFetchedField } from '@models/equityTouch';
// constants
import { GET_ET_COMPANIES_ENDPOINT } from '@constants/asyncEndpoints';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';
// styled components
import Styles from './EquityTouchAddon.style';

const SingleValue: React.FC<SingleValueProps<any>> = ({ children }) => (
  <div className="multiselect-select__single-value">
    <TruncateTooltip title={children} placement="topLeft">
      {children}
    </TruncateTooltip>
  </div>
);

/**
 * customDropdownIndicator - style the arrow of the dropdown.
 */
const DropdownIndicator: React.FC<IndicatorProps<any>> = props => (
  <components.DropdownIndicator {...props}>
    <DownOutlined />
  </components.DropdownIndicator>
);

interface EquityTouchAddonProps {
  name: string;
  initialValue: EquityTouchFetchedField;
}

const EquityTouchAddon: React.FC<EquityTouchAddonProps> = ({ name, initialValue }) => {
  const [field, , helpers] = useField(name);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectOption>>([]);

  const { loadOptions } = useAsyncSearch({ endpoint: GET_ET_COMPANIES_ENDPOINT });

  const handleChange = (options: ValueType<SelectOption>) => {
    setSelectedOptions(options as SelectOption[]);
    helpers.setValue(
      (options as SelectOption[]).map(option => ({ label: option.value, value: option.id }))
    );
  };

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  useEffect(() => {
    if (field.value && field.value.length !== selectedOptions.length) {
      const selectedOptions = field.value.map((option: SelectOption) => ({
        label: option.label,
        value: option.label,
      }));

      setSelectedOptions(selectedOptions);
    }
  }, [field.value, selectedOptions.length]);

  return (
    <Styles.AsyncWrapper>
      <AsyncMultiSelect
        className="addon--multiselect-async"
        cacheOptions
        components={{ DropdownIndicator, SingleValue }}
        loadOptions={getLoadOptionsDelay}
        defaultOptions={fetchedOptions}
        defaultValue={selectedOptions}
        getOptionValue={(option: SelectOption) => option.id?.toString() || ''}
        onChange={handleChange}
        maxLength={500}
        loadingMessage={({ inputValue }) => (inputValue.length > 2 ? 'Loading...' : 'Begin typing')}
        onSelectResetsInput={false}
        blurInputOnSelect={false}
        closeOnSelect
      />
    </Styles.AsyncWrapper>
  );
};

export default EquityTouchAddon;
