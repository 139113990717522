import { CaseReducer, createReducer, current, PayloadAction } from '@reduxjs/toolkit';
// models
import Company, {
  CompanyFundingRounds,
  CompanyProfile,
  PluginPrimaryContact,
} from '@optx/models/Company';
import { SelectOption } from '@optx/models/Option';
import { CompanyUserContact, ContactPhone } from '@models/api/contacts';
import {
  AddCompanyToWatchList,
  CompanyWatchList,
  DeleteCompanyFromWatchList,
} from '@optx/models/WatchList';
// interfaces
import {
  EditFieldSuccess,
  UpdateFieldsPayload,
  MultipleFieldsValue,
} from '@optx/features/company/edit-fields/state/interfaces';
import { TouchesFinancialPayload } from '@models/api/touches';
// constants
import { DEFAULT_COMPANY_OWNER_VALUE } from '@constants/table/company';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import {
  AiDescriptionResponse,
  AiRelationshipSummaryResponse,
} from '@optx/redux/company/profile/interfaces';
import { actions as companyProfileActions } from '@optx/redux/company/profile';
import { submitResearchRationaleSuccessReducer } from '@optx/redux/common/rationales/reducer';
import { fetchFailReducer, fetchReducer, fetchSuccessReducer } from '@redux/feature/fetch/reducers';
import * as researchRationaleActions from '@features/add-research-rationale/state/actions';
import { actions as tagsActions } from '@redux/company/tags';
import { actions as primaryContactsActions } from '@redux/company/primary-contacts';
import { actions as companyEditFields } from '@features/company/edit-fields/state';
import { SuccessCompanyTag, DeleteTagPayload } from '@optx/redux/company/tags/interfaces';
import { actions as bulkSalesloftActions } from '@optx/features/bulk-actions/salesloft';
import { EditCompanyStageActionPayload } from '@components/feature/company-individual-edit/state/stage/interfaces';
import { actions as companyStageActions } from '@components/feature/company-individual-edit/state/stage';
import { actions as favoriteListsActions } from '@redux/favorite-lists/lists';
import { actions as favoriteListsActionsPopup } from '@redux/favorite-lists/lists-popup';
import { EditCompanyOwnerSuccess } from '@components/feature/company-individual-edit/state/companyOwner/interfaces';
import { actions as companyOwnerActions } from '@components/feature/company-individual-edit/state/companyOwner';
import { actions as updateCompanyInfoActions } from '@redux/ui/modals/company-touches';
import { actions as editAllInfoActions } from '@optx/features/edit-all-info/state';
import { actions as fundingRoundActions } from '@redux/company/funding-rounds';
import {
  createCompanyToListSuccess,
  deleteCompanyToListSuccess,
} from '@optx/redux/common/list/reducers';
import * as actions from './actions';
import { CompanyCardState } from './interfaces';

const initialState: CompanyCardState = {
  data: null,
  pluginPrimaryContact: null,
  active: false,
  loading: false,
  error: '',
};

const fetchCompanyProfileReducer: CaseReducer<CompanyCardState> = draftState => {
  fetchReducer(draftState);
};

const fetchCompanyProfileSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<CompanyProfile>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);
  draftState.active = true;
};

const fetchPluginPrimaryContactReducer: CaseReducer<CompanyCardState> = draftState => {
  draftState.error = '';
};

const fetchPluginPrimaryContactSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<PluginPrimaryContact>
> = (draftState, action) => {
  draftState.loading = false;
  draftState.pluginPrimaryContact = action.payload;
};

const addCompanyTagSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<SuccessCompanyTag>
> = (draftState, action) => {
  const { companyId, ...tag } = action.payload;

  if (draftState.data) {
    // Add empty list if is null.
    if (!draftState.data.specialties) {
      draftState.data.specialties = [];
    }

    draftState.data.specialties.push(tag);
  }
};

const addContactSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  if (draftState.data) {
    const companyContacts = draftState.data?.contacts;
    const newContact = {
      ...(action.payload as CompanyUserContact),
      additional_phones: action.payload.phones as ContactPhone[],
      primary_contact: !!action.payload.primary_contact,
    };
    const mappedContacts = action.payload.primary_contact
      ? companyContacts?.map(contact => ({ ...contact, primary_contact: false }))
      : companyContacts;

    if (draftState.data && mappedContacts) {
      draftState.data.contacts = [...mappedContacts, ...[newContact]];
    }

    draftState.data.has_contacts = true;
  }
};

const deleteCompanyTagSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<DeleteTagPayload>
> = (draftState, action) => {
  const { id } = action.payload;

  if (draftState.data && draftState.data.specialties) {
    const index = draftState.data.specialties.findIndex(item => item.tag_id === id);
    const removedTagName: string | undefined = draftState.data.specialties.find(
      item => item.tag_id === id
    )?.tag;

    if (index !== -1) {
      draftState.data.specialties.splice(index, 1);
      const companyTags = draftState.data.cs_tags ? draftState.data.cs_tags.split('|') : [];

      if (removedTagName && companyTags.includes(removedTagName)) {
        const tagNameIndex = companyTags.indexOf(removedTagName);
        companyTags.splice(tagNameIndex, 1);
        draftState.data.cs_tags = companyTags.join('|');
      }
    }
  }
};

const addCompanyStageReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<EditCompanyStageActionPayload>
> = (draftState, action) => {
  const { companyId, companyStageText } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.stage = companyStageText;
  }
};

const addCompanyOwnerSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<EditCompanyOwnerSuccess>
> = (draftState, action) => {
  const { companyId, companyOwnerData } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    if (companyOwnerData) {
      draftState.data.company_owner =
        companyOwnerData.company_owner_name === null
          ? DEFAULT_COMPANY_OWNER_VALUE
          : companyOwnerData.company_owner_name;
      draftState.data.company_owner_id =
        companyOwnerData.company_owner_id !== null ? +companyOwnerData.company_owner_id : null;
      draftState.data.owner_img = companyOwnerData.company_owner_img;
    } else {
      draftState.data.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
      draftState.data.company_owner_id = null;
      draftState.data.owner_img = null;
    }
  }
};

const editUserContactSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  const contacts = draftState.data?.contacts;
  const contactDetails = action.payload;

  if (contacts) {
    const index = contacts.findIndex(contact => contact.person_id === contactDetails.person_id);
    const primaryContact = contacts.find(contact => contact.primary_contact);

    // check for existing primary contact and reset it,
    // if new contact is set as primary
    if (
      primaryContact &&
      contactDetails.primary_contact &&
      primaryContact.person_id !== contactDetails.person_id
    ) {
      primaryContact.primary_contact = false;
    }

    if (index !== -1) {
      contacts[index] = {
        ...contacts[index],
        ...contactDetails,
        is_edited: true,
        additional_phones: contactDetails.phones || null,
        // There is an issue with name. If initially there is no first_name or last_name
        // they are computed from full_name.  On edit only first_name and last name
        // are updated, but no full_name so we recreate it based on new values.
        full_name: `${contactDetails.first_name} ${contactDetails.last_name}`,
      };
    }
  }
};

const addCompanyToListSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<AddCompanyToWatchList>
> = (draftState, action) => {
  const { watchLists } = action.payload;

  let companyData = { ...draftState.data };

  if (draftState.data) {
    watchLists.forEach(list => {
      if (list.owner_name === action.payload.ownerName || list.owner_name === 'Equity Touch') {
        companyData = createCompanyToListSuccess(companyData as Company, list);
      }
    });
  }

  draftState.data = companyData as CompanyProfile;
};

export const deleteCompanyToListSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<DeleteCompanyFromWatchList>
> = (draftState, action) => {
  const { listId } = action.payload;

  const companyData = deleteCompanyToListSuccess(draftState.data as Company, listId);
  draftState.data = companyData as CompanyProfile;
};

export const createCompanyToListSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<{
    companyId: number;
    list: CompanyWatchList;
  }>
> = (draftState, action) => {
  const { list } = action.payload;

  const companyData = createCompanyToListSuccess(draftState.data as Company, list);
  draftState.data = companyData as CompanyProfile;
};

const updateFieldsSuccessReducer: CaseReducer<CompanyCardState, PayloadAction<EditFieldSuccess>> = (
  draftState,
  action
) => {
  const {
    companyId,
    companyOwnerData,
    fieldName,
    value,
    ilScore,
    ilScoreGrowth,
    score,
    scoreGrowth,
    newScore,
    newScoreGrowth,
    isPsgCompany,
    defaultOptxScore,
  } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    const optionSelected = (value as UpdateFieldsPayload).value as SelectOption;
    const rationale = (value as UpdateFieldsPayload).rationale;

    if (defaultOptxScore === 'il') {
      draftState.data.il_optx_score = isNaN(Number(ilScore))
        ? draftState.data.il_optx_score
        : Number(ilScore);
      draftState.data.il_score_growth =
        ilScoreGrowth || isNaN(Number(draftState.data.il_score_growth))
          ? draftState.data.il_score_growth
          : Number(draftState.data.il_score_growth);
    }

    if (!Array.isArray(fieldName)) {
      if (fieldName === 'Date Presented') {
        const selectedValue = (value as UpdateFieldsPayload).value as string[];
        draftState.data.date_presented = selectedValue;
      }

      if (fieldName !== 'PSG Platform' && fieldName !== 'Add-on') {
        draftState.data.score = isNaN(Number(score)) ? draftState.data.score : Number(score);
        draftState.data.score_growth = scoreGrowth?.toString() as string;
        draftState.data.score_v3 = isNaN(Number(newScore))
          ? draftState.data.score_v3
          : Number(newScore);
        draftState.data.score_v3_growth = newScoreGrowth?.toString() as string;
      }

      if (fieldName === 'Website Status') {
        draftState.data.url_status = optionSelected ? 'active' : 'not active';
      }

      if (fieldName === 'Company Type') {
        draftState.data.company_type = optionSelected.label;
      }

      if (fieldName === 'Pipeline Rank') {
        draftState.data.pipeline_rank =
          optionSelected.value !== 'blank' ? optionSelected.label : DEFAULT_EMPTY_VALUE;
      }

      if (fieldName === 'Company Owner') {
        draftState.data.company_owner = companyOwnerData
          ? companyOwnerData.company_owner_name
          : DEFAULT_COMPANY_OWNER_VALUE;
        draftState.data.owner_img = companyOwnerData
          ? companyOwnerData.company_owner_img
          : draftState.data.owner_img;
      }

      if (fieldName === 'Stage' || fieldName === 'Fund') {
        draftState.data.stage =
          optionSelected.value !== 'blank' ? optionSelected.label : DEFAULT_EMPTY_VALUE;
        draftState.data.is_psg_company = isPsgCompany as boolean;

        if (fieldName === 'Stage') {
          if (showRationaleForStage(optionSelected.label)) {
            draftState.data.stage_rationale = rationale as string;
          } else {
            draftState.data.stage_rationale = null;
          }
        }
      }
    } else {
      if (fieldName.includes('Stage')) {
        const optionSelected = (value as MultipleFieldsValue).value.Stage;
        draftState.data.stage =
          optionSelected.value !== 'blank' ? optionSelected.label : DEFAULT_EMPTY_VALUE;
        draftState.data.is_psg_company = isPsgCompany as boolean;

        if (showRationaleForStage(optionSelected.label)) {
          draftState.data.stage_rationale = rationale as string;
        } else {
          draftState.data.stage_rationale = null;
        }
      }

      if (fieldName.includes('Pipeline Rank')) {
        const optionSelected = (value as MultipleFieldsValue).value['Pipeline Rank'];
        draftState.data.pipeline_rank =
          optionSelected.value !== 'blank' ? optionSelected.label : DEFAULT_EMPTY_VALUE;
      }

      if (fieldName.includes('Company Owner')) {
        draftState.data.company_owner = companyOwnerData
          ? companyOwnerData.company_owner_name
          : DEFAULT_COMPANY_OWNER_VALUE;
        draftState.data.owner_img = companyOwnerData
          ? companyOwnerData.company_owner_img
          : draftState.data.owner_img;
      }

      draftState.data.score = isNaN(Number(score)) ? draftState.data.score : Number(score);
      draftState.data.score_growth = scoreGrowth?.toString() as string;
      draftState.data.score_v3 = isNaN(Number(newScore))
        ? draftState.data.score_v3
        : Number(newScore);
      draftState.data.score_v3_growth = newScoreGrowth?.toString() as string;
    }
  }
};

const updateCompanyInfoSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  if (draftState.data && draftState.data.company_id === payload.company_id) {
    if (payload.general_info) {
      if (payload.general_info.primary_contact) {
        const contacts = draftState.data.contacts.map(contact => {
          if (contact.person_id === payload.general_info?.primary_contact) {
            return { ...contact, primary_contact: true };
          } else {
            return { ...contact, primary_contact: false };
          }
        });
        draftState.data.contacts = contacts;
      }

      if (payload.general_info.num_employees !== undefined) {
        draftState.data.num_employees = payload.general_info.num_employees;
      }

      if (payload.general_info.sub_sector) {
        draftState.data.sub_sector = payload.general_info.sub_sector.name;
      }

      if (payload.general_info.product_category) {
        const updatedProdCat = payload.general_info.product_category.map(
          (prodCat: SelectOption) => {
            return {
              category: prodCat.label,
              // @ts-ignore
              is_valid: 'isValid' in prodCat ? prodCat.isValid : prodCat.is_valid,
            };
          }
        );
        draftState.data.product_category = updatedProdCat;
      }
    }

    if (payload.funding) {
      if (payload.funding.capital_raised) {
        draftState.data.capital_raised = payload.funding.capital_raised;
      }

      if (payload.funding.last_round) {
        draftState.data.last_round =
          payload.funding.last_round.label &&
          payload.funding.last_round.label !== DEFAULT_EMPTY_VALUE
            ? payload.funding.last_round.label
            : null;
        draftState.data.last_round_id =
          payload.funding.last_round.value &&
          payload.funding.last_round.value.toString() !== DEFAULT_EMPTY_VALUE
            ? payload.funding.last_round.value
            : null;
      }

      if (payload.funding.next_steps) {
        draftState.data.next_steps = payload.funding.next_steps;
      }

      if (payload.funding.acquiring_company) {
        draftState.data.parentcompany = payload.funding.acquiring_company;
      }

      if (payload.funding.last_round_date) {
        draftState.data.raise_date = payload.funding.last_round_date;
      }

      if (payload.funding.last_round_amount) {
        draftState.data.last_raised_amount = payload.funding.last_round_amount;
      }

      if (payload.funding.last_investors) {
        draftState.data.last_investors = payload.funding.last_investors || [];
      }
    }

    if (payload.financials) {
      if (payload.financials.ebitda) {
        draftState.data.ebitda_list = payload.financials.ebitda;
      }

      if (payload.financials.revenue) {
        draftState.data.revenue_list = payload.financials.revenue;
      }
    }
  }
};

const updateAllCompanyEditAllInfoSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  if (draftState.data && draftState.data.company_id === payload.company_id) {
    draftState.data = {
      ...draftState.data,
      ...payload,
    };
  }
};

const loadingCompletedReducer: CaseReducer<CompanyCardState> = draftState => {
  draftState.loading = false;
};

const aiDescriptionSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<AiDescriptionResponse>
> = (draftState, action) => {
  const { description } = action.payload;

  if (draftState.data) {
    const stateDescriptions = current(draftState.data?.descriptions);
    draftState.data.company_description = description;

    if (stateDescriptions && 'AI' in stateDescriptions) {
      draftState.data.descriptions.AI = description;
    } else {
      draftState.data.descriptions = { AI: description, ...stateDescriptions };
    }
  }

  draftState.loading = false;
};

const companyFundingRoundsSuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<CompanyFundingRounds>
> = (draftState, action) => {
  if (draftState.data) {
    const { total_raised, last_round } = action.payload;

    if (total_raised !== null) {
      draftState.data.capital_raised = total_raised;
      draftState.data.is_capital_raise_has_unknown = total_raised.includes('(plus Unknown)');
    }

    if (last_round?.amount !== null) {
      draftState.data.last_raised_amount = last_round?.amount as unknown as number;
    }
  }
};

const aiRelationshipSummarySuccessReducer: CaseReducer<
  CompanyCardState,
  PayloadAction<AiRelationshipSummaryResponse>
> = (draftState, action) => {
  if (draftState.data) {
    draftState.data.highlights = action.payload.highlights;
    draftState.data.is_ai_highlights_approved = action.payload.is_ai_highlights_approved;
  }
};

export const reducer = createReducer<CompanyCardState>(initialState, builder =>
  builder
    .addCase(actions.fetchCompanyProfile, fetchCompanyProfileReducer)
    .addCase(actions.fetchCompanyProfileSuccess, fetchCompanyProfileSuccessReducer)
    .addCase(actions.fetchCompanyProfileFail, fetchFailReducer)
    .addCase(actions.fetchPluginPrimaryContact, fetchPluginPrimaryContactReducer)
    .addCase(actions.fetchPluginPrimaryContactSuccess, fetchPluginPrimaryContactSuccessReducer)
    .addCase(actions.fetchPluginPrimaryContactFail, fetchFailReducer)
    .addCase(tagsActions.addCompanyTagSuccess, addCompanyTagSuccessReducer)
    .addCase(companyStageActions.addCompanyStage, addCompanyStageReducer)
    .addCase(companyOwnerActions.addCompanyOwnerSuccess, addCompanyOwnerSuccessReducer)
    .addCase(tagsActions.deleteCompanyTagSuccess, deleteCompanyTagSuccessReducer)
    .addCase(favoriteListsActions.addCompanyToListSuccess, addCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.deleteCompanyFromListSuccess, deleteCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.createFavoriteListSuccess, createCompanyToListSuccessReducer)
    .addCase(favoriteListsActionsPopup.addCompanyToListPopupSuccess, addCompanyToListSuccessReducer)
    .addCase(
      favoriteListsActionsPopup.deleteCompanyFromListPopupSuccess,
      deleteCompanyToListSuccessReducer
    )
    .addCase(
      favoriteListsActionsPopup.createFavoriteListPopupSuccess,
      createCompanyToListSuccessReducer
    )
    .addCase(primaryContactsActions.editPrimaryContactSuccess, editUserContactSuccessReducer)
    .addCase(bulkSalesloftActions.addContactSuccess, addContactSuccessReducer)
    .addCase(
      researchRationaleActions.submitResearchRationaleSuccess,
      submitResearchRationaleSuccessReducer
    )
    .addCase(companyEditFields.updateFieldsSuccess, updateFieldsSuccessReducer)
    .addCase(
      updateCompanyInfoActions.updateTouchCompanyInfoSuccess,
      updateCompanyInfoSuccessReducer
    )
    .addCase(editAllInfoActions.updateCompanyEditAllInfoSuccess, updateCompanyInfoSuccessReducer)
    .addCase(
      editAllInfoActions.updateAllCompanyEditAllInfoSuccess,
      updateAllCompanyEditAllInfoSuccessReducer
    )
    .addCase(fundingRoundActions.getCompanyFundingRoundsSuccess, companyFundingRoundsSuccessReducer)
    .addCase(companyProfileActions.opinionAiDescriptionSuccess, aiDescriptionSuccessReducer)
    .addCase(companyProfileActions.opinionAiDescriptionFail, loadingCompletedReducer)
    .addCase(companyProfileActions.regenerateAiDescriptionFail, loadingCompletedReducer)
    .addCase(companyProfileActions.regenerateAiDescriptionSuccess, aiDescriptionSuccessReducer)
    .addCase(
      companyProfileActions.aiRelationshipSummarySuccess,
      aiRelationshipSummarySuccessReducer
    )
);
