import styled, { createGlobalStyle, css } from 'styled-components';
import { Select } from 'antd';

const ContactSelectGlobal = createGlobalStyle`
  .contact-option {
    .ant-tag {
      margin: 0 5px;
    }
    .ant-select-item-option-content {
      order: 2;
    }
    .ant-select-item-option-state {
      order: 1;
      margin-right: 12px;
    }
  }
`;

const SelectWrapper = styled(Select)<{ $isCell?: boolean; $verticalAlign?: boolean }>`
  width: 100%;
  border: 1px solid #d9d9d9;

  .ant-select-selector {
    .ant-select-selection-search {
      opacity: 0;
    }
    .ant-select-selection-search-input {
      padding: 0;
    }
  }
  &.ant-select-open {
    .ant-select-selector {
      .ant-select-selection-placeholder,
      .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
        display: none;
      }
      .ant-select-selection-search {
        opacity: 1;
      }
    }
  }
  .ant-select-selection-overflow-item + .ant-select-selection-overflow-item:before {
    content: ', ';
  }
  .ant-select-selection-overflow-item + .ant-select-selection-overflow-item-suffix:before {
    display: none;
  }
  &.ant-select .ant-select-selector {
    display: flex;
    flex-wrap: nowrap;
  }
  .ant-select-selection-overflow {
    min-height: 40px;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    .ant-select-selection-overflow-item {
      display: inline-block;
    }
  }
  .ant-select-arrow {
    z-index: 1;
    top: 46%;

    .anticon svg {
      width: 12px;
      height: 12px;
    }
  }
  .ant-select-selector span {
    pointer-events: none;
  }
  .ant-select-selector span.flex-grow-1 {
    margin-top: -3px;
  }
  .ant-select-selector {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    z-index: 1;
    padding: 0 30px 0 11px !important;
    font-size: 16px;
    color: #334d6e;
    line-height: 38px;

    ${props =>
      props.$verticalAlign &&
      css`
        vertical-align: bottom;
      `}

    .ant-tag {
      margin: 0 0 0 5px;
    }
    .ant-select-selection-search {
      position: absolute;
      bottom: 3px;
    }
    & > span {
      z-index: -1;
      &:not(:first-child):not(.ant-select-selection-placeholder):not(
          .ant-select-selection-search
        ):before {
        content: ', ';
      }
    }
  }

  ${props =>
    props.$isCell &&
    css`
      width: 100%;
      border: 0;
      background-color: inherit;
      display: flex;
      align-items: center;

      .ant-select-selector {
        width: 100%;
        padding: 1px 6px;
        border: 0;
        color: #1890ff;
        background-color: transparent;

        .ant-select-selection-placeholder {
          left: 0;
        }
      }

      .ant-select-selection-placeholder {
        color: #1890ff;
      }

      .ant-select-arrow {
        height: 100%;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        right: 0;
        top: 0;
        background: inherit;
        transition: background 0.25s ease-out;

        .anticon svg {
          color: #1890ff;
          width: 16px;
          height: 16px;
        }
      }
    `}
`;

export default { ContactSelectGlobal, SelectWrapper };
