import styled from 'styled-components';

interface FormErrorMaskProps {
  $isChromeExtension: boolean;
}

const FormErrorMask = styled.div<FormErrorMaskProps>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001; // index of antd's Drawer is 1000

  display: flex;
  flex-direction: column;

  width: ${props => (props.$isChromeExtension ? '640px' : '735px')};
  height: ${props => (props.$isChromeExtension ? 'calc(100% - 60px)' : '100%')};
  margin-top: ${props => (props.$isChromeExtension ? '60px' : '0')};
  background: rgba(255, 255, 255, 0.9);

  div {
    padding: 40px;
    margin: 180px auto 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 12px;
    }

    p,
    button {
      display: block;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const Styled = {
  FormErrorMask,
};
