import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
// styles
import '@optx/components/pages/Home/Header/SearchHeader.styles.scss';
// models
import { GridKeys } from '@optx/models/grid';
// utils
import { mapTagsToNamesAndOperators } from '@utils/filters/mapTagsToNamesAndOperators';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as fullScreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as filterSelectors } from '@features/grid/filter';
import { selectors as searchesSelectors } from '@redux/company/saved-searches';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userSelectors } from '@redux/user/information';
// components
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import SearchCompaniesHints from '../../../../Home/SearchCompaniesHints';
import CompanySearch from './CompanySearch';
import FilterTags from './FilterTags';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
// eslint-disable-next-line max-len
import { default as CombinedSearchFilterTags } from '@optx/components/common/table/TableSavedSearches/cells/company/FilterTags';
import { FilterTagsSkeleton } from '@optx/components/common/skeletons';

const SearchHeader = () => {
  const dispatch = useDispatch();

  const { pathname } = window.location;
  const gridKey = (pathname.includes('ss-lists') ? 'sslists' : 'watchlists') as GridKeys;

  const showRestoreIcon = useSelector(filterSelectors.showCustomClearFilter(gridKey));
  const customClearedFilter = useSelector(filterSelectors.getCustomClearedFilter(gridKey));
  const isFullScreen = useSelector(fullScreenSelectors.isFullscreen);

  const savedSearches = useSelector(searchesSelectors.getSavedSearches);
  const combinedSearchId = useSelector(userSelectors.getCombinedSearchId(gridKey));
  const selectedCombinedSearch = savedSearches.find(
    search => search.unique_id === combinedSearchId
  );
  const listType = useSelector(searchSelectors.getListType('lists'));
  const filterLoading = useSelector(filterSelectors.getFiltersLoaded(gridKey));

  const filterNames = mapTagsToNamesAndOperators(
    selectedCombinedSearch?.parent_search_ids,
    savedSearches,
    selectedCombinedSearch?.logical_operators
  );

  return (
    <div className="search-header-content">
      <CompanySearch className="antd-search-input" autoComplete="off" />
      {filterLoading ? (
        listType === 'combined' ? (
          <CombinedSearchFilterTags
            filterNames={filterNames}
            listType={listType}
            savedSearches={filterNames}
          />
        ) : (
          <FilterTags />
        )
      ) : (
        <FilterTagsSkeleton length={3} />
      )}
      <Space size={4} style={{ marginLeft: 'auto' }}>
        <SearchCompaniesHints />
        <ClearSearchButton
          title="Clear All"
          isVisible={showRestoreIcon || listType === 'combined'}
          onClick={() =>
            dispatch(searchActions.clearSearch({ gridKey: 'lists', data: customClearedFilter }))
          }
        />
        {isFullScreen && <GridFullScreen />}
      </Space>
    </div>
  );
};

export default React.memo(SearchHeader);
