import React from 'react';
import { ColumnType } from 'antd/lib/table';
import numeral from 'numeral';
// services
import { UserService, CompanyService } from '@optx/services/api';
// models
import { PipelineReportCompanies } from '@features/pipeline-report/models';
import Company, { Addon } from '@optx/models/Company';
// constants
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
// components
import { TableCellDefault } from '@optx/components/common/table/AntTable/cells';
import {
  EditFieldSingleSelect,
  EditFieldSingleSelectOther,
  EditFieldText,
} from '@optx/features/company/edit-fields/components';
import { TableCellCompanyName, TableCellCompanyURL, TableCellDate, TableCellTeams } from './cells';
import TableCellActions from './cells/TableCellActions';
import { TableCellAddon } from '@optx/components/common/table/Companies/cells';

const columns: Array<ColumnType<PipelineReportCompanies & Company>> = [
  {
    dataIndex: 'company_url',
    render: (value: string | null, record: Company) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 36,
    className: 'company_url align-center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    render: (value: string, record: Company) => (
      <TableCellCompanyName value={value} record={record as Company} />
    ),
    width: 150,
    className: 'name',
    ellipsis: true,
  },
  {
    dataIndex: 'stage',
    className: 'stage',
    title: 'Stage',
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        service={CompanyService.updateCompanyData}
        fieldName="Stage"
        fieldKey="stage"
        value={value ?? 'None'}
        successMessage="Company stage updated successfully"
        errorMessage="Company stage update failed, Server error!"
        record={record}
      />
    ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'addon',
    className: 'addon',
    title: 'Add-on For',
    render: (value: Addon | null, record: Company) => (
      <TableCellAddon value={value} record={record} />
    ),
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'location',
    title: 'Location',
    className: 'location',
    render: TableCellDefault,
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'asking_amount',
    className: 'asking_amount',
    title: 'Equity Check (Ask Amount)',
    width: 200,
    ellipsis: true,
    render: (value: string, record: Company) => (
      <EditFieldText
        record={record}
        value={numeral(Number(value)).format('0.[0]a')}
        isFromGrid
        fieldName="Asking Amount"
      />
    ),
  },
  {
    dataIndex: 'fund',
    className: 'fund',
    title: 'Fund',
    width: 110,
    ellipsis: true,
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        fieldName="Fund"
        fieldKey="fund"
        service={CompanyService.updateCompanyData}
        value={value}
        successMessage="Company fund updated successfully"
        errorMessage="Company fund update failed, Server error!"
        record={record}
      />
    ),
  },
  {
    dataIndex: 'senior_deal_team_lead',
    title: 'Senior Deal Team Lead',
    className: 'senior_deal_team_lead',
    render: (value: string, record: Company) => <TableCellTeams value={value} record={record} />,
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'deal_team',
    className: 'deal_team',
    title: 'Deal Team',
    render: (value: string, record: Company) => <TableCellTeams value={value} record={record} />,
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'ops_team',
    className: 'ops_team',
    title: 'OPS Team',
    render: (value: string, record: Company) => <TableCellTeams value={value} record={record} />,
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'lead_source',
    className: 'lead_source',
    title: 'Lead Source',
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        value={value ?? 'None'}
        record={record}
        service={CompanyService.updateCompanyData}
        successMessage="Lead Source updated successfully"
        errorMessage="Lead Source update failed, Server error!"
        fieldName="Lead Source"
        fieldKey="lead_source"
      />
    ),
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'ic_status_date',
    className: 'ic_status_date',
    title: 'IC Status',
    render: (value: string, record: Company) => <TableCellDate value={value} record={record} />,
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'expected_close_date',
    className: 'expected_close_date',
    title: 'Close Date',
    render: (value: string, record: Company) => <TableCellDate value={value} record={record} />,
    width: 140,
    ellipsis: true,
  },
  {
    dataIndex: 'next_steps',
    title: 'Next Steps',
    className: 'next_steps',
    render: (value: string, record: Company) => (
      <EditFieldSingleSelectOther
        dropdownPlacement="topRight"
        isFromGrid
        service={UserService.updateNextSteps}
        fieldName="Next Steps"
        value={value}
        successMessage="Company next steps updated successfully!"
        errorMessage="Company next steps update failed, Server error!"
        record={record}
        fieldOtherKey={'next_steps_opinion'}
        otherEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
        fieldOtherValue={record.next_steps_opinion}
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'row_action',
    title: 'Action',
    align: 'center',
    fixed: 'right',
    className: 'cell-actions',
    render: (value: unknown, record: Company) => <TableCellActions value={value} record={record} />,
    width: 65,
    ellipsis: true,
  },
];

export default columns;
