import React, { useEffect, useMemo, useState } from 'react';
import { Button, Menu, MenuItemProps, MenuProps, Modal, Typography } from 'antd';
import { OptionsType } from 'react-select';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
import { UpdateListAssociation } from '@optx/models/WatchList';
// constants
import { COMPANY_NAME_ENDPOINT } from '@constants/asyncEndpoints';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// redux
import { actions } from '@optx/redux/favorite-lists/lists';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import { AsyncSingleSelect } from '@optx/shared/view/molecules/Select';

interface AddListAssociationMenuItemProps extends MenuItemProps {
  listId: number | string;
  companyName: string | undefined;
}

interface CompanyOption {
  label: string;
  value: string | null;
}

const AddListAssociationMenuItem: React.FC<AddListAssociationMenuItemProps> = ({
  onClick,
  listId,
  companyName,
  ...restProps
}) => {
  const isEdit = !!companyName;
  const initialCompany = useMemo(
    () => ({
      label: companyName || '',
      value: companyName || '',
    }),
    [companyName]
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyOption | null>(initialCompany);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>();

  const dispatch = useDispatch();
  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_NAME_ENDPOINT });

  const handleSave = () => {
    setShowModal(false);

    if (selectedCompany && selectedCompany?.value) {
      const payload: UpdateListAssociation = { listId, addonCompany: selectedCompany?.value };
      dispatch(actions.updateFavoriteListAssociation(payload));
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setSelectedCompany(initialCompany);
  };

  const handleClick: MenuProps['onClick'] = info => {
    setShowModal(true);
    onClick && onClick(info);
  };

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        options.unshift();
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  const handleMenuClose = () => {
    setFetchedOptions([]);
  };

  useEffect(() => {
    if (!companyName && companyName !== initialCompany.value) {
      setSelectedCompany(initialCompany);
    }
  }, [companyName, initialCompany]);

  return (
    <>
      <Menu.Item onClick={handleClick} {...restProps}>
        {isEdit ? 'Edit List Association (Add-ons)' : 'Associate List with a Company (Add-ons)'}
      </Menu.Item>
      <Modal
        visible={showModal}
        title="Associate Add-ons with Company?"
        centered
        onCancel={handleCancel}
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        }
      >
        <Typography.Text>Add-on For</Typography.Text>
        <AsyncSingleSelect
          className="addon--multiselect-async"
          value={selectedCompany as SelectOption<string>}
          onChange={e => setSelectedCompany(e as SelectOption<string>)}
          loadOptions={getLoadOptionsDelay}
          defaultOptions={fetchedOptions}
          controlShouldRenderValue
          closeMenuOnSelect
          blurInputOnSelect={false}
          onSelectResetsInput={false}
          onMenuClose={handleMenuClose}
          loadingMessage={({ inputValue }) =>
            inputValue.length > 2 ? 'Loading...' : 'Begin typing'
          }
        />
      </Modal>
    </>
  );
};

export default AddListAssociationMenuItem;
