import React, { useState } from 'react';
import { Card, Row, Col, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '@umijs/hooks';
import { Gutter } from 'antd/lib/grid/row';
import { CHART_KEYS } from '@constants/charts';
import { ChartIntervalKeys } from '@optx/models/charts/data';
import { RadioChangeEvent } from 'antd/lib/radio';
import WWWebsiteRankChart from './website-rank';
import G2Chart from './g2';
import GlassDoorChart from './glassdoor';
import LinkedinChart from './linkedin';
import CrunchbaseChart from './crunchbase';
import TwitterChart from './twitter';
import FacebookChart from './facebook';
import CapterraChart from './capterra';
import USWebsiteRankChart from './website-rank/USWebsiteRankChart';
import {
  selectors as localLinkedinSelectors,
  actions as localLinkedinActions,
} from '../state/linkedin';
import {
  selectors as localFacebookSelectors,
  actions as localFacebookActions,
} from '../state/facebook';

interface SocialMediaProps {
  companyId: number;
}

const gridGutter: Gutter | [Gutter, Gutter] = [
  { xs: 24, sm: 24, xl: 40 },
  { xs: 24, sm: 24, xl: 40 },
];

const SocialMedia: React.FC<SocialMediaProps> = ({ companyId }) => {
  const [chartInterval, setChartInterval] = useState<ChartIntervalKeys>('2Y');
  const dispatch = useDispatch();

  const chartDataLinkedin = useSelector(localLinkedinSelectors.getData);
  const activeChartDataLinkedin = chartDataLinkedin[chartInterval].data;

  const chartDataFacebook = useSelector(localFacebookSelectors.getData);
  const activeChartDatFacebook = chartDataFacebook[chartInterval].data;

  useMount(() => dispatch(localLinkedinActions.fetchLinkedinData(companyId)));
  useMount(() => dispatch(localFacebookActions.fetchFacebookData(companyId)));

  const changeInterval = (e: RadioChangeEvent) => {
    window.pendo.track(`CompanyPage_SocialMedia_${e.target.value}`);
    setChartInterval(e.target.value);
  };

  const header = (
    <Radio.Group value={chartInterval}>
      {CHART_KEYS.map((key, index) => (
        <Radio.Button value={key} key={index} onChange={changeInterval}>
          {key}
        </Radio.Button>
      ))}
    </Radio.Group>
  );

  return (
    <Card title={header}>
      <Row gutter={gridGutter} className="social-media-cards">
        <Col span={24} xl={12}>
          <USWebsiteRankChart companyId={companyId} chartInterval={chartInterval} />
        </Col>
        <Col span={24} xl={12}>
          <WWWebsiteRankChart companyId={companyId} chartInterval={chartInterval} />
        </Col>
        <Col span={24} xl={12}>
          <GlassDoorChart companyId={companyId} chartInterval={chartInterval} />
        </Col>
        <Col span={24} xl={12}>
          <G2Chart companyId={companyId} chartInterval={chartInterval} />
        </Col>
        <Col span={24} xl={12}>
          <CrunchbaseChart companyId={companyId} chartInterval={chartInterval} />
        </Col>
        <Col span={24} xl={12}>
          <CapterraChart companyId={companyId} chartInterval={chartInterval} />
        </Col>
        {activeChartDataLinkedin.length !== 0 && (
          <Col span={24} xl={12}>
            <LinkedinChart companyId={companyId} chartInterval={chartInterval} />
          </Col>
        )}
        <Col span={24} xl={12}>
          <TwitterChart companyId={companyId} chartInterval={chartInterval} />
        </Col>

        {activeChartDatFacebook.length !== 0 && (
          <Col span={24} xl={12}>
            <FacebookChart companyId={companyId} chartInterval={chartInterval} />
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default SocialMedia;
