/* eslint-disable max-len */
import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { has, Dictionary } from 'lodash';
// individual actions
import { actions as updateIndividualStageActions } from '@components/feature/company-individual-edit/state/stage';
import { actions as updateIndividualPipelineRankActions } from '@components/feature/company-individual-edit/state/pipelineRank';
import { actions as updateIndividualCompanyOwnerActions } from '@components/feature/company-individual-edit/state/companyOwner';
// models
import { EditCompanyStageActionPayload } from '@optx/components/feature/company-individual-edit/state/stage/interfaces';
import { EditCompanyPipelineRank } from '@optx/components/feature/company-individual-edit/state/pipelineRank/interfaces';
import { EditCompanyOwnerSuccess } from '@optx/components/feature/company-individual-edit/state/companyOwner/interfaces';
import {
  SelectedCompanies,
  SalesloftCompanyContacts,
  SelectedCompaniesGrids,
} from '@models/bulkActions';
import { UserInformation } from '@optx/models/user';
import { CompanyUserContact } from '@models/api/contacts';
import { actions as salesloftBulkActions } from '@features/bulk-actions/salesloft';
import { getSalesloftEmails } from '@optx/utils/salesloft';
import { getEmailsFromString } from '@optx/utils/equityTouch';
import { actions as leadSourceActions } from '@optx/components/feature/company-individual-edit/state/leadSource';
import * as bulkEtActions from '@features/bulk-actions/equity-touch/state/actions';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as seniorAdvisorActions } from '@optx/components/feature/company-individual-edit/state/seniorAdviserThesis';
import { actions as fundActions } from '@optx/components/feature/company-individual-edit/state/fund';
import { EditLeadSource } from '@components/feature/company-individual-edit/state/leadSource/interfaces';
import { EditSeniorAdvisor } from '@components/feature/company-individual-edit/state/seniorAdviserThesis/interfaces';
import { EditCompanyFund } from '@optx/components/feature/company-individual-edit/state/fund/interfaces';
import {
  SelectedCompaniesState,
  SelectedCompaniesPayload,
  FetchBulkEditDataSuccess,
  BulkUpdateDataSuccess,
} from './interfaces';
import { Addon } from '@optx/models/Company';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import * as actions from './actions';
import { updateFieldsSuccess } from '@optx/features/company/edit-fields/state/actions';

const updateState = (
  state: SelectedCompaniesState,
  payload: BulkUpdateDataSuccess,
  key: SelectedCompaniesGrids,
  field: string
) => {
  const label = payload.changedValue === 'None' ? DEFAULT_EMPTY_VALUE : payload.changedValue;

  state[key]
    .filter(item => payload.updatedCompaniesIds.includes(item.company_id))
    .map(item => {
      if (field === 'product_category' && Array.isArray(label)) {
        const categories = label.map(data => ({
          category: data,
          is_valid: true,
        }));
        // eslint-disable-next-line no-param-reassign
        item[field] = [...categories];
      } else {
        // eslint-disable-next-line no-param-reassign
        item[field as 'company_owner' | 'stage' | 'pipeline_rank'] = label as string;

        if (field === 'stage') {
          if (showRationaleForStage(label as string)) {
            // eslint-disable-next-line no-param-reassign
            item.stage_rationale = (payload.extraOptions as Dictionary<string>)
              .stage_rationale as string;
          } else {
            // eslint-disable-next-line no-param-reassign
            item.stage_rationale = null;
          }
        } else if (field === 'addon') {
          const currentAddons = item[field];

          // eslint-disable-next-line no-param-reassign
          item[field] = { ...currentAddons, ...(label as Addon) };
        }
      }

      return item;
    });
};

const updateIndividualStageState = (
  state: SelectedCompaniesState,
  payload: EditCompanyStageActionPayload,
  key: SelectedCompaniesGrids,
  field: string
) => {
  state[key]
    .filter(item => item.company_id === payload.companyId)
    .map(item => {
      // eslint-disable-next-line no-param-reassign
      item[field as 'stage'] = payload.companyStageText;

      return item;
    });
};

const updateIndividualPipelineRankState = (
  state: SelectedCompaniesState,
  payload: EditCompanyPipelineRank,
  key: SelectedCompaniesGrids,
  field: string
) => {
  state[key]
    .filter(item => item.company_id === payload.companyId)
    .map(item => {
      // eslint-disable-next-line no-param-reassign
      (item[field as 'pipeline_rank'] as string | number) = payload.pipelineRankText;

      return item;
    });
};

const updateIndividualCompanyOwnerState = (
  state: SelectedCompaniesState,
  payload: EditCompanyOwnerSuccess,
  key: SelectedCompaniesGrids,
  field: string
) => {
  state[key]
    .filter(item => item.company_id === payload.companyId)
    .map(item => {
      // eslint-disable-next-line no-param-reassign
      item[field as 'company_owner'] =
        payload.companyOwnerData && payload.companyOwnerData.company_owner_name
          ? payload.companyOwnerData.company_owner_name
          : null;

      return item;
    });
};

const updateIndividualLeadSourceState = (
  state: SelectedCompaniesState,
  payload: EditLeadSource,
  key: SelectedCompaniesGrids,
  field: string
) => {
  state[key]
    .filter(item => item.company_id === payload.companyId)
    .map(item => {
      // eslint-disable-next-line no-param-reassign
      item[field as 'lead_source'] = payload.leadSourceName;

      return item;
    });
};

const updateIndividualSeniorAdvisorState = (
  state: SelectedCompaniesState,
  payload: EditSeniorAdvisor,
  key: SelectedCompaniesGrids,
  field: string
) => {
  state[key]
    .filter(item => item.company_id === payload.companyId)
    .map(item => {
      // eslint-disable-next-line no-param-reassign
      item[field as 'senior_advisor_thesis'] = payload.seniorAdvisorName;

      return item;
    });
};

const updateIndividualFundState = (
  state: SelectedCompaniesState,
  payload: EditCompanyFund,
  key: SelectedCompaniesGrids,
  field: string
) => {
  state[key]
    .filter(item => item.company_id === payload.companyId)
    .map(item => {
      // eslint-disable-next-line no-param-reassign
      item[field as 'fund'] = payload.fundName;

      return item;
    });
};

const updateEtContactsState = (
  state: SelectedCompaniesState,
  payload: Partial<CompanyUserContact>,
  key: SelectedCompaniesGrids
) =>
  state[key]
    .filter(company => company.company_id === payload.company_id)
    .map(stateItem => {
      if (stateItem.contacts?.length) {
        const contacts = stateItem.contacts.map(item => {
          if (item.person_id === payload.person_id) {
            const changedContact = {
              ...item,
              ...payload,
              primary_email: payload.email ? payload.email : '',
              ...getSalesloftEmails(payload.email as string),
              additional_phones: payload.phones || null,
            };

            return changedContact;
          }

          return item;
        });

        // eslint-disable-next-line no-param-reassign
        stateItem.contacts = contacts;
      } else {
        // eslint-disable-next-line no-param-reassign
        stateItem.contacts = [
          {
            ...payload,
            additional_phones: payload.phones || null,
          },
        ];
      }

      return stateItem;
    });

const updateContactsState = (
  state: SelectedCompaniesState,
  payload: Partial<CompanyUserContact>,
  key: SelectedCompaniesGrids
) => {
  state[key]
    .filter(item => item.company_id === payload.company_id)
    .map(item => {
      if (item.contacts?.length) {
        if (payload.primary_contact) {
          const primaryContactIndex = item.contacts.findIndex(el => el.primary_contact === true);

          if (primaryContactIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            item.contacts[primaryContactIndex].primary_contact = false;
          }
        }

        item.contacts.push({
          ...payload,
          ...getSalesloftEmails(payload.email as string),
          primary_contact: !!payload.primary_contact,
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        item.contacts = [{ ...payload, primary_contact: !!payload.primary_contact }];
      }

      return item;
    });
};

const updateCompanyState = (
  company: SelectedCompanies,
  fieldName: string,
  companyId: number,
  value: any
) => {
  if (company.company_id && company.company_id === companyId) {
    if (fieldName !== 'product_category') {
      return {
        ...company,
        [fieldName]: value.value[fieldName !== 'addon' ? 'label' : 'optionalValue'],
      };
    } else {
      const categories = value.value.split(',').map((category: any) => ({
        category,
        is_valid: true,
      }));

      return {
        ...company,
        [fieldName]: categories,
      };
    }
  }

  return company;
};

const initialState: SelectedCompaniesState = {
  advancedSearch: [],
  watchlists: [],
  myCompanies: [],
  companiesOutReach: [],
  addonManagement: [],
  updatedCompaniesIds: [],
  checklistResearchInsights: [],
  changedValue: '',
  editFieldKey: '',
};

const selectCompaniesReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<SelectedCompaniesPayload>
> = (draftState, action) => {
  const { gridName, selectedCompanies } = action.payload;
  const sortedSelectedCompanies = [...selectedCompanies];
  draftState[gridName] = sortedSelectedCompanies;
};

const bulkUpdateDataSuccessReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<BulkUpdateDataSuccess>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateState(draftState, payload, 'advancedSearch', payload.editFieldKey);
  }

  if (draftState.watchlists.length !== 0) {
    updateState(draftState, payload, 'watchlists', payload.editFieldKey);
  }

  if (draftState.myCompanies.length !== 0) {
    updateState(draftState, payload, 'myCompanies', payload.editFieldKey);
  }

  if (draftState.companiesOutReach.length !== 0) {
    updateState(draftState, payload, 'companiesOutReach', payload.editFieldKey);
  }
};

const bulkUpdateIndividualStageReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<EditCompanyStageActionPayload>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateIndividualStageState(draftState, payload, 'advancedSearch', 'stage');
  }

  if (draftState.watchlists.length !== 0) {
    updateIndividualStageState(draftState, payload, 'watchlists', 'stage');
  }

  if (draftState.myCompanies.length !== 0) {
    updateIndividualStageState(draftState, payload, 'myCompanies', 'stage');
  }
};

const bulkUpdateIndividualPipelineRankReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<EditCompanyPipelineRank>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateIndividualPipelineRankState(draftState, payload, 'advancedSearch', 'pipeline_rank');
  }

  if (draftState.watchlists.length !== 0) {
    updateIndividualPipelineRankState(draftState, payload, 'watchlists', 'pipeline_rank');
  }

  if (draftState.myCompanies.length !== 0) {
    updateIndividualPipelineRankState(draftState, payload, 'myCompanies', 'pipeline_rank');
  }
};

const bulkUpdateIndividualCompanyOwnerSuccessReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<EditCompanyOwnerSuccess>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateIndividualCompanyOwnerState(draftState, payload, 'advancedSearch', 'company_owner');
  }

  if (draftState.watchlists.length !== 0) {
    updateIndividualCompanyOwnerState(draftState, payload, 'watchlists', 'company_owner');
  }

  if (draftState.myCompanies.length !== 0) {
    updateIndividualCompanyOwnerState(draftState, payload, 'myCompanies', 'company_owner');
  }
};

const bulkUpdateIndividualLeadSourceReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<EditLeadSource>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateIndividualLeadSourceState(draftState, payload, 'advancedSearch', 'lead_source');
  }

  if (draftState.watchlists.length !== 0) {
    updateIndividualLeadSourceState(draftState, payload, 'watchlists', 'lead_source');
  }

  if (draftState.myCompanies.length !== 0) {
    updateIndividualLeadSourceState(draftState, payload, 'myCompanies', 'lead_source');
  }
};

const bulkUpdateIndividualSeniorAdvisorReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<EditSeniorAdvisor>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateIndividualSeniorAdvisorState(
      draftState,
      payload,
      'advancedSearch',
      'senior_advisor_thesis'
    );
  }

  if (draftState.watchlists.length !== 0) {
    updateIndividualSeniorAdvisorState(draftState, payload, 'watchlists', 'senior_advisor_thesis');
  }

  if (draftState.myCompanies.length !== 0) {
    updateIndividualSeniorAdvisorState(draftState, payload, 'myCompanies', 'senior_advisor_thesis');
  }
};

const bulkUpdateIndividualFundReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<EditCompanyFund>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateIndividualFundState(draftState, payload, 'advancedSearch', 'fund');
  }

  if (draftState.watchlists.length !== 0) {
    updateIndividualFundState(draftState, payload, 'watchlists', 'fund');
  }

  if (draftState.myCompanies.length !== 0) {
    updateIndividualFundState(draftState, payload, 'myCompanies', 'fund');
  }
};

const fetchBulkEditDataSuccessReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<FetchBulkEditDataSuccess>
> = (draftState, action) => {
  const { gridName, dataFields, field } = action.payload;
  draftState[gridName].forEach((company, index) => {
    const currentItem = draftState[gridName][index];
    const { company_id: id } = company;
    draftState[gridName][index] = { ...currentItem, [field]: dataFields[id][field] };
  });
};

const fetchCompaniesContactsSuccessReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<{
    contacts: SalesloftCompanyContacts[];
    selectedCompanies: SelectedCompanies[];
    gridName: SelectedCompaniesGrids;
  }>
> = (draftState, action) => {
  const { contacts, gridName } = action.payload;
  contacts.forEach(contact => {
    const { company_id: id, contacts } = contact;
    const index = draftState[gridName].findIndex(company => company.company_id === id);

    if (index !== -1) {
      draftState[gridName][index].contacts = contacts.map(contact => ({
        ...contact,
        company_id: draftState[gridName][index].company_id,
        email: contact.primary_email,
        emails: getEmailsFromString(contact.primary_email),
        ...getSalesloftEmails(contact.primary_email as string | null),
      }));
    }
  });
};

const addContactSuccessReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateContactsState(draftState, payload, 'advancedSearch');
  }

  if (draftState.watchlists.length !== 0) {
    updateContactsState(draftState, payload, 'watchlists');
  }

  if (draftState.myCompanies.length !== 0) {
    updateContactsState(draftState, payload, 'myCompanies');
  }
};

const updateContactSuccessReducer: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, { payload }) => {
  if (draftState.advancedSearch.length !== 0) {
    updateEtContactsState(draftState, payload, 'advancedSearch');
  }

  if (draftState.watchlists.length !== 0) {
    updateEtContactsState(draftState, payload, 'watchlists');
  }

  if (draftState.myCompanies.length !== 0) {
    updateEtContactsState(draftState, payload, 'myCompanies');
  }
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<
  SelectedCompaniesState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const persistedFilter = action.payload.settings.session_settings
    ? action.payload.settings.session_settings.et_form_auto_save
    : undefined;

  if (persistedFilter !== null && persistedFilter !== undefined) {
    if (has(persistedFilter, 'Company Name')) {
      return;
    }

    const gridName = persistedFilter.grid as 'advancedSearch' | 'watchlists' | 'myCompanies';
    draftState[gridName] = persistedFilter.companiesData as SelectedCompanies[];
  }
};

const updateFieldsSuccessReducer: CaseReducer<SelectedCompaniesState> = (draftState, action) => {
  const fieldName = action.payload.value.fieldKey;
  const companyId = action.payload.companyId;
  const value = action.payload.value;
  let updatedState = { ...draftState };

  if (updatedState.advancedSearch.length !== 0) {
    updatedState.advancedSearch = updatedState.advancedSearch.map(company =>
      updateCompanyState(company, fieldName, companyId, value)
    );
  }

  if (updatedState.watchlists.length !== 0) {
    updatedState.watchlists = updatedState.watchlists.map(company =>
      updateCompanyState(company, fieldName, companyId, value)
    );
  }

  if (updatedState.myCompanies.length !== 0) {
    updatedState.myCompanies = updatedState.myCompanies.map(company =>
      updateCompanyState(company, fieldName, companyId, value)
    );
  }

  if (updatedState.companiesOutReach.length !== 0) {
    updatedState.companiesOutReach = updatedState.companiesOutReach.map(company =>
      updateCompanyState(company, fieldName, companyId, value)
    );
  }

  if (updatedState.addonManagement.length !== 0) {
    updatedState.addonManagement = updatedState.addonManagement.map(company =>
      updateCompanyState(company, fieldName, companyId, value)
    );
  }

  return updatedState;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.selectCompanyIds, selectCompaniesReducer)
    .addCase(actions.fetchBulkEditDataSuccess, fetchBulkEditDataSuccessReducer)
    .addCase(actions.bulkUpdateDataSuccess, bulkUpdateDataSuccessReducer)
    .addCase(updateIndividualStageActions.addCompanyStage, bulkUpdateIndividualStageReducer)
    .addCase(
      updateIndividualPipelineRankActions.addCompanyPipelineRank,
      bulkUpdateIndividualPipelineRankReducer
    )
    .addCase(
      salesloftBulkActions.fetchCompaniesContactsSuccess,
      fetchCompaniesContactsSuccessReducer
    )
    .addCase(salesloftBulkActions.addContactSuccess, addContactSuccessReducer)
    .addCase(
      updateIndividualCompanyOwnerActions.addCompanyOwnerSuccess,
      bulkUpdateIndividualCompanyOwnerSuccessReducer
    )
    .addCase(leadSourceActions.addLeadSource, bulkUpdateIndividualLeadSourceReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
    .addCase(seniorAdvisorActions.addSeniorAdviser, bulkUpdateIndividualSeniorAdvisorReducer)
    .addCase(fundActions.addFund, bulkUpdateIndividualFundReducer)
    .addCase(bulkEtActions.updateBulkContactSuccess, updateContactSuccessReducer)
    .addCase(updateFieldsSuccess, updateFieldsSuccessReducer)
);

export default reducer;
