import { call, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { CombineSearch } from './interfaces';
// services
import NotificationService from '@optx/services/NotificationService';
import { UserService } from '@optx/services/api';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import * as actions from './actions';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as combinedSearchActions } from '@features/combine-search/state';

function* createCombineSearchSaga(action: PayloadAction<CombineSearch>) {
  try {
    const res: AxiosResponse<CombineSearch> = yield call(
      UserService.createCombineSearch,
      action.payload
    );

    if (res.data) {
      const successMessage = 'Combined search was saved successfully!';
      yield put(savedSearchesActions.fetchSavedSearches());
      NotificationService.success(successMessage);
    } else {
      yield put(combinedSearchActions.combineSearchFail());
      NotificationService.error('Error saving combined search!');
    }
  } catch (error: any) {
    yield put(combinedSearchActions.combineSearchFail());
    const errorMessage = getErrorMessage(error, 'Error saving combined search!');
    NotificationService.error(errorMessage);
  }
}

export default function* combineSearchSaga() {
  yield takeLatest(actions.combineSearch, createCombineSearchSaga);
}
