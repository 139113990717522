import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
// redux
import {
  selectors as fullscreenSelectors,
  actions as fullscreenActions,
} from '@redux/ui/settings/fullscreen';
//constants
import { FULLSCREEN_TITLE } from '@optx/constants/titles';
// components
import Icon from '@optx/components/common/Icon';

const StyledIcon = styled(Icon)`
  width: 16px;
`;

interface GridFullScreenProps {
  hideFullScreenIcon?: boolean;
  pendoAction?: string;
}

const GridFullScreen = ({ hideFullScreenIcon, pendoAction }: GridFullScreenProps) => {
  const dispatch = useDispatch();
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const toggleFullScreen = useCallback(() => {
    if (!isFullscreen && pendoAction) {
      window.pendo.track(pendoAction);
    }

    dispatch(fullscreenActions.toggleFullScreen());
  }, [dispatch, isFullscreen, pendoAction]);

  if (isSafari) {
    return null;
  }

  if (isFullscreen) {
    return (
      <Button
        onClick={toggleFullScreen}
        style={{ marginLeft: '16px' }}
        type="primary"
        icon={<StyledIcon iconName="fullscreen-exit" className="mr-2" />}
      >
        EXIT FULL SCREEN
      </Button>
    );
  }

  return (
    <Tooltip title={FULLSCREEN_TITLE}>
      <Button
        type="text"
        onClick={toggleFullScreen}
        // issue with this icon has path smaller than SVG.
        icon={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <span>
            {!hideFullScreenIcon && (
              <Icon iconName="fullscreen" style={{ fontSize: 28, color: '#AFBDD1' }} />
            )}
          </span>
        }
      />
    </Tooltip>
  );
};

export default React.memo(GridFullScreen);
