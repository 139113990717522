import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import Icon from '@components/common/Icon';
import { styledColors } from '@utils/theme/colors';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface EditableCellProps {
  content?: JSX.Element;
  title?: string;
  closePopup?: boolean;
  setClosePopup?: (closePopup: boolean) => void;
  onVisibilityChange?: () => void;
  placement?: TooltipPlacement;
  onVisibilityShow?: () => void;
  actionPendo?: string;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  overlayClassName?: string;
  destroyOnHide?: boolean;
}

const PenIcon = styled(Icon)<{ $visible?: boolean }>`
  cursor: pointer;
  visibility: ${props => (props.$visible ? 'visible' : 'unset')};

  :hover {
    color: ${styledColors.blue6};
  }
`;

const EditableCell: React.FC<EditableCellProps> = ({
  content,
  title,
  closePopup,
  setClosePopup,
  onVisibilityChange,
  placement = 'top',
  onVisibilityShow,
  actionPendo,
  getPopupContainer,
  destroyOnHide = true,
  overlayClassName,
}) => {
  const [popupVisibility, setPopupVisibility] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    setPopupVisibility(!popupVisibility);

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    document.body.classList.add('popover-visible');
  };

  useEffect(() => {
    if (closePopup) {
      setPopupVisibility(false);

      if (setClosePopup) {
        setClosePopup(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup]);

  const handleVisibleChange = (visible: boolean) => {
    setPopupVisibility(visible);
  };

  useEffect(() => {
    return () => {
      if (popupVisibility) {
        document.body.classList.remove('popover-visible');
        onVisibilityChange && onVisibilityChange();
      } else {
        onVisibilityShow && onVisibilityShow();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupVisibility]);

  return (
    <Popover
      content={content}
      title={title}
      trigger="click"
      visible={popupVisibility}
      onVisibleChange={handleVisibleChange}
      placement={placement}
      destroyTooltipOnHide={destroyOnHide}
      getPopupContainer={getPopupContainer}
      overlayClassName={overlayClassName}
    >
      <div role="presentation" onClick={handleClick}>
        <PenIcon iconName="pen" $visible={popupVisibility} />
      </div>
    </Popover>
  );
};

export default EditableCell;
