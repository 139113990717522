import React, { useEffect, useState } from 'react';
import { Button, Col, Tooltip, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
// models
import { BaseField } from '@optx/models/companyFields';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { FieldUsedFor } from './interface';
//constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';

const { Option } = Select;

interface SingleSelectRoundFieldProps {
  field?: BaseField;
  value?: OptionTypeBase;
  fieldName?: string;
  fieldType?: string;
  options?: OptionTypeBase[];
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const SingleSelectRoundField: React.FC<SingleSelectRoundFieldProps> = ({
  field,
  fieldName,
  fieldType,
  options,
  isChromePlugin,
  value,
  onSave,
  onCancel,
  fieldUsedFor,
}) => {
  const [selectedOption, , setSelectedOption] = useField<OptionTypeBase>(
    field?.id || fieldType || ''
  );
  const [selectedState, setSelectedState] = useState<string | undefined>();
  const [closePopup, setClosePopup] = useState(false);

  const handleChange = (value: string) => {
    setSelectedState(value);

    setSelectedOption.setValue({
      value: value,
      label: (options || field?.data)?.find((option: OptionTypeBase) => option.value === value)
        ?.label,
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  useEffect(() => {
    if (
      typeof field?.value === 'object' &&
      fieldUsedFor !== 'editAllInfo' &&
      !selectedOption.value
    ) {
      setSelectedState(field.value.value);
    } else if (
      selectedOption.value &&
      typeof selectedOption.value === 'object' &&
      fieldUsedFor === 'editAllInfo'
    ) {
      setSelectedState(selectedOption.value.value);
    }

    if (typeof value === 'object') {
      setSelectedState(value.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  useEffect(() => {
    if (selectedOption.value) {
      if (
        typeof selectedOption.value === 'object' &&
        selectedOption.value.value !== selectedState
      ) {
        setSelectedState(selectedOption.value.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption.value]);

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            name="LastRound"
            value={selectedState}
            onChange={handleChange}
            getPopupContainer={trigger => trigger.parentElement!}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = option ? `${option?.children.props.children}` : '';

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {field.data &&
              field.data.length &&
              field.data?.map((option: OptionTypeBase) => (
                <Option key={option.value as string} value={option.value as string}>
                  <span>{option.label}</span>
                </Option>
              ))}
          </Select>
        </FormItem>
      </Col>
    );
  }

  const content = (
    <FormItem name={fieldType ?? ''}>
      <Typography.Text>Edit "{fieldName}" value</Typography.Text>
      <Select
        showSearch
        name={fieldType ?? ''}
        value={selectedState}
        onChange={handleChange}
        getPopupContainer={trigger => trigger.parentElement!}
        filterOption={(input: string, option: OptionTypeBase | undefined) => {
          const fullName = `${option?.children.props.children}`;

          return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {options?.map((option: OptionTypeBase) => (
          <Option key={option.value as string} value={option.value as string}>
            <span>{option.label}</span>
          </Option>
        ))}
      </Select>
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          onClick={() => onSave && onSave(fieldType)}
        >
          Save
        </Button>
      </div>
    </FormItem>
  );

  return (
    <EditPopover setClosePopup={setClosePopup} closePopup={closePopup} content={content}>
      <Tooltip title={value ? value.label : DEFAULT_EMPTY_VALUE}>
        {value ? value.label : DEFAULT_EMPTY_VALUE}
      </Tooltip>
    </EditPopover>
  );
};

export default React.memo(SingleSelectRoundField);
