import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { isEqual, Dictionary } from 'lodash';
// models
import { TouchesCreateForm } from '@models/api/touches';
import {
  EditField,
  FieldOptions,
  UpdateFieldsPayload,
  UpdateFieldsPayloadCustomData,
} from '@optx/features/company/edit-fields/state/interfaces';
import { SelectOption } from '@optx/models/Option';
import { CompanyUserContact } from '@optx/models/api/contacts';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import TOUCH_FORM_INITIAL_STATE, { TOUCH_TIME_FORMAT } from './touchInitialValues';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { ADD_TOUCH_LOCALSTORAGE_KEY_PREFIX } from '@optx/constants/autosave';
// services
import NotificationService from '@services/NotificationService';
// utils
import validationTouch from './validation';
import { normalizeValue } from '@optx/utils/helpers';
import { isDateInFuture, handlePendoAction } from '@optx/utils/utils';
import { deleteSavedValues } from '@optx/storage/localStorage/autoSaveStorage';
// redux
import {
  selectors as modalSelectors,
  actions as modalActions,
} from '@redux/ui/modals/company-touches';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { getFinancialFilterOptions } from '@optx/redux/company/filters/selectors';
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state/index';
// hooks
import { useInjectUI } from '@optx/common/hooks/inject';
import { useInjectEditAllCompany } from '@optx/features/edit-all-info';
import useInitCompanyTouchesDialog from '@optx/common/hooks/init/useInitCompanyTouchesDialog';
import { useInjectLocalStorageAutosave } from '@optx/features/local-storage-autosave';
// components
import TouchPropInfo from './TouchPropInfo';
import TouchModalTitle from './TouchModalTitle';
import TouchInformation from './TouchInformation';
import CompanyDrawerBanner from './CompanyDrawerBanner';
import AdditionalCompanyInfo from './AdditionalCompanyInfo';
import { LocalStorageAutoSave, FormErrorMask } from '@optx/features/local-storage-autosave';
import { Styled } from './CompanyTouches.styled';

const isFieldChanged = (
  normalizedValue: string,
  selectedValue: string | number | null | undefined,
  isCompanyOwner?: boolean
) => {
  const normalizedSelectedValue =
    selectedValue === 'None' || selectedValue === 'Unowned' || selectedValue === DEFAULT_EMPTY_VALUE
      ? null
      : selectedValue;

  if (
    (normalizedValue === 'blank' && normalizedSelectedValue) ||
    (normalizedValue !== 'blank' && !normalizedSelectedValue) ||
    (normalizedValue !== 'blank' &&
      normalizedSelectedValue &&
      normalizedValue.toString() !==
        (isCompanyOwner
          ? normalizedSelectedValue.toString().split(' ')[1]
          : normalizedSelectedValue.toString()))
  ) {
    return true;
  }

  return false;
};

interface CompanyTouchesType {
  isChromeExtension?: boolean;
}

const MINUTES_INTERVAL = 15;

const CompanyTouches: React.FC<CompanyTouchesType> = ({ isChromeExtension }) => {
  // get the company ID from the URL because the one from redux is read as -1 at first
  const COMPANY_ID_FROM_URL = parseInt(window.location.pathname.replace('/profile/', ''));
  const IS_FROM_GRID = !isChromeExtension && isNaN(COMPANY_ID_FROM_URL);

  useInjectUI();
  useInjectLocalStorageAutosave();
  useInitCompanyTouchesDialog(IS_FROM_GRID ? undefined : COMPANY_ID_FROM_URL);
  useInjectEditAllCompany();
  const dispatch = useDispatch();
  const location = useLocation();

  const fullName = useSelector(userInformationSelectors.getFullName);
  const userId = useSelector(userInformationSelectors.getUserId);
  const userEtId = useSelector(userInformationSelectors.getUserEtId);
  const company = useSelector(modalSelectors.getTouchCompany);
  const financialsLoaded = useSelector(modalSelectors.hasFinancialsLoaded);
  const isOpen = useSelector(modalSelectors.isOpen);
  const editTouch = useSelector(modalSelectors.editTouch);
  const touchFields = useSelector(modalSelectors.getTouchFinancials);
  const additionalFieldsError = useSelector(modalSelectors.getAdditionalFieldsError);
  const selectedStageType = useSelector(modalSelectors.getTouchCompanyStage);
  const selectedStageRationale = useSelector(modalSelectors.getTouchCompanyStageRationale);
  const selectedRankType = useSelector(modalSelectors.getTouchCompanyRank);
  const selectedCompanyOwnerType = useSelector(modalSelectors.getTouchCompanyOwner);
  const psgParticipants = useSelector(modalSelectors.getPsgParticipants);
  const hasAllFormDataLoaded = useSelector(modalSelectors.getHasAllFormDataLoaded);
  const subSectorOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.subSectorOptions
  );
  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const companyStageOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.stageOptions
  );
  const companyRankOptions = useSelector(individualEditSelectors.companyIndividualEdit.rankOptions);

  const [shouldUpdateToInitial, setShouldUpdateToInitial] = useState(false);

  const ebitdaOptions = useSelector(getFinancialFilterOptions('ebitda_numeric'));
  const revenueOptions = useSelector(getFinancialFilterOptions('last_rev_update_amount'));
  const arrOptions = useSelector(getFinancialFilterOptions('last_arr_value'));
  const gmOptions = useSelector(getFinancialFilterOptions('last_gross_margin'));

  const useAutosave = isOpen && !editTouch;
  const showFormDataErrorMask = useAutosave && !hasAllFormDataLoaded && !!subSectorOptions.length;

  const toggle = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm();
      setShouldUpdateToInitial(true);
      dispatch(modalActions.clearAdditionalFieldsError());
    }

    return dispatch(
      modalActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
        isFromGrid: IS_FROM_GRID,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company]);

  const handleCreateCompanyTouch = () => {
    if (formRef.current) {
      formRef.current.submitForm();

      if (formRef.current.errors) {
        const errors = Object.keys(formRef.current.errors);
        errors.forEach(error =>
          NotificationService.error(
            error.charAt(0).toUpperCase() +
              error.toString().replaceAll('_', ' ').slice(1) +
              ' is required!'
          )
        );

        // fix issue when company comes with last round as acquisition and acquiring company is empty
        if (
          errors.length === 0 &&
          formRef.current.values.acquiring_company === '' &&
          formRef.current.values.last_round?.label === 'Acquisition'
        ) {
          NotificationService.error('Acquiring Company is required!');
        }
      }
    }
  };

  const handleModalCancel = () => {
    deleteSavedValues(
      ADD_TOUCH_LOCALSTORAGE_KEY_PREFIX,
      IS_FROM_GRID || isChromeExtension ? company.company_id : COMPANY_ID_FROM_URL
    );
    toggle();
  };

  // CompanyInfo
  const companyInfoToSaveRef = useRef<EditField | null>(null);

  // Form
  const formRef = useRef<FormikProps<Partial<TouchesCreateForm>> | null>(null);

  const initiator = useMemo(() => {
    return (
      psgParticipants.find(user => {
        let initiatorId = userEtId || userId;

        if (editTouch !== null) {
          initiatorId = editTouch.initiatorid ? editTouch.initiatorid : editTouch.initiatorid_optx;
        }

        return user.value === initiatorId || user.optx_id === initiatorId;
      }) || null
    );
  }, [userEtId, userId, psgParticipants, editTouch]);

  const selectedContacts = useMemo(() => {
    if (!company?.contacts) {
      return [];
    }

    const validPrimaryContactIds = (company?.contacts as CompanyUserContact[]).reduce(
      (acc: number[], contact) => {
        if (
          contact.primary_contact &&
          (contact.tenure_end_date === 'present' ||
            isDateInFuture(contact.tenure_end_date as string))
        ) {
          acc.push(contact.person_id as number);
        }

        return acc;
      },
      []
    );

    return validPrimaryContactIds;
  }, [company?.contacts]);

  const formInitialValues = useMemo(() => {
    const start = new Date();
    const missingMinutes = MINUTES_INTERVAL - (moment(start).minute() % MINUTES_INTERVAL);
    const startTime = moment(start).add(missingMinutes, 'minutes');
    const endTime = moment(start).add(missingMinutes, 'minutes').add(MINUTES_INTERVAL, 'minutes');
    const formattedDate = moment(new Date()).format(ISO_DATE_FORMAT);

    let initialValues = {
      ...TOUCH_FORM_INITIAL_STATE,
      userId: userId?.toString(),
      initiator: initiator ? initiator.label : fullName,
      initiatorId: initiator ? initiator.value : 0,
      initiatorHasEtId: !!userEtId,
      companyId: company.company_id?.toString() || '',
      touchDate: formattedDate,
      touchTime: [startTime.format(TOUCH_TIME_FORMAT), endTime.format(TOUCH_TIME_FORMAT)],
      scheduleTouchDate: formattedDate,
      externalAttendees: selectedContacts,
    };

    if (editTouch != null) {
      const editTouchFormattedDate = moment(editTouch.startdate).format(ISO_DATE_FORMAT);

      initialValues = {
        ...initialValues,
        subject: editTouch.subject,
        type: editTouch.touchtype,
        touchId: editTouch.touchid,
        attendees: editTouch.psgParticipants ? editTouch.psgParticipants : [],
        externalAttendees: editTouch.externalParticipants
          ? editTouch.externalParticipants?.filter(item => item !== null)
          : [],
        companyId: company.company_id.toString(),
        initiator: initiator ? initiator.label : '',
        initiatorId: initiator ? editTouch.initiatorid || editTouch.initiatorid_optx : 0,
        initiatorHasEtId: !!editTouch.initiatorid,
        touchType: editTouch.iscomplete ? 'complete' : 'schedule',
        touchDate: editTouchFormattedDate,
        scheduleTouchDate: editTouchFormattedDate,
        textarea: editTouch.notes,
        touchTime: [
          moment(editTouch.starttime, 'hh:mm:ss').format(TOUCH_TIME_FORMAT),
          moment(editTouch.endtime, 'hh:mm:ss').format(TOUCH_TIME_FORMAT),
        ],
      };
    }

    return initialValues;
  }, [userId, initiator, fullName, userEtId, company.company_id, selectedContacts, editTouch]);

  const handleInitialValues = useCallback(() => {
    if (isEqual(formRef.current?.values, formInitialValues)) {
      return formRef.current!.values;
    }

    if (!formRef.current?.values || !IS_FROM_GRID || isChromeExtension) {
      return formInitialValues;
    }

    // this logic solves an issue that when opening Add Touch Note for a company from grid actions,
    // formInitialValues runs an extra time causing to overwrite values from autosave.
    const newInitialValues = Object.keys(formRef.current?.values ?? {}).reduce((acc, valueKey) => {
      const formValue = formRef.current?.values[valueKey as keyof TouchesCreateForm];
      const initialValue = formInitialValues[valueKey as keyof TouchesCreateForm];

      const useFormValue =
        formValue !== initialValue &&
        formValue !== '' &&
        formValue !== null &&
        Array.isArray(formValue) &&
        !formValue.length;

      return { ...acc, [valueKey]: useFormValue ? formValue : initialValue };
    }, {} as Partial<TouchesCreateForm>);

    return newInitialValues;
  }, [IS_FROM_GRID, formInitialValues, isChromeExtension]);

  const handleCompanyInfoChange = (newInfo: EditField | null) => {
    companyInfoToSaveRef.current = newInfo;
  };

  const handleSubmit = (values: Partial<TouchesCreateForm>) => {
    let additionalCompanyInfoPayload = companyInfoToSaveRef.current
      ? { ...companyInfoToSaveRef.current }
      : null;

    window.pendo.track(handlePendoAction('AddTouchNote'));

    if (
      additionalCompanyInfoPayload &&
      additionalCompanyInfoPayload.value &&
      Array.isArray(additionalCompanyInfoPayload.fieldName)
    ) {
      const payloadValue = (additionalCompanyInfoPayload.value as UpdateFieldsPayload).value;
      const payloadRationale = (additionalCompanyInfoPayload.value as UpdateFieldsPayload)
        .rationale;

      if (additionalCompanyInfoPayload.fieldName.includes('Stage')) {
        let isStageChanged = false;
        const isStageRationaleChanged =
          payloadRationale !== undefined && payloadRationale !== selectedStageRationale
            ? true
            : false;

        if ((payloadValue as unknown as Dictionary<SelectOption>).Stage) {
          const companyStage = (payloadValue as unknown as Dictionary<SelectOption>).Stage;
          const normalizedCompanyStage = normalizeValue(companyStage, companyStageOptions);
          isStageChanged = isFieldChanged(normalizedCompanyStage, selectedStageType);
        }

        if (!isStageChanged && !isStageRationaleChanged) {
          additionalCompanyInfoPayload.fieldName = additionalCompanyInfoPayload.fieldName.filter(
            item => item !== 'Stage'
          );

          delete (payloadValue as unknown as Dictionary<SelectOption>).Stage;
          delete (additionalCompanyInfoPayload.value as UpdateFieldsPayload).rationale;
        }
      }

      if (additionalCompanyInfoPayload.fieldName.includes('Pipeline Rank')) {
        let isRankChanged = false;

        if ((payloadValue as unknown as Dictionary<SelectOption>)['Pipeline Rank']) {
          const companyRank = (payloadValue as unknown as Dictionary<SelectOption>)[
            'Pipeline Rank'
          ];
          const normalizedCompanyRank = normalizeValue(companyRank, companyRankOptions);
          isRankChanged = isFieldChanged(normalizedCompanyRank, selectedRankType);
        }

        if (!isRankChanged) {
          additionalCompanyInfoPayload.fieldName = additionalCompanyInfoPayload.fieldName.filter(
            item => item !== 'Pipeline Rank'
          );

          delete (payloadValue as unknown as Dictionary<SelectOption>)['Pipeline Rank'];
        }
      }

      if (additionalCompanyInfoPayload.fieldName.includes('Company Owner')) {
        let isCompanyOwnerChanged = false;

        if ((payloadValue as unknown as Dictionary<SelectOption>)['Company Owner']) {
          const companyOwner = (payloadValue as unknown as Dictionary<SelectOption>)[
            'Company Owner'
          ];
          const normalizedCompanyOwner = normalizeValue(companyOwner, companyOwnerOptions);
          isCompanyOwnerChanged = isFieldChanged(
            normalizedCompanyOwner,
            selectedCompanyOwnerType,
            true
          );
        }

        if (!isCompanyOwnerChanged) {
          additionalCompanyInfoPayload.fieldName = additionalCompanyInfoPayload.fieldName.filter(
            item => item !== 'Company Owner'
          );

          delete (payloadValue as unknown as Dictionary<SelectOption>)['Company Owner'];
        }
      }

      if (!additionalCompanyInfoPayload.fieldName.length) {
        additionalCompanyInfoPayload = null;
      } else {
        const normalizedValue = payloadValue as unknown as { [key in FieldOptions]: SelectOption };
        let customData: UpdateFieldsPayloadCustomData = {};

        if (normalizedValue.Stage) {
          const rationale = (additionalCompanyInfoPayload.value as UpdateFieldsPayload).rationale;
          customData.stage = normalizedValue.Stage.value;
          customData.stage_rationale = rationale;
        }

        if (normalizedValue['Pipeline Rank']) {
          customData.rank = normalizedValue['Pipeline Rank'].value;
        }

        if (normalizedValue['Company Owner']) {
          customData.deal_team_leader = normalizedValue['Company Owner'].value;
          customData.deal_team_leader_name = normalizedValue['Company Owner'].label;
        }

        (additionalCompanyInfoPayload.value as UpdateFieldsPayload) = {
          ...(additionalCompanyInfoPayload.value as UpdateFieldsPayload),
          customData,
        };
      }
    }

    if (!additionalFieldsError.error_field) {
      let formValues = { ...values };

      if (!isEqual(formInitialValues, formValues)) {
        if (typeof formValues.attendees === 'string' && formValues.attendees !== '') {
          formValues.attendees = [formValues.attendees];
        } else if (typeof formValues.attendees === 'string') {
          formValues.attendees = [];
        }

        dispatch(
          modalActions.addTouchToCompany({
            touchInfo: formValues,
            additionalCompanyInfo: additionalCompanyInfoPayload,
            location: location.pathname,
          })
        );
      }
    } else if (additionalCompanyInfoPayload !== null) {
      const payload = {
        ...additionalCompanyInfoPayload,
        canBeDuplicatedLoader: false,
      };

      dispatch(editFieldActions.updateFields(payload));
    } else {
      toggle();
    }
  };

  useEffect(() => {
    if (isOpen && formRef.current?.values && !isEqual(formRef.current.values, formInitialValues)) {
      formRef.current.resetForm();
      setShouldUpdateToInitial(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Styled.DrawerTouches
        placement={isChromeExtension ? 'bottom' : 'right'}
        onClose={handleModalCancel}
        visible={isOpen}
        className={isChromeExtension ? 'company-touches touch-extension' : 'company-touches'}
        height={isChromeExtension ? 'calc(100% - 60px)' : '100%'}
        width={isChromeExtension ? '640px' : '750px'}
        title={
          <div>
            <TouchModalTitle
              isSaveDisabled={!!additionalFieldsError.error_text}
              handleCancel={handleModalCancel}
              handleSave={handleCreateCompanyTouch}
            />
          </div>
        }
      >
        <div className="ant-drawer-body-inner">
          {!isChromeExtension && <CompanyDrawerBanner company={company} />}
          <Styled.TouchContainer>
            <Formik
              initialValues={handleInitialValues()}
              enableReinitialize
              onSubmit={handleSubmit}
              validationSchema={validationTouch({
                ebitdaOptions,
                arrOptions,
                gmOptions,
                revenueOptions,
              })}
              validateOnMount={true}
              innerRef={instance => {
                formRef.current = instance;
              }}
              component={formikProps => (
                <Form layout="vertical" style={{ position: 'relative' }}>
                  {useAutosave && (
                    <LocalStorageAutoSave
                      key="add-touch-autosave"
                      storageKeyPrefix={ADD_TOUCH_LOCALSTORAGE_KEY_PREFIX}
                      companyId={company.company_id}
                    />
                  )}
                  <AdditionalCompanyInfo
                    shouldUpdateToInitial={shouldUpdateToInitial}
                    setUpdateToInitial={setShouldUpdateToInitial}
                    setInfoToSave={handleCompanyInfoChange}
                  />
                  <div>
                    <TouchInformation
                      sources={formikProps}
                      isChromeExtension={isChromeExtension}
                      editTouch={editTouch}
                    />
                    {isOpen && financialsLoaded && (
                      <TouchPropInfo
                        touchFields={touchFields}
                        className={isChromeExtension ? 'touch-prop-info--chrome-extension' : ''}
                        touchParentType={
                          formRef.current?.values.touchType === 'complete'
                            ? 'complete'
                            : 'scheduled'
                        }
                      />
                    )}
                  </div>
                </Form>
              )}
            />
          </Styled.TouchContainer>
        </div>
      </Styled.DrawerTouches>
      {showFormDataErrorMask && <FormErrorMask isChromeExtension={!!isChromeExtension} />}
    </>
  );
};

export default React.memo(CompanyTouches);
