import { call, put, takeLatest, select, fork } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import queryString from 'query-string';
// models
import { HistogramGroup, PreselectedFilter } from '@optx/models/filters';
// constants
import AppRoutes from '@constants/routes';
// services
import NotificationService from '@services/NotificationService';
import { CompanyService } from '@services/api';
import { getErrorMessage } from '@utils/api/errors';
// utils
import { normalizeFilter } from '@optx/utils/filters/filterHelpers';
import { getRouteAlias } from '@optx/utils/routes';
// redux
import { saga as routerSagas } from '@redux/router';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions as filterActions, selectors as filtersSelectors } from '@redux/company/filters';
import { getFiltersQuery, handleTouchFilters } from '@redux/company/search/search/sagasReusable';
import { selectors as searchSelectors } from '@redux/company/search/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import * as actions from './actions';
import {
  FetchHistogramsActionPayload,
  FilterChangedPayload,
  ToggleRefreshPayload,
} from './interfaces';

export function* fetchHistogramFiltersSaga(action: PayloadAction<FetchHistogramsActionPayload>) {
  try {
    const histogramsEnabled: boolean = yield select(searchSelectors.histogramsEnabled);
    const currentRoute = getRouteAlias();

    if (!histogramsEnabled || currentRoute === 'companyProfile') {
      return;
    }

    let filter: Dictionary<PreselectedFilter> = action.payload.filter || {};
    const defaultHistogramKeys: Array<string> = yield select(
      searchSelectors.selectAllFiltersWithHistograms
    );

    if (filter.ddate !== undefined) {
      delete filter.ddate;
    }

    filter = yield call(handleTouchFilters, filter);

    const filterQueryPayload: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      filter
    );
    const defaultHistograms: HistogramGroup = yield select(searchSelectors.selectDefaultHistograms);
    const filterQueryString = queryString.stringify(filterQueryPayload, { arrayFormat: 'comma' });
    const isDefaultHistogram = !filterQueryString;

    if (isDefaultHistogram && defaultHistograms) {
      yield put(actions.resetToDefault());
    } else {
      // @ts-ignore
      const res = yield call(CompanyService.getHistograms, {
        filterQuery: filterQueryPayload,
        histogramList: defaultHistogramKeys,
      });

      yield put(
        actions.fetchHistogramFiltersSuccess({
          data: res.data,
          companyCount: res.data.company_count,
          isDefault: isDefaultHistogram,
        })
      );
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Error fetching histograms!');
    yield put(actions.fetchHistogramFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

/**
 * Initialize histograms after filters are fetched on home page.
 */
function* initializeHistogramsSaga() {
  const histogramsEnabled: boolean = yield select(userInformationSelectors.filtersRefresh);
  const isAnalyst: boolean = yield select(userInformationSelectors.getIsAnalyst);
  const matcher: boolean = yield call(routerSagas.matchesLocation, [
    isAnalyst ? AppRoutes.advancedSearch : AppRoutes.home,
  ]);

  if (matcher && histogramsEnabled) {
    // when retrieving filters also make call to update histograms.
    // this is necessary for when the app is refreshed and histograms
    // have to reflect the state of the filters
    const filter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getFilter('advancedSearch')
    );
    const histogramFilters: Array<string> = yield select(searchSelectors.selectChangedFilters);
    yield put(actions.fetchHistogramFilters(filter, histogramFilters));
  }
}

/**
 * Initialize histograms after comming from other pages with filters already loaded but no histograms
 * (User refreshed the other page and accessed home page afterwards).
 * @param action location change action.
 */
function* locationChangeSaga(action: PayloadAction<LocationChangePayload>) {
  const isAnalyst: boolean = yield select(userInformationSelectors.getIsAnalyst);
  const matcher: boolean = yield call(routerSagas.matchesLocation, [
    isAnalyst ? AppRoutes.advancedSearch : AppRoutes.home,
  ]);

  if (matcher) {
    const filtersLoaded: boolean = yield select(filtersSelectors.filtersLoaded);
    const histogramsLoaded: boolean = yield select(searchSelectors.histogramsInitialized);

    if (filtersLoaded && !histogramsLoaded) {
      yield fork(initializeHistogramsSaga);
    }
  }
}

function* filterChangeSaga(action: PayloadAction<FilterChangedPayload>) {
  let { values } = action.payload;
  values = normalizeFilter(values);
  yield put(actions.fetchHistogramFilters(values));
}

function* toggleRefreshSaga(action: PayloadAction<ToggleRefreshPayload>) {
  let { enabled, values } = action.payload;
  values = normalizeFilter(values);

  if (enabled) {
    yield put(actions.fetchHistogramFilters(values));
  }

  yield put(userInformationActions.updateUserSettings({ filters_refresh: enabled }));
}

export default function* companyHistogramsSaga() {
  yield takeLatest(actions.fetchHistogramFilters, fetchHistogramFiltersSaga);
  yield takeLatest(filterActions.fetchCompanyFiltersSuccess, initializeHistogramsSaga);
  yield takeLatest(
    filterActions.fetchCompanyFiltersForNotificationsSuccess,
    initializeHistogramsSaga
  );
  yield takeLatest(LOCATION_CHANGE, locationChangeSaga);
  yield takeLatest(actions.filterChanged, filterChangeSaga);
  yield takeLatest(actions.toggleRefresh, toggleRefreshSaga);
}
