import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useSize } from '@umijs/hooks';
import { useVT } from 'virtualizedtableforantd4';
// models
import { SortByRule, SortRule } from '@optx/models/table/sorting';
import Company from '@optx/models/Company';
import { TableChangeCallback } from '@optx/models/table/antd-table';
// constants
import routes from '@constants/routes';
import { DEFAULT_SORT_ANALYST, DEFAULT_SORT_COMPANIES } from '../../constants/table';
// utils
import { mapSorting } from '@utils/table/sorting';
import scrollTableOnSorting from '@optx/utils/scrollTable';
// hooks
import useNonVirtualizedTable from '@optx/common/hooks/useNonVirtualizedTable';
import useActiveTableRow from '@optx/common/hooks/dom/useActiveTableRow';
import { useVirtualScrollPosition } from '@optx/features/scroll-history';
import useMultiSortNumbers from '@optx/common/hooks/useMultiSortNumbers';
// context
import { VirtualTableScrollYContext } from '@optx/features/scroll-history';
// redux
import { selectors, actions, getColumns } from '@features/checklist-research-insights/state';
import { getSessionSettings } from '@optx/redux/user/information/selectors';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
// components
import Table from '@shared/view/organims/Table';
import { GridPagination } from '@optx/features/grid/pagination/components';

const rowKey: keyof Company = 'company_id';
const paginationId = 'checklist-research-insights-pagination';

const SearchGrid: React.FC = () => {
  const dispatch = useDispatch();

  const onRow = useActiveTableRow<Company>('company_id', routes.reports.checklistResearchInsights);
  const virtualizedActive = useNonVirtualizedTable();
  const { onScroll, initialScrollPosition } = useVirtualScrollPosition(
    routes.reports.checklistResearchInsights
  );
  const [windowSize] = useSize(window.document.documentElement);
  // @ts-ignore

  const ctx = useContext(VirtualTableScrollYContext);
  const vtImperativeScroll = useRef<any>();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [currentScroll, setCurrentScroll] = useState(0);

  const isLoading = useSelector(selectors.search.getLoading);
  const sessionSettings = useSelector(getSessionSettings);
  const data = useSelector(selectors.search.getData);
  const defaultTableView = useSelector(selectors.ui.defaultTableView);
  const columns = useSelector(getColumns);
  const sorting = useSelector(selectors.sort.sortBy);
  const isMultiSort = useSelector(selectors.sort.multiSort);
  const totalItemsCount = useSelector(selectors.search.getCount);
  const { pageNumber, pageSize } = useSelector(
    paginationSelectors.getPagination('checklistResearchInsights')
  );
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);

  const [vt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll, initialScrollPosition]
  );

  useMultiSortNumbers(tableWrapperRef, sorting, isMultiSort, isLoading);

  const handleChange: TableChangeCallback<any> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const parsedSort =
          sessionSettings?.checklist_insights_companies_filters &&
          queryString.parse(sessionSettings?.checklist_insights_companies_filters, {
            arrayFormat: 'comma',
          });

        const sort = mapSorting(
          sorter,
          sorting,
          parsedSort && (parsedSort?.query as string),
          defaultTableView === 'analyst' ? DEFAULT_SORT_ANALYST : DEFAULT_SORT_COMPANIES,
          isMultiSort
        );
        dispatch(actions.sort.changeSort(sort as SortRule<any>));

        // scroll table to the left when sorting, issue for firefox
        scrollTableOnSorting(tableWrapperRef);

        const { sortBy: sortByVal } = mapSorting(sorter, sorting);
        dispatch(actions.sort.sort(sortByVal as Array<SortByRule<any>>));
        break;
      }

      default:
        break;
    }
  };

  const contentRef = useRef<HTMLDivElement | null>(
    tableWrapperRef.current?.querySelector('.ant-table-body') || null
  );

  if (!contentRef.current && tableWrapperRef.current) {
    contentRef.current = tableWrapperRef.current.querySelector('.ant-table-body') || null;
  }

  const handlePageChange = useCallback(
    (pageNumber: number, perPage: number = pageSize) =>
      dispatch(
        paginationActions.changePagination({
          gridKey: 'checklistResearchInsights',
          data: { pageNumber, pageSize: perPage },
        })
      ),
    [dispatch, pageSize]
  );

  const getNextScroll = useCallback(() => {
    let tableHeaderHeight = 0;

    if (tableWrapperRef.current) {
      tableHeaderHeight =
        tableWrapperRef.current.querySelector('.ant-table-header')?.getBoundingClientRect()
          .height || 0;
    }

    const virtualScrollSubstractElements = document.querySelectorAll(
      '[data-virtual-scroll-substract="my-companies-grid"]'
    );

    let substractHeight = 0;

    virtualScrollSubstractElements.forEach(element => {
      const styles = window.getComputedStyle(element, null);
      const margin = parseInt(styles.marginBottom);

      substractHeight += element.getBoundingClientRect().height + margin;
    });

    // Add in styles bottom padding the same size as pagination.
    const nextScroll =
      windowSize.height !== undefined ? windowSize.height - tableHeaderHeight - substractHeight : 0;

    return nextScroll;
  }, [windowSize.height]);

  /**
   * Update scroll each time the size of the wrapper changes or window size changes.
   * This can happen when let's say a column is added/removed and increases/decreases the height of table header.
   * Scroll doesn't include table header size.
   */
  useEffect(() => {
    setTimeout(() => {
      const nextScroll = getNextScroll();
      // Leave a 5px space at the top when computing exact height.
      const offset = 200;
      setCurrentScroll(nextScroll - offset);
    }, 0);
  }, [ctx.height, getNextScroll]);

  useEffect(() => {
    setTimeout(() => {
      vtImperativeScroll?.current?.scrollTo(initialScrollPosition);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <div className="checklist-research-insights-grid-wrapper" ref={tableWrapperRef}>
        <Table
          columns={columns}
          dataSource={data}
          className="legacy-table"
          rowKey={rowKey}
          onRow={onRow}
          onChange={handleChange}
          bordered
          showSorterTooltip={false}
          // virtualization
          scroll={{ y: currentScroll, x: true }} // It's important for using VT!!! DO NOT FORGET!!!
          components={virtualizedActive ? vt : undefined}
        />
      </div>
      {defaultTableView === 'company' && (
        <GridPagination
          onPageChange={handlePageChange}
          id={paginationId}
          totalItemsCount={totalItemsCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          isFullscreen={isFullscreen}
        />
      )}
    </div>
  );
};

export default React.memo(SearchGrid);
