import React, { useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import { Row } from 'antd';
import { Formik, FormikValues, FormikProps } from 'formik';
import { Form } from 'formik-antd';
// models
import { RangeFilter, PreselectedFilter } from '@optx/models/filters';
import { FormCheckableRangeOption } from '@optx/models/Option';
// utils
import { getQuickFiltersList, QuickFiltersListItemProps } from '@utils/filters/quickFilters';
// redux
import { actions as histogramFiltersActions } from '@features/histograms/histograms-advanced-search';
import { selectors as userSelectors } from '@redux/user/information';
import { selectors as companyFiltersSelectors } from '@redux/company/filters';
import { selectors as companySearchSelectors } from '@redux/company/search/search';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
// components
import FilterRange from '../../feature/company-filters/Filter/FilterRange';
import { FiltersContext, IFiltersContext } from '../../feature/company-filters/FiltersContext';

const QuickFilters: React.FC = () => {
  const dispatch = useDispatch();

  const histograms = useSelector(companySearchSelectors.selectHistograms);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const isIsraelScore = defaultOptxScore === 'il';
  const filter = useSelector(filterSelectors.getFilter('advancedSearch'));
  const filterSources = useSelector(companyFiltersSelectors.getFiltersMap);
  const keySearch = useSelector(searchSelectors.getSearchKey('advancedSearch'));
  const formRef = useRef<FormikProps<FormikValues> | null>(null);
  // call /search query
  const onSearch = useCallback(
    (searchKey: string, filter: Dictionary<PreselectedFilter>) =>
      dispatch(
        searchActions.searchCompanies({
          gridKey: 'advancedSearch',
          data: { searchKey, filter, shouldResetPageNumber: true },
        })
      ),
    [dispatch]
  );

  // search
  const handleSearch = (instance?: FormikProps<FormikValues>) => {
    const submittedValues = instance ? instance.values : formRef.current!.values;

    onSearch(keySearch, submittedValues);
  };

  // form submit
  const handleSubmit = () => {
    handleSearch();
  };

  const onFilterFieldChange = (fieldName: string) => {
    dispatch(histogramFiltersActions.filterChanged(fieldName, formRef.current?.values));
    handleSearch();
  };

  const filtersContext: IFiltersContext = {
    histograms,
    onManualFilterChange: onFilterFieldChange,
  };

  const quickFiltersList = useMemo((): QuickFiltersListItemProps[] | null => {
    if (Object.keys(filterSources).length) {
      return getQuickFiltersList(filterSources, undefined, undefined, isIsraelScore);
    }

    return null;
  }, [filterSources, isIsraelScore]);

  if (!quickFiltersList) {
    return null;
  }

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <FiltersContext.Provider value={filtersContext}>
      <div className="quick-filters-group">
        <Formik
          initialValues={filter}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formRef}
          validateOnChange
          validateOnBlur
        >
          <Form onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
            <Row>
              {quickFiltersList.map((item, index) => (
                <FilterRange
                  key={index}
                  filter={item as RangeFilter<Array<FormCheckableRangeOption>>}
                />
              ))}
            </Row>
          </Form>
        </Formik>
      </div>
    </FiltersContext.Provider>
  );
};

export default React.memo(QuickFilters);
