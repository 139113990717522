import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
// models
import { SourceScrubList } from '@optx/models/SourceScrubList';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { SortRule } from '@optx/models/table/sorting';
// constants
import { LISTS_ROUTES as routes } from '@constants/routes';
import { FindNewCompaniesSourceScrubLists } from '@constants/pendoActions';
// utils
import { mapSorting } from '@utils/table/sorting';
// redux
import { selectors as SourceScrubListsSelectors, actions } from '@redux/user/source-scrub-lists';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
// hooks
import { useScrollPosition } from '@features/scroll-history';

const SourceScrubLists: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(SourceScrubListsSelectors.getSourceScrubLists);
  const sorting = useSelector(SourceScrubListsSelectors.getSort);
  const query = useSelector(SourceScrubListsSelectors.getQuery);
  const columns = useSelector(SourceScrubListsSelectors.getGridColumns);
  const pageSize = useSelector(listsAndSearchesSelectors.pageSize);
  const pageNumber = useSelector(SourceScrubListsSelectors.getPageNumber);
  const tableRef = useRef<HTMLDivElement>(null);

  const onChange: TableChangeCallback<SourceScrubList> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const sort = mapSorting(sorter, sorting);

        if (sort.sortBy) {
          window.pendo.track(
            FindNewCompaniesSourceScrubLists[
              sort.sortBy[0].id as keyof typeof FindNewCompaniesSourceScrubLists
            ]
          );
        }

        dispatch(actions.sortSourceScrub(sort as SortRule<any>));
        break;
      }

      default:
        break;
    }
  };

  useScrollPosition(routes.sourceScrubLists, tableRef, pageNumber, query, pageSize);

  return (
    <div ref={tableRef}>
      <Table
        columns={columns}
        className="search-results source-scrub-grid legacy-table"
        dataSource={data}
        onChange={onChange}
        rowKey={'id'}
        showSorterTooltip={false}
        pagination={false}
        tableLayout="fixed"
        bordered
      />
    </div>
  );
};

export default React.memo(SourceScrubLists);
