import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary, some } from 'lodash';
import { PopoverProps } from 'antd/lib/popover';
// models
import { CompanyWatchList } from '@optx/models/WatchList';
import { LoadingIcon } from '@optx/shared/view/molecules/loader';
import { WatchListAction } from './interfaces';
// utils
import NotificationService from '../../../services/NotificationService';
import { handlePendoAction } from '@optx/utils/utils';
// redux
import {
  selectors as favoriteListsSelectors,
  actions as favoriteListsActions,
} from '../../../redux/favorite-lists/lists-popup';
import { selectors as companyListsSelectors } from '../../../redux/favorite-lists/company-lists';
// components
import PopoverContent from './PopoverContent';
import Styles from './AddCompanyToListPopover.styles';
import SortSelectLists from './SortSelectLists';

const message = 'There is already a list with this name, please choose a different title !';
interface AddCompanyToListPopoverProps extends PopoverProps {
  companyId: number;
  isOpen: boolean;
  toggle: () => void;
  /**
   * close popover automatically after saving company to list.
   */
  autoClose?: boolean;
  isExtension?: boolean;
}

const AddCompanyToListPopover: React.FC<AddCompanyToListPopoverProps> = ({
  companyId,
  isOpen,
  toggle,
  autoClose,
  isExtension = false,
}) => {
  // redux
  const companyWatchLists: Dictionary<CompanyWatchList> = useSelector(
    favoriteListsSelectors.getAllWatchlists
  );

  const loading: boolean = useSelector(companyListsSelectors.isLoading);
  const favoriteListsLoading: boolean = useSelector(favoriteListsSelectors.isLoading);

  const dispatch = useDispatch();
  const addCompanyToList = useCallback(
    (
      selectedLists: { list_title: string; list_id: number | null }[],
      companyId: number,
      errorOnConflict: boolean
    ) => {
      dispatch(
        favoriteListsActions.addCompanyToListPopup({
          selectedLists,
          company_id: companyId,
          error_on_conflict: errorOnConflict,
        })
      );
    },
    [dispatch]
  );
  const createNewList = useCallback(
    (title: string, companyId: number) =>
      dispatch(favoriteListsActions.createFavoriteListPopup({ title, company_id: companyId })),
    [dispatch]
  );

  // local state
  const [title, setTitle] = useState('');
  const [action, setAction] = useState<WatchListAction>('Add to');
  const [selectedOptions, setSelectedOptions] = useState<Dictionary<boolean>>({});
  const selectedOptionsRef = useRef<Dictionary<boolean>>({});
  const [searchValue, setSearchValue] = useState<string>('');

  const handleToggle = useCallback(
    (manualClose?: boolean) => {
      setTitle('');
      setAction('Add to');
      setSelectedOptions({});
      setSearchValue('');

      if (!manualClose || autoClose) {
        toggle();
      }
    },
    [toggle, autoClose]
  );

  const showCreateList = useCallback(() => {
    setAction('Create');
    setTitle('');
  }, []);

  const handleSelectChange = (
    listId?: string[],
    item?: CompanyWatchList,
    isEmpty = false,
    companyActiveWatchlist?: Dictionary<boolean>
  ) => {
    if (isEmpty) {
      setSelectedOptions({});
      setTitle('');

      return;
    }

    setTitle((item as CompanyWatchList).title);

    const arraySelectedOptions = Object.keys(selectedOptionsRef.current);

    const deletedItem = arraySelectedOptions.find(list => !listId?.includes(list));

    let activeWatchlistArr = companyActiveWatchlist ? Object.keys(companyActiveWatchlist) : [];

    if (listId?.length) {
      listId.forEach((list, index) => {
        if (!activeWatchlistArr.includes(list)) {
          selectedOptionsRef.current[list] = true;
        }
      });
    }

    if (deletedItem) {
      delete selectedOptionsRef.current[deletedItem];
      setSelectedOptions(selectedOptionsRef.current);
    }

    setSelectedOptions(selectedOptionsRef.current);
  };

  const handleCreateNewList = () => {
    // Check if title is already taken.
    if (some(companyWatchLists, ['title', title])) {
      NotificationService.warn(message);
    } else if (title.trim().length === 0) {
      setTitle('');
      NotificationService.warn('Enter valid name');
    } else {
      createNewList(title.trim(), companyId);
      setSelectedOptions({});
      setAction('Add to');
    }
  };

  useEffect(() => {
    selectedOptionsRef.current = {};
  }, [isOpen]);

  const handleAction = () => {
    if (action === 'Add to') {
      const selectedLists = Object.keys(selectedOptions).map(listId => ({
        list_title: companyWatchLists[listId].title,
        list_id: Number(listId),
      }));
      addCompanyToList(selectedLists, companyId, true);
      window.pendo.track(handlePendoAction('CreateOrAddToWatchlist'));
      handleToggle(true);
    } else {
      handleCreateNewList();
    }
  };

  const handleTitleChange = (title: string) => {
    setTitle(title);
    setSearchValue('');
  };

  const onCancel = () => {
    setSelectedOptions({});
    setSearchValue('');

    if (action === 'Add to') {
      handleToggle(true);
    }

    if (action === 'Create') {
      setTitle('');
      setAction('Add to');
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleAction();
    }
  };

  // Disable action button when there are no items selected.
  const disabled = action === 'Add to' ? Object.keys(selectedOptions).length === 0 : !title;

  return (
    <Styles.Modal
      title={[<span>Select Lists</span>, <SortSelectLists />]}
      visible={isOpen}
      okText={`+ ${action} List`}
      okButtonProps={{ disabled }}
      onOk={handleAction}
      onCancel={onCancel}
      className="dropdownplus"
      isExtension={isExtension}
    >
      {loading || favoriteListsLoading ? (
        <LoadingIcon />
      ) : (
        <PopoverContent
          companyId={companyId}
          action={action}
          handleSelectChange={handleSelectChange}
          companyWatchLists={companyWatchLists}
          handleTitleChange={handleTitleChange}
          selectedOptions={selectedOptions}
          isOpen={isOpen}
          title={title}
          showCreateList={showCreateList}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleDropdownKeyEvent={handleDropdownKeyEvent}
        />
      )}
    </Styles.Modal>
  );
};

AddCompanyToListPopover.defaultProps = {
  isOpen: false,
  toggle: () => {},
  autoClose: true,
};

export default AddCompanyToListPopover;
