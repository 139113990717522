import { createAction } from '@reduxjs/toolkit';
import Company from '@models/Company';
import { UrlDataResponse } from './interfaces';
import * as types from './types';
import { AddedCompany } from '../models';

export const checkSimilarCompanies = createAction<string>(types.checkSimilarCompanies.REQUEST);
export const checkSimilarCompaniesSuccess = createAction<Company[]>(
  types.checkSimilarCompanies.SUCCESS
);
export const checkSimilarCompaniesFail = createAction(types.checkSimilarCompanies.FAILURE);

export const checkUrl = createAction<string>(types.checkUrl.REQUEST);
export const checkUrlSuccess = createAction<UrlDataResponse>(types.checkUrl.SUCCESS);
export const checkUrlFail = createAction(types.checkUrl.FAILURE);

export const resetUrlData = createAction(types.resetUrlData);

export const addCompany = createAction<any>(types.addCompany.REQUEST);
export const addCompanySuccess = createAction<AddedCompany>(types.addCompany.SUCCESS);
export const addCompanyFail = createAction(types.addCompany.FAILURE);
