import React, { useCallback } from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
// models
import { CompanyUserContact } from '@models/api/contacts';
import { Cell } from '@optx/models/table/Cell';
// constants
import { CompanyPageRelationshipManagement, OPTXChromePlugin } from '@optx/constants/pendoActions';
// utils
import { getEmailsFromString } from '@utils/equityTouch';
import { handleMenuVisibleChange } from '@utils/menu';
// redux
import { actions } from '@redux/company/primary-contacts';

const TableCellActions: React.FC<Cell<CompanyUserContact>> = ({ value, record, index }) => {
  const dispatch = useDispatch();

  const editingContact = useCallback(() => {
    dispatch(
      actions.editingPrimaryContact({
        ...record,
        emails: getEmailsFromString(record.email),
      })
    );
  }, [dispatch, record]);

  const handleClick = () => {
    const chromeExtensionExists = document.getElementsByClassName('extension-tabs').length;

    if (chromeExtensionExists) {
      window.pendo.track(OPTXChromePlugin.companyPage.contacts_threeDotsAction_editContact);
    } else {
      window.pendo.track(CompanyPageRelationshipManagement.primaryContacts_editContact);
    }

    document.body.classList.remove('popover-visible');

    editingContact();
  };

  const menu = (
    <Menu className="dropdown-add">
      <Menu.Item key="0" onClick={handleClick}>
        Edit Contact
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="dropdown-block dropdown-tags empty-popover-wrapper">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        onVisibleChange={handleMenuVisibleChange}
      >
        <EllipsisOutlined
          onClick={() => {
            window.pendo.track(OPTXChromePlugin.companyPage.contacts_threeDotsAction);
          }}
        />
      </Dropdown>
    </div>
  );
};

export default TableCellActions;
