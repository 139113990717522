import { Row, Typography, Col, Radio } from 'antd';
// models
import { FormRationale } from '@optx/redux/company/filters/interfaces';
// components
import CreateOtherRaionale from '@optx/features/company/edit-fields/components/CreateOtherRationale';
import { Styled } from './RationaleSection.styled';

export interface RationaleSectionProps {
  rationaleOptions: FormRationale[];
  rationaleValue: string | null;
  handleRationaleChange: (value: string) => void;
  showIsOtherOrTag?: (key?: string) => boolean;
  handleOtherChange?: (option: string | null) => void;
  currRationaleValue?: string | null;
  isRationaleRequired?: boolean;
  isHeightOverflow?: boolean;
  isFromTouch?: boolean;
}

const RationaleSection = ({
  rationaleOptions,
  rationaleValue,
  handleRationaleChange,
  showIsOtherOrTag,
  handleOtherChange,
  currRationaleValue,
  isRationaleRequired,
  isHeightOverflow,
  isFromTouch,
}: RationaleSectionProps) => {
  return (
    <Styled.ContentContainer
      isHeightOverflow={isHeightOverflow}
      isRationaleRequired={isRationaleRequired}
    >
      {!isFromTouch && (
        <Row className="profile-information__subtitle">
          <Typography.Text>
            Train the algorithm and tell us why{' '}
            <span className="subtitle-additional-text">
              ({isRationaleRequired ? 'required' : 'optional'})
            </span>
          </Typography.Text>
        </Row>
      )}
      <Radio.Group
        buttonStyle="solid"
        onChange={e => handleRationaleChange(e.target.value)}
        value={rationaleValue}
        style={{ width: '100%' }}
      >
        {rationaleOptions?.map(option => (
          <Col key={option.value} style={{ textAlign: 'center', marginTop: 10, padding: 0 }}>
            <Radio.Button style={{ width: '100%' }} value={option.value}>
              {option.label}
            </Radio.Button>
          </Col>
        ))}
      </Radio.Group>
      {!!showIsOtherOrTag && showIsOtherOrTag() && (
        <Row style={{ marginTop: 20, padding: 0 }}>
          <Typography.Text>Please Explain</Typography.Text>
          {!!handleOtherChange && (
            <CreateOtherRaionale
              onInputChange={handleOtherChange}
              isHeightOwerflow={isHeightOverflow}
            />
          )}
          {showIsOtherOrTag('tag') && <Styled.Tag color="blue">{currRationaleValue}</Styled.Tag>}
        </Row>
      )}
    </Styled.ContentContainer>
  );
};

export default RationaleSection;
