import React, { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
//redux
import { actions, selectors } from '@redux/company/opportunity-presentation';
//components
import * as Styled from '../styles/DealPresentation.styled';
import InputField from '@optx/features/field-factory/InputField';
import TextareaField from '@optx/features/field-factory/TextareaField';
import SingleSelectField from '@optx/features/field-factory/SingleSelectField';
import MultiSelectField from '@optx/features/field-factory/MultiSelectField';

interface OtherFinancialInformationProps {
  companyId: number;
  isChromeExtension?: boolean;
}

const OtherFinancialsInformation: React.FC<OtherFinancialInformationProps> = ({
  isChromeExtension,
  companyId,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any> | null>(null);
  const financialsInformations = useSelector(selectors.getFinancialsData);
  let fieldName: string | undefined = undefined;

  const handleUpdateField = (fieldType?: string) => {
    fieldName = fieldType;

    if (formRef?.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      const payload = {
        company_id: companyId,
        financial: {
          [fieldName as string]: formRef.current?.values[fieldName as string],
        },
      };
      dispatch(actions.updateOpportunityPresentation(payload));
    }

    formRef.current?.resetForm();
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      <Styled.OtherFinancialsInformationWrapper
        style={{ marginLeft: '24px', marginBottom: '7px', marginTop: '16px' }}
      >
        <Styled.OtherFinancialsInformationTitle level={5}>
          {isChromeExtension ? 'Other Financials' : 'Other'}
        </Styled.OtherFinancialsInformationTitle>
        <Styled.OtherFinancialsInformationRow>
          <Styled.OtherFinancialsInformationLabel $isChromeExtension={isChromeExtension}>
            Revenue Model
          </Styled.OtherFinancialsInformationLabel>
          <Styled.OtherFinancialsInformationValue $isChromeExtension={isChromeExtension}>
            <MultiSelectField
              fieldName="Revenue Model"
              fieldType="revenue_model"
              value={financialsInformations?.revenue_model.value as string[]}
              onSave={handleUpdateField}
              options={financialsInformations?.revenue_model.data}
              isChromePlugin={isChromeExtension}
            />
          </Styled.OtherFinancialsInformationValue>
        </Styled.OtherFinancialsInformationRow>
        <Styled.OtherFinancialsInformationRow>
          <Styled.OtherFinancialsInformationLabel $isChromeExtension={isChromeExtension}>
            Cashflow
          </Styled.OtherFinancialsInformationLabel>
          <Styled.OtherFinancialsInformationValue $isChromeExtension={isChromeExtension}>
            <SingleSelectField
              fieldName="Cashflow"
              fieldType="cashflow"
              value={financialsInformations?.cashflow.value as string}
              onSave={handleUpdateField}
              options={financialsInformations?.cashflow.data}
              isChromePlugin={isChromeExtension}
            />
          </Styled.OtherFinancialsInformationValue>
        </Styled.OtherFinancialsInformationRow>

        <Styled.OtherFinancialsInformationRow>
          <Styled.OtherFinancialsInformationLabel $isChromeExtension={isChromeExtension}>
            Revenue By Country
          </Styled.OtherFinancialsInformationLabel>
          <Styled.OtherFinancialsInformationValue $isChromeExtension={isChromeExtension}>
            <InputField
              fieldName="Revenue By Country"
              fieldType="revenue_by_country"
              value={financialsInformations?.revenue_by_country as string}
              isChromePlugin={isChromeExtension}
              onSave={handleUpdateField}
            />
          </Styled.OtherFinancialsInformationValue>
        </Styled.OtherFinancialsInformationRow>

        <Styled.OtherFinancialsInformationRow>
          <Styled.OtherFinancialsInformationLabel $isChromeExtension={isChromeExtension}>
            Current Process
          </Styled.OtherFinancialsInformationLabel>
          <Styled.OtherFinancialsInformationValue $isChromeExtension={isChromeExtension}>
            <TextareaField
              fieldName="Current Process"
              characterLimit={500}
              value={financialsInformations?.financial_notes as string}
              fieldType="financial_notes"
              onSave={handleUpdateField}
            />
          </Styled.OtherFinancialsInformationValue>
        </Styled.OtherFinancialsInformationRow>

        <Styled.OtherFinancialsInformationRow>
          <Styled.OtherFinancialsInformationLabel $isChromeExtension={isChromeExtension}>
            Other Notes
          </Styled.OtherFinancialsInformationLabel>
          <Styled.OtherFinancialsInformationValue $isChromeExtension={isChromeExtension}>
            <TextareaField
              fieldName="Other Notes"
              characterLimit={500}
              value={financialsInformations?.other_notes as string}
              fieldType="other_notes"
              onSave={handleUpdateField}
            />
          </Styled.OtherFinancialsInformationValue>
        </Styled.OtherFinancialsInformationRow>
      </Styled.OtherFinancialsInformationWrapper>
    </Formik>
  );
};

export default OtherFinancialsInformation;
