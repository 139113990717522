import { createSelector } from 'reselect';
// models
import { GridKeys, GridKeysMain } from '@models/grid';
import { selectFeatureState, StateSlice } from '../../selectFeature';
import Company from '@optx/models/Company';
// utils
import { getPreviousNextCompanyIds as previousNextCompanyIds } from '@utils/company';
// redux
import { isLoading as isLoadingFavoriteListsSelectors } from '@redux/favorite-lists/lists/selectors';
import { selectors as favoriteSelectors } from '@redux/favorites';
import {
  getSearchKey as getSearchKeySelector,
  getTotalCount as getTotalCountSelector,
  isLoading as isLoadingSelector,
} from './selectorsReusable';

const selectSearchFeatureState = (state: StateSlice) => selectFeatureState(state).search;

export const getCompanies = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => {
    return state[gridKey].allIds.map(id => state[gridKey].byId[id]);
  });

export const getCompanyById = (gridKey: GridKeysMain, companyId: number) =>
  createSelector(getCompanies(gridKey), companies => {
    const findCompany = companies.find((company: Company) => company.company_id === companyId);

    return findCompany;
  });

export const getPreviousNextCompanyIds = (gridKey: GridKeysMain, companyId: number) =>
  createSelector(getCompanies(gridKey), previousNextCompanyIds(companyId));

export const getFirstCompanyFromSearch = (gridKey: GridKeysMain) =>
  createSelector(
    selectSearchFeatureState,
    state => state[gridKey].allIds.map(id => state[gridKey].byId[id])[0]
  );

export const getError = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].error);

export const shouldInitialFetch = (gridKey: GridKeysMain) =>
  createSelector(
    selectSearchFeatureState,
    state => !state[gridKey].fetchedAt && !state[gridKey].error && !state[gridKey].loading
  );

export const isInitialSearch = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => !state[gridKey].fetchedAt);

export const isLoading = (gridKey: GridKeysMain) => isLoadingSelector(gridKey);

export const fetched = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => !!state[gridKey].fetchedAt);

export const isCancelled = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].cancelled);

export const isError = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].error);

export const hideCancelSearch = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].hideCancelSearch);

export const filterIsLoading = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].loading);

export const makeSelectLoadingHomePage = (gridKey: GridKeysMain) =>
  createSelector(
    isLoading(gridKey),
    filterIsLoading(gridKey),
    favoriteSelectors.isLoading,
    isLoadingFavoriteListsSelectors,
    (loadingSeach, loadingFilters, loadingFavorite, loadingListsFavorites) => {
      return loadingSeach || loadingFilters || loadingFavorite || loadingListsFavorites;
    }
  );

export const getSearchName = (gridKey: GridKeysMain | GridKeys) =>
  createSelector(selectSearchFeatureState, state => {
    if (state[gridKey]) {
      return state[gridKey].searchTitle;
    }

    return '';
  });

export const getTrending = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].trending);

export const getTotalCount = (gridKey: GridKeysMain) => getTotalCountSelector(gridKey);

export const getSearchKey = (gridKey: GridKeysMain) => getSearchKeySelector(gridKey);

export const getTableGridQuery = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].tableGridQuery);

export const shouldUpdateSourcingOutReachAnalystInfo = createSelector(
  selectSearchFeatureState,
  state => state.outreach.shouldUpdateSourcingOutReachAnalystInfo
);

export const getCompanyId = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey].companyId);

export const getListType = (gridKey: GridKeysMain) =>
  createSelector(selectSearchFeatureState, state => state[gridKey]?.listType);
