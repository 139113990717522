import { call, put, takeLatest, select, fork } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
import { SortByRule, SortRule } from '@models/table/sorting';
// models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { PreselectedFilter } from '@optx/models/filters';
import Company from '@optx/models/Company';
import { PageInfo } from '@optx/models/table/Pagination';
import { SearchViewType } from '@optx/models/user';
import { GridKeys } from '@models/grid';
// constants
import { LONG_CARD_VIEW_DEFAULT_PAGE_SIZE } from '@constants/pagination';
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// utils
import { mapSortQuery } from '@utils/search';
// redux
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as listsSelectors, actions as listsActions } from '@redux/lists/details';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions as histogramActions } from '@features/histograms/histograms-list-details';
import { actions as searchActions, selectors as searchSelectors } from '@features/grid/search';
import * as selectors from './selectors';
import { actions as sortActions } from '../sort';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { sagas as searchFiltersSagas } from '@optx/features/grid/filter';
import { actions as longCardPaginationActions } from '@features/grid/pagination-long-card';
import { selectors as viewSelectors } from '@features/grid/view-type';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { actions as columnActions } from '../columns';
import { getFiltersQuery } from '@redux/company/search/search';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { userInfoUpdateSaga } from '@features/grid/state/sagasReusable';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

// sort
export function* changeSortSaga(action: PayloadAction<SortRule<Company>>) {
  const listId: string = yield select(listsSelectors.getRouteListId);
  const sortBy = action.payload.sortBy;
  const isSourceScrubRoute: boolean = yield select(listsSelectors.isSourceScrubRoute);
  const searchKey: string = yield select(searchSelectors.getSearchKey('lists'));
  let filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
  );

  filter = {
    ...filter,
    ...(isSourceScrubRoute
      ? { ss_list_id: listId, saved_list_id: undefined }
      : { saved_list_id: listId, ss_list_id: undefined }),
  };

  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);
  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(isSourceScrubRoute ? 'sslists' : 'watchlists')
  );
  const view: SearchViewType = yield select(viewSelectors.searchView('lists'));
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const gridKey: GridKeys = isSourceScrubRoute ? 'sslists' : 'watchlists';

  const id: number = yield select(getViewId(gridKey));
  const listType: string = yield select(getListType('lists'));

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
    fromSavedList: id,
    listType,
  };

  try {
    const userSettings: { [key: string]: number } = yield call(
      userInfoUpdateSaga,
      gridKey,
      pagination,
      true,
      true,
      sortBy
    );

    yield call(searchCompanies, searchData, 'lists', filter, undefined, undefined, userSettings);

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({ gridKey, data: pagination })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({
          gridKey,
          data: pagination,
        })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, 'lists', error);
  }
}

export function* resetSortSaga() {
  const sortBy = COMPANY_DEFAULT_SORT;
  const searchKey: string = yield select(searchSelectors.getSearchKey('lists'));
  const isSourceScrubRoute: PageInfo = yield select(listsSelectors.isSourceScrubRoute);
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter(isSourceScrubRoute ? 'sslists' : 'watchlists')
  );
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);
  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(isSourceScrubRoute ? 'sslists' : 'watchlists')
  );
  const view: SearchViewType = yield select(viewSelectors.searchView('lists'));
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };
  const userSessionSettingsUserListPageNumber: number = yield select(
    userInformationSelectors.getUserListSearchesResultsPageNumber
  );
  const userSessionSettingsSSListPageNumber: number = yield select(
    userInformationSelectors.getSSListSearchesResultsPageNumber
  );

  const gridKey: GridKeys = isSourceScrubRoute ? 'sslists' : 'watchlists';

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination: { ...pagination, pageNumber: 1 },
    sortBy,
  };
  const sortQuery = queryString.stringify(mapSortQuery(sortBy), { arrayFormat: 'comma' });

  try {
    yield call(searchCompanies, searchData, 'lists', filter);
    yield put(sortActions.resetSortSuccessAction());

    if (
      isSourceScrubRoute &&
      searchData.pagination.pageNumber !== userSessionSettingsSSListPageNumber
    ) {
      yield put(
        userInformationActions.updateUserSettingsForSSList({
          user_list_sorting: sortQuery,
          ss_list_search_results_page_number: searchData.pagination.pageNumber,
        })
      );
    } else if (
      !isSourceScrubRoute &&
      searchData.pagination.pageNumber !== userSessionSettingsUserListPageNumber
    ) {
      yield put(
        userInformationActions.updateUserSettingsForUserList({
          user_list_sorting: sortQuery,
          user_list_search_results_page_number: searchData.pagination.pageNumber,
        })
      );
    } else {
      yield put(
        userInformationActions.updateUserSettingsForUserList({ user_list_sorting: sortQuery })
      );
    }

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({
          gridKey,
          data: searchData.pagination,
        })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({
          gridKey,
          data: searchData.pagination,
        })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, 'lists', error);
  }
}

export function* multiSortSaga(
  action: PayloadAction<boolean, any, Array<SortByRule<any>> | undefined>
) {
  const isMultiSort = action.payload;
  const previousSorting = action.meta;
  const currentSorting: SortByRule<any>[] = yield select(selectors.getSorting);

  // Only make the call to API if previous sort was changed to single sort and
  // previous sorting is the same as next sorting.
  if (!isMultiSort && previousSorting !== currentSorting) {
    yield put(searchActions.hideCancelSearch({ gridKey: 'lists', data: true }));
    yield put(sortActions.changeSortAction({ sortBy: currentSorting }));
  }
}

export function* loadCompanyListSaga(
  action: PayloadAction<{ id: string; isSourceScrub: boolean }>
) {
  const { id, isSourceScrub } = action.payload;
  const gridKey: GridKeys = isSourceScrub ? 'sslists' : 'watchlists';

  const defaultFilter: Dictionary<PreselectedFilter> = yield select(
    filterSourcesSelectors.getDefaultFilters
  );
  const defaultSorting: SortByRule<any>[] = yield select(filterSourcesSelectors.getDefaultSorting);
  const defaultColumns: string = yield select(filterSourcesSelectors.getDefaultColumns);
  const defaultColumnOrder: string = yield select(filterSourcesSelectors.getDefaultColumnOrder);
  const defaultPinnedColumns: string = yield select(filterSourcesSelectors.getDefaultPinnedColumns);

  const regularPagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const view: SearchViewType = yield select(viewSelectors.searchView('lists'));
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };
  const userSessionSettingsUserListPageNumber: number = yield select(
    userInformationSelectors.getUserListSearchesResultsPageNumber
  );
  const userSessionSettingsSSListPageNumber: number = yield select(
    userInformationSelectors.getSSListSearchesResultsPageNumber
  );
  const sortBy = defaultSorting || (yield select(selectors.getSorting));
  const searchKey: string = yield select(filterSourcesSelectors.getDefaultSearchkey);
  let filter = Object.keys(defaultFilter).length
    ? defaultFilter
    : // @ts-ignore
      yield select(filterSelectors.getCustomClearedFilter(gridKey));

  const listId: number = yield select(getViewId('watchlists'));
  const listType: string = yield select(getListType('lists'));

  // Ensures to filter by the selected list
  filter = {
    ...filter,
    ...(isSourceScrub ? { ss_list_id: id } : { saved_list_id: id }),
  };
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
    fromSavedList: listId,
    listType,
  };

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), { arrayFormat: 'comma' });

  try {
    if (defaultColumns) {
      yield put(
        columnActions.updateColumns(defaultColumns, defaultColumnOrder, defaultPinnedColumns)
      );
    }

    if (isSourceScrub && searchData.pagination.pageNumber !== userSessionSettingsSSListPageNumber) {
      yield call(searchCompanies, searchData, 'lists', filter, false, undefined, {
        user_list_sorting: sortQuery,
        user_list_display_columns: defaultColumns ?? undefined,
        user_list_column_order: defaultColumnOrder || undefined,
        user_list_pinned_columns: defaultPinnedColumns ?? undefined,
        ss_list_search_results_page_number: searchData.pagination.pageNumber,
      });
    } else if (
      !isSourceScrub &&
      searchData.pagination.pageNumber !== userSessionSettingsUserListPageNumber
    ) {
      yield call(searchCompanies, searchData, 'lists', filter, false, undefined, {
        user_list_sorting: sortQuery,
        user_list_display_columns: defaultColumns ?? undefined,
        user_list_column_order: defaultColumnOrder || undefined,
        user_list_pinned_columns: defaultPinnedColumns ?? undefined,
        user_list_search_results_page_number: searchData.pagination.pageNumber,
      });
    } else {
      yield call(searchCompanies, searchData, 'lists', filter, false, undefined, {
        user_list_sorting: sortQuery,
        user_list_display_columns: defaultColumns ?? undefined,
        user_list_column_order: defaultColumnOrder || undefined,
        user_list_pinned_columns: defaultPinnedColumns ?? undefined,
      });
    }

    yield put(listsActions.loadCompanyListDetails({ id, isSourceScrub }));

    if (defaultSorting) {
      if (defaultSorting.length > 1) {
        yield put(sortActions.multiSortAction(true));
      } else {
        yield put(sortActions.resetSortSuccessAction(sortBy));
      }
    }

    if (view === 'long-card') {
      yield put(
        longCardPaginationActions.changeLongCardPaginationSuccess({
          gridKey,
          data: searchData.pagination,
        })
      );
    } else {
      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData.pagination,
        })
      );
    }

    yield put(histogramActions.fetchHistogramFilters(filter));
  } catch (error: any) {
    yield call(searchCompaniesFail, 'lists', error);
  }
}

export default function* userListsSearchSaga() {
  yield takeLatest(sortActions.changeSortAction, changeSortSaga);
  yield takeLatest(sortActions.multiSortAction, multiSortSaga);
  yield takeLatest(sortActions.resetSortAction, resetSortSaga);
  yield takeLatest(listsActions.loadCompanyList, loadCompanyListSaga);
  yield fork(searchFiltersSagas);
}
