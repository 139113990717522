import React from 'react';
import { Tooltip, Tag, Row, Col, Typography } from 'antd';
import { CompanyUserContact } from '@optx/models/api/contacts';
import styled, { css } from 'styled-components';

const { Text } = Typography;

interface ContactOptionsProps {
  contact: CompanyUserContact;
}

const RowWrapper = styled(Row)<{ $isPrimary: boolean }>`
  flex-flow: row nowrap;
  .contact-option__name {
    width: calc(50%);
  }
  ${props =>
    props.$isPrimary &&
    css`
      .contact-option__name {
        width: calc(50% - 57px);
      }
    `}
  .ant-typography {
    max-width: 100%;
  }
  .contact-option__title {
    width: calc(50% - 22px);
    text-align: right;
    margin-left: 10px;
  }
`;

const ContactOption: React.FC<ContactOptionsProps> = ({ contact }) => {
  const { first_name: firstName, last_name: lastName, title, primary_contact: isPrimary } = contact;
  const contactName = `${firstName} ${lastName}`;

  return (
    <RowWrapper justify="space-between" $isPrimary={isPrimary}>
      <Col className="contact-option__name">
        <Tooltip title={contactName}>
          <Text ellipsis>{contactName}</Text>
        </Tooltip>
      </Col>
      {isPrimary && (
        <Col>
          <Tag color="blue">Primary</Tag>
        </Col>
      )}
      <Col className="contact-option__title">
        <Tooltip title={title}>
          <Text ellipsis>{title}</Text>
        </Tooltip>
      </Col>
    </RowWrapper>
  );
};

export default ContactOption;
