import { FC } from 'react';
import { Tag } from 'antd';
// models
import { EquityTouchDatabaseMatch } from '@models/equityTouch';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

interface EquityTouchGroupsFieldContentProps {
  group: EquityTouchDatabaseMatch;
}

export const EquityTouchGroupsFieldContent: FC<EquityTouchGroupsFieldContentProps> = ({
  group,
}) => {
  return (
    <span className="d-flex align-items-center">
      <TruncateTooltip title={group.text}>
        <span className="text-ellipsis">{group.text}</span>
      </TruncateTooltip>

      {!group?.type && (
        <Tag color="gold" className="ml-1">
          ET
        </Tag>
      )}
    </span>
  );
};
