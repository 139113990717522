import React from 'react';
import { Result } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// redux
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

interface CompletedProps {
  totalCompanies: number;
}

const Completed: React.FC<CompletedProps> = ({ totalCompanies }) => {
  const successfulCount = useSelector(favoriteListsSelectors.getPercentage);
  const isCancel = useSelector(favoriteListsSelectors.isCancel);

  return (
    <ResultWrapper
      key="save-search-list-result-key"
      status={!successfulCount ? 'error' : 'success'}
      title={`${successfulCount || 0}/${totalCompanies} ${
        totalCompanies === 1 ? 'Company' : 'Companies'
      } ${
        successfulCount === 0 || isCancel ? 'added to the List' : 'Successfully added to the List'
      }`}
    />
  );
};

export default Completed;
