import { createFeatureActionType } from '@utils/feature';
import { featureName } from '@features/grid/featureName';

export const INITIAL_COMPANIES_SEARCH = createFeatureActionType(
  featureName,
  'INITIAL_COMPANIES_SEARCH'
);

export const CHANGE_PAGINATION_SUCCESS = createFeatureActionType(
  featureName,
  'CHANGE_PAGINATION_SUCCESS'
);

export const COMPANIES_SEARCH = createFeatureActionType(featureName, 'COMPANIES_SEARCH');

export const COMPANIES_SEARCH_SUCCESS = createFeatureActionType(
  featureName,
  'COMPANIES_SEARCH_SUCCESS'
);

export const COMPANIES_SEARCH_FAIL = createFeatureActionType(featureName, 'COMPANIES_SEARCH_FAIL');

export const CANCEL_COMPANY_SEARCH = createFeatureActionType(featureName, 'CANCEL_COMPANY_SEARCH');

export const HIDE_CANCEL_SEARCH = createFeatureActionType(featureName, 'HIDE_CANCEL_SEARCH');

export const UPDATE_TRENDING = createFeatureActionType(featureName, 'UPDATE_TRENDING');

export const CLEAR_COMPANY_SEARCH = createFeatureActionType(featureName, 'CLEAR_COMPANY_SEARCH');

export const CLEAR_COMPANY_SEARCH_SUCCESS = createFeatureActionType(
  featureName,
  'CLEAR_COMPANY_SEARCH_SUCCESS'
);

export const RESET_COMPANY_SEARCH = createFeatureActionType(featureName, 'RESET_COMPANY_SEARCH');

export const RESET_COMPANY_SEARCH_SUCCESS = createFeatureActionType(
  featureName,
  'RESET_COMPANY_SEARCH_SUCCESS'
);

export const LOAD_SAVED_SEARCH = createFeatureActionType(featureName, 'LOAD_SAVED_SEARCH');

export const LOAD_SUGGESTED_SEARCH = createFeatureActionType(featureName, 'LOAD_SUGGESTED_SEARCH');

export const RESET_FILTER = createFeatureActionType(featureName, 'RESET_FILTER');

export const LOAD_AUTOCOMPLETE_SEARCH = createFeatureActionType(
  featureName,
  'LOAD_AUTOCOMPLETE_SEARCH'
);

export const LOAD_COMPANIES_BY_FILTER = createFeatureActionType(
  featureName,
  'LOAD_COMPANIES_BY_FILTER'
);

export const SAVE_SEARCH_TITLE = createFeatureActionType(featureName, 'SAVE_SEARCH_TITLE');

export const DELETE_SAVED_LIST = createFeatureActionType(featureName, 'DELETE_SAVED_LIST');

export const LOAD_COMPANIES_BY_FILTER_COMPLETED = createFeatureActionType(
  featureName,
  'LOAD_COMPANIES_BY_FILTER_COMPLETED'
);

export const LOAD_DIVERSITY_FILTER = createFeatureActionType(featureName, 'LOAD_DIVERSITY_FILTER');

export const INITIALIZE_LONG_CARD_SEARCH = createFeatureActionType(
  featureName,
  'INITIALIZE_LONG_CARD_SEARCH'
);

export const SHOULD_UPDATE_SOURCING_OUT_REACH_ANALYST_INFO = createFeatureActionType(
  featureName,
  'SHOULD_UPDATE_SOURCING_OUT_REACH_ANALYST_INFO'
);

export const UPDATE_SOURCING_OUT_REACH_ANALYST_INFO = createFeatureActionType(
  featureName,
  'UPDATE_SOURCING_OUT_REACH_ANALYST_INFO'
);

export const REMOVE_FILTER = createFeatureActionType(featureName, 'REMOVE_FILTER');

export const CLEAR_FILTER_SUCCESS = createFeatureActionType(featureName, 'CLEAR_FILTER_SUCCESS');

export const FETCH_COUNT = createFeatureActionType(featureName, 'FETCH_COUNT');

export const FETCH_COUNT_SUCCESS = createFeatureActionType(featureName, 'FETCH_COUNT_SUCCESS');

export const FETCH_COUNT_FAIL = createFeatureActionType(featureName, 'FETCH_COUNT_FAIL');

export const MY_COMPANIES_CLEAR_SEARCH = createFeatureActionType(
  featureName,
  'MY_COMPANIES_CLEAR_SEARCH'
);
