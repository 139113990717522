import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
// utils
import { getRangeValues, getValuesRalation } from '@optx/utils/researchRationale';
// constants
import {
  SOFTWARE_CARD_NAME,
  FIT_CARD_NAME,
  OPTX_SCORE_RATIONALE_KEY,
  OPTX_SCORE_LOWER_RATIONALE_KEY,
  OPTX_SCORE_UPPER_RATIONALE_KEY,
  SOFTWARE_RATIONALE_KEY,
  NEW_OPTX_SCORE_RATIONALE_KEY,
  NEW_OPTX_SCORE_LOWER_RATIONALE_KEY,
  NEW_OPTX_SCORE_UPPER_RATIONALE_KEY,
} from '@features/add-research-rationale/constants';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import * as actions from './actions';
import {
  AddResearchRationaleSuccess,
  AddResearchRationaleState,
  ChangeFieldsPayload,
  AddResearchRationale,
} from './interfaces';

const initialState: AddResearchRationaleState = {
  open: false,
  company: null,
  fields: {},
  rationale: {},
  initialFieldsValues: {},
  initialRationaleValues: {},
  activeScoreRationaleReasonType: null,
  activeNewScoreRationaleReasonType: null,
};

const toggleAddResearchRationaleReducer: CaseReducer<
  AddResearchRationale & AddResearchRationaleState
> = (draftState, action) => {
  draftState.open = !draftState?.open!;
};

const toggleAddResearchRationaleSuccessReducer: CaseReducer<
  AddResearchRationaleState,
  PayloadAction<AddResearchRationaleSuccess>
> = (draftState, action) => {
  const { company, defaultScore } = action.payload;
  const scoreRationale =
    defaultScore === 'il' ? company?.il_optx_score_rationale : company?.optx_score_rationale;

  const newScoreRationale =
    defaultScore === 'il' ? company?.il_optx_score_rationale : company?.optx_score_v3_rationale;

  const scoreRationaleReason =
    defaultScore === 'il'
      ? company?.il_optx_score_rationale_reason
      : company?.optx_score_rationale_reason;

  const newScoreRationaleReason =
    defaultScore === 'il'
      ? company?.il_optx_score_rationale_reason
      : company?.optx_score_v3_rationale_reason;

  const scoreRationaleReasonType =
    defaultScore === 'il'
      ? company?.il_optx_score_rationale_reason_type
      : company?.optx_score_rationale_reason_type;

  const newScoreRationaleReasonType =
    defaultScore === 'il'
      ? company?.il_optx_score_rationale_reason_type
      : company?.optx_score_v3_rationale_reason_type;

  const scoreLowerRationaleReason =
    scoreRationaleReasonType === 'lower' ? (scoreRationaleReason as string | null) : null;
  const scoreUpperRationaleReason =
    scoreRationaleReasonType === 'upper' ? (scoreRationaleReason as string | null) : null;

  const newScoreLowerRationaleReason =
    newScoreRationaleReasonType === 'lower' ? (newScoreRationaleReason as string | null) : null;
  const newScoreUpperRationaleReason =
    newScoreRationaleReasonType === 'upper' ? (newScoreRationaleReason as string | null) : null;

  const scoreVerified =
    defaultScore === 'il' ? company?.il_optx_score_verified : company?.optx_score_verified;

  const newScoreVerified = company?.optx_score_v3_verified;

  draftState.company = company;

  if (scoreLowerRationaleReason !== null) {
    draftState.activeScoreRationaleReasonType = 'Score Lower';
  } else if (scoreUpperRationaleReason !== null) {
    draftState.activeScoreRationaleReasonType = 'Score Upper';
  } else if (scoreRationale && scoreRationale !== DEFAULT_EMPTY_VALUE) {
    const rangeValues = getRangeValues(scoreRationale);
    let score;

    if (draftState.company) {
      score = draftState.company[defaultScore === 'us' ? 'score' : 'il_optx_score'];
    }

    if (typeof score !== 'number') {
      score = 'N/A';
    }

    const scoreValuesRelation =
      typeof score !== 'string' ? getValuesRalation(rangeValues, score as number) : 'upper';

    draftState.activeScoreRationaleReasonType =
      // eslint-disable-next-line no-nested-ternary
      scoreValuesRelation === 'lower'
        ? 'Score Lower'
        : scoreValuesRelation === 'upper'
        ? 'Score Upper'
        : null;
  } else {
    draftState.activeScoreRationaleReasonType = null;
  }

  if (newScoreLowerRationaleReason !== null) {
    draftState.activeNewScoreRationaleReasonType = 'Score Lower';
  } else if (newScoreUpperRationaleReason !== null) {
    draftState.activeNewScoreRationaleReasonType = 'Score Upper';
  } else if (newScoreRationale && newScoreRationale !== DEFAULT_EMPTY_VALUE) {
    const rangeValues = getRangeValues(newScoreRationale);
    let newScore;

    if (draftState.company) {
      newScore = draftState.company.score_v3;
    }

    if (typeof newScore !== 'number') {
      newScore = 'N/A';
    }

    const scoreValuesRelation =
      typeof newScore !== 'string' ? getValuesRalation(rangeValues, newScore as number) : 'upper';

    draftState.activeNewScoreRationaleReasonType =
      // eslint-disable-next-line no-nested-ternary
      scoreValuesRelation === 'lower'
        ? 'Score Lower'
        : scoreValuesRelation === 'upper'
        ? 'Score Upper'
        : null;
  } else {
    draftState.activeNewScoreRationaleReasonType = null;
  }

  draftState.fields = {
    is_software: company?.is_software as boolean | null,
    is_interesting: company?.is_interesting as boolean | null,
    optx_score_verified: scoreVerified as boolean | null,
    optx_score_v3_verified: newScoreVerified as boolean | null,
  };

  draftState.rationale = {
    is_software_rationales: company?.is_software_rationale as string | null,
    psg_fit_rationales: company?.interesting_rationale as string | null,
    optx_score_rationales: scoreRationale as string | null,
    optx_score_upper_rationales: scoreUpperRationaleReason,
    optx_score_lower_rationales: scoreLowerRationaleReason,
    optx_score_v3_rationales: newScoreRationale as string | null,
    optx_score_v3_upper_rationales: newScoreUpperRationaleReason,
    optx_score_v3_lower_rationales: newScoreLowerRationaleReason,
  };

  draftState.initialFieldsValues = {
    is_software: company?.is_software as boolean | null,
    is_interesting: company?.is_interesting as boolean | null,
    optx_score_verified: scoreVerified as boolean | null,
    optx_score_v3_verified: newScoreVerified as boolean | null,
  };

  draftState.initialRationaleValues = {
    is_software_rationales: company?.is_software_rationale as string | null,
    psg_fit_rationales: company?.interesting_rationale as string | null,
    optx_score_rationales: scoreRationale as string | null,
    optx_score_upper_rationales: scoreUpperRationaleReason,
    optx_score_lower_rationales: scoreLowerRationaleReason,
    optx_score_v3_rationales: newScoreRationale as string | null,
    optx_score_v3_upper_rationales: newScoreUpperRationaleReason,
    optx_score_v3_lower_rationales: newScoreLowerRationaleReason,
  };
};

const changeFieldsReducer: CaseReducer<
  AddResearchRationaleState,
  PayloadAction<ChangeFieldsPayload>
> = (draftState, action) => {
  const { key, value } = action.payload;

  if (key === 'optx_score_verified' || key === 'il_optx_score_verified') {
    draftState.fields.optx_score_verified = value;
  } else if (key === 'optx_score_v3_verified') {
    draftState.fields.optx_score_v3_verified = value;
  } else {
    draftState.fields[key] = value;
  }

  // @ts-ignore
  draftState.company[key] = value;
};

const changeRationaleReducer: CaseReducer<
  AddResearchRationaleState,
  PayloadAction<ChangeFieldsPayload>
> = (draftState, action) => {
  const { key, value, defaultScore } = action.payload;

  if (key === SOFTWARE_RATIONALE_KEY && value === null) {
    draftState.rationale[SOFTWARE_RATIONALE_KEY] = DEFAULT_EMPTY_VALUE;
  } else if (key === OPTX_SCORE_RATIONALE_KEY && value !== null) {
    draftState.rationale[OPTX_SCORE_RATIONALE_KEY] = value;
    draftState.rationale[OPTX_SCORE_LOWER_RATIONALE_KEY] = null;
    draftState.rationale[OPTX_SCORE_UPPER_RATIONALE_KEY] = null;

    const rangeValues = getRangeValues(value as string);
    let score;

    if (draftState.company) {
      score = draftState.company[defaultScore === 'us' ? 'score' : 'il_optx_score'];
    }

    if (typeof score !== 'number') {
      score = 'N/A';
    }

    const scoreValuesRelation =
      typeof score !== 'string' ? getValuesRalation(rangeValues, score as number) : 'upper';

    draftState.activeScoreRationaleReasonType =
      // eslint-disable-next-line no-nested-ternary
      scoreValuesRelation === 'lower'
        ? 'Score Lower'
        : scoreValuesRelation === 'upper'
        ? 'Score Upper'
        : null;
  } else if (key === NEW_OPTX_SCORE_RATIONALE_KEY && value !== null) {
    draftState.rationale[NEW_OPTX_SCORE_RATIONALE_KEY] = value;
    draftState.rationale[NEW_OPTX_SCORE_LOWER_RATIONALE_KEY] = null;
    draftState.rationale[NEW_OPTX_SCORE_UPPER_RATIONALE_KEY] = null;

    const rangeValues = getRangeValues(value as string);
    let score;

    if (draftState.company) {
      score = draftState.company.score_v3;
    }

    if (typeof score !== 'number') {
      score = 'N/A';
    }

    const scoreValuesRelation =
      typeof score !== 'string' ? getValuesRalation(rangeValues, score as number) : 'upper';

    draftState.activeNewScoreRationaleReasonType =
      // eslint-disable-next-line no-nested-ternary
      scoreValuesRelation === 'lower'
        ? 'Score Lower'
        : scoreValuesRelation === 'upper'
        ? 'Score Upper'
        : null;
  } else if (
    key === OPTX_SCORE_RATIONALE_KEY &&
    (value === null || value === DEFAULT_EMPTY_VALUE)
  ) {
    draftState.rationale[OPTX_SCORE_RATIONALE_KEY] = value;
    draftState.rationale[OPTX_SCORE_LOWER_RATIONALE_KEY] = null;
    draftState.rationale[OPTX_SCORE_UPPER_RATIONALE_KEY] = null;
    draftState.activeScoreRationaleReasonType = null;
  } else if (
    key === NEW_OPTX_SCORE_RATIONALE_KEY &&
    (value === null || value === DEFAULT_EMPTY_VALUE)
  ) {
    draftState.rationale[NEW_OPTX_SCORE_RATIONALE_KEY] = value;
    draftState.rationale[NEW_OPTX_SCORE_LOWER_RATIONALE_KEY] = null;
    draftState.rationale[NEW_OPTX_SCORE_UPPER_RATIONALE_KEY] = null;
    draftState.activeNewScoreRationaleReasonType = null;
  } else if (key === OPTX_SCORE_LOWER_RATIONALE_KEY) {
    draftState.rationale[OPTX_SCORE_LOWER_RATIONALE_KEY] = value;
    draftState.rationale[OPTX_SCORE_UPPER_RATIONALE_KEY] = null;
  } else if (key === NEW_OPTX_SCORE_LOWER_RATIONALE_KEY) {
    draftState.rationale[NEW_OPTX_SCORE_LOWER_RATIONALE_KEY] = value;
    draftState.rationale[NEW_OPTX_SCORE_UPPER_RATIONALE_KEY] = null;
  } else if (key === OPTX_SCORE_UPPER_RATIONALE_KEY) {
    draftState.rationale[OPTX_SCORE_UPPER_RATIONALE_KEY] = value;
    draftState.rationale[OPTX_SCORE_LOWER_RATIONALE_KEY] = null;
  } else if (key === NEW_OPTX_SCORE_UPPER_RATIONALE_KEY) {
    draftState.rationale[NEW_OPTX_SCORE_UPPER_RATIONALE_KEY] = value;
    draftState.rationale[NEW_OPTX_SCORE_LOWER_RATIONALE_KEY] = null;
  } else {
    draftState.rationale[key] = value;
  }
};

const revertFieldsChangesReducer: CaseReducer<
  AddResearchRationaleState,
  PayloadAction<ChangeFieldsPayload>
> = (draftState, action) => {
  const { key, value } = action.payload;

  if (key === SOFTWARE_CARD_NAME) {
    draftState.fields.is_interesting = draftState.initialFieldsValues.is_interesting;
    draftState.rationale.psg_fit_rationales = draftState.initialRationaleValues.psg_fit_rationales;
    draftState.rationale.optx_score_rationales =
      draftState.initialRationaleValues.optx_score_rationales;
    draftState.rationale.optx_score_lower_rationales =
      draftState.initialRationaleValues.optx_score_lower_rationales;
    draftState.rationale.optx_score_upper_rationales =
      draftState.initialRationaleValues.optx_score_upper_rationales;
    draftState.rationale.optx_score_v3_rationales =
      draftState.initialRationaleValues.optx_score_v3_rationales;
    draftState.rationale.optx_score_v3_lower_rationales =
      draftState.initialRationaleValues.optx_score_v3_lower_rationales;
    draftState.rationale.optx_score_v3_upper_rationales =
      draftState.initialRationaleValues.optx_score_v3_upper_rationales;

    if (draftState.company) {
      draftState.company.is_interesting = draftState.initialFieldsValues.is_interesting as
        | boolean
        | null;
    }

    if (value === null) {
      draftState.rationale.is_software_rationales = DEFAULT_EMPTY_VALUE;
    }
  }

  if (key === FIT_CARD_NAME) {
    draftState.rationale.optx_score_rationales =
      draftState.initialRationaleValues.optx_score_rationales;
    draftState.rationale.optx_score_lower_rationales =
      draftState.initialRationaleValues.optx_score_lower_rationales;
    draftState.rationale.optx_score_upper_rationales =
      draftState.initialRationaleValues.optx_score_upper_rationales;
    draftState.rationale.optx_score_v3_rationales =
      draftState.initialRationaleValues.optx_score_v3_rationales;
    draftState.rationale.optx_score_v3_lower_rationales =
      draftState.initialRationaleValues.optx_score_v3_lower_rationales;
    draftState.rationale.optx_score_v3_upper_rationales =
      draftState.initialRationaleValues.optx_score_v3_upper_rationales;

    if (value === null) {
      draftState.rationale.psg_fit_rationales = null;
    }
  }
};

export default createReducer(initialState, builder =>
  builder
    .addCase(actions.toggleAddResearchRationale, toggleAddResearchRationaleReducer)
    .addCase(actions.toggleAddResearchRationaleSuccess, toggleAddResearchRationaleSuccessReducer)
    .addCase(actions.changeFields, changeFieldsReducer)
    .addCase(actions.changeRationale, changeRationaleReducer)
    .addCase(actions.revertFieldsChanges, revertFieldsChangesReducer)
);
