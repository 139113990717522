import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloudOutlined } from '@ant-design/icons';
import { Divider, Button, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
// constants
import globalConfig from '@optx/constants/config';
import { companyPageTop } from '@optx/constants/pendoActions';
// redux
import {
  getIsPartnerOnly,
  getHasAccessToReviewCompany,
} from '@optx/redux/user/information/selectors';
import { addCompanyReview } from '../state/actions';

const StyledDivider = styled(Divider)`
  border-right: 2px solid #b0d2f0;
  height: 20px;
`;

interface CompanyReviewProps {
  companyId: number;
  reviewedBy: string | null;
  reviewedDate: string | null;
  isReviewed: boolean;
}

const CompanyReview: React.FC<CompanyReviewProps> = ({
  companyId,
  reviewedBy,
  reviewedDate,
  isReviewed,
}) => {
  const dispatch = useDispatch();
  const isPartner = useSelector(getIsPartnerOnly);
  const hasReviewAccess = useSelector(getHasAccessToReviewCompany);

  const onClick = () => {
    window.pendo.track(companyPageTop.MarkAsReviewedButton);
    dispatch(addCompanyReview({ companyId }));
  };

  const formattedReviewedDate = moment(reviewedDate).format(globalConfig.short_date.DATE_FORMAT);

  const tooltipTitle = `Reviewed by Cloudfactory user "${reviewedBy}"
   on ${formattedReviewedDate}`;

  if (!isReviewed && (isPartner || hasReviewAccess)) {
    return (
      <div className="company_review">
        <Button onClick={onClick} type="default" ghost>
          Mark as reviewed
        </Button>
        <StyledDivider type="vertical" />
      </div>
    );
  }

  if (isReviewed) {
    return (
      <div className="company_review__cloud">
        <CloudOutlined style={{ fontSize: 20, color: 'white' }} />
        <Tooltip className="company_review__cloud__label" title={tooltipTitle}>
          {`reviewed on ${formattedReviewedDate}`}
        </Tooltip>
        <StyledDivider type="vertical" />
      </div>
    );
  }

  return null;
};

export default CompanyReview;
