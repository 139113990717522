import { FieldInputProps } from 'formik';
import { parse, stringify } from 'query-string';
// models
import { SelectOption } from '@models/Option';
import { RouteRedirectStateProps } from '@models/routes';
import { AddedCompany } from '../models';
// utils
import { getAddToSalesLoftURL, getCompanyProfileURL } from '@utils/routes';

export const getCityDependenciesOptions = (
  countryField?: FieldInputProps<SelectOption | undefined>,
  stateField?: FieldInputProps<SelectOption | undefined>
) => {
  let dependenciesOptions = {};

  if (countryField?.value && stateField?.value) {
    dependenciesOptions = {
      country: [countryField.value.value],
      state: [stateField.value.value],
    };
  } else if (countryField?.value) {
    dependenciesOptions = { country: [countryField.value.value] };
  } else if (stateField?.value) {
    dependenciesOptions = { state: [stateField.value.value] };
  }

  return dependenciesOptions;
};

export const getNewRoute = (
  addedCompany: AddedCompany,
  state: RouteRedirectStateProps | null,
  search: string,
  isSLCadence: boolean = false
) => {
  if (isSLCadence) {
    const searchParams = stringify(
      {
        id: addedCompany.company_id,
        name: addedCompany.company_name,
        url: addedCompany.company_url,
      },
      { arrayFormat: 'comma' }
    );

    return {
      pathname: getAddToSalesLoftURL(addedCompany.company_id),
      search: searchParams,
    };
  }

  return {
    pathname: getCompanyProfileURL(addedCompany.company_id),
    search,
    state,
  };
};

export const getDataFromPlugin = () => {
  const searchParams = parse(window.location.search);
  const addCompany = Boolean(searchParams.addCompany);
  const companyUrl = String(searchParams.companyUrl);

  return { addCompany, companyUrl };
};
