import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// utils
import {
  getMultiSelectOptions,
  orderAnalystsByCustom,
} from '@optx/utils/filters/mapFiltersOptions';
// models
import { MultiSelectFilterColumns } from '@optx/models/feature/analystsLeaderboard/filters';
import { SelectOption } from '@models/Option';
// redux
import { selectors, actions } from '@features/pipeline-report/state';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';

interface FilterMultiSelectProps extends MultiSelectProps {
  filterKey: MultiSelectFilterColumns;
}

export const AnalystsMultiSelect: React.FC<FilterMultiSelectProps> = ({
  filterKey,
  ...restProps
}) => {
  const [options, setOptions] = useState<Array<SelectOption>>();
  const [allowAnalystsEditing, setAllowAnalystsEditing] = useState<boolean>();
  const dispatch = useDispatch();

  const {
    normalized: { company_owner_id: normalizedData },
  } = useSelector(selectors.getFilters);
  const { company_owner_id: values } = useSelector(selectors.getValues);

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = orderAnalystsByCustom(
        getMultiSelectOptions(normalizedData as any) as any
      );

      setOptions(sortedOptions);
      setAllowAnalystsEditing(normalizedData.data.length >= 1);
    }
  }, [normalizedData]);

  const updateValue = useCallback(
    (newValue: Array<SelectOption>) => {
      dispatch(actions.updateValue({ filter: { value: newValue, key: filterKey } }));
    },
    [dispatch, filterKey]
  );

  const { localValue, onBlur, onChange } = useLocalMultiSelect(values as any, updateValue);

  return (
    <MultiSelectInline
      options={options}
      onBlur={onBlur}
      onChange={onChange}
      value={localValue}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      allPrefix={normalizedData ? normalizedData.placeholders : undefined}
      isDisabled={!allowAnalystsEditing}
      {...restProps}
    />
  );
};

export default AnalystsMultiSelect;
