import React, { useState } from 'react';
import { Result, Progress, Button, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as bulkSalesloftActions } from '@features/bulk-actions/salesloft';
import { actions as loaderActions } from '@features/custom-global-loader';

const ProgressWrapper = styled(Progress)`
  text-align: center;

  .ant-progress-outer {
    width: 50%;
  }

  .ant-progress-inner {
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

const ResultWrapper = styled(Result)`
  padding: 48px 32px;
`;

const InProgress: React.FC = () => {
  const dispatch = useDispatch();
  const percentage = useSelector(bulkActionsSelectors.salesloft.percentageCompleted);

  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(true);
  };

  const handleOk = () => {
    dispatch(bulkSalesloftActions.cancel(true));
    dispatch(loaderActions.toggle({ loading: true, customText: 'Canceling...' }));
    setVisible(false);
  };

  const handleCancelation = (event: React.MouseEvent<HTMLElement>) => {
    if (event && event.currentTarget) {
      event.currentTarget.blur();
    }

    setVisible(false);
  };

  return (
    <>
      <ProgressWrapper percent={percentage} showInfo={false} />
      <ResultWrapper
        key={'sl-bulk-inprogress-key'}
        status="success"
        title="In Progress"
        subTitle="Depending on the number of companies, this may take awhile"
        icon={<></>}
        extra={[<Button onClick={handleCancel}>Cancel</Button>]}
      />
      <Modal title="Confirmation" visible={visible} onOk={handleOk} onCancel={handleCancelation}>
        Are you sure you want to cancel the operation?
      </Modal>
    </>
  );
};

export default InProgress;
