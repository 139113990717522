import React, { useState } from 'react';
import { Menu, Tooltip, Popconfirm } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
// constants
import { COMPANY_CSV_SIZE_LIMIT } from '@constants/limit';
import { columns } from '@optx/components/pages/CompanyProfile/ProfileTabs/Addon/table/defaultColumns';
//services
import { CompanyService } from '@optx/services/api';
// utils
import { mapSortFieldQuery } from '@utils/mapSortFieldQuery';
import { mapSortOrderQuery } from '@utils/mapSortOrderQuery';
import mapFiltersToURLParams from '@utils/filters/mapFiltersToURLParams';
import { exportFileCSV } from '@utils/csvHandler';
// redux
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as profileSelectors } from '@redux/company/profile';
import { selectors as addonSelectors } from '@redux/company/addon-management';
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as searchSelectors } from '@features/grid/search';

const FILE_NAME = 'saved addons';

interface ExportToCSVProps {
  hideDropdown?: () => void;
}

const ExportToCSV = ({ hideDropdown, onClick, ...restProps }: MenuItemProps & ExportToCSVProps) => {
  const searchCompanyCount = useSelector(searchCountSelectors.getSearchCount('addons'));
  const searchHasValues = useSelector(addonSelectors.searchHasValues);
  const filter = useSelector(filterSelectors.getFilter('addons'));
  const searchKey = useSelector(searchSelectors.getSearchKey('addons'));
  const sortBy = useSelector(addonSelectors.getSorting);
  const filtersMap = useSelector(filterSourcesSelectors.getFiltersMap);
  const companyId = useSelector(profileSelectors.getCompanyId);
  const additionalFilterQuery = useSelector(addonSelectors.getAdditionalFiltersQuery);
  const [isLoading, setIsLoading] = useState(false);

  if (!searchHasValues) {
    return null;
  }

  const handleExportCSV = (includeDescription: boolean) => {
    if (companyId !== undefined) {
      let params = mapFiltersToURLParams(filtersMap, filter);
      params = { ...params, ...additionalFilterQuery };

      const paramsQuery = queryString.stringify(
        { ...params, ...(searchKey ? { query: searchKey } : {}), company_id: companyId },
        { arrayFormat: 'comma' }
      );

      const parameterFilter = {
        filters: paramsQuery,
        sortFields: queryString.stringify(
          { sort_fields: sortBy.map(mapSortFieldQuery) },
          { arrayFormat: 'comma' }
        ),
        sortOrders: queryString.stringify(
          {
            sort_orders: sortBy.map(mapSortOrderQuery),
          },
          { arrayFormat: 'comma' }
        ),
      };

      setIsLoading(true);

      exportFileCSV(
        FILE_NAME,
        parameterFilter,
        columns,
        CompanyService.exportCompanyAddonsToCSV,
        includeDescription,
        false
      ).then(() => {
        setIsLoading(false);
      });
    }
  };

  const disabled = !searchCompanyCount || searchCompanyCount > COMPANY_CSV_SIZE_LIMIT || isLoading;

  const handleClick = (includeDescription = false) => {
    if (!disabled) {
      handleExportCSV(includeDescription);
      hideDropdown && hideDropdown();
    }
  };

  //   wrap Menu.Item in span to show tooltip on hovering while Menu.Iten is disabled.
  return (
    <Tooltip title="Up to 100K rows" placement="left">
      <span>
        <Popconfirm
          title="Include the description?"
          onConfirm={() => handleClick(true)}
          onCancel={() => handleClick()}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item key="export-to-csv-menu" disabled={disabled} {...restProps}>
            Export to .csv
          </Menu.Item>
        </Popconfirm>
      </span>
    </Tooltip>
  );
};

export default React.memo(ExportToCSV);
