import { useContext, useEffect, useState, useCallback } from 'react';
import { Tabs, Typography, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
// models
import { CompanyProfile, FoundCompany } from '@models/Company';
// constants
import { TOUCHES_PER_PAGE } from '@features/long-card/company-card/constants/touches';
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// utils
import { iframePostMessage } from '@optx/utils/window';
// redux
import { actions as employeGrowthActions } from '@features/company/graphs/employee';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';
import { actions as opportunityActions } from '@redux/company/opportunity-presentation';
import { actions, selectors, TouchesTimeline } from '@features/company-touches';
import {
  actions as cardActions,
  selectors as cardSelectors,
} from '@features/long-card/company-card';
import * as authActions from '@redux/auth/authentication/actions';
// hooks
import useIsAnalyst from '@optx/common/hooks/useIsAnalyst';
// components
import NewsFeed from '@components/pages/CompanyProfile/ProfileTabs/NewsFeed';
import Lists from '@optx/components/pages/CompanyProfile/ProfileTabs/Lists';
import { LoadingOutlined } from '@ant-design/icons';
import { OPTXInsights } from './optx-insights';
import CardPannelGraphs from './CardPannelGraphs';
import CardPannelHeader from './CardPannelHeader';
import ChromeExtensionHeader from './Header';
import { Funding } from './funding';
import { Contacts } from './contacts';
import { Context } from '../ChromeExtensionContext';
import { ProprietaryInfo } from './propietary-info';
import { RelationshipMGMTSummary } from './relationship-management';
import CompanyNotFound from '@assets/images/company-not-found-plugin.png';
// styled
import { Styled } from './styled/CardPannel.styled';
import { ChromeExtensionSection } from './styled/ChromeExtension.styled';

const { TabPane } = Tabs;

const TABS = {
  stats: '1',
  touches: '2',
  funding: '3',
  proprietaryInfo: '4',
  contacts: '5',
  lists: '6',
  news: '7',
};

const ExtensionProfile = () => {
  const { foundCompanies, setCompaniesLoading } = useContext(Context);
  const { advancedSearchPathname } = useIsAnalyst();
  const company = useSelector(cardSelectors.getProfile);
  const contacts = useSelector(cardSelectors.getProfileContacts);
  // has touches
  const pagination = useSelector(selectors.search.pagination);
  const totalTouches = useSelector(selectors.search.total);
  const touchesCountLoading = useSelector(selectors.search.isLoading);

  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState<FoundCompany>();

  const getScoreGrowth = useCallback(
    (companyId?: number) => {
      dispatch(
        scoreGrowthActions.fetchScoreGrowth({
          company_id: companyId ?? (company?.company_id as number),
          company_url: company?.company_url as string,
        })
      );
    },
    [dispatch, company]
  );

  const onCompanyChange = (companyId: number) => {
    setCompaniesLoading(true);
    setSelectedCompany(foundCompanies.filter(match => match.company_id === companyId)[0]);

    dispatch(cardActions.fetchCompanyProfile(companyId, () => setCompaniesLoading(false)));
    getScoreGrowth(companyId);

    dispatch(employeGrowthActions.fetchEmployeeGrowth(companyId));
    dispatch(fundingRoundsActions.getCompanyFundingRounds(companyId));
    dispatch(opportunityActions.fetchOpportunityPresentation(companyId));
    dispatch(actions.filters.fetchTouchesFilters(companyId));
    dispatch(
      actions.search.fetchTouches({
        companyId,
        pagination: { ...pagination, pageSize: TOUCHES_PER_PAGE, pageNumber: 1 },
      })
    );
  };

  const onCloseClick = () => {
    iframePostMessage('collapse', undefined);
    window.pendo.track(OPTXChromePlugin.topHeader.xout);
  };

  const logOut = () => {
    window.pendo.track(OPTXChromePlugin.topHeader.actions.logout);
    dispatch(authActions.logOut({ isChromeExtenison: false }));
  };

  const getAddCompanyRedirectURL = () => {
    const params = stringify({
      addCompany: true,
      companyUrl: window.name,
    });

    return `${advancedSearchPathname}?${params}`;
  };

  useEffect(() => {
    setSelectedCompany(foundCompanies[0]);
  }, [foundCompanies]);

  useEffect(() => {
    const companyId = selectedCompany?.company_id;

    if (companyId && contacts.length) {
      const isPrimary = contacts.some(item => item.primary_contact);

      if (isPrimary) {
        dispatch(cardActions.fetchPluginPrimaryContact({ companyId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    if (company?.company_id) {
      getScoreGrowth();
    }
  }, [company, getScoreGrowth]);

  const handleTabPendoAction = (value: string) => {
    const currentTab = Object.keys(TABS).find(key => TABS[key as keyof typeof TABS] === value);

    const trackValue =
      OPTXChromePlugin.companyPage[currentTab as keyof typeof OPTXChromePlugin.companyPage];

    if (trackValue) {
      window.pendo.track(trackValue);
    }
  };

  return (
    <>
      <ChromeExtensionHeader
        foundCompanies={foundCompanies}
        selectedCompany={company}
        onCloseClick={onCloseClick}
        onLogOut={logOut}
      />
      <Styled.CardPannelWrapper
        className={company && foundCompanies.length ? undefined : 'no-company'}
      >
        {company && foundCompanies.length ? (
          <>
            <CardPannelHeader
              company={company as CompanyProfile}
              companyId={company.company_id}
              matches={foundCompanies.filter(
                (record: FoundCompany) => record.company_id !== company.company_id
              )}
              onCompanyChange={onCompanyChange}
              name={company.company_name}
              logo={company.logo_url !== '-' ? company.logo_url : null}
              country={company.country ?? ''}
              state={company.state ?? ''}
              city={company.city ?? ''}
              street={company.street ?? ''}
              owner={
                company.company_owner !== 'Unowned'
                  ? {
                      name: company.company_owner,
                      image: company.owner_img,
                      id: company.company_owner_id,
                    }
                  : null
              }
              isInEt={company.source_tag.includes('et')}
            />

            <Tabs
              className="extension-tabs"
              tabPosition="top"
              moreIcon={<Styled.MoreIcon>MORE</Styled.MoreIcon>}
              animated={false}
              onChange={value => handleTabPendoAction(value)}
            >
              <TabPane tab="OPTX STATS" key={TABS.stats} style={{ width: '100%' }}>
                <ChromeExtensionSection>
                  <OPTXInsights companyProfile={company as CompanyProfile} />
                </ChromeExtensionSection>
                <RelationshipMGMTSummary company={company as CompanyProfile} />
                <ChromeExtensionSection>
                  <CardPannelGraphs companyProfile={company as CompanyProfile} shouldUpdate />
                </ChromeExtensionSection>
              </TabPane>

              <TabPane
                tab={
                  <Styled.TouchesTabContainer>
                    <span>TOUCHES (</span>
                    {touchesCountLoading ? (
                      <LoadingOutlined
                        style={{ color: '#4ce1b6', marginLeft: 4, marginRight: 4 }}
                      />
                    ) : (
                      totalTouches
                    )}
                    <span>)</span>
                  </Styled.TouchesTabContainer>
                }
                key={TABS.touches}
                style={{ width: '100%' }}
              >
                <TouchesTimeline company={company} isExtension={true} />
              </TabPane>

              <TabPane tab="FUNDING" key={TABS.funding} style={{ width: '100%' }}>
                <Funding companyProfile={company as CompanyProfile} />
              </TabPane>

              <TabPane tab="PROPRIETARY INFO" key={TABS.proprietaryInfo} style={{ width: '100%' }}>
                <ProprietaryInfo company={company as CompanyProfile} />
              </TabPane>

              <TabPane tab="CONTACTS" key={TABS.contacts} style={{ width: '100%' }}>
                <Contacts company={company} />
              </TabPane>

              <TabPane tab="LISTS" key={TABS.lists} style={{ width: '100%' }}>
                <Lists companyId={company.company_id} isExtension />
              </TabPane>

              <TabPane tab="NEWS" key={TABS.news} style={{ width: '100%' }}>
                <NewsFeed company={company} />
              </TabPane>
            </Tabs>
          </>
        ) : (
          <div className="no-company">
            <div className="add-company-container">
              <Typography.Text className="not-found-label">
                Company not found in OPTX
              </Typography.Text>
              <Link to={getAddCompanyRedirectURL} target="_blank">
                <Button type="primary">Add to OPTX</Button>
              </Link>
            </div>

            <div className="company-not-found-container">
              <div className="image-container">
                <img src={CompanyNotFound} alt="company-not-found" />
              </div>
            </div>
          </div>
        )}
      </Styled.CardPannelWrapper>
    </>
  );
};

export default ExtensionProfile;
