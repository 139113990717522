import { debounce } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';

const PEN_ICON_WIDTH = 16;
const SEPARATOR_WIDTH = 7.4;
const ELLIPSIS_TEXT = '... ';
const FONT_TYPE = '14px SF Pro Display';

const getTextWidth = (() => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  return (text: string, font: string) => {
    if (context) {
      context.font = font;

      return context.measureText(text).width;
    }

    return 0;
  };
})();

const getFinalText = (text: string, containerWidth: number, remainingItemsNr: number) => {
  const additionalText =
    remainingItemsNr < 1 ? ELLIPSIS_TEXT : `${ELLIPSIS_TEXT}(${remainingItemsNr} more)`;
  const additionalTextWidth = getTextWidth(additionalText, FONT_TYPE);

  for (let c = 0; c < text.length; c++) {
    const subStrWidth = getTextWidth(text.substring(0, c), FONT_TYPE);
    const adjustedTextWidth = Math.ceil(subStrWidth + additionalTextWidth);

    if (adjustedTextWidth >= containerWidth) {
      const newText = text.substring(0, c - 1);

      return newText.endsWith('; ') || newText.endsWith(';')
        ? newText + `${ELLIPSIS_TEXT}(${remainingItemsNr + 1} more)`
        : newText + additionalText;
    } else if (c === text.length - 1) {
      return remainingItemsNr >= 1 ? text + additionalText : text;
    }
  }

  return '';
};

interface DynamicListDisplayProps {
  list: string[];
}

const DynamicListDisplay: React.FC<DynamicListDisplayProps> = ({ list }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState<string>('');

  useEffect(() => {
    const currentRefParent = containerRef.current?.parentElement;
    const containerWidth = currentRefParent!.offsetWidth - PEN_ICON_WIDTH || 0;

    const updateVisibleNames = () => {
      if (!currentRefParent) return;

      if (list.length === 1) {
        const nameWidth = getTextWidth(list[0], FONT_TYPE);

        setText(nameWidth > containerWidth ? getFinalText(list[0], containerWidth, 0) : list[0]);

        return;
      }

      for (let i = 0; i < list.length; i++) {
        const item = list.slice(0, i + 1).join('; ');
        const nameWidth = getTextWidth(item, FONT_TYPE);
        const remainingItems = list.length - (i + 1);
        const additionalText =
          i === list.length - 1 ? ELLIPSIS_TEXT : `${ELLIPSIS_TEXT}(${remainingItems} more)`;
        const additionalTextWidth = getTextWidth(additionalText, FONT_TYPE);
        const testWidth = Math.ceil(nameWidth + additionalTextWidth + i * SEPARATOR_WIDTH);

        if (testWidth > containerWidth) {
          setText(getFinalText(item, containerWidth - i * SEPARATOR_WIDTH, remainingItems));
          break;
        } else if (i === list.length - 1) {
          setText(item);
        }
      }
    };

    updateVisibleNames();

    const handleResize = debounce(updateVisibleNames, 100);
    let resizeObserver: ResizeObserver | null = null;

    if (currentRefParent) {
      resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(currentRefParent);
    }

    return () => {
      if (resizeObserver && currentRefParent) {
        resizeObserver.unobserve(currentRefParent);
      }
    };
  }, [list]);

  return (
    <span ref={containerRef} style={{ cursor: 'pointer' }} data-full-list={list.join(';')}>
      {text}
    </span>
  );
};

export default DynamicListDisplay;
