import styled from 'styled-components';
import { Col } from 'antd';

const InputFieldWrapper = styled(Col)`
  &.raw_url {
    flex: 0 0 72%;
    max-width: 72%;
  }
`;

export default {
  InputFieldWrapper,
};
