import React, { useState, useEffect, FC } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// constants
import globalConfig from '@optx/constants/config';
// models
import { DateRangeOption } from '@optx/models/Option';
// redux
import {
  getCards,
  getSelectedPeriod,
  getSelectedDate,
  getCardsCount,
} from '@optx/redux/scheduled-touches/selectors';
import { changeSelectedDate, changeSelectedPeriod } from '@optx/redux/scheduled-touches/actions';
// style
import Styled from './Cards.style';

interface CardsProps {
  selectedCard: string;
  setSelectedCard: React.Dispatch<React.SetStateAction<string>>;
}

const dateFilters = {
  Today: ['Today', 'Yesterday'],
  'This Week': ['This Week', 'Last Week'],
  'This Month': ['This Month', 'Last Month'],
  'This Quarter': ['This Quarter', 'Last Quarter'],
  'All Time': ['All Time'],
  'Custom Range': ['Custom Range'],
};

const Cards: FC<CardsProps> = ({ selectedCard, setSelectedCard }) => {
  const allCards = useSelector(getCards);
  const selectedPeriod = useSelector(getSelectedPeriod);
  const selectedDate = useSelector(getSelectedDate);
  const count = useSelector(getCardsCount);
  const [cards, setCards] = useState<DateRangeOption[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let cardKey: string = 'Custom Range';

    Object.entries(dateFilters).forEach(([key, values]) => {
      if (values.includes(selectedPeriod)) {
        cardKey = key;
      }

      return false;
    });

    setCards(allCards[cardKey as string]);
    setSelectedCard(selectedPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod, allCards]);

  const onClickCard = (card: DateRangeOption) => {
    if (selectedCard !== card?.label) {
      setSelectedCard(card.label!);
      dispatch(changeSelectedPeriod(card.label!));
      dispatch(changeSelectedDate([card.start, card.end]));
    }
  };

  const showDate = (card: DateRangeOption) => {
    switch (cards.length) {
      case 1: {
        const dateExists = selectedDate.every(date => date !== null);

        if (dateExists) {
          if (selectedDate?.[0] === selectedDate?.[1]) {
            return moment(new Date(selectedDate?.[0]!)).format(globalConfig.short_date.DATE_FORMAT);
          }

          return `${moment(selectedDate?.[0]).format(
            globalConfig.short_date.DATE_FORMAT
          )} - ${moment(selectedDate?.[1]).format(globalConfig.short_date.DATE_FORMAT)}`;
        }

        break;
      }

      case 2: {
        if (card.start === card.end) return moment(new Date(card.start)).format('dddd MMMM Do');

        return `${moment(card.start).format(globalConfig.short_date.DATE_FORMAT)} - ${moment(
          card.end
        ).format(globalConfig.short_date.DATE_FORMAT)}`;
      }

      default:
        return '';
    }

    return '';
  };

  return (
    <div className="d-flex" style={{ marginTop: '30px' }}>
      {cards &&
        cards.map((card, index) => {
          return (
            <Styled.InfoCard
              $highlighted={selectedCard === card.label}
              data-content={card.label}
              onClick={() => onClickCard(card)}
              key={`${card.label} ${index}`}
            >
              <div className="header d-flex">
                <CalendarOutlined style={{ fontSize: '46px', paddingRight: '8px' }} />
                <span className="d-flex flex-column">
                  <span className="day">{card.label}</span>
                  <span style={{ whiteSpace: 'nowrap' }}>{showDate(card)}</span>
                </span>
              </div>
              <div className="stats">
                <span className="d-flex flex-column">
                  <span className="category">SCHEDULED</span>
                  <span className="value">{count?.[card.label!]?.scheduled || 0}</span>
                </span>
                <span className="d-flex flex-column">
                  <span className="category">PASTDUE</span>
                  <span className="value red">{count?.[card.label!]?.past_due || 0}</span>
                </span>
              </div>
            </Styled.InfoCard>
          );
        })}
    </div>
  );
};

export default Cards;
