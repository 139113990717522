import React from 'react';
import { ColumnType } from 'antd/lib/table';
// constants
import globalConfig from '@optx/constants/config';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import {
  YEARS_LABELS,
  generateArrCurrentColumnKey,
  generateEbitdaCurrentColumnKey,
  generateGrowthCurrentColumnKey,
} from '@optx/constants/search';
import {
  DEFAULT_CELL_VALUE,
  DEFAULT_LAST_TOUCH,
  DEFAULT_NEXT_TOUCH,
} from '@optx/constants/table/cells';
import { NUMBER_FORMAT } from '@optx/constants/format/number';
import { NEXT_STEPS_OPINION_ENDPOINT } from '@constants/asyncEndpoints';
// models
import { CompanyKeys } from '@optx/models/table/Columns';
import Company, {
  CompanyProfile,
  CompanySourceTag,
  URLStatus,
  ProductCategory,
  CompanyUserValuesKeys,
} from '@optx/models/Company';
// utils
import { roundNumber } from '@optx/utils/number';
// components
import {
  EditFieldMultiSelect,
  EditFieldMultiSelectAsyncProductCategory,
  EditFieldMultiSelectAsyncCreatable,
  EditFieldRadio,
  EditFieldSingleSelect,
  EditFieldCheckboxText,
  EditFieldSingleSelectOther,
  EditFieldText,
  EditFieldDate,
} from '@optx/features/company/edit-fields/components';
import { CompanyService, UserService } from '@optx/services/api';
import {
  TableCellListDefault,
  TableCellNumber,
  TableCellDate,
  TableCellFormatMoney,
  TableCellListDate,
} from '@optx/components/common/table/AntTable/cells';
import {
  TableCellActions,
  TableCellPercentageGrowth,
  TableCellStringList,
  TableCellDescription,
  TableCellScoreAdmin,
  TableCellRadioAdmin,
  TableCellOPTXScore,
  TableCellInvestors,
  TableCellAddon,
  TableCellDealTeams,
} from '@optx/components/common/table/Companies/cells';
import {
  TableCellName,
  TableCellCompanyURL,
} from '@optx/components/common/table/Companies/styled-cells';
import { TableCellEditFinancialField } from '../cells/TableCellEditFinancialField';
import TableCellDefaultRender from '../../AntTable/cells/TableCellDefault';

export interface CompanyColumnType extends ColumnType<CompanyProfile> {
  dataIndex: CompanyKeys;
}

export const columns: Array<CompanyColumnType> = [
  {
    dataIndex: 'company_url',
    title: '',
    className: 'company_url',
    render: (value: string | null, record: Company, index: number) => (
      <TableCellCompanyURL value={value} record={record} />
    ),
    width: 42,
    fixed: 'left',
    align: 'center',
  },
  {
    dataIndex: 'company_name',
    title: 'Company Name',
    className: 'company_name',
    sorter: true,
    render: (value: string, record: CompanyProfile, index: number) => (
      <TableCellName value={value} record={record} />
    ),
    width: 240,
    fixed: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'score',
    title: 'OPTX Score',
    className: 'score',
    sorter: true,
    width: 150,
    render: (value: number, record: Company) => (
      <TableCellScoreAdmin record={record}>
        <TableCellOPTXScore company={record} />
      </TableCellScoreAdmin>
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'score_v3',
    title: 'OPTX Score BETA',
    className: 'score_v3',
    sorter: true,
    width: 150,
    render: (value: number, record: Company) => (
      <TableCellScoreAdmin record={record} isNewScore>
        <TableCellOPTXScore company={record} isNewScore />
      </TableCellScoreAdmin>
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'il_optx_score',
    title: 'IL OPTX Score',
    className: 'il_optx_score',
    sorter: true,
    width: 150,
    render: (value: number, record: Company) => (
      <TableCellScoreAdmin record={record}>
        <TableCellOPTXScore company={record} />
      </TableCellScoreAdmin>
    ),
    fixed: 'left',
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'parentcompany',
    className: 'parentcompany',
    title: 'Parent Company',
    sorter: true,
    render: TableCellDefaultRender,
    width: 240,
    fixed: false,
    ellipsis: true,
  },
  {
    dataIndex: 'is_in_et',
    title: 'In Equity Touch',
    className: 'is_in_et',
    sorter: true,
    render: (value: boolean, record: Company, index: number) => (value ? 'Yes' : 'No'),
    width: 85,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'company_description',
    title: 'Description',
    className: 'company_description',
    sorter: false,
    render: (value: string, record: Company, index: number) => (
      <TableCellDescription value={value} record={record} />
    ),
    width: 520,
    ellipsis: true,
  },
  {
    dataIndex: 'year_founded',
    title: 'Year Founded',
    className: 'year_founded',
    sorter: true,
    width: 90,
    align: 'left',
    ellipsis: true,
    render: (value: boolean | null, record: Company, index: number) => (
      <EditFieldSingleSelect
        fieldName="Year Founded"
        fieldKey="year_founded"
        value={record.year_founded === 'None' ? DEFAULT_CELL_VALUE : record.year_founded}
        record={record}
        service={CompanyService.updateCompanyData}
        successMessage="Company year founded updated successfully!"
        errorMessage="Company year founded update failed, Server error!"
        isFromGrid
      />
    ),
  },
  {
    dataIndex: 'is_software',
    title: 'Software',
    className: 'is_software',
    sorter: true,
    width: 85,
    render: (value: boolean | null, record: Company, index: number) => (
      <TableCellRadioAdmin
        value={value}
        record={record}
        fieldName="Software Company"
        service={UserService.addCompanySoftwareEvaluation}
        successMessage="Company software company updated successfully!"
        errorMessage="Failed to update value!"
        destroyOnHide
        isFromGrid
      />
    ),
  },
  {
    dataIndex: 'is_interesting',
    title: 'PSG Fit',
    className: 'is_interesting',
    sorter: true,
    render: (value: boolean | null, record: Company, index: number) => (
      <TableCellRadioAdmin
        value={value}
        record={record}
        fieldName="PSG Fit"
        service={UserService.updateFitEvaluation}
        successMessage="Company PSG fit updated successfully!"
        errorMessage="Failed to update value!"
        destroyOnHide
        isFromGrid
      />
    ),
    width: 110,
    align: 'center',
    ellipsis: true,
  },
  {
    dataIndex: 'diversity',
    title: 'Diversity Spotlight',
    className: 'diversity',
    sorter: true,
    render: TableCellStringList,
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'addon_bool',
    title: 'Show Add-Ons',
    className: 'addon_bool',
    sorter: true,
    render: (value: boolean) => (value ? 'Yes' : 'No'),
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'addon',
    title: 'Add-On for a Specific Company',
    className: 'addon',
    sorter: true,
    render: (value, record) => <TableCellAddon value={value} record={record} />,
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'date_presented',
    title: 'Date Presented',
    className: 'date_presented',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return (
        <EditFieldDate
          record={record}
          value={record.date_presented}
          fieldKey="date_presented"
          isFromGrid
          service={CompanyService.updateDatePresented}
          successMessage="Success updating company presentation!"
          fieldName="Date Presented"
          destroyOnHide
          format={globalConfig.short_date.DATE_FORMAT}
        />
      );
    },
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'company_owner',
    title: 'Company Owner',
    className: 'company_owner',
    sorter: true,
    render: (value: string | null, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          value={value}
          record={record}
          fieldName="Company Owner"
          fieldKey="deal_team_leader"
          successMessage="Company owner updated successfully!"
          errorMessage="Company owner update failed, Server error!"
          service={CompanyService.updateCompanyData}
        />
      ) : (
        value
      ),
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'senior_advisor_thesis',
    title: 'Senior Advisor Thesis',
    className: 'senior_advisor_thesis',
    sorter: true,
    render: (value: string | null, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          value={value}
          record={record}
          fieldName="Senior Advisor Thesis"
          successMessage="Senior Advisor Thesis updated successfully!"
          errorMessage="Senior Advisor Thesis update failed, Server error!"
          service={CompanyService.updateCompanyData}
          fieldKey="senior_advisor_thesis"
        />
      ) : (
        value
      ),
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'lead_source',
    title: 'Lead Source',
    className: 'lead_source',
    sorter: true,
    render: (value: string | null, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          value={value}
          record={record}
          service={CompanyService.updateCompanyData}
          successMessage="Lead Source updated successfully!"
          errorMessage="Lead Source update failed, Server error!"
          fieldName="Lead Source"
          fieldKey="lead_source"
        />
      ) : (
        value
      ),
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'pipeline_rank',
    title: 'Pipeline Rank',
    className: 'pipeline_rank',
    sorter: true,
    render: (value: number, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          fieldName="Pipeline Rank"
          value={value}
          service={CompanyService.updateCompanyData}
          successMessage="Pipeline rank updated successfully!"
          errorMessage="Pipeline rank update failed, Server error!"
          record={record}
          fieldKey={'rank' as CompanyUserValuesKeys}
        />
      ) : (
        <span className="not_editable">{value}</span>
      ),
    width: 85,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'stage',
    title: 'Stage',
    className: 'stage',
    sorter: true,
    render: (value: string, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          service={CompanyService.updateCompanyData}
          fieldName="Stage"
          value={value}
          successMessage="Company stage updated successfully!"
          errorMessage="Company stage update failed, Server error!"
          record={record}
          fieldKey="stage"
        />
      ) : (
        value
      ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'fund',
    title: 'Fund',
    className: 'fund',
    sorter: true,
    render: (value: string | null, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          fieldName="Fund"
          fieldKey="fund"
          service={CompanyService.updateCompanyData}
          value={value}
          successMessage="Company fund updated successfully!"
          errorMessage="Company fund update failed, Server error!"
          record={record}
        />
      ) : (
        value
      ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'num_employees',
    title: 'Number of Employees',
    className: 'num_employees',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={CompanyService.updateCompanyData}
        fieldName="Number of Employees"
      />
    ),
    width: 140,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'emp_growth_percent',
    title: 'Employee Growth Percentage Y/Y',
    className: 'emp_growth_percent',
    sorter: true,
    render: (value: number | string, record: Company, index: number) =>
      value !== null && value !== '-' ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
    width: 150,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'job_openings',
    title: 'Job Openings',
    className: 'job_openings',
    sorter: true,
    width: 90,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'job_openings_percent',
    title: 'Job Openings %',
    className: 'job_openings_percent',
    sorter: true,
    render: (value: string, record: Company, index: number) => (
      <TableCellPercentageGrowth value={value} record={record} />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'sw_website_rank',
    title: 'WW Website Rank',
    className: 'sw_website_rank',
    sorter: true,
    render: (value: string, record: Company, index: number) => (
      <TableCellNumber value={value} record={record} />
    ),
    width: 140,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'sw_website_rank_per_country',
    title: 'US Website Rank',
    className: 'sw_website_rank_per_country',
    sorter: true,
    render: (value: string, record: Company, index: number) => (
      <TableCellNumber value={value} record={record} />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'opportunity_type',
    title: 'Opportunity Type',
    className: 'opportunity_type',
    sorter: true,
    width: 110,
    ellipsis: true,
    render: (value: string, company: Company) =>
      company.source_tag.includes('et') ? (
        <EditFieldSingleSelect
          isFromGrid
          service={CompanyService.updateCompanyData}
          fieldName="Opportunity Type"
          fieldKey="opportunity_type"
          value={value}
          successMessage="Opportunity Type updated successfully!"
          errorMessage="Opportunity Type  update failed, Server error!"
          record={company}
        />
      ) : (
        value
      ),
  },
  {
    dataIndex: 'company_type',
    title: 'Company Type',
    className: 'company_type',
    sorter: true,
    width: 110,
    ellipsis: true,
    render: (value: string, company: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        service={CompanyService.updateCompanyData}
        fieldName="Company Type"
        fieldKey="company_type"
        value={company.company_type || DEFAULT_EMPTY_VALUE}
        successMessage="Company Type updated successfully!"
        errorMessage="Company Type update failed, Server error!"
        record={company}
      />
    ),
  },
  {
    dataIndex: 'sector',
    title: 'Sector',
    className: 'sector',
    sorter: true,
    render: (value: string, company: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        fieldName="Sector"
        fieldKey="sector"
        value={value === 'None' ? DEFAULT_EMPTY_VALUE : value}
        service={CompanyService.updateCompanyData}
        successMessage="Company sector updated successfully!"
        errorMessage="Company sector update failed, Server error!"
        record={company}
      />
    ),
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'sub_sector',
    title: 'Sub-sector',
    className: 'sub_sector',
    sorter: true,
    render: (value: string, company: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        value={value === 'None' ? DEFAULT_EMPTY_VALUE : value}
        record={company}
        service={CompanyService.updateCompanyData}
        successMessage="Company sub-sector updated successfully!"
        errorMessage="Company sub-sector update failed, Server error!"
        fieldName="Sub-Sector"
        fieldKey="sub_sector"
      />
    ),
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'product_category',
    title: 'Product Category',
    className: 'product_category',
    sorter: true,
    render: (value: ProductCategory[], company: Company) => (
      <EditFieldMultiSelectAsyncProductCategory
        fieldName="Product Category"
        value={value}
        record={company}
        service={CompanyService.updateCompanyData}
        fieldKey="product_category"
        asyncSearchEndpoint="/search/company_product_category"
        successMessage="Product category updated successfully!"
        isFromGrid
      />
    ),
    width: 100,
    ellipsis: true,
  },

  {
    dataIndex: 'sub_vertical',
    title: 'Sub Vertical',
    className: 'sub_vertical',
    sorter: true,
    render: (value: string[], company: Company) => (
      <EditFieldMultiSelectAsyncCreatable
        fieldName="Sub Vertical"
        value={value}
        record={company}
        service={UserService.subVertical}
        fieldKey={'sub_vertical' as CompanyUserValuesKeys}
        asyncSearchEndpoint="/search/sub_vertical"
        successMessage="Sub Vertical updated successfully!"
        errorMessage="Sub Vertical update failed, Server error!"
        isFromGrid
      />
    ),
    width: 250,
    ellipsis: true,
  },
  {
    dataIndex: 'raise_date',
    title: 'Last Funding Date',
    className: 'raise_date',
    sorter: true,
    render: (value: string, record: CompanyProfile) => (
      <EditFieldDate
        value={value}
        fieldKey="raise_date"
        fieldName="Last Funding Date"
        service={UserService.updateCompanyUserValues}
        isFromGrid
        record={record}
        destroyOnHide
        format={globalConfig.grid_date.DATE_FORMAT}
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'last_round',
    title: 'Last Round',
    className: 'last_round',
    sorter: true,
    render: TableCellDefaultRender,
    width: 115,
    ellipsis: true,
  },
  {
    dataIndex: 'last_raised_amount',
    title: 'Last Raised Amount',
    className: 'last_raised_amount',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={UserService.updateLastRaisedAmount}
        fieldName="Last Raised Amount"
      />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'active_investors',
    title: 'Active Investors',
    className: 'active_investors',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellInvestors value={value} record={record} />
    ),
    width: 200,
    ellipsis: true,
  },
  {
    dataIndex: 'capital_raised',
    title: 'Total Raised',
    className: 'capital_raised',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={UserService.updateCompanyUserValues}
        fieldName="Capital Raised"
      />
    ),
    width: 210,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_rev_update_amount',
    title: 'Revenue',
    className: 'last_rev_update_amount',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="revenue_list"
        fieldName="Revenue"
        filterName="last_rev_update_amount"
        extraKeys={['last_rev_update_amount', 'last_rev_update_year']}
        service={UserService.updateCompanyUserValues}
        isFromGrid={true}
      />
    ),
    width: 130,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_gross_margin',
    title: 'GM %',
    className: 'last_gross_margin',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="gross_margin_list"
        fieldName="GM %"
        filterName="last_gross_margin"
        service={CompanyService.addOpportunityPresentation}
        isFromGrid={true}
      />
    ),
    width: 100,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_rev_growth',
    title: 'Growth %',
    className: 'last_rev_growth',
    sorter: true,
    width: 100,
    align: 'left',
    ellipsis: true,
    render: (value: number | string, record: Company, index: number) =>
      value !== null && value !== undefined && value !== DEFAULT_EMPTY_VALUE ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
  },
  {
    dataIndex: generateGrowthCurrentColumnKey,
    title: `${YEARS_LABELS.current} Growth %`,
    className: generateGrowthCurrentColumnKey,
    sorter: true,
    width: 100,
    align: 'left',
    ellipsis: true,
    render: (value: number | string | undefined, record: Company, index: number) =>
      value !== null && value !== undefined && value !== DEFAULT_EMPTY_VALUE ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
  },
  {
    dataIndex: 'last_arr_value',
    title: 'ARR',
    className: 'last_arr_value',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="annual_recurring_revenue"
        fieldName="ARR"
        filterName="last_arr_value"
        service={CompanyService.addOpportunityPresentation}
        isFromGrid={true}
      />
    ),
    width: 100,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: generateArrCurrentColumnKey,
    title: `${YEARS_LABELS.current} ARR`,
    className: generateArrCurrentColumnKey,
    align: 'right',
    width: 150,
    sorter: true,
    ellipsis: true,
    render: (value: number | string | undefined, record: Company) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="annual_recurring_revenue"
        fieldName="ARR"
        filterName="last_arr_value"
        service={CompanyService.addOpportunityPresentation}
        isFromGrid={true}
        customFinancialYear={Number(YEARS_LABELS.current)}
      />
    ),
  },
  {
    dataIndex: 'ebitda_amount',
    title: 'EBITDA ',
    className: 'ebitda_amount',
    sorter: true,
    render: (value: number, record: CompanyProfile) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="ebitda_list"
        fieldName="EBITDA"
        filterName="ebitda_numeric"
        extraKeys={['ebitda_amount', 'ebitda_year']}
        service={UserService.updateCompanyUserValues}
        isFromGrid={true}
      />
    ),
    width: 100,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: generateEbitdaCurrentColumnKey,
    title: `${YEARS_LABELS.current} EBITDA`,
    className: generateEbitdaCurrentColumnKey,
    align: 'right',
    width: 150,
    sorter: true,
    ellipsis: true,
    render: (value: number | string | undefined, record: Company) => (
      <TableCellEditFinancialField
        company={record}
        fieldKey="ebitda_list"
        fieldName="EBITDA"
        filterName="ebitda_numeric"
        extraKeys={['ebitda_amount', 'ebitda_year']}
        service={UserService.updateCompanyUserValues}
        isFromGrid={true}
        customFinancialYear={Number(YEARS_LABELS.current)}
      />
    ),
  },
  {
    dataIndex: 'last_ebitda_margin',
    title: 'EBITDA Margin',
    className: 'last_ebitda_margin',
    sorter: true,
    render: (value: string, record: Company, index: number) =>
      value !== null && value !== undefined && value !== DEFAULT_EMPTY_VALUE ? (
        <TableCellPercentageGrowth value={value} record={record} />
      ) : (
        DEFAULT_EMPTY_VALUE
      ),
    width: 120,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'revenue_model',
    title: 'Revenue Model',
    className: 'revenue_model',
    sorter: true,
    render: (value: number, company: CompanyProfile) => (
      <EditFieldMultiSelect
        fieldName="Revenue Model"
        record={company}
        isFromGrid={true}
        successMessage="Revenue Model updated successfully!"
        errorMessage="Revenue Model update failed, Server error!"
        value={company.revenue_model}
        destroyOnHide={true}
      />
    ),
    width: 130,
    align: 'left',
  },
  {
    dataIndex: 'cashflow',
    title: 'Cashflow',
    className: 'cashflow',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return (
        <EditFieldSingleSelect
          isFromGrid
          value={value || DEFAULT_EMPTY_VALUE}
          record={record}
          fieldName="Cashflow"
          successMessage="Cashflow updated successfully!"
          errorMessage="Cashflow update failed, Server error!"
          fieldKey={'cashflow' as CompanyUserValuesKeys}
        />
      );
    },
    width: 160,
    ellipsis: true,
    align: 'right',
  },
  {
    dataIndex: 'deal_type',
    title: 'Deal Type',
    className: 'deal_type',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        value={value || DEFAULT_EMPTY_VALUE}
        record={record}
        fieldName="Deal Type"
        successMessage="Deal Type updated successfully!"
        errorMessage="Deal Type update failed, Server error!"
        service={CompanyService.addOpportunityPresentation}
        fieldKey={'deal_type' as CompanyUserValuesKeys}
      />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'banker_name',
    title: 'Banker',
    className: 'banker_name',
    sorter: true,
    render: (value: number, company: CompanyProfile) => (
      <EditFieldCheckboxText
        companyId={company.company_id}
        checkboxKey="banker"
        textInputKey="banker_name"
        checkValue={(company.banker as boolean) ?? null}
        textValue={company.banker_name ?? undefined}
        isFromGrid
        isCheckbox
      />
    ),
    width: 130,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'asking_amount',
    title: 'Equity Check (Ask Amount)',
    className: 'asking_amount',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldText record={record} value={value} isFromGrid fieldName="Asking Amount" />
    ),

    width: 200,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'customer_type',
    title: 'Customer Type',
    className: 'customer_type',
    sorter: true,
    render: (value: string[], record: CompanyProfile) => (
      <EditFieldMultiSelect
        record={record}
        fieldName="Customer Type"
        isFromGrid={true}
        successMessage="Customer Type updated successfully!"
        errorMessage="Customer Type update failed, Server error!"
        value={value}
        destroyOnHide={true}
        fieldRationaleValue={
          // temporary added to the condition checking on 'undefined' value
          // while api will make migration and bring this field to the Company object
          record.customer_other_rationale === undefined ? '' : record.customer_other_rationale
        }
      />
    ),
    width: 110,
    align: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'sales_and_marketing',
    title: 'Sales & Marketing',
    className: 'sales_and_marketing',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldSingleSelect
        isFromGrid
        value={value || DEFAULT_EMPTY_VALUE}
        record={record}
        fieldName="Sales and Marketing"
        successMessage="Sales and Marketing updated successfully!"
        errorMessage="Sales and Marketing update failed, Server error!"
        fieldKey="sales_and_marketing"
      />
    ),
    width: 190,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'sales_cycle',
    title: 'Sales Cycle',
    className: 'sales_cycle',
    sorter: true,
    render: (value: number, company: CompanyProfile) => (
      <EditFieldMultiSelect
        fieldName="Sales Cycle"
        record={company}
        isFromGrid={true}
        successMessage="Sales Cycle updated successfully!"
        errorMessage="Sales Cycle update failed, Server error!"
        value={company.sales_cycle}
        destroyOnHide={true}
      />
    ),
    width: 130,
    align: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'delivery_model',
    title: 'Delivery Model',
    className: 'delivery_model',
    sorter: true,
    render: (value: number, company: CompanyProfile) => (
      <EditFieldMultiSelect
        fieldName="Delivery Model"
        record={company}
        isFromGrid={true}
        successMessage="Delivery Model updated successfully!"
        errorMessage="Delivery Model update failed, Server error!"
        value={company.delivery_model}
        destroyOnHide={true}
      />
    ),
    width: 130,
    align: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'cloud_provider',
    title: 'Cloud Provider',
    className: 'cloud_provider',
    sorter: true,
    render: (value: string | null, record: Company) => {
      return (
        <EditFieldSingleSelect
          isFromGrid
          value={record.cloud_provider || DEFAULT_EMPTY_VALUE}
          record={record}
          fieldName="Cloud Provider"
          successMessage="Cloud Provider updated successfully!"
          errorMessage="Cloud Provider update failed, Server error!"
          fieldKey={'cloud_provider' as CompanyUserValuesKeys}
        />
      );
    },
    width: 100,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'architecture',
    title: 'Architecture',
    className: 'architecture',
    sorter: true,
    render: (value: number, company: CompanyProfile) => (
      <EditFieldMultiSelect
        fieldName="Architecture"
        record={company}
        isFromGrid={true}
        successMessage="Architecture updated successfully!"
        errorMessage="Architecture update failed, Server error!"
        value={company.architecture}
        destroyOnHide={true}
      />
    ),
    width: 130,
    align: 'left',
    ellipsis: true,
  },
  {
    dataIndex: 'last_valuation',
    title: 'Valuation',
    className: 'last_valuation',
    sorter: true,
    render: (value: number, record: Company, index: number) => (
      <TableCellFormatMoney
        value={value}
        record={record}
        formatValue={val => roundNumber(val, `$${NUMBER_FORMAT}`).toUpperCase()}
      />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'last_touch_date',
    title: 'Last Touch',
    className: 'last_touch_date',
    sorter: true,
    render: (value: string, record: Company, index: number) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={
          value !== DEFAULT_LAST_TOUCH && value !== DEFAULT_NEXT_TOUCH ? undefined : value
        }
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'next_touch_date',
    title: 'Scheduled Touch',
    className: 'next_touch_date',
    sorter: true,
    render: (value: string | string[], record: Company, index: number) => (
      <TableCellListDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={typeof value === 'string' ? value : undefined}
      />
    ),
    width: 140,
    ellipsis: true,
  },
  {
    dataIndex: 'next_touch_initiator_name',
    title: 'Scheduled Touch Initiated By',
    className: 'next_touch_initiator_name',
    sorter: true,
    render: (value: Array<string>, record: Company, index: number) => (
      <TableCellListDefault value={value} record={record} />
    ),
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'last_touch_initiator_name',
    title: 'Last Touch Initiated By',
    className: 'last_touch_initiator_name',
    sorter: true,
    width: 160,
    ellipsis: true,
  },
  {
    dataIndex: 'current_ceo',
    title: 'CEO',
    className: 'current_ceo',
    sorter: true,
    render: TableCellDefaultRender,
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'ceo_approval',
    title: 'CEO Approval',
    className: 'ceo_approval',
    sorter: true,
    width: 90,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'g2_rating',
    title: 'G2Crowd Rating',
    className: 'g2_rating',
    sorter: true,
    width: 90,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'g2_review_count',
    title: 'G2Crowd Reviews',
    className: 'g2_review_count',
    sorter: true,
    width: 90,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'ct_rating',
    title: 'Capterra Rating',
    className: 'ct_rating',
    sorter: true,
    width: 90,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'ct_review_count',
    title: 'Capterra Reviews',
    className: 'ct_review_count',
    sorter: true,
    width: 90,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'size_multiple',
    title: 'Size Multiple',
    className: 'size_multiple',
    sorter: true,
    render: (value: number | null | string, record: Company) => (
      <TableCellNumber value={value} record={record} />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'cb_rank',
    title: 'Crunchbase Rank',
    className: 'cb_rank',
    sorter: true,
    render: (value: number | null, record: Company, index: number) => (
      <TableCellNumber value={value} record={record} />
    ),
    width: 110,
    align: 'right',
    ellipsis: true,
  },
  {
    dataIndex: 'city',
    title: 'Town/City',
    className: 'city',
    sorter: true,
    render: TableCellDefaultRender,
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'state',
    title: 'State/Province',
    className: 'state',
    sorter: true,
    render: TableCellDefaultRender,
    width: 130,
    ellipsis: true,
  },
  {
    dataIndex: 'country',
    title: 'Country',
    className: 'country',
    sorter: true,
    render: TableCellDefaultRender,
    width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'source_tag',
    title: 'Source',
    ellipsis: false,
    className: 'source_tag',
    sorter: true,
    render: (value: Array<CompanySourceTag>, record: Company, index: number) => (
      <TableCellListDefault value={value} record={record} shouldBeInUpperCase />
    ),
    width: 180,
  },
  {
    dataIndex: 'linkedin',
    title: 'Linkedin Profile',
    className: 'linkedin',
    sorter: true,
    render: (value: string, record: Company) => (
      <EditFieldText
        value={value}
        record={record}
        isFromGrid
        service={CompanyService.updateLinkedinURL}
        fieldName="Linkedin Profile"
        entityType="company"
        isURL
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'is_in_business',
    title: 'In Business',
    className: 'is_in_business',
    sorter: true,
    render: (value, record, index) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="In Business"
        service={UserService.addCompanyInBusinessEvaluation}
        successMessage="Company in business state updated successfully!"
        errorMessage="Failed to update company in business state!"
        isFromGrid
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'active',
    title: 'Active on Pipeline',
    className: 'active',
    sorter: true,
    render: (value, record, index) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="Active on Pipeline"
        service={UserService.updateActive}
        successMessage="Company active state updated successfully!"
        errorMessage="Failed to update company active state!"
        isFromGrid
      />
    ),
    width: 90,
    ellipsis: true,
  },
  {
    dataIndex: 'url_status',
    title: 'Website',
    className: 'url_status',
    sorter: true,
    render: (value: URLStatus | null, record: Company) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="Website Status"
        service={UserService.updateWebsiteActive}
        successMessage="Company website status updated successfully!"
        errorMessage="Failed to update value!"
        isFromGrid
        isFromHistory
      />
    ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'next_steps',
    title: 'Next Steps',
    className: 'next_steps',
    sorter: true,
    render: (value: string, record: Company) =>
      record.source_tag.includes('et') ? (
        <EditFieldSingleSelectOther
          isFromGrid
          service={UserService.updateNextSteps}
          fieldName="Next Steps"
          fieldOtherKey={'next_steps_opinion'}
          fieldOtherValue={record.next_steps_opinion}
          value={value}
          successMessage="Company next steps updated successfully!"
          errorMessage="Company next steps update failed, Server error!"
          record={record}
          otherEndpoint={NEXT_STEPS_OPINION_ENDPOINT}
          defaultMenuIsOpen
        />
      ) : (
        value
      ),
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'created_on_optx',
    title: 'Date Created',
    className: 'created_on_optx',
    sorter: true,
    render: (value: string, record: Company) => (
      <TableCellDate
        value={value}
        format={globalConfig.grid_date.DATE_FORMAT}
        record={record}
        defaultValue={!value || value === DEFAULT_CELL_VALUE ? DEFAULT_CELL_VALUE : undefined}
      />
    ),
    width: 120,
    ellipsis: true,
  },
  {
    dataIndex: 'deal_team',
    title: 'Deal Team',
    className: 'deal_team',
    sorter: true,
    render: (value: number[] | null) => <TableCellDealTeams value={value} fieldType="Deal Team" />,
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'senior_deal_team_lead',
    title: 'Senior Deal Team Lead',
    className: 'senior_deal_team_lead',
    sorter: true,
    render: (value: string) => (
      <TableCellDealTeams value={value} fieldType="Senior Deal Team Lead" />
    ),
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'prev_stage',
    title: 'Previous Stage',
    className: 'prev_stage',
    sorter: true,
    render: TableCellDefaultRender,
    width: 110,
    ellipsis: true,
  },
  {
    dataIndex: 'is_ai_ml',
    title: 'AI / ML',
    className: 'is_ai_ml',
    sorter: true,
    render: (value: boolean | null, record: Company, index: number) => (
      <EditFieldRadio
        value={value}
        record={record}
        fieldName="AI / ML"
        service={UserService.updateAiMl}
        successMessage="Company AI / ML updated successfully!"
        errorMessage="Failed to update value!"
        isFromGrid
      />
    ),
    width: 110,
    align: 'center',
    ellipsis: true,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    className: 'row_actions',
    render: (value: unknown, record: CompanyProfile, index: number) => (
      <TableCellActions value={value} record={record} index={index} />
    ),
    width: 65,
    fixed: 'right',
  },
];

export default columns;
