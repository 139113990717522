import React, { useCallback, useState } from 'react';
import { Timeline, Tabs, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
import { CompanyPageHistory } from '@optx/constants/pendoActions';
// redux
import { actions as auditActions, selectors as auditSelectors } from '../state';
import { ChangedCompanyFieldsPayload } from '../state/interfaces';
import { AuditField } from './AuditField';
import { Styles } from './AuditTrail.styled';
import { AuditTrailHeader } from './AuditTrailHeader';

interface AuditTrailProps {
  companyId: number;
  activeProfileKey: string;
}

const AuditTrail: React.FC<AuditTrailProps> = ({ companyId, activeProfileKey }) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const fields = useSelector(auditSelectors.getFields);
  const loading = useSelector(auditSelectors.loading);
  const searchQuery = useSelector(auditSelectors.getSearchQuery);
  const startDate = useSelector(auditSelectors.getStartDate);
  const endDate = useSelector(auditSelectors.getEndDate);
  const pagination = useSelector(auditSelectors.getPagination);
  const count = useSelector(auditSelectors.getCount);
  const internalFields = useSelector(auditSelectors.getInternal);
  const optxFields = useSelector(auditSelectors.getOPTX);
  const hasReachedResultsEnd = useSelector(auditSelectors.getHasReachedResultsEnd);

  const [activeTab, setActiveTab] = useState('1');

  const payload: ChangedCompanyFieldsPayload = {
    companyId,
    pagination,
    field: searchQuery,
    internal: internalFields,
    optx: optxFields,
    startDate,
    endDate,
  };

  const fetchFields = useCallback(
    (payload: ChangedCompanyFieldsPayload) => {
      dispatch(auditActions.fetchChangedCompanyFields(payload));
    },
    [dispatch]
  );

  const handleLoadMore = () => {
    fetchFields({
      ...payload,
      pagination: { ...pagination, pageNumber: pagination.pageNumber + 1 },
    });
  };

  const infiniteScrollRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage:
      activeProfileKey === CompanyProfileTabs.HISTORY &&
      fields.length > 0 &&
      count > fields.length &&
      !hasReachedResultsEnd,
    onLoadMore: handleLoadMore,
  });

  const filterFields = (key: string) => {
    if (key === '1') {
      window.pendo.track(CompanyPageHistory.auditTrail_all);
      fetchFields({
        ...payload,
        internal: undefined,
        optx: undefined,
        pagination: { ...pagination, pageNumber: 1 },
      });
    }

    if (key === '2') {
      const newPayload: ChangedCompanyFieldsPayload = {
        ...payload,
        internal: 'False',
        optx: 'False',
        pagination: { ...pagination, pageNumber: 1 },
      };
      fetchFields(newPayload);
    }

    if (key === '3') {
      const newPayload: ChangedCompanyFieldsPayload = {
        ...payload,
        internal: 'True',
        optx: 'False',
        pagination: { ...pagination, pageNumber: 1 },
      };
      fetchFields(newPayload);
    }

    if (key === '4') {
      const newPayload: ChangedCompanyFieldsPayload = {
        ...payload,
        internal: undefined,
        optx: 'True',
        pagination: { ...pagination, pageNumber: 1 },
      };
      fetchFields(newPayload);
    }

    setActiveTab(key);
  };

  const noFields = (
    <Typography.Title level={5} style={{ margin: '1.5rem 0' }}>
      No Fields Found
    </Typography.Title>
  );

  const mappedFields = fields?.map((field, index) => {
    return (
      <Timeline.Item label={moment(field.modified_at).format('MMMM D, YYYY')} key={index}>
        <AuditField field={field} />
      </Timeline.Item>
    );
  });

  return (
    <div ref={infiniteScrollRef}>
      <Styles.AuditTrailWrapper title="Audit Trail" $hasFields={!!fields.length}>
        <AuditTrailHeader
          activeProfileKey={activeProfileKey}
          companyId={companyId}
          activeTab={activeTab}
        />

        <Tabs onChange={filterFields} defaultActiveKey="1">
          <TabPane tab="All" key={1}>
            {!loading && !fields.length && noFields}
            {fields?.length ? <Timeline mode="left">{mappedFields}</Timeline> : null}
          </TabPane>
          <TabPane tab="Data Sources" key={2}>
            {!loading && !fields.length && noFields}
            {fields?.length ? <Timeline mode="left">{mappedFields}</Timeline> : null}
          </TabPane>
          <TabPane tab="Internal" key={3}>
            {!loading && !fields.length && noFields}
            {fields?.length ? <Timeline mode="left">{mappedFields}</Timeline> : null}
          </TabPane>
          <TabPane tab="OPTX score" key={4}>
            {!loading && !fields.length && noFields}
            {fields?.length ? <Timeline mode="left">{mappedFields}</Timeline> : null}
          </TabPane>
        </Tabs>
      </Styles.AuditTrailWrapper>
    </div>
  );
};

export default AuditTrail;
