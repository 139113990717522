import React from 'react';
// models
import Company from '@optx/models/Company';
import { Cell } from '@optx/models/table/Cell';
// constants
import { cellIsEqual } from '../../AntTable/cells/memoize';
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const TableCellDescription: React.FC<Cell<Company>> = ({ value, record }) => {
  return (
    <>
      {value ? (
        <TruncateTooltip title={value} placement="topLeft">
          {value}
        </TruncateTooltip>
      ) : (
        <>{DEFAULT_CELL_VALUE}</>
      )}
    </>
  );
};

export default React.memo(TableCellDescription, cellIsEqual);
