import React, { useState, useEffect, useCallback, MouseEvent } from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';
// utils
import { styledColors } from '@optx/utils/theme/colors';
// components
import { TooltipPlacement } from 'antd/lib/tooltip';
import Icon from '../Icon';

interface EditPopoverProps {
  content?: JSX.Element;
  title?: string;
  closePopup?: boolean;
  customOverlayStyle?: React.CSSProperties;
  setClosePopup?: (closePopup: boolean) => void;
  onClick?: () => void;
  // trigger only when popup will close
  onVisibilityChange?: () => void;
  // trigger on popup visibility change
  onVisibilityUpdate?: (visibility: boolean) => void;
  className?: string;
  hidePenIcon?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  placement?: TooltipPlacement;
  isDynamicPopoverHeight?: boolean;
  destroyOnHide?: boolean;
  actionPendo?: string;
  overlayClassName?: string;
}

// visible is string because of firebug. if we convert to boolean there is console error.
const PenIcon = styled(Icon)<{ visible: string }>`
  cursor: pointer;
  visibility: ${props => (props?.visible === 'true' ? 'visible' : 'unset')};

  :hover {
    color: ${styledColors.blue6};
  }
`;

const EditPopover: React.FC<EditPopoverProps> = ({
  children,
  content,
  title,
  closePopup,
  setClosePopup,
  customOverlayStyle,
  onClick,
  onVisibilityChange,
  onVisibilityUpdate,
  className,
  getPopupContainer,
  hidePenIcon = false,
  placement = 'top',
  isDynamicPopoverHeight,
  destroyOnHide = false,
  actionPendo,
  overlayClassName,
  ...rest
}) => {
  const [popupVisibility, setPopupVisibility] = useState(false);
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (actionPendo) {
        window.pendo.track(actionPendo);
      }

      onClick && onClick();
      setPopupVisibility(prevState => !prevState);
    },
    [onClick, actionPendo]
  );

  useEffect(() => {
    if (closePopup) {
      setPopupVisibility(false);

      if (setClosePopup) {
        setClosePopup(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup, actionPendo]);

  const handleVisibleChange = (visible: boolean) => {
    setPopupVisibility(visible);
  };

  useEffect(() => {
    onVisibilityUpdate && onVisibilityUpdate(popupVisibility);

    if (!popupVisibility && onVisibilityChange) onVisibilityChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupVisibility]);

  // currently, there is an issue with antd Popover, where, if you open it from a dropdown menu option,
  // like we have on History tab, for audit fields, and it's height changes, the position of the
  // popover is recalculated by the library and it's moved somewhere outside the screen. This happens
  // for fields like Product Category, Software Company, Ops Team, Last Round and Deal Team. For now, the fix
  // is to get the initial position of the popover, which is correct, add a style tag and set the position
  // in css, using important. After the popover is closed, if these styles where created, remove them from the DOM.
  // The isDynamicPopoverHeight is used to mark fields for which it is possible for their height to change
  // setTimeout is used to allow time for popover node element to be added to the DOM and have it's position set,
  // after that we can grab the position and hardcode it in css, so that it's no longer overwritten
  useEffect(() => {
    if (isDynamicPopoverHeight && popupVisibility) {
      setTimeout(() => {
        const style = document.createElement('style');
        style.className = 'popover-position-fix';
        const popoverHTML = document.querySelector('.ant-popover') as HTMLElement;

        if (popoverHTML) {
          style.textContent = `
          .ant-popover {
            left: ${popoverHTML.offsetLeft}px !important;
            top: ${popoverHTML.offsetTop}px !important;
          }
        `;
          document.head.appendChild(style);
        }
      }, 1000);
    } else if (!popupVisibility) {
      const style = document.querySelectorAll('.popover-position-fix')[0];

      if (style) {
        style.remove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupVisibility]);

  return (
    <div className={className}>
      <Popover
        content={content}
        title={title}
        trigger="click"
        visible={popupVisibility}
        overlayStyle={customOverlayStyle}
        onVisibleChange={handleVisibleChange}
        getPopupContainer={getPopupContainer}
        placement={placement}
        destroyTooltipOnHide={destroyOnHide}
        overlayClassName={overlayClassName}
        {...rest}
      >
        <span className="edit-field--container" onClick={handleClick} role="presentation">
          <span className="edit-field--value">{children}</span>
          {!hidePenIcon && (
            <span className="edit-pen">
              <PenIcon iconName="pen" visible={popupVisibility.toString()} />
            </span>
          )}
        </span>
      </Popover>
    </div>
  );
};

export default EditPopover;
