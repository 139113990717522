import { select, call, put, cancel, cancelled, fork, take } from 'redux-saga/effects';
import { Dictionary } from 'lodash';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
// models
import { PreselectedFilter, FilterSource } from '@models/filters';
import { ColumnKeys } from '@models/table/Columns';
import Company from '@models/Company';
import { SelectOption } from '@optx/models/Option';
import { SearchPayloadRequest, CustomSearchPayloadRequest } from '@models/api/contacts';
import { UpdateUserSettingsPayload } from '@models/api/user';
import { SearchSave, SearchStateData, View } from '@models/search';
import { DefaultViews } from '@redux/company/filters/interfaces';
import { UserSessionSettings, UserInformation } from '@models/user';
import { SuccessErrorCallback } from '@models/callback';
import { GridKeysMain, GetGridSearchData } from '@models/grid';
import { AddonManagementPayload } from '@redux/company/addon-management/interfaces';
// services
import NotificationService from '@services/NotificationService';
import { SearchService, AnalystService, CompanyService } from '@services/api';
// utils
import {
  matchSavedSearches,
  matchView,
  hasFilters,
  isCustomUIView,
  mapSearchCounterQuery,
  mapSortQuery,
} from '@utils/search';
import { sortSimilarCompanies } from '@optx/utils/utils';
import { getRouteAlias, getRouteAliasFromGridKey } from '@utils/routes';
import { parseFilter } from '@utils/filters/parseFilters';
import { getSearchOriginKeyFromGridKey } from '@utils/grid';
// redux
import * as actions from './actions';
import { actions as searchKeyActions } from '@features/grid/searchkey';
import { actions as searchCountActions } from '@features/grid/search-count';
import { selectors as advancedSearchSearchSelectors } from '@redux/company/search/search';
import { selectors as searchSelectors } from '@features/grid/search';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { selectors as viewSelectors } from '@features/grid/view';
import { selectors as listsSelectors } from '@redux/lists/details';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { selectors as advancedSearchColumnSelectors } from '@redux/company/search/columns';
import { selectors as suggestedSearchesSelectors } from '@redux/company/suggested-searches';
import {
  actions as savedSearchesActions,
  selectors as savedSearchesSelectors,
} from '@redux/company/saved-searches';
import { selectors as listsSearchCommonSelectors } from '@redux/lists/search/common';
import { selectors as listsDetailsSelectors } from '@redux/lists/details';
import { userInfoUpdateSaga, getGridSearchDataSaga } from '@features/grid/state/sagasReusable';
import { selectors as outreachColumnSelectors } from '@redux/company-outreach/columns';
import { selectors as myCompaniesFilterCardsSelectors } from '@optx/redux/my-companies/filters-cards';
import { selectors as myCompaniesColumnSelectors } from '@redux/my-companies/columns';
import { actions as paginationActions } from '@features/grid/pagination';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as companyOutReachFiltersSelectors } from '@redux/company-outreach/filters';
import { similarCompaniesSortingAction } from '@optx/redux/company/search/sort/actions';

/**
 * Update company filters for current session.
 * @param payload search payload containing filters.
 * @param gridKey the grid key
 * @param optionalSettings other optional user setting to be saved.
 */
export function* updateFilterSettings(
  payload: SearchPayloadRequest,
  gridKey: GridKeysMain,
  optionalSettings: Partial<UpdateUserSettingsPayload> = {},
  from?: string
) {
  // just for myCompanies
  const selectedCardId: string = yield select(myCompaniesFilterCardsSelectors.getSelectedCardId);
  const selectedCard: SearchStateData = yield select(
    myCompaniesFilterCardsSelectors.getSelectedCard
  );

  const newPayload: CustomSearchPayloadRequest = { ...payload };
  delete newPayload.pagination;

  let queryPayload: Dictionary<PreselectedFilter> = {};

  if (gridKey === 'outreach') {
    const parsedTableGridQuery = queryString.parse(payload.tableGridQuery!);
    const filterRangeValues: [string, string] = yield select(
      companyOutReachFiltersSelectors.getSourcingFilterOptionsDateValue
    );

    let customFilterPayload = {};

    if (filterRangeValues) {
      customFilterPayload = {
        ...newPayload.filter,
        ddate_lower: filterRangeValues[0],
        ddate_upper: filterRangeValues[1],
      };
    } else {
      customFilterPayload = {
        ...newPayload.filter,
        ddate_lower: parsedTableGridQuery.ddate_lower as string,
        ddate_upper: parsedTableGridQuery.ddate_upper as string,
      };
    }

    queryPayload = mapSearchCounterQuery(
      newPayload.searchKey,
      customFilterPayload,
      payload.analystId !== undefined
        ? payload.analystId
        : (parsedTableGridQuery.analyst_id as string),
      payload.sourcingOutReachColumnName || (parsedTableGridQuery.column_name as string)
    );
  } else {
    queryPayload = {
      ...mapSearchCounterQuery(newPayload.searchKey, newPayload.filter),
      ...(gridKey === 'myCompanies' ? { query: newPayload.searchKey } : {}),
    };
  }

  let newQueryPayload = { ...queryPayload };

  if (payload.listType === 'combined') {
    newQueryPayload.combined_search_id = payload.fromSavedList as string;
  }

  if (gridKey === 'myCompanies') {
    let stageFilter: PreselectedFilter = [];

    // if "stage" and "next_touch_date" filters are identical with default card filters
    // we dont save them in session sesttings
    if (selectedCardId === 'card1') {
      // @ts-ignore
      stageFilter = (selectedCard?.filter?.stage?.every((item: string) =>
        (queryPayload?.stage as string[])?.includes(item)
      ) as PreselectedFilter)
        ? []
        : queryPayload.stage;
    } else {
      stageFilter = queryPayload?.stage || [];
    }

    newQueryPayload.stage = stageFilter;
  }

  const query = queryString.stringify(
    { ...newQueryPayload, search_title: payload.searchTitle },
    { arrayFormat: 'comma' }
  );
  const userSettings: UserInformation | null = yield select(
    userInformationSelectors.getUserSettings
  );

  try {
    const cancelSearch: boolean = yield select(searchSelectors.isCancelled(gridKey));

    if (cancelSearch) {
      yield cancel();
    }

    let gridKeySessionName = '';

    switch (gridKey) {
      case 'advancedSearch':
        gridKeySessionName = 'company';
        break;

      case 'lists':
        gridKeySessionName = 'user_list';
        break;

      case 'outreach':
        gridKeySessionName = 'sourcing_outreach_detail';
        break;

      case 'myCompanies':
        gridKeySessionName = 'my_companies';
        break;

      case 'addons':
        gridKeySessionName = 'addon';
        break;

      default:
        break;
    }

    const hasUserSettingsChanged = Boolean(
      userSettings &&
        query !==
          userSettings.settings.session_settings[
            `${gridKeySessionName}_filters` as keyof UserSessionSettings
          ]
    );

    if (hasUserSettingsChanged || Object.keys(optionalSettings).length) {
      const sortQuery = queryString.stringify(mapSortQuery(newPayload.sortBy || []), {
        arrayFormat: 'comma',
      });

      let updateUserSettingsData = {
        [gridKeySessionName + '_sorting']: sortQuery,
        [gridKeySessionName + '_filters']: query,
        my_companies_selected_card_id: gridKey === 'myCompanies' ? selectedCardId : undefined,
        ...optionalSettings,
      };

      if (from === 'loadSuggestedSearch') {
        yield put(
          userInformationActions.updateUserSettingsForSuggestedSearches(updateUserSettingsData)
        );
      } else {
        yield put(userInformationActions.updateUserSettings(updateUserSettingsData));
      }
    }
  } catch (error: any) {
    NotificationService.error('Failed persisting filters settings!');
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(
        actions.cancelCompanySearch({
          gridKey,
          data: false,
        })
      );
    }
  }
}

/**
 * Search companies.
 * @param data query payload data.
 * @param newFilter new filter.
 * @param addSearchHeader omit search header, when a new search is made.
 * @param searchTitle saved search title.
 * @param updateSessionSettings update filter settings to be able to load them on next refresh.
 */
export function* searchCompanies(
  data: SearchPayloadRequest,
  gridKey: GridKeysMain,
  newFilter: Dictionary<PreselectedFilter>,
  addSearchHeader?: boolean,
  searchTitle?: string,
  updateSessionSettings: Partial<UpdateUserSettingsPayload> | boolean = true,
  callback?: SuccessErrorCallback,
  isSimilarCompaniesSearch?: boolean
) {
  const isSourceScrub: boolean = yield select(listsSelectors.isSourceScrubRoute);

  const listGridKey = gridKey === 'lists' && isSourceScrub ? 'sslists' : 'watchlists';
  const isSimilarCompaniesSorting: boolean | undefined = yield select(
    advancedSearchSearchSelectors.isSimilarCompaniesSorting
  );

  try {
    const searchOriginKey = getSearchOriginKeyFromGridKey(
      gridKey === 'lists' ? listGridKey : gridKey
    );

    const filtersSearchOriginValue: number = yield select(state =>
      filterSourcesSelectors.getFilterSearchOrigin(state, searchOriginKey)
    );

    let payloadData = { ...data };

    if (payloadData.fromSavedList === 'default') {
      delete payloadData.fromSavedList;
    }

    if (typeof filtersSearchOriginValue === 'number') {
      payloadData = {
        ...payloadData,
        searchOrigin: filtersSearchOriginValue,
        sortBy: payloadData.sortBy,
      };
    }

    let res: AxiosResponse<Company[]>;

    if (gridKey === 'advancedSearch') {
      delete payloadData.filter.saved_list_id;
      delete payloadData.filter.ss_list_id;
    }

    if (gridKey === 'outreach') {
      delete payloadData.filter.saved_list_id;
      res = yield call(AnalystService.getCompaniesListOutReach, payloadData);
    } else if (gridKey === 'addons') {
      // we don't need search origin and date range filters on addon page
      delete payloadData.searchOrigin;
      delete payloadData.filter.ddate_lower;
      delete payloadData.filter.ddate_upper;

      res = yield call(
        CompanyService.fetchCompanyAddonManagement,
        payloadData as AddonManagementPayload
      );
    } else {
      res = yield call(SearchService.searchCompanies, payloadData, addSearchHeader);
    }

    let columns: string = '';
    let columnOrder: ColumnKeys[] = [];
    let pinnedColumns: string = '';
    let suggestedSearches: SearchSave[] = [];

    switch (gridKey) {
      case 'advancedSearch':
        columns = yield select(advancedSearchColumnSelectors.getVisibleColumnString);
        columnOrder = yield select(advancedSearchColumnSelectors.getColumnOrder);
        pinnedColumns = yield select(advancedSearchColumnSelectors.getPinnedColumnsString);
        suggestedSearches = yield select(suggestedSearchesSelectors.getSuggestedSearches);
        break;

      case 'lists':
        columns = yield select(listsSearchCommonSelectors.getVisibleColumnString);
        columnOrder = yield select(listsSearchCommonSelectors.getColumnOrder);
        pinnedColumns = yield select(listsSearchCommonSelectors.getPinnedColumnsString);
        break;

      case 'outreach':
        columns = yield select(outreachColumnSelectors.getVisibleColumnString);
        columnOrder = yield select(outreachColumnSelectors.getColumnOrder);
        pinnedColumns = yield select(outreachColumnSelectors.getPinnedColumnsString);
        break;

      case 'myCompanies':
        columns = yield select(myCompaniesColumnSelectors.getVisibleColumnString);
        columnOrder = yield select(myCompaniesColumnSelectors.getColumnOrder);
        pinnedColumns = yield select(myCompaniesColumnSelectors.getPinnedColumnsString);
        break;

      default:
        break;
    }

    const savedSearches: SearchSave[] = yield select(savedSearchesSelectors.getAllViews);
    const filteredSavedSearches: SearchSave[] = savedSearches.filter(
      search => search.search_info.type !== 'combined'
    );
    const currentSearch = savedSearches.find(search => search.unique_id === data.fromSavedList);
    const { defaultOptxView } = yield select(savedSearchesSelectors.getViews);

    let matchData = { ...data };

    if (gridKey === 'myCompanies') {
      const newFilterQuery: Dictionary<string | Array<number | string>> = yield call(
        getFiltersQuery,
        newFilter
      );
      matchData.filter = { ...newFilterQuery, source_tag_filter: [] };
    }

    let title = searchTitle;
    let view;

    // check new search request against saved searches
    if (!title && data.listType !== 'combined') {
      const { matchedTitle, matchedView } = matchView(
        matchData,
        columns,
        columnOrder.join(),
        pinnedColumns,
        suggestedSearches.length
          ? [...filteredSavedSearches, ...suggestedSearches]
          : filteredSavedSearches,
        defaultOptxView
      );
      title = matchedTitle;
      view = matchedView;

      // if no saved searches matched, check against
      // suggested searches and also include sorting
      if (title === undefined) {
        title = matchSavedSearches(data, suggestedSearches, true);
      }
    } else {
      view = savedSearches.find(search => search.title === searchTitle);
    }

    const isAdvancedSearch = gridKey === 'advancedSearch';

    const cancelSearch: boolean = yield select(searchSelectors.isCancelled(gridKey));

    if (cancelSearch) {
      yield cancel();
    }

    let searchData = {
      ...data,
      filter: newFilter,
    };
    let viewId;
    let saveTitle = false;

    if (gridKey === 'advancedSearch') {
      if (title) {
        view = savedSearches.find(search => search.title === title);
        if (!view) view = suggestedSearches.find(search => search.title === title);
      }

      if (view) {
        viewId = view.unique_id;

        if (hasFilters(view.search_criteria)) {
          saveTitle = true;
        }
      }

      searchData = { ...searchData, fromSavedList: viewId, searchTitle: saveTitle ? title : '' };

      if (data.listType === 'combined') {
        searchData.searchTitle = currentSearch?.title;
        saveTitle = true;
      }
    }

    const companies: Company[] =
      gridKey === 'addons' ? (res.data as unknown as { data: Company[] }).data : res.data;

    if (gridKey === 'advancedSearch' && (isSimilarCompaniesSearch || isSimilarCompaniesSorting)) {
      const similarCompanyId = (searchData.filter.similarCompanies as SelectOption)?.value
        .split(',')
        .pop()!;

      const similarCompanyName = companies.find(
        company => company.company_id === Number(similarCompanyId)
      )?.company_name;

      const persistedSimilarCompanyName: SelectOption = yield select(
        filterSelectors.getSimilarCompanyName()
      );

      if (similarCompanyId !== undefined) {
        searchData = {
          ...searchData,
          filter: {
            ...searchData.filter,
            similarCompanies: {
              ...(searchData.filter.similarCompanies as SelectOption),
              label: similarCompanyName || persistedSimilarCompanyName?.label || '',
            },
          },
        };
      }
    }

    if (isAdvancedSearch && 'similarCompanies' in searchData.filter) {
      searchData = {
        ...searchData,
        searchTitle: '',
      };
    }

    yield put(
      actions.searchCompaniesSuccess(
        sortSimilarCompanies(
          isSimilarCompaniesSorting,
          companies,
          searchData.filter.similarCompanies as SelectOption<string>
        ),
        {
          gridKey,
          data: searchData,
        }
      )
    );

    if (gridKey === 'lists') {
      yield put(
        searchKeyActions.setSearchKey({
          data: payloadData.searchKey,
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
        })
      );
      yield put(
        searchCountActions.searchCount({
          data: payloadData,
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
        })
      );
    } else {
      yield put(searchKeyActions.setSearchKey({ data: payloadData.searchKey, gridKey: gridKey }));
      yield put(searchCountActions.searchCount({ data: payloadData, gridKey: gridKey }));
    }

    const location = getRouteAlias();

    const applyViewData = {
      id: view ? view.unique_id : '',
      label: title || '',
      pageAlias: getRouteAliasFromGridKey(location === 'sourceScrubLists' ? 'sslists' : gridKey),
    };

    if (title !== 'Recently Funded' && data.listType !== 'combined') {
      applyViewData.id = viewId || '';
      applyViewData.label = title || '';
    }

    if (data.listType !== 'combined') {
      applyViewData.id = viewId || '';
      applyViewData.label = title || '';
    }

    if (data.listType === 'combined') {
      applyViewData.label = currentSearch?.title || '';
      applyViewData.id = data.fromSavedList || '';
    }

    yield put(savedSearchesActions.applyViewSuccess(applyViewData));

    if (!saveTitle) {
      yield put(actions.saveSearchTitle({ gridKey, data: '' }));
    }

    callback && callback(null);

    if (updateSessionSettings) {
      yield call(
        updateFilterSettings,
        data,
        gridKey,
        updateSessionSettings as Partial<UpdateUserSettingsPayload>
      );
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(
        actions.cancelCompanySearch({
          gridKey,
          data: false,
        })
      );
    }
  }
}

// send error as a parameter because in same cases we can have a javascript error that doesn't appear in
// dev console, but we can console log it
export function* searchCompaniesFail(gridKey: GridKeysMain, error: any, errorNumber?: number) {
  let errorMessage = 'Get companies fail, Server error!';
  if (gridKey === 'outreach') errorMessage = 'Get Sourcing OutReach Companies fail, Server error!';
  if (gridKey === 'myCompanies') errorMessage = 'Get My Companies fail, Server error!';
  if (gridKey === 'addons') errorMessage = 'Failed to fetch company Add-on Management!';
  if (errorNumber === 400) errorMessage = 'Not found!';

  yield put(actions.searchCompaniesFail({ gridKey, data: errorMessage }));
  NotificationService.error(errorMessage);
}

export function* initializeLongCardSearchSaga(gridKey: GridKeysMain, tableGridQuery?: string) {
  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey);
  let {
    sortBy,
    filter: filterQuery,
    searchKey,
    originalFilter: filter,
    pagination,
  } = gridSearchData;

  let sessionFilters: string | undefined = '';

  if (gridKey === 'lists' || gridKey === 'outreach') {
    const sessionSettings: UserSessionSettings = yield select(
      userInformationSelectors.getSessionSettings
    );
    const { filters: defaultFilters, searchKey: defaultSearchKey }: DefaultViews = yield select(
      filterSourcesSelectors.getDefaultViews
    );
    const {
      user_list_filters: listsSessionFilters,
      sourcing_outreach_detail_filters: outreachSessionFilters,
    } = sessionSettings;

    let savedFilters = {};

    if (gridKey === 'lists') {
      sessionFilters = listsSessionFilters;
    } else if (gridKey === 'outreach') {
      sessionFilters = outreachSessionFilters;
    }

    if (sessionFilters) {
      const companyFilters: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
      const [parsedFilters] = parseFilter(sessionFilters, companyFilters);

      savedFilters = parsedFilters;
    }

    if (
      sessionFilters === undefined &&
      Object.keys(defaultFilters).length &&
      !window.location.search
    ) {
      filter = defaultFilters;
      searchKey = defaultSearchKey;
    }

    const listId: string = yield select(listsDetailsSelectors.getRouteListId);
    const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);

    // @ts-ignore
    filter = {
      ...filter,
      ...savedFilters,
      ...(isSourceScrub
        ? { ss_list_id: listId, saved_list_id: null }
        : { ss_list_id: null, saved_list_id: listId }),
    };

    filterQuery = yield call(getFiltersQuery, filter);
  }

  let searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
  };

  if (gridKey === 'outreach' && tableGridQuery) {
    const parsedSessionsFilters = queryString.parse(sessionFilters!);

    const parsedTableGridQuery = queryString.parse(tableGridQuery);

    const customTableGridQuery = {
      ...parsedTableGridQuery,
      analyst_id:
        parsedSessionsFilters.analyst_id !== undefined
          ? parsedSessionsFilters.analyst_id
          : parsedTableGridQuery.analyst_id,
      column_name: parsedSessionsFilters.column_name || parsedTableGridQuery.column_name,
      ddate_upper: parsedSessionsFilters.ddate_upper || parsedTableGridQuery.ddate_upper,
      ddate_lower: parsedSessionsFilters.ddate_lower || parsedTableGridQuery.ddate_lower,
    };

    searchData.tableGridQuery = queryString.stringify(customTableGridQuery, {
      arrayFormat: 'comma',
    });
  }

  let isSimilarCompaniesSearch = false;

  if (gridKey === 'advancedSearch' && filter.similarCompanies) {
    isSimilarCompaniesSearch = true;
    yield put(similarCompaniesSortingAction(true));
  }

  try {
    yield call(
      searchCompanies,
      searchData,
      gridKey,
      filter,
      undefined,
      '',
      true,
      undefined,
      isSimilarCompaniesSearch
    );

    if (gridKey === 'advancedSearch') {
      const view: View = yield select(viewSelectors.getView(gridKey));
      const suggestedSearches: SearchSave[] = yield select(
        suggestedSearchesSelectors.getSuggestedSearches
      );
      const isOptxDefaultView = view.id === 'default';
      const searchTitle: string | undefined = yield select(searchSelectors.getSearchName(gridKey));

      const addFromSavedListParam =
        view.id !== '' && !isOptxDefaultView && !isCustomUIView(view.id);
      const isSuggestedSearch = suggestedSearches.some(search => search.title === searchTitle);
      const addSearchTitleParam = searchTitle && isSuggestedSearch && !addFromSavedListParam;

      searchData = {
        ...searchData,
        ...(addFromSavedListParam && { fromSavedList: view.id }),
        ...(addSearchTitleParam && {
          filter: { ...searchData.filter },
          search_title: searchTitle,
        }),
      };
    }

    const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);

    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(
        searchCountActions.searchCount({
          gridKey,
          data: searchData,
        })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

export function* initialCompanySearchSaga(gridKey: GridKeysMain, tableGridQuery?: string) {
  const isSavedSearchesLoaded: boolean = yield select(savedSearchesSelectors.loaded);
  const query = window.location.search ? window.location.search.replace('?', '') : undefined;
  const list = query?.split('=')[0] === 'saved_search_id';

  if (list && !isSavedSearchesLoaded) {
    yield take(savedSearchesActions.initializeSavedSearchesAndViewsSuccess);
  }

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey);

  let { sortBy, searchKey, originalFilter: filter, pagination } = gridSearchData;

  const sessionSettings: UserSessionSettings = yield select(
    userInformationSelectors.getSessionSettings
  );
  const {
    sourcing_outreach_detail_filters: outreachColumnSelectorsessionFilters,
    user_list_filters: listsSessionFilters,
    company_filters: companySessionFilters,
  } = sessionSettings;

  let sessionFilters: string | undefined = '';

  switch (gridKey) {
    case 'advancedSearch':
      sessionFilters = companySessionFilters;
      break;

    case 'lists':
      sessionFilters = listsSessionFilters;
      break;

    case 'outreach':
      sessionFilters = outreachColumnSelectorsessionFilters;
      break;

    default:
      break;
  }

  const defaultViews: DefaultViews = yield select(filterSourcesSelectors.getDefaultViews);
  const { filters: defaultFilters, searchKey: defaultSearchKey, data: defaultData } = defaultViews;
  const location = getRouteAlias();
  const updatedGridKey =
    location === 'userLists' ? 'watchlists' : location === 'sourceScrubLists' ? 'sslists' : gridKey;
  const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getCustomClearedFilter(updatedGridKey)
  );

  let listType;
  let id;

  const sessionFiltersQuery = queryString.parse(sessionFilters || '');

  if (sessionFilters?.includes('combined_search_id')) {
    listType = 'combined';
    id = Number(sessionFiltersQuery.combined_search_id);
  }

  if (
    sessionFilters === undefined &&
    Object.keys(defaultFilters).length &&
    !window.location.search
  ) {
    filter = defaultFilters;
    searchKey = defaultSearchKey;
    listType = defaultData[0]?.search_info.type || '';
    id = defaultData[0]?.unique_id;
  }

  if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);
    const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);

    // @ts-ignore
    filter = {
      ...(listType === 'combined' ? customClearedFilter : filter),
      ...(isSourceScrub
        ? { ss_list_id: listId, saved_list_id: null }
        : { ss_list_id: null, saved_list_id: listId }),
    };
  }

  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  let searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
    fromSavedList: id,
    listType,
  };

  if (gridKey === 'outreach' && tableGridQuery) {
    const parsedSessionsFilters = queryString.parse(sessionFilters!);

    const parsedTableGridQuery = queryString.parse(tableGridQuery);

    const customTableGridQuery = {
      ...parsedTableGridQuery,
      analyst_id:
        parsedSessionsFilters.analyst_id !== undefined
          ? parsedSessionsFilters.analyst_id
          : parsedTableGridQuery.analyst_id,
      column_name: parsedSessionsFilters.column_name || parsedTableGridQuery.column_name,
      ddate_upper: parsedSessionsFilters.ddate_upper || parsedTableGridQuery.ddate_upper,
      ddate_lower: parsedSessionsFilters.ddate_lower || parsedTableGridQuery.ddate_lower,
    };

    searchData.tableGridQuery = queryString.stringify(customTableGridQuery, {
      arrayFormat: 'comma',
    });
  }

  try {
    let searchTitle: undefined | string,
      updateSessionSettings: Partial<UpdateUserSettingsPayload> | boolean;

    if (gridKey === 'outreach') {
      searchTitle = '';
      updateSessionSettings = {
        sourcing_outreach_results_page_number: pagination.pageNumber,
      };
    } else {
      searchTitle = undefined;
      updateSessionSettings = false;
    }

    if (gridKey === 'advancedSearch' && filter.similarCompanies) {
      yield put(similarCompaniesSortingAction(true));
    }

    yield fork(
      searchCompanies,
      searchData,
      gridKey,
      filter,
      undefined,
      searchTitle,
      updateSessionSettings
    );

    yield call(userInfoUpdateSaga, gridKey, pagination);

    if (gridKey === 'advancedSearch') {
      const view: View = yield select(viewSelectors.getView(gridKey));
      const suggestedSearches: SearchSave[] = yield select(
        suggestedSearchesSelectors.getSuggestedSearches
      );
      const isOptxDefaultView = view.id === 'default';
      const searchTitle: string | undefined = yield select(
        searchSelectors.getSearchName('advancedSearch')
      );

      const addFromSavedListParam =
        view.id !== '' && !isOptxDefaultView && !isCustomUIView(view.id);
      const isSuggestedSearch = suggestedSearches.some(search => search.title === searchTitle);
      const addSearchTitleParam = searchTitle && isSuggestedSearch && !addFromSavedListParam;

      searchData = {
        ...searchData,
        ...(addFromSavedListParam && { fromSavedList: view.id }),
        ...(addSearchTitleParam && {
          filter: { ...searchData.filter, search_title: searchTitle as string },
        }),
      };
    }

    if (gridKey === 'outreach')
      yield put(paginationActions.changePaginationSuccess({ gridKey, data: pagination }));
  } catch (error: any) {
    if (gridKey === 'outreach') {
      yield call(searchCompaniesFail, gridKey, error, error.response.status as number);
    } else {
      yield call(searchCompaniesFail, gridKey, error);
    }
  }
}
