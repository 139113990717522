// components
import { LineSkeleton } from '../Skeleton';
import CommonStyled from '@features/analyst-home/components/AnalystSearchCard/AnalystSearchCard.styled';
import Styled from './RecentlyAccessedGroupCard.styled';

const RecentlyAccessedGroupCardSkeleton = () => {
  return (
    <CommonStyled.StyledCard hoverable $isSmallCard>
      <Styled.CardTitle>
        <LineSkeleton />
      </Styled.CardTitle>
      <Styled.HighlightedBlock>
        <LineSkeleton />
        <LineSkeleton />
      </Styled.HighlightedBlock>
    </CommonStyled.StyledCard>
  );
};

export default RecentlyAccessedGroupCardSkeleton;
