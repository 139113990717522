import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
// models
import { AutosaveStatus, LocalStorageAutosaveState } from './interfaces';
// redux
import * as actions from './actions';

const initialState: LocalStorageAutosaveState = {
  status: 'none',
};

const updateAutosaveStatusReducer: CaseReducer<
  LocalStorageAutosaveState,
  PayloadAction<AutosaveStatus>
> = (draftState, action) => {
  draftState.status = action.payload;
};

export default createReducer(initialState, builder =>
  builder.addCase(actions.updateAutosaveStatus, updateAutosaveStatusReducer)
);
