export const FETCH_USER_INFORMATION = 'FETCH_USER_INFORMATION';
export const FETCH_USER_INFORMATION_SUCCESS = 'FETCH_USER_INFORMATION_SUCCESS';
export const FETCH_USER_INFORMATION_FAIL = 'FETCH_USER_INFORMATION_FAIL';

export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAIL = 'UPDATE_USER_SETTINGS_FAIL';

export const UPDATE_USER_SETTINGS_FROM_AUTO_SAVE = 'UPDATE_USER_SETTINGS_FROM_AUTO_SAVE';
export const UPDATE_USER_SETTINGS_FROM_AUTO_SAVE_SUCCESS =
  'UPDATE_USER_SETTINGS_FROM_AUTO_SAVE_SUCCESS';
export const UPDATE_USER_SETTINGS_FROM_AUTO_SAVE_FAIL = 'UPDATE_USER_SETTINGS_FROM_AUTO_SAVE_FAIL';

export const UPDATE_USER_SETTINGS_FOR_USER_LISTS = 'UPDATE_USER_SETTINGS_FOR_USER_LISTS';
export const UPDATE_USER_SETTINGS_FOR_USER_LISTS_SUCCESS =
  'UPDATE_USER_SETTINGS_FOR_USER_LISTS_SUCCESS';
export const UPDATE_USER_SETTINGS_FOR_USER_LISTS_FAIL = 'UPDATE_USER_SETTINGS_FOR_USER_LISTS_FAIL';

export const UPDATE_USER_SETTINGS_FOR_SS_LISTS = 'UPDATE_USER_SETTINGS_FOR_SS_LISTS';
export const UPDATE_USER_SETTINGS_FOR_SS_LISTS_SUCCESS =
  'UPDATE_USER_SETTINGS_FOR_SS_LISTS_SUCCESS';
export const UPDATE_USER_SETTINGS_FOR_SS_LISTS_FAIL = 'UPDATE_USER_SETTINGS_FOR_SS_LISTS_FAIL';

export const UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS = 'UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS';
export const UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS_SUCCESS =
  'UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS_SUCCESS';
export const UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS_FAIL =
  'UPDATE_USER_SETTINGS_FOR_FAVORITES_LISTS_FAIL';

export const UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES =
  'UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES';
export const UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES_SUCCESS =
  'UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES_SUCCESS';
export const UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES_FAIL =
  'UPDATE_USER_SETTINGS_FOR_SAVED_CONTACT_SEARCHES_FAIL';

export const UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES =
  'UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES';
export const UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES_SUCCESS =
  'UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES_SUCCESS';
export const UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES_FAIL =
  'UPDATE_USER_SETTINGS_FOR_SUGGESTED_SEARCHES_FAIL';
