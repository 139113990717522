import React from 'react';
import styled from 'styled-components';
// models
import { CompanyProfile } from '@optx/models/Company';
// components
import { CheckCircleTwoTone, CloseOutlined } from '@ant-design/icons';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const PSGCompanyLabel = styled.span`
  text-align: center;
  color: #1e3a51;
  font: bold 18px 'Times New Roman';
`;

interface TableCellScoreProps {
  value: number | string | null;
  record: CompanyProfile;
  verifiedKey: 'optx_score_verified' | 'il_optx_score_verified';
  isPSGCompany?: boolean;
}

const TableCellScore: React.FC<TableCellScoreProps> = ({
  value,
  record,
  verifiedKey,
  isPSGCompany,
}) => {
  return (
    <>
      {isPSGCompany ? (
        <>
          {record[verifiedKey] ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 21, marginRight: 6, position: 'relative', top: 2 }}
            />
          ) : record[verifiedKey] === false ? (
            <CloseOutlined style={{ fontSize: 21, marginRight: 6, position: 'relative', top: 2 }} />
          ) : null}
          <PSGCompanyLabel>PSG</PSGCompanyLabel>
        </>
      ) : (
        <TruncateTooltip title={value} className="text-truncate" placement="topRight">
          {record[verifiedKey] ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 21, marginRight: 6, position: 'relative', top: 2 }}
            />
          ) : record[verifiedKey] === false ? (
            <CloseOutlined style={{ fontSize: 21, marginRight: 6, position: 'relative', top: 2 }} />
          ) : null}
          <span>{value}</span>
        </TruncateTooltip>
      )}
    </>
  );
};

export default TableCellScore;
