import styled, { css } from 'styled-components';
import { Dropdown, Typography, Menu } from 'antd';
import { styledColors } from '@optx/utils/theme/colors';

const DropdownWrapper = styled(Dropdown)`
  width: 210px;
  text-align: left;
  margin-right: 10px;
`;

const TriggerWrapper = styled.div`
  display: flex;
  align-items: center;

  .anticon {
    margin-left: auto;
  }
`;

const SelectedLabel = styled(Typography.Text)`
  color: ${styledColors.blue6};
  font-weight: 500;
  margin-left: 5px;
`;

interface MenuWrapperProps {
  $customWidth?: string;
}

const MenuWrapper = styled(Menu)<MenuWrapperProps>`
  ${props =>
    css`
      width: ${props.$customWidth ? props.$customWidth : null};
    `}
  max-height: 350px;
  overflow-y: auto;

  .ant-dropdown-menu-item {
    .anticon-ellipsis {
      visibility: hidden;
      transform: scale(1.7);
      color: ${styledColors.blue6};

      svg {
        width: 22px;
        height: 22px;
      }
    }

    &:hover {
      background-color: ${styledColors.blue1};
      .anticon-ellipsis {
        visibility: visible;
      }
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.ant-typography-ellipsis-single-line {
    vertical-align: middle;
  }
`;

export default {
  DropdownWrapper,
  TriggerWrapper,
  MenuWrapper,
  MenuItem,
  SelectedLabel,
};
