import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
// Models
import { CompanyWatchList } from '@optx/models/WatchList';
import Company from '@optx/models/Company';
// utils
import { handlePendoAction } from '@optx/utils/utils';
// redux
import { actions as modalActions } from '@redux/ui/modals/company-notes';
import { actions as modalTouchesActions } from '@redux/ui/modals/company-touches';
import { actions as modalActionsAddResearch } from '@optx/features/add-research-rationale/state';
import { actions as notesActions } from '@redux/company/notes/companyNotes';
import { selectors as listSelectors } from '@redux/lists/details';
import { actions as companyReviewActions } from '@features/company-review/state/index';
import {
  selectors as favoriteListsSelectors,
  actions as favoriteListsActions,
} from '@redux/favorite-lists/lists-popup';
import { actions as companyListsActions } from '@redux/favorite-lists/company-lists';
import { actions as companyEditAllActions } from '@optx/features/edit-all-info/state';

// get the company ID from the URL because the one from redux is read as -1 at first
const companyID = parseInt(window.location.pathname.replace('/profile/', ''));
const isFromGrid = isNaN(companyID);

export const useDropdownAdd = (
  companyId: number,
  companyName: string,
  company: Company,
  index?: number,
  noteId?: number
) => {
  // redux
  const dispatch = useDispatch();
  const toggleCompanyNotesModal = useCallback(() => {
    document.body.classList.remove('popover-visible');
    dispatch(
      modalActions.toggleCompanyNotesDialog({
        companyId,
        companyName,
      })
    );
  }, [dispatch, companyId, companyName]);

  const toggleCompanyTouchesModal = useCallback(() => {
    document.body.classList.remove('popover-visible');
    dispatch(
      modalTouchesActions.toggleCompanyTouchesDialog({
        companyId,
        companyName,
        company,
        isFromGrid,
      })
    );
  }, [dispatch, companyId, company, companyName]);

  const toggleEditAllModal = useCallback(() => {
    document.body.classList.remove('popover-visible');
    dispatch(
      companyEditAllActions.toggleCompanyEditAllFormDialog({
        companyId,
        companyName,
        company,
      })
    );
  }, [dispatch, companyId, company, companyName]);

  const toggleAddResearchRationale = useCallback(() => {
    document.body.classList.remove('popover-visible');
    dispatch(modalActionsAddResearch.toggleAddResearchRationale({ companyId }));
  }, [dispatch, companyId]);

  const filterListId = useSelector(listSelectors.getFilterListId);
  const favoritesLists: Dictionary<CompanyWatchList> = useSelector(
    favoriteListsSelectors.getAllWatchlists
  );

  // local state
  //   dropdowns
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openDropdownSelectList, setOpenDropdownSelectList] = useState(false);
  const [openRationalePopover, setRationalePopover] = useState(false);

  const [openEditNotePopover, setOpenEditNotePopover] = useState(false);

  const toggle = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const toggleEditNote = () => {
    setOpenEditNotePopover(prevState => !prevState);

    if (openEditNotePopover) {
      document.body.classList.remove('popover-visible');
    }
  };

  const toggleRationale = () => {
    setRationalePopover(prevState => !prevState);

    if (openRationalePopover) {
      document.body.classList.remove('popover-visible');
    }
  };

  const toggleSelectList = () => {
    // update List
    dispatch(favoriteListsActions.fetchFavoriteListsPopup());

    dispatch(companyListsActions.fetchListsExcludingCompany(companyId));
    setOpenDropdownSelectList(prevState => !prevState);

    if (openDropdownSelectList) {
      document.body.classList.remove('popover-visible');
    }
  };

  const deleteFromWatchList = useCallback(
    (filterListId, companyId) => {
      document.body.classList.remove('popover-visible');
      dispatch(
        favoriteListsActions.deleteCompanyFromListPopup({
          list_id: filterListId,
          company_id: companyId,
        })
      );
    },
    [dispatch]
  );

  const removeNote = useCallback(() => {
    if (noteId) {
      dispatch(notesActions.deleteCompanyNote({ companyId, noteId }));
      document.body.classList.remove('popover-visible');
    }
  }, [companyId, dispatch, noteId]);

  const companyReview = () => {
    dispatch(
      companyReviewActions.addCompanyReview({
        companyId,
      })
    );
    window.pendo.track(handlePendoAction('MarkAsReviewed'));
  };

  return {
    filterListId,
    favoritesLists,
    toggle,
    toggleSelectList,
    dropdownOpen,
    openDropdownSelectList,
    toggleCompanyNotesModal,
    toggleCompanyTouchesModal,
    toggleAddResearchRationale,
    openEditNotePopover,
    toggleEditNote,
    openRationalePopover,
    toggleRationale,
    deleteFromWatchList,
    removeNote,
    companyReview,
    toggleEditAllModal,
  };
};
