import { createSelector } from 'reselect';
// models
import { PipelineReportState } from './interfaces';
import { featureName } from '../featureName';
// constants
import { initialColumnOrderPipelineGrid } from '@optx/constants/table/columnDisplay/company-search';
import { SORT_DESCENDING_BY_DEFAULT } from '@optx/constants/table/sort/defaultSort';
// utils
import { filterSortableVisibleColumns } from '@optx/utils/table/column-visibility';
import mapTags from '@optx/utils/filters/mapTags';
import { sortColumns } from '@optx/utils/table/sorting';
// redux
import { selectors as searchSelectors } from '@redux/company-outreach/sort';
// components
import columns from '../components/Table/columns';

const pipelineReportState = (state: Record<typeof featureName, PipelineReportState>) =>
  state[featureName];

export const pipelineReportCompanies = createSelector(
  pipelineReportState,
  state => state.companies.data
);
export const companiesCount = createSelector(
  pipelineReportState,
  state => state.companies.count ?? 0
);
export const pipelineReportFilters = createSelector(pipelineReportState, state => state.filters);
export const pipelineReportOpportunities = createSelector(
  pipelineReportState,
  state => state.opportunities
);
export const pipelineReportCardLoading = createSelector(
  pipelineReportState,
  state => state.loading
);

export const pipelineReportCompanyLoading = createSelector(
  pipelineReportState,
  state => state.companies.loading
);

export const pipelineReportLoading = createSelector(
  pipelineReportState,
  state => state.companies.loading || state.loading
);

export const filterModalIsOpen = createSelector(pipelineReportState, state => state.modalIsOpen);
export const totalFund = createSelector(pipelineReportState, state => state.totalFund);
export const fundAssociation = createSelector(pipelineReportState, state => state.fundAssociation);

export const getFilters = createSelector(pipelineReportState, state => {
  const { normalized, filter, values, clear } = state.filters;

  return { normalized, filter, values, clear };
});

export const getValues = createSelector(pipelineReportState, state => {
  const { values } = state.filters;

  return { ...values };
});

export const getFilterSources = createSelector(pipelineReportState, state => {
  const { data } = state.filters;

  return data;
});

export const getTags = createSelector(pipelineReportFilters, filters => {
  const { data, values, clear } = filters;

  return mapTags(values, data, clear, []);
});

export const isClearedFilter = createSelector(getTags, tags => {
  const tagNotDefault = tags.find(tag => {
    if (tag.isDefault === false && tag.values?.length > 0) {
      return true;
    }

    return false;
  });

  return !!tagNotDefault;
});

export const saveSearchLoading = createSelector(
  pipelineReportState,
  state => state.saveSearchLoading
);

export const getColumnDisplay = createSelector(
  pipelineReportState,
  state => state.companies.columnDisplay
);

/**
 * Get displayed columns.
 * Used for defining the columns table
 */
export const getDisplayedColumns = createSelector(getColumnDisplay, columnDisplay =>
  filterSortableVisibleColumns(columns, columnDisplay, initialColumnOrderPipelineGrid)
);

export const getColumns = createSelector(
  getDisplayedColumns,
  searchSelectors.getSorting,
  searchSelectors.isMultiSort,
  (columns, sorting, isMultiSort) =>
    // @ts-ignore
    sortColumns(columns, sorting, {
      isMultiSort,
      sortDirectionsKeys: isMultiSort ? undefined : SORT_DESCENDING_BY_DEFAULT,
    })
);

export const getCardStatus = createSelector(pipelineReportState, state => state.cardHidden);
