import { useEffect, useRef } from 'react';

export function useDebounceEffect(fn: () => void, waitTime: number, deps?: []) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      return;
    }

    const t = setTimeout(() => {
      fn.apply(undefined, deps || []);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
