import styled from 'styled-components';
import { Title } from '@optx/shared/view/atoms/Typography';
import { Row, Col } from 'antd';

const SearchGridWrapper = styled(Row)`
  background-color: rgb(240, 242, 245);
  min-height: 66px;
  padding: 1rem 40px;
`;

const ControlsWrapper = styled(Col)`
  margin-left: auto;

  .ant-btn {
    margin-left: 8px;
  }

  .ant-btn-icon-only {
    margin-left: 4px;
  }
`;

const PageTitle = styled(Title)`
  h1& {
    flex: 1 1 20%;
    margin: 0;
  }

  h1& > span span {
    &:first-child {
      max-width: 65%;
    }
    &:last-child {
      width: 35%;
    }
  }
`;

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export default {
  SearchGridWrapper,
  PageTitleWrapper,
  ControlsWrapper,
  PageTitle,
};
