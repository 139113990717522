import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { Select } from 'antd';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { filterOption } from '@utils/select/select';
// redux
import { actions as userActions } from '@redux/user/information';
import {
  actions as salesloftActions,
  selectors as salesloftSelectors,
} from '@redux/contacts/salesloft';
import {
  getFormValues,
  getData,
  getTemporaryFormValues,
} from '@redux/company/equity-touch/selectors';
import { selectors as primaryContactsSelectors } from '@redux/company/primary-contacts';
import { updateTemporaryFormValues } from '@redux/company/equity-touch/actions';

const { Option } = Select;

interface SelectCadenceOptionalProps {
  errors: string[];
}

interface ProfileRouteMatch {
  id: string;
}

const SelectCadenceOptional: React.FC<SelectCadenceOptionalProps> = ({ errors }) => {
  const dispatch = useDispatch();

  // get company id from route
  const match = useRouteMatch<ProfileRouteMatch>();
  const {
    params: { id },
  } = match;
  const options = useSelector(salesloftSelectors.getCadences);
  const formData = useSelector(getData);
  const formValues = useSelector(getFormValues);
  const temporaryFormValues = useSelector(getTemporaryFormValues);
  const isAddEquityTouch = window.location.href.includes('add-to-equity-touch');
  const [value, setValue] = useState<number | undefined>(undefined);
  const contacts = useSelector(primaryContactsSelectors.getPrimaryContacts);

  const valuesExists = Object.keys((formValues as any) || {}).length !== 0;
  useEffect(() => {
    if (valuesExists && formValues?.cadence) {
      setValue(formValues?.cadence as number);
      dispatch(
        salesloftActions.salesloftSelectCadence({
          ...(options.find(option => option.id === formValues?.cadence) as any),
          company_id: Number(id),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.cadence]);

  const handleChange = (valueData: number) => {
    const cadenceValue = options.find(option => option.id === valueData);
    dispatch(
      salesloftActions.salesloftSelectCadence({ ...(cadenceValue as any), company_id: Number(id) })
    );
    setValue(valueData);
    const salesloftContacts = [
      ...(temporaryFormValues.salesloft_contacts as number[]),
      ...(formValues.salesloft_contacts as number[]),
    ];

    if (isAddEquityTouch) {
      dispatch(
        updateTemporaryFormValues({
          ...temporaryFormValues,
          cadence: valueData,
          salesloft_contacts: Array.from(new Set(salesloftContacts)),
        })
      );

      dispatch(
        userActions.updateUserSettings({
          et_form_auto_save: {
            values: {
              ...temporaryFormValues,
              ...(formValues as any),
              cadence: valueData,
              salesloft_contacts: Array.from(new Set(salesloftContacts)),
            },
            formData: formData as any,
            date: moment().format(ISO_DATE_FORMAT),
          },
        } as Partial<any>)
      );
    }
  };

  const hasPrimaryContact = contacts.filter((item, index) => {
    return item.primary_contact === true;
  });

  return (
    <>
      <Select
        showSearch
        placeholder="Select a cadence"
        onChange={handleChange}
        optionFilterProp="children"
        filterOption={filterOption}
        value={value}
        disabled={contacts.length === 0 && hasPrimaryContact.length === 0}
      >
        <Option key="blank" value="">
          Select a cadence
        </Option>
        {options.map(option => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
      {!!errors.length &&
        errors.map((error, index) => (
          <Text key={index} type="danger" style={{ display: 'block' }}>
            {error}
          </Text>
        ))}
    </>
  );
};

export default SelectCadenceOptional;
