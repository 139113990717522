/* eslint-disable max-len */
import React, { useRef, useMemo, ReactNodeArray } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
import { Col } from 'antd';
import { Formik, FormikValues, FormikProps } from 'formik';
// models
import { PreselectedFilter, HistogramGroup, HistogramType } from '@optx/models/filters';
import { FilterTag } from '@optx/models/tags';
// utils
import { mapSourcesByFilter } from '@utils/filters/mapSourcesByFilter';
import validationSchema from '@utils/filtersValidationSchema';
// redux
import { selectors as companyFiltersSelectors } from '@optx/redux/company/filters';
// components
import {
  IFiltersContext,
  FiltersContext,
} from '@optx/components/feature/company-filters/FiltersContext';
import SaveSearch from '@optx/components/pages/Home/Header/SaveSearch/SaveSearch';
import CompanySearchDropdown from '@optx/components/pages/Home/Header/CompanySearchDropdown';
import { StyledAddonMultiSelect as AddonMultiSelect } from '@optx/components/pages/CompanyProfile/ProfileTabs/Addon/components/AddonMultiSelect.styled';
import SecondaryQuickFilter from './SecondaryQuickFilter';
import Styled from './SecondaryQuickFilters.styles';
import LocationFilterGroup from './LocationFilterGroup';
import { FilterTagsSkeleton } from '@optx/components/common/skeletons';

interface SecondaryQuickFiltersPanelProps {
  onSearch: (searchKey: string, filter: Dictionary<PreselectedFilter>) => void;
  keySearch: string;
  histograms: HistogramGroup<HistogramType>;
  filter: Dictionary<PreselectedFilter>;
  options: FilterTag[];
  viewId?: string | number;
  isMyCompany?: boolean;
  isAddonManagement?: boolean;
  filtersLoaded?: boolean;
}

const SecondaryQuickFiltersPanel: React.FC<SecondaryQuickFiltersPanelProps> = ({
  onSearch,
  keySearch,
  histograms,
  filter,
  options,
  viewId,
  isMyCompany,
  isAddonManagement,
  filtersLoaded,
  children,
}) => {
  const filterSources = useSelector(companyFiltersSelectors.getCompanyFilters);
  const secondaryQuickFilters = useSelector(companyFiltersSelectors.getSecondaryQuickFiltersMap);
  const secondaryQuickFiltersAddonManagement = useSelector(
    companyFiltersSelectors.getSecondaryQuickFiltersMapAddonManagement
  );
  const formRef = useRef<FormikProps<FormikValues> | null>(null);

  const sourcesByFilter = useMemo(() => mapSourcesByFilter(filterSources), [filterSources]);

  // Filtered from location filters(to don't show them by default)
  const withoutLocation = useMemo(() => {
    if (isAddonManagement) {
      return Object.values(secondaryQuickFiltersAddonManagement).filter(
        singleFilter => !singleFilter.is_location
      );
    }

    return Object.values(secondaryQuickFilters).filter(singleFilter => !singleFilter.is_location);
  }, [isAddonManagement, secondaryQuickFilters, secondaryQuickFiltersAddonManagement]);

  // search
  const handleSearch = (instance?: FormikProps<FormikValues>) => {
    const submittedValues = instance ? instance.values : formRef.current!.values;

    onSearch(keySearch, submittedValues);
  };

  // form submit
  const handleSubmit = () => {
    handleSearch();
  };

  const filtersContext: IFiltersContext = {
    histograms,
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <FiltersContext.Provider value={filtersContext}>
      <Styled.SecondaryQuickFiltersPanel align="middle" justify="space-between">
        <Col span={21}>
          {!filtersLoaded ? (
            <FilterTagsSkeleton length={5} align="flex-start" />
          ) : (
            <Formik
              initialValues={filter}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={validationSchema}
              innerRef={formRef}
            >
              <Styled.FiltersWrapper
                onKeyDown={handleDropdownKeyEvent}
                tabIndex={0}
                className={isAddonManagement ? 'addon-management-filters' : ''}
              >
                {Object.values(withoutLocation).map(singleFilter => (
                  <SecondaryQuickFilter
                    options={options}
                    key={singleFilter.column}
                    filter={singleFilter}
                    source={sourcesByFilter[singleFilter.column]}
                    onApply={handleSubmit}
                    onReset={() => {
                      formRef.current!.resetForm(filter);
                    }}
                    isMyCompany={isMyCompany}
                    isAddon={isAddonManagement}
                  />
                ))}

                {!isAddonManagement && (
                  <LocationFilterGroup
                    options={options}
                    sources={filterSources}
                    filters={
                      isAddonManagement
                        ? secondaryQuickFiltersAddonManagement
                        : secondaryQuickFilters
                    }
                    onReset={() => {
                      formRef.current!.resetForm(filter);
                    }}
                  />
                )}
              </Styled.FiltersWrapper>
            </Formik>
          )}
          {isAddonManagement && <AddonMultiSelect filterKey="Origin" allowSelectAll={true} />}
        </Col>
        <Styled.ButtonsWrapper>
          {!isAddonManagement && <SaveSearch viewId={viewId} />}
          {(children as ReactNodeArray)[0]}
          <CompanySearchDropdown isAddonManagement={isAddonManagement}>
            {(children as ReactNodeArray)[1]}
          </CompanySearchDropdown>
        </Styled.ButtonsWrapper>
      </Styled.SecondaryQuickFiltersPanel>
    </FiltersContext.Provider>
  );
};

export default SecondaryQuickFiltersPanel;
