import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Card, Radio, Tooltip as AntdTooltip, Tag } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  Line,
  LineChart,
  Legend,
} from 'recharts';
import moment from 'moment';
// models
import { ChartData, ChartIntervalKeys, ChartItem, ChartItemKeys } from '@models/charts/data';
// constants
import globalConfig from '@optx/constants/config';
import { ISO_DATE_FORMAT, SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
import { CompanyPageOverviewPendo, OPTXChromePlugin } from '@optx/constants/pendoActions';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { getFirstValidDate, getChartTicks } from '@utils/charts';
// components
import { ScoreTrending } from '@components/common/trending';
import KeyPerformanceIndicator from '@components/common/Text/KeyPerformanceIndicator';
import { ReactComponent as Gradients } from '@assets/svg/gradient-definitions.svg';
import EmptyChart from './EmptyChart';
import LineChartTooltip from './LineChartTooltip';
import CombinedChartTicks from './CombinedChartTicks';
import Styles from './ChartHeader.style';

interface LinearChartProps {
  data: ChartData;
  isLoading?: boolean;
  dataKey: string;
  dataSecondaryKey?: string;
  labelKey: string;
  title: string;
  kpiLabel: string;
  newKPILabel?: string;
  className?: string;
  isTrending?: boolean;
  psgDate?: string | null;
  headerValue?: number;
  newHeaderValue?: number;
  growth?: string;
  newGrowth?: string;
  isPSGCompany?: boolean;
  isExtension?: boolean;
  legend?: React.ReactElement;
  scoreType?: string;
}

interface DotProps {
  value: number | null;
  payload: ChartItem;
  stroke: string;
  cx: number;
  cy: number;
  fill: string;
}

const NewTag = styled(Tag)`
  margin-right: 25px;
  border-color: #a0d911;
`;

const LinearChart: React.FC<LinearChartProps> = ({
  data,
  isLoading = false,
  dataKey,
  dataSecondaryKey,
  labelKey,
  title,
  kpiLabel,
  newKPILabel,
  isTrending,
  headerValue,
  newHeaderValue,
  growth,
  newGrowth,
  psgDate,
  isPSGCompany = false,
  isExtension = false,
  legend,
  scoreType,
}) => {
  const [interval, setIntervalValue] = useState<'' | ChartIntervalKeys>('');
  const [maxValue, setMaxValue] = useState<string | number | undefined | null>(DEFAULT_EMPTY_VALUE);
  const [minValue, setMinValue] = useState<string | number | undefined | null>(DEFAULT_EMPTY_VALUE);
  const [growthClassName, setGrowthClassName] = useState('');
  const [newMaxValue, setNewMaxValue] = useState<string | number | undefined | null>(
    DEFAULT_EMPTY_VALUE
  );
  const [newMinValue, setNewMinValue] = useState<string | number | undefined | null>(
    DEFAULT_EMPTY_VALUE
  );
  const [newGrowthClassName, setNewGrowthClassName] = useState('');
  const [chartPoints, setChartPoints] = useState<ChartItem[]>([]);
  const [historyStartsWidth, setHistoryStartsWidth] = useState<string | undefined>();
  const [ticks, setTicks] = useState<Array<string>>([]);
  const [formatGrowth, setFormatGrowth] = useState<number | null>(null);
  const [newFormatGrowth, setNewFormatGrowth] = useState<number | null>(null);
  const [psgDotCoordinate, setPsgDotCoordinate] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const areaRef = useRef<any>();
  const areDefaultTicksSetRef = useRef<boolean>(false);

  let defaultKey = '3M' as ChartIntervalKeys;

  if (data['2Y'].data.length) {
    defaultKey = '2Y';
  } else if (data['1Y'].data.length) {
    defaultKey = '1Y';
  } else if (data['6M'].data.length) {
    defaultKey = '6M';
  }

  const updateGrowth = (key: ChartIntervalKeys) => {
    if (!isNaN(Number(data[key]?.growth))) {
      return setFormatGrowth(data[key]?.growth as number);
    }

    return setFormatGrowth(null);
  };

  const updateNewGrowth = (key: ChartIntervalKeys) => {
    if (!isNaN(Number(data[key]?.v3_growth))) {
      return setNewFormatGrowth(data[key]?.v3_growth as number);
    }

    return setNewFormatGrowth(null);
  };

  const handleSizeChange = (e: RadioChangeEvent) => {
    const key = e.target.value as ChartIntervalKeys;

    setIntervalValue(key);
    setMaxValue(data[key]?.max);
    setMinValue(data[key]?.min);
    setChartPoints((data[key]?.data || []).filter(item => item?.quarter !== ''));
    updateGrowth(key);

    setNewMaxValue(data[key]?.v3_max);
    setNewMinValue(data[key]?.v3_min);
    updateNewGrowth(key);

    if (data[key]?.growth! > 0) {
      setGrowthClassName('kpi--positive');
    } else if (data[key]?.growth! < 0) {
      setGrowthClassName('kpi--negative');
    } else {
      setGrowthClassName('');
    }

    if (data[key]?.v3_growth! > 0) {
      setNewGrowthClassName('kpi--positive');
    } else if (data[key]?.v3_growth! < 0) {
      setNewGrowthClassName('kpi--negative');
    } else {
      setNewGrowthClassName('');
    }

    setTicks(getChartTicks(data, key));
  };

  useEffect(() => {
    updateGrowth(interval || defaultKey);
    updateNewGrowth(interval || defaultKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[defaultKey], interval, defaultKey]);

  useEffect(() => {
    if (data[defaultKey].data.length) {
      // calculate and save the width of "history starts from" element
      // it's width will be proportional to the number of empty labels compared to
      // the number of data points that have values
      if (defaultKey === '3M') {
        let emptyLabels = 0;

        data[defaultKey].data.forEach((item: ChartItem) => {
          if (item[dataKey as ChartItemKeys] === undefined) {
            emptyLabels++;
          }
        });

        if (emptyLabels) {
          setHistoryStartsWidth(`calc(${data[defaultKey].data[1]?.position!}% - 90px`);
        }
      }

      let points: ChartItem[] = [];

      if (!interval) {
        setIntervalValue(defaultKey);
        points = data[defaultKey].data;
      } else {
        points = data[interval].data;
      }

      setChartPoints(points.filter(item => item.quarter !== ''));

      if (!areDefaultTicksSetRef.current) {
        setTicks(getChartTicks(data, defaultKey));
        areDefaultTicksSetRef.current = true;
      }
    }

    if (defaultKey === interval) {
      setMaxValue(data[defaultKey]?.max);
      setMinValue(data[defaultKey]?.min);

      setNewMaxValue(data[defaultKey]?.v3_max);
      setNewMinValue(data[defaultKey]?.v3_min);

      if (data[defaultKey]?.growth! > 0) {
        setGrowthClassName('kpi--positive');
      } else if (Number(growth) < 0) {
        setGrowthClassName('kpi--negative');
      } else {
        setGrowthClassName('');
      }

      if (data[defaultKey]?.v3_growth! > 0) {
        setNewGrowthClassName('kpi--positive');
      } else if (Number(newGrowth) < 0) {
        setNewGrowthClassName('kpi--negative');
      } else {
        setNewGrowthClassName('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, interval]);

  useEffect(() => {
    if (isPSGCompany && areaRef.current) {
      const points = areaRef.current.props.points;

      if (points?.length > 0) {
        points.map((item: any, index: number) => {
          const psgDateMoment = moment(psgDate, ISO_DATE_FORMAT);
          const itemDateMoment = moment(item.payload.date, ISO_DATE_FORMAT);

          if (psgDateMoment.isSameOrBefore(itemDateMoment)) {
            if (
              psgDateMoment.isBetween(
                moment(points[index - 1]?.date, ISO_DATE_FORMAT),
                itemDateMoment,
                undefined,
                '()'
              )
            ) {
              setPsgDotCoordinate({ x: item.x - 45, y: isExtension ? -30 : -40 });

              return;
            } else if (index === 0 && psgDateMoment.isSameOrBefore(itemDateMoment)) {
              setPsgDotCoordinate({ x: item.x, y: isExtension ? -30 : -40 });

              return;
            } else if (index === points.length - 1 && psgDateMoment.isSame(itemDateMoment)) {
              setPsgDotCoordinate({ x: item.x - 16, y: isExtension ? -30 : -40 });

              return;
            } else if (psgDateMoment.isSame(itemDateMoment)) {
              setPsgDotCoordinate({ x: item.x - 16, y: isExtension ? -30 : -40 });

              return;
            }
          } else if (psgDateMoment.isSameOrAfter(itemDateMoment)) {
            setPsgDotCoordinate({ x: item.x - 16, y: isExtension ? -30 : -40 });

            return;
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartPoints, interval]);

  const handleActionPendo = (value: string) => {
    const chromeExtensionExists = document.getElementsByClassName('extension-tabs').length;

    if (chromeExtensionExists) {
      window.pendo.track(
        OPTXChromePlugin.companyPage[
          `optxScore${value.toUpperCase()}` as keyof typeof OPTXChromePlugin.companyPage
        ]
      );
    } else {
      window.pendo.track(
        CompanyPageOverviewPendo[`optxScore${value}` as keyof typeof CompanyPageOverviewPendo]
      );
    }
  };

  const header = (
    <Styles.ChartHeaderWrapper>
      <Styles.ChartTitleWrapper>{title}</Styles.ChartTitleWrapper>
      <Radio.Group value={interval} onChange={handleSizeChange}>
        {Object.keys(data).map((key, index) => {
          const isDisabled = !data[key as ChartIntervalKeys].data.length;

          return (
            <Radio.Button
              key={index}
              value={key}
              disabled={isDisabled}
              onClick={() => handleActionPendo(key)}
            >
              {key}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </Styles.ChartHeaderWrapper>
  );

  const extra = (
    <div className={isTrending ? 'profile-chart-card__trending' : ''}>
      <KeyPerformanceIndicator
        label="High"
        value={typeof maxValue === 'number' ? maxValue!.toString() : DEFAULT_EMPTY_VALUE}
      />
      <KeyPerformanceIndicator
        label="Low"
        value={typeof minValue === 'number' ? minValue!.toString() : DEFAULT_EMPTY_VALUE}
      />
      {isTrending ? (
        <ScoreTrending
          score={headerValue}
          growth={formatGrowth}
          label={kpiLabel}
          customFontSize="16px"
          isPSGCompany={isPSGCompany}
        />
      ) : (
        <KeyPerformanceIndicator
          label={kpiLabel}
          value={typeof growth === 'number' ? `${growth}%` : DEFAULT_EMPTY_VALUE}
          className={growthClassName}
        />
      )}
    </div>
  );

  const betaScoreExtra = (
    <div className={isTrending ? 'profile-chart-card__trending' : ''}>
      <NewTag color="#7CB305">NEW</NewTag>
      <KeyPerformanceIndicator
        label="High"
        value={typeof newMaxValue === 'number' ? newMaxValue!.toString() : DEFAULT_EMPTY_VALUE}
      />
      <KeyPerformanceIndicator
        label="Low"
        value={typeof newMinValue === 'number' ? newMinValue!.toString() : DEFAULT_EMPTY_VALUE}
      />
      {isTrending ? (
        <ScoreTrending
          score={newHeaderValue}
          growth={newFormatGrowth}
          label={newKPILabel || 'OPTX BETA'}
          customFontSize="16px"
          isPSGCompany={isPSGCompany}
          infoText={
            'We are beta testing modifications to our OPTX score algorithm to improve accuracy. ' +
            'Please provide feedback in the Key Info Checklist.'
          }
          isExtension={isExtension}
        />
      ) : (
        <KeyPerformanceIndicator
          label={newKPILabel || 'OPTX BETA'}
          value={typeof newGrowth === 'number' ? `${newGrowth}%` : DEFAULT_EMPTY_VALUE}
          className={newGrowthClassName}
        />
      )}
    </div>
  );

  const renderDot = ({ value, payload, stroke, cx, cy }: DotProps) => {
    if (!value && value !== 0) return null;

    return (
      <circle
        key={`dot-${JSON.stringify(payload)}-${stroke[6]}`}
        cx={cx}
        cy={cy}
        r={4}
        stroke={stroke}
        strokeWidth={1}
        fill="#fff"
      />
    );
  };

  const renderActiveDot = ({ value, fill, cx, cy }: DotProps) => {
    if (!value && value !== 0) return null;

    let color = fill,
      xCoord = cx,
      yCoord = cy;

    return <circle cx={xCoord} cy={yCoord} r={1.5} stroke={color} strokeWidth={1} fill={color} />;
  };

  return (
    <Card title={header} extra={extra} className="profile-chart-card extra us">
      {!isLoading && (
        <div className="beta-score-extra-wrapper">
          {legend && chartPoints.length > 0 && (
            <Legend
              content={legend}
              align="left"
              verticalAlign="top"
              height={0}
              margin={{ top: isExtension ? 90 : 65, left: 24 }}
            />
          )}
          {dataSecondaryKey && (
            <div
              className={
                chartPoints.length > 0 && isPSGCompany && psgDate
                  ? 'beta-score-extra psg'
                  : 'beta-score-extra'
              }
            >
              {betaScoreExtra}
            </div>
          )}
        </div>
      )}
      <Gradients />
      {isLoading ? (
        <Styles.LoadingIconWrapper>
          <Styles.LoadingIcon />
        </Styles.LoadingIconWrapper>
      ) : (
        <div className="profile-chart-card__container">
          <ResponsiveContainer>
            <LineChart data={chartPoints} margin={{ left: 24, top: 5 }}>
              <XAxis
                dataKey={labelKey}
                tickLine={false}
                stroke="#C2CFE0"
                tickSize={15}
                ticks={ticks}
                interval={0}
                type="number"
                tick={props => (
                  <CombinedChartTicks
                    startDate={chartPoints.length ? chartPoints[0].date : ''}
                    endDate={chartPoints.length ? chartPoints[chartPoints.length - 1].date : ''}
                    intervalKey={interval}
                    {...props}
                  />
                )}
              />
              <YAxis
                orientation="right"
                tickCount={2}
                tickMargin={10}
                tickLine={false}
                axisLine={false}
                stroke="#C2CFE0"
                type="number"
              />
              <Tooltip
                wrapperStyle={{ zIndex: 1 }}
                content={(props: TooltipProps) => {
                  if (!dataSecondaryKey) {
                    return (
                      <LineChartTooltip
                        primaryKey={dataKey}
                        primaryLabel={
                          scoreType === 'us' ? 'OPTX Score Value:' : 'IL OPTX Score Value:'
                        }
                        {...props}
                      />
                    );
                  }

                  return (
                    <LineChartTooltip
                      primaryKey={dataKey}
                      secondaryKey={dataSecondaryKey}
                      primaryLabel={
                        scoreType === 'us' ? 'OPTX Score Value:' : 'IL OPTX Score Value:'
                      }
                      secondaryLabel="OPTX Score BETA Value:"
                      {...props}
                    />
                  );
                }}
                cursor={false}
              />
              <Line
                type="monotone"
                dataKey={dataKey}
                stroke="#1890FF"
                strokeWidth="2px"
                dot={renderDot}
                ref={areaRef}
                connectNulls
                activeDot={renderActiveDot}
              />
              {dataSecondaryKey && (
                <Line
                  type="monotone"
                  dataKey={dataSecondaryKey}
                  stroke="#219653"
                  strokeWidth="2px"
                  dot={renderDot}
                  connectNulls
                  activeDot={renderActiveDot}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
          {!chartPoints.length && <EmptyChart text={isPSGCompany ? 'PSG Company' : undefined} />}
          {!isPSGCompany &&
            !!historyStartsWidth &&
            !data[Object.keys(data)[1] as ChartIntervalKeys].data.length && (
              <EmptyChart
                className="chart-empty-labels"
                text={`History Starts ${moment(
                  getFirstValidDate(data[defaultKey].data)?.date
                ).format(SHORT_MONTH_YEAR_DATE_FORMAT)}`}
                width={historyStartsWidth}
              />
            )}
          {chartPoints.length > 0 && isPSGCompany && psgDate && (
            <div
              style={{
                position: 'absolute',
                top: psgDotCoordinate.y,
                left: psgDotCoordinate.x,
                background: '#214d89',
                width: 32,
                borderRadius: 32,
                height: 32,
                fontFamily: 'Times New Roman',
                lineHeight: '32px',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <AntdTooltip
                title={moment(psgDate, ISO_DATE_FORMAT).format(globalConfig.grid_date.DATE_FORMAT)}
              >
                PSG
              </AntdTooltip>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default LinearChart;
