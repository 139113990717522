import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'react-router-redux';
// models
import { AxiosResponse } from 'axios';
import { CompanyBulkEditingResults } from '@optx/models/bulkActions';
import { SelectOption } from '@optx/models/Option';
// constants
import {
  BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST,
  BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST,
} from '@optx/config/bulkConfig';
import { LISTS_ROUTES } from '@optx/constants/routes';
// services
import { UserService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import {
  addCompanyToListBulkActionSuccess,
  initialFetchFavoritesLists,
} from '@redux/favorite-lists/lists/actions';
import { selectors as userSelectors } from '@redux/user/information';
import { actions as searchActions } from '@features/grid/search';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { initialFetchFavoritesListsPopup } from '@optx/redux/favorite-lists/lists-popup/actions';
import { actions as listActions } from '@redux/favorite-lists/lists';
import { actions as editFieldActions } from '@features/bulk-actions/edit-fields';
import * as actions from './actions';
import { BulkAddToWatchList, BulkRemoveFromWatchList, BulkWatchlistResponse } from './interfaces';

function* bulkAddToWatchListSaga(action: PayloadAction<BulkAddToWatchList>) {
  const { selectedCompanies, selectedData, field } = action.payload;
  const companies = [...selectedCompanies];

  const existingListIds: number[] = [];
  const newTitles: string[] = [];

  selectedData.flat().map(data => {
    if (data.value !== data.label) {
      return existingListIds.push(Number(data.value));
    }

    return newTitles.push(data.label.toString());
  });

  const selectedCompaniesCount: number = yield select(
    bulkActionsSelectors.editFields.selectedCompaniesCount
  );

  const companiesBatch = companies.slice(0, BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST);
  const company_ids = companiesBatch.map(company => company.company_id);

  try {
    const res: AxiosResponse<BulkWatchlistResponse[]> = yield call(
      UserService.bulkAddToWatchlist,
      company_ids,
      existingListIds,
      newTitles
    );

    if (res.data) {
      const failedCompanies = res.data;
      const currentResults: CompanyBulkEditingResults[] = yield select(
        bulkActionsSelectors.editFields.results
      );
      const nextResults: CompanyBulkEditingResults[] = [];

      yield put(
        editFieldActions.updatePercentage(
          Math.round((BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST / selectedCompaniesCount) * 100)
        )
      );

      companiesBatch.forEach(company => {
        const { company_id: id, company_url, company_name, raw_url } = company;

        const failedCompany = failedCompanies.filter(company => company.company_id === id);
        const failedIds = failedCompany.reduce((acc: number[], item) => {
          if (!item.status) {
            if (acc.length === 0) {
              return [item.list_id];
            }

            acc.push(item.list_id);

            return acc;
          }

          return acc;
        }, []);

        const allErrorMessage = failedCompany
          .filter(item => item.message !== 'Success')
          .map(item => item.message);
        const errorMessage = failedCompany[0].message;

        const errorForSomeList = `Company was not added to: '${selectedData
          .flat()
          .reduce((acc: string[], item: SelectOption) => {
            if (failedIds.includes(Number(item.value)) && item.label) {
              if (acc.length === 0) {
                return [item.label];
              }

              acc.push(item.label);

              return acc;
            }

            return acc;
          }, [])
          .join(', ')}'\n \n Reason: ${Array.from(new Set(allErrorMessage)).join(', ')}`;

        nextResults.push({
          company_id: id,
          company_url,
          raw_url,
          company_name,
          sync_status: errorMessage === 'Success' && failedIds.length === 0,
          error_reason:
            errorMessage === 'Success' && failedIds.length === 0 ? '' : errorForSomeList,
        });
      });

      const listIds = failedCompanies.map(company => company.list_id);
      const uniqueListIds = listIds.filter((listId, index, arr) => arr.indexOf(listId) === index);
      let updatedSelectedData: SelectOption[] = [];

      // replacing temporary string ids with final ids for newly created lists
      selectedData.flat().map((data, index) => {
        const updatedData: SelectOption = {
          value: uniqueListIds[index].toString(),
          label: data.label,
          isET: data.isET,
        };
        updatedSelectedData = [...updatedSelectedData, updatedData];

        return updatedSelectedData;
      });

      const finalResults = [...currentResults, ...nextResults];

      yield put(editFieldActions.saveResults(finalResults));

      companies.splice(0, BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST);
      const cancelSearch: boolean = yield select(bulkActionsSelectors.editFields.cancel);

      if (!cancelSearch) {
        if (companies.length) {
          yield put(
            actions.bulkAddToWatchList({
              selectedCompanies: companies,
              selectedData: updatedSelectedData,
              field,
            })
          );
        } else {
          yield put(editFieldActions.updateProgress(false));
          yield put(editFieldActions.updateCompleted(true));
          yield put(editFieldActions.updatePercentage(100));

          const successfulResults = finalResults.filter(company => company.sync_status);

          if (successfulResults) {
            yield put(
              editFieldActions.bulkEditFieldSuccess({
                companyIds: successfulResults.map(company => company.company_id),
                selectedData: updatedSelectedData,
                field,
              })
            );

            if (field === 'watchlist') {
              const ownerName: string = yield select(userSelectors.getFullName);

              yield put(
                addCompanyToListBulkActionSuccess({
                  companyIds: successfulResults.map(company => company.company_id),
                  watchLists: updatedSelectedData,
                  ownerName,
                })
              );
              yield put(initialFetchFavoritesLists());
              yield put(initialFetchFavoritesListsPopup());
            }
          }
        }
      } else {
        const canceledCompanies: CompanyBulkEditingResults[] = [];
        const prevResults: CompanyBulkEditingResults[] = yield select(
          bulkActionsSelectors.editFields.results
        );

        if (companies.length) {
          companies.forEach(company => {
            const { company_id: id, company_url, company_name, raw_url } = company;
            canceledCompanies.push({
              company_id: id,
              company_url,
              raw_url,
              company_name,
              sync_status: false,
              error_reason: 'Canceled by user',
            });
          });
        }

        yield put(editFieldActions.saveResults([...prevResults, ...canceledCompanies]));
        yield put(editFieldActions.updateProgress(false));
        yield put(editFieldActions.updateCompleted(true));
        yield put(editFieldActions.cancel(false));
        yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
      }
    }
  } catch (e: any) {
    const message = getErrorMessage(e, 'Failed to add to Watchlist!');
    yield put(editFieldActions.updateProgress(false));
    yield put(editFieldActions.updateCompleted(true));
    yield put(editFieldActions.updatePercentage(100));
    NotificationService.error(message);
  }
}

function* bulkRemoveFromWatchListSaga(action: PayloadAction<BulkRemoveFromWatchList>) {
  const { selectedCompanies, listId, listDetailCount, isRemoveFromAddonManagement } =
    action.payload;
  const companies = [...selectedCompanies];

  const companiesBatch = selectedCompanies.slice(0, BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST);

  const listDetailCountBatch = listDetailCount - companiesBatch.length;
  const company_ids = companiesBatch.map(company => company.company_id);

  const selectedCompaniesCount: number = yield select(
    bulkActionsSelectors.editFields.selectedCompaniesCount
  );

  const { pageNumber, pageSize } = yield select(paginationSelectors.getPagination('watchlists'));

  try {
    const res: AxiosResponse<BulkWatchlistResponse[]> = yield call(
      UserService.bulkRemoveFromWatchList,
      company_ids,
      listId
    );
    companies.splice(0, BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST);
    const cancelSearch: boolean = yield select(bulkActionsSelectors.editFields.cancel);

    if (res.data) {
      const failedCompanies = res.data;
      const currentResults: CompanyBulkEditingResults[] = yield select(
        bulkActionsSelectors.editFields.results
      );
      const nextResults: CompanyBulkEditingResults[] = [];

      yield put(
        editFieldActions.updatePercentage(
          Math.round((BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST / selectedCompaniesCount) * 100)
        )
      );

      companiesBatch.forEach(company => {
        const { company_id: id, company_url, company_name, raw_url } = company;
        const failedCompany = failedCompanies.filter(company => company.company_id === id);
        const errorMessage = failedCompany[0].message;

        nextResults.push({
          company_id: id,
          company_url,
          raw_url,
          company_name,
          sync_status: errorMessage === 'Success',
          error_reason: errorMessage === 'Success' ? '' : errorMessage,
        });
      });

      const finalResults = [...currentResults, ...nextResults];
      yield put(editFieldActions.saveResults(finalResults));
    }

    if (!cancelSearch) {
      if (companies.length) {
        yield put(
          actions.bulkRemoveFromWatchList({
            listId: listId,
            selectedCompanies: companies,
            listDetailCount: listDetailCountBatch,
            isRemoveFromAddonManagement,
          })
        );
      } else {
        yield put(editFieldActions.updateProgress(false));
        yield put(editFieldActions.updateCompleted(true));
        yield put(editFieldActions.updatePercentage(100));

        if (listId !== 'favorites') {
          yield put(
            listActions.updateFavoriteListCount({
              list_id: listId,
              count: listDetailCountBatch,
            })
          );
        }

        yield take(searchActions.initialCompaniesSearch);

        if (listDetailCountBatch === 0) {
          const isSourceScrub = window.location.pathname.substr(1).startsWith('ss');

          if (isSourceScrub) {
            yield put(push(LISTS_ROUTES.sourceScrubLists));
          } else {
            yield put(push(LISTS_ROUTES.myWatchList));
          }
        } else {
          // if current page is the last and all companies were removed, then set pagination to the new last (previous)
          const newLastPageNumber = Math.ceil(listDetailCountBatch / pageSize);
          const shouldAdjustPagination = pageNumber !== 1 && pageNumber > newLastPageNumber;

          if (shouldAdjustPagination) {
            yield put(
              paginationActions.changePagination({
                gridKey: 'watchlists',
                data: { pageNumber: pageNumber - 1, pageSize },
              })
            );
          }
        }
      }
    } else {
      yield put(editFieldActions.updateProgress(false));
      yield put(editFieldActions.updateCompleted(true));
      yield put(editFieldActions.cancel(false));
      yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));

      if (listId !== 'favorites') {
        yield put(
          listActions.updateFavoriteListCount({
            list_id: listId,
            count: listDetailCountBatch,
          })
        );
      }
    }
  } catch (e: any) {
    const message = getErrorMessage(e, 'Failed to remove to Watchlist!');
    yield put(editFieldActions.updateProgress(false));
    yield put(editFieldActions.updateCompleted(true));
    yield put(editFieldActions.updatePercentage(100));

    NotificationService.error(message);
  }
}

export default function* bulkEditSaga() {
  yield takeLatest(actions.bulkAddToWatchList, bulkAddToWatchListSaga);
  yield takeLatest(actions.bulkRemoveFromWatchList, bulkRemoveFromWatchListSaga);
}
