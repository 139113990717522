import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
// models
import { CompanyTabLists } from '@optx/models/Company';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const { Text } = Typography;

const ListURL = styled.a`
  color: #1890ff;
  display: inline !important;
`;

const TableCellListURL: React.FC = (value: any, record: CompanyTabLists) => {
  const urlPath = record.list_type === 'Watchlist' ? '/user-lists/' : '/ss-lists/';

  return (
    <TruncateTooltip title={value || DEFAULT_CELL_VALUE}>
      {value && record.clickable ? (
        <ListURL
          href={`${urlPath + record.unique_id}?title=${encodeURIComponent(record.title)}`}
          className="company-name__profile-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => window.pendo.track(OPTXChromePlugin.companyPage.lists_clickCompanyName)}
        >
          {value || DEFAULT_CELL_VALUE}
        </ListURL>
      ) : (
        <Text style={{ fontWeight: 600, cursor: 'default' }}>{value || DEFAULT_CELL_VALUE}</Text>
      )}
    </TruncateTooltip>
  );
};

export default TableCellListURL;
