import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

const Icon = styled(InfoCircleOutlined)`
  color: #1890ff;
  opacity: 1;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: 20px;

  &.extension-info {
    right: 10px;
  }
`;

export default {
  Icon,
};
