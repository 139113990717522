import React from 'react';
import { Typography } from 'antd';
// models
import { TableCellSearches } from '@optx/models/table/Cell';
// hooks
import useSugestedSearches from '@optx/common/hooks/saved-searches/useSugestedSearches';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const TableCellSearch: React.FC<TableCellSearches> = ({ value, row }) => {
  const { handleSearchResults } = useSugestedSearches();

  const handleClick = () => {
    if (row.unique_id) {
      handleSearchResults(row.title);
    }
  };

  return (
    <div role="presentation" onClick={handleClick}>
      <Typography.Text style={{ maxWidth: '100%' }} ellipsis>
        <TruncateTooltip title={value} className="text-truncate">
          {value}
        </TruncateTooltip>
      </Typography.Text>
    </div>
  );
};

export default TableCellSearch;
