import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put, select } from 'redux-saga/effects';
// models
// services
import NotificationService from '@services/NotificationService';
import { UserService } from '@services/api';
import { selectors as userSelectors } from '@redux/user/information';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { OptxScoreRationale } from './interfaces';
// redux
import * as actions from './actions';

export function* sendOptxScoreRationaleSaga(action: PayloadAction<OptxScoreRationale>) {
  const {
    companyId,
    rationale,
    optxScoreVerified,
    optxScoreRationaleReason,
    optxScoreRationaleReasonType,
    scoreType,
  } = action.payload;
  const defaultScore: string = yield select(userSelectors.getDefaultScore);

  let rationaleReasonLower = '';
  let rationaleReasonUpper = '';
  const rationaleTransformed = !rationale || rationale === DEFAULT_EMPTY_VALUE ? '' : rationale;

  if (optxScoreRationaleReasonType === 'lower') {
    rationaleReasonLower = optxScoreRationaleReason || '';
    rationaleReasonUpper = '';
  } else if (optxScoreRationaleReasonType === 'upper') {
    rationaleReasonLower = '';
    rationaleReasonUpper = optxScoreRationaleReason || '';
  }

  try {
    yield call(
      UserService.sendOptxScoreRationale,
      companyId,
      rationaleTransformed,
      optxScoreVerified,
      rationaleReasonLower,
      rationaleReasonUpper,
      scoreType
    );

    if (scoreType === 'score_v3') {
      yield put(
        actions.sendNewOptxScoreRationaleSuccess({
          companyId,
          rationale,
          defaultScore,
          optxScoreVerified,
          optxScoreRationaleReason,
          optxScoreRationaleReasonType,
          scoreType,
        })
      );
    } else {
      yield put(
        actions.sendOptxScoreRationaleSuccess({
          companyId,
          rationale,
          defaultScore,
          optxScoreVerified,
          optxScoreRationaleReason,
          optxScoreRationaleReasonType,
          scoreType,
        })
      );
    }

    const successMessage = 'Company rationale updated successfully';
    NotificationService.success(successMessage);
  } catch (e) {
    const errorMsg = 'Failed to updated company rationale';
    NotificationService.error(errorMsg);
  }
}

export default function* userSaga() {
  yield takeLatest(actions.sendOptxScoreRationale, sendOptxScoreRationaleSaga);
  yield takeLatest(actions.sendNewOptxScoreRationale, sendOptxScoreRationaleSaga);
}
