import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { Dictionary } from 'lodash';
// models
import { EquityTouchFetchedField, UpdateCompanyET } from '@models/equityTouch';
import { SelectOption, SelectableOption } from '@models/Option';
import Company, { Addon, CompanyProfile, ProductCategory } from '@optx/models/Company';
import { UserInformation } from '@optx/models/user';
import { CompanyUserContact } from '@optx/models/api/contacts';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
import { CONTACT_SELECT_LIMIT } from '@optx/constants/contacts';
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { getOptionLabel } from '@optx/utils/option';
// redux
import { actions as salesloftBulkActions } from '@features/bulk-actions/salesloft';
import { actions as primaryContactActions } from '@redux/company/primary-contacts';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as fetchPrimaryContactsActions } from '@redux/company/primary-contacts';
import { EquityTouchState } from './interfaces';
import * as actions from './actions';
import { fetchReducer, fetchSuccessReducer, fetchFailReducer } from '../../feature/fetch/reducers';

export const updateCompanyETData = (
  companyState: Company | CompanyProfile,
  payload: UpdateCompanyET
) => {
  const {
    formValues,
    fieldsData: { stageOptions, rankOptions, sectorOptions, subSectorOptions, companyOwnerOptions },
  } = payload.updateData;
  const state = companyState;

  const addonFor = formValues['Add-On For'] as SelectableOption;
  const newDescription = formValues.Description as string;
  const ownerLabel = getOptionLabel(formValues['Deal Team Lead'] as string, companyOwnerOptions);
  const rankLabel = getOptionLabel(formValues.Rank as string, rankOptions);
  const sectorLabel = getOptionLabel(formValues.Sector as string, sectorOptions);
  const stageLabel = getOptionLabel(formValues.Stage as string, stageOptions);
  const subSectorLabel = getOptionLabel(formValues['Sub-Sector'] as string, subSectorOptions);

  if (addonFor) {
    state.addon = addonFor ? null : (addonFor as Addon);
  }

  if (state.addon_bool !== undefined) {
    state.addon_bool = !!formValues['Add-On'];
  }

  if (state.descriptions !== undefined && newDescription) {
    state.descriptions = { 'Equity Touch': newDescription, ...state.descriptions };
  }

  state.num_employees = (formValues.FTEs as number) || DEFAULT_CELL_VALUE;
  state.is_in_et = true;
  state.company_owner = (ownerLabel as string) || DEFAULT_CELL_VALUE;
  state.pipeline_rank = rankLabel || DEFAULT_CELL_VALUE;
  state.product_category =
    (formValues['Product Category'] as ProductCategory[]) || DEFAULT_CELL_VALUE;
  state.sector = (sectorLabel as string) || DEFAULT_CELL_VALUE;
  state.source_tag.push('et');
  state.stage = (stageLabel as string) || DEFAULT_CELL_VALUE;
  state.sub_sector = (subSectorLabel as string) || DEFAULT_CELL_VALUE;
};

const initialState: EquityTouchState = {
  loading: false,
  error: '',
  data: {},
  formValues: {},
  temporaryFormValues: {},
  fetchedAt: '',
};

const fetchEquityTouchFormDataSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Dictionary<EquityTouchFetchedField>>
> = (draftState, action) => {
  fetchSuccessReducer(draftState, action);
  draftState.fetchedAt = new Date().toISOString();
};

const addToEquityTouchSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>>
> = (draftState, action) => {
  draftState.formValues = action.payload;
};

const updateEquityTouchSelectedContactsReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<number[]>
> = (draftState, action) => {
  draftState.formValues.contacts = action.payload;
};

const updateEquityTouchSelectedSalesLoftContactsReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<number[]>
> = (draftState, action) => {
  draftState.formValues.salesloft_contacts = action.payload;
};

const addContactSuccessReducer: CaseReducer<EquityTouchState, PayloadAction<any>> = (
  draftState,
  action
) => {
  draftState.loading = false;

  if (action.payload.primary_contact) {
    let slContacts: any = draftState.formValues.salesloft_contacts;

    if (slContacts && Array.isArray(slContacts) && !slContacts.includes(action.payload.person_id)) {
      slContacts.push(action.payload.person_id);
    } else {
      slContacts = Array.isArray(slContacts) ? slContacts : [action.payload.person_id];
    }

    draftState.formValues.salesloft_contacts = slContacts;
  }

  const contacts = draftState.formValues.contacts as number[];
  contacts?.push(action.payload.person_id);

  const contactSelectedLimit = contacts?.slice(0, CONTACT_SELECT_LIMIT);

  draftState.formValues.contacts =
    contacts?.length >= CONTACT_SELECT_LIMIT ? contactSelectedLimit : contacts;

  const temporaryContacts = draftState.temporaryFormValues.contacts as number[];
  temporaryContacts?.push(action.payload.person_id);

  const temporaryContactsSelectedLimit = temporaryContacts?.slice(0, CONTACT_SELECT_LIMIT);

  draftState.temporaryFormValues.contacts =
    temporaryContacts?.length >= CONTACT_SELECT_LIMIT
      ? temporaryContactsSelectedLimit
      : temporaryContacts;

  draftState.loading = false;
};

const fetchPrimaryContactsSuccessReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Array<CompanyUserContact>>
> = (draftState, action) => {
  draftState.loading = false;

  const primaryContact = action.payload.map(contact => ({
    ...contact,
    ...(contact.tenure_start_date && {
      tenure_start_date: moment(contact.tenure_start_date).format(ISO_DATE_FORMAT),
    }),
    ...(contact.tenure_end_date && {
      tenure_end_date:
        contact.tenure_end_date !== PRESENT_DATE_FORMAT
          ? moment(contact.tenure_end_date).format(ISO_DATE_FORMAT)
          : contact.tenure_end_date,
    }),
  }));

  const contacts = primaryContact?.map(contact => contact.person_id).filter(list => list !== null);

  if (draftState.formValues.contacts === undefined) {
    if (contacts.length >= CONTACT_SELECT_LIMIT) {
      const contactSelectedLimit = contacts.slice(0, CONTACT_SELECT_LIMIT);
      draftState.formValues.contacts = contactSelectedLimit as number[];
    } else {
      draftState.formValues.contacts = contacts as number[];
    }
  }

  if (draftState.temporaryFormValues.contacts === undefined) {
    if (contacts.length >= CONTACT_SELECT_LIMIT) {
      const contactSelectedLimit = contacts.slice(0, CONTACT_SELECT_LIMIT);
      draftState.temporaryFormValues.contacts = contactSelectedLimit as number[];
    } else {
      draftState.temporaryFormValues.contacts = contacts as number[];
    }
  }
};

const updateTemporaryFormValuesReducer: CaseReducer<
  EquityTouchState,
  PayloadAction<Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>>
> = (draftState, action) => {
  draftState.temporaryFormValues = action.payload;
};

const resetAddToEquityTouchReducer: CaseReducer<EquityTouchState> = draftState => {
  draftState.data = {};
  draftState.formValues = {};
  draftState.temporaryFormValues = {};
  draftState.fetchedAt = '';
};

// External reducers
// Update persisted filter to be used when filters are loaded.
const fetchUserInformationSuccess: CaseReducer<EquityTouchState, PayloadAction<UserInformation>> = (
  draftState,
  action
) => {
  const persistedFilter: any = action.payload.settings.session_settings
    ? action.payload.settings.session_settings?.et_form_auto_save
    : undefined;

  if (persistedFilter !== undefined && persistedFilter !== null) {
    const values = (persistedFilter?.values ?? {}) as Dictionary<
      string | number | boolean | number[] | string[] | null | SelectOption
    >;
    const formData = (persistedFilter?.formData ?? {}) as Dictionary<EquityTouchFetchedField>;
    draftState.data = formData;
    draftState.formValues = values;
  }
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchEquityTouchFormData, fetchReducer)
    .addCase(actions.fetchEquityTouchFormDataSuccess, fetchEquityTouchFormDataSuccessReducer)
    .addCase(
      fetchPrimaryContactsActions.fetchPrimaryContactsSuccess,
      fetchPrimaryContactsSuccessReducer
    )
    .addCase(actions.fetchEquityTouchFormDataFail, fetchFailReducer)
    .addCase(actions.addToEquityTouchSuccess, addToEquityTouchSuccessReducer)
    .addCase(actions.updateEquityTouchSelectedContacts, updateEquityTouchSelectedContactsReducer)
    .addCase(
      actions.updateEquityTouchSelectedSalesLoftContacts,
      updateEquityTouchSelectedSalesLoftContactsReducer
    )
    .addCase(actions.resetAddToEquityTouch, resetAddToEquityTouchReducer)
    .addCase(actions.updateTemporaryFormValues, updateTemporaryFormValuesReducer)
    .addCase(primaryContactActions.updatePrimaryContacts, addContactSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccess)
    .addCase(salesloftBulkActions.addContactSuccess, addContactSuccessReducer)
);

export default reducer;
