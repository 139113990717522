type envResponseType = 'dev-bb' | 'dev-cl' | 'local' | 'uat' | 'prod' | null;

/**
 * Retrieves the environment based on the REACT_APP_API_ROOT environment variable.
 * @returns {envResponseType} The environment name ('dev-bb', 'dev-cl', 'uat', 'prod') or
 *                          null if REACT_APP_API_ROOT is not set.
 */

export function getEnv(): envResponseType {
  const rootDomain = window.location.origin;

  if (rootDomain) {
    if (rootDomain.search('dev-bb') !== -1) {
      return 'dev-bb';
    } else if (rootDomain.search('dev-cl') !== -1) {
      return 'dev-cl';
    } else if (rootDomain.search('uat') !== -1) {
      return 'uat';
    } else if (rootDomain.search('localhost') !== -1) {
      return 'local';
    } else {
      return 'prod';
    }
  }

  return null;
}
