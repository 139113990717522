import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
// redux
import { selectors } from '@redux/user/information/';
// components
import { defaultColumns } from '@optx/components/common/table/TableOptxRecommendNotifications';
import { RecommendedNotificationsTable } from './OptxNotifications.styled';

const OptxRecommendNotifications: React.FC = () => {
  const recommendedOptions = useSelector(selectors.getDefaultRecommendedOptxNotifications);

  const handleDataSource = () => {
    if (recommendedOptions) {
      return Object.entries(recommendedOptions).map(([key, value]) => value);
    }

    return [];
  };

  return (
    <div>
      <Typography.Title style={{ fontWeight: 'normal', marginBottom: '18px' }} level={4}>
        OPTX Recommended Notifications
      </Typography.Title>
      <RecommendedNotificationsTable
        columns={defaultColumns as any}
        rowKey={(record, index) => 'row-' + index}
        dataSource={handleDataSource()}
        className="legacy-table"
        pagination={false}
        tableLayout="fixed"
        bordered
      />
    </div>
  );
};

export default React.memo(OptxRecommendNotifications);
