import { createReducer, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
// models
import { UserInformation } from '@optx/models/user';
import { FilterSource } from '@optx/models/filters';
import { CompanyFiltersMeta } from '@optx/models/search';
import Company from '@models/Company';
import { GridPayload } from '@models/grid';
import { SearchPayload } from '@models/api/contacts';
// constants
import { COMPANY_DEFAULT_SORT } from '@constants/table/sort/defaultSort';
// utils
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import * as companySearchSortReducers from '@redux/company/search/sort/reducer';
import { interfaces } from '@redux/company/search/sort';
import { actions as userInformationActions } from '@redux/user/information';
import { actions as companyFiltersActions } from '@redux/company/filters';
import * as actions from './actions';
import { actions as searchActions } from '@features/grid/search';
import { actions as listsActions } from '../../details';

const initialState: interfaces.SortState = {
  multiSort: false,
  sortBy: COMPANY_DEFAULT_SORT,
  loading: false,
};

export const fetchUserInformationSuccessReducer: CaseReducer<
  interfaces.SortState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const sorting = action.payload.settings.session_settings?.user_list_sorting;

  if (sorting) {
    const { sortBy, isMultiSort } = parseSorting(sorting);
    draftState.sortBy = sortBy;
    draftState.multiSort = isMultiSort;
  } else if (sorting === '') {
    draftState.sortBy = [];
    draftState.multiSort = false;
  }
};

const fetchCompanyFiltersSuccessReducer: CaseReducer<
  interfaces.SortState,
  PayloadAction<Array<FilterSource>, any, CompanyFiltersMeta>
> = (draftState, action) => {
  if (
    action.meta.defaultViews.length &&
    !action.meta.settings?.session_settings?.user_list_sorting
  ) {
    const { sortBy } = parseSorting(action.meta.defaultViews[0].search_criteria);

    if (sortBy.length) {
      draftState.sortBy = sortBy;
    }

    if (sortBy.length > 1) {
      draftState.multiSort = true;
    }
  }

  draftState.loading = false;
};

export const searchCompaniesSuccessReducer: CaseReducer<
  interfaces.SortState,
  PayloadAction<Company[], any, GridPayload<Partial<SearchPayload>>>
> = (draftState, action) => {
  const { data, gridKey } = action.meta;

  if (gridKey === 'lists') {
    if (!isEqual(draftState.sortBy, data.sortBy)) {
      draftState.sortBy = data.sortBy || [];
    }
  }
};

const reducer = createReducer<interfaces.SortState>(initialState, builder =>
  builder
    // This reducer updates the UI before the call is completed. If commented out,
    // the UI will update aftet the call. Useful for cancelling sorting.
    // .addCase(actions.changeSortAction, companySearchSortReducers.changeSortReducer)
    .addCase(actions.changeSortAction, companySearchSortReducers.changeSortReducer)
    .addCase(actions.multiSortAction, companySearchSortReducers.multiSortReducer)
    .addCase(actions.resetSortSuccessAction, companySearchSortReducers.resetSortSuccessReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
    .addCase(
      userInformationActions.updateUserSettingsSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsFromAutoSaveSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForSSListSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForFavoritesListSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForUserListSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForSavedContactSearchesSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(
      userInformationActions.updateUserSettingsForSuggestedSearchesSuccess,
      companySearchSortReducers.updateUserSettingsSuccessReducer
    )
    .addCase(companyFiltersActions.fetchCompanyFiltersSuccess, fetchCompanyFiltersSuccessReducer)
    .addCase(
      companyFiltersActions.fetchCompanyFiltersForNotificationsSuccess,
      fetchCompanyFiltersSuccessReducer
    )
    // Search actions
    .addCase(searchActions.searchCompaniesSuccess, searchCompaniesSuccessReducer)
    .addCase(searchActions.clearSearchSuccess, companySearchSortReducers.clearSearchSuccessReducer)
    .addCase(searchActions.resetSearchSuccess, companySearchSortReducers.clearSearchSuccessReducer)

    // Load company list success.
    .addCase(
      listsActions.loadCompanyListSuccess,
      companySearchSortReducers.loadCompanyListSuccessReducer
    )
);

export default reducer;
