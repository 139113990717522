import React, { useEffect, useContext, useRef, useState } from 'react';
import { Button, Typography } from 'antd';
import { ValueType } from 'react-select';
import { useSelector } from 'react-redux';
import { MenuPlacement } from 'react-select/src/types';
// models
import { SelectOption } from '@optx/models/Option';
import { EditFieldSingleSelectOtherProps } from '@optx/models/feature/edit-field/EditFieldSingleSelectProps';
import { FieldValue } from '../../audit-trail/state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { renderLabelOther, renderValueOther } from '@optx/utils/editFields';
// redux
import { getNextSteps } from '@optx/redux/company/filters/selectors';
import { selectors } from '@components/feature/company-individual-edit/state/index';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
import useUpdateFields from '../hooks/useUpdateFields';
import useAsyncMenuPosition from '@optx/common/hooks/select/useAsyncMenuPosition';
// components
import { Styled as TableCellStyled } from '@optx/features/company/edit-fields/components/TableCellFit.styled';
import SingleSelect from '@optx/components/common/select/SingleSelect';
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import CreateOtherOptions from './CreateOtherOptions';
// styles
import { Styled } from './EditFieldSingleSelectOther.styled';

const EditFieldSingleSelectOther: React.FC<EditFieldSingleSelectOtherProps> = ({
  record,
  fieldName,
  fieldKey,
  fieldOtherKey,
  fieldOtherValue,
  value,
  isFromGrid,
  hidePenIcon,
  isFromGridDropDown,
  isFromHistory,
  service,
  successMessage,
  dropdownLabel,
  useTooltip,
  dropdownPlacement,
  otherEndpoint,
  defaultMenuIsOpen = false,
  destroyOnHide = false,
  actionPendo,
}) => {
  const [selectedValue, setSelectedValue] = useState<FieldValue>(value);
  const [closePopUp, setClosePopUp] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string | null>(null);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);
  const parentCreatableRef = useRef<HTMLDivElement>(null);
  const [asyncMenuPlacement, setAsyncMenuPlacement] = useState<MenuPlacement | undefined>('auto');

  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    service,
    successMessage,
    isFromHistory,
    extraKeys: fieldOtherKey ? [fieldOtherKey] : undefined,
  });

  const { getMenuPlacement } = useAsyncMenuPosition({
    parentEl: parentCreatableRef,
    distanceFromBottom: 140,
  });

  const editFieldSingleSelectSelectors = {
    'Next Steps': getNextSteps,
  };

  let label = value === null ? DEFAULT_EMPTY_VALUE : value;

  const isLoadingOptions = useSelector(selectors.companyIndividualEdit.isLoadingInfo);
  const popoverContentRef = useRef(null);

  const { dropdownVisible, setDropdownVisible } = useContext(DropdownAddContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectOptions = [
    { value: 'blank', label: 'None' },
    ...useSelector(editFieldSingleSelectSelectors[fieldName]),
  ];

  const initialSelectValue = selectOptions.find(
    option => selectedValue?.toString().toLocaleLowerCase() === option.label.toLocaleLowerCase()
  );

  const onChange = (newValue: ValueType<SelectOption>) => {
    const label = (newValue as SelectOption).label;

    if (label !== 'Other') {
      updateField(newValue, fieldKey);
      setClosePopUp(true);
      setDropdownVisible(!dropdownVisible);
    }

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    setSelectedValue(label);
    setSaveBtnDisabled(false);
    setOtherValue(null);
  };

  const handleOtherChange = (option: SelectOption) => {
    if (option.isDefault) {
      setOtherValue(null);
      setSelectedValue(option.value);
    } else {
      setOtherValue(option.value);
    }

    setSaveBtnDisabled(false);
  };

  useEffect(() => {
    if (selectedValue === DEFAULT_EMPTY_VALUE) {
      setSelectedValue('None');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedValue(value);

    if (value === 'Other' && fieldOtherValue) {
      setOtherValue(fieldOtherValue as string | null);
    }
  }, [value, record, fieldOtherValue]);

  const handleCancel = () => {
    setClosePopUp(true);
    setSaveBtnDisabled(true);

    if (selectedValue !== value) {
      setSelectedValue(value);
    }

    if (otherValue !== fieldOtherValue) {
      setOtherValue(fieldOtherValue as string | null);
    }
  };

  const handleSave = () => {
    const currentOption = selectOptions.find(option => option.label === selectedValue);

    if (currentOption) {
      updateField(currentOption, fieldKey, undefined, undefined, undefined, undefined, [
        otherValue ?? '',
      ]);
    }

    setClosePopUp(true);
    setSaveBtnDisabled(true);
    setDropdownVisible(!dropdownVisible);
  };

  const handleTagRemoval = () => {
    setOtherValue(null);
    setSaveBtnDisabled(false);
  };

  useOnClickOutside(popoverContentRef, handleCancel);

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const onVisibilityChange = () => {
    setAsyncMenuPlacement(getMenuPlacement());
  };

  const content = (
    <Styled.ContentContainer
      className="profile-other"
      tabIndex={0}
      ref={popoverContentRef}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => handleDropdownKeyEvent(event)}
    >
      <SingleSelect
        defaultMenuIsOpen={defaultMenuIsOpen}
        options={selectOptions as SelectOption[]}
        onChange={onChange}
        isLoading={isLoadingOptions}
        value={initialSelectValue as SelectOption}
        closeMenuOnSelect
      />
      {selectedValue === 'Other' && (
        <>
          <Styled.InputWrap>
            <Typography.Text>Please Explain</Typography.Text>
            <CreateOtherOptions
              onInputChange={handleOtherChange}
              apiEndpoint={otherEndpoint}
              setMenuMaxHeight={200}
              menuPlacement={asyncMenuPlacement}
            />
            {otherValue && (
              <Styled.Tag color="blue">
                {otherValue}{' '}
                <span className="remove-tag" onClick={handleTagRemoval}>
                  X
                </span>
              </Styled.Tag>
            )}
          </Styled.InputWrap>
        </>
      )}
      {(value !== selectedValue || selectedValue === 'Other') && (
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            onClick={handleSave}
            disabled={saveBtnDisabled}
          >
            Save
          </Button>
        </div>
      )}
    </Styled.ContentContainer>
  );

  if (!isFromGrid || isFromGridDropDown) {
    return (
      <EditPopover
        content={content}
        title={`Edit "${fieldName}" value`}
        closePopup={closePopUp}
        setClosePopup={setClosePopUp}
        hidePenIcon={hidePenIcon}
        destroyOnHide={destroyOnHide}
        getPopupContainer={triger => triger.parentElement as HTMLElement}
      >
        {renderLabelOther({
          isFromGridDropDown,
          isFromHistory,
          dropdownLabel,
          value,
          label,
          useTooltip: useTooltip,
          extraValue: fieldOtherValue ?? '',
        })}
      </EditPopover>
    );
  }

  return (
    <span ref={parentCreatableRef}>
      <InlineEdit
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <EditCellPen
            content={content}
            title={`Edit "${fieldName}" value`}
            closePopup={closePopUp}
            setClosePopup={setClosePopUp}
            placement={dropdownPlacement}
            onVisibilityShow={onVisibilityChange}
          />
        }
        fill
      >
        {value ? (
          <TruncateTooltip
            title={renderValueOther(value as string, fieldOtherValue as string | null)}
          >
            <TableCellStyled.TableCellInner>
              {renderValueOther(value as string, fieldOtherValue as string | null)}
            </TableCellStyled.TableCellInner>
          </TruncateTooltip>
        ) : (
          value
        )}
      </InlineEdit>
    </span>
  );
};

export default React.memo(EditFieldSingleSelectOther);
