import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
// redux
import { actions as modalActions } from '@redux/ui/modals/gridModals';

const ShowFilterButton = () => {
  const dispatch = useDispatch();

  const toggleModalFiltersButton = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.blur();
    dispatch(modalActions.toggleModal({ gridKey: 'contacts' }));
  };

  return (
    <>
      <Tooltip title="Filter">
        <Button type="text" onClick={toggleModalFiltersButton} icon={<FilterOutlined />} />
      </Tooltip>
    </>
  );
};

export default ShowFilterButton;
