import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
// utils
import { getSingleSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
import { getFunds, getRegion } from '../utils';
// redux
import { selectors, actions } from '@features/pipeline-report/state';
// components
import { SingleSelect, SingleSelectProps } from '@shared/view/molecules/Select';

interface FilterSingleSelectProps extends SingleSelectProps {
  filterKey: 'country' | 'is_addon';
}

export const FilterSingleSelect: React.FC<FilterSingleSelectProps> = ({ filterKey, ...props }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Array<SelectOption>>([]);
  const [option, setOption] = useState<ValueType<SelectOption>>();

  const { normalized, filter } = useSelector(selectors.getFilters);
  const fundAssociation = useSelector(selectors.fundAssociation);

  const normalizedData = normalized[filterKey];

  const value = filter[filterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getSingleSelectOptions(normalizedData);

      if (options.length === 0) {
        setOption(sortedOptions[0]);
      }

      setOptions(sortedOptions);

      if (filterKey === 'country') {
        const fundValue = filter.fund;
        const findRegionByFund = getRegion(fundValue as SelectOption[]);
        const findRegion = sortedOptions.find(option => option.label === findRegionByFund);

        setOption(findRegion);
      } else {
        if (value) {
          const findValue = sortedOptions.find(option => option.value === value);

          setOption(findValue);
        } else {
          setOption(sortedOptions[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, normalizedData]);

  const updateValue = useCallback(
    (newValue: ValueType<SelectOption>) => {
      if (filterKey === 'country') {
        let value;
        let key = 'fund';
        const { label } = newValue as SelectOption;

        if (label === 'Europe') {
          value = getFunds(normalized.fund.data, 'Europe');
        } else if (label === 'North America + Israel') {
          value = getFunds(normalized.fund.data, 'North America + Israel');
        } else if (label === 'Israel') {
          key = 'is_il_optx_score';
          value = true;
        } else {
          value = getFunds(normalized.fund.data, 'All');
        }

        setOption(options.find(option => option.label === label));

        dispatch(actions.updateValue({ filter: { value, key } }));

        if (key !== 'is_il_optx_score') {
          dispatch(actions.updateValue({ filter: { value: label, key: 'country' } }));
        }
      } else {
        if ((newValue as SelectOption).label === value) {
          return;
        }

        dispatch(
          actions.updateValue({
            filter: { value: (newValue as SelectOption).value, key: filterKey },
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, fundAssociation]
  );

  return (
    <SingleSelect
      options={options}
      onChange={updateValue}
      value={option}
      placeholder={normalizedData ? normalizedData.placeholders : undefined}
      valuePrefix={normalizedData ? `${normalizedData.placeholders}:` : undefined}
      {...props}
    />
  );
};
