import { useState } from 'react';
import { Button, Col, Form, Typography } from 'antd';
import styled from 'styled-components';
import { useField } from 'formik';
import { FormItem } from 'formik-antd';
import { OptionsType, ValueType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { BaseField } from '@optx/models/companyFields';
import { SuccessErrorCallback } from '@optx/models/callback';
// constants
import { SELECT_UNKNOWN } from '@optx/components/common/select/constants';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
import { LAST_INVESTORS_ENDPOINT } from '@constants/asyncEndpoints';
// utils
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
// components
import AsyncCreatableSelect from '@optx/components/common/select/AsyncCreatableSelect';
import EditPopover from '@optx/components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const StyledFormItem = styled(FormItem)`
  .async-create-select {
    .async-create-select__control {
      height: 32px;
      min-height: 32px;
      .async-create-select__value-container {
        height: 30px !important;
        min-height: 30px;
        div:nth-child(2) {
          padding: 0;
        }
      }
    }
  }
`;

interface SelectProps {
  field?: BaseField;
  fieldName?: string;
  fieldType?: string;
  value?: string | null;
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  autoFocus?: boolean;
}

const AsyncCreatableSelectLastInvestors: React.FC<SelectProps> = ({
  field,
  fieldName,
  fieldType,
  value,
  isChromePlugin,
  onSave,
  onCancel,
  autoFocus = true,
}) => {
  const { loadOptions } = useAsyncSearch({ endpoint: field?.endpoint ?? LAST_INVESTORS_ENDPOINT });
  const [selectedInvestor, , helpers] = useField<string[]>(field?.id || fieldType || '');

  const [closePopup, setClosePopup] = useState(false);
  let investorValue = field?.value?.[0] || value || '';

  if (field && !investorValue) {
    investorValue = selectedInvestor.value?.[0] || '';
  }

  const tooltipTitle = investorValue ? investorValue : DEFAULT_EMPTY_VALUE;

  const [selectedInvestorName, setSelectedInvestorName] = useState<SelectOption>({
    label: investorValue,
    value: investorValue,
  });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([SELECT_UNKNOWN]);

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  };

  // when menu is closed, clear cached values.
  const handleMenuClose = () => {
    setFetchedOptions([{ label: '', value: '' }]);
  };

  const handleChange = (option: ValueType<SelectOption<string>> | null) => {
    // avoid label with 'Create' prefix from CreatableSelect
    const value = option ? (option as SelectOption).value : '';
    helpers.setValue(value === '' ? [] : [value]);
    setSelectedInvestorName({ label: value, value });
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (visibility) {
      helpers.setValue([investorValue]);
      setSelectedInvestorName({ label: investorValue, value: investorValue });
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <StyledFormItem name={field.id}>
          <AsyncCreatableSelect
            value={selectedInvestorName}
            loadOptions={getLoadOptionsDelay}
            options={fetchedOptions}
            onChange={handleChange}
            onMenuClose={handleMenuClose}
            controlShouldRenderValue
            closeMenuOnSelect
            disableValueRemove
            autoFocus={autoFocus}
            tabIndex={0}
          />
        </StyledFormItem>
      </Col>
    );
  }

  const content = (
    <Form.Item style={{ height: '100px' }} name={fieldType ?? ''}>
      <div
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <AsyncCreatableSelect
          value={selectedInvestorName}
          loadOptions={getLoadOptionsDelay}
          options={fetchedOptions}
          onChange={handleChange}
          onMenuClose={handleMenuClose}
          controlShouldRenderValue
          closeMenuOnSelect
          disableValueRemove
          autoFocus={true}
          tabIndex={0}
        />

        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={checkSaveButton(selectedInvestorName.value, value)}
            onClick={() => {
              onSave && onSave(fieldType);
              setClosePopup(true);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Form.Item>
  );

  return (
    <EditPopover
      getPopupContainer={element => element}
      setClosePopup={setClosePopup}
      customOverlayStyle={{ width: '480px' }}
      onVisibilityUpdate={handleVisibilityChange}
      destroyOnHide
      closePopup={closePopup}
      content={content}
      placement={isChromePlugin ? 'topRight' : 'top'}
    >
      <TruncateTooltip placement={isChromePlugin ? 'leftTop' : 'topLeft'} title={tooltipTitle}>
        {tooltipTitle}
      </TruncateTooltip>
    </EditPopover>
  );
};

export default AsyncCreatableSelectLastInvestors;
