import React, { useEffect, useMemo, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from 'lodash';
//models
import { CompanyProfile, ProprietaryInfoDropdown } from '@optx/models/Company';
//redux
import { actions, selectors } from '@redux/company/opportunity-presentation';
//components
import {
  CustomerKPIWrapper,
  CustomerKPIValues,
  CustomerKPILabel,
  FirstCustomerKPIRow,
  SecondCustomerKPIRow,
} from '../styles/ProprietaryInfoCards.styled';
import InputField from '@optx/features/field-factory/InputField';
import InputNumberField from '@optx/features/field-factory/InputNumberField';
import SingleSelectField from '@optx/features/field-factory/SingleSelectField';
import MultiSelectField from '@optx/features/field-factory/MultiSelectField';
import MultiSelectPopupField from '@optx/features/field-factory/MultiSelectPopupField';

interface CustomerKPIProps {
  company: CompanyProfile;
  isChromePlugin?: boolean;
}

const getCustomerTypeFieldExtraData = (customerTypeInfo?: ProprietaryInfoDropdown) => {
  if (customerTypeInfo) {
    return {
      customer_other_rationale: {
        label: 'Other Rationale',
        placeholder: 'Please Explain',
        type: 'textarea',
        value: customerTypeInfo.extra_data?.customer_other_rationale,
      },
    };
  }
};

const CustomerKPI: React.FC<CustomerKPIProps> = ({ company, isChromePlugin }) => {
  const dispatch = useDispatch();
  const customerKpiInfo = useSelector(selectors.getCustomerKpisData);
  const formRef = useRef<FormikProps<any> | null>(null);
  let fieldNames: string[] = [];

  const initialValues = useMemo(
    () => ({
      customers_number: customerKpiInfo?.customers_number || '',
      customer_type: customerKpiInfo?.customer_type.value || [],
      customer_concentration: customerKpiInfo?.customer_concentration || '',
      sales_and_marketing: customerKpiInfo?.sales_and_marketing.value || '',
      sales_cycle: customerKpiInfo?.sales_cycle.value || [],
      acv: customerKpiInfo?.acv || '',
      clt: customerKpiInfo?.clt || '',
      nps: customerKpiInfo?.nps || '',
      gross_retention: customerKpiInfo?.gross_retention?.toString() || '',
      net_retention: customerKpiInfo?.net_retention?.toString() || '',
      customer_churn: customerKpiInfo?.customer_churn || '',
      cac: customerKpiInfo?.cac || '',
      ltv_cac: customerKpiInfo?.ltv_cac || '',
    }),
    [customerKpiInfo]
  );

  const handleUpdateField = (fieldType?: string) => {
    if (fieldType) {
      fieldNames.push(fieldType);
    }

    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmit = () => {
    if (formRef?.current) {
      const payload = {
        company_id: company.company_id,
        customer_kpis: {} as Dictionary<string | string[]>,
      };

      fieldNames.forEach(fieldName => {
        const value = formRef.current?.values[fieldName];

        if (value !== undefined) {
          payload.customer_kpis[fieldName] = value;
        }
      });

      if (Object.entries(payload.customer_kpis).length) {
        dispatch(actions.updateOpportunityPresentation(payload));
      }
    }

    formRef.current?.resetForm();
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues(initialValues);
    }
  }, [initialValues]);

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={instance => {
        formRef.current = instance;
      }}
    >
      <CustomerKPIWrapper $isChromePlugin={isChromePlugin}>
        <FirstCustomerKPIRow className="first-customer-kpi-row" $isChromeExtension={isChromePlugin}>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Number of Customers</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputNumberField
              fieldName="Number of Customers"
              value={customerKpiInfo?.customers_number as string}
              fieldType="customers_number"
              onSave={handleUpdateField}
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Customer Type</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <MultiSelectPopupField
              fieldName="Customer Type"
              fieldType="customer_type"
              onSave={handleUpdateField}
              fieldValue={(customerKpiInfo?.customer_type.value as string[]) || []}
              fieldOptions={customerKpiInfo?.customer_type.data}
              fieldExtraData={getCustomerTypeFieldExtraData(customerKpiInfo?.customer_type)}
              popupWidth={480}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>
            Customer Concentration
          </CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="Customer Concentration"
              onSave={handleUpdateField}
              value={customerKpiInfo?.customer_concentration as string}
              fieldType="customer_concentration"
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Sales & Marketing</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <SingleSelectField
              fieldName="Sales & Marketing"
              fieldType="sales_and_marketing"
              onSave={handleUpdateField}
              value={customerKpiInfo?.sales_and_marketing.value as string}
              options={customerKpiInfo?.sales_and_marketing.data}
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Sales Cycle</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <MultiSelectField
              fieldName="Sales Cycle"
              fieldType="sales_cycle"
              onSave={handleUpdateField}
              value={(customerKpiInfo?.sales_cycle.value as string[]) || []}
              options={customerKpiInfo?.sales_cycle.data}
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>ACV</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="ACV"
              value={customerKpiInfo?.acv as string}
              onSave={handleUpdateField}
              fieldType="acv"
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>CLT</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="CLT"
              value={customerKpiInfo?.clt as string}
              onSave={handleUpdateField}
              fieldType="clt"
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>NPS</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="NPS"
              value={customerKpiInfo?.nps as string}
              onSave={handleUpdateField}
              fieldType="nps"
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
        </FirstCustomerKPIRow>
        <SecondCustomerKPIRow
          className="second-customer-kpi-row"
          $isChromeExtension={isChromePlugin}
        >
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Gross Retention</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="Gross Retention"
              fieldType="gross_retention"
              value={customerKpiInfo?.gross_retention?.toString() as string}
              isChromePlugin={isChromePlugin}
              onSave={handleUpdateField}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Net Retention</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="Net Retention"
              fieldType="net_retention"
              value={customerKpiInfo?.net_retention?.toString() as string}
              isChromePlugin={isChromePlugin}
              onSave={handleUpdateField}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>Customer Churn</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="Customer Churn"
              isChromePlugin={isChromePlugin}
              value={customerKpiInfo?.customer_churn as string}
              onSave={handleUpdateField}
              fieldType="customer_churn"
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>CAC</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="CAC"
              value={customerKpiInfo?.cac as string}
              onSave={handleUpdateField}
              fieldType="cac"
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
          <CustomerKPILabel $isChromePlugin={isChromePlugin}>LTV/CAC</CustomerKPILabel>
          <CustomerKPIValues $isChromePlugin={isChromePlugin}>
            <InputField
              fieldName="LTV/CAC"
              value={customerKpiInfo?.ltv_cac as string}
              onSave={handleUpdateField}
              fieldType="ltv_cac"
              isChromePlugin={isChromePlugin}
            />
          </CustomerKPIValues>
        </SecondCustomerKPIRow>
      </CustomerKPIWrapper>
    </Formik>
  );
};

export default CustomerKPI;
