import { FormValues } from '../models';
import { AddCompanyState } from '../state/interfaces';

export const FORM_INITIAL_VALUES: FormValues = {
  companyName: '',
  primaryUrl: '',

  alternativeName: '',
  secondaryUrl: '',

  primaryAddress: '',
  primaryAddressSecondLine: '',
  city: {
    label: '',
    value: '',
  },
  state: {
    label: '',
    value: '',
  },
  zipCode: '',
  country: {
    label: '',
    value: '',
  },

  hasSecondaryAddress: false,

  secondaryAddress: '',
  secondaryAddressSecondLine: '',
  secondaryCity: {
    label: '',
    value: '',
  },
  secondaryCountry: {
    label: '',
    value: '',
  },
  secondaryState: {
    label: '',
    value: '',
  },
  secondaryZipCode: '',

  sector: {
    label: '',
    value: '',
  },
  subSector: {
    label: '',
    value: '',
  },
  productCategory: [],
  ftes: null,

  companyOwner: {
    label: '',
    value: '',
  },
  etCheck: null,
  isAddon: {
    label: 'No',
    value: 0,
  },

  stage: {
    label: '',
    value: '',
  },
  stageRationale: null,
  fund: {
    label: '',
    value: '',
  },
  addonFor: [],

  rank: {
    label: '',
    value: '',
  },
  source: {
    label: '',
    value: '',
  },
  rationale: '',
  opportunityType: {
    label: '',
    value: '',
  },
  description: '',
};

export const FORM_TOTAL_STEPS = 4; // this doesn't include the last completed step

export const STATE_INITIAL_VALUES: AddCompanyState = {
  loading: false,
  similarCompanies: null,
  addedCompany: null,
  urlData: null,
};

export const MODAL_WRAPPER_TITLE = 'Add New Company';

export const VIEW_AND_CLOSE = 'View Company and Close';
