import React, { ReactNode } from 'react';
// redux
import Company, { CompanyProfile } from '@optx/models/Company';
// hooks
import useIsAdminOrAnalyst from '@optx/common/hooks/useisAdminOrAnalyst';
// components
import EditFieldOPTX from '@optx/features/company/edit-fields/components/EditFieldOPTX';
import EditFieldNewOPTX from '@optx/features/company/edit-fields/components/EditFieldNewOPTX';

interface TableCellScoreAdminProps {
  record: Company | CompanyProfile;
  children: ReactNode;
  isNewScore?: boolean;
}

const TableCellScoreAdmin: React.FC<TableCellScoreAdminProps> = ({
  record,
  children,
  isNewScore,
}) => {
  const isAdminOrAnalyst = useIsAdminOrAnalyst();

  if (isAdminOrAnalyst) {
    if (isNewScore) {
      return (
        <EditFieldNewOPTX record={record} isFromGrid>
          {children}
        </EditFieldNewOPTX>
      );
    }

    return (
      <EditFieldOPTX record={record} isFromGrid>
        {children}
      </EditFieldOPTX>
    );
  }

  return <>{children}</>;
};

export default React.memo(TableCellScoreAdmin);
