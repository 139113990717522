import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import Company from '@models/Company';
import { AddCompanyResponse, UrlDataResponse } from './interfaces';
// services
import NotificationService from '@services/NotificationService';
import { CompanyService, SearchService } from '@services/api';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import * as actions from './actions';

function* checkUrlSaga(action: PayloadAction<string>) {
  const url = action.payload;

  try {
    const res: AxiosResponse<UrlDataResponse> = yield call(SearchService.getIsUrlAvailable, url);

    if (res.data) {
      yield put(actions.checkUrlSuccess(res.data));
    } else {
      NotificationService.error("Couldn't check for url, Server Error!");
      yield put(actions.checkUrlFail());
    }
  } catch (error: any) {
    NotificationService.error(getErrorMessage(error, "Couldn't check for url, Server Error!"));
    yield put(actions.checkUrlFail());
  }
}

function* checkSimilarCompaniesSaga(action: PayloadAction<string>) {
  const url = action.payload;

  try {
    const res: AxiosResponse<Company[]> = yield call(SearchService.getSimilarCompaniesByURL, url);

    if (res.data) {
      yield put(actions.checkSimilarCompaniesSuccess(res.data));
    } else {
      NotificationService.error("Couldn't fetch similar companies, Server Error!");
      yield put(actions.checkSimilarCompaniesFail());
    }
  } catch (error: any) {
    NotificationService.error("Couldn't fetch similar companies, Server Error!");
    yield put(actions.checkSimilarCompaniesFail());
  }
}

function* addCompanySaga(action: PayloadAction<any>) {
  const companyData = action.payload;

  try {
    const res: AxiosResponse<AddCompanyResponse> = yield call(
      CompanyService.addCompany,
      companyData
    );

    if (res.data) {
      const addedCompanyData = res.data[0];
      const data = { ...addedCompanyData, company_url: action.payload.company_url };

      yield put(actions.addCompanySuccess(data));
    } else {
      NotificationService.error("Couldn't add company, Server Error!");
      yield put(actions.addCompanyFail());
    }
  } catch (error: any) {
    NotificationService.error(getErrorMessage(error, "Couldn't add company, Server Error!"));
    yield put(actions.addCompanyFail());
  }
}

export default function* AddCompanySaga() {
  yield takeLatest(actions.checkUrl, checkUrlSaga);
  yield takeLatest(actions.checkSimilarCompanies, checkSimilarCompaniesSaga);
  yield takeLatest(actions.addCompany, addCompanySaga);
}
