import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Button, Select, Input, Row, Typography } from 'antd';
import { isEqual } from 'lodash';
import { usePrevious } from '@umijs/hooks';
// models
import { FinancialFieldRangeValue, FinancialFieldType } from '@models/Company';
import { ServiceType, FieldOptions } from '@features/company/edit-fields/state/interfaces';
import { SelectOption } from '@optx/models/Option';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { VALIDATE_EDIT_FINANCIAL_FIELD_KEYS } from '@optx/constants/editFieldFinancials';
import { regexNegativeAbbreviation, numberPercentageMatchRegex } from '@optx/constants/regex';
// utils
import { generateYears } from '@optx/utils/option';
import { getMostRecentYear, isOptionChecked } from '@optx/utils/option';
import { getAmount, validateField, isYearEstimated } from '@optx/utils/editFields';
import { normalizeNegativeSignValues } from '@optx/utils/utils';
import { highlightText } from '@optx/utils/text';
import { parseNumberFromAbbreviation } from '@optx/utils/number';
import { roundingPresentationValues } from '@optx/utils/dealPresentation';
// components
import EditPopover from '@components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import InputWithDropdown from '@optx/shared/view/molecules/FormFields/InputWithDropdown';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
// redux
import { actions as opportunityActions } from '@redux/company/opportunity-presentation';
// hooks
import useUpdateFields from '../hooks/useUpdateFields';
import useFinancialField from '../hooks/useFinancialField';
// styles
import Container from './EditFieldFinancial.styled';

const { Option } = Select;
const { Text } = Typography;

interface EditFieldFinancialProps {
  companyId: number;
  fieldKey: 'revenue_list' | 'ebitda_list' | 'annual_recurring_revenue' | 'gross_margin_list';
  companyUrl: string | null;
  extraKeys?: string[];
  fieldListValues: FinancialFieldType[] | null;
  service: ServiceType | null;
  fieldName: FieldOptions;
  isFromGrid?: boolean;
  dbSource?: string | null;
  hidePenIcon?: boolean;
  isFromHistory?: boolean;
  options: SelectOption[];
  actionPendo?: string;
  customFinancialYear?: number;
}

const EditFieldFinancial: React.FC<EditFieldFinancialProps> = ({
  fieldKey,
  // used for property names required by api
  extraKeys,
  companyId,
  companyUrl,
  fieldListValues,
  service,
  fieldName,
  isFromGrid,
  dbSource,
  hidePenIcon,
  isFromHistory,
  options,
  actionPendo,
  customFinancialYear,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const prevFieldListValues = usePrevious(fieldListValues);
  const prevOptions = usePrevious(options);

  const [label, setLabel] = useState<string>('');
  const [amount, setAmount] = useState<string | undefined>();
  const [year, setYear] = useState<string | number | undefined>();
  const [isFromSource, setIsFromSource] = useState<boolean | undefined>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [closePopup, setClosePopup] = useState(false);

  const { updateField } = useUpdateFields({
    fieldName,
    companyId,
    companyUrl,
    service,
    isFromHistory,
    extraKeys,
  });

  const { updateFinancialFields, isAmountValid, isAmountValidForSource } = useFinancialField({
    amount,
    fieldListValues,
    options,
    isFromSource,
    setLabel,
    setAmount,
    setYear,
    setIsFromSource,
    setDisabled,
    setError,
    fieldName,
    customFinancialYear,
  });
  const title = `Edit "${fieldName}" value`;
  const placeholder = fieldName === 'GM %' ? title : 'in millions of dollars';
  const opportunityPresentationKey = fieldName === 'ARR' ? 'annual_recurring_revenue' : 'gm';

  const handleSelectOption = (event: MenuInfo) => {
    containerRef.current && containerRef.current.focus();
    setAmount(event.key);
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setAmount(value);
  };

  const handleYearChange = (value: number) => {
    // get the amount associated with the selected year
    const amountValue = getAmount(fieldListValues as FinancialFieldType[], Number(value));

    if (amountValue === undefined || typeof amountValue === 'string') {
      setAmount(amountValue);
    } else if (typeof amountValue === 'number' && fieldName === 'GM %') {
      setAmount(amountValue.toString());
    } else if (typeof amountValue === 'number') {
      setAmount((Number(amountValue)! / 1000000).toString());
    } else {
      const rangeLabel = options.find(option => isEqual(option.rangeValue, amountValue))?.label;
      setAmount(rangeLabel);
    }

    setYear(value);
  };

  const handleCancel = () => {
    updateFinancialFields();
    setError('');
    setClosePopup(true);
  };

  const getIsValueChanged = () => {
    // if user doesn't change the current value just close the popover
    const currentValue = getAmount(fieldListValues, Number(year));
    const isAmountAbbreviated =
      typeof amount === 'string' && regexNegativeAbbreviation.test(amount);

    const amountValueInMillions =
      amount === '' || amount === undefined
        ? null
        : isAmountAbbreviated
        ? parseNumberFromAbbreviation(amount)
        : Number(amount)! * 1000000;

    const isFromDropdown = isOptionChecked(amount || null, options);

    let parsedAmount: string | number | null | boolean | FinancialFieldRangeValue =
      isAmountValid() && amountValueInMillions;

    if (fieldName === 'GM %') {
      const amountHasPercentage =
        typeof amount === 'string' && numberPercentageMatchRegex.test(amount);
      parsedAmount = null;

      if (amount !== undefined && amount !== '') {
        parsedAmount = amountHasPercentage ? parseInt(amount.replace('%', '')) : Number(amount);
      }
    }

    if (isFromDropdown && options) {
      const option = options.find(option => option.label === amount);
      parsedAmount = option?.rangeValue ?? option?.value ?? null;
    }

    if (!parsedAmount && parsedAmount !== 0) {
      parsedAmount = DEFAULT_EMPTY_VALUE;
    }

    let isValueChanged: boolean =
      currentValue === undefined && parsedAmount === DEFAULT_EMPTY_VALUE
        ? false
        : !isEqual(currentValue, parsedAmount);

    return { isValueChanged, parsedAmount };
  };

  const handleSave = () => {
    const { isValueChanged, parsedAmount } = getIsValueChanged();
    const normalizedAmount =
      parsedAmount !== DEFAULT_EMPTY_VALUE && !Number.isNaN(Number(parsedAmount))
        ? roundingPresentationValues(Number(parsedAmount))
        : parsedAmount;

    // check if any values were changed
    if (!isValueChanged) {
      setClosePopup(true);
    } else if (
      validateField(amount!, VALIDATE_EDIT_FINANCIAL_FIELD_KEYS[fieldKey], options) &&
      isAmountValidForSource(year as number)
    ) {
      if (fieldKey === 'annual_recurring_revenue' && !isFromGrid) {
        const payload = {
          company_id: companyId,
          financial: {
            annual_recurring_revenue: {
              value: normalizedAmount,
              year: Number(year),
              estimated: isYearEstimated(fieldListValues, year as number),
            },
          },
        };

        dispatch(opportunityActions.updateOpportunityPresentation(payload, isFromHistory));
      } else {
        if (fieldKey === 'annual_recurring_revenue' || fieldKey === 'gross_margin_list') {
          const ArrValue = {
            financial: {
              [opportunityPresentationKey]: {
                value: normalizedAmount,
                year: Number(year),
                estimated: isYearEstimated(fieldListValues, year as number),
              },
            },
          };
          updateField(ArrValue, fieldKey);
        } else {
          const normalizedValue =
            normalizedAmount || normalizedAmount === 0 ? normalizedAmount : DEFAULT_EMPTY_VALUE;

          updateField({ value: normalizedValue, optionalValue: Number(year) }, fieldKey);
        }
      }

      setClosePopup(true);
    }
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  // hook used for updating label and popover input values on first mount
  // and after request is finished and redux store is updated
  useEffect(() => {
    if (!isEqual(fieldListValues, prevFieldListValues) || !isEqual(prevOptions, options)) {
      updateFinancialFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldListValues, options]);

  useEffect(() => {
    // if validation returns error, disable save button until value in input field is valid
    if (disabled && isAmountValid()) {
      setDisabled(false);
      setError('');
    }

    if (!getIsValueChanged().isValueChanged) setDisabled(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const content = (
    <Container
      isArr={fieldName === 'ARR'}
      className={
        'profile-information__financial ' + (fieldKey === 'ebitda_list' ? 'ebitda-value' : '')
      }
      ref={containerRef}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Row className="amount-wrapper">
        <Text>{`${fieldName} Amount`}</Text>
        {!closePopup && options.length !== 0 ? (
          <InputWithDropdown
            options={options}
            placeholder={placeholder}
            value={amount as string | undefined}
            onInputChange={handleAmountChange}
            onSelectOption={handleSelectOption}
            allowClear
          />
        ) : (
          <Input
            placeholder={placeholder}
            value={amount}
            onChange={handleAmountChange}
            allowClear
          />
        )}
        {error && (
          <Text className="error" style={{ maxWidth: '300px;' }} type="danger">
            {error}
          </Text>
        )}
      </Row>

      <Row>
        <Text>{`${fieldName} Year`}</Text>
        <Select placeholder="select an option" value={year as number} onChange={handleYearChange}>
          {generateYears(Number(getMostRecentYear(fieldListValues))).map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Row>
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          disabled={disabled}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Container>
  );

  if (!isFromGrid) {
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        onVisibilityChange={handleCancel}
        hidePenIcon={hidePenIcon}
        actionPendo={actionPendo}
      >
        {!hidePenIcon && !isFromHistory ? (
          <span style={highlightText(label)}>{normalizeNegativeSignValues(label)}</span>
        ) : (
          'Edit Field'
        )}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditPopover
          content={content}
          onVisibilityChange={handleCancel}
          title={title}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
        />
      }
      fill
    >
      <TruncateTooltip title={normalizeNegativeSignValues(label)}>
        <span style={highlightText(label)}>{normalizeNegativeSignValues(label)}</span>
      </TruncateTooltip>
    </InlineEdit>
  );
};

export default React.memo(EditFieldFinancial);
