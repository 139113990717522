import React from 'react';
import { ColumnType } from 'antd/lib/table';
// models
import { CompanyUserContact } from '@models/api/contacts';
// services
import { CompanyService } from '@optx/services/api';
// components
import { EditFieldText } from '@optx/features/company/edit-fields/components';
import {
  TableCellCompanyName,
  TableCellEmail,
  TableCellFormatNumber,
  TableCellDateInterval,
  TableCellCeoRating,
} from '../AntTable/cells';
import TableCellBoolean from '../AntTable/cells/TableCellBoolean';
import { TableCellList } from '../TableContactInformation/cells';
import TableCellDefaultRender from '../AntTable/cells/TableCellDefault';

const columns: ColumnType<CompanyUserContact>[] = [
  {
    dataIndex: 'source_db',
    title: 'Source',
    className: 'source_db',
    sorter: true,
    render: TableCellDefaultRender,
    width: 100,
  },
  {
    dataIndex: 'first_name',
    title: 'First Name',
    className: 'first_name',
    sorter: true,
    render: TableCellDefaultRender,
    width: 120,
  },
  {
    dataIndex: 'last_name',
    title: 'Last Name',
    className: 'last_name',
    sorter: true,
    render: TableCellDefaultRender,
    width: 120,
  },
  {
    dataIndex: 'title',
    title: 'Title',
    className: 'title',
    sorter: true,
    render: TableCellDefaultRender,
    width: 150,
  },
  {
    dataIndex: 'ceo_approval',
    title: 'CEO Approval',
    className: 'ceo_approval',
    sorter: true,
    render: value => TableCellCeoRating(value, 'Not Rated'),
    width: 100,
  },
  {
    dataIndex: 'display_location',
    title: 'Location',
    className: 'display_location',
    sorter: true,
    render: TableCellDefaultRender,
    width: 150,
  },
  {
    dataIndex: 'company_name',
    title: 'Company',
    className: 'company_name',
    sorter: true,
    render: TableCellCompanyName,
    width: 200,
  },
  {
    dataIndex: 'tenure_start_date',
    title: 'Tenure',
    className: 'tenure_start_date align-center',
    sorter: true,
    render: TableCellDateInterval,
    width: 160,
  },
  {
    dataIndex: 'email',
    title: 'Email',
    className: 'email',
    render: TableCellEmail,
    width: 150,
  },
  {
    dataIndex: 'phone',
    title: 'Phone',
    className: 'phone',
    render: TableCellDefaultRender,
    width: 150,
  },
  {
    dataIndex: 'linkedin',
    title: 'LinkedIn Hyperlink',
    className: 'linkedin align-center',
    render: (value: string, record: CompanyUserContact) => (
      <EditFieldText
        value={value}
        record={record}
        fieldName="Linkedin Profile"
        service={CompanyService.updateLinkedinURL}
        isFromGrid
        entityType="person"
        isURL
        isContact
        successMessage="Contact linkedin value updated successfully"
      />
    ),
    width: 100,
  },
  {
    dataIndex: 'cb_rank',
    title: 'Crunchbase Rank',
    className: 'cb_rank align-right',
    sorter: true,
    render: TableCellFormatNumber,
    width: 110,
  },
  {
    dataIndex: 'country_code',
    title: 'Country Code',
    className: 'country_code',
    sorter: true,
    render: TableCellDefaultRender,
    width: 100,
  },
  {
    dataIndex: 'state_code',
    title: 'State Code',
    className: 'state_code',
    sorter: true,
    render: TableCellDefaultRender,
    width: 100,
  },
  {
    dataIndex: 'city',
    title: 'City',
    className: 'city',
    sorter: true,
    render: TableCellDefaultRender,
    width: 100,
  },
  {
    dataIndex: 'portfolio_executive',
    title: 'Portfolio Executive',
    className: 'portfolio_executive',
    sorter: true,
    render: TableCellBoolean,
    width: 100,
  },
  {
    dataIndex: 'psg_function',
    title: 'PSG Function',
    className: 'psg_function',
    width: 150,
    sorter: true,
    render: (value: any, record: any, index: number) => (
      <TableCellList value={value} record={record} />
    ),
    ellipsis: true,
  },
  {
    dataIndex: 'slack_channel',
    title: 'Slack Channel',
    className: 'slack_channel',
    width: 150,
    sorter: true,
    render: (value: any, record: any, index: number) => (
      <TableCellList value={value} record={record} />
    ),
    ellipsis: true,
  },
];

export default columns;
