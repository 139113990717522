import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// models
import { SelectOption } from '@models/Option';
import { AddonAdditionalFiltersKeys } from '@redux/company/addon-management/interfaces';
// constants
import { CompanyPageAddOnMGMT } from '@constants/pendoActions';
// utils
import { getMultiSelectOptions } from '@optx/utils/filters/mapFiltersOptions';
import { mapToSelectOption } from '@optx/utils/option';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { selectors, actions } from '@redux/company/addon-management';
import { selectors as favoriteListsSelectors } from '@redux/favorite-lists/lists';
// hooks
import useLocalMultiSelect from '@optx/common/hooks/select/useLocalMultiSelect';
// components
import { MultiSelectInline, MultiSelectProps } from '@shared/view/molecules/Select';
import { StyledMultiSelectWrapper } from './AddonMultiSelect.styled';

interface AddonMultiSelectFilterProps extends MultiSelectProps {
  filterKey: AddonAdditionalFiltersKeys;
}

export const AddonMultiSelectFilter: React.FC<AddonMultiSelectFilterProps> = ({
  filterKey = 'Origin',
  ...restProps
}) => {
  const [options, setOptions] = useState<Array<SelectOption>>();

  const dispatch = useDispatch();
  const filterData = useSelector(selectors.getAdditionalFilters);
  const savedLists = useSelector(favoriteListsSelectors.getLists);
  const transformedFilterKey = filterKey.toLowerCase();
  const normalizedData = filterData.normalized[transformedFilterKey];
  const companyId = useSelector(profileSelectors.getCompanyId);

  const valuesData = useSelector(selectors.getAdditionalValues);

  const values = valuesData[transformedFilterKey];

  useEffect(() => {
    if (normalizedData) {
      const sortedOptions = getMultiSelectOptions(normalizedData as any) as SelectOption[];
      const normalizedOptions = sortedOptions.map(item => {
        if (savedLists[item.value]) {
          return {
            ...item,
            canBeOpened: true,
          };
        }

        return item;
      });

      setOptions(normalizedOptions);
    }
  }, [savedLists, normalizedData]);

  const updateValue = useCallback(
    (newValue: Array<SelectOption>) => {
      if (companyId !== undefined) {
        dispatch(
          actions.updateAdditionalFilters({
            value: newValue,
            key: transformedFilterKey,
            isPaginationChanged: true,
            companyId: companyId,
            shouldResetPageNumber: true,
          })
        );
      }
    },

    [dispatch, transformedFilterKey, companyId]
  );

  const { localValue, onBlur, onChange } = useLocalMultiSelect(values as any, updateValue);

  const normalizedLocalValue = localValue
    ? (localValue as SelectOption[]).map(item => {
        if (!item.hasOwnProperty('disabled')) {
          return mapToSelectOption(item);
        }

        return item;
      })
    : [];

  return (
    <StyledMultiSelectWrapper>
      <MultiSelectInline
        options={options}
        onBlur={() => {
          window.pendo.track(CompanyPageAddOnMGMT.origin);
          onBlur();
        }}
        onChange={onChange}
        value={normalizedLocalValue}
        placeholder={filterKey}
        allPrefix={filterKey}
        {...restProps}
      />
    </StyledMultiSelectWrapper>
  );
};

export default AddonMultiSelectFilter;
