import React, { useMemo, useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { OptionsType, ValueType, ActionMeta } from 'react-select';
import { Dictionary } from 'lodash';
// models
import { MultiSelectFilter } from '@optx/models/filters';
import Option, { FilterMultiSelectOption } from '@optx/models/Option';
// utils
import { mapToSelectOption } from '@utils/option';
// components
import { MultiSelect } from '@shared/view/molecules/Select';
import SearchFilterCard from './SearchFilterCard';
import { FiltersContext } from '../FiltersContext';

interface FilterMultiSelectOptions {
  filter: MultiSelectFilter<Array<FilterMultiSelectOption>>;
  autoFocus?: boolean;
}

// this multi select helper is a type that is not send to getHistogram or search query.
// it just add/remove values on dependent filters.
const FilterMultiSelectHelper: React.FC<FilterMultiSelectOptions> = ({ filter, autoFocus }) => {
  const [field, , helpers] = useField<Array<FilterMultiSelectOption> | undefined>(filter.column);
  const { values: formFields, getFieldHelpers } =
    useFormikContext<Dictionary<Array<FilterMultiSelectOption>>>();
  const { onManualFilterChange } = useContext(FiltersContext);

  const options: OptionsType<FilterMultiSelectOption> = useMemo(
    () => filter.data.map(option => mapToSelectOption(option, filter.format_name)),
    [filter.data, filter.format_name]
  );

  const filterChanged = () => {
    // Call on change on the next tick.
    setTimeout(() => {
      onManualFilterChange && onManualFilterChange(filter.column);
    }, 0);
  };

  // In case the multiselect has dependent filters, this function
  // sends the values from the 'entries' property to the dependent filter.
  // eg: Europe by Tier. When selected, it selects the Country filter.
  const handleDependentFilter = (
    values: ValueType<FilterMultiSelectOption>,
    action?: ActionMeta<FilterMultiSelectOption>
  ) => {
    filter.used_by.forEach((usedByColumn: string) => {
      let dependentFieldValues: Array<Option> = formFields[usedByColumn]
        ? [...formFields[usedByColumn]]
        : [];

      // if action is to remove/deselect option, then get all keys from the deselected option
      // and filter the current records based on the previous keys map.
      if (action?.action === 'remove-value' || action?.action === 'deselect-option') {
        if (action.option!.value !== '*') {
          const removedKeys = action.option!.entries!.map(removedKey => removedKey.value);

          // return only values that are not in the removedKeys list.
          dependentFieldValues = dependentFieldValues.filter(
            (fieldValue: Option) => removedKeys.indexOf(fieldValue.value) === -1
          );
        }

        if (action?.action === 'remove-value') {
          filterChanged();
        }
      }
      // if action is to add new options entries, then just guarantee that there are no
      // repeated value being inserted.
      else if ((values as Array<FilterMultiSelectOption>).length) {
        const currentDependentValues: Dictionary<boolean> = {};

        dependentFieldValues.forEach(option => {
          currentDependentValues[option.value] = true;
        });

        // loop through each value to see if there is an entries property.
        (values as Array<FilterMultiSelectOption>).forEach(valueEntry => {
          if (valueEntry.entries) {
            // get all values from entries mapped to current pattern.
            const newValues: ValueType<Option> = valueEntry.entries
              .map(option => mapToSelectOption(option, filter.format_name))
              // remove duplicated entries values based on current selected list.
              .filter(fieldValue => !currentDependentValues[fieldValue.value]);

            dependentFieldValues = [...dependentFieldValues, ...newValues];
          }
        });
      } else {
        const allDependentValues: Dictionary<boolean> = {};

        options.forEach(option => {
          if (option.entries) {
            option.entries.reduce((acumulator, option) => {
              // eslint-disable-next-line no-param-reassign
              acumulator[option.value] = true;

              return acumulator;
            }, allDependentValues);
          }
        });

        dependentFieldValues = dependentFieldValues.filter(
          option => !allDependentValues[option.value]
        );
      }

      getFieldHelpers(usedByColumn).setValue(dependentFieldValues);
    });
  };

  const handleChange = (
    values: ValueType<FilterMultiSelectOption>,
    action?: ActionMeta<FilterMultiSelectOption>
  ) => {
    helpers.setValue(values as Array<FilterMultiSelectOption>);

    if (filter.used_by && filter.used_by.length) {
      handleDependentFilter(values, action);
    }
  };

  const handleBlur = () => {
    filterChanged();
  };

  return (
    <SearchFilterCard label={filter.label}>
      <MultiSelect
        autoFocus={autoFocus}
        options={options}
        onChange={handleChange}
        value={field.value}
        placeholder={filter.placeholders}
        onBlur={handleBlur}
      />
    </SearchFilterCard>
  );
};

export default React.memo(FilterMultiSelectHelper);
