import React, { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
// constants
import { CompanyPageAddOnMGMT } from '@constants/pendoActions';

// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import { actions } from '@redux/company/addon-management';
import { actions as searchKeyActions } from '@features/grid/searchkey';
// components
import SearchInput, { SearchInputProps } from '@components/common/form/input/SearchInput';

interface CompaniesSearchProps extends Omit<SearchInputProps, 'onSearch' | 'value'> {}

const CompaniesSearch: React.FC<CompaniesSearchProps> = ({ onFocus, onBlur, ...restProps }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const inputRef = useRef<Input | null>(null);

  const companyId = useSelector(profileSelectors.getCompanyId);

  const search = useCallback(
    (searchKey: string) => {
      if (companyId !== undefined) {
        dispatch(searchKeyActions.setSearchKey({ gridKey: 'addons', data: searchKey }));
        dispatch(
          actions.fetchCompanyAddonManagement({ companyId: companyId, shouldResetPageNumber: true })
        );
      }
    },
    [dispatch, companyId]
  );

  const handleSearch = (searchKey: string) => {
    // close the dropdown by clicking on input.
    window.pendo.track(CompanyPageAddOnMGMT.search);
    inputRef.current!.input?.click();
    search(searchKey);
    setQuery('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <SearchInput
      ref={inputRef}
      {...restProps}
      onChange={handleChange}
      onSearch={handleSearch}
      value={query}
      placeholder="Search for companies by keyword"
    />
  );
};

export default CompaniesSearch;
