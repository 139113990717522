import React from 'react';
import { Col } from 'antd';
import { RowProps } from 'antd/lib/row';
import Styled from './KeyInformationSectionRow.styled';

interface KeyInformationSectionRowProps extends RowProps {
  label: string;
  overflowHidden?: boolean;
}

export const KeyInformationSectionRow = ({
  label,
  children,
  overflowHidden = true,
  ...rest
}: KeyInformationSectionRowProps) => {
  return (
    <Styled.Row $overflowHidden={overflowHidden} {...rest}>
      <Col className="profile-information__label">{label}</Col>
      <Col className="profile-information__value">{children}</Col>
    </Styled.Row>
  );
};
