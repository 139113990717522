import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// models
import { CompanyUserValue } from '@models/Company';
import { SuccessErrorCallback } from '@optx/models/callback';
import { APIBoolean } from '@optx/models/api/generic';
// services
import { UserService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { mapCompanyPropName } from '@utils/mapCompanyPropName';
// redux
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { PageInfo } from '@optx/models/table/Pagination';
import * as actions from './actions';
import { actions as presentantionActions } from '../opportunity-presentation';

export function* updateCompanyUserValuesSaga(
  action: PayloadAction<CompanyUserValue, any, SuccessErrorCallback>
) {
  yield put(customGlobalLoaderActions.toggle({ loading: true, customText: '' }));

  try {
    const { meta: callback } = action;
    const res: AxiosResponse = yield call(UserService.updateCompanyUserValues, action.payload);

    if (res.data) {
      yield put(actions.updateCompanyUserValuesSuccess(action.payload));

      yield put(
        actions.updateCompanyEmployeeGrowthPercentage({
          companyId: action.payload.companyId,
          empGrowthPercent: res.data.emp_growth_percent,
        })
      );

      callback && callback(true);

      NotificationService.success(
        `Company ${mapCompanyPropName(action.payload.fieldKey)} updated successfully!`
      );

      yield put(actions.updateCompanyOptxScoreSuccess(res.data));
      yield put(
        actions.updateCompanyTrendingLines({
          employeeCountTrends: res.data?.employee_count_trends,
          optxScoreTrends: res.data?.optx_score_trends,
          newOptxScoreTrends: res.data?.optx_score_v3_trends,
          companyId: action.payload.companyId,
        })
      );

      if (action.payload.fieldKey === 'last_rev_update_amount') {
        // To update the score growth, we need to fetch the opportunities again
        yield put(presentantionActions.fetchOpportunityPresentation(action.payload.companyId));
      }

      if (action.payload.fieldKey === 'ebitda_amount') {
        // To update the score growth, we need to fetch the opportunities again
        yield put(presentantionActions.fetchOpportunityPresentation(action.payload.companyId));
      }

      if (action.payload.isFromHistory) {
        const pagination: PageInfo = yield select(auditSelectors.getPagination);
        const field: string = yield select(auditSelectors.getSearchQuery);
        const optx: APIBoolean = yield select(auditSelectors.getOPTX);
        const internal: APIBoolean = yield select(auditSelectors.getInternal);
        const startDate: string = yield select(auditSelectors.getStartDate);
        const endDate: string = yield select(auditSelectors.getEndDate);

        yield put(
          auditActions.fetchChangedCompanyFields({
            companyId: action.payload.companyId,
            pagination,
            field,
            internal,
            optx,
            startDate,
            endDate,
          })
        );
      }
    } else {
      NotificationService.error('Failed to update value!');
    }

    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
  } catch (error: any) {
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));

    NotificationService.error('Failed to update value try catch!');
  }
}

export default function* userEnteredValuesSaga() {
  yield takeLatest(actions.updateCompanyUserValues, updateCompanyUserValuesSaga);
}
