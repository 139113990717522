import { createAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import { PreselectedFilter } from '@optx/models/filters';
import { CompanyWatchList } from '@optx/models/WatchList';
import { ColumnWidth, ColumnWidthUserSettings } from '@optx/models/table/Columns';
// local
import {
  PipelineReportCardResponse,
  PipelineReportFetchFiltersSuccessPayload,
  PipelineReportFundAssociation,
  PipelineReportResetFilterPayload,
  PipelineReportSingleSelectPayload,
} from './interfaces';
import * as types from './types';
import { PipelineReportCompanies, PipelineReportCompaniesColumns } from '../models';

export const fetchPipelineReportCompany = createAction<
  undefined | boolean | PipelineReportFundAssociation | PipelineReportSingleSelectPayload
>(types.FETCH_PIPELINE_REPORT_COMPANY);
export const fetchPipelineReportCompanySuccess = createAction<{
  companies: PipelineReportCompanies[];
  total_count: number;
}>(types.FETCH_PIPELINE_REPORT_COMPANY_SUCCESS);
export const fetchPipelineReportCompanyFail = createAction<string>(
  types.FETCH_PIPELINE_REPORT_COMPANY_FAIL
);

export const fetchPipelineReportCompanyCount = createAction<string>(
  types.FETCH_PIPELINE_REPORT_COMPANY_COUNT
);
export const fetchPipelineReportCompanyCountSuccess = createAction<number>(
  types.FETCH_PIPELINE_REPORT_COMPANY_COUNT_SUCCESS
);
export const fetchPipelineReportCompanyCountFail = createAction<string>(
  types.FETCH_PIPELINE_REPORT_COMPANY_COUNT_FAIL
);

export const fetchPipelineReportCard = createAction<
  undefined | boolean | PipelineReportSingleSelectPayload
>(types.FETCH_PIPELINE_REPORT_CARD);
export const fetchPipelineReportCardSuccess = createAction<PipelineReportCardResponse>(
  types.FETCH_PIPELINE_REPORT_CARD_SUCCESS
);
export const fetchPipelineReportCardFail = createAction<string>(
  types.FETCH_PIPELINE_REPORT_CARD_FAIL
);

// save search as list
export const saveSearchAsList = createAction<{ title: string }>(types.SAVE_SEARCH_AS_LIST);

export const saveSearchAsListSuccess = createAction<Dictionary<CompanyWatchList>>(
  types.SAVE_SEARCH_AS_LIST_SUCCESS
);
export const saveSearchAsListFail = createAction<string>(types.SAVE_SEARCH_AS_LIST_FAIL);

// filters
export const fetchFilters = createAction(types.FETCH_FILTERS);
export const fetchFiltersSuccess = createAction<PipelineReportFetchFiltersSuccessPayload>(
  types.FETCH_FILTERS_SUCCESS
);
export const fetchFiltersFail = createAction<string>(types.FETCH_FILTERS_FAIL);
export const clearFilters = createAction(types.FILTER_CLEAR);
export const resetToDefault = createAction(types.RESET_TO_DEFAULT);
export const resetFilter = createAction<PipelineReportResetFilterPayload>(types.FILTER_RESET);
export const updateValue = createAction<PipelineReportSingleSelectPayload>(types.UPDATE_VALUE);
export const applyFilters = createAction<Dictionary<PreselectedFilter>>(types.APPLY_FILTERS);

// ui
export const toggleFiltersModal = createAction(types.TOGGLE_FILTERS_MODAL);

// columns
export const saveColumnWidth = createAction<ColumnWidth<PipelineReportCompaniesColumns>>(
  types.SAVE_COLUMN_WIDTH
);
export const resetColumnWidths = createAction<ColumnWidthUserSettings>(types.RESET_COLUMN_WIDTHS);

// hide cards
export const hideCardsToggle = createAction(types.HIDE_CARDS_TOGGLE);
