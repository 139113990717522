import React from 'react';
import { Dropdown, Button, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// constants
import { EXPORT_SAVE_TITLE, EXPORT_TITLE, EXPORT_SORTING_TITLE } from '@constants/titles';
import { CompanyPageAddOnMGMT } from '@optx/constants/pendoActions';
// redux
import { selectors as fullscreenSelectors } from '@redux/ui/settings/fullscreen';
// components
import SearchMenu from './SearchMenu';

interface CompanySearchDropdownProps {
  isAddonManagement?: boolean;
}

const CompanySearchDropdown: React.FC<CompanySearchDropdownProps> = ({
  children,
  isAddonManagement,
}) => {
  const isFullscreen = useSelector(fullscreenSelectors.isFullscreen);

  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (flag: boolean) => {
    window.pendo.track(CompanyPageAddOnMGMT.exportSaveSearch);
    setVisible(flag);
  };

  return (
    <Tooltip
      title={
        isAddonManagement ? EXPORT_TITLE : isFullscreen ? EXPORT_SORTING_TITLE : EXPORT_SAVE_TITLE
      }
      placement="topRight"
    >
      <Dropdown
        overlay={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <SearchMenu isAddonManagement={isAddonManagement} hideDropdown={() => setVisible(false)}>
            {children}
          </SearchMenu>
        }
        trigger={['click']}
        visible={visible}
        placement="bottomRight"
        onVisibleChange={handleVisibleChange}
      >
        <Button
          type="text"
          onClick={() => setVisible(!visible)}
          icon={<EllipsisOutlined style={{ fontSize: 28 }} />}
        />
      </Dropdown>
    </Tooltip>
  );
};

export default CompanySearchDropdown;
