import { createSelector } from 'reselect';
import queryString from 'query-string';
// models
import { AppState } from '@optx/redux/interfaces';
import { GridKeys } from '@models/grid';
import { SelectOption } from '@optx/models/Option';
import { SpecialFilterTag } from '@optx/models/tags';
// utils
import { generateUniqueKey } from '@optx/utils/uuid';
import { selectFeatureState } from '../../selectFeature';
import mapTags from '@optx/utils/filters/mapTags';
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
// redux
import { getSearchKey as searchKey } from '@optx/features/grid/searchkey/state/selectors';

export const getModalsIsOpen = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => state.pagination[gridKey]);

export const getFilter = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => state.filter[gridKey].filter);

export const getSimilarCompanyName = () =>
  createSelector(selectFeatureState, state => {
    const similarCompanyFilterName = state.filter.advancedSearch?.filter.similarCompanies;

    return similarCompanyFilterName as SelectOption;
  });

export const getDefaultFilter = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => state.filter[gridKey].preselected);

export const getClearedFilter = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => state.filter[gridKey].clear);

export const getCustomClearedFilter = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => state.filter[gridKey]?.customClearedFilter);

export const getFiltersLoaded = (gridKey: GridKeys) =>
  createSelector(selectFeatureState, state => !!state.filter[gridKey]?.fetchedAt);

const selectCompanyFilters = (state: AppState) => state.filters;

const filterGetCompanyFilters = createSelector(selectCompanyFilters, state => state.data || []);
export const filterGetFiltersMap = createSelector(selectCompanyFilters, state => state.filters);

export const getTags = (gridKey: GridKeys) =>
  createSelector(
    getFilter(gridKey),
    filterGetCompanyFilters,
    getClearedFilter(gridKey),
    searchKey(gridKey),
    (filter, filterSources, clearedFilter, searchKey) => {
      const tags = mapTags(filter, filterSources, clearedFilter);

      if (searchKey) {
        tags.unshift({
          filter: 'keyword' as SpecialFilterTag,
          uniqueKey: generateUniqueKey(),
          label: 'Keyword',
          values: [
            {
              value: searchKey,
            },
          ],
        });
      }

      return tags;
    }
  );

/**
 * Check the current filter against custom cleared filter to be able to display custom clear filter button.
 */
export const showCustomClearFilter = (gridKey: GridKeys) =>
  createSelector(
    searchKey(gridKey),
    filterGetFiltersMap,
    getFilter(gridKey),
    getCustomClearedFilter(gridKey),
    (searchKey, normalizedFilters, filter, customClearedFilter) => {
      // If there is a search key there is no need for further check.
      if (searchKey || filter.similarCompanies) {
        return true;
      }

      const normalizedFilter = { ...filter };

      if (normalizedFilter.ss_list_id !== undefined) {
        delete normalizedFilter.ss_list_id;
      }

      if (normalizedFilter.saved_list_id !== undefined) {
        delete normalizedFilter.saved_list_id;
      }

      // Check current filter against custom filter.
      const filterURLParams = mapFiltersToURLParams(normalizedFilters, normalizedFilter);
      const filterQuery = queryString.stringify(filterURLParams, { arrayFormat: 'comma' });

      const customFilterURLParams = mapFiltersToURLParams(normalizedFilters, customClearedFilter);
      const customFilterQuery = queryString.stringify(customFilterURLParams, {
        arrayFormat: 'comma',
      });

      return filterQuery !== customFilterQuery;
    }
  );
