import React from 'react';
import { DropdownAddMenuItemProps } from '@models/DropdownAdd';
import { Menu } from 'antd';

const { Item } = Menu;

const DropdownAddMenuItem: React.FC<DropdownAddMenuItemProps> = ({
  value,
  isVisible,
  render,
  onClick,
  actionIndex,
  index,
  className,
  ...rest
}) => {
  return isVisible === undefined || isVisible === true ? (
    <Item
      key={index}
      className={'ant-dropdown-menu-item ' + (className || '')}
      onClick={() => {
        document.body.classList.remove('popover-visible');
        actionIndex(index);
      }}
      {...rest}
    >
      {value || (render && render())}
    </Item>
  ) : null;
};

export default DropdownAddMenuItem;
