import React from 'react';
// models
import { CompanyUserContact } from '@models/api/contacts';
// components
import { ColumnType } from 'antd/lib/table';
// cells
import { TableCellDateInterval, TableCellEmail } from '@components/common/table/AntTable/cells';
import { TableCellIcons, TableCellActions, TableCellName } from './cells';
import TableCellDefaultRender from '../AntTable/cells/TableCellDefault';

const columns: ColumnType<CompanyUserContact>[] = [
  {
    dataIndex: 'full_name',
    title: 'Name',
    className: 'full_name',
    width: 300,
    render: TableCellName,
  },
  {
    dataIndex: 'title',
    title: 'Title',
    className: 'title',
    width: 160,
    render: TableCellDefaultRender,
  },
  {
    dataIndex: 'tenure_start_date',
    title: 'Tenure',
    className: 'tenure',
    width: 150,
    render: TableCellDateInterval,
  },
  {
    dataIndex: 'email',
    title: 'Email Address',
    className: 'email',
    width: 160,
    render: TableCellEmail,
  },
  {
    dataIndex: 'icons',
    title: 'Sources',
    className: 'icons',
    width: 200,
    render: TableCellIcons,
  },
  {
    dataIndex: 'row_actions',
    title: 'Action',
    width: 65,
    className: 'row_actions sticky sticky-right',
    render: (value: any, record: CompanyUserContact, index: number) => (
      <TableCellActions value={value} record={record} index={index} />
    ),
  },
];

export default columns;
