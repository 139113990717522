import React, { useEffect, useState } from 'react';
import { Col, Typography, Input } from 'antd';
import { useField, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { Form } from 'formik-antd';
import styled from 'styled-components';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
// constants
import { numberBigRegExpEndingDot, numberBigRegExp, regexAbbreviation } from '@constants/regex';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { numberToMillions } from '@utils/number';

const validationFields = ['capital_raised', 'last_round_amount'];

const InputWrapper = styled.div`
  &.has-errors .ant-input,
  &.has-errors .ant-input-affix-wrapper {
    border: 1px solid #ff7875;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }

  &.valid-input .ant-input-affix-wrapper:hover,
  &.valid-input .ant-input-affix-wrapper,
  &.valid-input .ant-input,
  &.valid-input .ant-input:hover {
    border: 1px solid #d9d9d9;
  }
`;

const validateField = (amount: string | number, fieldName: string) => {
  return validationFields.includes(fieldName) &&
    ((amount && Number.isNaN(Number(amount)) && amount !== DEFAULT_EMPTY_VALUE) ||
      (amount as number) < 0) &&
    !regexAbbreviation.test(typeof amount === 'string' ? amount : '')
    ? 'has-errors'
    : 'valid-input';
};

interface InputMillionsFieldProps {
  field: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const InputMillionsField: React.FC<InputMillionsFieldProps> = ({ field, fieldUsedFor }) => {
  const [selected, , setSelected] = useField<number | string | null>(field.id);
  const [amount, setAmount] = useState<string | undefined>(undefined);
  const [isInitialAmount, setIsInitialAmount] = useState(true);

  const formik = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    if (
      numberBigRegExp.test(inputValue) ||
      inputValue === '' ||
      inputValue === DEFAULT_EMPTY_VALUE ||
      regexAbbreviation.test(inputValue)
    ) {
      const isInputValueAbbreviated = regexAbbreviation.test(inputValue);

      setAmount(isNaN(parseInt(inputValue)) && !isInputValueAbbreviated ? '' : inputValue);
    } else if (numberBigRegExpEndingDot.test(inputValue)) {
      setAmount(inputValue);
    }
  };

  useEffect(() => {
    if (fieldUsedFor === 'editAllInfo') {
      setAmount(
        selected.value === null ||
          (isNaN(selected.value as number) &&
            !regexAbbreviation.test(typeof selected.value === 'string' ? selected.value : ''))
          ? ''
          : regexAbbreviation.test(typeof selected.value === 'string' ? selected.value : '')
          ? (selected.value as string)
          : (Number(selected.value)! / 1000000).toString()
      );
    } else if ((field?.value || field?.value === 0) && !selected.value) {
      if (field.value === DEFAULT_EMPTY_VALUE) {
        setAmount('');
      } else {
        setAmount((Number(field.value)! / 1000000).toString());
      }
    }

    setIsInitialAmount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (selected.value) {
      let newAmount = '';

      if (typeof selected.value === 'string' && regexAbbreviation.test(selected.value)) {
        newAmount = selected.value;
      } else {
        newAmount = (Number(selected.value)! / 1000000).toString();
      }

      if (newAmount !== amount) {
        setAmount(newAmount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.value]);

  useEffect(() => {
    if (!isInitialAmount) {
      const normalizedAmount = typeof amount === 'string' ? amount : '';

      if (amount && numberBigRegExp.test(amount)) {
        if (!isEqual(selected.value, numberToMillions(amount))) {
          setSelected.setValue(numberToMillions(amount), true);
        }
      } else if (amount && numberBigRegExpEndingDot.test(amount)) {
        const amountRemovedDot = amount.slice(0, -1);
        setSelected.setValue(numberToMillions(amountRemovedDot));
      } else if (amount === '' || amount === DEFAULT_EMPTY_VALUE) {
        if (selected.value !== null) {
          setSelected.setValue(null);
        }
      } else if (regexAbbreviation.test(normalizedAmount)) {
        setSelected.setValue(normalizedAmount);
      } else {
        setSelected.setValue(Number(amount));
      }
    }

    setTimeout(() => {
      formik.validateForm();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <Col span={8} className={field.id}>
      <Form.Item name={field.id}>
        <Typography.Text>
          {field.label}
          <span> (in millions)</span>
        </Typography.Text>
        <InputWrapper className={validateField(amount ?? '', field.id)}>
          <Input
            value={amount}
            onChange={handleChange}
            placeholder={field.placeholder}
            disabled={!field.editable}
          />
        </InputWrapper>
      </Form.Item>
    </Col>
  );
};

export default React.memo(InputMillionsField);
