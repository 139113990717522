import { Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
// models
import { FileDetails } from '@optx/models/files';
// constants
import { CompanyPageFilesEquityTouchFilesDownload } from '@optx/constants/pendoActions';
// redux
import { actions as fileActions } from '@optx/redux/company/files';

const Wrapper = styled.div`
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  span.tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type TableCellFileNameProps = {
  value: string;
  record: FileDetails;
};

const TableCellFileName = ({ value, record: file }: TableCellFileNameProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(fileActions.downloadFile({ id: file.file_id, name: file.name }));

    window.pendo.track(CompanyPageFilesEquityTouchFilesDownload);
  };

  return (
    <Wrapper key={file.file_id}>
      <Button type="link" onClick={handleClick}>
        <Tooltip className="tooltip" title={file.name}>
          {file.name}
        </Tooltip>
        <DownloadOutlined />
      </Button>
    </Wrapper>
  );
};

export default TableCellFileName;
