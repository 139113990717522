import React, { useMemo } from 'react';
// models
import Company from '@optx/models/Company';
import { Cell } from '@optx/models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@optx/constants/table/cells';
// utils
import { cellIsEqual } from '../../AntTable/cells/memoize';
// components
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const TableCellInvestors: React.FC<Cell<Company>> = ({ value }) => {
  const formattedValue = useMemo(() => {
    if (!value || !value.length || !Array.isArray(value)) {
      return DEFAULT_CELL_VALUE;
    }

    return [...value].sort().join('; ');
  }, [value]);

  if (formattedValue === DEFAULT_CELL_VALUE) {
    return <>{formattedValue}</>;
  }

  return (
    <TruncateTooltip title={formattedValue} placement="topLeft">
      {formattedValue}
    </TruncateTooltip>
  );
};

export default React.memo(TableCellInvestors, cellIsEqual);
