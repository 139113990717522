import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash';
// models
import { EquityTouchContactForm, EquityTouchDatabaseMatch } from '@models/equityTouch';
import { CompanyUserContact } from '@models/api/contacts';
// constants
import { EQT_CONTACT_FORM_INITIAL_STATE } from '@constants/equitytouch';
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// utils
import { mapPhoneNumbers } from '@utils/contact';
// redux
import { selectors as companyIndividualEditSelectors } from '@components/feature/company-individual-edit/state';
import { actions as bulkSalesloftActions } from '@optx/features/bulk-actions/salesloft';
// components
import { ContactForm } from '@optx/features/contact-form';

interface SalesloftAddContactProps {
  visible: boolean;
  contactData: Partial<CompanyUserContact> | undefined;
  maxEmails?: number;
  onCancel: () => void;
  shouldResolve?: boolean;
  isEtBulk?: boolean;
  isExtension?: boolean;
}

export const SalesloftAddContact: React.FC<SalesloftAddContactProps> = ({
  visible,
  contactData,
  onCancel,
  maxEmails,
  shouldResolve,
  isEtBulk,
  isExtension = false,
}) => {
  const dispatch = useDispatch();

  const individualEditOptions = useSelector(
    companyIndividualEditSelectors.companyIndividualEdit.getAllOptions
  );
  const initialValues: EquityTouchContactForm = EQT_CONTACT_FORM_INITIAL_STATE;

  const handleSubmit = (values: Partial<CompanyUserContact>) => {
    const formValues = { ...values };
    const emailList = formValues.emails;

    const newContact = {
      ...formValues,
      email: emailList!
        .filter(value => value.email !== '')
        .map(val => val.email)
        .join(','),
      person_id: contactData?.person_id as number,
      company_name: contactData?.company_name,
      company_url: contactData?.company_url,
      company_id: contactData?.company_id,
      tenure_start_date: formValues.tenure_start_date
        ? moment(formValues.tenure_start_date).format(ISO_DATE_FORMAT)
        : null,
      tenure_end_date:
        formValues.tenure_end_date && formValues.tenure_end_date !== PRESENT_DATE_FORMAT
          ? moment(formValues.tenure_end_date).format(ISO_DATE_FORMAT)
          : null,
      is_edited: true,
      primary_email: contactData?.email,
      phones: mapPhoneNumbers(
        formValues,
        individualEditOptions?.person_phone_type.received_values as EquityTouchDatabaseMatch[]
      ),
      additional_phones: mapPhoneNumbers(
        formValues,
        individualEditOptions?.person_phone_type.received_values as EquityTouchDatabaseMatch[]
      ),
    };

    if (!isEqual(initialValues, values)) {
      window.pendo.track(OPTXChromePlugin.companyPage.save);
      dispatch(bulkSalesloftActions.addContact({ contact: newContact, shouldResolve, isEtBulk }));
    }

    onCancel();
  };

  return (
    <ContactForm
      type="add"
      visible={visible}
      onCancel={() => {
        window.pendo.track(OPTXChromePlugin.companyPage.cancel);
        onCancel();
      }}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      maxEmails={maxEmails}
      isExtension={isExtension}
    />
  );
};
