/* eslint-disable max-len */
import { PayloadAction } from '@reduxjs/toolkit';
import {
  SaveResearchRationaleResponse,
  ScoreRationaleResponse,
} from '@optx/features/add-research-rationale/state/interfaces';
import { CompanyCardState } from '@optx/features/long-card/company-card/state/interfaces';
import { CompanyProfileState } from '@optx/redux/company/profile/interfaces';
import { CompanyProfile } from '@optx/models/Company';

export const submitResearchRationaleSuccessReducer = (
  draftState: CompanyCardState | CompanyProfileState,
  action: PayloadAction<SaveResearchRationaleResponse>
) => {
  const {
    company_id: companyId,
    fields,
    scoreValues,
    scoreRationale,
    newScoreRationale,
  } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    // for score values, according to backend, psg fit and software are called sequentially.
    // so, first we update the data with values coming from psg_fit,
    // then we update the data, if it exists, with values coming from software
    if (fields.psg_fit) {
      draftState.data.is_interesting = fields.psg_fit.value as boolean | null;
      draftState.data.interesting_rationale = fields.psg_fit.rationale as string | null;
      // score values for default score
      draftState.data.score = scoreValues?.fitScore as number;
      draftState.data.score_growth = scoreValues?.fitScoreGrowth?.toString() || null;
      draftState.data.score_v3 = scoreValues?.fitNewScore as number;
      draftState.data.score_v3_growth = scoreValues?.fitNewScoreGrowth?.toString() || null;

      // TODO there are two properties for each israel and default score growth, should change api to
      // keep only one
      if ((draftState.data as CompanyProfile).optx_score_growth !== undefined) {
        (draftState.data as CompanyProfile).optx_score_growth = scoreValues?.fitScoreGrowth || null;
      }

      // score values for israel score
      draftState.data.il_optx_score = scoreValues?.fitIsraelScore as number;

      if (draftState.data.il_score_growth !== undefined) {
        draftState.data.il_score_growth = scoreValues?.fitIsraelScoreGrowth?.toString() || null;
      }

      if ((draftState.data as CompanyProfile).il_optx_score_growth !== undefined) {
        (draftState.data as CompanyProfile).il_optx_score_growth =
          scoreValues?.fitIsraelScoreGrowth || null;
      }
    }

    if (fields.software_company) {
      if (draftState.data.is_software !== fields.software_company.value) {
        draftState.data.is_software_verified = true;
      }

      draftState.data.is_software = fields.software_company.value as boolean;
      draftState.data.is_software_rationale = fields.software_company.rationale as string | null;
      // score values for default score
      draftState.data.score = scoreValues?.softwareScore as number;
      draftState.data.score_growth = scoreValues?.softwareScoreGrowth?.toString() || null;
      draftState.data.score_v3 = scoreValues?.softwareNewScore as number;
      draftState.data.score_v3_growth = scoreValues?.softwareNewScoreGrowth?.toString() || null;

      if ((draftState.data as CompanyProfile).optx_score_growth !== undefined) {
        (draftState.data as CompanyProfile).optx_score_growth =
          scoreValues?.softwareScoreGrowth || null;
      }

      // score values for israel score
      draftState.data.il_optx_score = scoreValues?.softwareIsraelScore as number;

      if (draftState.data.il_score_growth !== undefined) {
        draftState.data.il_score_growth =
          scoreValues?.softwareIsraelScoreGrowth?.toString() || null;
      }

      if ((draftState.data as CompanyProfile).il_optx_score_growth !== undefined) {
        (draftState.data as CompanyProfile).il_optx_score_growth =
          scoreValues?.softwareIsraelScoreGrowth || null;
      }
    }

    // if api returns false for this property, it means no verification was sent for optx score
    if (scoreRationale !== false) {
      if ((scoreRationale as ScoreRationaleResponse).score_type === 'il_optx_score') {
        draftState.data.il_optx_score_rationale = (
          scoreRationale as ScoreRationaleResponse
        ).rationale;
        draftState.data.il_optx_score_rationale_reason = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason;
        draftState.data.il_optx_score_rationale_reason_type = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason_type;
        draftState.data.il_optx_score_verified = (
          scoreRationale as ScoreRationaleResponse
        ).optx_score_verified;
      } else {
        draftState.data.optx_score_rationale = (scoreRationale as ScoreRationaleResponse).rationale;
        draftState.data.optx_score_rationale_reason = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason;
        draftState.data.optx_score_rationale_reason_type = (
          scoreRationale as ScoreRationaleResponse
        ).rationale_reason_type;
        draftState.data.optx_score_verified = (
          scoreRationale as ScoreRationaleResponse
        ).optx_score_verified;
      }
    }

    if (
      newScoreRationale !== false &&
      (newScoreRationale as ScoreRationaleResponse).score_type === 'score_v3'
    ) {
      draftState.data.optx_score_v3_rationale = (
        newScoreRationale as ScoreRationaleResponse
      ).rationale;
      draftState.data.optx_score_v3_rationale_reason = (
        newScoreRationale as ScoreRationaleResponse
      ).rationale_reason;
      draftState.data.optx_score_v3_rationale_reason_type = (
        newScoreRationale as ScoreRationaleResponse
      ).rationale_reason_type;
      draftState.data.optx_score_v3_verified = (
        newScoreRationale as ScoreRationaleResponse
      ).optx_score_verified;
    }
  }
};
