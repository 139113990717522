import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import Overflow from 'rc-overflow';
import { Typography, Button, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
// models
import { TouchFieldProps } from './interface';
import { SelectOption } from '@optx/models/Option';
import { Addon } from '@optx/models/Company';
// components
import MultiSelectAsyncField from './MultiSelectAsyncField';
// styles
import Styled from './MultiSelectField.styled';

const addonAsOptions = (addon: Addon | null) =>
  Object.entries(addon || {}).map(([key, value]) => ({
    label: key,
    value: (value || 0).toString(),
  }));

const optionsAsAddon = (options: SelectOption[]) => {
  const addon: Addon = {};

  options.forEach(option => {
    addon[option.label] = Number(option.value);
  });

  return addon;
};

const MultiSelectAsyncRequiredFieldWrapper: React.FC<TouchFieldProps> = ({ field }) => {
  const [selectedList, , setSelectedList] = useField<Addon | null>(field.id);
  const [fieldRequired] = useField<SelectOption>(field.requiredFor?.field ?? '');

  const fieldValueAsArray = addonAsOptions(field.value);

  const [selected, setSelected] = useState<SelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isFieldVisible, setIsFieldVisible] = useState(false);
  const [filterTagValues, setFilterTagValues] = useState<SelectOption[]>(fieldValueAsArray);
  const [isInitialAmount, setIsInitialAmount] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(true);

  useEffect(() => {
    if (field.value && (!selected || !selected.length)) {
      setSelected(fieldValueAsArray);
    }

    setIsInitialAmount(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (selectedList.value && !isEqual(addonAsOptions(selectedList.value), selectedOptions)) {
      setSelected(addonAsOptions(selectedList.value));
    }

    setIsInitialAmount(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList.value]);

  useEffect(() => {
    setSelectedOptions(selected);
    setFilterTagValues(selected ?? []);

    if (!isInitialAmount && !isEqual(optionsAsAddon(selected), selectedList.value)) {
      setSelectedList.setValue(optionsAsAddon(selected));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (field.requiredFor) {
      const requiredList: any[] = Array.isArray(field.requiredFor.value)
        ? [...field.requiredFor.value]
        : [field.requiredFor.value];
      let isRequiredValue = false;

      if (fieldRequired.value && fieldRequired.value.label !== undefined) {
        isRequiredValue = requiredList.some(item => isEqual(item, fieldRequired.value.label));
      } else if (fieldRequired.value !== undefined) {
        isRequiredValue = requiredList.some(item => isEqual(item, fieldRequired.value));
      }

      if (!!fieldRequired.value?.toString() && isRequiredValue && isFieldVisible === false) {
        setIsFieldVisible(true);
      } else if (!!fieldRequired.value?.toString() && !isRequiredValue && isFieldVisible === true) {
        setIsFieldVisible(false);

        // reset to null if required is not met
        setSelectedList.setValue({});
        setSelectedOptions([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRequired, field]);

  const handleCancel = () => {
    setIsDropdownVisible(false);
    setSelectedOptions(selected);
    setApplyDisabled(true);
  };

  const handleApply = () => {
    setIsDropdownVisible(false);
    setSelected(selectedOptions);
    setApplyDisabled(true);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const changeHandler = (options: SelectOption[]) => {
    const mappedOptions =
      field.id === 'addon'
        ? options.map(option => ({ ...option, label: option.label.split(' [')[0] }))
        : options;

    setSelectedOptions(mappedOptions);
    setApplyDisabled(false);
  };

  const menu = (
    <Styled.MenuWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div>
        <MultiSelectAsyncField
          label={field.label}
          endpoint={field.endpoint ?? ''}
          selectedOptions={selectedOptions}
          changeHandler={changeHandler}
        />
        <Row justify="end" className="menu-buttons-wrapper">
          <Button style={{ marginRight: 8 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleApply} disabled={applyDisabled}>
            Apply
          </Button>
        </Row>
      </div>
    </Styled.MenuWrapper>
  );

  return (
    <>
      {isFieldVisible && (
        <Styled.SelectWrapper span={8} className={field.id}>
          <Typography.Text>{field.label}</Typography.Text>
          <Styled.DropdownWrapper
            visible={isDropdownVisible}
            onVisibleChange={flag => {
              !flag ? handleCancel() : setIsDropdownVisible(flag);
            }}
            overlay={menu}
            trigger={['click']}
            placement="topRight"
          >
            <Button>
              <Styled.TriggerWrapper>
                <Overflow
                  data={
                    filterTagValues &&
                    filterTagValues.map(item => ({
                      label: item.label,
                      value: item.value.toString(),
                      uniqueKey: item.value.toString(),
                    }))
                  }
                  renderItem={(item: SelectOption) => (
                    <div className="option-label">
                      {item.label}
                      <span className="comma">,</span>
                    </div>
                  )}
                  renderRest={items => <div className="more-label">+ {items.length} more</div>}
                  maxCount="responsive"
                  style={{ justifyContent: 'flex-start' }}
                />
              </Styled.TriggerWrapper>
              <DownOutlined />
            </Button>
          </Styled.DropdownWrapper>
        </Styled.SelectWrapper>
      )}
    </>
  );
};

export default React.memo(MultiSelectAsyncRequiredFieldWrapper);
