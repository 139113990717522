import { useEffect, RefObject } from 'react';
import { findDOMNode } from 'react-dom';

/**
 * Custom hook for auto-focusing an input element within a specified class.
 *
 * @param {React.RefObject<T>} ref - A ref object that can be used to access the component.
 * @param {boolean} autoFocus - A boolean indicating whether the input should be auto-focused.
 * @param {string} className - The class name of the input container.
 */

const useAutoFocusSelect = (ref: RefObject<any>, autoFocus: boolean, className: string) => {
  useEffect(() => {
    if (ref.current && autoFocus) {
      const element = findDOMNode(ref.current) as HTMLElement;

      if (element) {
        setTimeout(() => {
          const inputContainer = element.getElementsByClassName(className)[0] as HTMLElement;

          if (inputContainer) {
            const inputElement = inputContainer.querySelector('input') as HTMLElement;

            if (inputElement) {
              inputElement.focus();
            } else {
              inputContainer.focus();
            }
          }
        }, 100);
      }
    }
  }, [autoFocus, ref, className]);
};

export default useAutoFocusSelect;
