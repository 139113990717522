import React from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// models
import Company from '@optx/models/Company';
import { Cell } from '@models/table/Cell';
// hooks
import { useActiveEditableField } from '@components/feature/company-individual-edit/hooks';
// redux
import { actions } from '@features/pipeline-report/state';

const TableCellActions: React.FC<Cell<Company>> = ({ record }) => {
  const dispatch = useDispatch();

  const { addCompanyActive } = useActiveEditableField(false);

  const handleMarkCompanyAsInactive = () => {
    addCompanyActive(record.company_id, false);
    // we should do it to update all value on report
    dispatch(actions.fetchFilters());
  };

  const menu = (
    <Menu>
      <Menu.Item key="mark-company-as-active">
        <button type="button" onClick={handleMarkCompanyAsInactive}>
          Mark company as inactive
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="dropdown-dot">
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <EllipsisOutlined className="dropdown-dot__icon" />
      </Dropdown>
    </div>
  );
};

export default TableCellActions;
