import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';
// models
import { GridKeys, GridKeysMain } from '@optx/models/grid';
// utils
import { getLocationMainGridKey } from '@optx/utils/grid';
// redux
import { actions as modalActions } from '@redux/ui/modals/gridModals';
import { selectors as searchSelectors } from '@features/grid/search';

interface ShowFilterButtonProps {
  gridKey: GridKeys;
  placement?: TooltipPlacement;
  style?: React.CSSProperties;
  actionPendo?: string;
}

const ShowFilterButton: React.FC<ShowFilterButtonProps> = ({
  gridKey,
  placement,
  style,
  actionPendo,
}) => {
  const dispatch = useDispatch();

  const listType = useSelector(
    searchSelectors.getListType(getLocationMainGridKey() as GridKeysMain)
  );
  const isFilterDisabled = listType === 'combined';
  const tooltipTitle = isFilterDisabled ? 'Not supported for combined searches' : 'Filter';

  const toggleModal = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.blur();

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    dispatch(modalActions.toggleModal({ gridKey }));
  };

  return (
    <Tooltip title={tooltipTitle} placement={placement}>
      <Button
        style={style}
        type="text"
        onClick={toggleModal}
        icon={<FilterOutlined />}
        disabled={isFilterDisabled}
      />
    </Tooltip>
  );
};

export default React.memo(ShowFilterButton);
