import React, { useRef, useEffect, useState } from 'react';
import numeral from 'numeral';
import ReactDOM from 'react-dom';
import { Dictionary } from 'lodash';
import { Form } from 'formik-antd';
import { Formik, FormikValues, FormikProps } from 'formik';
import { Row, Col, Button, Typography, Space } from 'antd';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
// models
import { FilterSource, PreselectedFilter } from '@optx/models/filters';
// redux
import { selectors as searchCountSelectors } from '@features/grid/search-count';
import { selectors as contactsSearchSelectors } from '@redux/contacts/search/search';
// components
import SearchInput from '@optx/components/common/form/input/SearchInput';
import SearchFilterGroup from '@optx/components/feature/company-filters/Filter/SearchFilterGroup';

const { Title } = Typography;

interface FilterFormProps {
  filterSources: Array<FilterSource>;
  // clear
  onClearFilter: (shouldSort: boolean) => void;
  //   search
  keySearch: string;
  onSearch: (key: string, filter: Dictionary<PreselectedFilter>) => void;
  validationSchema?: any;
  onClose?: () => void;
}

const FilterForm: React.FC<FilterFormProps> = ({
  filterSources,
  onClearFilter,
  keySearch,
  onSearch,
  validationSchema,
  onClose,
}) => {
  // form
  const formRef = useRef<FormikProps<FormikValues> | null>(null);

  const filter = useSelector(contactsSearchSelectors.getFilter);

  // formik submit handler
  const handleSubmit = () => {
    handleSearch(inputSearch);
  };

  // manually trigger submit
  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  // search
  const handleSearch = (searchKey: string) => {
    if (formRef.current) {
      // Make any parent state updates with batched state updates.
      // This happens in an async callback or any event from WebAPIs https://developer.mozilla.org/en-US/docs/Web/Events
      // More info: https://github.com/facebook/react/issues/14259
      ReactDOM.unstable_batchedUpdates(() => onSearch(searchKey, formRef.current!.values));
    }
  };

  const [inputSearch, setInputSearch] = useState('');

  useEffect(() => {
    setInputSearch(keySearch);
  }, [keySearch]);

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const handleResetSearch = (e: React.MouseEvent<HTMLElement, MouseEvent>, shouldSort: boolean) => {
    e.currentTarget.blur();

    // use same function to reset and clear filters and sorting
    // send a boolean to check if only reseting
    // filters or also resetting sorting
    setInputSearch('');

    if (formRef.current) {
      formRef.current.resetForm();
    }

    onClearFilter(shouldSort);
  };

  const total = useSelector(searchCountSelectors.getSearchCount('contacts'));

  const handleClose = () => {
    onClose && onClose();
    formRef.current && formRef.current.resetForm(filter);
  };

  return (
    <div className="filter__wrapper">
      <div className="filter-submit-wrapper">
        <div className="filter-submit__search-input">
          <SearchInput
            onSearch={handleSearch}
            value={inputSearch}
            onChange={handleKeyChange}
            placeholder="Search for contacts by keyword"
          />
        </div>

        <Row style={{ padding: '20px 40px' }}>
          <Col xs={24} md={6} lg={12}>
            <Space align="center" style={{ width: '100%', flexWrap: 'wrap' }}>
              <Title level={2} style={{ fontWeight: 'normal', margin: 0 }}>
                Filters
              </Title>
              <Title level={4} type="secondary" style={{ fontWeight: 'normal', margin: 0 }}>
                {`(Showing ${numeral(total).format('0,0')} ${
                  total !== 1 ? 'Contacts' : 'Contact'
                })`}
              </Title>
            </Space>
          </Col>
          <Col xs={24} md={18} lg={12}>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }} align="center">
              <Button type="default" size="large" onClick={e => handleResetSearch(e, true)}>
                Deselect All
              </Button>
              <Button type="default" size="large" onClick={e => handleResetSearch(e, false)}>
                Restore Defaults
              </Button>
              <Button type="primary" size="large" onClick={triggerSubmit}>
                Apply Filters
              </Button>
              <Button
                type="link"
                icon={<CloseOutlined />}
                size="large"
                onClick={handleClose}
                style={{ color: '#000' }}
              />
            </Space>
          </Col>
        </Row>
      </div>
      <Formik
        initialValues={filter}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        innerRef={instance => {
          formRef.current = instance;
        }}
        component={props => (
          <Form layout="vertical" name="filtersForm">
            <Row gutter={16} style={{ padding: '40px 50px' }}>
              {filterSources.map(filterSource => (
                <SearchFilterGroup key={filterSource.id} source={filterSource} />
              ))}
            </Row>
          </Form>
        )}
      />
    </div>
  );
};

export default FilterForm;
