import { takeLatest, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
// models
import { AxiosResponse } from 'axios';
import { SelectOption } from '@optx/models/Option';
import { CompanyBulkEditingResults, BulkEditFieldResponse } from '@optx/models/bulkActions';
import { Addon } from '@optx/models/Company';
// constants
import {
  BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET,
  BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST,
  BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST,
  BULK_EDIT_COMPANY_LIMIT_ADD_TAGS,
  BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY,
} from '@optx/config/bulkConfig';
// services
import { CompanyService } from '@optx/services/api';
import NotificationService from '@optx/services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
// redux
import { actions as customGlobalLoaderActions } from '@features/custom-global-loader';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import * as actions from './actions';
import { BulkEditFields } from './interfaces';
import * as selectedCompaniesActions from '../../selected-companies/state/actions';

function* bulkEditFieldSaga(action: PayloadAction<BulkEditFields>) {
  const { selectedCompanies, selectedData, field, rationale } = action.payload;
  const companies = [...selectedCompanies];
  let fieldValue: string | string[] =
    typeof selectedData === 'string' ? selectedData : selectedData[0].value;
  const extraOptions: Dictionary<string> = {};
  let dataField = field;
  let BULK_EDIT_COMPANY_LIMIT = BULK_EDIT_COMPANY_LIMIT_SYNCED_TO_ET;

  switch (dataField) {
    case 'watchlist':
      BULK_EDIT_COMPANY_LIMIT = BULK_EDIT_COMPANY_LIMIT_ADD_TO_WATCHLIST;
      break;
    case 'remove_from_watchlist':
      BULK_EDIT_COMPANY_LIMIT = BULK_EDIT_COMPANY_LIMIT_REMOVE_FROM_WATCHLIST;
      break;
    case 'cs_tags':
      BULK_EDIT_COMPANY_LIMIT = BULK_EDIT_COMPANY_LIMIT_ADD_TAGS;
      break;
    case 'is_software':
      BULK_EDIT_COMPANY_LIMIT = BULK_EDIT_COMPANY_LIMIT_SET_AS_SOFTWARE_COMPANY;
      break;
    case 'stage':
      extraOptions.stage_rationale = rationale as string;
      break;
    default:
      break;
  }

  const selectedCompaniesCount: number = yield select(
    bulkActionsSelectors.editFields.selectedCompaniesCount
  );

  // special case for company owner, need to send
  // equity touch related poperty names
  if (dataField === 'company_owner_id') {
    dataField = 'deal_team_leader';
    extraOptions.deal_team_leader_name = (selectedData[0] as SelectOption).label;
  }

  // for pipeline_rank column, we need to send rank as parameter
  if (dataField === 'pipeline_rank') {
    dataField = 'rank';
  }

  // for addon, we need to send the actual name and the company addon id
  let addonId;

  if (dataField === 'addon') {
    fieldValue = (selectedData as SelectOption[]).map(data => data.label);
    addonId = (selectedData[0] as SelectOption).id;
  }

  if (dataField === 'product_category') {
    fieldValue = (selectedData as SelectOption[]).map(data => data.label).join(',');
  }

  if (dataField === 'cs_tags') {
    const arrayOfTags: string[] = [];

    (selectedData as SelectOption[]).forEach(company => {
      arrayOfTags.push(company.value);
    });

    fieldValue = arrayOfTags.toString();
  }

  const companiesBatch = companies.slice(0, BULK_EDIT_COMPANY_LIMIT);

  try {
    const res: AxiosResponse<Dictionary<BulkEditFieldResponse>> = yield call(
      CompanyService.bulkEdit,
      companiesBatch.map(company => company.company_id),
      dataField,
      fieldValue,
      extraOptions
    );

    if (res.data) {
      const failedCompanies = res.data;
      const currentResults: CompanyBulkEditingResults[] = yield select(
        bulkActionsSelectors.editFields.results
      );
      const nextResults: CompanyBulkEditingResults[] = [];

      yield put(
        actions.updatePercentage(
          Math.round((BULK_EDIT_COMPANY_LIMIT / selectedCompaniesCount) * 100)
        )
      );

      companiesBatch.forEach(company => {
        const { company_id: id, company_url: url, company_name: name, raw_url: rawUrl } = company;
        let errorMessage = '';
        let scoreInfo = null;

        if (Object.keys(failedCompanies).includes(id.toString())) {
          errorMessage = !failedCompanies[id].status
            ? failedCompanies[id].reason_to_failed ?? ''
            : '';

          if (field === 'company_owner_id') {
            scoreInfo = {
              score: failedCompanies[id].optx_score,
              score_growth: failedCompanies[id].optx_score_growth,
              optx_score_trends: failedCompanies[id].optx_score_trends,
              score_v3: failedCompanies[id].score_v3,
              score_v3_growth: failedCompanies[id].score_v3_growth,
              optx_score_v3_trends: failedCompanies[id].optx_score_v3_trends,
              il_optx_score: failedCompanies[id].il_optx_score,
              il_score_growth: failedCompanies[id].il_optx_score_growth,
              il_optx_score_trends: failedCompanies[id].il_optx_score_trends,
            };
          }
        }

        nextResults.push({
          company_id: id,
          company_url: url,
          raw_url: rawUrl,
          company_name: name,
          sync_status: !errorMessage,
          error_reason: errorMessage,
          ...(scoreInfo && scoreInfo),
        });
      });

      const finalResults = [...currentResults, ...nextResults];

      yield put(actions.saveResults(finalResults));

      companies.splice(0, BULK_EDIT_COMPANY_LIMIT);
      const cancelSearch: boolean = yield select(bulkActionsSelectors.editFields.cancel);

      if (!cancelSearch) {
        if (companies.length) {
          yield put(
            actions.bulkEditField({ selectedCompanies: companies, selectedData, field, rationale })
          );
        } else {
          yield put(actions.updateProgress(false));
          yield put(actions.updateCompleted(true));
          yield put(actions.updatePercentage(100));

          const successfulResults = finalResults.filter(company => company.sync_status);

          if (successfulResults) {
            yield put(
              actions.bulkEditFieldSuccess({
                companyIds: successfulResults.map(company => company.company_id),
                selectedData,
                field,
                ...(field === 'company_owner_id' && { additionalInfo: successfulResults }),
                ...(field === 'stage' ? { rationale: extraOptions?.stage_rationale } : {}),
                ...(field === 'addon' && { additionalAddonId: addonId }),
              })
            );

            if (field !== 'cs_tags') {
              let formattedValue: string | string[] | Addon | null = (
                selectedData[0] as SelectOption
              ).label;

              if (field === 'product_category') {
                formattedValue = (selectedData as SelectOption[]).map(data => data.label);
              }

              if (field === 'addon') {
                const hasBlankOption = (selectedData as SelectOption[]).some(
                  option => option.value === 'None'
                );

                let addons: Addon | null = null;

                if (!hasBlankOption) {
                  addons = (selectedData as SelectOption[]).reduce((acc, curr) => {
                    return { ...acc, [curr.value]: curr.id };
                  }, {});
                }

                formattedValue = addons;
              }

              yield put(
                selectedCompaniesActions.bulkUpdateDataSuccess({
                  updatedCompaniesIds: successfulResults.map(company => company.company_id),
                  changedValue: formattedValue,
                  editFieldKey: field === 'company_owner_id' ? 'company_owner' : field,
                  ...(field === 'stage' ? { extraOptions } : {}),
                })
              );
            }
          }
        }
      } else {
        const canceledCompanies: CompanyBulkEditingResults[] = [];
        const prevResults: CompanyBulkEditingResults[] = yield select(
          bulkActionsSelectors.editFields.results
        );

        if (companies.length) {
          companies.forEach(company => {
            const {
              company_id: id,
              company_url: url,
              company_name: name,
              raw_url: rawUrl,
            } = company;

            canceledCompanies.push({
              company_id: id,
              company_url: url,
              raw_url: rawUrl,
              company_name: name,
              sync_status: false,
              error_reason: 'Canceled by user',
            });
          });
        }

        yield put(actions.saveResults([...prevResults, ...canceledCompanies]));
        yield put(actions.updateProgress(false));
        yield put(actions.updateCompleted(true));
        yield put(actions.cancel(false));
        yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
      }
    }
  } catch (e: any) {
    const message = getErrorMessage(e, 'Failed to edit fields!');
    yield put(actions.updateProgress(false));
    yield put(actions.updateCompleted(true));
    yield put(actions.cancel(false));
    yield put(customGlobalLoaderActions.toggle({ loading: false, customText: '' }));
    NotificationService.error(message);
  }
}

export default function* bulkEditSaga() {
  yield takeLatest(actions.bulkEditField, bulkEditFieldSaga);
}
