import { call, put, select, takeLeading } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
//models
import { SearchPayloadRequest } from '@optx/models/api/contacts';
import { GridPayload } from '@optx/models/grid';
import { CustomUIViewIds } from '@optx/models/user';
//constants
import { customUIViewIds } from '@optx/constants/table/columnDisplay/company-search';
//services
import { CompanyService, SearchService } from '@services/api';
import NotificationService from '@optx/services/NotificationService';
import { AnalystService } from '@optx/services/api/AnalystService';
//utils
import { preProcessSearch } from '@optx/utils/search';
import { getErrorMessage } from '@optx/utils/api/errors';
//redux
import { selectors as userInformationSelectors } from '@redux/user/information';
import * as actions from './actions';

export function* searchCountSaga(
  action: PayloadAction<
    GridPayload<SearchPayloadRequest | string> & {
      companyId?: number;
    }
  >
) {
  const { data, gridKey, companyId } = action.payload;

  switch (gridKey) {
    case 'advancedSearch':
    case 'sslists':
    case 'watchlists':
      if (typeof data !== 'string') {
        try {
          const searchTitle: string = yield select(
            userInformationSelectors.getAdvancedSearchSearchTitle
          );
          const filter = { ...data.filter, search_title: data.searchTitle || searchTitle || '' };

          const res: AxiosResponse<number> = yield call(
            SearchService.searchCompanyCount,
            preProcessSearch(data.searchKey),
            filter,
            customUIViewIds.includes(data.fromSavedList as CustomUIViewIds)
              ? undefined
              : data.fromSavedList,
            data.listType
          );
          yield put(
            actions.searchCountSuccess({
              gridName: gridKey,
              searchCount: res.data,
            })
          );
        } catch (error: any) {
          const errorMessage = getErrorMessage(error, 'Get count companies fail!');

          NotificationService.error(errorMessage);
          yield put(actions.searchCountFailure(gridKey));
        }
      }

      break;
    case 'myCompanies':
      if (typeof data !== 'string') {
        try {
          const res: AxiosResponse<number> = yield call(
            SearchService.searchCompanyCount,
            data.searchKey,
            data.filter,
            data.fromSavedList,
            data.listType
          );
          yield put(actions.searchCountSuccess({ gridName: gridKey, searchCount: res.data }));
        } catch (error: any) {
          const errorMessage = getErrorMessage(error, 'Get count companies fail!');

          NotificationService.error(errorMessage);
          yield put(actions.searchCountFailure(gridKey));
        }
      }

      break;
    case 'outreach':
      try {
        const res: AxiosResponse<number> = yield call(
          AnalystService.getCompanieListOutReachCount,
          data
        );
        yield put(
          actions.searchCountSuccess({
            gridName: gridKey,
            searchCount: res.data,
          })
        );
      } catch (error: any) {
        const errorMessage = getErrorMessage(error, 'Get count companies fail!');

        NotificationService.error(errorMessage);
        yield put(actions.searchCountFailure(gridKey));
      }

      break;
    case 'contacts':
      if (typeof data !== 'string') {
        try {
          const res: AxiosResponse<number> = yield call(
            SearchService.searchContactsCounter,
            preProcessSearch(data.searchKey),
            data.filter
          );

          yield put(actions.searchCountSuccess({ gridName: 'contacts', searchCount: res.data }));
        } catch (error: any) {
          const errorMessage = getErrorMessage(error, 'Search contacts count fail, Server error!');
          NotificationService.error(errorMessage);
          yield put(actions.searchCountFailure(gridKey));
        }
      }

      break;
    case 'addons':
      if (typeof data !== 'string') {
        try {
          const res: AxiosResponse<number> = yield call(
            CompanyService.fetchCompanyAddonManagementCount,
            {
              companyId: (data.companyId as number) || (companyId as number),
              pagination: data.pagination,
              sortBy: data.sortBy,
              filter: data.filter,
              searchKey: data.searchKey,
            }
          );

          yield put(actions.searchCountSuccess({ gridName: 'addons', searchCount: res.data }));
        } catch (error: any) {
          const errorMessage = getErrorMessage(error, 'Get count companies fail!');

          NotificationService.error(errorMessage);
          yield put(actions.searchCountFailure(gridKey));
        }
      }

      break;

    case 'checklistResearchInsights':
      if (typeof data === 'string') {
        try {
          const res: AxiosResponse<number> = yield call(
            AnalystService.getCompanyCountChecklistInsight,
            data
          );
          yield put(
            actions.searchCountSuccess({
              gridName: 'checklistResearchInsights',
              searchCount: res.data,
            })
          );
        } catch (error: any) {
          const errorMessage = getErrorMessage(
            error,
            'Failed to fetch Checklist Research Insights!'
          );
          NotificationService.error(errorMessage);
          yield put(actions.searchCountFailure(gridKey));
        }
      }

      break;

    default:
      break;
  }
}

export default function* fetchSearchCount() {
  yield takeLeading(actions.searchCount, searchCountSaga);
}
