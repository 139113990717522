import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';

// services
import { AnalystService } from '@services/api';
import NotificationService from '@services/NotificationService';
// utils
import { getErrorMessage } from '@utils/api/errors';
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import { mapSortQuery } from '@optx/utils/search';
// redux
import { actions as userInformationActions } from '@redux/user/information';
import { actions } from '../actions';
import { selectors } from '../selectors';
import { SortByRule } from '@optx/models/table/sorting';

export function* search() {
  const { normalized, filter } = yield select(selectors.filters.getFilters);
  const sortBy: SortByRule<any>[] = yield select(selectors.sort.sortBy);
  const query: string = yield select(selectors.filters.searchKey);
  const normalizedFilter = {
    ...filter,
    ...(filter.analyst_id && {
      analyst_id: filter.analyst_id.length === 0 ? [''] : filter.analyst_id,
    }),
  };
  const convertSortBy = mapSortQuery(sortBy);

  const queryData = {
    ...convertSortBy,
    ...mapFiltersToURLParams(normalized, normalizedFilter),
    outreach_query: query || '',
  };

  const payload = queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });

  const customQueryData = {
    ...queryData,
  };

  delete customQueryData.analyst_id;
  delete customQueryData.sort_fields;
  delete customQueryData.sort_orders;

  const customPayload = queryString.stringify(customQueryData, {
    arrayFormat: 'comma',
  });

  yield put(
    userInformationActions.updateUserSettings({ sourcing_outreach_filters: customPayload })
  );

  try {
    const res: AxiosResponse = yield call(AnalystService.getSourcingOutreach, payload);
    yield put(actions.search.fetchSourcingOutreachSuccess(res.data));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Failed to fetch sourcing outreach summary data!');

    yield put(actions.search.fetchSourcingOutreachFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* handleFilterActions() {
  yield put(actions.search.fetchSourcingOutreach());
}

function* changeSortingSaga() {
  yield put(actions.search.fetchSourcingOutreach());
  const sortBy: SortByRule<any>[] = yield select(selectors.sort.sortBy);

  const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
    arrayFormat: 'comma',
  });

  yield put(
    userInformationActions.updateUserSettings({
      sourcing_outreach_sorting: sortQuery,
    })
  );
}

export default function* sourcingOutreachSaga() {
  yield takeEvery(actions.search.fetchSourcingOutreach, search);
  yield takeLatest(actions.filters.clearFilters, handleFilterActions);
  yield takeLatest(actions.filters.resetToDefault, handleFilterActions);
  yield takeLatest(actions.filters.applyFilters, handleFilterActions);
  yield takeLatest(actions.filters.updateFilters, handleFilterActions);
  yield takeLatest(actions.filters.resetFilter, handleFilterActions);
  yield takeLatest(actions.sort.sort, changeSortingSaga);
}
