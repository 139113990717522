import styled from 'styled-components';
import { useSelector } from 'react-redux';
// redux
import * as selectors from '../state/selectors';
// components
import Icon from '@optx/components/common/Icon';

const StyledStatusWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .fade-out {
    animation: fadeOut 1.2s ease-out forwards;
    animation-delay: 0.8s;
  }

  .fade-in {
    animation: fadeIn 1.2s;
  }

  .fade-in-fade-out {
    animation: fadeIn 1.2s forwards, fadeOut 0.5s 1.2s forwards;
  }
`;

const AutoSaveStatus = () => {
  const autosaveStatus = useSelector(selectors.getAutosaveStatus);

  const iconStyle: React.CSSProperties = { fontSize: 16 };
  const textStyle: React.CSSProperties = { fontWeight: 600, color: '#595959' };

  if (autosaveStatus === 'none') {
    return null;
  }

  return (
    <StyledStatusWrapper>
      {autosaveStatus === 'saved' && (
        <>
          <Icon className="fade-in" iconName="autosave-saved" style={iconStyle} />
          <span className="fade-in-fade-out" style={textStyle}>
            Saved
          </span>
        </>
      )}
      {autosaveStatus === 'saving' && (
        <>
          <Icon className="fade-in" iconName="autosave-saving" style={iconStyle} />
          <span className="fade-in" style={textStyle}>
            Saving...
          </span>
        </>
      )}
    </StyledStatusWrapper>
  );
};

export default AutoSaveStatus;
