import React, { useCallback, useEffect, useState } from 'react';
import { OptionsType, ValueType } from 'react-select';
import { useField } from 'formik';
import { debounce } from 'lodash';
// models
import { SuccessErrorCallback } from '@models/callback';
import { SelectOption } from '@models/Option';
import { EquityTouchFetchedField } from '@models/equityTouch';
import { ProductCategory } from '@optx/models/Company';
// constants
import { COMPANY_PRODUCT_CATEGORY_ENDPOINT } from '@constants/asyncEndpoints';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// hooks
import useAsyncSearch from '@hooks/select/useAsyncSearch';
// styled components
import Styles from './EquityTouchAddon.style';
// components
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';

interface EquityTouchProductCategoryProps {
  name: string;
  initialValue: EquityTouchFetchedField;
}

const EquityTouchProductCategory: React.FC<EquityTouchProductCategoryProps> = ({
  name,
  initialValue,
}) => {
  const [, , helpers] = useField(name);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectOption>>([]);

  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_PRODUCT_CATEGORY_ENDPOINT });

  const handleChange = (value: ValueType<SelectOption>) => {
    setSelectedOptions(value as SelectOption[]);

    helpers.setValue(
      (value as SelectOption[]).map(value => ({
        category: value.value,
        is_valid: value.isValid === false ? value.isValid : true,
      }))
    );
  };

  const handleDefaultValues = useCallback(() => {
    setSelectedOptions(
      (initialValue.from_database as ProductCategory[]).map(item => ({
        value: item?.category,
        label: item?.category,
        isValid: item?.is_valid,
      }))
    );
  }, [initialValue.from_database]);

  useEffect(() => {
    handleDefaultValues();
  }, [handleDefaultValues]);

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  return (
    <Styles.AsyncWrapper>
      <AsyncMultiSelect
        className="addon--multiselect-async"
        cacheOptions
        loadOptions={getLoadOptionsDelay}
        defaultOptions={fetchedOptions}
        defaultValue={selectedOptions}
        onChange={handleChange}
        maxLength={500}
        loadingMessage={({ inputValue }) => (inputValue.length > 2 ? 'Loading...' : 'Begin typing')}
        onSelectResetsInput={false}
        blurInputOnSelect={false}
        closeOnSelect
      />
    </Styles.AsyncWrapper>
  );
};

export default EquityTouchProductCategory;
