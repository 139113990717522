import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { Dictionary } from 'lodash';
// models
import { GridKeysMain, GridPayload } from '@optx/models/grid';
import { SearchPayloadRequest } from '@models/api/contacts';
import { ViewOption } from '@models/search';
import { PreselectedFilter } from '@models/filters';
import { UpdateUserSettingsPayload } from '@models/api/user';
import { PageInfo } from '@models/table/Pagination';
import { SortByRule } from '@models/table/sorting';
// utils
import { mapSortQuery, hasFilters, preProcessSearch } from '@utils/search';
// redux
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { selectors as searchSelectors } from '@redux/company/search/search/';
import { actions as searchActions } from '@features/grid/search';
import { actions as savedSearchesActions } from '@redux/company/saved-searches';
import { actions as columnActions } from '@redux/company/search/columns';
import { searchCompanies, searchCompaniesFail } from '@features/grid/search/state/sagasReusable';
import { getFiltersQuery } from '@redux/company/search/search/sagasReusable';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import { selectors as filterSelectors } from '@optx/features/grid/filter';

export function* applyViewSaga(action: PayloadAction<GridPayload<ViewOption>>) {
  const {
    data: {
      columns,
      column_order: columnOrder,
      pinned_columns: pinnedColumns,
      filters: viewFilters,
      searchKey: viewSearchKey,
      sortBy,
      search_criteria: searchCriteria,
      unique_id: id,
      title,
      search_info: searchInfo,
    },
    gridKey,
  } = action.payload;

  console.log('advancedSearchApplyViewSaga action: ', action);
  console.log('advancedSearchApplyViewSaga gridKey function return: ', gridKey);

  if (!gridKey) return;

  const pagination: PageInfo = yield select(paginationSelectors.getPagination(gridKey));
  const userPageNumber: number = yield select(userInformationSelectors.getResultsPageNumber);
  const isCombinedSearch = searchInfo?.type === 'combined';
  let filter: Dictionary<PreselectedFilter> | undefined;
  let searchKey: string = yield select(searchSelectors.getSearchKey);
  let isSimilarCompaniesSavedSearch = false;

  if (hasFilters(searchCriteria) || id === 'default') {
    const parsedFilters = queryString.parse(searchCriteria, { arrayFormat: 'comma' });

    if (parsedFilters.company_id) {
      isSimilarCompaniesSavedSearch = true;
      const viewFiltersCustom = {
        ...viewFilters,
        similarCompanies: {
          label: '',
          value: parsedFilters.company_id.toString(),
        },
      };
      filter = viewFiltersCustom;
      searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
    } else {
      filter = viewFilters;
      searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
    }
  }

  if (!hasFilters(searchCriteria) && viewSearchKey) {
    filter = yield select(filterSelectors.getFilter('advancedSearch'));
    searchKey = viewSearchKey ? preProcessSearch(viewSearchKey) : '';
  }

  yield put(columnActions.updateColumns(columns, columnOrder, pinnedColumns));

  if (isCombinedSearch || filter || sortBy) {
    const currentSortBy: SortByRule<any>[] = yield select(searchSelectors.getSorting);
    const currentFilter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getFilter('advancedSearch')
    );
    const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getCustomClearedFilter(gridKey)
    );

    let filterQuery: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      isCombinedSearch ? customClearedFilter : filter || currentFilter
    );

    // before we separated watchlist from advanced search page it is possible that
    // some saved searches include the list id
    // if we have old saved searches that include list id, remove it.
    // when applying a view we should not include the list id
    // @ts-ignore
    filterQuery = { ...filterQuery, ss_list_id: undefined, saved_list_id: undefined };

    const searchData: SearchPayloadRequest = {
      searchKey,
      fromSavedList: typeof id === 'number' ? id : undefined,
      listType: searchInfo?.type || '',
      filter: filterQuery,
      pagination: {
        ...pagination,
        pageNumber: 1,
      },
      sortBy: sortBy || currentSortBy,
    };

    const sessionSettings: Partial<UpdateUserSettingsPayload> = {};

    if (sortBy) {
      const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
        arrayFormat: 'comma',
      });
      sessionSettings.company_sorting = sortQuery;
    }

    if (columns !== null) {
      sessionSettings.company_display_columns = columns;

      if (columnOrder) {
        sessionSettings.company_column_order = columnOrder;
      }
    }

    if (pinnedColumns !== null) {
      sessionSettings.company_pinned_columns = pinnedColumns;
    }

    const pageNumberChanged = searchData.pagination.pageNumber !== userPageNumber;

    if (pageNumberChanged) {
      sessionSettings.search_results_page_number = searchData.pagination.pageNumber;
    }

    const updateSession =
      filter && !sortBy && !columns && !pinnedColumns && !pageNumberChanged
        ? true
        : sessionSettings;

    try {
      yield call(
        searchCompanies,
        searchData,
        gridKey as GridKeysMain,
        filter || currentFilter,
        undefined,
        title,
        updateSession,
        undefined,
        isSimilarCompaniesSavedSearch
      );

      if (sortBy) {
        if (sortBy.length > 1) {
          yield put(
            searchActions.resetSearchSuccess({ gridKey: gridKey as GridKeysMain, data: true })
          );
        } else {
          yield put(
            searchActions.resetSearchSuccess({ gridKey: gridKey as GridKeysMain, data: false })
          );
        }
      }

      if ((hasFilters(searchCriteria) || isCombinedSearch) && id !== 'default') {
        yield put(searchActions.saveSearchTitle({ gridKey: gridKey as GridKeysMain, data: title }));
      } else {
        yield put(searchActions.saveSearchTitle({ gridKey: gridKey as GridKeysMain, data: '' }));
      }

      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: 'advancedSearch',
          data: searchData.pagination,
        })
      );
    } catch (error: any) {
      yield call(searchCompaniesFail, gridKey as GridKeysMain, error);
    }
  } else {
    yield put(
      userInformationActions.updateUserSettings({
        company_display_columns: columns as string,
        company_column_order: columnOrder as string,
        company_pinned_columns: pinnedColumns as string,
      })
    );
    yield put(
      savedSearchesActions.applyViewSuccess({ id, label: title, pageAlias: 'advancedSearch' })
    );
    yield put(searchActions.saveSearchTitle({ gridKey: gridKey as GridKeysMain, data: '' }));
  }
}
