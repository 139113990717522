import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Dictionary } from 'lodash';
import { Input, Button, Table } from 'antd';
import { useSelector } from 'react-redux';
// models
import { CompanyWatchList } from '@optx/models/WatchList';
// redux
import { selectors as watchListsSelectors } from '@redux/favorite-lists/lists-popup';
import { useIsChromePlugin } from '@optx/common/hooks/useIsChromePlugin';
// components
import SearchInput from '@optx/components/common/form/input/SearchInput';
import columns from './WatchListOptionsColumn';

interface MenuListProps {
  options: Dictionary<CompanyWatchList>;
  selectedOptions: Dictionary<boolean>;
  disabledOptions?: Dictionary<boolean>;
  showCreateList?: VoidFunction;
  onChange: (
    listId?: string[],
    item?: CompanyWatchList,
    isEmpty?: boolean,
    companyActiveWatchlist?: Dictionary<boolean>
  ) => void;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  isOpen: boolean;
  handleDropdownKeyEvent: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const MenuList: React.FC<MenuListProps> = ({
  options,
  selectedOptions,
  disabledOptions = {},
  onChange,
  showCreateList,
  searchValue,
  setSearchValue,
  isOpen,
  handleDropdownKeyEvent,
}) => {
  const companyWatchListsIds = useSelector(watchListsSelectors.getAllListIds);
  const selectedValues = { ...selectedOptions, ...disabledOptions };

  const newDataList: CompanyWatchList[] = [];
  companyWatchListsIds.forEach(itemId => {
    // @ts-ignore
    newDataList.push({ ...options[itemId], id: options[itemId]?.unique_id.toString() });
  });
  const data = newDataList.filter(item => item.title !== 'Portfolio Platform Companies');

  const { isChromePlugin } = useIsChromePlugin();

  const inputRef = useRef<Input | null>(null);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
    Object.keys(selectedValues)
  );
  const [filteredData, setFilteredData] = React.useState<CompanyWatchList[]>(data);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setFilteredData(
      data.filter(item => item.title.toLowerCase().includes(event.target.value.toLowerCase()))
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRow: CompanyWatchList[]) => {
    if (selectedRow.length === 0) onChange(undefined, undefined, true);
    setSelectedRowKeys(newSelectedRowKeys);
    selectedRow.forEach(item => {
      onChange(newSelectedRowKeys as string[], item, false, disabledOptions);
    });
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
    getCheckboxProps: (record: CompanyWatchList) => ({
      disabled: disabledOptions[record.unique_id.toString()] || record.share_permission === 1,
    }),
  };

  React.useEffect(() => {
    if (!isOpen) {
      setFilteredData(data);
      setSelectedRowKeys(Object.keys(selectedValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <SearchInput
        ref={inputRef}
        onChange={handleChange}
        value={searchValue}
        placeholder="Search lists"
      />
      <Button
        className="btn-create"
        style={{ left: isChromePlugin ? '2rem' : '12.8rem' }}
        onClick={showCreateList}
      >
        + NEW
      </Button>
      <Table
        style={{ height: 295, overflowY: 'auto', width: '100%' }}
        rowSelection={rowSelection}
        columns={columns}
        rowKey={list => list.unique_id.toString()}
        dataSource={filteredData}
        pagination={false}
      />
    </div>
  );
};

export default MenuList;
