import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  selectors as documentUploadTouchSelectors,
  actions as documentUploadTouchActions,
} from '@redux/ui/modals/add-document-upload-touch';
// hooks
import { useInjectDocumentUploadModal } from '@optx/common/hooks/inject';
// components
import DocumentDragAndDrop from './components/DocumentDragAndDrop';
import { Styled } from '../TouchInformation.styled';

const { TextArea } = Input;

interface DocumentUploadModalProps {
  isChromeExtension?: boolean;
}

const StyledNotesLabel = styled.label`
  margin-top: 30px;
`;

const DocumentUploadModal: React.FC<DocumentUploadModalProps> = ({ isChromeExtension = false }) => {
  useInjectDocumentUploadModal();
  const dispatch = useDispatch();
  const [notes, setNotes] = useState<string>('');
  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const isOpen = useSelector(documentUploadTouchSelectors.isOpen);
  const files = useSelector(documentUploadTouchSelectors.fileList);
  const getUploading = useSelector(documentUploadTouchSelectors.getUploading);

  const resetModal = () => {
    dispatch(documentUploadTouchActions.toggleModal());
    dispatch(documentUploadTouchActions.updateFileList([]));
    setNotes('');
  };

  const handleSave = () => {
    // dispatch file state action
    dispatch(documentUploadTouchActions.uploadFiles({ notes }));
    setSubmitted(prev => !prev);
  };

  useEffect(() => {
    if (isSubmitted && getUploading === false) {
      resetModal();
      setSubmitted(prev => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUploading]);

  const handleCancel = () => {
    resetModal();
  };

  const handleNotesChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    setNotes(e.target.value);
  };

  return (
    <Styled.DocumentUploadModal
      visible={isOpen}
      title="Upload File"
      okText={getUploading ? 'Uploading...' : 'Save'}
      okButtonProps={{ disabled: files.length === 0, loading: getUploading }}
      onOk={handleSave}
      onCancel={handleCancel}
      $isChromeExtension={isChromeExtension}
    >
      <DocumentDragAndDrop />
      <StyledNotesLabel htmlFor="document-upload-textarea">
        <strong>Notes</strong> (optional)
      </StyledNotesLabel>
      <TextArea
        id="document-upload-textarea"
        rows={4}
        placeholder="Enter notes"
        value={notes}
        onChange={handleNotesChange}
      />
    </Styled.DocumentUploadModal>
  );
};

export default DocumentUploadModal;
