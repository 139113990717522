import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import { ZoomInOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import { stringify, parse } from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
// models
import { DropdownAddProps, DropdownAddItem } from '@models/DropdownAdd';
import { Company, CompanyNotification } from '@models/Company';
import { SuccessErrorCallback } from '@optx/models/callback';
import { SelectOption } from '@optx/models/Option';
import { ReferrerUrlParam } from '@models/routes';
import { EquityTouchFetchedField } from '@models/equityTouch';
// constants
import { FAVORITE_LIST_ID } from '@constants/lists';
import { CompanyProfileTabs } from '@optx/constants/routes';
import { CompanyPageAddOnMGMT } from '@optx/constants/pendoActions';
// services
import NotificationService from '@services/NotificationService';
import { CompanyService, UserService } from '@optx/services/api';
// utils
import { hasEditPermission } from '@utils/lists';
import { geEquityTouchURL, stringifyReferrerUrlParam, getAddToSalesLoftURL } from '@utils/routes';
import { convertToURL } from '@utils/url';
import { handleMenuVisibleChange } from '@utils/menu';
import { handlePendoAction } from '@optx/utils/utils';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { actions as etActions } from '@redux/company/equity-touch';
import { selectors as individualEditSelectors } from '@components/feature/company-individual-edit/state';
// hooks
import useUpdateFields from '@optx/features/company/edit-fields/hooks/useUpdateFields';
import { useInitWatchListsIncludingCompany } from '@optx/common/hooks/init';
import { useHistoryTab } from '@optx/common/hooks/history/useHistoryTab';
import { useDropdownAdd } from '@components/DropdownAdd/useDropdownAdd';
// IMPORTANT: useInitWatchListsIncludingCompany should be before this import or all will break.
// components
import { EditRationaleProfile } from '@components/feature/company-individual-edit/components';
import Icon from '@components/common/Icon';
import {
  EditFieldCheckBoxSelectAsync,
  EditFieldSingleSelect,
  EditFieldText,
  EditFieldRadio,
} from '@optx/features/company/edit-fields/components';
import { DropdownAddContext } from './DropdownAddContext';
import PopoverSelectWatchList from './AddCompanyToListPopover';
import PopoverEditNote from './PopoverEditNote';
import RemoveAsAddon from './RemoveAsAddon';
import DropdownAddMenu from './DropdownAddMenu';
import { FavoriteButton, useCompanyFavorite } from '@optx/features/company/favorites';

const DropdownAdd: React.FC<DropdownAddProps> = ({
  record,
  index,
  deleteNote,
  editNote,
  isBlue = false,
  fromNotesPage,
  className,
  view,
}) => {
  const {
    is_in_et: isInEt,
    note_unique_id: noteId,
    et_url: companyEtUrl,
    links,
    stage,
    pipeline_rank: rank,
    company_owner: companyOwner,
    addon,
    company_id: companyId,
    company_name: companyName,
    company_url: companyUrl,
    company_owner: companyOwnerName,
    lead_source: leadSource,
    senior_advisor_thesis: seniorAdvisor,
    fund,
    linkedin,
    url_status: urlStatus,
    next_steps: nextSteps,
  } = (record as CompanyNotification & Company) || {};

  const getIsPartnerOnly = useSelector(userSelectors.getIsPartnerOnly);
  const hasReviewAccess = useSelector(userSelectors.getHasAccessToReviewCompany);

  const company = record as Company;

  const {
    filterListId,
    favoritesLists,
    toggleSelectList,
    openDropdownSelectList,
    // toggleCompanyNotesModal,
    toggleCompanyTouchesModal,
    toggleAddResearchRationale,
    deleteFromWatchList,
    openEditNotePopover,
    toggleEditNote,
    openRationalePopover,
    toggleRationale,
    removeNote,
    companyReview,
    toggleEditAllModal,
  } = useDropdownAdd(companyId, companyName, company, index, noteId);

  const history = useHistory();
  const location = useLocation();
  const { tab } = parse(location.search);
  const dispatch = useDispatch();
  const { updateHistoryFields } = useHistoryTab();
  const { updateField } = useUpdateFields({
    fieldName: 'Company Owner',
    companyUrl: record?.company_url ?? null,
    companyId,
    service: CompanyService.updateCompanyData,
  });
  const userLastName = useSelector(userSelectors.getLastName);
  const userFullName = useSelector(userSelectors.getFullName);
  const currentCompanyOwnerName = useRef<string>('');
  const companyOwnerOptions = useSelector(
    individualEditSelectors.companyIndividualEdit.companyOwnerOptions
  );
  const owner: SelectOption | undefined = companyOwnerOptions.find(
    option => option.label === userLastName
  );

  const handleCallback = useCallback(
    (data?: SuccessErrorCallback, showHistory?: boolean) => {
      if (data) {
        if (showHistory) {
          updateHistoryFields({
            afterValue: currentCompanyOwnerName.current,
            beforeValue: companyOwner === 'Unowned' ? 'None' : companyOwner,
            fieldChanged: 'Company Owner',
          });
        }
      }
    },
    [companyOwner, updateHistoryFields]
  );

  const components = useSelector(userSelectors.authorizedComponents);
  const addOwner = useCallback(
    (companyOwnerId, companyOwnerName) => {
      currentCompanyOwnerName.current = companyOwnerName;
      const value: SelectOption = {
        label: companyOwnerName,
        value: companyOwnerId,
      };
      updateField(value, 'deal_team_leader', undefined, handleCallback);
    },
    [handleCallback, updateField]
  );

  const etUrl = (links?.['Equity Touch'] || companyEtUrl) ?? '';

  const isAdmin = Object.keys(components).includes('admin_portal_link');

  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);

  const [initializeCompanyLists, setInitializeCompanyLists] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [targetOffset, setTargetOffset] = useState<[number, number]>([0, 0]);

  const { isFavorite, toggleFavorite } = useCompanyFavorite(companyId);

  useInitWatchListsIncludingCompany(companyId, initializeCompanyLists);

  useEffect(() => {
    return () => {
      if (!dropdownVisible || (dropdownVisible && window.location.pathname.includes('lists'))) {
        document.body.classList.remove('popover-visible');
      }
    };
  }, [dropdownVisible]);

  const fetchFormData = useCallback(
    (id: string, cb: (data: Dictionary<EquityTouchFetchedField>) => void) =>
      dispatch(etActions.fetchEquityTouchFormData(Number(id), cb)),
    [dispatch]
  );

  const companyInfoParams = stringify(
    { id: companyId, name: companyName, url: companyUrl },
    { arrayFormat: 'comma' }
  );

  const handleAddToEtClick = () => {
    dispatch(etActions.resetAddToEquityTouch());
    fetchFormData(companyId.toString(), (data: Dictionary<EquityTouchFetchedField>) => {
      if (Object.keys(data).length) {
        const searchParams: ReferrerUrlParam = parse(location.pathname);
        history.push(
          geEquityTouchURL(
            companyId,
            `${stringifyReferrerUrlParam(
              searchParams.referrer ? searchParams.referrer : location.pathname
            )}&${companyInfoParams}`
          )
        );
      } else {
        NotificationService.error(
          'Error in communication with EquityTouch. Please contact support team.'
        );
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items: DropdownAddItem[] = !fromNotesPage
    ? [
        {
          value: 'Verify Key Info',
          onClick: () => {
            toggleAddResearchRationale();
          },
          isVisible: !getIsPartnerOnly,
          key: 'item-1',
        },
        {
          value: 'Add to Equity Touch',
          onClick: handleAddToEtClick,
          isVisible: !isInEt,
          key: 'item-2',
        },
        {
          value: 'Add to Sales Loft Cadence',
          onClick: () => {
            history.push(getAddToSalesLoftURL(companyId, companyInfoParams));
          },
          key: 'item-3',
        },
        {
          value: '',
          onClick: () => {
            if (window.location.pathname.startsWith('/profile/')) {
              window.pendo.track(handlePendoAction('OpenURLInNewTab'));
            }

            toggleDropdownVisible();
          },
          render: () => (
            <a href={`/profile/${companyId}`} target="_blank" rel="noopener noreferrer">
              Open URL in new tab
            </a>
          ),
          key: 'item-4',
        },
        {
          value: '',
          onClick: () => {
            if (window.location.pathname.startsWith('/profile/')) {
              window.pendo.track(handlePendoAction('OpenInEquityTouch'));
            }

            toggleDropdownVisible();
          },
          render: () => (
            <a href={convertToURL(etUrl) || undefined} target="_blank" rel="noopener noreferrer">
              Open in Equity Touch
            </a>
          ),

          isVisible: etUrl !== '',
          key: 'item-5',
        },

        {
          value: '',
          render: () => <FavoriteButton isFavorite={isFavorite} />,
          onClick: () => {
            toggleFavorite();
            toggleDropdownVisible();
          },
          key: 'item-6',
        },
        // { value: ' Create a note', onClick: () => toggleCompanyNotesModal(), key: 'item-7' },
        {
          value: ' Add Touch Note',
          onClick: () => {
            toggleCompanyTouchesModal();
          },
          isVisible: isInEt && hasTouchAccess,
          key: 'item-25',
        },
        {
          value: `Remove from ${favoritesLists[filterListId]?.title} Watchlist`,
          isVisible:
            !!filterListId &&
            filterListId !== FAVORITE_LIST_ID &&
            !!favoritesLists[filterListId] &&
            hasEditPermission(favoritesLists[filterListId]?.share_permission),
          onClick: () => deleteFromWatchList(filterListId, companyId),
          key: 'item-8',
        },
        {
          value: 'Create or add to watchlist',
          onClick: () => {
            toggleSelectList();
          },
          key: 'item-9',
        },
        {
          value: 'Claim as Owner',
          onClick: () => addOwner(owner?.value, owner?.label),
          isVisible: userFullName !== companyOwnerName && !isAdmin && isInEt,
          accessFor: 'analyst_general',
          key: 'item-10',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              value={stage}
              record={record as Company}
              isFromGrid
              isFromGridDropDown
              dropdownLabel="Edit Stage"
              hidePenIcon
              service={CompanyService.updateCompanyData}
              successMessage="Company stage updated successfully!"
              errorMessage="Company stage update failed, Server error!"
              fieldName="Stage"
              fieldKey="stage"
              actionPendo={handlePendoAction('EditStage')}
            />
          ),
          isVisible: isInEt,
          key: 'item-11',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              value={nextSteps}
              record={record as Company}
              isFromGrid
              isFromGridDropDown
              dropdownLabel="Edit Next Steps"
              hidePenIcon
              service={UserService.updateNextSteps}
              successMessage="Company next steps updated successfully!"
              errorMessage="Company next steps update failed, Server error!"
              fieldName="Next Steps"
              actionPendo={handlePendoAction('NextSteps')}
            />
          ),
          isVisible: isInEt && tab === CompanyProfileTabs.ADDON_MGMT && !isBlue,
          key: 'item-12',
        },
        {
          value: '',
          onClick: () => {},
          render: () => <RemoveAsAddon record={record as Company} />,
          isVisible: tab === CompanyProfileTabs.ADDON_MGMT && !isBlue,
          key: 'item-13',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              fieldName="Pipeline Rank"
              fieldKey="rank"
              value={rank}
              isFromGrid
              isFromGridDropDown
              dropdownLabel="Set Pipeline Rank"
              service={CompanyService.updateCompanyData}
              successMessage="Pipeline rank updated successfully!"
              errorMessage="Company pipeline rank update failed, Server error!"
              hidePenIcon
              record={record as Company}
              actionPendo={handlePendoAction('SetPipelineRank')}
            />
          ),
          isVisible: isInEt,
          key: 'item-14',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              fieldName="Fund"
              fieldKey="fund"
              record={record as Company}
              value={fund}
              isFromGrid
              isFromGridDropDown
              hidePenIcon
              dropdownLabel="Set Fund"
              successMessage="Company fund updated successfully!"
              errorMessage="Company fund update failed, Server error!"
              service={CompanyService.updateCompanyData}
              actionPendo={handlePendoAction('SetFund')}
            />
          ),
          isVisible: isInEt,
          key: 'item-15',
        },
        {
          value: 'Set Rationale',
          onClick: () => {
            toggleRationale();
          },
          isVisible: isInEt,
          key: 'item-16',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              value={companyOwner}
              record={record as Company}
              isFromGrid
              isFromGridDropDown
              dropdownLabel="Change Ownership"
              hidePenIcon
              service={CompanyService.updateCompanyData}
              successMessage="Company owner updated successfully!"
              errorMessage="Company owner update failed, Server error!"
              fieldName="Company Owner"
              fieldKey="deal_team_leader"
              actionPendo={handlePendoAction('ChangeOwnership')}
            />
          ),
          isVisible: isInEt,
          key: 'item-17',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              value={leadSource}
              record={record as Company}
              isFromGrid
              isFromGridDropDown
              dropdownLabel="Change Lead Source"
              hidePenIcon
              service={CompanyService.updateCompanyData}
              successMessage="Lead Source updated successfully!"
              errorMessage="Company fund update failed, Server error!"
              fieldName="Lead Source"
              fieldKey="lead_source"
              actionPendo={handlePendoAction('ChangeLeadSource')}
            />
          ),
          isVisible: isInEt && leadSource !== undefined,
          key: 'item-18',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldSingleSelect
              value={seniorAdvisor}
              record={record as Company}
              isFromGrid
              isFromGridDropDown
              dropdownLabel="Change Senior Advisor Thesis"
              hidePenIcon
              service={CompanyService.updateCompanyData}
              successMessage="Senior Advisor Thesis updated successfully!"
              errorMessage="Company senior advisor thesis update failed, Server error!"
              fieldName="Senior Advisor Thesis"
              fieldKey="senior_advisor_thesis"
              actionPendo={handlePendoAction('ChangeSeniorAdvisorThesis')}
            />
          ),
          isVisible: isInEt && seniorAdvisor !== undefined,
          key: 'item-19',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldCheckBoxSelectAsync
              fieldName="Add-on"
              record={record as Company}
              value={addon}
              isFromGridDropDown
              service={CompanyService.updateAddon}
              hidePenIcon
              dropdownLabel="Make an Add-on"
              successMessage="Add-on updated successfully!"
              errorMessage=""
              isFromGrid
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
              actionPendo={handlePendoAction('MakeAnAdd-on')}
            />
          ),
          isVisible: isInEt && leadSource !== undefined,
          key: 'item-20',
          className: 'add-on',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldText
              value={linkedin}
              record={record as Company}
              isFromGrid={false}
              isFromHistory
              hidePenIcon
              service={CompanyService.updateLinkedinURL}
              fieldName="Linkedin Profile"
              triggerLabel="Edit Linkedin Profile"
              entityType="company"
              isURL
              actionPendo={handlePendoAction('EditLinkedInProfile')}
            />
          ),
          key: 'item-21',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <EditFieldRadio
              value={urlStatus}
              record={record as Company}
              isFromGrid={false}
              isFromHistory
              hidePen
              service={UserService.updateWebsiteActive}
              successMessage="Company website status updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="Website Status"
              triggerLabel="Edit Website Status"
              actionPendo={handlePendoAction('EditWebsiteStatus')}
            />
          ),
          isVisible: companyUrl !== null,
          key: 'item-22',
        },
        {
          value: 'Mark as reviewed',
          onClick: () => {
            companyReview();
          },
          isVisible: getIsPartnerOnly || hasReviewAccess,
          key: 'item-23',
        },
        {
          value: 'Edit Company Info',
          onClick: () => {
            toggleEditAllModal();
          },
          key: 'item-28',
        },
      ]
    : [
        {
          value: '',
          render: () => (
            <div>
              <EditOutlined />
              Edit Note
            </div>
          ),
          onClick: toggleEditNote,
          isVisible: editNote,
          key: 'item-24',
        },
        {
          value: '',
          render: () => (
            <div>
              <DeleteOutlined />
              Delete Note
            </div>
          ),
          onClick: removeNote,
          isVisible: deleteNote,
          key: 'item-26',
        },
        {
          value: '',
          onClick: () => {},
          render: () => (
            <a
              href={`/profile/${companyId}?tab=${CompanyProfileTabs.RELATIONSHIP_MANAGEMENT}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ZoomInOutlined />
              View on Company Profile
            </a>
          ),
          key: 'item-27',
        },
      ];

  const triggerRef = useRef<HTMLDivElement>(null);

  const handleVisibleChange = (visible: boolean) => {
    if (visible && view === 'table') {
      const trigger = triggerRef.current;

      if (trigger) {
        const boundingLimits = trigger.getBoundingClientRect();
        const { top, height } = boundingLimits;

        const page = window.location.pathname;
        let menuHeight = 0;

        if (page.includes('my-companies')) {
          menuHeight = 544;
        } else if (page.includes('profile')) {
          const addonTableClass = document.getElementsByClassName('addon-management-grid-wrapper');

          if (addonTableClass) {
            window.pendo.track(CompanyPageAddOnMGMT.sortByAction);
          }

          menuHeight = company.is_in_et ? 640 : 288;
        } else if (page.includes('checklist-research-insights')) {
          menuHeight = company.is_in_et ? 544 : 188;
        }

        // add a +10 offset to prevent scroll on bottom issue
        const yCoord = top - menuHeight < 0 ? 200 : menuHeight + height + 10;

        // adding a positive offset means it goes up on y-axis or left on x-axis,
        // relative to the trigger (3-dots ellipsis)
        setTargetOffset([0, yCoord]);
      }
    }

    setDropdownVisible(visible);
    handleMenuVisibleChange(visible);

    // Use timeout due to menu transition animation being blocked/delayed while the DOM changes and API call are made.
    setTimeout(() => {
      // Initialize company lists on first dropdown open only.
      if (visible && !initializeCompanyLists && !window.location.pathname.includes('user-lists')) {
        setInitializeCompanyLists(true);
      }
    }, 300);
  };

  const handleDropdownVisible = useCallback(
    (actionIndex: number | undefined) => {
      if (items[actionIndex as number].value !== '') {
        setDropdownVisible(!dropdownVisible);
      }
    },
    [dropdownVisible, items]
  );

  const toggleDropdownVisible = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleActionsNames = useCallback(
    (actionIndex: number | undefined) => {
      if (typeof actionIndex === 'number') {
        handleDropdownVisible(actionIndex);
        items[actionIndex as number].onClick();
      }
    },
    [handleDropdownVisible, items]
  );

  const menu = <DropdownAddMenu items={items} actionIndex={handleActionsNames} />;

  return (
    <DropdownAddContext.Provider value={{ dropdownVisible, setDropdownVisible }}>
      <div className="dropdown-block dropdown-tags empty-popover-wrapper">
        <Dropdown
          getPopupContainer={
            view !== 'table' ? trigger => trigger.parentNode as HTMLElement : undefined
          }
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={handleVisibleChange}
          className={className}
          visible={dropdownVisible}
          overlayStyle={{ zIndex: 1029 }}
          align={{ targetOffset }}
        >
          {isBlue ? (
            <Button
              type="text"
              icon={<Icon iconName="three-dots" className="profiledotsbutton" />}
              ref={triggerRef}
            />
          ) : (
            <EllipsisOutlined ref={triggerRef} />
          )}
        </Dropdown>
        <PopoverSelectWatchList
          companyId={companyId}
          isOpen={openDropdownSelectList}
          toggle={toggleSelectList}
        />
        <PopoverEditNote
          record={record as CompanyNotification}
          isOpen={openEditNotePopover}
          toggle={toggleEditNote}
          getPopupContainer={
            view !== 'table' ? trigger => trigger.parentNode as HTMLElement : undefined
          }
          placement="leftBottom"
          destroyTooltipOnHide
        />
        <EditRationaleProfile
          companyId={companyId}
          isOpen={openRationalePopover}
          toggle={toggleRationale}
        />
      </div>
    </DropdownAddContext.Provider>
  );
};

export default DropdownAdd;
