import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import queryString from 'query-string';
// constants
import { TotalsCardLabels, labelNameMap } from '@features/analyst-home/constants/labels';
import appRoutes, { DEFAULT_REPORTS } from '@constants/routes';
// redux
import { selectors } from '@features/analyst-home/state';
import { selectors as userSelectors } from '@redux/user/information';
import { actions, useInject } from '@optx/features/sourcing-outreach-summary';
// components
import StatsCompare from './components/StatsCompare';
import Styled from './components/Sidebar.styles';
import CompareStyled from './components/StatsCompare.styles';
import EmailsSentChart from './components/EmailsSentChart';
import TouchActivityLegends from './components/TouchActivityLegends';
import ActivityInsightsSkeleton from './ActivityInsightsSkeleton';

const ActivityInsights = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useInject();

  const activityInsightData = useSelector(selectors.getActivityInsightsData);
  const activityTouchInsightsData = useSelector(selectors.getActivityTouchInsightsData);
  const activityDates = useSelector(selectors.getActivityInsightsDates);
  const activityDateFilter = useSelector(selectors.getActivityInsightsDateFilter);
  const activityRedirectFilters = useSelector(selectors.getActivityInsightsRedirect);
  const touchTypes = useSelector(selectors.getTouchTypesRedirect);
  const analystName = useSelector(userSelectors.getFullName);
  const loading = useSelector(selectors.getTouchInsightsLoading);

  if (loading) {
    return <ActivityInsightsSkeleton />;
  }

  const handleReportRedirect = (title: string, extraDataLabel?: string) => {
    // @ts-ignore
    const filterTitle = Object.keys(labelNameMap)[Object.values(labelNameMap).indexOf(title)];

    const selectedFilters = activityRedirectFilters[filterTitle][extraDataLabel ?? 'analyst'];

    const { column_name, pipeline_rank, ...restFilters } = queryString.parse(selectedFilters, {
      arrayFormat: 'comma',
    });

    const [ddate_lower, ddate_upper] = activityDateFilter;

    const filtersData = {
      ...restFilters,
      ddate_lower,
      ddate_upper,
    };
    const filters = queryString.stringify(filtersData);

    const panel_filters = queryString.stringify({ pipeline_rank }, { arrayFormat: 'comma' });

    const state = {
      column_name,
      analyst_name: [analystName],
      filters,
      panel_filters,
    };
    // call init action to set pagination page number to 1
    // to prevent empty grid when saved page number is bigger then number of pages in grid
    dispatch(actions.init());
    history.push(`${DEFAULT_REPORTS.sourcingOutreachSummaryCompanies}`, state);
  };

  const handleTouchRedirect = (label: string) => {
    const { touch_type: touchType } = queryString.parse(touchTypes[label], {
      arrayFormat: 'comma',
    });
    const selectedPeriod = activityDates.find(
      date => date.start === activityDateFilter[0] && date.end === activityDateFilter[1]
    )?.label;

    history.push(
      `${appRoutes.scheduledTouches}?touchType=${touchType}${
        selectedPeriod ? `&selectedPeriod=${selectedPeriod}` : ''
      }`
    );
  };

  return (
    <>
      {activityInsightData.map(item => (
        <Styled.SubSection key={item.label}>
          <StatsCompare
            title={item.label}
            values={item.values}
            handleRedirect={handleReportRedirect}
            extraData={
              <div className="extra-data">
                {Object.entries(item.totals).map(([key, value]) => {
                  const label = TotalsCardLabels[key as keyof typeof TotalsCardLabels];

                  return (
                    <div key={label}>
                      <b>{label}</b>
                      <span
                        className="clickable"
                        onClick={() => handleReportRedirect(item.label, label)}
                      >
                        {value as number}
                      </span>
                    </div>
                  );
                })}
              </div>
            }
          />
        </Styled.SubSection>
      ))}
      <Styled.SubSection>
        <CompareStyled.Title>Touch Types by Activity</CompareStyled.Title>
        <Styled.ChartTitle># OF TOUCHES COMPARED TO AVG</Styled.ChartTitle>
        <EmailsSentChart data={activityTouchInsightsData.chartData} />
        <div className="extra-data">
          <div>
            <b>Total Touches</b>
            <span className="clickable" onClick={() => handleTouchRedirect('analyst')}>
              {activityTouchInsightsData.countTouch?.analyst}
            </span>
          </div>
          <div>
            <b>Team Average</b>
            <span>{activityTouchInsightsData.countTouch?.analyst_team_avg}</span>
          </div>
        </div>
        <TouchActivityLegends
          data={activityTouchInsightsData.touchLegends}
          handleRedirect={handleTouchRedirect}
        />
      </Styled.SubSection>
    </>
  );
};

export default ActivityInsights;
