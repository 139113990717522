import { call, put, takeLatest, take, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
// services
import { AnalystService, FilterService } from '@services/api';
import NotificationService from '@services/NotificationService';
// constants
import { FAVORITE_LIST_OWNER } from '@optx/constants/lists';
import { columnDisplayInitialStatePipelineGrid } from '@optx/constants/table/columnDisplay/company-search';
// models
import { PageInfo } from '@optx/models/table/Pagination';
import { AxiosResponse } from 'axios';
import { PipelineReportCompanies, PipelineReportCompaniesColumns } from '../models';
import { ColumnWidth, ColumnWidthUserSettings } from '@optx/models/table/Columns';
import { PipelineReportSingleSelectPayload } from './interfaces';
import { FilterGroup, PreselectedFilter } from '@optx/models/filters';
import { CompanyWatchList } from '@optx/models/WatchList';
import { SearchPayloadRequest } from '@optx/models/api/contacts';
// utils
import mapFiltersToURLParams from '@optx/utils/filters/mapFiltersToURLParams';
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { actions as gridSearchCountActions } from '@features/grid/search-count';
import {
  selectors as userSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions as fundActions } from '@components/feature/company-individual-edit/state/fund';
import { actions as companyEditFields } from '@features/company/edit-fields/state';
// local
import * as actions from './actions';
import * as selectors from './selectors';
import { SelectOption } from '@optx/models/Option';

function* getFilterQuery(
  action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>,
  includePagination: boolean = true
) {
  const { normalized, filter, clear } = yield select(selectors.getFilters);

  const pagination: PageInfo = yield select(paginationSelectors.getPagination('pipelineReport'));

  let queryData: Dictionary<PreselectedFilter | number> = {};

  if (action?.payload?.shouldClear) {
    const defaultScore: string = yield select(userSelectors.getDefaultScore);

    const normalizedFilter = {
      ...clear,
      ...(clear.company_owner_id && {
        company_owner_id: clear.company_owner_id.length === 0 ? [''] : clear.company_owner_id,
      }),
    };

    queryData = {
      ...mapFiltersToURLParams(normalized, normalizedFilter),
      ...(defaultScore === 'il' ? { is_il_optx_score: true } : {}),
      country: undefined,
      page: pagination.pageNumber,
      per_page: pagination.pageSize,
    };
  } else {
    const normalizedFilter = {
      ...filter,
      ...(filter.company_owner_id && {
        company_owner_id: filter.company_owner_id.length === 0 ? [''] : filter.company_owner_id,
      }),
    };

    const mappedFiltersURLParams = mapFiltersToURLParams(normalized, normalizedFilter);

    queryData = {
      ...mappedFiltersURLParams,
      country: undefined,
      page: pagination.pageNumber,
      per_page: pagination.pageSize,
    };

    if (normalizedFilter.country === 'Israel') {
      delete queryData.fund;
      queryData.is_il_optx_score = true;
    }

    if (queryData.fund === '') {
      delete queryData.fund;
    }
  }

  if (!includePagination) {
    delete queryData.page;
    delete queryData.per_page;
  }

  return queryString.stringify(queryData, {
    arrayFormat: 'comma',
  });
}

export function* fetchPipelineReportSaga(
  action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>
) {
  const query: string = yield call(getFilterQuery, action);
  const countQuery: string = yield call(getFilterQuery, action, false);
  const pagination: PageInfo = yield select(paginationSelectors.getPagination('pipelineReport'));

  try {
    const res: AxiosResponse = yield call(AnalystService.getPipelineReportsCompany, query);

    if (res.data) {
      yield put(actions.fetchPipelineReportCompanyCount(countQuery));
      yield put(actions.fetchPipelineReportCompanySuccess(res.data));
      yield put(
        userInformationActions.updateUserSettings({
          pipeline_report: decodeURIComponent(query),
          bi_weekly_pipeline_report_page_number: pagination.pageNumber,
          bi_weekly_pipeline_report_per_page: pagination.pageSize,
        })
      );
    } else {
      const errorMessage = 'Bi-Weekly Pipeline Report fail!';
      yield put(actions.fetchPipelineReportCompanyFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Bi-Weekly Pipeline Report fail, Server error!');
    yield put(actions.fetchPipelineReportCompanyFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchPipelineReportCardSaga(
  action?: PayloadAction<PipelineReportSingleSelectPayload | undefined>
) {
  const query: string = yield call(getFilterQuery, action, false);

  try {
    const res: AxiosResponse = yield call(AnalystService.getPipelineReportsCard, query);

    if (res.data) {
      yield put(actions.fetchPipelineReportCardSuccess(res.data));
    } else {
      const errorMessage = 'Bi-Weekly Pipeline Report fail!';
      yield put(actions.fetchPipelineReportCardFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Bi-Weekly Pipeline Report fail, Server error!');
    yield put(actions.fetchPipelineReportCardFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchPipelineReportCompanyCountSaga(query: PayloadAction<string>) {
  try {
    const res: AxiosResponse<number> = yield call(
      AnalystService.getPipelineReportsCompanyCount,
      query.payload
    );

    if (typeof res.data === 'number') {
      yield put(actions.fetchPipelineReportCompanyCountSuccess(res.data));
      yield put(
        gridSearchCountActions.searchCountSuccess({
          gridName: 'pipelineReport',
          searchCount: res.data,
        })
      );
    } else {
      const errorMessage = 'Bi-Weekly Pipeline Report fail!';
      yield put(actions.fetchPipelineReportCompanyCountFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = getErrorMessage(error, 'Bi-Weekly Pipeline Report fail, Server error!');
    yield put(actions.fetchPipelineReportCompanyCountFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* fetchFiltersSaga() {
  try {
    const shouldFetchUserInformation: boolean = yield select(userSelectors.shouldFetch);

    if (shouldFetchUserInformation) {
      yield take(userInformationActions.fetchUserInformationSuccess);
    }

    const defaultScore: string = yield select(userSelectors.getDefaultScore);

    const res: AxiosResponse = yield call(FilterService.getOptions, FilterGroup.PipelineReport);

    yield put(actions.fetchFiltersSuccess({ filters: res.data.filters, defaultScore }));

    yield put(actions.fetchPipelineReportCard());

    yield take(actions.fetchPipelineReportCardSuccess);

    yield put(actions.fetchPipelineReportCompany());
  } catch (error: any) {
    const errorMessage = getErrorMessage(
      error as Error,
      'Failed to fetch filters for Bi-Weekly Pipeline Report!'
    );

    yield put(actions.fetchFiltersFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* saveSearchAsListSaga(action: PayloadAction<{ title: string }>) {
  const { title } = action.payload;

  const { normalized, filter } = yield select(selectors.getFilters);

  const filterQuery = mapFiltersToURLParams(normalized, filter);
  const userName: string = yield select(userSelectors.getFullName);
  const companyData: PipelineReportCompanies[] = yield select(selectors.pipelineReportCompanies);

  const searchData: SearchPayloadRequest = {
    searchKey: '',
    filter: { ...filterQuery, fund: filter?.fund.map((item: SelectOption) => item.value) },
    pagination: { pageNumber: 0, pageSize: 0 },
    sortBy: [],
  };

  try {
    const res: AxiosResponse<Dictionary<CompanyWatchList>> = yield call(
      AnalystService.saveAsListPipelineReport,
      title,
      searchData
    );

    const getKeys = Object.keys(res.data)[0];
    const value = res.data[getKeys];

    res.data[Object.keys(res.data)[0]] = {
      ...res.data[Object.keys(res.data)[0]],
      owner_name: userName,
      origin: FAVORITE_LIST_OWNER,
    };

    yield put(
      actions.saveSearchAsListSuccess({ [getKeys]: { ...value, count: companyData.length } })
    );
    NotificationService.success(`Create watchlist ${title} success!`);
  } catch (e: any) {
    // save the title to reducer, create a new action
    const message = `Create watchlist ${title} failed!`;
    yield put(actions.saveSearchAsListFail(message));
    NotificationService.error(message);
  }
}

function* updateFilterSaga(action?: PayloadAction<PipelineReportSingleSelectPayload>) {
  yield put(actions.fetchPipelineReportCard(action?.payload));

  yield take(actions.fetchPipelineReportCardSuccess);

  yield put(actions.fetchPipelineReportCompany(action?.payload));

  if (action?.payload.filter.key === 'country') {
    yield put(
      userInformationActions.updateUserSettings({
        bi_weekly_pipeline_report_region: action?.payload.filter.value as string,
      })
    );
  }
}

/**
 * reset filter saga is separated from the update one
 * because the action payload mustn't be passed on the saga,
 * only on the reducer.
 */
function* resetFilterSaga() {
  yield put(actions.fetchPipelineReportCard());

  yield take(actions.fetchPipelineReportCardSuccess);

  yield put(actions.fetchPipelineReportCompany());
}

function* clearFiltersSaga() {
  const payload = { filter: { key: '', value: '' }, shouldClear: true };

  yield put(actions.fetchPipelineReportCard(payload));

  yield take(actions.fetchPipelineReportCardSuccess);

  yield put(actions.fetchPipelineReportCompany(payload));
}

export function* saveColumnWidthSaga(
  action: PayloadAction<ColumnWidth<PipelineReportCompaniesColumns>>
) {
  const { columnId, width, userSetting } = action.payload;

  const columnWidths: Dictionary<number> = yield select(userSelectors.columnWidthsAdvancedSearch);

  let newColumnWidths = { ...columnWidths };

  newColumnWidths = {
    ...newColumnWidths,
    ...{ [columnId as PipelineReportCompaniesColumns]: width },
  };

  if (
    columnDisplayInitialStatePipelineGrid[columnId as PipelineReportCompaniesColumns].width ===
    width
  ) {
    delete newColumnWidths[columnId as PipelineReportCompaniesColumns];
  }

  yield put(
    userInformationActions.updateUserSettings({
      [userSetting]: newColumnWidths,
    })
  );
}

export function* resetColumnWidthsSaga(action: PayloadAction<ColumnWidthUserSettings>) {
  yield put(
    userInformationActions.updateUserSettings({
      [action.payload]: {},
    })
  );
}

export default function* alertsSaga() {
  yield takeLatest(actions.fetchPipelineReportCompany, fetchPipelineReportSaga);
  yield takeLatest(actions.fetchPipelineReportCard, fetchPipelineReportCardSaga);
  yield takeLatest(actions.fetchPipelineReportCompanyCount, fetchPipelineReportCompanyCountSaga);
  // filters
  yield takeLatest(actions.fetchFilters, fetchFiltersSaga);
  yield takeLatest(actions.updateValue, updateFilterSaga);
  yield takeLatest(actions.clearFilters, clearFiltersSaga);
  yield takeLatest(actions.resetToDefault, updateFilterSaga);
  yield takeLatest(actions.applyFilters, updateFilterSaga);
  yield takeLatest(actions.resetFilter, resetFilterSaga);
  yield takeLatest(actions.saveSearchAsList, saveSearchAsListSaga);
  // width
  yield takeLatest(actions.saveColumnWidth, saveColumnWidthSaga);
  yield takeLatest(actions.resetColumnWidths, resetColumnWidthsSaga);
  // external
  yield takeLatest(companyEditFields.updateFieldsSuccess, fetchPipelineReportCardSaga);
  yield takeLatest(fundActions.addFundSuccess, fetchPipelineReportCardSaga);
}
