import { GridKeys, GridKeysMain } from '@optx/models/grid';

export const GRID_KEYS: GridKeys[] = [
  'advancedSearch',
  'watchlists',
  'sslists',
  'myCompanies',
  'addons',
  'outreach',
  'contacts',
  'checklistResearchInsights',
  'pipelineReport',
];

export const GRID_VIEW_KEYS: GridKeys[] = [
  'advancedSearch',
  'watchlists',
  'sslists',
  'myCompanies',
  'outreach',
];

export const GRID_FETCHED_COUNT_KEYS: GridKeys[] = [
  'advancedSearch',
  'lists',
  'myCompanies',
  'outreach',
  'addons',
];

export const GRID_KEYS_SEARCH: GridKeysMain[] = [
  'advancedSearch',
  'lists',
  'myCompanies',
  'addons',
  'outreach',
];

export const GRID_FILTER_KEYS: GridKeys[] = [
  'advancedSearch',
  'watchlists',
  'sslists',
  'myCompanies',
  'addons',
];

export const PAGINATION_INITIAL_STATE = {
  pageNumber: 1,
  pageSize: 50,
};

export const LONGCARD_PAGINATION_INITIAL_STATE = {
  pageNumber: 1,
  pageSize: 50,
};

export const SEARCH_COUNT_INITAL_STATE = {
  searchCount: 0,
  loading: false,
  error: '',
};
export const VIEW_INITIAL_STATE = {
  id: '',
  label: '',
};

export const FILTERS_INITIAL_STATE = {
  filter: {},
  preselected: {},
  clear: {},
  customClearedFilter: {},
  fetchedAt: null,
};

export const SEARCH_INITIAL_STATE = {
  byId: {},
  allIds: [],
  loading: false,
  error: '',
  fetchedAt: '',
  trending: '1Y',
  cancelled: false,
  hideCancelSearch: false,
  shouldUpdateSourcingOutReachAnalystInfo: true,
  searchTitle: '',
  searchKey: '',
  companyId: 0,
};

export const VIEW_TYPE_INITIAL_STATE = {
  searchView: 'table',
  changedCompanyCard: false,
};

export const GRID_MODALS_INITIAL_STATE = {
  isModalOpen: false,
};

export const VIEW_TRANSITION_INITIAL_STATE = {
  isViewTransition: false,
};
