import styled, { css } from 'styled-components';
import { Card, Typography } from 'antd';

const { Paragraph } = Typography;

const sharedStyles = `
  position: relative;
  z-index: 2;
  text-align: center;
`;

const StyledCard = styled(Card)<{ $isSmallCard?: boolean; $areNotifications?: boolean }>`
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 4px 0px #00000026;
  width: 100%;
  max-width: 300px;

  ${props =>
    props.$isSmallCard &&
    css`
      padding-left: 20px;
      padding-right: 20px;
      min-width: 150px;

      @media (min-width: 1024px) {
        min-width: 164px;
      }

      @media (min-width: 1280px) {
        min-width: 178px;
      }

      @media (min-width: 1360px) {
        min-width: 150px;
      }

      @media (1360px <= width < 1392px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      @media (min-width: 1440px) {
        min-width: 168px;
      }

      @media (min-width: 1680px) {
        min-width: 178px;
      }

      @media (min-width: 2000px) {
        min-width: 160px;
      }

      @media (min-width: 2370px) {
        min-width: 220px;
      }

      @media (min-width: 2550px) {
        min-width: 240px;
      }

      @media (min-width: 2610px) {
        min-width: 250px;
      }

      @media (min-width: 2750px) {
        min-width: 265px;
      }

      @media (min-width: 2930px) {
        min-width: 300px;
      }
    `}

  &:hover {
    cursor: pointer;
  }

  ::before {
    content: '';
    position: absolute;
    width: 130%;
    height: 90%;
    border-radius: 45%;
    background-color: #e0e6ee;
    z-index: 1;
    top: ${props => (props.$isSmallCard ? '0' : '-8%')};
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-card-body {
    padding: 10px 0 20px;

    &:before,
    &:after {
      content: none;
    }

    ${props =>
      props.$isSmallCard &&
      css`
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: ${props.$areNotifications ? '186' : '162'}px;
      `}
  }
`;

const CardTitle = styled(Paragraph)`
  /* Blue */
  color: #334d6e;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0 !important;
  ${sharedStyles}
`;

const SubTitle = styled(Paragraph)`
  /* Grey */
  color: #595959;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  ${sharedStyles}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  ${sharedStyles}
`;

const Styled = {
  StyledCard,
  CardTitle,
  SubTitle,
  IconWrapper,
};

export default Styled;
