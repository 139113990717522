/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary, isEqual } from 'lodash';
import moment from 'moment';
// models
import { CompanyProfile } from '@optx/models/Company';
import {
  EquityTouchDatabaseMatch,
  EquityTouchFetchedField,
  EquityTouchFormJSON,
} from '@optx/models/equityTouch';
// constants
import globalConfig from '@optx/constants/config';
import { CONTACT_SELECT_LIMIT } from '@optx/constants/contacts';
import { AUTOSAVE_TIMER_DURATION } from '@optx/constants/equitytouch';
// redux
import { actions as bulkEqtActions } from '@features/bulk-actions/equity-touch';
import { selectors as bulkEqtSelectors } from '@features/bulk-actions';
import { SelectOption } from '@optx/models/Option';
import { EquityTouchBulkCompanies, SelectedCompanies } from '@optx/models/bulkActions';
import { getSessionSettings } from '@redux/user/information/selectors';
import { actions as userActions } from '@redux/user/information';

interface AutoSaveType {
  step: number;
}

const AutoSave: React.FC<AutoSaveType> = ({ step }) => {
  const formik = useFormikContext();
  // @ts-ignore
  const { grid } = useParams();
  const dispatch = useDispatch();
  const { action: routerAction } = useHistory();

  const getFormData = useSelector(bulkEqtSelectors.equityTouch.getFormData);
  const getCompanies = useSelector(bulkEqtSelectors.equityTouch.getCompanies);
  const temporaryData = useSelector(bulkEqtSelectors.equityTouch.getTemporaryData);
  const listType = useSelector(bulkEqtSelectors.equityTouch.getListType);
  const listId = useSelector(bulkEqtSelectors.equityTouch.getListId);
  const sessionSettings = useSelector(getSessionSettings);
  const getCompanyDatas = useSelector(
    bulkEqtSelectors.selectedCompanies.getSelectedCompanies(grid)
  );
  const companyProfileId = (
    useSelector(bulkEqtSelectors.equityTouch.getCompanyData) as CompanyProfile
  )?.company_id;

  const [formValues, setFormValues] = useState(formik?.values);
  const [userSettings, setUserSettings] = useState({});

  useEffect(() => {
    if (!isEqual(formValues, formik?.values)) {
      setFormValues(formik?.values);
    }
  }, [formik?.values]);

  const save = useCallback(
    (
      newValues: Dictionary<EquityTouchFormJSON>,
      oldValues: EquityTouchBulkCompanies,
      selectedCompanies: SelectedCompanies[],
      formData: Dictionary<EquityTouchFetchedField<any>>,
      firstStep: Dictionary<EquityTouchFormJSON>,
      companiesET: EquityTouchBulkCompanies
    ) => {
      let value = {};
      console.log('save');

      const formDataCopy = { ...formData };
      const mappedFormData = Object.keys(formDataCopy).reduce(
        (acc: Dictionary<EquityTouchFetchedField>, field: string) => {
          const fieldValue: any = newValues[field];

          if (newValues[field] && fieldValue !== '') {
            if (formDataCopy[field].type === 'select_suggest') {
              acc[field] = {
                ...formDataCopy[field],
                database_matched: fieldValue as EquityTouchDatabaseMatch,
                received_values: [fieldValue as SelectOption],
                from_database: fieldValue.label,
              };
            } else if (formDataCopy[field].label === 'Add-on') {
              acc[field] = {
                ...formDataCopy[field],
                from_database: fieldValue as boolean,
              };
            } else if (formDataCopy[field].type === 'select') {
              const data = (formDataCopy[field].received_values as EquityTouchDatabaseMatch[]).find(
                (item: EquityTouchDatabaseMatch) => item.itemId === fieldValue
              );

              if (!data) {
                acc[field] = {
                  ...formDataCopy[field],
                  from_database: fieldValue,
                };
              } else {
                acc[field] = {
                  ...formDataCopy[field],
                  database_matched: data as EquityTouchDatabaseMatch,
                  from_database: data.text as string,
                };
              }

              return acc;
            } else
              acc[field] = {
                ...formDataCopy[field],
                database_matched: (newValues as any)[field],
              };

            return acc;
          }

          acc[field] = {
            ...formDataCopy[field],
          };

          return acc;
        },
        {}
      );

      if (step === 1) {
        const oldVal = companiesET || {};

        value = {
          formData: mappedFormData,
          firstStepData: {
            ...firstStep,
            ...newValues,
            cadence: (temporaryData as any)?.step1?.cadence,
          },
          companiesById: {
            ...oldVal,
            ...firstStep,
            ...newValues,
            cadence: (temporaryData as any)?.step1?.cadence,
          },
        };
        dispatch(
          bulkEqtActions.updateTemporaryData({
            step1: {
              ...firstStep,
              ...newValues,
              cadence: (temporaryData as any)?.step1?.cadence,
            },
            step2: {
              ...oldVal,
              ...firstStep,
              ...newValues,
              cadence: (temporaryData as any)?.step1?.cadence,
            } as any,
            ...(listId && { listId }),
            ...(listType && { listType }),
          })
        );
      } else if (step === 2 && companyProfileId && newValues) {
        const oldVal = oldValues || {};

        let slContactsUpdated;

        if (
          (temporaryData as any)?.step2?.[companyProfileId] &&
          'salesloft_contacts' in (temporaryData as any)?.step2?.[companyProfileId]
        ) {
          if (oldVal[companyProfileId].salesloft_contacts) {
            const tempSLContacts = (temporaryData as any)?.step2?.[companyProfileId]
              ?.salesloft_contacts;
            const oldSLContacts = oldVal[companyProfileId]?.salesloft_contacts as any;

            if (tempSLContacts?.length === oldSLContacts?.length) {
              slContactsUpdated = [];
              tempSLContacts?.forEach((tempSLContactData: any) => {
                const oldSLContactData = oldSLContacts.filter(
                  (contact: any) => contact.person_id === tempSLContactData.person_id
                )[0];
                if (Object.keys(tempSLContactData).length <= Object.keys(oldSLContactData).length) {
                  slContactsUpdated.push(oldSLContactData);
                } else slContactsUpdated.push(tempSLContactData);
              });
            } else {
              slContactsUpdated = tempSLContacts;
            }
          } else {
            slContactsUpdated = (temporaryData as any)?.step2?.[companyProfileId]
              ?.salesloft_contacts;
          }
        } else {
          slContactsUpdated = getCompanies[companyProfileId]?.salesloft_contacts;
        }

        let contactsUpdated;

        if (
          (temporaryData as any)?.step2?.[companyProfileId] &&
          'contacts' in (temporaryData as any)?.step2?.[companyProfileId]
        ) {
          if (oldVal[companyProfileId].contacts) {
            const tempContacts = (temporaryData as any)?.step2?.[companyProfileId]?.contacts;
            const oldContacts = oldVal[companyProfileId]?.contacts as any;

            if (
              tempContacts?.length === oldContacts?.length ||
              tempContacts?.length < oldContacts?.length
            ) {
              contactsUpdated = [];
              tempContacts.forEach((tempContactData: any) => {
                const oldContactData = oldContacts.filter(
                  (contact: any) => contact.person_id === tempContactData.person_id
                )[0];
                if (Object.keys(tempContactData).length <= Object.keys(oldContactData).length) {
                  contactsUpdated.push(oldContactData);
                } else contactsUpdated.push(tempContactData);
              });
            }
          } else {
            contactsUpdated = (temporaryData as any)?.step2?.[companyProfileId]?.contacts;
          }
        } else {
          contactsUpdated = getCompanies[companyProfileId]?.contacts;
        }

        value = {
          firstStepData: firstStep,
          companiesById: {
            ...firstStep,
            ...oldVal,
            [companyProfileId]: {
              ...newValues,
              cadence:
                (temporaryData as any)?.step2?.[companyProfileId] &&
                'cadence' in (temporaryData as any)?.step2?.[companyProfileId]
                  ? (temporaryData as any)?.step2?.[companyProfileId]?.cadence
                  : oldVal?.[companyProfileId]?.cadence,
              salesloft_contacts: slContactsUpdated,
              contacts: contactsUpdated.slice(0, CONTACT_SELECT_LIMIT),
            },
          },
        };

        dispatch(
          bulkEqtActions.updateTemporaryData({
            step1: {
              ...firstStep,
              ...newValues,
              cadence: firstStep?.cadence || newValues?.cadence,
            },
            step2: {
              ...firstStep,
              ...oldVal,
              [companyProfileId]: {
                ...newValues,
                cadence:
                  (temporaryData as any)?.step2?.[companyProfileId] &&
                  'cadence' in (temporaryData as any)?.step2?.[companyProfileId]
                    ? (temporaryData as any)?.step2?.[companyProfileId]?.cadence
                    : oldVal?.[companyProfileId]?.cadence,
                salesloft_contacts: slContactsUpdated,
                contacts: contactsUpdated.slice(0, CONTACT_SELECT_LIMIT),
              },
              ...(listId && { listId }),
              ...(listType && { listType }),
            } as any,
          })
        );
      }

      const userSettingsData = {
        et_form_auto_save: {
          ...(listId && { listId }),
          ...(listType && { listType }),
          date: moment().format(globalConfig.short_date.DATE_FORMAT),
          step,
          grid,
          queue: companyProfileId,
          formData,
          companiesData: selectedCompanies,
          ...value,
        },
      } as Partial<any>;

      if (!isEqual(userSettings, userSettingsData)) {
        dispatch(userActions.updateUserSettingsFromAutoSave(userSettingsData));
        setUserSettings(userSettingsData);
      }
    },
    [dispatch, formValues, companyProfileId, temporaryData]
  );

  const handleAutosave = (
    newValues: Dictionary<EquityTouchFormJSON>,
    oldValues: EquityTouchBulkCompanies,
    selectedCompanies: SelectedCompanies[],
    formData: Dictionary<EquityTouchFetchedField<any>>,
    firstStep: Dictionary<EquityTouchFormJSON>,
    companiesET: EquityTouchBulkCompanies
  ) => save(newValues, oldValues, selectedCompanies, formData, firstStep, companiesET);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const dataReceived = Object.keys(getCompanies || {}).length !== 0;
    const valuesExists =
      Object.keys((formValues as Dictionary<EquityTouchFormJSON>) || {}).length !== 0;
    const formData = Object.keys(getFormData || {}).length !== 0;

    if (valuesExists && dataReceived && formData) {
      const timer = setTimeout(() => {
        handleAutosave(
          formik.values as Dictionary<EquityTouchFormJSON>,
          getCompanies,
          getCompanyDatas,
          getFormData,
          {
            ...(sessionSettings?.et_form_auto_save?.firstStepData as any),
            ...temporaryData?.step1,
          },
          getCompanies
        );
      }, AUTOSAVE_TIMER_DURATION);

      return () => clearTimeout(timer);
    }
  }, [formValues, getCompanies, companyProfileId, getCompanyDatas, getFormData]);

  useEffect(() => {
    if (routerAction === 'POP') {
      const dataReceived = Object.keys(getCompanies || {}).length !== 0;

      if (dataReceived && companyProfileId && step === 2) {
        formik?.setValues(getCompanies[companyProfileId]);
      }
    }
  }, [getFormData, routerAction, companyProfileId, getCompanies]);

  useEffect(() => {
    formik?.validateForm({
      ...temporaryData.step1,
      ...(formValues as Dictionary<EquityTouchFormJSON>),
      cadence:
        temporaryData.step1?.cadence || (formValues as Dictionary<EquityTouchFormJSON>)?.cadence,
    });
  }, [formValues, temporaryData]);

  return null;
};

export default AutoSave;
