import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
// models
import { CompanyProfile, SocialMediaProps } from '@optx/models/Company';
// constants
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// utils
import { socialMediaMapping } from '@optx/utils/socialMedia';
// components
import Icon from '@optx/components/common/Icon';
import CardPanelHeader from './styled/CardPannelHeader.styles';

interface CompanyProfileLinksProps {
  company: CompanyProfile;
}

const CompanyProfileLinks: React.FC<CompanyProfileLinksProps> = ({ company }) => {
  const socialMediaList: Array<SocialMediaProps> = useMemo(() => {
    return socialMediaMapping(company);
  }, [company]);

  return (
    <Row justify="end">
      <Col xs={24}>
        <div className="profilelinks">
          <CardPanelHeader.SocialMediaWrapper>
            <ul>
              {socialMediaList.map((media, index) => (
                <li key={`socialmedia-${index}`}>
                  <a href={media.url} title={media.title} target="_blank" rel="noopener noreferrer">
                    <Icon
                      onClick={() => {
                        window.pendo.track(
                          OPTXChromePlugin.companyBanner[
                            `${media.icon
                              .toLowerCase()
                              .replace(
                                /\s+/g,
                                ''
                              )}_icon` as keyof typeof OPTXChromePlugin.companyBanner
                          ]
                        );
                      }}
                      iconName={media.icon}
                      style={{
                        fill:
                          media.icon === 'website' && company.url_status === 'not active'
                            ? 'red'
                            : media.icon === 'grata' || media.icon === 'pitchbook'
                            ? 'none'
                            : '#0050b3',
                      }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </CardPanelHeader.SocialMediaWrapper>
        </div>
      </Col>
    </Row>
  );
};

export default CompanyProfileLinks;
