import React from 'react';
import { Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
// redux
import {
  fetchScheduledTouches,
  changeSelectedTab,
  fetchScheduledTouchesNextPage,
} from '@optx/redux/scheduled-touches/actions';
// components
import {
  getSelectedTab,
  getTabs,
  getTouches,
  getTotalTouches,
  isTouchesLoading,
  getTabsCount,
  isTouchesCountLoading,
} from '@redux/scheduled-touches/selectors';
import { LoadingOutlined } from '@ant-design/icons';
import { ScheduledTouchItem } from './TouchItem';
import SortBy from './SortBy';
import CustomSelect from './CustomSelect';
// styles
import Styled from './TouchesList.style';

const TouchesList = () => {
  const dispatch = useDispatch();
  const tabs = useSelector(getTabs);
  const selectedTab = useSelector(getSelectedTab);
  const loading = useSelector(isTouchesLoading);
  const touches = useSelector(getTouches);
  const totalTouches = useSelector(getTotalTouches);
  const count = useSelector(getTabsCount);
  const isCountLoading = useSelector(isTouchesCountLoading);

  const onChangeTab = (tab: string) => {
    dispatch(changeSelectedTab(tab));
    dispatch(fetchScheduledTouches());
  };

  const handleLoadMore = () => {
    dispatch(fetchScheduledTouchesNextPage());
  };

  const infiniteScrollRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage: touches !== null && totalTouches !== 0,
    onLoadMore: handleLoadMore,
  });

  return (
    <div ref={infiniteScrollRef} className="touches-list-wrapper">
      <Styled.Tabs activeKey={selectedTab} onChange={onChangeTab}>
        {Object.entries(tabs).map(([key, value]) => {
          return (
            <Styled.Tabs.TabPane
              key={key}
              tab={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <>
                  {`${value}`} (
                  {isCountLoading ? (
                    <LoadingOutlined style={{ color: '#4ce1b6', margin: 0 }} />
                  ) : (
                    count?.[key] || 0
                  )}
                  )
                </>
              }
            >
              <Styled.ActionsHeader isEmpty={touches?.length === 0} className="d-flex">
                <CustomSelect type="rankType" />
                <CustomSelect type="touchType" />
                <CustomSelect type="stageType" />
                <SortBy />
              </Styled.ActionsHeader>
              {touches?.map(item => (
                <ScheduledTouchItem key={item.touch_id} data={item} />
              ))}
              {(touches?.length === 0 || touches === null) && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Styled.Tabs.TabPane>
          );
        })}
      </Styled.Tabs>
    </div>
  );
};

export default TouchesList;
