import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from 'lodash';
import styled from 'styled-components';
import { Select, Tooltip, message, Typography } from 'antd';
import moment from 'moment';
// models
import { CompanyProfile } from '@models/Company';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { filterOption } from '@optx/utils/select/select';
// redux
import { selectors as salesloftSelectors } from '@redux/contacts/salesloft';
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';
import { actions as bulkActions } from '@features/bulk-actions/equity-touch';
// components
import { useParams } from 'react-router';
import { Context } from '../common/Context';

const SelectWrapper = styled(Select)`
  width: 50%;
`;

const { Option } = Select;

interface SelectCadenceProps {
  readOnly?: boolean;
}

const SelectCadence: React.FC<SelectCadenceProps> = ({ readOnly }) => {
  const dispatch = useDispatch();
  const { step, setCadence, errors } = useContext(Context);
  // @ts-ignore
  const { grid } = useParams();
  const options = useSelector(salesloftSelectors.getCadences);
  const companyData = useSelector(
    bulkActionsSelectors.equityTouch.getCompanyData
  ) as CompanyProfile;
  const companyIds = useSelector(bulkActionsSelectors.equityTouch.getCompanies);
  const bulkValues = useSelector(bulkActionsSelectors.equityTouch.getBulkValues);
  const temporaryData = useSelector(bulkActionsSelectors.equityTouch.getTemporaryData);
  const formData = useSelector(bulkActionsSelectors.equityTouch.getFormData);
  const companiesData = useSelector(
    bulkActionsSelectors.selectedCompanies.getSelectedCompanies(grid)
  );
  const [value, setValue] = useState<string | number | undefined>(undefined);
  const [disabled, setDisabled] = useState(false);
  let valueLoaded: boolean = false;
  const isRedirectedToStep3 = window.location.pathname.includes('step-3');

  const handleChange = (value: any) => {
    setValue(value);
    setCadence(value);

    const updatedEquityTouchCompanieById = {
      ...companyIds[companyData.company_id],
      cadence: value,
    };

    dispatch(bulkActions.updateBulkCadence(updatedEquityTouchCompanieById));

    if (step === 1) {
      if (value === '') {
        dispatch(
          bulkActions.updateTemporaryData({
            step2: {
              ...temporaryData?.step2,
              [companyData?.company_id]: {
                ...(temporaryData?.step2?.[companyData.company_id] as any),
                cadence: value,
              },
            } as any,
          })
        );
        dispatch(
          bulkActions.autoSave({
            step: step + 1,
            grid,
            queue: companyData.company_id,
            companiesById: {
              ...temporaryData.step2,
              [companyData?.company_id]: {
                ...(temporaryData?.step2?.[companyData.company_id] as any),
                cadence: value,
              },
            },
            companiesData,
            firstStepData: (temporaryData as any).step1,
            formData: formData as any,
            date: moment().format(ISO_DATE_FORMAT),
          })
        );
      } else {
        dispatch(
          bulkActions.updateTemporaryData({
            step2: {
              ...temporaryData?.step2,
              [companyData?.company_id]: {
                ...(temporaryData?.step2?.[companyData.company_id] as any),
                cadence: value,
              },
            } as any,
          })
        );
        dispatch(
          bulkActions.autoSave({
            step: step + 1,
            grid,
            queue: companyData.company_id,
            companiesById: {
              ...temporaryData?.step2,
              [companyData?.company_id]: {
                ...(temporaryData?.step2?.[companyData.company_id] as any),
                cadence: value,
              },
            },
            companiesData,
            firstStepData: (temporaryData as any).step1,
            formData: formData as any,
            date: moment().format(ISO_DATE_FORMAT),
          })
        );
      }
    } else if (step === 0 && !isRedirectedToStep3) {
      dispatch(
        bulkActions.updateTemporaryData({
          step1: { ...(temporaryData as any).step1, cadence: value },
        })
      );

      dispatch(
        bulkActions.autoSave({
          step: step + 1,
          grid,
          queue: companyData.company_id,
          companiesById: companyIds,
          companiesData,
          firstStepData: {
            ...(temporaryData as any).step1,
            cadence: value,
          },
          formData: formData as any,
          date: moment().format(ISO_DATE_FORMAT),
        })
      );
    }
  };

  useEffect(() => {
    if (!valueLoaded) {
      if (step === 0 && (companyIds as any)?.cadence) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueLoaded = true;
        handleChange((companyIds as any)?.cadence as number);
      } else if (
        step === 1 &&
        (temporaryData?.step2?.[companyData.company_id] as any) &&
        'cadence' in (temporaryData?.step2?.[companyData.company_id] as any)
      ) {
        if (
          !(temporaryData?.step2?.[companyData.company_id] as any)?.salesoft_contacts?.length &&
          disabled
        ) {
          setDisabled(false);
        }

        handleChange((temporaryData?.step2?.[companyData.company_id] as any)?.cadence);
      } else if (value === undefined) {
        if (Object.keys(companyData).length) {
          if (!companyData.contacts.length) {
            if (companyIds[companyData.company_id]?.cadence) {
              message.warning(
                "The cadence was deselected because this company doesn't have any contacts"
              );
            }

            valueLoaded = true;
            handleChange('');
          } else if (
            companyIds[companyData.company_id] &&
            'cadence' in companyIds[companyData.company_id]
          ) {
            valueLoaded = true;
            handleChange(companyIds[companyData.company_id]?.cadence as number);
          } else if ((companyIds as any)?.cadence) {
            valueLoaded = true;
            handleChange((companyIds as any)?.cadence as number);
          }

          if (step === 1 && !companyData.contacts.length) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }

          if (step === 0) {
            if (bulkValues.cadence) {
              valueLoaded = true;
              handleChange(bulkValues.cadence as number);
            }
          }
        }
      } else if (
        !(
          'cadence' in companyIds ||
          (companyIds as unknown as Dictionary<number | string>)?.cadence === '' ||
          'newContact' in
            (temporaryData?.step2?.[companyData.company_id] as unknown as Dictionary<number>)
        )
      ) {
        valueLoaded = true;

        handleChange((companyIds as any)?.cadence);
      }
    } else {
      valueLoaded = true;
      handleChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, (companyIds as any)?.cadence]);

  return (
    <>
      <Tooltip title={disabled ? 'This company does not have any contacts' : ''}>
        <SelectWrapper
          showSearch
          placeholder="Select a cadence"
          onChange={handleChange}
          optionFilterProp="children"
          value={value}
          disabled={readOnly || disabled || (companyData.contacts && !companyData.contacts?.length)}
          filterOption={filterOption}
          dropdownMatchSelectWidth={250}
        >
          <Option key="blank" value="">
            Select a cadence
          </Option>
          {options.map(option => (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          ))}
        </SelectWrapper>
      </Tooltip>
      {!!errors.length &&
        errors.map((error, index) => (
          <Typography.Text key={index} type="danger" style={{ display: 'block' }}>
            {error}
          </Typography.Text>
        ))}
    </>
  );
};

export default SelectCadence;
