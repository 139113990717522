import React from 'react';
import { components, OptionProps } from 'react-select';
import { Checkbox as $Checkbox, Tag as AntDTag } from 'antd';
import styled, { css } from 'styled-components';

type OptionFCProps = OptionProps<any> & { highlight?: boolean };

const highlightStyles = css`
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 12px 17px;
  margin-bottom: 8px;
`;

// Tag for EqutyTouch select Options
const Tag = styled(AntDTag)`
  cursor: pointer;
  margin-left: 10px;
  color: gold;
  height: max-content;
`;

/**
 * Option Function Component removing props not used by original Option component.
 */
const OptionFC: React.FunctionComponent<OptionFCProps> = ({ highlight, ...restProps }) => (
  <components.Option {...restProps} />
);

const Option = styled(OptionFC)`
  ${props => props.highlight && highlightStyles};
  align-items: center;

  &.multiselect__option {
    display: flex;
  }

  &.sub-option {
    padding-left: 25px;
  }

  & > span {
    margin-top: -2px;
  }

  .option-value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .option-value-bold {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
  }
`;

const Checkbox = styled($Checkbox)`
  margin: 0 8px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  span.ant-checkbox {
    top: 0;
  }
`;

export default {
  Option,
  Checkbox,
  OptionFC,
  highlightStyles,
  Tag,
};
