import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
// styles
import './styles.scss';
// models
import { CompanyCompetitor } from '@optx/models/Company';
// constants
import { CompanyPageOverviewPendo } from '@optx/constants/pendoActions';
// redux
import { selectors as userSelectors } from '@redux/user/information';
// utils
import { getCompanyProfileURL } from '../../../../utils/routes';
// components
import { CompanyAvatar } from '../../avatar';
import { ScoreTrending } from '../../trending';
import { OpenInNewTabHover } from '../../link';

interface CompetitorCardProps {
  competitor: CompanyCompetitor;
  to: string;
}

const CompetitorCard: React.FC<CompetitorCardProps> = ({ competitor, to }) => {
  const profileURL = getCompanyProfileURL(competitor.company_id);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);

  const isPSGCompany = competitor.is_psg_company;

  return (
    <div className="media-object competitor-card">
      <div className="media-object-section">
        <CompanyAvatar companyName={competitor.company_name} logoUrl={competitor.logo_url} />
      </div>
      <div className="media-object-section text-ellipsis" style={{ flex: 1 }}>
        <OpenInNewTabHover url={profileURL}>
          <Tooltip title={competitor.company_name || undefined}>
            <Link
              className="competitor-card__name text-ellipsis"
              to={profileURL}
              onClick={() =>
                window.pendo.track(CompanyPageOverviewPendo.similarCompanies_profileLink)
              }
            >
              {competitor.company_name}
            </Link>
          </Tooltip>
        </OpenInNewTabHover>
        <a href={to} target="_blank" rel="noreferrer" className="competitor-card__link">
          {competitor.raw_url}
        </a>
      </div>
      <div className="media-object-section score-trending-wrapper">
        {isPSGCompany ? (
          <span className="is-psg-company">PSG</span>
        ) : (
          <ScoreTrending
            growth={
              defaultOptxScore === 'il'
                ? competitor.il_optx_score_growth
                : competitor.optx_score_growth
            }
            label={defaultOptxScore === 'il' ? 'IL OPTX SCORE' : 'OPTX SCORE'}
            score={defaultOptxScore === 'il' ? competitor.il_optx_score : competitor.score}
            customFontSize="16px"
          />
        )}
      </div>
    </div>
  );
};

CompetitorCard.defaultProps = {
  to: '#',
};

export default CompetitorCard;
