import { useEffect } from 'react';
import { findDOMNode } from 'react-dom';

/**
 * Custom hook that automatically focuses an input element within a given DOM node.
 *
 * @param {React.RefObject<HTMLElement>} ref - A React ref object pointing to a DOM element.
 * @param {boolean} shouldFocus - A boolean indicating whether the input should be focused.
 *
 * @example
 * const checkboxRef = useRef<HTMLElement | null>(null);
 * useAutoFocusCheckbox(checkboxRef, true);
 */
function useAutoFocusCheckbox(ref: React.RefObject<HTMLElement>, shouldFocus: boolean) {
  useEffect(() => {
    const domNode = findDOMNode(ref.current) as HTMLElement;

    if (domNode) {
      setTimeout(() => {
        const inputElement = domNode.querySelector('input') as HTMLElement;

        if (inputElement && shouldFocus) {
          inputElement.focus({ preventScroll: true });
        }
      }, 100);
    }
  }, [ref, shouldFocus]);
}

export default useAutoFocusCheckbox;
