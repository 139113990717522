import React, { useCallback, useEffect, useMemo } from 'react';
import { Card, Select, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
import { CompanyPageRelationshipManagement } from '@constants/pendoActions';
// utils
import { mapOptionsToLabeledValues } from '@components/common/select/Select/PrefixedSelectionItem';
// redux
import { SearchTouchesPayload } from '@optx/redux/company/touches/search/interfaces';
import {
  actions as touchesFiltersActions,
  selectors as touchesFiltersSelectors,
} from '@redux/company/touches/filters';
import {
  selectors as touchSearchSelectors,
  actions as touchSearchActions,
} from '@redux/company/touches/search';
import { selectors as profileSelectors } from '@redux/company/profile';
import { selectors as userSelectors } from '@redux/user/information';
// components
import TouchesSummary from './TouchesSummary';
import TouchesTimeline from './TouchesTimeline';
import TouchesIntro from './TouchesIntro';
// styles
import './styles.scss';

interface TouchesProps {
  activeProfileKey: string;
}

const valueToTrackMapping = {
  '*': CompanyPageRelationshipManagement.equityTouchHistory_touchType_all,
  Call: CompanyPageRelationshipManagement.equityTouchHistory_touchType_call,
  'E-mail': CompanyPageRelationshipManagement.equityTouchHistory_touchType_email,
  'Internal Note': CompanyPageRelationshipManagement.equityTouchHistory_touchType_internalNote,
  'Stage Modified': CompanyPageRelationshipManagement.equityTouchHistory_touchType_stageModified,
};

const Touches: React.FC<TouchesProps> = ({ activeProfileKey }) => {
  const company = useSelector(profileSelectors.getProfile) as CompanyProfile;
  const currentInitiator = useSelector(touchesFiltersSelectors.getCurrentInitiator);
  const currentTouchType = useSelector(touchesFiltersSelectors.getCurrentTouchType);
  const dispatch = useDispatch();
  const fetchTouchesFilters = useCallback(
    (companyId: number) => dispatch(touchesFiltersActions.fetchTouchesFilters(companyId)),
    [dispatch]
  );

  const isInET = company.is_in_et;

  // search data
  const pagination = useSelector(touchSearchSelectors.getPagination);
  const touchesCompanyId = useSelector(touchSearchSelectors.getCompanyId);

  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);

  const fetchTouches = useCallback(
    (payload: SearchTouchesPayload) => dispatch(touchSearchActions.fetchTouches(payload)),
    [dispatch]
  );

  useEffect(() => {
    if (company.company_id !== touchesCompanyId) {
      fetchTouchesFilters(company.company_id);

      fetchTouches({
        companyId: company.company_id,
        pagination: { ...pagination, pageNumber: 1 },
      });
    }
  }, [company.company_id, touchesCompanyId, pagination, fetchTouchesFilters, fetchTouches]);

  // infinite scroll
  const loadingSearch = useSelector(touchSearchSelectors.isLoading);
  const hasMore = useSelector(touchSearchSelectors.hasMore);

  const handleLoadMore = () => {
    if (company.company_id === touchesCompanyId) {
      fetchTouches({
        companyId: company.company_id,
        pagination: { ...pagination, pageNumber: pagination.pageNumber + 1 },
        initiatorId: currentInitiator,
        touchType: currentTouchType,
      });
    }
  };

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading: loadingSearch,
    hasNextPage: activeProfileKey === CompanyProfileTabs.RELATIONSHIP_MANAGEMENT && hasMore,
    onLoadMore: handleLoadMore,
  });

  // filter dropdowns
  const touchTypes = useSelector(touchesFiltersSelectors.getTypesOptions);
  const initiators = useSelector(touchesFiltersSelectors.getInitiatorOptions);

  const handleTypeChange = (value: string) => {
    const trackAction = valueToTrackMapping[value as keyof typeof valueToTrackMapping];

    if (trackAction) {
      window.pendo.track(trackAction);
    }

    fetchTouches({
      companyId: company.company_id,
      pagination: { ...pagination, pageNumber: 1 },
      touchType: value,
      initiatorId: currentInitiator,
    });
  };

  function handleInitiatorChange(value: string) {
    window.pendo.track(CompanyPageRelationshipManagement.equityTouchHistory_createdBy);
    fetchTouches({
      companyId: company.company_id,
      pagination: { ...pagination, pageNumber: 1 },
      initiatorId: value,
      touchType: currentTouchType,
    });
  }

  const typesOptions = useMemo(
    () => mapOptionsToLabeledValues('Touch Type: ', touchTypes),
    [touchTypes]
  );

  const initiatorsOptions = useMemo(
    () => mapOptionsToLabeledValues('Created By: ', initiators),
    [initiators]
  );

  return (
    <div ref={infiniteRef}>
      <Card
        className="ant-card--touches profile-box"
        title="Equity Touch History"
        extra={isInET && hasTouchAccess && <TouchesIntro />}
      >
        <Card.Grid style={{ width: '100%' }} hoverable={false}>
          <Row style={{ marginBottom: 0 }} gutter={[9, 8]} justify="end">
            <TouchesSummary />
          </Row>
        </Card.Grid>
        <Card.Grid style={{ width: '100%' }} hoverable={false}>
          <Row style={{ marginBottom: 0 }} gutter={[9, 8]} justify="end">
            {!!touchTypes.length && (
              <Col>
                <Select onChange={handleTypeChange} value={currentTouchType}>
                  {typesOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            )}
            {!!initiators.length && (
              <Col>
                <Select
                  onChange={handleInitiatorChange}
                  value={currentInitiator}
                  style={{ minWidth: '170px' }}
                >
                  {initiatorsOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
        </Card.Grid>
        <Card.Grid className="ant-card-grid--touches" style={{ width: '100%' }} hoverable={false}>
          <TouchesTimeline company={company} />
        </Card.Grid>
      </Card>
    </div>
  );
};

export default Touches;
