import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions } from '@redux/favorite-lists/lists';
import { getRouteAlias } from '@optx/utils/routes';
import { RouteAliases } from '@optx/models/routes';
import { getQuery } from '@redux/favorite-lists/lists/selectors';

const useFetchFavoriteLists = () => {
  const pageAlias: RouteAliases | null = getRouteAlias();
  const showEmptyLists = useSelector(selectors.showEmptyLists);
  const dispatch = useDispatch();
  const query = useSelector(getQuery);

  const isAdvancedSearchPage = pageAlias === 'advancedSearch';
  const isWatchListPage = pageAlias === 'watchList';
  const isAllReportsPage = pageAlias === 'allReports';
  const isTouchManagementPage = pageAlias === 'touchManagement';

  useEffect(() => {
    if (showEmptyLists === null) {
      if (isWatchListPage) {
        dispatch(
          actions.fetchFavoriteLists({ query: query || '', fetchAll: true, showEmptyLists: false })
        );
      } else {
        !isAdvancedSearchPage &&
          !isTouchManagementPage &&
          dispatch(
            actions.fetchFavoriteLists({ query: query || '', fetchAll: true, showEmptyLists: true })
          );
      }
    } else if (showEmptyLists && isWatchListPage) {
      dispatch(
        actions.fetchFavoriteLists({ query: query || '', fetchAll: true, showEmptyLists: false })
      );
    } else if (
      !showEmptyLists &&
      !isWatchListPage &&
      !isAdvancedSearchPage &&
      !isAllReportsPage &&
      !isTouchManagementPage
    ) {
      dispatch(
        actions.fetchFavoriteLists({ query: query || '', fetchAll: true, showEmptyLists: true })
      );
    }

    // only on watchlist page we call different endpoint by query.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchListPage, showEmptyLists]);
};

export default useFetchFavoriteLists;
