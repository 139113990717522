import React, { FC, useEffect, useState } from 'react';
import { TrendingLine } from '@optx/components/common/charts';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
// models
import Company from '@models/Company';
import { ChartBarIntervalKeys } from '@optx/models/charts/data';
// utils
import { getIsPsgCompany } from '@optx/utils/company';
// redux
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as userSelectors } from '@redux/user/information';

type TableCellOPTXScoreProps = {
  company: Company;
  isNewScore?: boolean;
};

const renderCompanyOPTXScoreTrendline = (
  company: Company,
  defaultOPTXScore: string,
  period: ChartBarIntervalKeys,
  isNewScore: boolean = false
) => {
  let points: (number | null)[] = [];

  if (isNewScore) {
    points = company?.optx_score_v3_trends?.[period] || [];
  } else if (defaultOPTXScore === 'il') {
    points = company.il_optx_score_trends?.[period] || [];
  } else {
    points = company?.optx_score_trends?.[period] || [];
  }

  return <TrendingLine points={points} color={isNewScore ? '#219653' : '#1890FF'} />;
};

const TableCellOPTXScore: FC<TableCellOPTXScoreProps> = ({ company, isNewScore }) => {
  const [period, setPeriod] = useState<ChartBarIntervalKeys>('1Y');
  const { pathname } = useLocation();
  const myCompaniesTrending = useSelector(searchSelectors.getTrending('myCompanies'));
  const searchTrending = useSelector(searchSelectors.getTrending('advancedSearch'));
  const watchlistTrending = useSelector(searchSelectors.getTrending('lists'));
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const companyOutReachTrending = useSelector(searchSelectors.getTrending('outreach'));

  const score = isNewScore
    ? company.score_v3
    : defaultOptxScore === 'il'
    ? company.il_optx_score
    : company.score;

  useEffect(() => {
    if (pathname.startsWith('/user-lists') || pathname.startsWith('/ss-lists')) {
      setPeriod(watchlistTrending);
    } else if (pathname.startsWith('/my-companies')) {
      setPeriod(myCompaniesTrending);
    } else if (pathname.startsWith('/all-reports')) {
      setPeriod(companyOutReachTrending);
    } else {
      setPeriod(searchTrending);
    }
  }, [companyOutReachTrending, myCompaniesTrending, pathname, searchTrending, watchlistTrending]);

  const handleNanOptxScore = (score: number) => {
    if (typeof score !== 'number') {
      return 'N/A';
    }

    return Number(score);
  };

  if (getIsPsgCompany(company))
    return (
      <Tooltip title="PSG Company">
        <span>PSG Company</span>
      </Tooltip>
    );

  return (
    <span className="d-flex align-items-center">
      <span className="flex-grow-1">{handleNanOptxScore(score)}</span>

      {renderCompanyOPTXScoreTrendline(company, defaultOptxScore, period, isNewScore)}
    </span>
  );
};

export default React.memo(TableCellOPTXScore);
