import React from 'react';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// models
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

export const TableCellInvestorNames = (value: any) => {
  if (!value || value === DEFAULT_CELL_VALUE || value?.length === 0) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  return (
    <TruncateTooltip title={value.join('; ')} placement="topLeft" maxTooltipWidth="112px">
      {value.join('; ')}
    </TruncateTooltip>
  );
};

export default TableCellInvestorNames;
