import React, { memo } from 'react';

interface ChartLabel {
  label: string;
  value: number;
  color: string;
}

interface ChartLegendProps {
  data: ChartLabel[];
}

const ChartLegend: React.FC<ChartLegendProps> = ({ data }) => {
  return (
    <div className="chart-legends">
      {data.map((item: ChartLabel) => {
        return (
          <div className="chart-legend" key={item.label}>
            <span className="count" style={{ backgroundColor: item.color }}>
              {item.value}
            </span>
            <div className="label" style={{ backgroundColor: `${item.color as string}1a` }}>
              <span className="name">{item.label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(ChartLegend);
