import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
// models
import { CompanyProfile } from '@models/Company';
import { CompanyUserContact } from '@models/api/contacts';
// utils
import { getEmailsFromString } from '@utils/equityTouch';
// components
import { EquityTouchEditContact } from '@features/company/add-to-equity-touch';
// redux
import { selectors as bulkActionsSelectors } from '@features/bulk-actions';

const TableCellActions = (value: string, row: Partial<CompanyUserContact>, index: number) => {
  const companyData = useSelector(bulkActionsSelectors.equityTouch.getCompanyData);
  const [visible, setVisible] = useState(false);
  const [contactData, setContactData] = useState<Partial<CompanyUserContact> | undefined>();

  const handleClick = () => {
    setVisible(true);
    setContactData({
      ...row,
      emails: getEmailsFromString(row.email),
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit-contact-menu" onClick={handleClick}>
        Edit Contact
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={menu}
        placement="bottomRight"
        disabled={(companyData as CompanyProfile).is_in_et}
      >
        <EllipsisOutlined className="dropdown-dot__icon" />
      </Dropdown>
      <EquityTouchEditContact
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        contactData={contactData}
      />
    </>
  );
};

export default TableCellActions;
