import React, { useEffect, useRef, useCallback } from 'react';
import { Space, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
// models
import { TableChangeCallback } from '@optx/models/table/antd-table';
// constants
import { LISTS_ROUTES as routes } from '@constants/routes';
// utils
import { mapSorting } from '@utils/table/sorting';
// redux
import {
  selectors as searchesSelectors,
  actions as searchesActions,
} from '@redux/company/saved-searches';
import { selectors as listsAndSearchesSelectors } from '@features/lists-and-searches';
// hooks
import { useScrollPosition } from '@features/scroll-history';
// components
import { MainHeader } from '@components/common/layout';
import { companyColumns } from '@components/common/table/TableSavedSearches/columns';
import SavedSearchesSearch from './SavedSearchesSearch';
import ClearSearchButton from './ClearSearchButton';
import FilterTags from '../FilterTags';
import { GridPagination } from '@features/grid/pagination/components';

const CompanySavedSearchesTable: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(searchesSelectors.getSavedSearches);
  const query = useSelector(searchesSelectors.getQuery);
  const shouldFetch = useSelector(searchesSelectors.getShouldFetch);
  const pageNumber = useSelector(searchesSelectors.getPageNumber);
  const sortBy = useSelector(searchesSelectors.getSortBy);
  const pageSize = useSelector(listsAndSearchesSelectors.pageSize);
  const totalItemsCount = useSelector(searchesSelectors.getTotal);
  const tableRef = useRef<HTMLDivElement>(null);
  const columns = useSelector(searchesSelectors.getGridColumns(companyColumns));

  const onChange: TableChangeCallback<any> = (pagination, filters, sorter, extra) => {
    switch (extra.action) {
      case 'sort': {
        const newSorting = mapSorting(sorter, sortBy);
        let sortingCopy = { ...(newSorting as any) };

        if (sortingCopy?.sortBy?.[0]?.id === 'date') {
          sortingCopy = {
            sortBy: [{ id: 'created_at', desc: sortingCopy?.sortBy?.[0]?.desc }],
          };
        }

        dispatch(searchesActions.sortCompanySearchesLists(sortingCopy.sortBy));
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      dispatch(searchesActions.fetchSavedSearches(query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollPosition(routes.companySavedSearches, tableRef, pageNumber, query, pageSize);

  const handlePageChange = useCallback(
    (pageNumber: number, perPage: number = pageSize) =>
      dispatch(searchesActions.savedCompanySearchPagination({ pageNumber, pageSize: perPage })),
    [dispatch, pageSize]
  );

  return (
    <>
      <MainHeader className="main-header">
        <div className="search-header-content">
          <SavedSearchesSearch className="antd-search-input" autoComplete="off" />
          <FilterTags listType="Save Company Search" />
          <Space size={4} style={{ marginLeft: 'auto' }}>
            {query && <ClearSearchButton />}
          </Space>
        </div>
      </MainHeader>
      <div ref={tableRef}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={onChange}
          bordered
          pagination={false}
          rowKey={'unique_id'}
          showSorterTooltip={false}
          tableLayout="fixed"
          className="overflow-auto search-results legacy-table"
        />
      </div>
      <GridPagination
        onPageChange={handlePageChange}
        totalItemsCount={totalItemsCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </>
  );
};

export default React.memo(CompanySavedSearchesTable);
