import React, { useEffect, useState } from 'react';
import { Col, Typography } from 'antd';
import { Form, Input } from 'formik-antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';

interface InputBankerNameFieldProps {
  field: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const InputBankerNameField: React.FC<InputBankerNameFieldProps> = ({ field, fieldUsedFor }) => {
  const [selected, , setSelected] = useField<string>(field?.id ?? '');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [fieldRequired, ,] = useField<boolean>('banker');

  useEffect(() => {
    if (
      fieldUsedFor !== 'editAllInfo' &&
      (field?.value || field?.value === '') &&
      !selected.value
    ) {
      if (!isEqual(field.value, selected.value)) {
        setSelected.setValue(field.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.value]);

  useEffect(() => {
    if (fieldRequired.value && isDisabled) {
      setIsDisabled(false);
    } else if (!fieldRequired.value && !isDisabled) {
      setSelected.setValue('');
      setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRequired.value]);

  return (
    <Col span={8} className={field.id}>
      <Form.Item name={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <Input
          name={field.id}
          placeholder={field.placeholder}
          disabled={isDisabled}
          value={selected.value ?? ''}
        />
      </Form.Item>
    </Col>
  );
};

export default React.memo(InputBankerNameField);
