import React, { useState } from 'react';
import { Timeline } from 'antd';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
// models
import Company from '@optx/models/Company';
// constants
import { CompanyPageRelationshipManagement } from '@optx/constants/pendoActions';
// redux
import { selectors as userSelectors } from '@redux/user/information';
import { selectors } from '@redux/company/touches/search';
// components
import DangerousInnerHTML from '@optx/components/common/DangerousInnerHTML';
import TimelineTouchItem from '@optx/features/company-touches/components/TimelineTouchItem';
import TimelineIntervalLabel from '@features/company-touches/components/TimelineIntervalLabel';
// styles
import './style.scss';

interface TouchesTimeline {
  company: Company;
}

const TouchesTimeline: React.FC<TouchesTimeline> = ({ company }) => {
  const touches = useSelector(selectors.getTouches);
  const hasTouchAccess = useSelector(userSelectors.getHasAccessToTouches);
  const [activeKey, setActiveKey] = useState<string | string[]>('');

  if (!touches) {
    return <DangerousInnerHTML type="p" innerHTML="" />;
  }

  const {
    touch_history: {
      last_six_weeks: lastSixWeeks,
      over_six_months: overSixMonths,
      over_six_weeks: overSixWeeks,
      scheduled,
    },
    number_of_touches: {
      last_six_weeks: lastSixWeeksCount,
      over_six_months: overSixMonthsCount,
      over_six_weeks: overSixWeeksCount,
      scheduled: scheduledCount,
    },
  } = touches;

  // ISSUE: seems there is a bug and Timeline.Item has to be direct children of Timeline otherwise the label isn't
  // displayed. List can't be mapped inside Fragment or any other element :(.
  return (
    <Timeline mode="left" className="ant-timeline-label">
      {scheduled.map((touch, index) => {
        const futureTouchClassName = classnames(
          'timeline-item--touch',
          'timeline-item--touch-future',
          {
            bordered: index === scheduled.length - 1,
          }
        );

        return (
          <TimelineTouchItem
            key={index}
            touch={touch}
            touchesCount={scheduledCount}
            index={index}
            setActiveKey={setActiveKey}
            activeKey={activeKey}
            timelineInterval="SCHEDULED TOUCHES"
            company={company}
            colorInterval="#87919f"
            hasTouchAccess={hasTouchAccess}
            className={futureTouchClassName}
            renderCount={() => `${scheduledCount} scheduled`}
            actionPendo={
              CompanyPageRelationshipManagement.equityTouchHistory_previousTouches_touchpoint
            }
          />
        );
      })}
      {!scheduled.length && (
        <Timeline.Item
          className="timeline-item--touch  timeline-item--touch-future  timeline-item--touch-no-future bordered"
          label={<TimelineIntervalLabel title="SCHEDULED TOUCHES" renderCount={() => ''} />}
        >
          No Future Touch Scheduled
        </Timeline.Item>
      )}
      {lastSixWeeks.map((touch, index) => (
        <TimelineTouchItem
          key={index}
          touch={touch}
          touchesCount={lastSixWeeksCount}
          index={index}
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          timelineInterval="LAST 6 WEEKS"
          company={company}
          colorInterval="#13C2C2"
          hasTouchAccess={hasTouchAccess}
          className="ant-timeline--past"
          actionPendo={
            CompanyPageRelationshipManagement.equityTouchHistory_previousTouches_touchpoint
          }
        />
      ))}
      {overSixWeeks.map((touch, index) => (
        <TimelineTouchItem
          key={index}
          touch={touch}
          touchesCount={overSixWeeksCount}
          index={index}
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          timelineInterval="MORE THAN 6 WEEKS"
          company={company}
          colorInterval="#FA8C16"
          hasTouchAccess={hasTouchAccess}
          className="ant-timeline--past"
          actionPendo={
            CompanyPageRelationshipManagement.equityTouchHistory_previousTouches_touchpoint
          }
        />
      ))}
      {overSixMonths.map((touch, index) => (
        <TimelineTouchItem
          key={index}
          touch={touch}
          touchesCount={overSixMonthsCount}
          index={index}
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          timelineInterval="MORE THAN 6 MONTHS"
          company={company}
          colorInterval="#FA541C"
          hasTouchAccess={hasTouchAccess}
          className="ant-timeline--past"
          actionPendo={
            CompanyPageRelationshipManagement.equityTouchHistory_previousTouches_touchpoint
          }
        />
      ))}
    </Timeline>
  );
};

export default TouchesTimeline;
