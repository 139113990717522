import { call, takeLatest, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { Dictionary, uniqBy } from 'lodash';
import { AxiosResponse } from 'axios';
// models
import { CompanyUserContact, ContactPhone, PhoneType } from '@models/api/contacts';
import { SuccessErrorCallback } from '@models/callback';
import { LogicOption, SelectOption } from '@models/Option';
import {
  UpdateCompanyETData,
  EquityTouchContactPost,
  EquityTouchSubmitField,
  GroupsSubmitField,
  WatchlistsSubmitField,
  EquityTouchCheck,
} from '@models/equityTouch';
import { PreselectedFilter } from '@optx/models/filters';
// constants
import { PRESENT_DATE_FORMAT, ISO_DATE_FORMAT } from '@optx/constants/format/date';
// services
import { CompanyService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { actions as loaderActions } from '@features/custom-global-loader';
import { selectors as companyEditSelectors } from '@components/feature/company-individual-edit/state';
import { selectors as filterSelectors } from '@features/grid/filter';
import { actions as listActions } from '@redux/lists/details';
import { actions as userInformationActions } from '@redux/user/information';
import { getErrorMessage } from '@utils/api/errors';
import {
  selectors as primaryContactsSelectors,
  actions as primaryContactsActions,
} from '../primary-contacts';
import * as actions from './actions';
import * as selectors from './selectors';

export function* equityTouchAvailabilitySaga(
  action: PayloadAction<string, any, SuccessErrorCallback>
) {
  const { payload: id, meta: callback } = action;

  try {
    const res: AxiosResponse<Dictionary<EquityTouchCheck>> = yield call(
      CompanyService.checkEquityTouchAvailability,
      id
    );

    if (res.data) {
      callback && callback(res.data);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to check company in Equity Touch!';
    NotificationService.error(errorMessage);
  }
}

export function* fetchEquityTouchFormDataSaga(
  action: PayloadAction<number, any, SuccessErrorCallback>
) {
  const { payload: id, meta: callback } = action;

  try {
    // @ts-ignore
    const res = yield call(CompanyService.fetchEquityTouchFormData, { companyId: id });

    if (res.data) {
      yield put(actions.fetchEquityTouchFormDataSuccess({ ...res.data, companyId: id }));
      callback && callback(res.data);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch company from Equity Touch!';
    yield put(actions.fetchEquityTouchFormDataFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* addtoEquityTouchSaga(
  action: PayloadAction<
    {
      id: string;
      formData: Dictionary<
        | string
        | EquityTouchSubmitField
        | GroupsSubmitField
        | EquityTouchContactPost[]
        | WatchlistsSubmitField[]
      >;
      formValues: Dictionary<string | number | boolean | number[] | string[] | null | SelectOption>;
    },
    any,
    SuccessErrorCallback
  >
) {
  const { meta: callback } = action;
  const postData = action.payload;
  const contacts: EquityTouchContactPost[] = [];
  const editedContactIds: number[] = [];
  const primaryContacts: CompanyUserContact[] = yield select(
    primaryContactsSelectors.getPrimaryContacts
  );
  const selectedContacts: number[] = yield select(selectors.getSelectedContacts);
  // @ts-ignore
  const phoneIds = [...(yield select(companyEditSelectors.companyIndividualEdit.phoneOptions))];

  // include the contact data that has been selected from the contacts table
  if (selectedContacts && selectedContacts.length) {
    selectedContacts.forEach((contactId: number) => {
      const contactData = primaryContacts.find(contact => contact.person_id === contactId);
      let phones: ContactPhone[] = [];

      if (contactData?.additional_phones && contactData?.additional_phones.length) {
        phones = [...contactData.additional_phones];
      }

      if (contactData?.phone && contactData?.phone_type) {
        const phoneId = (phoneIds as SelectOption[]).find(
          phoneItem => phoneItem.label === contactData.phone_type
        );

        phones.push({
          phone_number: contactData.phone,
          phone_type: contactData.phone_type as PhoneType,
          is_primary: false,
          phone_type_id: phoneId ? Number(phoneId.value) : undefined,
        });
      }

      contacts.push({
        first_name: contactData!.first_name,
        last_name: contactData!.last_name,
        title: contactData!.title,
        phones: phones,
        emails: contactData!.email,
        linkedin: contactData!.linkedin,
        primary_contact: contactData!.primary_contact,
        tenure_start_date: contactData!.tenure_start_date
          ? moment(contactData!.tenure_start_date).format(ISO_DATE_FORMAT)
          : null,
        tenure_end_date:
          contactData!.tenure_end_date && contactData!.tenure_end_date !== PRESENT_DATE_FORMAT
            ? moment(contactData!.tenure_end_date).format(ISO_DATE_FORMAT)
            : contactData!.tenure_end_date === PRESENT_DATE_FORMAT
            ? contactData!.tenure_end_date
            : null,
        person_id: contactData!.person_id as number,
      });

      if (contactData!.is_edited) {
        editedContactIds.push(contactData!.person_id as number);
      }
    });
  }

  if (contacts.length) {
    postData.formData = {
      ...postData.formData,
      contacts: uniqBy(contacts, (contact: EquityTouchContactPost) => contact.person_id),
    };
  } else {
    postData.formData = { ...postData.formData, contacts: [] };
  }

  try {
    const res: AxiosResponse<any> = yield call(CompanyService.addEquityTouch, postData);

    if (res.data) {
      if (res.data.errorType !== null) {
        callback && callback({ url: res.data.url, hasError: true });
        let errorMessage =
          'During operation one ore more errors occurred: Failed to add company to Equity Touch!';

        if (res.data.errorType === 'add_contact_failed') {
          errorMessage = 'During operation one ore more errors occurred: Failed to add contact';
        } else if (res.data.errorType === 'contact_custom_fields_failed') {
          errorMessage =
            'During operation one ore more errors occurred: Failed to add custom field for contact';
        } else if (res.data.errorType === 'add_company_group_failed') {
          errorMessage =
            'During operation one ore more errors occurred: Failed to add company group';
        }

        NotificationService.error(errorMessage);
      } else {
        callback && callback({ url: res.data.url });
      }

      const filters: Dictionary<PreselectedFilter> = yield select(
        filterSelectors.getFilter('advancedSearch')
      );

      // @ts-ignore
      const stageOptions = yield select(companyEditSelectors.companyIndividualEdit.stageOptions);
      // @ts-ignore
      const rankOptions = yield select(companyEditSelectors.companyIndividualEdit.rankOptions);
      // @ts-ignore
      const sectorOptions = yield select(companyEditSelectors.companyIndividualEdit.sectorOptions);
      // @ts-ignore
      const subSectorOptions = yield select(
        // @ts-ignore
        companyEditSelectors.companyIndividualEdit.subSectorOptions
      );
      // @ts-ignore
      const companyOwnerOptions = yield select(
        // @ts-ignore
        companyEditSelectors.companyIndividualEdit.companyOwnerOptions
      );

      const updatePayload: UpdateCompanyETData = {
        companyId: Number(action.payload.id),
        formValues: action.payload.formValues,
        contacts,
        fieldsData: {
          stageOptions,
          rankOptions,
          sectorOptions,
          subSectorOptions,
          companyOwnerOptions,
        },
      };

      // update data in store
      const filterValues = filters.source_tag_filter as Array<LogicOption>;
      const index = filterValues.findIndex(item => item.value === 'et');

      yield put(actions.updateCompanyETData({ updateData: updatePayload, editedContactIds }));

      if (filterValues[index].notIn) {
        yield put(listActions.deleteCompanyInCurrentList(Number(action.payload.id)));
      }
    }
  } catch (error: any) {
    let errorMessage = 'Failed to add company to Equity Touch!';

    // adding company and contacts to equity touch are two distinct processes in the backend
    // if company is added but contacts are not, check error object, show the
    // confirmation page, but also show error for contacts
    if (error && error.response) {
      const errorData = error.response.data.errors[Object.keys(error.response.data.errors)[0]];

      if (errorData.errorType && errorData.errorType === 'contact_failed') {
        callback && callback({ url: errorData.url, hasError: true });
        errorMessage = errorData.msgs;
      } else {
        errorMessage = errorData;
        callback && callback('');
      }
    }

    NotificationService.error(errorMessage);
  }
}

export function* saveContactSaga(
  action: PayloadAction<Partial<CompanyUserContact>, any, VoidFunction>
) {
  const { payload: payloadData, meta: callback } = action;
  yield put(loaderActions.toggle({ loading: true }));

  try {
    // @ts-ignore
    const res = yield call(CompanyService.editPrimaryContact, payloadData);

    if (res.data) {
      payloadData.tenure_start_date = res.data.tenure_start_date;
      payloadData.tenure_end_date = res.data.tenure_end_date;

      yield put(primaryContactsActions.updatePrimaryContacts(payloadData));

      callback && callback();
    }
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to edit primary contact!');
    yield put(actions.saveContactFail(message));
    NotificationService.error(message);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

export function* autoSaveSaga(
  action: PayloadAction<Dictionary<
    string | number | boolean | number[] | string[] | null | SelectOption
  > | null>
) {
  try {
    yield put(userInformationActions.updateUserSettings({ et_form_auto_save: action.payload }));
  } catch (error: any) {
    const message = getErrorMessage(error, 'Failed to edit primary contact!');
    NotificationService.error(message);
  }
}

export default function* equityTouchSaga() {
  yield takeLatest(actions.equityTouchAvailability, equityTouchAvailabilitySaga);
  yield takeLatest(actions.fetchEquityTouchFormData, fetchEquityTouchFormDataSaga);
  yield takeLatest(actions.addToEquityTouch, addtoEquityTouchSaga);
  yield takeLatest(actions.saveContact, saveContactSaga);
  yield takeLatest(actions.autoSave, autoSaveSaga);
}
