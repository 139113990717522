import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
// utils
import { styledColors } from '@optx/utils/theme/colors';

const DropdownWrapper = styled(Dropdown)`
  text-align: left;
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.0015);
  border-radius: 2px;
  padding: 0px 5px;
  width: 100%;
  overflow: hidden;

  &.ant-btn:focus .anticon-down,
  &.ant-btn:active .anticon-down,
  .anticon-down {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px !important;
  }
`;

const TriggerWrapper = styled.div`
  color: ${styledColors.gray8};
  display: flex;
  position: relative;

  .rc-overflow {
    align-items: center;
  }
  .rc-overflow-item {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:nth-last-child(2) {
      div .comma {
        display: none;
      }
    }
  }

  .option-label {
    margin: 0 8px 0 0;
    padding: 4px 0;
    display: inline;
  }

  .more-label {
    margin: 0 2px 0 0px;
    padding: 4px 8px;
  }
`;

const MenuWrapper = styled(Menu)`
  width: 400px;
  padding: 10px 20px;

  .ant-card-meta-detail {
    overflow: visible;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .company_owner_id--multiselect,
  .stage--multiselect {
    width: 260px;
    margin-right: 135px;
  }

  .ant-card-bordered {
    border: 0;
  }

  .menu-buttons-wrapper {
    padding: 10px 0;

    button {
      margin-right: 8px;
    }
  }

  .touch--multiselect-async-wrapper {
    width: 100%;
  }

  .multiselect-async-creatable-wrapper {
    width: auto;
    margin-right: 160px;
  }

  .touch--multiselect-async {
    .multiselect__menu {
      position: absolute;
      bottom: 100%;
      top: auto;
      margin: 0;
    }

    .multiselect__control,
    .multiselect__value-container {
      height: 32px;
      min-height: 32px;
    }

    .anticon-search {
      margin-top: -3px;
    }
  }
`;

export const Styled = {
  DropdownWrapper,
  TriggerWrapper,
  MenuWrapper,
};
