import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { uniqBy } from 'lodash';
import moment from 'moment';
// constants
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { contactFilterOption, reorderPrimaryContact } from '@utils/select/select';
import { validateContactLimit } from '@optx/utils/contact';
// redux
import { selectors as primaryContactsSelectors } from '@redux/company/primary-contacts';
import {
  getFormValues,
  getData,
  getTemporaryFormValues,
} from '@redux/company/equity-touch/selectors';
import { actions as userActions } from '@redux/user/information';
import {
  updateTemporaryFormValues,
  updateEquityTouchSelectedSalesLoftContacts,
} from '@redux/company/equity-touch/actions';
import { actions as salesloftActions } from '@redux/contacts/salesloft';
import ContactOptionIcon from '@shared/view/molecules/contacts/ContactOptionIcon';
import ContactOption from '@optx/shared/view/molecules/contacts/ContactOption';
import Styled from '@optx/shared/view/molecules/Select/Styled/ContactSelectWrapper.styled';
import ContactTags from '@optx/shared/view/molecules/contacts/ContactTags';

const { Option } = Select;

const SelectSalesLoftContacts: React.FC = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState<number[]>([]);
  const temporaryFormValues = useSelector(getTemporaryFormValues);
  const contacts = useSelector(primaryContactsSelectors.getPrimaryContacts);
  const isLoadingContacts = useSelector(primaryContactsSelectors.isLoading);
  const formData = useSelector(getData);
  const formValues = useSelector(getFormValues);

  const isAddEquityTouch = window.location.href.includes('add-to-equity-touch');

  useEffect(() => {
    if (!isLoadingContacts) {
      if (formValues?.salesloft_contacts) {
        handleChange(
          Array.isArray(formValues?.salesloft_contacts)
            ? formValues?.salesloft_contacts
            : ([formValues.salesloft_contacts] as number[]),
          false
        );
      }

      if (!formValues?.salesloft_contacts && contacts) {
        const getPrimaryContactsIds = contacts.reduce((acc: number[], item) => {
          if (item.primary_contact) {
            acc.push(item.person_id as number);
          }

          return acc;
        }, []);
        handleChange(
          Array.isArray(getPrimaryContactsIds)
            ? getPrimaryContactsIds
            : ([getPrimaryContactsIds] as number[]),
          false
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formValues?.salesloft_contacts, contacts]);

  const handleChange = (value: any, shouldUpdateUserSettings = true) => {
    if (validateContactLimit(value?.length)) {
      dispatch(salesloftActions.salesloftSelectContacts(value));
      dispatch(updateEquityTouchSelectedSalesLoftContacts(value as number[]));
      setValue(reorderPrimaryContact(value, contacts));
    }

    if (isAddEquityTouch) {
      dispatch(
        updateTemporaryFormValues({
          ...temporaryFormValues,
          salesloft_contacts: value,
        })
      );

      if (shouldUpdateUserSettings) {
        dispatch(
          userActions.updateUserSettings({
            et_form_auto_save: {
              values: {
                ...(temporaryFormValues as any),
                contacts: uniqBy(
                  temporaryFormValues.contacts as number[],
                  (contact: number) => contact
                ),
                salesloft_contacts: value,
              },
              formData: formData as any,
              date: moment().format(ISO_DATE_FORMAT),
            },
          } as Partial<any>)
        );
      }
    }
  };

  const getDisabledStatus = () => {
    if (
      temporaryFormValues &&
      Array.isArray(temporaryFormValues?.salesloft_contacts) &&
      temporaryFormValues?.salesloft_contacts.length
    )
      return false;

    return (
      (temporaryFormValues && temporaryFormValues?.cadence === '') ||
      (temporaryFormValues && !('cadence' in temporaryFormValues))
    );
  };

  return (
    <>
      <Styled.SelectWrapper
        showArrow
        disabled={getDisabledStatus()}
        placeholder="Select contacts"
        onChange={(val: any) => handleChange(val)}
        optionFilterProp="children"
        filterOption={contactFilterOption}
        mode="multiple"
        tagRender={props => <ContactTags {...props} />}
        menuItemSelectedIcon={ContactOptionIcon}
        $verticalAlign
        $isCell={false}
        dropdownMatchSelectWidth={250}
        value={value}
        className="ant-select-customize-input"
      >
        {contacts.map(contact => (
          <Option
            key={contact.person_id as number}
            value={contact.person_id as number}
            className="contact-option"
          >
            <ContactOption contact={contact} />
          </Option>
        ))}
      </Styled.SelectWrapper>
      <Styled.ContactSelectGlobal />
    </>
  );
};

export default SelectSalesLoftContacts;
