import { Dictionary } from 'lodash';
import { GroupType } from 'react-select';
// models
import RangeOption, { CheckableRangeOption, SingleCheckboxRangeOption } from './RangeOption';
import Option, {
  SelectOption,
  FormRangeOption,
  FormCheckboxOption,
  DateRangeOption,
  LogicOption,
  FormCheckableRangeOption,
} from './Option';
import { Identifiable } from './Identifiable';
import { SearchSaveResponse } from '@optx/models/search';
import { AlertSettingsResponse } from '@redux/company/filters/interfaces';

export enum FilterGroup {
  Company = 0,
  Contacts,
  EquityTouchCompanies = 6,
  PipelineReport = 8,
  SourcingOutreachSummaryReport = 9,
  Touches = 10,
  ChecklistResearchInsightReport = 11,
}

export type CompanyFilterType =
  | 'range_input'
  | 'date_range'
  | 'date_range_radio'
  | 'checkbox'
  | 'logic_checkbox'
  | 'multi_select'
  | 'single_select'
  | 'multi_select_helper'
  | 'single_select_helper'
  | 'multiple_range_nr'
  | 'multi_text'
  | 'endpoint_query'
  | 'radio'
  | 'filter_group'
  | 'filter_group_georange'
  | 'filter_group_custom';

export type CompanyFilterColumn =
  | 'sw_website_rank'
  | 'sw_website_rank_per_country'
  | 'company_owner_id'
  | 'emp_growth_percent'
  | 'raise_date'
  | 'last_round'
  | 'last_touch_date'
  | 'score'
  | 'score_v3'
  | 'last_rev_update_amount'
  | 'company_tag'
  | 'is_software'
  | 'is_interesting'
  | 'addon'
  | 'addon_bool'
  | 'num_employees'
  | 'location'
  | 'city'
  | 'state'
  | 'country'
  | 'pipeline_rank'
  | 'source_tag_filter'
  | 'capital_raised'
  | 'stage'
  | 'next_steps';

export type CompanyFormRationale =
  | 'is_software_rationales'
  | 'psg_fit_rationales'
  | 'optx_score_rationales'
  | 'optx_score_lower_rationales'
  | 'optx_score_upper_rationales'
  | 'stage_pass_dead_rationales';

export type CompanyAlertSettings = 'deliver' | 'frequency';

export type FilterFormat = 'location';

export interface CheckOption<T = number> extends RangeOption<T> {
  checked?: boolean | string | Array<string>;
}

export interface FilterSource<T = Filter> {
  id: string;
  label: string;
  tag?: string;
  data: Array<T>;
}

export interface FilterPlaceholder {
  min: string;
  max: string;
}

// Histogram structures
export interface Histogram {
  x: number;
  bucket: number;
  max_value: number;
  min_value: number;
  color?: string;
}

export interface HistogramFilterOption {
  min?: number;
  max?: number;
  value?: string;
  name?: string;
  is_null?: number;
}

export interface HistogramSlider {
  data: Array<Histogram>;
  min: number;
  max: number;
}

export type HistogramFilterType = Dictionary<HistogramFilterOption> | string | Dictionary<string>;
export type HistogramType = Dictionary<Array<HistogramFilterType>> | HistogramSlider | number;

export interface HistogramGroup<T = HistogramType> {
  [param: string]: T;
}

export interface BaseFilterDataType {
  name: string;
  value: string;
}

export interface Filter<T extends object = any> {
  index: number;
  column: string;
  type: CompanyFilterType;
  data: null | T;
  custom?: boolean;
  rationales?: Array<BaseFilterDataType>;
  format_name?: FilterFormat;
  label?: string;
  tooltip: string | null;
  preselected?: Array<Dictionary<string>>;
  placeholders: null | FilterPlaceholder;
  endpoint: string;
  depends_on: Array<string>;
  histogram?: Array<Histogram>;
  hasHistogram?: boolean;
  slider_dots?: Array<number>;
  used_by?: Array<string>;
  used_for?: Array<string>;
  groupItems?: Array<Filter<any>>;
  empty_state?: Array<Option>;
  is_location?: boolean;
  is_quick_filter?: boolean;
  range_values?: SelectOption[];
}

export type FilterURLParameter =
  | string
  | RangeOption<number | string>
  | Array<string | RangeOption<number | string>>;

export interface FilterSorting {
  id: string;
  desc: string;
}

export interface ParsedFilterSorting {
  name: string | undefined;
  order: string;
}

// New filter
// generic base filter
export interface BaseFilter<T = any> {
  disabled?: boolean;
  index: number;
  column: string;
  type: CompanyFilterType;
  data: T;
  default_value?: any;
  label: string;
  tooltip: string | null;
  used_by: Array<string>;
  used_for: Array<string>;
  empty_state?: Array<Option>;
  rationales?: Array<BaseFilterDataType>;
  // Model might be different Array<string>...
  preselected?: string | Array<string>;

  // Special on change event.
  onChange?: VoidFunction;
  groupItems?: Array<Filter<any>>;
  is_quick_filter?: boolean;

  // For Location.
  is_location?: boolean;
}

// generic range filter
export interface RangeFilter<T> extends BaseFilter<T> {
  histogram?: Array<Histogram>;
  slider_dots: Array<number>;
  slider_type: string;
  placeholders: FilterPlaceholder;
  allow_multiple: boolean;
  is_formatted: boolean;
  slider_plus: boolean;
  slider_handle_sticks: 'left' | 'right' | null;
  slider_min: number;
  slider_step: number;
  active_for?: Array<string>;
}

export interface MultiRangeNumberFilter extends RangeFilter<Array<RangeOption>> {
  custom: boolean;
}

export interface MultiCheckboxFilter extends BaseFilter<Array<FormCheckboxOption>> {
  custom: boolean;
  placeholders: FilterPlaceholder;
}

export interface MultiSelectFilter<T = any> extends Omit<BaseFilter<T>, 'preselected'> {
  used_by: Array<string>;
  used_for: Array<string>;
  format_name?: FilterFormat;
  placeholders?: string;
  preselected?: Array<Option>;
}

export interface MultiselectAsyncFilter extends BaseFilter<null> {
  endpoint: string;
  depends_on: Array<string>;
  placeholders?: string;
}

export interface MultiselectMappedFilter {
  disabled: undefined;
  entries: undefined;
  label: string;
  value: string;
}

export type FilterGroupDataSectionOption = {
  name: string;
  value: string;
  label?: string;
  checked?: boolean;
  parent?: string;
};
export interface FilterGroupDataSection {
  index: number;
  type: CompanyFilterType;
  column: CompanyFilterColumn;
  label: string | null;
  data: FilterGroupDataSectionOption[] | null;

  // extra
  placeholders: string | FilterPlaceholder;

  // checkbox
  custom: boolean;
  tooltip: string | null;
  used_by: string[];
  used_for: string[];

  // date range
  slider_dots: number[];
  slider_type: string;
  allow_multiple: boolean;
  is_formatted: boolean;
  slider_plus: boolean;
  slider_handle_sticks: 'left' | 'right' | null;
  slider_min: number;
  slider_step: number;
  active_for?: Array<string>;

  // For groups
  reset_for?: Array<string>;
}

export type PreselectedFilter =
  | string
  | CheckableRangeOption<number | string>
  | SingleCheckboxRangeOption
  | SelectOption
  | Array<
      | string
      | SelectOption
      | FormRangeOption
      | FormCheckboxOption
      | CheckableRangeOption
      | SingleCheckboxRangeOption
      | DateRangeOption
      | LogicOption
      | FormCheckableRangeOption
    >
  | [string | null, string | null]
  | undefined
  | boolean;

/**
 * Special filter keys, not visible in filters UI.
 */
export type SpecialFilterKey = 'company_tag' | 'is_platform' | 'diversity' | 'is_psg_company';

export type Filters = {
  [key in SpecialFilterKey]?: string;
} & Dictionary<PreselectedFilter>;

export type FilterOptions =
  | Array<SelectOption>
  | Array<GroupType<SelectOption> | SelectOption>
  | Array<Identifiable<DateRangeOption>>;

/**
 * Search origin keys, to indicate from what page we have "search company" api call.
 */

export type FilterSearchOriginName =
  | 'advanced_search'
  | 'my_companies'
  | 'my_watchlist'
  | 'source_scrub_list'
  | 'profile_page';

export interface FilterSearchOrigin {
  name: FilterSearchOriginName;
  value: number;
}

export interface FilterResponse {
  default_views: SearchSaveResponse[];
  filters: FilterSource[];
  forms: {
    alert_settings: AlertSettingsResponse;
    next_steps: {
      next_step_choices: SelectOption[];
    };
    rationales: Dictionary<SelectOption[]>;
  };
  search_origin: FilterSearchOrigin[];
}
