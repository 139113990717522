import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// models
import { Company, Addon } from '@models/Company';
import { CustomValue } from '@optx/features/company/edit-fields/state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// services
import { CompanyService } from '@optx/services/api';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import {
  selectors as addonSelectors,
  actions as addonActions,
} from '@redux/company/addon-management';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
// components
import Popconfirm from '@optx/shared/view/molecules/Popconfirm/Popconfirm';

interface RemoveAsAddonProps {
  record: Company;
}

const RemoveAsAddon: React.FC<RemoveAsAddonProps> = ({ record }) => {
  const dispatch = useDispatch();
  const { addon, company_id: companyId, addon_sync_et: addonSyncEt } = record;
  const companyProfileName = useSelector(profileSelectors.getCompanyName);
  const relatedListsIds = useSelector(
    addonSelectors.getOriginAdditionalFilterRelatedListsIds(companyId)
  );

  const handleConfirm = () => {
    let payload = {
      companyId,
      value: {
        value: false,
        optionalValue: false,
      } as unknown as CustomValue,
      successMessage: 'Add-on updated successfully!',
      errorMessage: 'Add-on update failed, Server error!',
    };

    if (Object.keys(addon || {}).includes(companyProfileName)) {
      const normalizedAddon = Object.entries(addon as Addon).filter(
        ([key, _]) => key !== companyProfileName
      );

      if (normalizedAddon.length) {
        payload = {
          ...payload,
          value: {
            value: true,
            optionalValue: Object.fromEntries(normalizedAddon),
          },
        };
      }

      dispatch(
        editFieldActions.updateFields({
          companyUrl: record.company_url,
          companyId: companyId,
          value: payload,
          service: CompanyService.updateAddon,
          fieldName: 'Add-on',
          isFromHistory: false,
          canBeDuplicatedLoader: true,
        })
      );

      if (relatedListsIds.length) {
        dispatch(
          addonActions.removeCompanyFromAddonManagement({
            list_id: relatedListsIds,
            company_id: companyId,
          })
        );
      }
    } else if (relatedListsIds.length) {
      dispatch(
        addonActions.removeCompanyFromAddonManagement({
          list_id: relatedListsIds,
          company_id: companyId,
        })
      );
    } else {
      const normalizedAddon =
        addonSyncEt && addonSyncEt !== DEFAULT_EMPTY_VALUE
          ? Object.entries(addon as Addon).filter(([key, _]) => key !== addonSyncEt)
          : [];

      if (normalizedAddon.length) {
        payload = {
          ...payload,
          value: {
            value: true,
            optionalValue: Object.fromEntries(normalizedAddon),
          },
        };
      }

      dispatch(
        editFieldActions.updateFields({
          companyUrl: record.company_url,
          companyId: companyId,
          value: payload,
          service: CompanyService.updateAddon,
          fieldName: 'Add-on',
          isFromHistory: false,
          canBeDuplicatedLoader: true,
        })
      );
    }
  };

  return (
    <Popconfirm title="as Add-on" onConfirm={() => handleConfirm()} action="Remove">
      <div>Remove as Add-on</div>
    </Popconfirm>
  );
};

export default RemoveAsAddon;
