import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Space } from 'antd';
import { useHistory, useLocation } from 'react-router';
// models
import Company from '@optx/models/Company';
import { RouteRedirectStateProps } from '@models/routes';
// constants
import { CompanyPageRelationshipManagement } from '@optx/constants/pendoActions';
// utils
import { resetHistoryState } from '@utils/routes';
// redux
import { selectors as profileSelectors } from '@redux/company/profile';
import {
  actions as modalActions,
  selectors as modalSelectors,
} from '@redux/ui/modals/company-touches';

const TouchesIntro: React.FC = () => {
  const dispatch = useDispatch();
  let { state: locationState, search, pathname } = useLocation<RouteRedirectStateProps | null>();
  const history = useHistory();
  const isModalOpen = useSelector(modalSelectors.isOpen);
  const [firstLoad, setFirstLoad] = useState(true);

  const company = useSelector(profileSelectors.getProfile) as Company;

  const handleCreateTouch = useCallback(() => {
    window.pendo.track(CompanyPageRelationshipManagement.equityTouchHistory_addTouchNote);

    dispatch(
      modalActions.toggleCompanyTouchesDialog({
        companyId: company.company_id,
        companyName: company.company_name,
        company,
      })
    );
  }, [company, dispatch]);

  useEffect(() => {
    setFirstLoad(false);

    if (search.endsWith('addTouch=true') && !locationState) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      locationState = { addTouch: true };
    }
  }, []);

  useEffect(() => {
    if (!isModalOpen && search.endsWith('addTouch=true') && !firstLoad) {
      history.push({
        pathname,
        search: search.split('&')[0],
      });
    }
  }, [isModalOpen, search, firstLoad, history, pathname]);

  useEffect(() => {
    if (locationState?.addTouch) handleCreateTouch();
  }, [locationState, handleCreateTouch]);

  // reset history state data in order to hide add touch modal after refresh
  resetHistoryState(history, locationState, 'addTouch');

  return (
    <Row style={{ marginBottom: 0 }} gutter={[30, { xs: 15, sm: 0 }]}>
      <Col>
        <Space size="middle">
          <Button onClick={handleCreateTouch}>Add Touch Note</Button>
        </Space>
      </Col>
    </Row>
  );
};

export default TouchesIntro;
