import styled, { css } from 'styled-components';
import { Typography } from 'antd';
// components
import { MainContent } from '@optx/components/common/layout';
import { Title } from '@optx/shared/view/atoms/Typography';
import { AnalystsMultiSelect } from '@features/equity-touch-integration/components';

const { Paragraph } = Typography;

const cardsWrappersCommonStyles = css`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const StyledMainContent = styled(MainContent)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 30px 26px;
`;

const StatsWrapper = styled.div<{ isRecentlyAccessedLists?: boolean }>`
  ${props =>
    props.isRecentlyAccessedLists &&
    css`
      width: fit-content !important;
    `}
`;

const SearchCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 1025px) {
    justify-content: start;
  }

  ${cardsWrappersCommonStyles}
`;

const MyCompaniesCardsWrapper = styled.div<{ cardCount: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  ${props =>
    props.cardCount &&
    css`
      @media (min-width: 1025px) {
        justify-content: start;
        width: 100%;
      }
    `}

  ${cardsWrappersCommonStyles}

  @media (width < 1280px) {
    .ant-card:nth-child(n + 5) {
      display: none;
    }
  }

  @media (1280px <= width < 1360px) {
    .ant-card:nth-child(n + 6) {
      display: none;
    }
  }

  @media (1360px <= width < 1680px) {
    .ant-card:nth-child(n + 5) {
      display: none;
    }
  }

  @media (1680px <= width < 1890px) {
    .ant-card:nth-child(n + 6) {
      display: none;
    }
  }

  @media (1890px <= width < 2000px) {
    .ant-card:nth-child(n + 7) {
      display: none;
    }
  }

  @media (2000px <= width) {
    .ant-card:nth-child(n) {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${StatsWrapper},
  ${StyledMainContent} {
    min-width: 1px;
    width: 100% !important;
  }

  @media (max-width: 1359px) {
    flex-wrap: wrap;
  }

  .ant-skeleton {
    width: 100%;
    height: 100%;
  }
`;

const PageTitle = styled(Title)<{ $isGreetingsTitle?: boolean; $isRegularTitle?: boolean }>`
  ${({ $isRegularTitle, $isGreetingsTitle }) => {
    let spaceBelowElement = 0;

    if ($isRegularTitle) {
      spaceBelowElement = 20;
    }

    if ($isGreetingsTitle) {
      spaceBelowElement = 30;
    }

    return css`
      padding-bottom: ${spaceBelowElement}px !important;
    `;
  }};

  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .ant-badge {
    margin-right: 10px;
  }

  .ant-badge-count {
    transform: translate(-29%, -50%);
  }

  &h2.ant-typography {
    margin-top: 1rem;
    margin-bottom: 0.9rem;
  }
`;

const ByAnalystsOrTeamMultiSelect = styled(AnalystsMultiSelect)`
  max-width: 350px;
`;

const TypographyTitle = styled(Typography.Title)`
  &.ant-typography {
    /* Dark BLue */
    color: #334d6e;
    margin: 0;
  }
`;
const SecondaryTitle = styled(Paragraph)`
  /* Gray */
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatsCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 15px;
`;

const Styled = {
  Wrapper,
  StatsWrapper,
  SearchCardsWrapper,
  MyCompaniesCardsWrapper,
  MainContent: StyledMainContent,
  PageTitle,
  TypographyTitle,
  SecondaryTitle,
  ByAnalystsOrTeamMultiSelect,
  TitleWrapper,
  StatsCardsWrapper,
};

export default Styled;
