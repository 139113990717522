import React, { useState, useMemo, useEffect } from 'react';
import Truncate from 'react-truncate';
import { useDispatch } from 'react-redux';
import { Select, Row, Col } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
//models
import { CompanyProfile } from '@optx/models/Company';
import { FieldChangedValue } from '@optx/features/company/audit-trail/state/interfaces';
// constants
import { OPTXChromePlugin, companyPageTop } from '@constants/pendoActions';
// utils
import { mapDescriptionOptions } from '@optx/utils/utils';
//redux
import { actions as CompanyProfileActions } from '@optx/redux/company/profile';
// hooks
import { useToggle } from '@optx/common/hooks/modal';
import { useHistoryTab } from '@optx/common/hooks/history';
// components
import PoweredByAITag from '@optx/components/common/powered-by-ai-tag/PoweredByAITag';
import { FeedbackMechanism } from '@optx/components/common/feedback-mechanism';
import { OverlayLoader } from '@optx/components/common/loader';
import Styles from './CompanySourceDescriptions.styles';
// styles
import './styles.scss';

interface CompanySourceDescriptionsProps {
  company: CompanyProfile;
}

const AI_DESCRIPTION_KEY = 'AI';

const CompanySourceDescriptions: React.FC<CompanySourceDescriptionsProps> = ({ company }) => {
  const { updateHistoryFields } = useHistoryTab();
  const dispatch = useDispatch();

  const options = useMemo(() => {
    return mapDescriptionOptions(company.descriptions);
  }, [company.descriptions]);

  const [currentValue, setCurrentValue] = useState(
    options.length ? (options[0].value as string) : ''
  );

  useEffect(() => {
    setCurrentValue(options.length ? (options[0].value as string) : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.descriptions]);

  const [lines, setLines] = useState<number | false>(6);
  const [isThumbsDown, setIsThumbsDown] = useState(false);
  const [loadingDescription, setLoadingDescription] = useState(false);
  const [isOpen, toggle] = useToggle();

  const handleCurrentValue = (value: string) => {
    const chromeExtensionExists = document.getElementsByClassName('extension-tabs').length;

    if (chromeExtensionExists) {
      window.pendo.track(OPTXChromePlugin.companyPage.descriptions.description);

      if (value === 'Crunchbase (Short)' || value === 'Crunchbase (Long)') {
        const key = value === 'Crunchbase (Short)' ? 'crunchbaseShort' : 'crunchbaseLong';

        window.pendo.track(
          OPTXChromePlugin.companyPage.descriptions[
            key as keyof typeof OPTXChromePlugin.companyPage.descriptions
          ]
        );
      } else {
        const key = value
          .replace(/\s/g, '')
          .split(/\s+/)
          .map(word => word.charAt(0).toLowerCase() + word.slice(1))
          .join('');

        window.pendo.track(
          OPTXChromePlugin.companyPage.descriptions[
            key as keyof typeof OPTXChromePlugin.companyPage.descriptions
          ]
        );
      }
    } else {
      window.pendo.track(companyPageTop.changeDescription);
    }

    setCurrentValue(value);
    dispatch(CompanyProfileActions.setSelectedDescription(value));
  };

  const saveHistory = (afterValue: string, beforeValue?: string, opinion?: string | null) => {
    updateHistoryFields({
      afterValue,
      beforeValue: beforeValue ?? '',
      opinion: opinion ?? undefined,
      fieldChanged: FieldChangedValue.generatedDescription,
    });
  };

  const handleThumbUp = () => {
    dispatch(
      CompanyProfileActions.opinionAiDescription(true, (newValue: string) =>
        saveHistory(newValue, currentValue, 'Positive')
      )
    );
  };

  const handleThumbDown = () => {
    setLoadingDescription(true);
    dispatch(
      CompanyProfileActions.opinionAiDescription(false, (newValue: string) => {
        saveHistory(newValue, company.descriptions[currentValue], 'Negative');
        setLoadingDescription(false);
      })
    );
  };

  const handleRegenerate = () => {
    setLoadingDescription(true);
    dispatch(
      CompanyProfileActions.regenerateAiDescription((newValue: string) => {
        saveHistory(newValue, company.descriptions[currentValue]);
        setLoadingDescription(false);
      })
    );
  };

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isOpen) {
      setLines(6);
    } else {
      setLines(false);
    }

    toggle();
  };

  const isDescriptionAI = useMemo(
    () =>
      currentValue === AI_DESCRIPTION_KEY ||
      (options.length === 1 && options[0].value === AI_DESCRIPTION_KEY),
    [currentValue, options]
  );

  if (!options.length) {
    return null;
  }

  return (
    <Row className="company-source-descriptions">
      <Row align="middle">
        <Col>
          {options.length === 1 ? (
            <div className="company-source-descriptions__no-selection">{options[0].label}</div>
          ) : (
            <Select
              className="ant-select--profile-descriptions"
              onChange={handleCurrentValue}
              value={currentValue}
            >
              {options.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
        {isDescriptionAI && (
          <>
            <Col>
              <PoweredByAITag />
            </Col>
            <Col>
              <FeedbackMechanism
                value={company.is_ai_description_approved}
                isGenerated={currentValue === AI_DESCRIPTION_KEY}
                handleThumbUp={handleThumbUp}
                handleThumbDown={handleThumbDown}
                handleRegenerate={handleRegenerate}
                setIsThumbsDown={setIsThumbsDown}
              />
            </Col>
          </>
        )}
      </Row>
      <Col span={24} className="company-source-descriptions__text">
        {loadingDescription ? (
          <OverlayLoader isOverlay loading />
        ) : (
          <Truncate
            lines={lines}
            ellipsis={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Styles.EllipsisExpander type="button" className="ml-1" onClick={handleToggle}>
                ...
              </Styles.EllipsisExpander>
            }
            style={{ textDecoration: isThumbsDown ? 'line-through' : 'initial' }}
          >
            {company.descriptions[currentValue]}
          </Truncate>
        )}

        {isOpen && (
          <Row className="justify-content-center cursor-pointer" onClick={handleToggle}>
            <Col className="col-sm-auto">
              <DoubleRightOutlined style={{ transform: 'rotate(-90deg)', cursor: 'pointer' }} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default CompanySourceDescriptions;
