import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useField } from 'formik';
import { OptionTypeBase } from 'react-select';
// models
import { BaseField } from '@optx/models/companyFields';
// components
import LocationSearchInput from '@features/company/edit-fields/components/edit-field-location/LocationSearchInput';
import Styled from './InputLocationField.styled';

interface InputLocationFieldProps {
  field: BaseField;
}

const InputLocationField: React.FC<InputLocationFieldProps> = ({ field }) => {
  const [selected, , setSelected] = useField<OptionTypeBase | null>(field?.id || '');
  const [customValue, setCustomValue] = useState<string>('');

  const handleChange = (locationValue: string, locationId: string) => {
    setCustomValue(locationValue);

    if (locationId !== '') {
      setSelected.setValue({
        label: locationValue,
        value: locationId,
      });
    } else {
      setSelected.setValue({
        label: field.value?.label || '',
        value: '',
      });
    }
  };

  useEffect(() => {
    if (field?.value) {
      setCustomValue(selected.value?.label || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, selected.value?.label]);

  return (
    <Styled.LocationFieldWrapper span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <LocationSearchInput
        handleChange={handleChange}
        placeholder={selected.value?.label ?? ''}
        address={customValue === 'Unknown' || field.value?.label === customValue ? '' : customValue}
        allowClear
        autoFocus={false}
      />
    </Styled.LocationFieldWrapper>
  );
};

export default React.memo(InputLocationField);
