import React, { useEffect, useCallback } from 'react';
import { Col, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
// models
import { CompanyProfile, ScoreGrowth } from '@optx/models/Company';
// redux
import { selectors as fundingSelectors } from '@redux/company/funding-rounds';
import { selectors as userSelectors } from '@redux/user/information';
import graphSelectors from '@features/company/graphs/state/selectors';
import { actions as scoreGrowthActions } from '@features/company/graphs/score';
// components
import LinearCombinedChart from '@optx/components/feature/charts/LinearCombinedChart';
import BarChartIntervals from '@optx/components/feature/charts/BarChartIntervals';
import LinearChart from '@optx/components/feature/charts/LinearChart';
import ChartLegend from '@optx/components/feature/charts/ChartLegend';

interface CardPannelGraphsProps {
  companyProfile: CompanyProfile;
}

const CardPannelGraphs: React.FC<CardPannelGraphsProps> = ({ companyProfile }) => {
  const employeeGraphData = useSelector(graphSelectors.employee.getEmployeeGrowthData);
  const isEmployeeGraphLoading = useSelector(graphSelectors.employee.isLoading);
  const scoreGraphData = useSelector(graphSelectors.score.getScoreGrowthData);
  const isScoreGraphLoading = useSelector(graphSelectors.score.isLoading);
  const defaultOptxScore = useSelector(userSelectors.getDefaultScore);
  const getPSGDate = useSelector(graphSelectors.score.getPSGDate);

  const fundingGraphData = useSelector(fundingSelectors.getFundingRoundsGraph);
  const isFundingGraphLoading = useSelector(fundingSelectors.isLoading);
  const roundsTotal = useSelector(fundingSelectors.getNumberOfRounds);
  const totalRaised = useSelector(fundingSelectors.getTotalRaised);
  const totalInvestors = useSelector(fundingSelectors.getTotalInvestors);

  const dispatch = useDispatch();

  const getScoreGrowth = useCallback(
    (payload: ScoreGrowth) => dispatch(scoreGrowthActions.fetchScoreGrowth(payload)),
    [dispatch]
  );

  const extraFunding = [
    {
      label: 'Total # of rounds',
      value: roundsTotal.toString(),
    },
    {
      label: 'Total # of investors',
      value: totalInvestors ? totalInvestors.toString() : '0',
    },
    {
      label: 'Total raised',
      value: totalRaised.toString(),
      format: true,
    },
  ];

  useEffect(() => {
    getScoreGrowth({
      company_id: companyProfile.company_id,
      company_url: companyProfile.company_url,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfile.score, companyProfile.il_optx_score]);

  return (
    <>
      <Row>
        <Col style={{ width: '100%' }}>
          {defaultOptxScore === 'il' ? (
            <LinearChart
              data={scoreGraphData}
              isLoading={isScoreGraphLoading}
              dataKey="score_value"
              labelKey="position"
              title="IL OPTX Score"
              kpiLabel="IL OPTX Score"
              headerValue={companyProfile.il_optx_score}
              isTrending
              className="profile-chart score-chart"
              isPSGCompany={companyProfile.is_psg_company}
              psgDate={getPSGDate}
            />
          ) : (
            <LinearChart
              data={scoreGraphData}
              isLoading={isScoreGraphLoading}
              dataKey="score_value"
              dataSecondaryKey="score_v3_value"
              labelKey="position"
              title="OPTX Score"
              kpiLabel="OPTX Score"
              newKPILabel="OPTX Beta"
              headerValue={companyProfile.score}
              newHeaderValue={companyProfile.score_v3}
              isTrending
              className="profile-chart score-chart"
              scoreType="us"
              isPSGCompany={companyProfile.is_psg_company}
              psgDate={getPSGDate}
              legend={<ChartLegend type="score" />}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <LinearCombinedChart
            data={employeeGraphData}
            isLoading={isEmployeeGraphLoading}
            dataKey="count_emp"
            dataSecondaryKey="count_job"
            labelKey="position"
            title="Employee Count"
            kpiLabel="%Growth"
            className="profile-chart employee-chart"
            legend={<ChartLegend type="employee" />}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <BarChartIntervals
            data={fundingGraphData}
            isLoading={isFundingGraphLoading}
            label="x_axis"
            title="Funding Rounds"
            dataKey="total_investment"
            extra={extraFunding}
            emptyText="BootStrapped"
            className="profile-chart funding-chart"
          />
        </Col>
      </Row>
    </>
  );
};

export default CardPannelGraphs;
