import { HTMLAttributes } from 'react';
import { Dictionary } from 'lodash';
import { SortByRule } from '@models/table/sorting';
import { ColumnType, SorterResult, SortOrder } from 'antd/lib/table/interface';
import { SORT_DESCENDING_BY_DEFAULT } from '@optx/constants/table/sort/defaultSort';

export interface HeaderCellAttributes extends HTMLAttributes<HTMLElement> {
  'data-order-priority': number;
}

export interface SortColumnsConfig {
  isMultiSort?: boolean;
  sortDirectionsKeys?: Array<string>;
  sortDirections?: Array<SortOrder>;
}

export const defaultConfig: SortColumnsConfig = {
  // Reversed sort direction from default in ANTD table.
  sortDirections: ['descend', 'ascend'],
};

/**
 * Sort table columns.
 * @param columns table columns.
 * @param sorter sorter.
 * @param config Sort columns configuration with multi sort and default sort direction.
 * @param defaultOptxScore decided column which is default.
 * IMPORTANT: Make sure sortOrder is assigned for only one column, meaning that it is in the controlled mode.
 */
export const sortColumns = (
  columns: Array<ColumnType<any>>,
  sorter: Array<SortByRule<any>>,
  defaultOptxScore?: string,
  config: SortColumnsConfig = {}
) => {
  if (!sorter.length) {
    let isControlled = false;
    let multipleScore = false;

    // Make sure sortOrder is assigned for only one column, meaning that it is in the controlled mode.
    return columns
      .filter(column => {
        if (column.dataIndex) {
          if (defaultOptxScore === 'il') {
            if (~(column.dataIndex as string).search('il_optx_score')) {
              if (multipleScore && defaultOptxScore !== 'il') {
                return null;
              }

              multipleScore = true;

              return column;
            }

            if (~(column.dataIndex as string).search('score')) {
              if (multipleScore && defaultOptxScore === 'il') {
                return null;
              }

              return null;
            }

            return column;
          }

          if (defaultOptxScore !== 'il') {
            if (
              ~(column.dataIndex as string).search('score') &&
              (column.dataIndex as string).search('score_v3')
            ) {
              if (multipleScore && defaultOptxScore !== 'il') {
                return null;
              }

              multipleScore = true;

              return column;
            }

            if (~(column.dataIndex as string).search('il_optx_score')) {
              if (multipleScore && defaultOptxScore === 'il') {
                return null;
              }

              return null;
            }

            return column;
          }

          return column;
        }
      })
      .map(column => {
        if (!isControlled && column.sorter) {
          const newCol = { ...column };
          newCol.sortOrder = null;
          isControlled = true;

          return newCol;
        }

        return column;
      });
  }

  const mergedConfig: SortColumnsConfig = {
    ...defaultConfig,
    ...config,
  };
  const { isMultiSort, sortDirections, sortDirectionsKeys } = mergedConfig;

  let multipleScore = false;

  return columns
    .filter(column => {
      if (column.dataIndex) {
        if (defaultOptxScore === 'il') {
          if (~(column.dataIndex as string).search('il_optx_score')) {
            if (multipleScore && defaultOptxScore !== 'il') {
              return null;
            }

            multipleScore = true;

            return column;
          }

          if (~(column.dataIndex as string).search('score')) {
            if (multipleScore && defaultOptxScore === 'il') {
              return null;
            }

            return null;
          }

          return column;
        }

        if (defaultOptxScore !== 'il') {
          if (~(column.dataIndex as string).search('il_optx_score')) {
            if (multipleScore && defaultOptxScore !== 'il') {
              return null;
            }

            return null;
          }

          if (
            ~(column.dataIndex as string).search('score') &&
            (column.dataIndex as string).search('score_v3')
          ) {
            if (multipleScore && defaultOptxScore === 'il') {
              return null;
            }

            multipleScore = true;

            return column;
          }

          return column;
        }

        return column;
      }
    })
    .map((column, columnIndex) => {
      const index = sorter.findIndex(sortItem => {
        if (sortItem.id === 'created_at' && column.dataIndex === 'date') return true;

        return sortItem.id === column.dataIndex;
      });
      const columnSort = index !== -1 ? sorter[index] : null;
      const newColumn: ColumnType<any> = { ...column };

      // Update default sort direction.
      if (sortDirectionsKeys && sortDirectionsKeys.includes(newColumn.dataIndex as string)) {
        newColumn.sortDirections = sortDirections;
      }

      if (column.sorter) {
        if (isMultiSort) {
          /**
           * On multi-sort is important to add multiple option in sorter for all sorted columns,
           * otherwise we get inconsistent sorting response from table.
           */
          newColumn.sorter = { multiple: 1 };
        } else {
          newColumn.sorter = true;
        }
      }

      // Update Sorting.
      if (columnSort) {
        newColumn.sortOrder = columnSort.desc ? 'descend' : 'ascend';

        if (isMultiSort) {
          const multiple = index + 1;

          // newColumn.sorter = { multiple: columnIndex };

          newColumn.onHeaderCell = () => {
            return {
              'data-order-priority': multiple,
            } as HeaderCellAttributes;
          };
        }

        return newColumn;
      }

      return newColumn;
    });
};

/**
 * Map ANTD table sorter to sorting rules.
 * @param nextSorting sorting form ANTD table.
 * The sort is array on multisort when clicking on a column included already in sort prior to clicking it.
 * When adding a new column in multi sort or single sort the columnSort is the sorting object.
 * @param sorting current sorting.
 * @param isMultiSort  is multiple column sort.
 */
export const mapSorting = (
  nextSorting: SorterResult<any> | Array<SorterResult<any>>,
  sorting: Array<SortByRule<any>>,
  keywordSearch?: string | null,
  defaultSort?: Array<SortByRule<any>>,
  isMultiSort?: boolean,
  onlyTwoStates?: boolean
) => {
  /**
   * Multisort
   * Next sorting is a list when:
   * - new item is added
   * - item is removed and there are two or more items.
   * - item order in sorting has been changed.
   */
  if (Array.isArray(nextSorting)) {
    return {
      sortBy: mapMultiSortList(sorting, nextSorting),
    };
  }

  // Only one item in sorting.
  const columnId = nextSorting.field as string;

  // if (isMultiSort) {
  //   // Toggle item sorting. Don't allow sorting removal.
  //   return [{ id: columnId, desc: nextSorting.order === 'descend' }];
  // }

  if (keywordSearch && keywordSearch !== '') {
    // Sorting by relevance when we only have sorting by one column and filter by keyword applied
    if (nextSorting?.order) {
      return {
        sortBy: [{ id: columnId, desc: nextSorting?.order === 'descend' }],
      };
    }

    if (onlyTwoStates) {
      return {
        sortBy: [{ id: columnId, desc: nextSorting?.order === 'descend' }],
      };
    }

    // Handle single sort.
    // New sort item has been clicked and load default order set in columns.
    return { sortBy: defaultSort };
  }

  if (
    SORT_DESCENDING_BY_DEFAULT.includes(nextSorting.field as string) &&
    !isMultiSort &&
    !onlyTwoStates
  ) {
    return {
      sortBy: [{ id: columnId, desc: nextSorting.order !== 'ascend' }],
    };
  }

  return {
    sortBy: [{ id: columnId, desc: nextSorting?.order === 'descend' }],
  };
};

/**
 * Map multi sort list.
 * This function also fixes sorting order in antd table.
 * By default the order is taken each time by column order with the last clicked sort item at the end.
 * @param sorting current sorting.
 * @param nextSorting next sorting in grid.
 * @returns next mapped sorting.
 */
function mapMultiSortList(sorting: Array<any>, nextSorting: Array<SorterResult<any>>) {
  let newSorting: Array<SortByRule<any>> = [];

  if (sorting.length < nextSorting.length) {
    // Item has been added.
    const item = nextSorting[nextSorting.length - 1];

    newSorting = [
      ...sorting,
      {
        id: item.column?.dataIndex as string,
        desc: item.order === 'descend',
      },
    ];
  } else if (sorting.length > nextSorting.length) {
    // Item has been removed.
    const newSortingKeys = nextSorting.reduce<Dictionary<boolean>>((accumulator, sortItem) => {
      const newAccumulator = accumulator;
      newAccumulator[sortItem.column?.dataIndex as string] = true;

      return newAccumulator;
    }, {});

    newSorting = sorting.filter(item => newSortingKeys[item.id]);
  } else {
    // An item in list have been changed. It is added by default at the end of the list.
    const lastClickedItem = nextSorting[nextSorting.length - 1];

    // Kepp the old order of sorting items.
    return sorting.map(item => {
      if (item.id === lastClickedItem.column?.dataIndex) {
        return {
          ...item,
          desc: lastClickedItem.order === 'descend',
        };
      }

      return item;
    });
  }

  return newSorting;
}
