import React, { useEffect, useState } from 'react';
import { Row, Typography, Tag, Button, Dropdown, Tooltip, Col } from 'antd';
// models
import { CompanyProfile, FoundCompany } from '@models/Company';
// constants
import { searchInNewTab } from '@optx/utils/search';
import { ReferrerUrlOrigin } from '@optx/models/routes';
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// components
import Icon from '@components/common/Icon';
import OwnerAvatar from '@shared/view/organims/OwnerAvatar';
import { IconCompany } from '@components/icons';
import IsraelFlag from '@optx/assets/images/flags/israel_flag.jpg';
// redux
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as filterSourcesSelectors } from '@redux/company/filters';
import { useSelector } from 'react-redux';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// styles
import { defaultAvatarImage } from '@optx/constants/images';
import CompanyProfileLinks from './CompanyProfileLinks';
import Styles from './long-card/LongCardView.styled';

import MatchesMenu from './MatchesMenu';
import { CardHeaderStyles } from './styled/CardPannelHeader.styles';

const { Text } = Typography;

interface CardPannelHeaderProps {
  company: CompanyProfile;
  companyId: number;
  matches: Array<FoundCompany>;
  onCompanyChange: (companyId: number) => void;
  name: string;
  country: string;
  state: string;
  city: string;
  street: string;
  logo: string | null;
  isInEt: boolean;
  owner: {
    name: string | null;
    image: string | null;
    id: number | null;
  } | null;
}

const CardPannelHeader: React.FC<CardPannelHeaderProps> = ({
  company,
  companyId,
  matches,
  onCompanyChange,
  name,
  state,
  country,
  street,
  city,
  logo,
  isInEt,
  owner,
}) => {
  const [cityStreetLabel, setCityStreetLabel] = useState<string>('');
  const [stateCountryLabel, setStateCountryLabel] = useState<string>('');

  const clearedFilter = useSelector(filterSelectors.getClearedFilter('advancedSearch'));
  const sources = useSelector(filterSourcesSelectors.getFiltersMap);
  const { advancedSearchPathname } = useIsAnalyst();

  const handleClick = (id: number) => {
    onCompanyChange(id);
  };

  const handleParentCompanyClick = () => {
    // some parent companies have ' dba ' in their names
    // in that case we only search for name after ' dba '
    // ex: 'Football1x2, Ltd. dba 1X2 Network'
    const [firstPart, secondPart] = company.parentcompany.split(' dba ');

    searchInNewTab(
      clearedFilter,
      sources,
      'query',
      secondPart || firstPart,
      {
        referrer: ReferrerUrlOrigin.parentCompanySearch,
      },
      advancedSearchPathname
    );
  };

  const handleShowParentCompany = () => {
    if (
      (company.parentcompany && company.last_round) ||
      (company.parentcompany && company.last_round === null)
    ) {
      return (
        <Row>
          <Col xs={24}>
            <div className="profile__parent-company">
              <Tag color="red" onClick={handleParentCompanyClick}>
                Parent Company: {company.parentcompany}
              </Tag>
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  const onOwnerClick = () => {
    const origin = window.location.origin;
    const ownerId = company.company_owner_id;
    const newLocation = `${origin}${advancedSearchPathname}?company_owner_id=${ownerId}`;
    window.open(newLocation, '_blank');
  };

  useEffect(() => {
    setCityStreetLabel(city + (city && street ? ', ' : '') + street);

    setStateCountryLabel(state + (country && state ? ', ' : '') + country);
  }, [city, street, country, state]);

  return (
    <>
      {matches.length > 0 && (
        <CardHeaderStyles.Icons span={7}>
          <Row align="middle">
            <Dropdown
              overlay={<MatchesMenu onClick={handleClick} matches={matches} />}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button type="default">{matches.length} more match(es)</Button>
            </Dropdown>
          </Row>
        </CardHeaderStyles.Icons>
      )}
      <CardHeaderStyles.CompanyHeaderWrapper>
        <Row className="card-header-row" gutter={[20, 0]}>
          <CardHeaderStyles.Wrapper span={24}>
            <Styles.CenteredColumn span={4}>
              <Styles.ImageWrapper className="image-wrapper">
                {!logo ? (
                  <IconCompany width={'80px'} height={'80px'} />
                ) : (
                  <img src={logo || undefined} alt={name} />
                )}
              </Styles.ImageWrapper>
            </Styles.CenteredColumn>
            <CardHeaderStyles.CompanyNameWrapper span={10}>
              <Row>
                <Col>
                  {isInEt ? (
                    <Tag color="green" style={{ width: 'fit-content' }}>
                      In ET
                    </Tag>
                  ) : (
                    <Tag color="gray" style={{ width: 'fit-content' }}>
                      Not in ET
                    </Tag>
                  )}
                  {company.is_in_israel && (
                    <img
                      src={IsraelFlag}
                      alt="Israel"
                      style={{ borderRadius: 4, width: 'fit-content' }}
                      height={20}
                    />
                  )}
                </Col>
              </Row>
              <CardHeaderStyles.CompanyName>
                <p>
                  {name.length > 33 ? <Tooltip title={name}>{name}</Tooltip> : name}
                  <Styles.CompanyProfileLink to={`/profile/${companyId}`} target="_blank">
                    <Icon
                      iconName="open-in-new"
                      onClick={() =>
                        window.pendo.track(OPTXChromePlugin.companyBanner.company_icon)
                      }
                    />
                  </Styles.CompanyProfileLink>
                </p>
              </CardHeaderStyles.CompanyName>

              <Text type="secondary" className="location-text">
                <p>
                  {(cityStreetLabel || stateCountryLabel) && <Icon iconName="pin" />}
                  {cityStreetLabel.length > 30 ? (
                    <Tooltip title={cityStreetLabel}>
                      {cityStreetLabel.substring(0, 30) + '...'}
                    </Tooltip>
                  ) : (
                    cityStreetLabel
                  )}
                  {cityStreetLabel && <br className="location-separator" />}
                  {stateCountryLabel.length > 30 ? (
                    <Tooltip title={stateCountryLabel}>
                      {stateCountryLabel.substring(0, 30) + '...'}
                    </Tooltip>
                  ) : (
                    stateCountryLabel
                  )}
                </p>
              </Text>
              {handleShowParentCompany()}
            </CardHeaderStyles.CompanyNameWrapper>
            <CardHeaderStyles.HeaderRight span={10}>
              <CompanyProfileLinks company={company} />
              {owner && (
                <CardHeaderStyles.OwnerWrapper>
                  <OwnerAvatar
                    ownerId={owner.id}
                    name={owner.name}
                    ownerImage={owner.image || defaultAvatarImage}
                    title="PSG OWNER"
                    className="extension-header-owner"
                    onClick={onOwnerClick}
                  />
                </CardHeaderStyles.OwnerWrapper>
              )}
            </CardHeaderStyles.HeaderRight>
          </CardHeaderStyles.Wrapper>
        </Row>
      </CardHeaderStyles.CompanyHeaderWrapper>
    </>
  );
};

export default CardPannelHeader;
