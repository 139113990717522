import styled from 'styled-components';
import { Modal, Row, Col } from 'antd';
import { ColProps } from 'antd/lib/col';
import { ModalProps } from 'antd/lib/modal';

interface ModalWrapperProps extends ModalProps {
  isCompleted: boolean;
}

interface BoxWrapperProps extends ColProps {
  width?: string;
  $isStageField?: boolean;
}

const ModalWrapper = styled(Modal)<ModalWrapperProps>`
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  .ant-modal-header,
  .ant-modal-footer {
    border-color: #e8e8e8;
  }

  .ant-modal-body {
    background: #f3f6f9;
  }

  th.ant-table-cell {
    padding: 19px 7px;
  }

  .ant-table-wrapper {
    height: 400px;
    overflow-y: auto;
  }

  .ant-btn:first-child {
    display: ${props => (props.isCompleted ? 'none' : 'inline-block')};
  }
`;

const RowWrapper = styled(Row)`
  justify-content: space-between;
  padding: 13px 15px;
`;

const BoxWrapper = styled(Col)<BoxWrapperProps>`
  width: ${({ width }) => width || 'calc(50% - 17px)'};
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(66, 17, 115, 0.06);
  border-radius: 8px;
  ${({ $isStageField }) => $isStageField && 'min-height: 255px;'};

  .ant-typography:not(.multiselect__value-container .ant-typography) {
    display: inline-block;
    margin-bottom: 13px;
  }
`;

export const Styled = {
  ModalWrapper,
  RowWrapper,
  BoxWrapper,
};
