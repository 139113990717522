import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
// models
import {
  ScheduledTouchesState,
  DashboardSuccess,
  ScheduledTouchesSortBy,
  ScheduledTouchesResponse,
  ScheduledTouchesCountResponse,
  SCHEDULED_TOUCHES_DEFAULT_SORT,
} from './interfaces';
import { UserInformation } from '@optx/models/user';
import { DateRangeOption } from '@models/Option';
// utils
import { getParametersFromURL, removeAllParametersFromURL } from '@utils/url';
import { parseSorting } from '@optx/utils/filters/parseSorting';
// redux
import {
  changeSelectedTab,
  changeSelectedPeriod,
  changeSelectedDate,
  changeScheduledTouchesTouchType,
  changeScheduledTouchesStageType,
  fetchScheduledTouchesDashboardFail,
  fetchScheduledTouchesDashboardSuccess,
  fetchScheduledTouchesDashboard,
  fetchScheduledTouches,
  fetchScheduledTouchesFail,
  fetchScheduledTouchesSuccess,
  changeScheduledTouchesSortBy,
  fetchScheduledTouchesCount,
  fetchScheduledTouchesCountSuccess,
  fetchScheduledTouchesCountFail,
  changeScheduledTouchesRankType,
  fetchScheduledTouchesNextPage,
  fetchScheduledTouchesNextPageSuccess,
  fetchScheduledTouchesNextPageFail,
  resetScheduledTouchesFilter,
} from './actions';
import { actions as userInformationActions } from '@redux/user/information';

export const initialState: ScheduledTouchesState = {
  dashboard: {
    inputDates: [],
    cards: {},
    tabs: {},
    stages: [],
    touchTypes: [],
    ranks: [],
    error: null,
    loading: false,
    fetchedAt: null,
  },
  touches: { data: null, error: null, loading: false, fetchedAt: null },
  count: { data: null, error: null, loading: false, fetchedAt: null },
  sortBy: SCHEDULED_TOUCHES_DEFAULT_SORT,
  pagination: {
    pageNumber: 1,
    pageSize: 50,
  },
  insight: {},
  insightTotal: 0,
  selectedTab: '*',
  stageType: '*',
  touchType: 'All',
  selectedPeriod: '',
  remainedTouches: 500,
  totalTouches: 500,
  selectedDateRage: [null, null],
  rankType: 'All',
};

const fetchScheduledTouchesReducer = (draftState: ScheduledTouchesState) => {
  draftState.pagination.pageNumber = 1;
  draftState.touches.loading = true;
  draftState.touches.error = '';
};

const fetchScheduledTouchesNextPageReducer = (draftState: ScheduledTouchesState) => {
  draftState.pagination.pageNumber += 1;
  draftState.touches.loading = true;
  draftState.touches.error = '';
};

const fetchScheduledTouchesDashboardReducer = (draftState: ScheduledTouchesState) => {
  draftState.dashboard.loading = true;
  draftState.dashboard.error = '';
};

const fetchScheduledTouchesCountReducer = (draftState: ScheduledTouchesState) => {
  draftState.count.loading = true;
  draftState.count.error = '';
};

const fetchScheduledTouchesFailReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.touches.loading = false;
  draftState.touches.error = action.payload;
};

const fetchScheduledTouchesDashboardFailReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.dashboard.loading = false;
  draftState.dashboard.error = action.payload;
};

const fetchScheduledTouchesCountSuccessReducer: CaseReducer<
  ScheduledTouchesState,
  PayloadAction<ScheduledTouchesCountResponse>
> = (draftState, action) => {
  draftState.count.fetchedAt = new Date().toISOString();
  draftState.count.loading = false;
  draftState.count.data = action.payload;
  draftState.dashboard.loading = false;

  // insight
  draftState.insight = action.payload.insight;
  let insightTotalTouches = Object.values(action.payload.insight).reduce(
    (acc: number, item: number) => acc + item,
    0
  );
  insightTotalTouches += action.payload.tabs.past_due ?? 0;

  draftState.insightTotal = insightTotalTouches;
};

const fetchUserInformationSuccessReducer: CaseReducer<
  ScheduledTouchesState,
  PayloadAction<UserInformation>
> = (draftState, action) => {
  const filters = action.payload.settings.session_settings?.touch_filters;
  const sorting = action.payload.settings.session_settings?.touch_sorting;

  if (sorting) {
    const { sortBy } = parseSorting(sorting);

    draftState.sortBy = `${sortBy[0].id}.${
      sortBy[0].desc ? 'desc' : 'asc'
    }` as ScheduledTouchesSortBy;
  }

  if (filters) {
    const {
      touch_type: touchType,
      stage_type: stageType,
      rank_type: rankType,
      end_date: endDate,
      start_date: startDate,
      pipeline_rank: pipelineRank,
      tab,
      selected_period: selectedPeriod,
    } = queryString.parse(filters);

    if (touchType) {
      draftState.touchType = touchType as string;
    }

    if (tab) {
      draftState.selectedTab = tab as string;
    }

    if (stageType) {
      draftState.stageType = stageType as string;
    }

    if (rankType) {
      draftState.rankType = rankType as string;
    }

    if (pipelineRank) {
      draftState.rankType = pipelineRank as string;
    }

    if (startDate && endDate) {
      draftState.selectedDateRage = [startDate as string, endDate as string];
    }

    if (selectedPeriod && typeof selectedPeriod === 'string') {
      draftState.selectedPeriod = selectedPeriod;
    }
  }
};

const fetchScheduledTouchesCountFailReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.count.loading = false;
  draftState.count.error = action.payload;
};

const fetchScheduledTouchesSuccessReducer: CaseReducer<
  ScheduledTouchesState,
  PayloadAction<ScheduledTouchesResponse>
> = (draftState, action) => {
  draftState.touches.fetchedAt = new Date().toISOString();
  draftState.touches.loading = false;
  draftState.touches.data = action.payload.result;
  draftState.remainedTouches = action.payload.remained_touch_count;
  draftState.totalTouches = action.payload.count;
};

const fetchScheduledTouchesNextPageSuccessReducer: CaseReducer<
  ScheduledTouchesState,
  PayloadAction<ScheduledTouchesResponse>
> = (draftState, action) => {
  draftState.touches.fetchedAt = new Date().toISOString();
  draftState.touches.loading = false;

  if (draftState.touches.data) {
    draftState.touches.data = [...draftState.touches.data, ...action.payload.result];
  } else {
    draftState.touches.data = action.payload.result;
  }

  draftState.remainedTouches = action.payload.remained_touch_count;
  draftState.totalTouches = action.payload.count;
};

const fetchScheduledTouchesDashboardSuccessReducer: CaseReducer<
  ScheduledTouchesState,
  PayloadAction<DashboardSuccess>
> = (draftState, { payload }) => {
  const [selectedPeriod, selectedTab, touchType] = getParametersFromURL([
    'selectedPeriod',
    'selectedTab',
    'touchType',
  ]);

  draftState.dashboard.fetchedAt = new Date().toISOString();
  draftState.dashboard.loading = false;
  draftState.dashboard.inputDates = payload.input_dates;
  draftState.dashboard.tabs = payload.tabs;
  draftState.dashboard.cards = payload.cards;
  draftState.dashboard.stages = payload.stages;
  draftState.dashboard.ranks = payload.pipeline_rank;
  draftState.dashboard.touchTypes = payload.touch_types;

  if (touchType) {
    draftState.touchType = touchType;
  }

  if (selectedTab && selectedTab in draftState.dashboard.tabs) {
    draftState.selectedTab = selectedTab;
  }

  // 1 - is for 'This Week' period
  let defaultDate: DateRangeOption = payload.input_dates[1];

  if (selectedPeriod) {
    const inputDateOption = payload.input_dates.find(
      inputDate => inputDate.label === selectedPeriod
    );

    if (inputDateOption) {
      defaultDate = inputDateOption;
    }

    draftState.selectedPeriod = defaultDate.label!;
    draftState.selectedDateRage = [defaultDate.start, defaultDate.end];
  }

  // this check is for case when we don't have user settings and we don't have parameters in URL
  if (!draftState.selectedPeriod) {
    draftState.selectedPeriod = defaultDate.label!;
  }

  // default date will be This week
  if (draftState.selectedDateRage.includes(null)) {
    draftState.selectedDateRage = [defaultDate.start, defaultDate.end];
  }

  draftState.sortBy = payload.sortBy as ScheduledTouchesSortBy;

  removeAllParametersFromURL();
};

const resetScheduledTouchesFilterReducer = (draftState: ScheduledTouchesState) => {
  draftState.stageType = '*';
  draftState.touchType = 'All';
  draftState.rankType = 'All';

  // 1 - is for 'This Week' period
  const defaultDate: DateRangeOption = draftState.dashboard.inputDates[1];

  if (defaultDate) {
    draftState.selectedPeriod = defaultDate.label!;
    draftState.selectedDateRage = [defaultDate.start, defaultDate.end];
  }
};

const changeSortingReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<ScheduledTouchesSortBy>
) => {
  draftState.sortBy = action.payload;
};

const changeStageTypeReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.stageType = action.payload;
};

const changeTouchTypeReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.touchType = action.payload;
};

const changeRankTypeReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.rankType = action.payload;
};

const changeSelectedDateReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<[string | null, string | null]>
) => {
  draftState.selectedDateRage = action.payload;
};

const changeSelectedPeriodReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.selectedPeriod = action.payload;
  draftState.pagination.pageNumber = 1;
};

const changeSelectedTabReducer = (
  draftState: ScheduledTouchesState,
  action: PayloadAction<string>
) => {
  draftState.selectedTab = action.payload;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchScheduledTouchesDashboard, fetchScheduledTouchesDashboardReducer)
    .addCase(resetScheduledTouchesFilter, resetScheduledTouchesFilterReducer)
    .addCase(fetchScheduledTouchesDashboardSuccess, fetchScheduledTouchesDashboardSuccessReducer)
    .addCase(fetchScheduledTouchesDashboardFail, fetchScheduledTouchesDashboardFailReducer)
    .addCase(fetchScheduledTouches, fetchScheduledTouchesReducer)
    .addCase(fetchScheduledTouchesSuccess, fetchScheduledTouchesSuccessReducer)
    .addCase(fetchScheduledTouchesFail, fetchScheduledTouchesFailReducer)
    .addCase(fetchScheduledTouchesCount, fetchScheduledTouchesCountReducer)
    .addCase(fetchScheduledTouchesCountSuccess, fetchScheduledTouchesCountSuccessReducer)
    .addCase(fetchScheduledTouchesCountFail, fetchScheduledTouchesCountFailReducer)
    .addCase(fetchScheduledTouchesNextPage, fetchScheduledTouchesNextPageReducer)
    .addCase(fetchScheduledTouchesNextPageSuccess, fetchScheduledTouchesNextPageSuccessReducer)
    .addCase(fetchScheduledTouchesNextPageFail, fetchScheduledTouchesFailReducer)
    .addCase(changeScheduledTouchesSortBy, changeSortingReducer)
    .addCase(changeScheduledTouchesTouchType, changeTouchTypeReducer)
    .addCase(changeScheduledTouchesStageType, changeStageTypeReducer)
    .addCase(changeScheduledTouchesRankType, changeRankTypeReducer)
    .addCase(changeSelectedDate, changeSelectedDateReducer)
    .addCase(changeSelectedPeriod, changeSelectedPeriodReducer)
    .addCase(changeSelectedTab, changeSelectedTabReducer)
    .addCase(userInformationActions.fetchUserInformationSuccess, fetchUserInformationSuccessReducer)
);

export default reducer;
