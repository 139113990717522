import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
import { CompanyProfileTabs } from '@optx/constants/routes';
// utils
import { profileRedirectPath } from '@utils/routes';
// hooks
import useIsAnalyst from '@optx/common/hooks/useIsAnalyst';
// components
import Icon from '@components/common/Icon';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

const TableCellCompanyName = (value: any, record: any) => (
  <CompanyNameRender name={value} companyId={record?.company_id} />
);

interface CompanyNameRenderProps {
  name: string;
  companyId?: string | number;
  showOpenInNewTab?: boolean;
  stopClickPropagation?: boolean;
  origin?: string;
}

const stopEventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const CompanyNameRender: React.FC<CompanyNameRenderProps> = ({
  name,
  companyId,
  stopClickPropagation,
  origin,
}) => {
  const location = useLocation();
  const { advancedSearchPathname } = useIsAnalyst();

  const newTabPath = origin ? advancedSearchPathname : location.pathname;
  const pagePath = origin
    ? `/profile/${companyId}?tab=${CompanyProfileTabs.PROFILE_OVERVIEW}`
    : profileRedirectPath(Number(companyId), location.pathname);

  const handleClick = (event: React.MouseEvent) => {
    stopClickPropagation && stopEventPropagation(event);
  };

  return (
    <div className="d-flex">
      {companyId ? (
        <>
          <TruncateTooltip title={name || DEFAULT_CELL_VALUE} placement={'topLeft'}>
            <Link onClick={handleClick} className="company-name__profile-link" to={pagePath}>
              {name || DEFAULT_CELL_VALUE}
            </Link>
          </TruncateTooltip>
          <Link
            onClick={handleClick}
            className="open-in-new"
            to={profileRedirectPath(Number(companyId), newTabPath, true)}
            target="_blank"
          >
            <Icon iconName="open-in-new" />
          </Link>
        </>
      ) : (
        <TruncateTooltip title={name || DEFAULT_CELL_VALUE} placement={'topLeft'}>
          <span className="company-name__no-link text-ellipsis">{name || DEFAULT_CELL_VALUE}</span>
        </TruncateTooltip>
      )}
    </div>
  );
};

CompanyNameRender.defaultProps = {
  showOpenInNewTab: true,
};

export default TableCellCompanyName;
