import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { Button, Col, Row, Typography } from 'antd';
import { useField } from 'formik';
import { Select } from 'formik-antd';
// models
import { AppState } from '@optx/redux/interfaces';
import { SelectOption } from '@optx/models/Option';
// constants
import { STAGE_RATIONALE_KEY } from '@optx/features/add-research-rationale/constants';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import { getFormRationales } from '@optx/redux/company/filters/selectors';
// hooks
import useGetFilterData from '../../hooks/useGetFilterData';
// components
import RationaleSection from '@optx/features/company/edit-fields/components/RationaleSection';
// styled
import { Styled } from '@optx/features/field-factory/RadioGroupField.styled';

const { Option } = Select;

const StageWithRationaleField = () => {
  const [stageField, , stageHelpers] = useField<SelectOption | undefined>('stage');
  const [stageRationaleField, , setStageRationaleFieldHelpers] = useField<string | null>(
    'stageRationale'
  );
  const [oldStageValue, setOldStageValue] = useState<SelectOption | undefined>(stageField.value);
  const [rationaleBoxVisible, setRationaleBoxVisible] = useState<boolean>(
    showRationaleForStage(stageField.value?.label || '') && !stageRationaleField.value
  );
  const [selectedRationaleOption, setSelectedRationaleOption] = useState<string | null>(null);
  const [otherRationale, setOtherRationale] = useState<string | null>(null);
  const rationaleOptions = useSelector(
    (state: AppState) => getFormRationales(state, STAGE_RATIONALE_KEY) ?? []
  );
  const { stages } = useGetFilterData(4);

  const [stageLabel, setStageLabel] = useState<string>(stageField.value?.label || '');
  const selectedLabel = rationaleOptions.find(
    option => option.value === selectedRationaleOption
  )?.label;
  const label = selectedRationaleOption === null ? 'Not selected' : selectedLabel || 'Other';

  const handleRationaleChange = (value: string) => setSelectedRationaleOption(value);

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);
    const optionExists =
      selectedRationaleOption &&
      selectedRationaleOption !== DEFAULT_EMPTY_VALUE &&
      !values.includes(selectedRationaleOption);

    return key === 'tag'
      ? !!(optionExists && otherRationale)
      : !!(optionExists && selectedRationaleOption && selectedRationaleOption.length > 0);
  };

  const handleOtherChange = (newValue: string | null) => {
    setOtherRationale(newValue);
    setSelectedRationaleOption(newValue);
  };

  const handleRationaleSave = () => {
    setRationaleBoxVisible(false);
    setStageRationaleFieldHelpers.setValue(selectedRationaleOption);

    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    if (selectedRationaleOption && values.includes(selectedRationaleOption)) {
      setOtherRationale(null);
    }
  };

  const handleRationaleCancel = () => {
    setSelectedRationaleOption(stageRationaleField.value);
    setRationaleBoxVisible(false);

    if (!stageRationaleField.value) {
      stageHelpers.setValue(oldStageValue);
    }
  };

  const handleStageSelectChange = (val: string) => {
    const stage = stages.find(stage => stage.value === val);

    if (stage) {
      setStageLabel(stage.label);
      setOldStageValue(stageField.value);
      setRationaleBoxVisible(showRationaleForStage(stage.label));
      setOtherRationale(null);
      setSelectedRationaleOption(null);
      stageHelpers.setValue({ label: stage.label, value: stage.value });
      setStageRationaleFieldHelpers.setValue(null);
    }
  };

  return (
    <>
      <Select
        name={stageField.name}
        value={stageField.value?.value}
        onChange={handleStageSelectChange}
        filterOption={(input: string, option: OptionTypeBase | undefined) => {
          const fullName = `${option?.children.props.children}`;

          return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        getPopupContainer={() => document.getElementById('step-4')!}
        showSearch
      >
        {stages.map((stage, index) => (
          <Option key={index} value={stage.value}>
            <Typography.Text>{stage.label}</Typography.Text>
          </Option>
        ))}
      </Select>
      {rationaleBoxVisible && (
        <Styled.ChangeRationaleWrapper positionedAbsolute>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Row justify="space-between" className="heading-wrapper">
                <Col>
                  {stageLabel?.length > 0 && (
                    <div>
                      <Typography.Title>
                        Add {stageLabel} Rationale:{' '}
                        <span className="required-label">(Required) </span>
                      </Typography.Title>
                    </div>
                  )}
                </Col>
                <Col>
                  <Button onClick={handleRationaleCancel} className="cancel-btn">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleRationaleSave}
                    className="save-rationale-btn"
                    disabled={!selectedRationaleOption}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <Row className="body-row">
                <RationaleSection
                  rationaleOptions={rationaleOptions}
                  rationaleValue={label === 'Other' ? 'other' : selectedRationaleOption}
                  currRationaleValue={otherRationale}
                  handleRationaleChange={handleRationaleChange}
                  showIsOtherOrTag={showIsOtherOrTag}
                  handleOtherChange={handleOtherChange}
                  isFromTouch={true}
                  isRationaleRequired={false}
                />
              </Row>
            </Col>
          </Row>
        </Styled.ChangeRationaleWrapper>
      )}
    </>
  );
};

export default React.memo(StageWithRationaleField);
