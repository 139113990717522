import * as yup from 'yup';
// models
import { Addon } from '@optx/models/Company';
// constants
import { urlRegExp } from '@optx/constants/regex';
import { FIELD_REQUIRED_ERROR } from '@optx/constants/form';
import {
  LINK_URL_VALUE_ERROR_MESSAGE,
  ABBREVIATION_ERROR_MESSAGE,
} from '@optx/constants/table/cells';
// utils
import { validatePositiveNumbers } from '@optx/utils/validation';

const validationAllInfo = (stageRequiredValues: any[]) => {
  const validationSchema = yup.object({
    last_round: yup.object().nullable(),
    acquiring_company: yup
      .string()
      .nullable()
      .test('requiredConditional', FIELD_REQUIRED_ERROR, function (value) {
        return (
          this.parent.last_round?.label !== 'Acquisition' ||
          (value && !!value && this.parent.last_round?.label === 'Acquisition')
        );
      }),
    addon_bool: yup.boolean().nullable(),
    addon: yup
      .object<Addon>()
      .nullable()
      .test('requiredConditional', FIELD_REQUIRED_ERROR, function (value) {
        if (this.parent.addon_bool) {
          return Object.keys(value || {}).length > 0;
        }

        return true;
      }),
    stage_rationale: yup
      .string()
      .nullable()
      .test('requiredConditional', FIELD_REQUIRED_ERROR, function (value) {
        return (
          !stageRequiredValues.some(item => item === this.parent.stage) ||
          (value && !!value && stageRequiredValues.some(item => item === this.parent.stage))
        );
      }),
    banker_name: yup
      .string()
      .nullable()
      .test('requiredConditional', FIELD_REQUIRED_ERROR, function (value) {
        return (value && !!value && this.parent.banker) || !this.parent.banker;
      }),
    raw_url: yup
      .string()
      .nullable()
      .required(FIELD_REQUIRED_ERROR)
      .matches(urlRegExp, LINK_URL_VALUE_ERROR_MESSAGE),
    secondary_urls: yup
      .array()
      .of(yup.string().nullable())
      .nullable()
      .test('validSecondaryUrl', LINK_URL_VALUE_ERROR_MESSAGE, function (value) {
        if (!value || value?.length === 0 || (value?.length === 1 && !value?.[0])) {
          return true;
        }

        return value?.every((item: string) => {
          urlRegExp.lastIndex = 0;

          return urlRegExp.test(item);
        });
      })
      .test(
        'secondaryUrlsDifferentFromPrimary',
        'Secondary URLs must be different from the primary URL!',
        function (value) {
          // @ts-ignore
          const primaryUrl = this.from?.[0]?.value?.company_url;

          if (!primaryUrl) {
            return true;
          }

          return !value?.includes(primaryUrl);
        }
      )
      .test('uniqueSecondaryUrls', 'Secondary URLs cannot have duplicates!', function (value) {
        return !value?.some((item: string, index: number) => value.indexOf(item) !== index);
      }),
    capital_raised: yup
      .mixed()
      .nullable()
      .test('requiredConditional', ABBREVIATION_ERROR_MESSAGE, function (value) {
        return validatePositiveNumbers(value);
      }),
    last_round_amount: yup
      .mixed()
      .nullable()
      .test('requiredConditional', ABBREVIATION_ERROR_MESSAGE, function (value) {
        return validatePositiveNumbers(value);
      }),
    last_round_date: yup.string().nullable(),
  });

  return validationSchema;
};

export default validationAllInfo;
