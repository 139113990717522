import queryString from 'query-string';
// models
import { SelectOption } from '@optx/models/Option';

/**
 * Function used to handle updateUserSettings data.
 * When changing account region, it affects the pipeline_report query saved in user endpoint.
 * @param pipelineReportQuery filter query saved in the user endpoint
 * @param newOptxScoreSetting new account region selected
 * @returns
 */
export const getNewPipelineReportQuery = (
  pipelineReportQuery: string,
  newOptxScoreSetting: string
) => {
  const newQuery = queryString.parse(pipelineReportQuery, { arrayFormat: 'comma' });

  if (newOptxScoreSetting === 'us') {
    // default to All Regions if the selected region is US
    delete newQuery.is_il_optx_score;
  } else if (newOptxScoreSetting === 'il') {
    // default to Israel if the selected region is IL
    newQuery.is_il_optx_score = 'true';
  }

  delete newQuery.fund;

  return queryString.stringify(newQuery, { arrayFormat: 'comma' });
};

/**
 * Filters and concatenates fund labels based on the specified country criteria.
 *
 * @param {SelectOption[]} funds - An array of fund options, where each option contains a value.
 * @param {string} [country] - An optional country filter. If provided, it can be 'All', 'Europe',
 *                             or any other string.
 *                           - 'All': Includes all fund labels.
 *                           - 'Europe': Includes only fund labels that contain the word 'Europe'.
 *                           - Any other string: Includes fund labels that do not contain the word 'Europe'.
 * @returns {string} A comma-separated string of fund labels that match the specified country criteria.
 */
export const getFunds = (funds: SelectOption[], country?: string) => {
  return funds.reduce((acc, item) => {
    if (item.value !== 'blank') {
      if (country) {
        if (country === 'All') {
          if (acc.length === 0) {
            return [item];
          }

          acc.push(item);

          return acc;
        } else if (country === 'Europe') {
          if (item.value.includes('Europe')) {
            if (acc.length === 0) {
              return [item];
            }

            acc.push(item);

            return acc;
          }

          return acc;
        } else if (!item.value.includes('Europe')) {
          if (acc.length === 0) {
            return [item];
          }

          acc.push(item);

          return acc;
        }

        return acc;
      }

      if (acc.length === 0) {
        return [item];
      }

      acc.push(item);

      return acc;
    } else {
      return acc;
    }
  }, [] as SelectOption[]);
};

/**
 * Determines the region based on the selected funds.
 *
 * @param {SelectOption[]} selectedFunds - An array of selected fund options, where each option has a label property.
 * @returns {string} - The determined region based on the selected funds.
 */
export const getRegion = (selectedFunds: SelectOption[]) => {
  if (Array.isArray(selectedFunds)) {
    const lengthFunds = selectedFunds.length;
    const checkAllEuropeValues = selectedFunds.every(fund => fund.value?.includes('Europe'));
    const checkNoneValue = selectedFunds.some(fund => fund.value === 'blank');

    // the length should be 2 if region is Europe
    if (lengthFunds === 2) {
      if (checkAllEuropeValues) {
        return 'Europe';
      }
    }
    // the length should be 6 if region is Nort America + Israel
    else if (lengthFunds === 6) {
      if (!checkAllEuropeValues && !checkNoneValue) {
        return 'North America + Israel';
      }
    } else {
      return 'All Regions';
    }
  }

  return null;
};
