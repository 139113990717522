import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { OptionsType } from 'react-select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Button, Checkbox, Row, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { debounce, isEqual } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
import { SuccessErrorCallback } from '@optx/models/callback';
import Company, { Addon, CompanyProfile, CompanyUserValuesKeys } from '@optx/models/Company';
import { CustomValue, ServiceType } from '../state/interfaces';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { CompanyProfileTabs } from '@optx/constants/routes';
import { COMPANY_NAME_SEARCH_ENDPOINT } from '@constants/search';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// utils
import { formatAddon } from '@optx/utils/format';
// hooks
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import useUpdateFields from '../hooks/useUpdateFields';
import useReferrerProfileLink from '@hooks/useReferrerLink';
import useAutoFocusCheckbox from '@optx/common/hooks/useAutoFocusCheckbox';
// components
import EditCellPen from '@optx/components/common/table/Companies/styled-cells/EditCellPen';
import EditPopover from '@optx/components/common/popover/EditPopover';
import { InlineEdit } from '@optx/shared/view/molecules/edit/InlineEdit';
import Popover from '@optx/shared/view/molecules/Popover';
import { DropdownAddContext } from '@optx/components/DropdownAdd/DropdownAddContext';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { AsyncMultiSelect } from '@optx/shared/view/molecules/Select';
import Icon from '@components/common/Icon';
import DynamicListDisplay from '@optx/shared/view/molecules/DynamicListDisplay/DynamicListDisplay';
// styles
import { Styled } from './EditFieldCheckBoxSelectAsync.styled';

interface EditFieldCheckBoxAsyncProps {
  value: Addon | null;
  record: Company | CompanyProfile | undefined;
  fieldName: 'Add-on';
  fieldKey?: CompanyUserValuesKeys;
  hidePenIcon?: boolean;
  isFromGridDropDown?: boolean;
  dropdownLabel?: string;
  service: ServiceType;
  successMessage: string;
  errorMessage: string;
  type?: 'check' | 'company';
  isFromGrid: boolean;
  hasAddonLink?: boolean;
  isFromHistory?: boolean;
  actionPendo?: string;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
}

const EditFieldCheckBoxSelectAsync: React.FC<EditFieldCheckBoxAsyncProps> = ({
  value,
  record,
  fieldName,
  fieldKey,
  isFromGrid,
  service,
  successMessage,
  errorMessage,
  type = 'check',
  isFromGridDropDown,
  dropdownLabel,
  hidePenIcon,
  hasAddonLink,
  isFromHistory,
  actionPendo,
  getPopupContainer,
}) => {
  const { setDropdownVisible } = useContext(DropdownAddContext);
  const location = useLocation();

  const { updateField } = useUpdateFields({
    fieldName,
    companyId: record?.company_id ?? 0,
    companyUrl: record?.company_url ?? null,
    service,
    successMessage,
    errorMessage,
    isFromHistory,
  });

  const addonCheck = value !== null && Object.keys(value).length !== 0;

  const popoverContentRef = useRef(null);
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const [closePopup, setClosePopup] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(addonCheck);
  const [associatedCompanies, setAssociatedCompanies] = useState<OptionsType<SelectOption>>([]);
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>();
  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_NAME_SEARCH_ENDPOINT });

  const { getReferrerUrl } = useReferrerProfileLink();

  const handleDefaultValues = useCallback(() => {
    setAssociatedCompanies(
      Object.entries(value || {}).map(([key, value]) => ({
        label: key,
        value: key,
        id: value,
      }))
    );
  }, [value]);

  const associatedCompaniesAsAddon = useCallback(() => {
    return associatedCompanies.reduce(
      (acc, company) => ({ ...acc, [company.value]: company.id }),
      {} as Addon
    );
  }, [associatedCompanies]);

  useAutoFocusCheckbox(checkboxRef, isFocus);

  useEffect(() => {
    if (isEqual(value, associatedCompaniesAsAddon())) {
      setIsButtonDisabled(true);
    } else if (!checkboxValue || (checkboxValue && associatedCompanies.length > 0)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [checkboxValue, value, associatedCompanies, associatedCompaniesAsAddon]);

  useEffect(() => {
    setCheckboxValue(addonCheck);
    handleDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDefaultValues]);

  if (!record) return null;

  const title = `${fieldName} Management`;

  const updateCompanyAddonCheckbox = (event: CheckboxChangeEvent) => {
    const { checked } = event.target;
    setCheckboxValue(checked);

    if (checked === false) {
      setAssociatedCompanies([]);
    }
  };

  const handleSave = () => {
    setClosePopup(true);
    setDropdownVisible(false);
    const payload: CustomValue = {
      value: checkboxValue,
      optionalValue: associatedCompaniesAsAddon(),
      additionalValue: record.company_id,
    };

    if (addonCheck === checkboxValue && isEqual(value, associatedCompaniesAsAddon())) {
      return null;
    }

    if (actionPendo) {
      window.pendo.track(actionPendo);
    }

    return updateField(payload, fieldKey);
  };

  const handleCancel = () => {
    setCheckboxValue(addonCheck);
    handleDefaultValues();
    setClosePopup(true);
    setIsFocus(false);
  };

  const getLoadOptionsDelay = debounce((query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        options.unshift();
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  }, 300);

  const onAsyncMultiselectMenuClose = () => {
    setFetchedOptions([]);
  };

  const getDisabledStatus = () => {
    if (!checkboxValue) return false;

    if (checkboxValue && (!associatedCompanies || associatedCompanies?.length === 0)) {
      return true;
    }

    return false;
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <Styled.ContentContainer
      className="profile-information__financial"
      ref={popoverContentRef}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Row>
        <Checkbox
          ref={el => {
            checkboxRef.current = el;
          }}
          autoFocus={!isFromGrid}
          defaultChecked={checkboxValue}
          checked={checkboxValue}
          onChange={updateCompanyAddonCheckbox}
        >
          Company is an Add-on
        </Checkbox>
      </Row>
      <Row style={{ marginTop: 8, width: 280 }}>
        <Typography.Text>Associated Company / Platform</Typography.Text>
        <AsyncMultiSelect
          className="addon--multiselect-async"
          onChange={options => {
            const mappedOptions = (options as SelectOption[]).map(option => ({
              ...option,
              label: option.value,
            }));

            setAssociatedCompanies(mappedOptions);
          }}
          loadOptions={getLoadOptionsDelay}
          defaultOptions={fetchedOptions}
          defaultValue={associatedCompanies}
          autoFocus={false}
          // addon values can repeat causing multiselect issues
          getOptionValue={(option: SelectOption) => option.id?.toString() || ''}
          loadingMessage={({ inputValue }) =>
            inputValue.length > 2 ? 'Loading...' : 'Begin typing'
          }
          isDisabled={!checkboxValue}
          controlShouldRenderValue
          closeMenuOnSelect={false}
          onMenuClose={onAsyncMultiselectMenuClose}
          onSelectResetsInput={false}
          blurInputOnSelect={false}
          maxLength={500}
          closeOnSelect
        />
        {getDisabledStatus() && (
          <Typography.Text type="danger">Select a parent company</Typography.Text>
        )}
      </Row>
      <div
        className="profile-information__popover-buttons"
        style={{ margin: getDisabledStatus() ? '7px 0 0 -16px' : '29px 0 0 -16px' }}
      >
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          className="profile-information__save"
          type="primary"
          disabled={isButtonDisabled}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Styled.ContentContainer>
  );

  if (hasAddonLink && value) {
    const editPopover = (
      <EditPopover
        content={content}
        title={title}
        onVisibilityChange={handleCancel}
        closePopup={closePopup}
        hidePenIcon={hidePenIcon}
        setClosePopup={setClosePopup}
        destroyOnHide
        getPopupContainer={getPopupContainer}
      >
        <Styled.ProfileAddonList>{formatAddon(value)}</Styled.ProfileAddonList>
      </EditPopover>
    );

    if (value && Object.keys(value).length) {
      return (
        <Popover
          placement="bottomLeft"
          title={null}
          content={
            <Styled.PopoverContent>
              {Object.entries(value).map(([companyName, companyId]) => {
                if (!companyId) return null;

                const link =
                  `/profile/${companyId}` +
                  location.search.replace(/&tab=[^&]*/, `&tab=${CompanyProfileTabs.ADDON_MGMT}`);

                return (
                  <Styled.AddOnLinkContainer>
                    <InlineEdit
                      key={companyId}
                      action={
                        <Styled.NewTabLink to={link} target="_blank">
                          <Icon iconName="open-in-new" />
                        </Styled.NewTabLink>
                      }
                    >
                      <span>
                        <Link to={link}>{companyName}</Link>
                      </span>
                    </InlineEdit>
                  </Styled.AddOnLinkContainer>
                );
              })}
            </Styled.PopoverContent>
          }
        >
          {editPopover}
        </Popover>
      );
    }

    return editPopover;
  }

  if (!isFromGrid || isFromGridDropDown) {
    return (
      <EditPopover
        content={content}
        title={title}
        onVisibilityChange={handleCancel}
        closePopup={closePopup}
        hidePenIcon={hidePenIcon}
        setClosePopup={setClosePopup}
        destroyOnHide
        getPopupContainer={getPopupContainer}
      >
        {isFromHistory
          ? 'Edit Field'
          : isFromGrid ||
            (type === 'check' ? (
              <Checkbox checked={addonCheck} style={{ pointerEvents: 'none' }} />
            ) : (
              <TruncateTooltip title={formatAddon(value)} placement="topLeft">
                {formatAddon(value)}
              </TruncateTooltip>
            ))}
        {dropdownLabel && dropdownLabel}
      </EditPopover>
    );
  }

  return (
    <InlineEdit
      action={
        <EditCellPen
          content={content}
          title={title}
          onVisibilityShow={() => {
            setIsFocus(true);
          }}
          destroyOnHide={false}
          onVisibilityChange={handleCancel}
          closePopup={closePopup}
          setClosePopup={setClosePopup}
          getPopupContainer={getPopupContainer}
        />
      }
      fill
    >
      {value && Object.keys(value).length ? (
        <Popover
          placement="topLeft"
          title={null}
          trigger="click"
          content={
            <Styled.PopoverContent>
              {Object.entries(value).map(([companyName, companyId]) => {
                if (!companyId) return null;

                const link = getReferrerUrl(companyId) + `&tab=${CompanyProfileTabs.ADDON_MGMT}`;

                return (
                  <Styled.AddOnLinkContainer>
                    <InlineEdit
                      key={companyId}
                      action={
                        <Styled.NewTabLink to={link} target="_blank">
                          <Icon iconName="open-in-new" />
                        </Styled.NewTabLink>
                      }
                    >
                      <span>
                        <Link to={link}>{companyName}</Link>
                      </span>
                    </InlineEdit>
                  </Styled.AddOnLinkContainer>
                );
              })}
            </Styled.PopoverContent>
          }
        >
          <div>
            <DynamicListDisplay list={Object.keys(value)} />
          </div>
        </Popover>
      ) : (
        DEFAULT_EMPTY_VALUE
      )}
    </InlineEdit>
  );
};

export default React.memo(EditFieldCheckBoxSelectAsync);
