import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// services
import { UserService, SearchService } from '@services/api';
import NotificationService from '@services/NotificationService';
// models
import { SearchPayload, SearchPayloadRequest } from '@optx/models/api/contacts';
import { PageInfo } from '@optx/models/table/Pagination';
import { FilterSearchOriginName, PreselectedFilter, FilterSource } from '@optx/models/filters';
// utils
import { processCardList, getCompanyOwner, myCompaniesCardsFilters } from '@utils/myCompanies';
import { preProcessSearch } from '@optx/utils/search';
// redux
import { actions as histogramActions } from '@features/histograms/histograms-my-companies';
import { actions as searchKeyActions } from '@features/grid/searchkey';
import { actions as searchCountActions } from '@features/grid/search-count';
import { selectors as userInformationSelectors } from '@redux/user/information';
import { getFiltersQuery, handleTouchFilters } from '@redux/company/search/search/sagasReusable';
import { selectors as companiesFilterSelectors } from '@redux/company/filters';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { UpdateUserSettingsPayload } from '@optx/models/api/user';
import { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import { SortByRule } from '@optx/models/table/sorting';
import { SearchViewType } from '@optx/models/user';
import Company from '@optx/models/Company';
import * as selectors from './selectors';
import * as actions from './actions';
import * as myCompaniesSearchSelectors from '../search/selectors';
import { selectors as searchSelectors } from '@features/grid/search';
import { actions as searchActions } from '@features/grid/search';
import { selectors as sortSelectors } from '../sort';
import { updateFilterSettings, searchCompanies } from '@features/grid/search/state/sagasReusable';
import { SearchStateData, SearchCompanyPayload } from '@models/search';
import { selectors as paginationSelectors } from '@features/grid/pagination';
import { selectors as filterSelectors } from '@features/grid/filter';
import { selectors as longCardPaginationSelectors } from '@features/grid/pagination-long-card';
import { getViewId } from '@optx/features/grid/view/state/selectors';
import { getListType } from '@optx/features/grid/search/state/selectors';

/**
 * Fetch Cards section for My Companies
 */
function* fetchMyCompaniesCardsSaga(
  action: PayloadAction<Partial<SearchCompanyPayload>, any, boolean>
) {
  let filterQuery = {};

  const { filter, shouldHandleTouchFilters, shouldResetPageNumber } = action.payload;

  if (filter) {
    const touchFilters: Dictionary<PreselectedFilter> = yield call(handleTouchFilters, filter);
    filterQuery = yield call(getFiltersQuery, touchFilters);
  }

  const searchKey = action.payload.searchKey ? preProcessSearch(action.payload.searchKey) : '';
  const fetched: boolean = yield select(searchSelectors.fetched);
  const selectedCard: SearchStateData = yield select(selectors.getSelectedCard);
  const selectedCardId: string = yield select(selectors.getSelectedCardId);
  const allFiltersWithHistograms: string[] = yield select(
    myCompaniesSearchSelectors.selectAllFiltersWithHistograms
  );

  try {
    const res: AxiosResponse<Dictionary<SearchStateData>> = yield call(
      UserService.fetchMyCompaniesCards,
      searchKey,
      {
        ...filterQuery,
        saved_list_id: undefined,
      },
      selectedCardId ? (selectedCardId as string) : 'card1'
    );

    if (res.data) {
      const cardsList = processCardList(res.data);

      yield put(actions.fetchMyCompaniesCardsSuccess(cardsList));

      const searchData: Partial<SearchPayload> = {
        searchKey,
        filter: action.payload.filter,
        shouldHandleTouchFilters,
      };

      if (shouldResetPageNumber) searchData.shouldResetPageNumber = shouldResetPageNumber;

      yield put(
        searchActions.searchCompanies(
          {
            gridKey: 'myCompanies',
            data: searchData,
          },
          action.meta
        )
      );

      if (action.meta) {
        yield put(searchActions.clearFilterSuccess(selectedCard));
      }

      if (!fetched) {
        yield put(histogramActions.init());
      } else {
        yield put(histogramActions.fetchHistogramFilters(filter || {}, allFiltersWithHistograms));
      }
    } else {
      const errorMessage = 'Error fetching My Companies!';
      yield put(actions.fetchMyCompaniesCardsFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = `Error fetching My Companies - ${e}`;
    yield put(actions.fetchMyCompaniesCardsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* initializeMyCompaniesCardsSaga() {
  const searchKey: string = yield select(searchSelectors.getSearchKey('myCompanies'));
  let filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('myCompanies')
  );
  const authorizedComponents: Dictionary<boolean> = yield select(
    userInformationSelectors.authorizedComponents
  );
  let companyOwner: Array<{ value: string; label: string }> | undefined;
  const selectedCardId: string = yield select(selectors.getSelectedCardId);

  // for analysts we need to apply company owner filter
  // check is user is analyst, search for company owner filter,
  // get the company owner id for the current user
  if (
    !authorizedComponents.admin_portal_link &&
    // @ts-ignore
    (filter?.company_owner_id === undefined || filter?.company_owner_id?.length === 0)
  ) {
    const filterSources: FilterSource[] = yield select(companiesFilterSelectors.getCompanyFilters);
    const userName: string = yield select(userInformationSelectors.getFullName);

    companyOwner = getCompanyOwner(filterSources, userName);
    filter = { ...filter, company_owner_id: companyOwner };
  }

  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  const allFiltersWithHistograms: string[] = yield select(
    myCompaniesSearchSelectors.selectAllFiltersWithHistograms
  );

  try {
    const res: AxiosResponse<Dictionary<SearchStateData>> = yield call(
      UserService.fetchMyCompaniesCards,
      searchKey,
      {
        ...filterQuery,
        saved_list_id: undefined,
      },
      selectedCardId ? (selectedCardId as string) : 'card1'
    );

    if (res.data) {
      const cardsList = processCardList(res.data);

      yield put(actions.fetchMyCompaniesCardsSuccess(cardsList));

      const selectedCard: SearchStateData = yield select(selectors.getSelectedCard);
      const tablePagination: PageInfo = yield select(
        paginationSelectors.getPagination('myCompanies')
      );
      const longCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination('myCompanies')
      );
      const subFilter: string = yield select(selectors.getSubFilter);
      const sortBy: SortByRule<any>[] = yield select(sortSelectors.getSorting);
      const viewType: SearchViewType = yield select(viewTypeSelectors.searchView('myCompanies'));

      const pagination: PageInfo = {
        pageNumber: viewType === 'table' ? tablePagination.pageNumber : 1,
        pageSize: viewType === 'table' ? tablePagination.pageSize : longCardPagination.pageSize,
      };

      const searchData: SearchPayloadRequest = {
        searchKey,
        filter: {
          ...selectedCard?.filter,
          // @ts-ignore
          ...(subFilter ? selectedCard?.data[subFilter] : {}),
          ...filterQuery,
          source_tag_filter: selectedCard?.filter?.source_tag_filter,
          ...myCompaniesCardsFilters(selectedCard, {
            nextTouchDate: filterQuery.next_touch_date as any,
            lastTouchDateDays: filterQuery.last_touch_date_days as any,
            stage: filterQuery.stage as any,
          }),
          query: searchKey,
        },
        pagination,
        sortBy,
      };

      yield call(searchCompanies, searchData, 'myCompanies', filter);

      const fetched: boolean = yield select(searchSelectors.fetched('myCompanies'));

      if (!fetched) {
        yield put(histogramActions.init());
      } else {
        yield put(histogramActions.fetchHistogramFilters(filter, allFiltersWithHistograms));
      }
    } else {
      const errorMessage = 'Error fetching My Companies!';
      yield put(actions.fetchMyCompaniesCardsFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = `Error fetching My Companies - ${e}`;
    yield put(actions.fetchMyCompaniesCardsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* changeCardSaga() {
  const searchKey: string = yield select(searchSelectors.getSearchKey('myCompanies'));
  const filter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getFilter('myCompanies')
  );
  const listType: string = yield select(getListType('myCompanies'));
  const selectedCardId: string = yield select(selectors.getSelectedCardId);
  const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
    filterSelectors.getCustomClearedFilter('myCompanies')
  );
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(
    getFiltersQuery,
    listType === 'combined' ? customClearedFilter : filter
  );
  const allFiltersWithHistograms: string[] = yield select(
    myCompaniesSearchSelectors.selectAllFiltersWithHistograms
  );

  try {
    const res: AxiosResponse<Dictionary<SearchStateData>> = yield call(
      UserService.fetchMyCompaniesCards,
      searchKey,
      {
        ...filterQuery,
        saved_list_id: undefined,
      },
      selectedCardId
    );

    if (res.data) {
      const selectedCard: SearchStateData = yield select(selectors.getSelectedCard);
      const tablePagination: PageInfo = yield select(
        paginationSelectors.getPagination('myCompanies')
      );
      const userPageNumber: number = yield select(
        userInformationSelectors.getMyCompaniesResultsPageNumber
      );

      const longCardPagination: PageInfo = yield select(
        longCardPaginationSelectors.getLongCardPagination('myCompanies')
      );
      const subFilter: string = yield select(selectors.getSubFilter);
      const sortBy: SortByRule<any>[] = yield select(sortSelectors.getSorting);
      const id: number = yield select(getViewId('myCompanies'));
      const listType: string = yield select(getListType('myCompanies'));
      const searchView: SearchViewType = yield select(viewTypeSelectors.searchView('myCompanies'));
      const pagination: PageInfo = {
        pageNumber: 1,
        pageSize: searchView === 'table' ? tablePagination.pageSize : longCardPagination.pageSize,
      };
      const savedListId = selectedCard.filter?.saved_list_id
        ? { saved_list_id: selectedCard.filter?.saved_list_id }
        : { saved_list_id: undefined };
      const searchData: SearchPayloadRequest = {
        searchKey,
        filter: subFilter
          ? {
              ...selectedCard.filter,
              // @ts-ignore
              ...selectedCard.data[subFilter],
              ...filterQuery,
              ...savedListId,
              source_tag_filter: selectedCard.filter.source_tag_filter,
              ...myCompaniesCardsFilters(selectedCard, {
                nextTouchDate: filterQuery.next_touch_date as any,
                lastTouchDateDays: filterQuery.last_touch_date_days as any,
                stage: filterQuery.stage as any,
              }),
              query: searchKey,
            }
          : {
              ...selectedCard.filter,
              ...filterQuery,
              ...savedListId,
              source_tag_filter: selectedCard.filter.source_tag_filter,
              ...myCompaniesCardsFilters(selectedCard, {
                nextTouchDate: filterQuery.next_touch_date as any,
                lastTouchDateDays: filterQuery.last_touch_date_days as any,
                stage: filterQuery.stage as any,
              }),
              query: searchKey,
            },
        pagination,
        sortBy,
        fromSavedList: id,
        listType,
      };

      // save settings on card change
      if (searchData.pagination.pageNumber !== userPageNumber) {
        yield call(updateFilterSettings, searchData, 'myCompanies', {
          my_companies_results_page_number: searchData.pagination.pageNumber,
        });
      } else {
        yield call(
          updateFilterSettings,
          searchData,
          'myCompanies',
          true as unknown as Partial<UpdateUserSettingsPayload>
        );
      }

      yield put(searchCountActions.searchCount({ data: searchData, gridKey: 'myCompanies' }));

      const searchOriginKey: FilterSearchOriginName = 'my_companies';
      const filtersSearchOriginValue: number = yield select(state =>
        companiesFilterSelectors.getFilterSearchOrigin(state, searchOriginKey)
      );

      let payloadData = { ...searchData };

      if (typeof filtersSearchOriginValue === 'number') {
        payloadData = {
          ...payloadData,
          searchOrigin: filtersSearchOriginValue,
        };
      }

      const searchResults: AxiosResponse<Company[]> = yield call(
        SearchService.searchCompanies,
        payloadData,
        false
      );
      const matchData = { ...searchData };
      const newFilterQuery: Dictionary<string | Array<number | string>> = yield call(
        getFiltersQuery,
        filter
      );
      matchData.filter = { ...newFilterQuery, source_tag_filter: [] };
      yield put(
        searchActions.searchCompaniesSuccess(searchResults.data, {
          gridKey: 'myCompanies',
          data: {
            ...searchData,
            filter,
          },
        })
      );
      yield put(
        searchKeyActions.setSearchKey({ data: searchData.searchKey, gridKey: 'myCompanies' })
      );
      const fetched: boolean = yield select(searchSelectors.fetched);

      if (!fetched) {
        yield put(histogramActions.init());
      } else {
        yield put(histogramActions.fetchHistogramFilters(filter, allFiltersWithHistograms));
      }
    } else {
      const errorMessage = 'Error fetching My Companies!';
      yield put(actions.fetchMyCompaniesCardsFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = `Error fetching My Companies - ${e}`;
    yield put(actions.fetchMyCompaniesCardsFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export default function* myCompaniesFilterSaga() {
  yield takeLatest(actions.fetchMyCompaniesCards, fetchMyCompaniesCardsSaga);
  yield takeLatest(actions.initializeMyCompaniesCards, initializeMyCompaniesCardsSaga);
  yield takeLatest(actions.changeCard, changeCardSaga);
}
