import styled from 'styled-components';

interface ChangeRationaleWrapperProps {
  positionedAbsolute?: boolean;
}

const ChangeRationaleWrapper = styled.div<ChangeRationaleWrapperProps>`
  align-items: center;
  background: #fff;
  box-shadow: 0 0 10px #575757;
  border: 1px solid #1890ff;
  margin-top: 16px;
  margin-bottom: 16px;
  width: ${props => (props.positionedAbsolute ? '500px' : '100%')};

  ${props =>
    props.positionedAbsolute &&
    `
    position: absolute;
    top: -150px; 
    z-index: 1;
  `}

  .heading-wrapper {
    padding: 16px;
    border-bottom: 1px solid #c2cfe080;

    h1.ant-typography {
      font-size: 16px !important;
      margin-top: 10px;

      span {
        color: red;
      }
    }
  }

  .body-row {
    padding: 16px 16px 26px;
    display: flex;
    justify-content: center;

    & > div {
      min-width: 300px;
    }
  }

  .cancel-btn {
    border: 1px solid #d9d9d9;
    color: ##595959;
    margin-right: 10px;
  }

  .save-rationale-btn:not(:disabled) {
    background: #1890ff;
    color: #ffffff;
    border: 1px solid #1890ff;
  }
`;

const RationaleVerifiedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  max-width: max-content;

  & > span:first-child {
    font-size: 21px;
  }

  .rationale-label {
    display: inline-block;
    font-weight: 600;
  }

  .rationale-value {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #1890ff;
    font-weight: 600;
    cursor: pointer;

    .rationale-edit-icon {
      font-size: 16px;
      margin-top: -4px;
      visibility: hidden;
    }

    &:hover {
      .rationale-edit-icon {
        visibility: visible;
      }
    }
  }
`;

export const Styled = { ChangeRationaleWrapper, RationaleVerifiedWrapper };
