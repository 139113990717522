import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Typography, Button, Select, Checkbox } from 'antd';
import { useField } from 'formik';
import { orderBy } from 'lodash';
// models
import { FinancialField } from './interface';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { generateYears } from '@optx/utils/option';
// redux
import { actions as editAllInfoActions } from '@features/edit-all-info/state';
// styles
import Styled from './AddYearBox.styled';

interface FieldWithSetValue {
  setValue: (value: FinancialField[]) => void;
}

interface AddYearBoxProps {
  financialData?: FinancialField[];
  selectedYear?: number | undefined;
  isEstimated?: boolean | undefined;
  tabKey?: string;
  fieldId?: string;
  addYearHandler: () => void;
  saveYearHandler: (financial: FinancialField[]) => void;
}

const AddYearBox: React.FC<AddYearBoxProps> = ({
  financialData,
  selectedYear,
  isEstimated,
  tabKey,
  fieldId,
  addYearHandler,
  saveYearHandler,
}) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [estimated, setEstimated] = useState<boolean>(isEstimated ?? false);
  const [yearSelected, setYearSelected] = useState<string | undefined>(
    selectedYear?.toString() ?? undefined
  );
  const [searchYear, setSearchYear] = useState<string | undefined>('');

  const [revenueField, , setRevenueField] = useField<Array<FinancialField>>('revenue');
  const [arrField, , setArrField] = useField<Array<FinancialField>>('annual_recurring_revenue');
  const [ebitdaField, , setEbitdaField] = useField<Array<FinancialField>>('ebitda');
  const [gmField, , setGmField] = useField<Array<FinancialField>>('gm');

  let financialField = financialData
    ? financialData
    : [revenueField.value, arrField.value, ebitdaField.value, gmField.value].find(
        item => item !== undefined
      ) || [];

  useEffect(() => {
    setYearSelected(selectedYear?.toString());
  }, [selectedYear]);

  useEffect(() => {
    setEstimated(isEstimated ?? false);
  }, [isEstimated]);

  if (!financialData && !financialField.length) {
    return null;
  }

  const saveYear = () => {
    if (!yearSelected) return;

    const parseYear = (item: FinancialField) => ({
      year: parseInt(yearSelected),
      estimated,
      value: item.value,
    });

    const updateField = (field: FinancialField[], selectedYear: number) =>
      field.map(item => (item.year === selectedYear ? parseYear(item) : item));

    const updateAndSetField = (field: FieldWithSetValue, updatedField: FinancialField[]) => {
      const orderedField = orderBy(updatedField, 'year', 'desc');
      field.setValue(orderedField);
    };

    let newFinancial = financialField;
    let newRevenue = revenueField.value;
    let newARR = arrField.value;
    let newEBITDA = ebitdaField.value;
    let newGM = gmField.value;

    if (selectedYear) {
      newFinancial = updateField(financialField, selectedYear);
      newRevenue = updateField(revenueField.value, selectedYear);
      newARR = updateField(arrField.value, selectedYear);
      newEBITDA = updateField(ebitdaField.value, selectedYear);
      newGM = updateField(gmField.value, selectedYear);
    } else {
      const newEntry = { year: parseInt(yearSelected), estimated, value: DEFAULT_EMPTY_VALUE };
      newFinancial = [...financialField, newEntry];
      newRevenue = [...revenueField.value, newEntry];
      newARR = [...arrField.value, newEntry];
      newEBITDA = [...ebitdaField.value, newEntry];
      newGM = [...gmField.value, newEntry];
    }

    updateAndSetField(setRevenueField, newRevenue);
    updateAndSetField(setArrField, newARR);
    updateAndSetField(setEbitdaField, newEBITDA);
    updateAndSetField(setGmField, newGM);

    dispatch(editAllInfoActions.addYearChangeLog({ year: parseInt(yearSelected), estimated }));
    saveYearHandler(newFinancial);
  };

  const handleYearChange = (value: string) => {
    setYearSelected(value);

    // when edit a Financial year we can change to estimated or actual
    if (selectedYear && selectedYear.toString() === value && isEstimated !== undefined) {
      if (estimated === isEstimated) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    } else {
      // only one year can be selected as active or estimated
      setIsButtonDisabled(financialField.some(item => item.year === parseInt(value)));
    }
  };

  const handleChange = (value: boolean) => {
    setEstimated(value);

    if (yearSelected) {
      // when edit a Financial year we can change to estimated or actual
      if (selectedYear && selectedYear.toString() === yearSelected && isEstimated !== undefined) {
        if (value === isEstimated) {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }
      } else {
        // only one year can be selected as active or estimated
        setIsButtonDisabled(
          financialField.some(item => item.year.toString() === yearSelected.toString())
        );
      }
    }
  };

  const options =
    isEstimated === undefined
      ? generateYears(parseInt(searchYear as string), financialField, estimated)
      : generateYears(parseInt(searchYear as string));

  const isYearInLimits = options.find(item => item.value === searchYear);

  return (
    <Styled.AddYearBoxWrapper>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Row justify="space-between" className="heading-row">
            <Col>
              <Typography.Title>{selectedYear ? 'Edit Year' : 'Select Year'}</Typography.Title>
            </Col>
            <Col>
              <Button onClick={addYearHandler} className="cancel-btn">
                Cancel
              </Button>
              <Button onClick={saveYear} className="save-year-btn" disabled={isButtonDisabled}>
                Save
              </Button>
            </Col>
          </Row>
          <Row className="body-row">
            <Col span={12} className="year-select">
              <Select
                onChange={(value: string) => {
                  handleYearChange(value);
                  setSearchYear(value);
                }}
                style={{ display: 'block', width: 220 }}
                listHeight={195}
                value={yearSelected ? yearSelected : new Date().getFullYear().toString()}
                onSearch={(value: string) => {
                  setSearchYear(value);
                  setIsButtonDisabled(true);
                }}
                placeholder="Select year"
                options={options}
                open={true}
                virtual={false}
                showSearch
                getPopupContainer={trigger => trigger.parentElement!}
                filterOption={() => {
                  return true;
                }}
              />
            </Col>
            <Col span={12}>
              <Checkbox onChange={e => handleChange(e.target.checked)} checked={estimated}>
                Estimated
              </Checkbox>
              {searchYear && !isYearInLimits && (
                <div className="no-year-found">
                  <Typography.Text style={{ padding: '20px 0 0 0', color: '#ff4d4f' }}>
                    Please add year between {new Date().getFullYear() - 20} and{' '}
                    {new Date().getFullYear() + 5}
                  </Typography.Text>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Styled.AddYearBoxWrapper>
  );
};

export default React.memo(AddYearBox);
