import styled from 'styled-components';

const CompanyTableWrapper = styled.div`
  .ant-table-wrapper {
    position: relative;
    z-index: 1;
  }
  .external-scroll-wrapper,
  .external-scroll {
    bottom: 64px;
  }
  .react-resizable {
    position: relative;
    overflow: hidden;
    background-clip: padding-box;
  }

  .ant-table-body {
    overflow: hidden;
    min-height: 18px;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }

  .dragHandler:hover {
    cursor: move;
    background-color: #ccc;
  }

  .resizeHandle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 5;
  }
`;

export const Styled = {
  CompanyTableWrapper,
};
