import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
// models
import { EditAllCompanyInfoState, EditAllInfo, EditAllFieldsGroup } from './interfaces';
import { CompanyFieldsGroup } from '@optx/models/companyFields';
import { AllFinancialYearsType } from '@optx/models/Financial';
// constants
import { COMPANY_ALL_INFO_KEYS } from '../constants/editAllCompany';
import { EDIT_ALL_LOCALSTORAGE_KEY_PREFIX } from '@optx/constants/autosave';
// utils
import { formatInitialValues } from '@utils/touches';
// redux
import * as actions from './actions';
import { deleteSavedValues } from '@optx/storage/localStorage/autoSaveStorage';

const initialState: EditAllCompanyInfoState = {
  shouldInitialFetch: true,
  open: false,
  companyId: -1,
  companyName: '',
  allFields: [],
  propInfo: [],
  eqtView: [],
  gridOrder: [],
  idInfo: [],
  initialState: null,
  financialYearsChangeLog: [],
  isFinancialsLoaded: false,
  company: {
    company_id: -1,
    company_owner: '',
    owner_img: '',
    company_name: '',
    company_url: '',
    logo_url: '',
    is_in_et: false,
    location: '',
    year_founded: -1,
    company_owner_id: -1,
    stage: '',
    contacts: [],
    pipeline_rank: '',
    source_tag: [],
  },
  error: '',
  disableSaveBtnFor: null,
};

const toggleCompanyEditAllFormDialogReducer: CaseReducer<
  EditAllCompanyInfoState,
  PayloadAction<EditAllInfo>
> = (draftState, action) => {
  const { companyId, companyName, company } = action.payload;
  draftState.isFinancialsLoaded = false;

  draftState.open = !draftState.open;
  draftState.companyId = companyId;
  draftState.companyName = companyName;
  draftState.company = pick(company, COMPANY_ALL_INFO_KEYS);
  draftState.disableSaveBtnFor = null;
  draftState.initialState = null;
};

const getEditAllInfoSuccessReducer: CaseReducer<
  EditAllCompanyInfoState,
  PayloadAction<Record<EditAllFieldsGroup, CompanyFieldsGroup[]>>
> = (draftState, action) => {
  draftState.allFields = action.payload.all_fields;
  draftState.propInfo = action.payload.prop_info;
  draftState.eqtView = action.payload.eqt_view;
  draftState.gridOrder = action.payload.grid_order;
  draftState.idInfo = action.payload.id_info;

  const initialState = {
    ...formatInitialValues(action.payload.all_fields),
    ...formatInitialValues(action.payload.prop_info),
    ...formatInitialValues(action.payload.eqt_view),
    ...formatInitialValues(action.payload.grid_order),
    ...formatInitialValues(action.payload.id_info),
  };

  draftState.initialState = initialState;
  draftState.isFinancialsLoaded = true;
};

const updateCompanyEditAllInfoSuccessReducer: CaseReducer<EditAllCompanyInfoState> = draftState => {
  draftState.open = false;
  draftState.initialState = null;

  deleteSavedValues(EDIT_ALL_LOCALSTORAGE_KEY_PREFIX, draftState.companyId);
};

const changeSaveBtnStatusReducer: CaseReducer<
  EditAllCompanyInfoState,
  PayloadAction<string | null>
> = (draftState, action) => {
  draftState.disableSaveBtnFor = action.payload;
};

const addYearChangeLogReducer: CaseReducer<
  EditAllCompanyInfoState,
  PayloadAction<AllFinancialYearsType>
> = (draftState, action) => {
  draftState.financialYearsChangeLog = {
    ...draftState.financialYearsChangeLog,
    [action.payload.year]: action.payload,
  };
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.toggleCompanyEditAllFormDialog, toggleCompanyEditAllFormDialogReducer)
    .addCase(actions.getEditAllInfoSuccess, getEditAllInfoSuccessReducer)
    .addCase(actions.updateCompanyEditAllInfoSuccess, updateCompanyEditAllInfoSuccessReducer)
    .addCase(actions.changeSaveBtnStatus, changeSaveBtnStatusReducer)
    .addCase(actions.addYearChangeLog, addYearChangeLogReducer)
);

export default reducer;
