import React, { useEffect, useState } from 'react';
import { Button, Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
//constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';
import { SingleSelectFieldWrapper } from './SingleSelectField.styled';

const { Option } = Select;

interface SingleSelectFieldProps {
  field?: BaseField;
  value?: string;
  fieldName?: string;
  fieldType?: string;
  options?: OptionTypeBase[];
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
  fieldUsedFor?: FieldUsedFor;
}

const SingleSelectField: React.FC<SingleSelectFieldProps> = ({
  field,
  fieldName,
  fieldType,
  options,
  value,
  isChromePlugin,
  onSave,
  onCancel,
  fieldUsedFor,
}) => {
  const [selected, , setSelectedOption] = useField<string>(field?.id || fieldType || '');
  const [closePopup, setClosePopup] = useState(false);

  const blankValue = fieldType === 'next_steps' ? 'blank' : '';
  const tooltipTitle = value && value !== blankValue ? value?.toString() : DEFAULT_EMPTY_VALUE;
  const selectValue = selected.value || value || blankValue;

  const handleChange = (value: string) => {
    setSelectedOption.setValue(value);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      setSelectedOption.setValue(value);
    }

    if (!value && visibility) {
      setSelectedOption.setValue('');
    }
  };

  useEffect(() => {
    if (
      (field?.value || field?.value === '') &&
      fieldUsedFor !== 'editAllInfo' &&
      (!selected.value || !selected.value.length)
    ) {
      if (typeof field.value === 'object' && field.value.value) {
        setSelectedOption.setValue(field.value.value);
      } else {
        setSelectedOption.setValue(field.value);
      }
    }

    if (value) {
      setSelectedOption.setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, value]);

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            name={field.id}
            value={selected.value}
            onChange={handleChange}
            disabled={field.editable === false}
            getPopupContainer={trigger => trigger.parentElement!}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = option ? `${option?.children.props.children}` : '';

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {field.data &&
              field.data.length &&
              field.data?.map((option: OptionTypeBase) => (
                <Option key={option.value as string} value={option.value as string}>
                  <span>{option.label}</span>
                </Option>
              ))}
          </Select>
        </FormItem>
      </Col>
    );
  }

  const content = (
    <FormItem name={fieldType ?? ''}>
      <SingleSelectFieldWrapper
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <Select
          showSearch
          autoFocus
          defaultOpen
          name={fieldType ?? ''}
          style={{ width: '300px' }}
          value={selectValue}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = `${option?.children.props.children}`;

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {options?.map((option: OptionTypeBase) => (
            <Option key={option.value as string} value={option.value as string}>
              <span>{option.label}</span>
            </Option>
          ))}
        </Select>
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={checkSaveButton(selected.value, value)}
            onClick={() => {
              onSave && onSave(fieldType);
              setClosePopup(true);
            }}
          >
            Save
          </Button>
        </div>
      </SingleSelectFieldWrapper>
    </FormItem>
  );

  return (
    <EditPopover
      setClosePopup={setClosePopup}
      closePopup={closePopup}
      customOverlayStyle={{ width: '480px' }}
      content={content}
      onVisibilityUpdate={handleVisibilityChange}
      destroyOnHide={true}
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
    >
      <TruncateTooltip title={tooltipTitle}>{tooltipTitle}</TruncateTooltip>
    </EditPopover>
  );
};

export default React.memo(SingleSelectField);
