import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import styled from 'styled-components';
// hooks
import { useHasOverflow } from '@optx/common/hooks/useHasOverflow';

type TruncateTooltipProps = TooltipProps & {
  maxWidth?: string;
  maxTooltipWidth?: string;
};

const DefaultTooltip = styled(Tooltip)`
  display: inline;
`;

const ContentWrapper = styled.div<{ $maxWidth?: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}`};
`;

const TruncateTooltip: React.FC<TruncateTooltipProps> = ({
  title,
  className,
  children,
  maxWidth,
  maxTooltipWidth,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasOverflow = useHasOverflow(ref, title as string);

  return (
    <ContentWrapper ref={ref} $maxWidth={maxWidth}>
      {hasOverflow ? (
        <DefaultTooltip
          title={title}
          className={className}
          overlayStyle={{ maxWidth: maxTooltipWidth }}
          {...rest}
        >
          {/* wrapping children with a div can cause problems with overflow detection */}
          {children}
        </DefaultTooltip>
      ) : (
        <>{children}</>
      )}
    </ContentWrapper>
  );
};

export default TruncateTooltip;
