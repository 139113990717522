import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import AntIcon from '@ant-design/icons';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
// models
import { FieldChangedValue } from '@optx/features/company/audit-trail/state/interfaces';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
import { companyPageTop } from '@optx/constants/pendoActions';
// hooks
import { useHistoryTab } from '@optx/common/hooks/history';
// redux
import { selectors as profileSelectors } from '@optx/redux/company/profile';
import { actions as editFieldActions } from '@optx/features/company/edit-fields/state';
import { getIsAdminOrAnalyst } from '@optx/redux/user/information/selectors';
// components
import Icon from '@optx/components/common/Icon';
import { UserService } from '@optx/services/api';
import { EditFieldRadioRationale } from '@optx/features/company/edit-fields/components';
import Styles from './PSGFit.style';

const PSGFitDefault: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const company = useSelector(profileSelectors.getProfile);
  const { updateHistoryFields } = useHistoryTab();
  const { tab } = queryString.parse(location.search);
  const isAdminOrAnalyst = useSelector(getIsAdminOrAnalyst);

  if (!company) {
    return null;
  }

  const handleSelect = (val: boolean) => {
    const payload = {
      companyId: company.company_id,
      value: val,
      successMessage: 'Company PSG fit updated successfully!',
    };
    dispatch(
      editFieldActions.updateFields({
        companyUrl: company.company_url,
        companyId: company.company_id,
        value: payload,
        service: UserService.updateFitEvaluation,
        fieldName: 'PSG Fit',
      })
    );
    updateHistoryFields({
      afterValue: val !== null ? val : 'None',
      beforeValue: company.is_interesting !== null ? company.is_interesting : 'None',
      fieldChanged: FieldChangedValue.psgFit,
    });
  };

  return (
    <Row className="fit-default">
      <Styles.TextWrapper>
        <Styles.FitText>PSG FIT</Styles.FitText>
        <Styles.SelectText>(Select)</Styles.SelectText>{' '}
      </Styles.TextWrapper>
      <Col>
        <Row>
          <Styles.ThumbUp
            onClick={() => {
              window.pendo.track(companyPageTop.psgFitThumbsUp);
              handleSelect(true);
            }}
          >
            <AntIcon
              component={() => (
                <Icon
                  iconName="thumbs-up"
                  style={{ color: '#E8E8E8', fontSize: 27 }}
                  onClick={() => {
                    window.pendo.track(companyPageTop.psgFitThumbsUp);
                  }}
                />
              )}
            />
          </Styles.ThumbUp>

          {isAdminOrAnalyst ? (
            <EditFieldRadioRationale
              record={company}
              value={company.is_interesting}
              isFromGrid={false}
              service={UserService.updateFitEvaluation}
              successMessage="Company PSG fit updated successfully!"
              errorMessage="Failed to update value!"
              fieldName="PSG Fit"
              isFromHistory={tab === CompanyProfileTabs.HISTORY}
              placement="leftTop"
              updateRadioValue={false}
              destroyOnHide
              showOnlyRadio
              hidePen
            >
              <Styles.ThumbDown>
                <AntIcon
                  component={() => (
                    <Icon
                      iconName="thumbs-down"
                      style={{ color: '#E8E8E8', fontSize: 27 }}
                      onClick={() => {
                        window.pendo.track(companyPageTop.psgFitThumbsDown);
                      }}
                    />
                  )}
                />
              </Styles.ThumbDown>
            </EditFieldRadioRationale>
          ) : (
            <Styles.ThumbDown
              onClick={() => {
                window.pendo.track(companyPageTop.psgFitThumbsDown);
                handleSelect(false);
              }}
            >
              <AntIcon
                component={() => (
                  <Icon iconName="thumbs-down" style={{ color: '#E8E8E8', fontSize: 27 }} />
                )}
              />
            </Styles.ThumbDown>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PSGFitDefault;
