import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
// models
import { SalesloftResponse } from '@optx/models/Salesloft';
// utils
import { getContactErrors } from '@optx/utils/salesloft';
import { handlePendoAction } from '@optx/utils/utils';
// redux
import {
  selectors as salesloftSelectors,
  actions as salesloftActions,
} from '@redux/contacts/salesloft';
import {
  selectors as primaryContactsSelectors,
  actions as primaryContactsActions,
} from '@redux/company/primary-contacts';
// hooks
import { useInitFilters } from '@hooks/init';
import { useInjectSalesloft } from '@hooks/inject';
import { useSalesloftAuth } from '@hooks/salesloft';
import {
  useInitialFetchEditOptions,
  useInject as useInjectCompanyIndividualEdit,
} from '@optx/components/feature/company-individual-edit';
import { useInjectCompanyProfile } from '@components/pages/CompanyProfile/hooks';
// services
import NotificationService from '@services/NotificationService';
// components
import SalesloftCadence from './components/SalesloftCadence';
import Header from './components/Header';
import Confirmation from './components/Confirmation';
import PageLoader from './components/PageLoader';
import { MainContent } from '../../common/layout';
import { SalesloftContext } from './SalesloftContext';
import EditContactFormSalesloft from './components/EditContactFormSalesloft';
// styles
import './styles.scss';

const useInjectReducerSaga = () => {
  useInjectSalesloft();
  useInjectCompanyIndividualEdit();
  useInjectCompanyProfile();
};

const Salesloft: React.FC = () => {
  useInjectReducerSaga();
  useInitFilters();
  useInitialFetchEditOptions();

  const contacts = useSelector(primaryContactsSelectors.getPrimaryContacts);
  const postData = useSelector(salesloftSelectors.getPostData);

  const { fetchSalesloftAuth } = useSalesloftAuth();
  const match = useRouteMatch<string>();
  const {
    // @ts-ignore
    params: { id },
  } = match;
  const dispatch = useDispatch();
  const [confirmationRes, setConfirmationRes] = useState<SalesloftResponse>();
  const [cadenceId, setCadenceId] = useState<number | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const loading = useSelector(salesloftSelectors.isLoading);

  useEffect(() => {
    dispatch(primaryContactsActions.fetchPrimaryContacts(id));

    return () => {
      dispatch(salesloftActions.salesloftReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Manually trigger validation for emails aftet contacts changes
  useEffect(() => {
    const errors = getContactErrors(contacts, postData.contactIds);
    setErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const handleSubmit = () => {
    const errors = getContactErrors(contacts, postData.contactIds);

    if (cadenceId && !postData.contactIds.length) {
      NotificationService.error(
        'No contact was selected for Sales Loft. Please select at least one contact or deselect the cadence.'
      );
    } else if (errors.length) {
      setErrors(errors);
    } else {
      dispatch(
        salesloftActions.postSalesloftData({ id: '' }, (res: SalesloftResponse) => {
          if (res) {
            setConfirmationRes(res);
          } else {
            fetchSalesloftAuth('', (url: string) => {
              window.open(
                url,
                'popUpWindow',
                // eslint-disable-next-line max-len
                'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
              );
            });
          }
        })
      );
    }

    window.pendo.track(handlePendoAction('AddToSalesLoftCadence'));
  };

  useEffect(() => {
    return () => {
      dispatch(primaryContactsActions.clearPrimaryContacts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContent className="add-to-salesloft main-content">
      <PageLoader isLoading={loading} />
      {confirmationRes ? (
        <Confirmation res={confirmationRes} />
      ) : (
        <SalesloftContext.Provider
          value={{
            cadenceId,
            setCadenceId,
            errors,
            setErrors,
          }}
        >
          <Header handleSubmit={handleSubmit} />
          <SalesloftCadence />
          <EditContactFormSalesloft />
        </SalesloftContext.Provider>
      )}
    </MainContent>
  );
};

export default Salesloft;
