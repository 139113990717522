import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Button, Row, Collapse, Typography } from 'antd';
// models
import { BaseField, CompanyFieldsGroup } from '@optx/models/companyFields';
// utils
import { focusNextElActions } from '@utils/focusActions';
// redux
import {
  actions as companyEditAllActions,
  selectors as companyEditAllSelectors,
} from '@optx/features/edit-all-info/state';
// components
import FieldFactory from '@features/field-factory';
import AddYearBox from '@optx/features/field-factory/AddYearBox';
// styles
import { Styled } from './TouchPropInfo.styled';

interface TouchPropInfoProps {
  touchFields: CompanyFieldsGroup<BaseField>[];
  className: string;
  touchParentType?: 'complete' | 'scheduled';
}

const TouchPropInfo: React.FC<TouchPropInfoProps> = ({
  touchFields,
  className,
  touchParentType,
}) => {
  const dispatch = useDispatch();

  const saveBtnStatus = useSelector(companyEditAllSelectors.getDisableSaveBtnFor);

  const [accordionKey, setAccordionKey] = useState<string | string[]>(
    touchParentType === 'complete' ? ['1'] : []
  );
  const [addYear, setAddYear] = useState(false);

  const [showAddYear, setShowAddYear] = useState<boolean>(true);
  const [expandBtnText, setExpandBtnText] = useState<string>('Expand All');

  const keyArray = touchFields.map((fieldsSource: CompanyFieldsGroup) =>
    fieldsSource.id.toString()
  );

  const handleCardToggle = (key: string | string[]) => {
    setAccordionKey(key);

    setShowAddYear(
      (typeof key === 'string' && key === '1') || (Array.isArray(key) && key.includes('1'))
    );

    if (key.length !== keyArray.length) {
      setExpandBtnText('Expand All');
    } else {
      setExpandBtnText('Collapse All');
    }

    const lastKey = keyArray[keyArray.length - 1];

    if (lastKey && key.includes(lastKey) && !accordionKey.includes(lastKey)) {
      setTimeout(() => {
        const drawerHTML = document.querySelector('.ant-drawer-body') as HTMLElement;
        const drawerInnerHTML = document.querySelector('.ant-drawer-body-inner') as HTMLElement;
        drawerHTML?.scrollTo({ top: drawerInnerHTML.clientHeight, behavior: 'smooth' });
      }, 300);
    }

    focusNextElActions(
      '.touch-prop-info .ant-collapse-header[aria-expanded="true"]:focus',
      '.ant-col:first-child input'
    );
  };

  const toggleCollapseAll = () => {
    if (accordionKey.length === keyArray.length) {
      setAccordionKey([]);

      if (showAddYear) {
        setShowAddYear(false);
      }

      setExpandBtnText('Expand All');
    } else {
      setAccordionKey(keyArray);

      if (!showAddYear) {
        setShowAddYear(true);
      }

      setExpandBtnText('Collapse All');
    }
  };

  useEffect(() => {
    setAccordionKey(touchParentType === 'complete' ? ['1'] : []);
    setExpandBtnText('Expand All');
    setShowAddYear(touchParentType !== 'scheduled');
  }, [touchParentType]);

  useEffect(() => {
    if (saveBtnStatus && saveBtnStatus !== 'editAllInfo') {
      setAddYear(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBtnStatus]);

  const saveYearHandler = () => {
    setAddYear(!addYear);

    dispatch(companyEditAllActions.changeSaveBtnStatus(null));
  };

  const addYearHandler = () => {
    setAddYear(!addYear);

    const btnStatus = !addYear ? 'editAllInfo' : null;

    dispatch(companyEditAllActions.changeSaveBtnStatus(btnStatus));
  };

  return (
    <Styled.TouchPropInfoContainer>
      <Row gutter={[48, 24]} className={`touch-prop-info ${className}`}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h1 className="touch-prop-info--title">Edit Proprietary Info</h1>
            </Col>
            <Col>
              <Button onClick={toggleCollapseAll} className="link-btn">
                {expandBtnText}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[48, 48]}>
            <Collapse
              bordered={false}
              expandIconPosition="right"
              activeKey={accordionKey}
              onChange={key => handleCardToggle(key)}
            >
              {touchFields.map((fieldsSource: CompanyFieldsGroup, index: number) => (
                <Collapse.Panel
                  header={
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography>{fieldsSource.label}</Typography>
                      {fieldsSource.id === 1 && showAddYear && (
                        <Button
                          style={{ marginRight: '10px' }}
                          className="link-btn"
                          onClick={e => {
                            e.stopPropagation();
                            addYearHandler();
                          }}
                          tabIndex={-1}
                        >
                          +Add Years
                        </Button>
                      )}
                    </Row>
                  }
                  key={fieldsSource.id}
                  forceRender={true}
                >
                  {fieldsSource.id === 1 && addYear && (
                    <AddYearBox addYearHandler={addYearHandler} saveYearHandler={saveYearHandler} />
                  )}
                  <Row gutter={[12, 16]} className={`prop-info--row-${index}`}>
                    {fieldsSource &&
                      fieldsSource.data?.map((field: BaseField) => (
                        <FieldFactory
                          key={field.id}
                          field={field as BaseField}
                          fieldUsedFor="companyTouches"
                        />
                      ))}
                  </Row>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Row>
        </Col>
      </Row>
    </Styled.TouchPropInfoContainer>
  );
};

export default TouchPropInfo;
