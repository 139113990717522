import { Input } from 'antd';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook for focusing an input element.
 *
 * @param {boolean} focus - A boolean indicating whether the input should be focused.
 * @param {boolean} closePopup - A boolean indicating whether a popup should be closed.
 * @returns {React.RefObject<Input>} - A ref object that can be used to access the input element.
 */
const useFocusInput = (focus: boolean, closePopup: boolean): React.RefObject<Input> => {
  const inputRef = useRef<Input>(null);

  /**
   * Callback function to focus the input element.
   *
   * @param {Input | null} input - The input element or null.
   */
  const focusInputRef = useCallback(
    input => {
      if (input && focus && !closePopup) {
        setTimeout(() => {
          input.focus();
        }, 300);
      }
    },
    [focus, closePopup]
  );

  useEffect(() => {
    if (inputRef.current) {
      focusInputRef(inputRef.current);
    }
  }, [focusInputRef]);

  return inputRef;
};

export default useFocusInput;
