import { useInjectReducer } from 'redux-injectors';
// local
import { reducer } from './state';
import { featureName } from './featureName';

const useInject = () => {
  useInjectReducer({ key: featureName, reducer });
};

export default useInject;
