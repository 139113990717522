import { Button } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// constants
import appRoutes from '@constants/routes';
// hooks
import useIsAnalyst from '@hooks/useIsAnalyst';
// components
import FixedLoader from './loader';

const StyledWrapper = styled.div`
  padding-top: 100px;
  text-align: center;
`;

interface ErrorComponentProps {
  onRedirect?: VoidFunction;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ onRedirect }) => {
  const { advancedSearchPathname } = useIsAnalyst();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const pathname = location.pathname;
  const addCompany = query.get('addCompany');
  const companyUrl = query.get('companyUrl');

  const allowedParams = new Set(['addCompany', 'companyUrl']);
  const hasOnlyAllowedParams = Array.from(query.keys()).every(key => allowedParams.has(key));

  if (
    hasOnlyAllowedParams &&
    addCompany === 'true' &&
    companyUrl &&
    pathname === appRoutes.advancedSearch
  ) {
    return <FixedLoader loading={true} />;
  }

  return (
    <StyledWrapper>
      <h3>Something went wrong</h3>

      <Link to={advancedSearchPathname} onClick={onRedirect}>
        <Button size="small">Go Back </Button>
      </Link>
    </StyledWrapper>
  );
};

export default React.memo(ErrorComponent);
