import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
// models
import { Company, FoundCompany } from '@optx/models/Company';
// constants
import { OPTXChromePlugin } from '@optx/constants/pendoActions';
// redux
import { selectors } from 'src/components/feature/company-individual-edit/state/index';
// components
import Icon from '@optx/components/common/Icon';
import FixedLoader from '@optx/components/common/loader';
// styles
import Styled from './styled/Header.styled';
import { NewTabIcon } from './styled/ChromeExtension.styled';

import ActionDropdown from './ActionDropdown';
import LogOut from './LogOut';

interface ChromeExtensionHeaderProps {
  selectedCompany: Company | null;
  foundCompanies: Array<FoundCompany>;
  onCloseClick: () => void;
  onLogOut: () => void;
}

const ChromeExtensionHeader: React.FC<ChromeExtensionHeaderProps> = ({
  selectedCompany,
  foundCompanies,
  onCloseClick,
  onLogOut,
}) => {
  const loading = useSelector(selectors.companyIndividualEdit.isLoadingCompanyOwnerInfo);

  const logOutMenu = (
    <Styled.ActionMenu selectedKeys={[]}>
      <LogOut onClick={onLogOut} />
    </Styled.ActionMenu>
  );

  return (
    <Styled.ChromeExtensionHeader>
      <FixedLoader loading={loading} />
      <>
        <Icon iconName="chrome-optx-logo" className="optx-logo" />
        <div className="buttons-container">
          {selectedCompany && foundCompanies.length ? (
            <Link
              to={`/profile/${selectedCompany.company_id}`}
              target="_blank"
              onClick={() => window.pendo.track(OPTXChromePlugin.topHeader.view)}
            >
              <Button className="view-in-optx-btn" type="primary">
                View in OPTX <NewTabIcon />
              </Button>
            </Link>
          ) : null}
          {selectedCompany && foundCompanies.length ? (
            <ActionDropdown record={selectedCompany} onLogOut={onLogOut} />
          ) : (
            <div className="dropdown-block dropdown-tags empty-popover-wrapper">
              <Dropdown overlay={logOutMenu} trigger={['click']} placement="bottomRight">
                <Styled.DotsButton>
                  <Icon iconName="chrome-dots-button" className="dots-button" />
                </Styled.DotsButton>
              </Dropdown>
            </div>
          )}
          <button type="button" onClick={onCloseClick}>
            <Icon iconName="chrome-close" />
          </button>
        </div>
      </>
    </Styled.ChromeExtensionHeader>
  );
};

export default ChromeExtensionHeader;
