/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, useState, ReactNode, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Typography, Row, Tooltip } from 'antd';
// models
import { CompanyProfile } from '@optx/models/Company';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { COMPANY_NAME_ENDPOINT } from '@constants/asyncEndpoints';
import { SEARCH_MINIMUM_CHAR_COUNT } from '@optx/constants/search';
// utils
import { parseNumberFromAbbreviation } from '@utils/number';
// hooks
import { useOnClickOutside } from '@optx/common/hooks/useOnClickOutside';
// redux
import { updateCompanyUserValues } from '@redux/company/user-values/actions';
// components
import EditPopover from '@components/common/popover/EditPopover';
import { message } from '@optx/constants/table/cells';
import { SelectOption } from '@optx/models/Option';
import { OptionsType } from 'react-select';
import styled from 'styled-components';
import { AsyncSingleSelect, SingleSelect } from '@optx/shared/view/molecules/Select';
import useAsyncSearch from '@optx/common/hooks/select/useAsyncSearch';
import { selectors as fundingRoundsSelector } from '@redux/company/funding-rounds';
import { SELECT_UNKNOWN } from '@optx/components/common/select/constants';
import { SuccessErrorCallback } from '@optx/models/callback';
import { TagOutlined } from '@ant-design/icons';
import { useHistoryTab } from '@optx/common/hooks/history';

interface ProfileUserValuesProps {
  company: CompanyProfile;
  labelValue?: string;
  validation?: (value: string) => boolean;
  getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
  formatValue?: (value: number | string | null | undefined) => string;
  lastTemporaryFieldValue?: string | number;
  isProfileInformation?: boolean;
  hidePenIcon?: boolean;
}

interface SelectType {
  label: string | null;
  value: string | number | null;
}

const ContentContainer = styled.div`
  .ant-row {
    padding: 2px 0 !important;

    .ant-typography {
      padding-bottom: 2px;
    }
  }
`;

const SelectAsyncContainer = styled(AsyncSingleSelect)`
  width: 100%;
`;
const SelectContainer = styled(SingleSelect)`
  width: 100%;
`;

const title = 'Edit "Last Round" value';
const placeholder = 'Enter last round value';

const loadValue = (
  value: string | number | null,
  formatValue?: (value: number | string | null | undefined) => string
) => {
  if (value) {
    if (formatValue) return formatValue(value);

    return value || '';
  }

  return '0.0';
};

const ProfileLastRound: React.FC<ProfileUserValuesProps> = ({
  company,
  labelValue,
  validation,
  formatValue,
  getPopupContainer,
  isProfileInformation = false,
  hidePenIcon,
  lastTemporaryFieldValue,
}) => {
  const dispatch = useDispatch();
  const { updateHistoryFields } = useHistoryTab();
  const [value, setValue] = useState<string | number>(
    loadValue(company.last_raised_amount, formatValue)
  );
  const [isButtonVisible, setButtonVisible] = useState(true);

  const lastTemporaryFieldValueFormated =
    formatValue && company.last_raised_amount !== null
      ? formatValue(lastTemporaryFieldValue ?? company.last_raised_amount)
      : DEFAULT_EMPTY_VALUE;
  const [label, setLabel] = useState(loadValue(company.last_raised_amount, formatValue));
  const [extraValue, setExtraValue] = useState<string | ReactNode>(
    company.last_round ? `(${company.last_round})` : ''
  );
  const popoverContentRef = useRef(null);
  const lastTemporarySelectedValue = {
    label: company.last_round,
    value: company.last_round_id,
  };

  const [selectedType, setSelectedType] = useState<SelectType>({
    label: company.last_round,
    value: company.last_round_id,
  });
  const [selectedAcquiredCompany, setSelectedAcquiredCompany] = useState<SelectType>({
    label: company.parentcompany,
    value: company.parentcompany,
  });
  const [fetchedOptions, setFetchedOptions] = useState<OptionsType<SelectOption>>([SELECT_UNKNOWN]);
  const [closePopup, setClosePopup] = useState(false);
  const { loadOptions } = useAsyncSearch({ endpoint: COMPANY_NAME_ENDPOINT });
  const fundingTypes = useSelector(fundingRoundsSelector.getFundingTypes);

  const updateValue = useCallback(() => {
    setValue(loadValue(company.last_raised_amount, formatValue));
    setSelectedType({
      label: company.last_round,
      value: company.last_round_id,
    });
    setSelectedAcquiredCompany({
      label: company.parentcompany,
      value: company.parentcompany,
    });
  }, [company, formatValue]);

  useOnClickOutside(popoverContentRef, () => updateValue());

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (validation) {
      if (validation(value)) {
        if (value === '') {
          setValue(value);
          setButtonVisible(false);

          return;
        }

        setValue(value);
      } else {
        message().error('Field only supports numbers and letters for abbreviation (K, M, B, T)');
      }
    } else {
      setValue(value);
    }

    setButtonVisible(true);
  };

  const handleSaveCallback = (data?: SuccessErrorCallback) => {
    if (data) {
      setLabel(value ? (parseNumberFromAbbreviation(value.toString()) as number) : '$0.0');

      if (selectedType?.label) {
        setExtraValue(`(${selectedType?.label})`);
      }

      if (lastTemporaryFieldValueFormated !== value && formatValue) {
        updateHistoryFields({
          afterValue: value === '' ? null : value,
          beforeValue:
            lastTemporaryFieldValueFormated === DEFAULT_EMPTY_VALUE
              ? null
              : lastTemporaryFieldValueFormated,
          fieldChanged: 'Last Raised Amount',
        });
      }

      if (
        lastTemporarySelectedValue.value !== undefined &&
        lastTemporarySelectedValue.value !== selectedType.value
      ) {
        updateHistoryFields({
          afterValue: selectedType.label,
          beforeValue: lastTemporarySelectedValue.label,
          fieldChanged: 'LAST ROUND',
        });
      }
    }
  };

  const handleSave = () => {
    dispatch(
      updateCompanyUserValues(
        {
          companyId: company.company_id,
          fieldKey: 'last_raised_amount',
          lastRoundData: {
            last_round: selectedType?.label as string,
            last_round_id: selectedType?.value as number,
            parentcompany: (selectedAcquiredCompany?.value ?? '') as string,
          },
          value: parseNumberFromAbbreviation(value.toString()),
        },
        handleSaveCallback
      )
    );
    setClosePopup(true);
  };

  const handleCancel = () => setClosePopup(true);

  const handleDashLastRoundAmount = () => {
    if (company.last_raised_amount === null) {
      return `- ${extraValue || ''}`;
    }

    const lastRoundValue = label
      ? formatValue
        ? `$${formatValue(label)}`
        : label
      : DEFAULT_EMPTY_VALUE;

    return `${lastRoundValue} ${extraValue}`;
  };

  const onVisibilityChange = () => {
    setValue(loadValue(company.last_raised_amount, formatValue));
  };

  useEffect(() => {
    setValue(loadValue(company.last_raised_amount, formatValue));
    setLabel(loadValue(company.last_raised_amount, formatValue));
    setSelectedType({
      label: company.last_round,
      value: company.last_round_id,
    });
    setExtraValue(company.last_round ? `(${company.last_round})` : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    const visible =
      (selectedType?.label !== 'Acquisition' ||
        (selectedType?.label === 'Acquisition' && selectedAcquiredCompany?.value !== null)) &&
      value !== '';
    setButtonVisible(visible);
  }, [selectedAcquiredCompany, selectedType, value]);

  const getLoadOptionsDelay = (query: string, callback: SuccessErrorCallback) => {
    if (query.length >= SEARCH_MINIMUM_CHAR_COUNT) {
      loadOptions(query, options => {
        options.unshift(SELECT_UNKNOWN);
        const callbackResult = callback(options);

        // set current filter to a cached variable.
        setFetchedOptions(options);

        return callbackResult;
      });
    }
  };

  // when menu is closed, clear cached values.
  const onAsyncMultiselectMenuClose = () => {
    setFetchedOptions([SELECT_UNKNOWN]);
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const content = (
    <ContentContainer
      className="profile-information__financial"
      ref={popoverContentRef}
      onKeyDown={handleDropdownKeyEvent}
      tabIndex={0}
    >
      <Row>
        <Typography.Text>Funding Type</Typography.Text>
        <SelectContainer
          options={fundingTypes}
          onChange={(input: SelectType) => {
            if (input.label !== 'Acquisition' && selectedAcquiredCompany.label !== null) {
              setSelectedAcquiredCompany({
                label: null,
                value: null,
              });
            }

            setSelectedType(input);
          }}
          value={selectedType}
          placeholder="Please Select"
          closeMenuOnSelect
          SearchIcon={null}
        />
      </Row>
      {selectedType?.label === 'Acquisition' && (
        <Row>
          <Typography.Text>Acquiring company</Typography.Text>
          <SelectAsyncContainer
            className="addon--multiselect-async"
            onChange={(value: SelectOption) => setSelectedAcquiredCompany(value)}
            loadOptions={getLoadOptionsDelay}
            controlShouldRenderValue
            closeMenuOnSelect
            defaultOptions={fetchedOptions}
            required={selectedType?.label === 'Acquisition'}
            defaultValue={selectedAcquiredCompany}
            value={selectedAcquiredCompany}
            closeOnSelect
            onSelectResetsInput={false}
            blurInputOnSelect={false}
            onMenuClose={onAsyncMultiselectMenuClose}
            autoFocus
          />
        </Row>
      )}
      <Row>
        <Typography.Text>Funding Amount</Typography.Text>
        <Input value={value} onChange={handleChangeInput} placeholder={placeholder} />
      </Row>
      <div className="profile-information__popover-buttons">
        <Button className="profile-information__cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isButtonVisible}
          className="profile-information__save"
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </ContentContainer>
  );

  if (labelValue)
    return (
      <EditPopover
        content={content}
        title={title}
        closePopup={closePopup}
        setClosePopup={setClosePopup}
        onVisibilityChange={onVisibilityChange}
        hidePenIcon={hidePenIcon}
        getPopupContainer={getPopupContainer}
        placement="bottom"
      >
        {labelValue}
      </EditPopover>
    );

  return !isProfileInformation ? (
    <div className="media-object company-kpi">
      <div className="media-object-section">
        <div className="company-kpi__icon">
          <TagOutlined />
        </div>
      </div>
      <div className="media-object-section">
        <div className="company-kpi__title company-kpi__edit">
          <EditPopover
            content={content}
            title={title}
            closePopup={closePopup}
            setClosePopup={setClosePopup}
            onVisibilityChange={onVisibilityChange}
            hidePenIcon={false}
            getPopupContainer={getPopupContainer}
            placement="bottom"
          >
            {handleDashLastRoundAmount()}
          </EditPopover>
        </div>
        <div className="company-kpi__label">Last Round</div>
      </div>
    </div>
  ) : (
    <EditPopover
      content={content}
      title={title}
      closePopup={closePopup}
      setClosePopup={setClosePopup}
      onVisibilityChange={onVisibilityChange}
      getPopupContainer={getPopupContainer}
      hidePenIcon={hidePenIcon}
    >
      <Tooltip title={handleDashLastRoundAmount()}>{handleDashLastRoundAmount()}</Tooltip>
    </EditPopover>
  );
};

export default React.memo(ProfileLastRound);
