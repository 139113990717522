import { createSelector } from 'reselect';
// constants
import { DEFAULT_SORTING_USER_SETTINGS } from '@optx/constants/filters';
// redux
import { selectors as userInformationSelectors } from '../../user/information';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as companyFiltersSelectors } from '../../company/filters';
import { selectors as detailListSelectors } from '@redux/lists/details';
import { selectors as savedSearchSelectors } from '../../company/saved-searches';

export const shouldInitialSearch = createSelector(
  userInformationSelectors.getSessionSettings,
  userInformationSelectors.loaded,
  companyFiltersSelectors.filtersLoaded,
  detailListSelectors.getFilterListId,
  searchSelectors.shouldInitialFetch('lists'),
  userInformationSelectors.getDefaultViewId,
  savedSearchSelectors.loaded,
  (
    persistFilters,
    loadedUserInfo,
    loadedFilters,
    getFilterListId,
    shouldFetchCompanies,
    defaultViewId,
    loadSavedSearches
  ) => {
    if (
      ((!persistFilters.user_list_sorting && !persistFilters.user_list_filters) ||
        (persistFilters.user_list_filters === '' &&
          persistFilters.user_list_sorting === DEFAULT_SORTING_USER_SETTINGS)) &&
      getFilterListId &&
      !defaultViewId &&
      !window.location.search
    ) {
      return loadedUserInfo && shouldFetchCompanies;
    }

    return loadedUserInfo && loadedFilters && shouldFetchCompanies && loadSavedSearches;
  }
);
