import React, { ReactNode, useCallback } from 'react';
import classnames from 'classnames';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// services
import { CompanyService, UserService } from '@optx/services/api';
// models
import { CompanyProfile, CompanyUserValuesKeys } from '@optx/models/Company';
// components
import EditFieldText from '@optx/features/company/edit-fields/components/EditFieldText';

interface CompanyEditKPIProps {
  company: CompanyProfile;
  icon: ReactNode;
  label: string;
  value: string | number;
  fieldKey: CompanyUserValuesKeys;
  className?: string;
}

const CompanyEditKPI: React.FC<CompanyEditKPIProps> = ({
  company,
  className,
  icon,
  fieldKey,
  value,
  label,
}) => {
  const location = useLocation();
  const { tab } = queryString.parse(location.search);
  const kpiClassName = classnames('media-object company-kpi', className);

  const service =
    fieldKey === 'capital_raised'
      ? UserService.updateCompanyInfo
      : CompanyService.updateCompanyData;

  const handleOptions = useCallback(() => {
    if (fieldKey === 'capital_raised') {
      return 'Capital Raised';
    }

    return 'Number of Employees';
  }, [fieldKey]);

  return (
    <div className={kpiClassName}>
      <div className="media-object-section">
        <div className="company-kpi__icon">{icon}</div>
      </div>
      <div className="media-object-section">
        <div className="company-kpi__title company-kpi__edit">
          <EditFieldText
            isFromGrid={false}
            fieldName={handleOptions()}
            record={company}
            service={service}
            value={value}
            refreshOnChange={tab === CompanyProfileTabs.HISTORY}
          />
        </div>
        <div className="company-kpi__label">{label}</div>
      </div>
    </div>
  );
};

export default CompanyEditKPI;
