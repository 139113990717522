import React from 'react';
import Styles from './ChartLegend.style';

type ChartLegendProps = {
  type: string;
};

const ChartLegend: React.FC<ChartLegendProps> = ({ type }: ChartLegendProps) => {
  return (
    <Styles.Wrapper>
      <Styles.LegendWrapper type={type}>
        <Styles.Legend type={type} />
      </Styles.LegendWrapper>
      <Styles.LegendText type={type}>
        {type === 'employee' ? 'Job Openings' : 'OPTX Score BETA'}
      </Styles.LegendText>
    </Styles.Wrapper>
  );
};

export default ChartLegend;
