import { Row as AntRow } from 'antd';
import styled from 'styled-components';

interface RowProps {
  $overflowHidden?: boolean;
}

const Row = styled(AntRow)<RowProps>`
  overflow: ${props => (props.$overflowHidden ? 'hidden' : 'visible')};
`;

export default {
  Row,
};
