import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useVT } from 'virtualizedtableforantd4';
import { TableProps } from 'antd/es/table';
import { useSize } from '@umijs/hooks';
// constants
import routes from '@constants/routes';
// models
import { SortByRule } from '@optx/models/table/sorting';
import { TableChangeCallback } from '@optx/models/table/antd-table';
import { CompanyInEquityTouch } from '@optx/features/equity-touch-integration/models/CompanyInEquityTouch';
// utils
import { mapSorting } from '@optx/utils/table/sorting';
import { generateUniqueKey } from '@optx/utils/uuid';
// redux
import { selectors, actions } from '@optx/features/equity-touch-integration/state';
// hooks
import useIsMount from '@optx/common/hooks/useIsMount';
import { useIsLaptopOrSmaller } from '@optx/common/hooks/responsive';
import useNonVirtualizedTable from '@optx/common/hooks/useNonVirtualizedTable';
// components
import Table from '@shared/view/organims/Table';
import {
  useVirtualScrollPosition,
  VirtualTableScrollYContext,
} from '@optx/features/scroll-history';

const CompaniesInETTable: React.FC<TableProps<any>> = props => {
  const dispatch = useDispatch();
  const useIsLaptopSmaller = useIsLaptopOrSmaller();
  const virtualizedActive = useNonVirtualizedTable();
  const data = useSelector(selectors.search.data);
  const columns = useSelector(selectors.sort.getGridColumns);
  const sortBy = useSelector(selectors.sort.sortBy);
  const pageNumber = useSelector(selectors.pagination.pageNumber);
  const isMount = useIsMount();
  const vtImperativeScroll = useRef<any>();

  const [windowSize] = useSize(window.document.documentElement);
  const [currentScroll, setCurrentScroll] = useState(windowSize.height || 0);
  const tableRef = useRef<HTMLDivElement>(null);

  const { onScroll, initialScrollPosition } = useVirtualScrollPosition(
    routes.reports.equityTouchIntegration
  );

  const ctx = useContext(VirtualTableScrollYContext);

  const handleChange: TableChangeCallback<CompanyInEquityTouch> = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    switch (extra.action) {
      case 'sort': {
        const { sortBy: sortByVal } = mapSorting(sorter, sortBy);
        dispatch(actions.sort.sort(sortByVal as Array<SortByRule<any>>));
        break;
      }

      default:
        break;
    }
  };

  const [vt] = useVT(
    () => ({
      initTop: initialScrollPosition,
      scroll: { y: currentScroll },
      onScroll,
      ref: vtImperativeScroll,
    }),
    [currentScroll, initialScrollPosition]
  );

  /**
   * Each time new pagination is done - resets the scroll.
   */
  useEffect(() => {
    if (!isMount) {
      vtImperativeScroll?.current?.scrollTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    // fix table being cropped when navigating to other pages and coming back
    setTimeout(() => {
      vtImperativeScroll?.current?.scrollTo(1);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getNextScroll = useCallback(() => {
    let tableHeaderHeight = 0;

    if (tableRef.current) {
      tableHeaderHeight =
        tableRef.current.querySelector('.ant-table-header')?.getBoundingClientRect().height || 0;
    }

    const virtualScrollSubstractElements = document.querySelectorAll(
      '[data-virtual-scroll-substract="my-companies-grid"]'
    );

    let substractHeight = 0;

    virtualScrollSubstractElements.forEach(element => {
      const styles = window.getComputedStyle(element, null);
      const margin = parseInt(styles.marginBottom);

      substractHeight += element.getBoundingClientRect().height + margin;
    });

    // Add in styles bottom padding the same size as pagination.
    const nextScroll =
      windowSize.height !== undefined ? windowSize.height - tableHeaderHeight - substractHeight : 0;

    return nextScroll;
  }, [windowSize.height]);
  /**
   * Update scroll each time the size of the wrapper changes or window size changes.
   * This can happen when let's say a column is added/removed and increases/decreases the height of table header.
   * Scroll doesn't include table header size.
   */
  useEffect(() => {
    setTimeout(() => {
      const nextScroll = getNextScroll();
      // Leave a 5px space at the top when computing exact height.
      const offset = 3;
      setCurrentScroll(nextScroll - offset);
    }, 0);
  }, [ctx.height, getNextScroll]);

  return (
    <div ref={tableRef}>
      <Table
        columns={columns}
        dataSource={data}
        className="legacy-table et-integration"
        rowKey={generateUniqueKey}
        onChange={handleChange}
        pagination={false}
        bordered
        scroll={{ x: useIsLaptopSmaller ? 1000 : '100%', y: currentScroll || 250 }}
        components={virtualizedActive ? vt : undefined}
        isInEt
      />
    </div>
  );
};

export default React.memo(CompaniesInETTable);
