import React from 'react';
// models
import { Cell } from '@models/table/Cell';
// constants
import { DEFAULT_CELL_VALUE } from '@constants/table/cells';
// components
import { cellIsEqual } from './memoize';
import TruncateTooltip from '@optx/shared/view/molecules/TruncateTooltip';

interface CustomCell extends Cell<any> {
  shouldBeInUpperCase?: boolean;
}

const TableCellListDefault: React.FC<CustomCell> = ({ value, shouldBeInUpperCase }) => {
  if (!value || value === DEFAULT_CELL_VALUE || !Array.isArray(value) || !value.length) {
    return <>{DEFAULT_CELL_VALUE}</>;
  }

  let valueFormated = value.join(', ');

  if (shouldBeInUpperCase) {
    valueFormated = valueFormated.toUpperCase();
  }

  return (
    <TruncateTooltip title={valueFormated} className="text-truncate">
      {valueFormated}
    </TruncateTooltip>
  );
};

export default React.memo(TableCellListDefault, cellIsEqual);
