import React, { useState, useContext, Context } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { ValueType } from 'react-select';
// models
import { SelectOption } from '@optx/models/Option';
import { AppState } from '@optx/redux/interfaces';
import { BulkEditFieldContext } from '@optx/models/bulkActions';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { showRationaleForStage } from '@optx/utils/helpers';
// redux
import { selectors } from '@components/feature/company-individual-edit/state';
import { getFormRationales } from '@optx/redux/company/filters/selectors';
// components
import SingleSelect from '@optx/components/common/select/SingleSelect';
import RationaleSection from '@optx/features/company/edit-fields/components/RationaleSection';

interface EditStageProps {
  context: Context<BulkEditFieldContext>;
}

export const EditStage: React.FC<EditStageProps> = ({ context }) => {
  const { setSelectedData, setRationaleValue } = useContext(context);

  const initialSelectValue = { value: '', label: '' };
  const [selectedValue, setSelectedValue] = useState(initialSelectValue);
  const [selectedRationale, setSelectedRationale] = useState<string | null>(null);

  const selectOptions = [
    { value: 'blank', label: 'None' },
    ...useSelector(selectors.companyIndividualEdit.stageOptions),
  ];

  const isLoadingInfo = useSelector(selectors.companyIndividualEdit.isLoadingInfo);

  const rationaleOptions = useSelector(
    (state: AppState) => getFormRationales(state, 'stage_pass_dead_rationales') ?? []
  );

  const handleRationaleChange = (value: string) => {
    setSelectedRationale(value);
    setRationaleValue(value);
  };

  const showIsOtherOrTag = (key?: string) => {
    const values = rationaleOptions.map(option => option.value).slice(0, -1);

    return !!(
      key !== 'tag' &&
      selectedRationale &&
      !values.includes(selectedRationale) &&
      selectedRationale !== DEFAULT_EMPTY_VALUE &&
      selectedRationale.length > 0
    );
  };

  const handleOtherChange = (option: React.SetStateAction<string | null>) => {
    if (option !== DEFAULT_EMPTY_VALUE) {
      setSelectedRationale(option);

      if (typeof option === 'string') {
        setRationaleValue(option);
      } else {
        setRationaleValue(undefined);
      }
    }
  };

  const handleSelectChange = (option: ValueType<SelectOption<string>>) => {
    setSelectedValue(option as SelectOption);
    setSelectedData([option as SelectOption]);
    setRationaleValue(undefined);
    setSelectedRationale(null);
  };

  const customRationaleValue = showIsOtherOrTag() ? 'other' : selectedRationale;

  return (
    <>
      <Typography.Text className="ant-modal-title">Select New Stage</Typography.Text>

      <SingleSelect
        placeholder="search or select stage"
        options={selectOptions}
        onChange={handleSelectChange}
        value={selectedValue}
        isLoading={isLoadingInfo}
        closeMenuOnSelect={true}
        className="single-select-menu-is-opened-container is-stage"
        maxMenuHeight={200}
      />

      {showRationaleForStage(selectedValue.label) && (
        <RationaleSection
          rationaleOptions={rationaleOptions}
          rationaleValue={customRationaleValue}
          handleRationaleChange={handleRationaleChange}
          currRationaleValue={selectedRationale}
          showIsOtherOrTag={showIsOtherOrTag}
          handleOtherChange={handleOtherChange}
          isRationaleRequired
          isHeightOverflow
        />
      )}
    </>
  );
};
